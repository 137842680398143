import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "kf-main-content"
};
const _hoisted_2 = {
  class: "kf-main-port"
};
const _hoisted_3 = {
  class: "kf-main-port-container"
};
const _hoisted_4 = {
  class: "kf-main-port-container"
};
const _hoisted_5 = {
  class: "kf-right-panel"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_main3d_viewport = _resolveComponent("main3d-viewport");
  const _component_main_viewport = _resolveComponent("main-viewport");
  const _component_right_panel = _resolveComponent("right-panel");
  const _component_KFDialog = _resolveComponent("KFDialog");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_withDirectives(_createElementVNode("div", _hoisted_3, [_createVNode(_component_main3d_viewport)], 512), [[_vShow, $setup.viewPortData.view3D]]), _withDirectives(_createElementVNode("div", _hoisted_4, [_createVNode(_component_main_viewport)], 512), [[_vShow, $setup.viewPortData.view2D]])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_right_panel)]), _createVNode(_component_KFDialog)]);
}