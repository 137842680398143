import { onMounted } from 'vue';
import mainViewport from '../mainContent/mainViewport';
import main3dViewport from '../mainContent/main3dViewport';
import rightPanel from '../mainContent/rightPanel/index.vue';
import ProjectService from "../../service/project";
import { viewPortData } from "@/model/globalData";
import KFDialog from "@/components/dialog/KFDialog.vue";
export default {
  name: "main-content",
  components: {
    KFDialog,
    mainViewport,
    main3dViewport,
    rightPanel
  },
  setup() {
    setInterval(() => {
      ProjectService.heartbeat();
    }, 60000);
    onMounted(() => {
      // setTimeout(() => {
      //     loadAllSpace();
      // }, 800);
    });
    return {
      viewPortData
    };
  }
};