import { ref } from 'vue';
const menuList = ref([{
  name: Kf.t('File'),
  code: "1",
  type: 'file',
  children: [{
    name: Kf.t('BuildingList'),
    code: "2-0",
    type: 'scene',
    key: 'openScene',
    icon: 'icon-Vector3'
  }, {
    name: Kf.t('ReturnProjectList'),
    code: "2-1",
    type: 'file',
    key: 'openProject',
    icon: 'icon-Vector3'
  }, {
    name: Kf.t('ClearCanvas'),
    code: "2-2",
    type: 'file',
    icon: 'icon-Vector3',
    key: 'clean'
  }, {
    name: Kf.t('Save'),
    code: "2-3",
    type: 'file',
    key: 'saveScene',
    icon: 'icon-Vector3'
  }, {
    name: Kf.t('SaveAs'),
    code: "2-4",
    type: 'file',
    key: 'saveAs',
    icon: 'icon-Vector2'
  }, {
    name: Kf.t('ExportModel'),
    code: "2-8",
    type: 'file',
    key: 'ExportModel',
    icon: 'icon-Vector2',
    children: [{
      name: 'glb',
      code: "2-8-1",
      type: 'glb',
      icon: 'icon-Vector2'
    }, {
      name: 'gltf',
      code: "2-8-2",
      type: 'gltf',
      icon: 'icon-Vector2'
    }, {
      name: 'obj',
      code: "2-8-3",
      type: 'obj',
      icon: 'icon-Vector2'
    }]
  }
  // {name: Kf.t('ExportSpaceData'), code: "2-5", type: 'file', key: 'exportSpaceData', icon: 'icon-Vector2'},
  // {name: Kf.t('CreateSpacePointTable'), code: "2-6", type: 'file', key: 'createSpacePointTable', icon: 'icon-Vector2'},
  /*          {name: "导入场景", code: "2-5", type: 'file', icon: 'icon-Vector1'},
            {name: Kf.t('ExportAs'), code: "2-6", type: 'file', icon: 'icon-Vector2'},
            {name: Kf.t('UpdateScene'), code: "2-7", type: 'file', icon: 'icon-Vector1'}*/]
}, {
  name: Kf.t('DataStatus'),
  code: "2",
  type: 'system',
  children: [{
    name: Kf.t('RegionSettings'),
    code: "2-8",
    type: 'area',
    key: 'openArea',
    icon: 'icon-Vector3'
  }]
},
/*{
 name: Kf.t('Tools'), code: "3", type: 'system', children: [
     {name: Kf.t('GroundGrid'), code: "1-4", type: 'file', icon: 'icon-Vector2'},
 ]
},*/
{
  name: Kf.t('Help'),
  code: "4",
  type: 'help',
  children: [{
    name: Kf.t('ShortcutKeys'),
    code: "3-1",
    type: 'about',
    key: 'openHotKey',
    icon: 'icon-aboutus'
  },
  /* {name: Kf.t('UserManual'), code: "3-1", type: 'about', key: 'use_docs', icon: 'icon-aboutus'},
   {name: Kf.t('About'), code: "3-1", type: 'about', key: 'openAbout', icon: 'icon-aboutus'},*/
  {
    name: Kf.t('ContactUs'),
    code: "3-1",
    type: 'contactUs',
    key: 'contactUs',
    icon: 'icon-lianxiwomen'
  }]
}]);
const hotKey = [[Kf.t('Copy2'), 'CTRL + C'], [Kf.t('Paste'), 'CTRL + V'], [Kf.t('Save'), 'CTRL + S'], [Kf.t('AddSelection') + '（2D）', 'Shift'], [Kf.t('Drag&Copy') + '（2D）', 'Ctrl+' + Kf.t('Drag')], [Kf.t('Edit'), 'E'], [Kf.t('PickUp'), 'V']];
export { hotKey };
export default {
  menuList
};