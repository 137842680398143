import "core-js/modules/es.array.push.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-f12e2c14"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "scope-point_list_panel-root"
};
const _hoisted_2 = {
  class: "kf-panel-header-tabs-con-thead"
};
const _hoisted_3 = {
  class: "kf-panel-tr thead-tr scope-sm-point_list_panel-header"
};
const _hoisted_4 = {
  class: "scope-sm-point_list_panel-header-title"
};
const _hoisted_5 = {
  class: "scope-sm-point_list_panel-header-dropDown"
};
const _hoisted_6 = {
  class: "kf-panel-header-tabs-con-search"
};
const _hoisted_7 = {
  class: "scope-point_list_panel-container"
};
const _hoisted_8 = ["onClick"];
const _hoisted_9 = ["onClick", "onDblclick", "onMousedown", "onContextmenu"];
const _hoisted_10 = {
  class: "scope-point-name"
};
const _hoisted_11 = {
  style: {
    "font-size": "12px",
    "margin-right": "8px",
    "width": "12px",
    "height": "12px"
  }
};
const _hoisted_12 = {
  class: "scope-point-name1"
};
const _hoisted_13 = {
  class: "scope-point-floor"
};
const _hoisted_14 = {
  key: 0
};
const _hoisted_15 = {
  class: "scope-point-floor"
};
const _hoisted_16 = {
  class: "scope-point-name"
};
const _hoisted_17 = {
  class: "scope-point-mapping"
};
import extendInput from "@/components/propWidget/extendInput.vue";
import { ref, computed } from "vue";
import { smDatas } from "@/model/smCollectionData";
import { doItemClick, doItemDBClick, showContextMenu } from "@/components/mainContent/rightPanel/index";
import { currData, globalState } from "@/model/globalData";
import { getModelByName } from "@/model/3Dmodel";
import tools from "@/utils/tools";
export default {
  __name: 'point',
  setup(__props) {
    const currList = ref([]);
    const isShowTempItemDom = ref(false);
    const tempItem = ref(null);
    const dragImgStyle = ref('');
    const clickOnGroupDiv = group => {
      group.isOpen = !group.isOpen;
    };
    let searchValue = ref('');
    const onChange = event => {
      let file = event.currentTarget.files[0];
      let reader = new FileReader();
      reader.onload = function (result) {
        let points = JSON.parse(result.target.result);
        for (let i = 0; i < points.length; i++) {
          let model = smDatas['createDevicePoint'](points[i]);
          model.setNeedSave();
        }
      };
      reader.readAsText(file);
    };
    let isActive = function (item) {
      return currData.point === item || currData.currListMap.has(item);
    };
    const onDevicePointMousedown = function (event, item) {
      let startX = event.clientX;
      let startY = event.clientY;
      globalState.dragData = item;
      tempItem.value = item;
      let documentMousemove = function (event) {
        if (Math.abs(startX - event.clientX) > 10 || Math.abs(startY - event.clientY) > 10) {
          isShowTempItemDom.value = true;
        }
        if (isShowTempItemDom.value) {
          dragImgStyle.value = `top:${event.clientY - 21}px;left:${event.clientX - 21}px;z-index:100`;
        }
      };
      let documentMouseup = function (event) {
        isShowTempItemDom.value = false;
        dragImgStyle.value = '';
        // currData.mode2 = '';
        globalState.dragData = null;
        document.removeEventListener('mousemove', documentMousemove);
        document.removeEventListener('mouseup', documentMouseup);
      };
      document.addEventListener('mousemove', documentMousemove);
      document.addEventListener('mouseup', documentMouseup);
    };
    const getMappingString = function (item) {
      let size = item.deviceModelList.size;
      if (size > 1) {
        return Kf.t('Mapped') + `(${size})`;
      } else if (size) {
        return Kf.t('Mapped');
      }
      return Kf.t('Unmapped');
    };
    const buildingSelected = ref('all');
    const floorSelected = ref('all');
    const selectedChanged = function (type, value) {
      if (!value) {
        return;
      }
      if (type === 'building') {
        buildingSelected.value = value[0];
      } else if (type === 'floor') {
        floorSelected.value = value[0];
      }
    };
    const buildingOptions = computed(() => {
      let points = smDatas.devicePointsProxy.value;
      let buildings = [{
        name: Kf.t('All') + ` (${points.length})`,
        value: 'all'
      }];
      let buildingMap = new Map();
      for (let item of points) {
        if (!buildingMap.has(item.deviceBuilding)) {
          buildingMap.set(item.deviceBuilding, {
            name: item.deviceBuilding,
            value: item.deviceBuilding,
            count: 0
          });
          buildings.push(buildingMap.get(item.deviceBuilding));
        }
        buildingMap.get(item.deviceBuilding).count++;
      }
      for (let item of buildingMap.values()) {
        item.name = item.name + ` (${item.count})`;
      }
      return buildings;
    });
    const floorOptions = computed(() => {
      let points = smDatas.devicePointsProxy.value;
      let floors = [{
        name: Kf.t('All') + ` (${points.length})`,
        value: 'all'
      }];
      let floorMap = new Map();
      for (let item of points) {
        if (!floorMap.has(item.floor)) {
          floorMap.set(item.floor, {
            name: item.floor,
            value: item.floor,
            count: 0
          });
          floors.push(floorMap.get(item.floor));
        }
        floorMap.get(item.floor).count++;
      }
      for (let item of floorMap.values()) {
        item.name = item.name + ` (${item.count})`;
      }
      return floors;
    });
    const groupCache = ref({});
    const deviceGroupDatas = computed(() => {
      let points = smDatas.devicePointsProxy.value;
      let search = searchValue.value;
      let _deviceGroups = {};
      for (let item of points) {
        if (item.isDeleted) {
          continue;
        }
        if (buildingSelected.value !== 'all' && item.deviceBuilding !== buildingSelected.value) {
          continue;
        }
        if (floorSelected.value !== 'all' && item.floor !== floorSelected.value) {
          continue;
        }
        let group = item.deviceType || item.deviceTypeCode || item.deviceName;
        if (search && !tools.pinyinMatch(item.deviceType, search) && !tools.pinyinMatch(item.deviceTypeCode, search) && !tools.pinyinMatch(item.deviceName, search) && !tools.pinyinMatch(item.deviceMapId, search) && !tools.pinyinMatch(item.deviceId, search) && !tools.pinyinMatch(item.spaceComponentName, search)) {
          continue;
        }
        if (!_deviceGroups[group]) {
          if (!groupCache.value[group]) {
            groupCache.value[group] = {
              name: group,
              data: [],
              isOpen: false
            };
          }
          groupCache.value[group].data = [];
          _deviceGroups[group] = groupCache.value[group];
        }
        _deviceGroups[group].data.push(item);
      }
      return _deviceGroups;
    });
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_CaretRight = _resolveComponent("CaretRight");
      const _component_el_icon = _resolveComponent("el-icon");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('Name')), 1), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", null, [_createVNode(extendInput, {
        inputType: "select",
        placeholder: _ctx.$t('Undefined'),
        options: buildingOptions.value,
        targets: [buildingSelected.value],
        onChange: _cache[0] || (_cache[0] = $event => selectedChanged('building', $event))
      }, null, 8, ["placeholder", "options", "targets"])]), _createElementVNode("div", null, [_createVNode(extendInput, {
        inputType: "select",
        placeholder: _ctx.$t('Undefined'),
        options: floorOptions.value,
        targets: [floorSelected.value],
        onChange: _cache[1] || (_cache[1] = $event => selectedChanged('floor', $event))
      }, null, 8, ["placeholder", "options", "targets"])])])])]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_el_input, {
        placeholder: _ctx.$t('Search'),
        "prefix-icon": "Search",
        clearable: "",
        modelValue: _unref(searchValue),
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _isRef(searchValue) ? searchValue.value = $event : searchValue = $event)
      }, null, 8, ["placeholder", "modelValue"])]), _createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(deviceGroupDatas.value, group => {
        return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", {
          class: "scope-point-device-title",
          onClick: $event => clickOnGroupDiv(group)
        }, [_createVNode(_component_el_icon, {
          style: _normalizeStyle([{
            "margin-top": "10px"
          }, {
            transform: !group.isOpen ? 'rotate(0deg)' : 'rotate(90deg)'
          }])
        }, {
          default: _withCtx(() => [_createVNode(_component_CaretRight)]),
          _: 2
        }, 1032, ["style"]), _createTextVNode(" " + _toDisplayString(group.name + ` (${group.data.length})`), 1)], 8, _hoisted_8), group.isOpen ? (_openBlock(true), _createElementBlock(_Fragment, {
          key: 0
        }, _renderList(group.data, item => {
          return _openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["scope-point-device-item", [item.isDeleted ? 'kf-panel-tab-header-delete' : '', _unref(isActive)(item) ? 'kf-panel-tab-header-active' : 'kf-panel-tab-header-default']]),
            onClick: $event => _unref(doItemClick)($event, item, _unref(smDatas).devicePoints),
            onDblclick: $event => _unref(doItemDBClick)($event, item, _unref(smDatas).devicePoints),
            onMousedown: $event => onDevicePointMousedown($event, item),
            onContextmenu: _withModifiers($event => _unref(showContextMenu)($event, item, _unref(smDatas).devicePoints), ["prevent", "stop"])
          }, [_createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_createElementVNode("i", {
            class: _normalizeClass(_unref(getModelByName)(item.spaceComponentName) ? 'iconfont icon-guanlian' : 'iconfont icon-xuanzhong1'),
            style: _normalizeStyle(item.spaceComponent ? _unref(getModelByName)(item.spaceComponentName) ? 'font-size: 12px;color:green' : 'font-size: 12px;color:red' : 'font-size: 12px')
          }, null, 6)]), _createElementVNode("div", null, _toDisplayString(item.deviceName || `<${item.name}`), 1)]), _createElementVNode("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, _toDisplayString(item.floor), 1), _createElementVNode("div", {
            class: _normalizeClass(["scope-point-mapping", {
              'scope-point-mapping-active': item.deviceModelList.size
            }])
          }, _toDisplayString(getMappingString(item)), 3), item.spaceComponent ? (_openBlock(), _createElementBlock("div", _hoisted_14)) : _createCommentVNode("", true)])], 42, _hoisted_9);
        }), 256)) : _createCommentVNode("", true)]);
      }), 256)), isShowTempItemDom.value ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "scope-point_list_panel-temp-container",
        style: _normalizeStyle(dragImgStyle.value)
      }, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_15, _toDisplayString(tempItem.value.floor), 1), _createElementVNode("div", _hoisted_16, _toDisplayString(tempItem.value.deviceName || `<${tempItem.value.name}`), 1)]), _createElementVNode("div", _hoisted_17, _toDisplayString(getMappingString(tempItem.value)), 1)], 4)) : _createCommentVNode("", true)])]);
    };
  }
};