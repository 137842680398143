import "core-js/modules/es.array.push.js";
import { Wall } from "@/objects/smWall";
import { SMFloor } from "@/objects/smFloor";
import { Model } from "@/objects/smModel";
import { Area } from "@/objects/smArea";
import { Pipeline } from "@/objects/smPipeline";
import { DevicePoint } from "@/objects/smDevicePoint";
import { Drawing } from "@/objects/smDrawing";
import { ImageDrawing } from "@/objects/smImageDrawing";
import { DxfDrawing } from "@/objects/smDxfDrawing";
import { getSMType, registerSMType } from "@/objects/smObject";
import { globalState, currData, globalNeedSave } from "@/model/globalData";
import { ref, watch } from 'vue';
import { selectedThemeId } from "@/model/3Dmodel";
registerSMType('floor', SMFloor);
registerSMType('area', Area);
registerSMType('wall', Wall);
registerSMType('area', Area);
registerSMType('pipeline', Pipeline);
registerSMType('devicePoint', DevicePoint);
registerSMType('drawing', Drawing);
registerSMType('imageDrawing', ImageDrawing);
registerSMType('dxfDrawing', DxfDrawing);
class SMCollection {
  constructor() {
    //mode2 是为了区分mode事件冲突
    this.buildingProxy = ref(null);
    this.trashList = {};
    this.objects = {};
    this.floorsProxy = ref([]);
    this.drawingsProxy = ref([]);
    this.wallsProxy = ref([]);
    this.areasProxy = ref([]);
    this.pipelinesProxy = ref([]);
    this.modelsProxy = ref([]);
    this.devicePointsProxy = ref([]);
    this.updateCallbacks = [];
    this.addCallbacks = [];
    this.removeCallbacks = [];
    this.connectionTslTemplateMap = {};
    this.modelsGroupProxy = ref(new Map());
    watch(() => selectedThemeId.value, () => {
      if (this.building) {
        this.building.setNeedSave();
      }
    });
  }
  get building() {
    return this.buildingProxy.value;
  }
  get floors() {
    return this.floorsProxy.value;
  }
  get drawings() {
    return this.drawingsProxy.value;
  }
  get walls() {
    return this.wallsProxy.value;
  }
  get areas() {
    return this.areasProxy.value;
  }
  get pipelines() {
    return this.pipelinesProxy.value;
  }
  get devicePoints() {
    return this.devicePointsProxy.value;
  }
  get models() {
    return this.modelsProxy.value;
  }
  get modelsGroup() {
    return this.modelsGroupProxy.value;
  }
  update(obj) {
    if (!obj) {
      return;
    }
    let callbacks = this.updateCallbacks;
    for (let i = 0; i < callbacks.length; i++) {
      let callback = callbacks[i];
      if (callback) {
        callback(obj);
      }
    }
  }
  addCallback(obj) {
    if (!obj) {
      return;
    }
    let callbacks = this.addCallbacks;
    for (let i = 0; i < callbacks.length; i++) {
      let callback = callbacks[i];
      if (callback) {
        callback(obj);
      }
    }
  }
  clean() {
    let datas = [this.models, this.areas, this.walls, this.drawings, this.pipelines, this.floors, this.devicePoints];
    for (let i = 0; i < datas.length; i++) {
      while (datas[i].length) {
        this.remove(datas[i][0]);
      }
    }
    this.remove(this.building);
    globalState.mode = 'wall';
    globalState.modeArgs = [];
    globalNeedSave.value = false;
    currData.floor = null;
    currData.wall = null;
    currData.area = null;
    currData.pipeline = null;
    currData.models = {};
    currData.model2 = null;
    currData.segment = null;
    currData.point = null;
    currData.devicePoint = null;
    currData.currList = [];
    currData.currListFlag++;
    this.trashList = {};
  }
  deleteAll() {
    let needSave = false;
    let datas = [this.models, this.areas, this.walls, this.drawings, this.pipelines, this.floors, this.devicePoints];
    for (let i = 0; i < datas.length; i++) {
      for (let j = 0; j < datas[i].length; j++) {
        if (!datas[i][j].isDeleted) {
          datas[i][j].isDeleted = true;
          needSave = true;
          datas[i][j].makeDirty();
        }
      }
    }
    globalState.mode = 'wall';
    globalState.modeArgs = [];
    globalNeedSave.value = false;
    currData.floor = null;
    currData.wall = null;
    currData.area = null;
    currData.pipeline = null;
    currData.models = {};
    currData.model2 = null;
    currData.segment = null;
    currData.point = null;
    currData.devicePoint = null;
    currData.currList = [];
    currData.currListFlag++;
    this.trashList = {};
    if (needSave) {
      globalNeedSave.value = true;
    }
  }
  delete(objects) {
    if (!(objects instanceof Array)) {
      objects = [objects];
    }
    for (let obj of [...objects]) {
      if (!obj.isDeleted) {
        obj.isDeleted = true;
        obj.makeDirty();
      }
    }
  }
  remove(obj) {
    if (!obj) {
      return;
    }
    let type = obj.type;
    if (type.indexOf('Drawing') > 0) {
      type = 'drawing';
    }
    let array = this[type + 's'];
    let index = array ? array.indexOf(obj) : -1;
    if (index === -1) {
      return;
    }
    this.trashList[obj.nanoId] = obj;
    array.splice(index, 1);
    index = currData.currList.indexOf(obj);
    if (index >= 0) {
      currData.currList.splice(index, 1);
      currData.currListFlag++;
    }
    if (currData[obj.type] === obj) {
      currData[obj.type] = array[0];
    }
    if (obj.type === 'model') {
      delete currData.models[obj.nanoId];
    }
    delete this.objects[obj.nanoId];
    let callbacks = this.removeCallbacks;
    for (let i = 0; i < callbacks.length; i++) {
      let callback = callbacks[i];
      if (callback) {
        callback(obj);
      }
    }
  }
  createObject(options) {
    let obj;
    if (options && this.trashList[options.uuid]) {
      obj = this.trashList[options.uuid];
      delete this.trashList[options.uuid];
      obj.updateProperties(options);
    } else {
      let smType = options.type;
      options.dirtyCallback = (obj, flag) => {
        this.update(obj);
      };
      obj = new (getSMType(smType))(this, options);
    }
    if (obj.type === 'building') {
      // this.building = obj;
      if (this.building) {
        console.warn('Can not load anther building...', obj);
      }
    } else {
      let type = obj.type;
      if (type.indexOf('Drawing') > 0) {
        type = 'drawing';
      }
      let typeArray = this[type + 's'];
      typeArray.push(obj);
      obj = typeArray[typeArray.length - 1];
      if (obj.type === 'model') {
        if (globalState.mode === 'model') {
          currData.models[obj.nanoId] = obj;
        }
      } else if (!currData[obj.type]) {
        currData[obj.type] = obj;
      }
      this.addCallback(obj);
    }
    this.objects[obj.nanoId] = obj;
    obj.updateProperties(options);
    return obj;
  }
  create(data) {
    switch (data.type) {
      case 'floor':
        return this.createFloor(data);
      case 'drawing':
      case 'imageDrawing':
        return this.createDrawing(data);
      case 'dxfDrawing':
        return this.createDxfDrawing(data);
      case 'wall':
        return this.createWall(data);
      case 'area':
        return this.createArea(data);
      case 'pipeline':
        return this.createPipeline(data);
      case 'model':
        return this.createModel(data);
      case 'devicePoint':
        return this.createDevicePoint(data);
      default:
        console.error('can not create sm object: ', data);
    }
  }
  createFloor(options) {
    let oldFloors = this.floors.filter(x => !x.isDeleted).sort((a, b) => a.elevation - b.elevation);
    let lastFloor = null;
    let name = 'F1';
    for (let i = 1; i < 1000; i++) {
      name = 'F' + i;
      let temp = oldFloors.find(x => !x.isDeleted && x.name === name);
      if (!temp) {
        break;
      }
      lastFloor = temp;
    }
    return this.createObject(Object.assign({
      name: name,
      type: 'floor',
      properties: {
        position: lastFloor ? [0, lastFloor.elevation + lastFloor.height, 0] : [0, 0, 0]
      }
    }, options));
  }
  createDrawing(options) {
    return this.createObject(Object.assign({
      type: 'imageDrawing',
      name: Kf.t('Diagrams'),
      scaling: 10
    }, options));
  }
  createDxfDrawing(options) {
    return this.createObject(Object.assign({
      type: 'dxfDrawing',
      name: Kf.t('Diagrams'),
      scaling: 10
    }, options));
  }
  createWall(options) {
    return this.createObject(Object.assign({
      name: Kf.t('Wall'),
      type: 'wall'
    }, options));
  }
  createArea(options) {
    return this.createObject(Object.assign({
      name: Kf.t('Regional'),
      type: 'area'
    }, options));
  }
  createPipeline(options) {
    return this.createObject(Object.assign({
      name: Kf.t('Pipeline'),
      type: 'pipeline'
    }, options));
  }
  createModel(options) {
    return this.createObject(Object.assign({
      name: Kf.t('Model'),
      type: 'model'
    }, options));
  }
  createDevicePoint(options) {
    return this.createObject(Object.assign({
      name: Kf.t('Position'),
      type: 'devicePoint'
    }, options));
  }
  cloneObject(obj) {
    if (!obj) {
      return;
    }
    switch (obj.type) {
      case 'building':
        console.warn('Can not clone building...', obj);
        break;
      case 'floor':
        let data = obj.serialize();
        let idMap = {};
        delete data.uuid;
        delete data.nanoId;
        delete data.name;
        data.properties.position[1] += obj.height;
        let floor = this.createFloor(data);
        floor.setNeedSave();
        idMap[obj.nanoId] = floor.nanoId;
        for (let id of obj.objects) {
          let subObj = this.objects[id];
          let temp = subObj.serialize();
          delete temp.uuid;
          delete temp.nanoId;
          temp.floorId = floor.nanoId;
          temp.parentId = idMap[temp.parentId];
          let newObj = this.create(temp);
          newObj.setNeedSave();
          idMap[subObj.nanoId] = newObj.nanoId;
        }
        return floor;
      case 'model':
        let temp = obj.serialize();
        delete temp.uuid;
        delete temp.nanoId;
        if (temp.properties && temp.properties.properties) {
          temp.properties.properties = JSON.parse(JSON.stringify(temp.properties.properties));
        }
        let newObj = this.create(temp);
        newObj.setNeedSave();
        return newObj;
    }
  }
  unBindModelAndDevicePoint(id) {
    for (let devicePoint of this.devicePoints) {
      devicePoint.deviceModelList.delete(id);
    }
    for (let model of this.models) {
      if (model.deviceId === id) {
        model.deviceId = null;
      }
    }
  }
}
export { SMCollection };