import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = {
  class: "atom_box h100 w100"
};
const _hoisted_2 = ["title"];
const _hoisted_3 = {
  key: 0,
  class: "iconfont icon-yuanchicun"
};
const _hoisted_4 = {
  key: 1,
  class: "iconfont icon-zuidahua"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    ref: "root",
    class: _normalizeClass(['vivify ' + $setup.frameProp.className, "kf_atom_canvas_box h100"]),
    style: _normalizeStyle($setup.frameProp.style),
    title: $setup.frameProp.tip
  }, [_createElementVNode("canvas", {
    ref: "domCanvas",
    id: "idWeb3dCanvas",
    onTap: _cache[0] || (_cache[0] = (...args) => $setup.eventHandler.tap && $setup.eventHandler.tap(...args)),
    onLoad: _cache[1] || (_cache[1] = (...args) => $setup.eventHandler.load && $setup.eventHandler.load(...args)),
    "on:loadingHide": _cache[2] || (_cache[2] = (...args) => $setup.eventHandler.loadingHide && $setup.eventHandler.loadingHide(...args)),
    onRender: _cache[3] || (_cache[3] = (...args) => $setup.eventHandler.render && $setup.eventHandler.render(...args)),
    style: {
      "width": "100%",
      "height": "100%",
      "outline": "none"
    }
  }, null, 544), $props.props.fullscreen ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    onClick: _cache[4] || (_cache[4] = (...args) => $setup.doFullscreen && $setup.doFullscreen(...args)),
    style: {
      "position": "absolute",
      "left": "5px",
      "top": "5px"
    }
  }, [$setup.fullscreen ? (_openBlock(), _createElementBlock("i", _hoisted_3)) : _createCommentVNode("", true), !$setup.fullscreen ? (_openBlock(), _createElementBlock("i", _hoisted_4)) : _createCommentVNode("", true)])) : _createCommentVNode("", true)], 14, _hoisted_2)]);
}