import { currData } from "@/model/globalData";
import vertexCoordinatesProp from "@/components/propWidget/vertexCoordinatesProp";
import segmentProp from "@/components/propWidget/segmentProp";
import numInput from "@/components/propWidget/numInput";
import { wallOptions } from "@/model/purposeOptions";
import extendInput from '@/components/propWidget/extendInput';
import { formatWallOptions4SelectUILable2Name, floorList, getItemProperty, isMultiple, updateItemProperty, floorListOptions } from "@/components/mainContent/rightPanel/property/index";
export default {
  name: "wall",
  components: {
    vertexCoordinatesProp,
    numInput,
    segmentProp,
    extendInput
  },
  setup() {
    let wallOptions4UI = formatWallOptions4SelectUILable2Name(wallOptions);
    return {
      isMultiple,
      getItemProperty,
      updateItemProperty,
      currData,
      wallOptions4UI,
      floorListOptions
    };
  }
};