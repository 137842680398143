import "core-js/modules/es.array.push.js";
import { Vector2, verticalLine2DPoint, lineIntersect, verticalArc2DPoint, Vector3, intersectCheckSegmentAndBox } from "@/maths/vector";
import { undoMap } from "@/undo/undoMap";
import { keyboardValue } from "@/utils/keyboard";
import { globalState, currData } from "@/model/globalData";
import { smDatas } from "@/model/smCollectionData";
class SmWallCreator {
  constructor(creator) {
    this.type = 'wallCreator';
    this.owner = creator;
    this.collection = creator.collection;
    this.tools = creator.tools;
    this.wallSegmentStack = [];
    this.wallBreakSegmentPointList = [];
  }
  mouseRelease(pos, ray) {
    let segments = currData.segments;
    let segment = segments[0];
    if (globalState.isDrawing && segment) {
      currData.segment = null;
      for (let segment of segments) {
        currData.wall.removeSegment(segment);
      }
      let points = this.wallBreakSegmentPointList;
      let lastBreakPoint = points[points.length - 1];
      if (lastBreakPoint && lastBreakPoint === segment.pointA && lastBreakPoint.segments.length === 2) {
        let point = segment.pointA;
        currData.wall.joinSegment(point.segments[0], point.segments[1], point);
        currData.wall.setAccessoriesNeedSave();
      }
    }
    this._arcData = null;
    this.wallSegmentStack = [];
    this.wallBreakSegmentPointList = [];
    globalState.isDrawing = '';
  }
  modeChanged() {
    this.mouseRelease();
  }
  updateText() {
    let segment = currData.segment;
    let currPoint = currData.point;
    if (globalState.isDrawing && segment) {
      let pointA = currData.segment.pointA;
      this.tools.updateTextDegree(segment.pointA, 0, (Math.atan2(currPoint.y - pointA.y, currPoint.x - pointA.x) * 180 / Math.PI).toFixed(1) + '°');
      this.tools.updateTextLength(currPoint, 0, (0.1 * pointA.distance(currPoint)).toFixed() + 'cm');
    } else {
      this.tools.updateTextDegree();
      this.tools.updateTextLength();
    }
  }
  mouseTap(pos, ray, adsorb) {
    if (!currData.wall) {
      let wall = this.pickObject(pos, ray, adsorb);
      if (wall) {
        currData.wall = wall;
        return;
      }
      if (currData.floor && !currData.floor.visible) {
        return;
      }
      if (!currData.floor) {
        let floor = smDatas.createFloor();
        floor.setNeedSave();
        currData.floor = floor;
      }
      wall = this.collection.createWall();
      wall.floor = currData.floor.nanoId;
      wall.setNeedSave();
      undoMap.push({
        cmd: 'create',
        value: wall
      });
      currData.wall = wall;
    }
    if (currData.wall && currData.wall.finalVisible && !currData.wall.locked && !currData.subType) {
      if (globalState.drawingMode === 'arc' && globalState.isDrawing) {
        if (this._arcData.step === 0) {
          this._arcData.step = 1;
        } else if (this._arcData.step === 1) {
          this._arcData = null;
          this.wallSegmentStack = [];
          this.wallBreakSegmentPointList = [];
          globalState.isDrawing = '';
        }
      } else if (globalState.drawingMode === 'rect' && globalState.isDrawing) {
        let wall = currData.wall;
        let point = adsorb.point;
        if (wall.points.includes(point) && currData.points[2] !== point && currData.segments.length === 4) {
          currData.segments[1].setPointB(point);
          currData.segments[2].setPointA(point);
          wall.removePoint(currData.points[2]);
        } else {
          point = currData.points[2];
        }
        currData.points = [];
        currData.segments = [];
        this.wallSegmentStack = [];
        this.wallBreakSegmentPointList = [];
        if (adsorb.segment && wall.segments.indexOf(adsorb.segment) >= 0) {
          wall.breakSegment(adsorb.segment, point);
          wall.setAccessoriesNeedSave();
        }
        globalState.isDrawing = '';
      } else if (!adsorb || !globalState.isDrawing || !currData.segment || adsorb.point !== currData.segment.pointA) {
        if (!currData.undoData) {
          currData.undoData = {
            cmd: 'updateObj',
            target: currData.wall,
            value: currData.wall.serialize()
          };
        }
        if (!globalState.isDrawing) {
          currData.point = null;
        }
        this.createPoint(pos, ray, adsorb);
        currData.wall.setNeedSave();
        currData.setCurrList(currData.wall);
      }
    }
  }
  mouseUp(pos, ray, adsorb) {
    let dragData = this.dragData;
    if (dragData) {
      this.dragData = null;
      globalState.isDrawing = '';
      if (dragData.type === 'breakControl') {
        currData.point = null;
      }
    }
  }
  mouseMove(pos, ray, adsorb) {
    this.updatePoint(pos, adsorb);
  }
  createPoint(pos, ray, adsorb) {
    let wall = currData.wall;
    let currSegment = currData.segment;
    if (adsorb) {
      if (adsorb.point) {
        pos.x = adsorb.point.x;
        pos.y = adsorb.point.y;
        if (wall.points.indexOf(adsorb.point) >= 0) {
          if (currSegment && currSegment.pointB === adsorb.point) {
            let pointA = currSegment.pointA;
            let seg = wall.createSegment(pointA, adsorb.point);
            wall.removeSegment(currSegment);
            currData.segment = seg;
            currSegment = seg;
          }
          currData.point = adsorb.point;
        }
      } else {
        if (adsorb.pointX) {
          pos.x = adsorb.pointX.x;
        }
        if (adsorb.pointY) {
          pos.y = adsorb.pointY.y;
        }
      }
    }
    if (globalState.drawingMode === 'arc') {
      if (!globalState.isDrawing) {
        this._arcData = {
          center: {
            x: pos.x,
            y: pos.y
          },
          radius: 0,
          startAngle: 0,
          angle: Math.PI * 2,
          step: 0
        };
        globalState.isDrawing = 'arc';
        let points = [wall.createPoint(pos), wall.createPoint(pos)];
        currData.points = points;
        let segment = wall.createSegment(points[0], points[1]);
        currData.segments = [segment];
        segment.angle = this._arcData.angle;
        segment.center = new Vector2(pos.x, pos.y);
      }
    } else if (globalState.drawingMode === 'rect') {
      if (!currData.point || !globalState.isDrawing) {
        let point = currData.point || wall.createPoint(pos);
        point.x = Math.floor(point.x);
        point.y = Math.floor(point.y);
        let points = [point, wall.createPoint(pos), wall.createPoint(pos), wall.createPoint(pos)];
        currData.points = points;
        let segments = [wall.createSegment(points[0], points[1]), wall.createSegment(points[1], points[2]), wall.createSegment(points[2], points[3]), wall.createSegment(points[3], points[0])];
        currData.segments = segments;
        this.wallSegmentStack.push(...segments);
        if (adsorb.segment && wall.segments.indexOf(adsorb.segment) >= 0) {
          wall.breakSegment(adsorb.segment, point);
          wall.setAccessoriesNeedSave();
          this.wallBreakSegmentPointList.push(point);
        }
        globalState.isDrawing = 'rect';
      }
    } else {
      let point = currData.point || wall.createPoint(pos);
      point.x = Math.floor(point.x);
      point.y = Math.floor(point.y);
      currData.point = wall.createPoint(pos);
      if (currSegment) {
        this.wallSegmentStack.push(currSegment);
      }
      if (adsorb.segment && wall.segments.indexOf(adsorb.segment) >= 0) {
        wall.breakSegment(adsorb.segment, point);
        wall.setAccessoriesNeedSave();
        this.wallBreakSegmentPointList.push(point);
      }
      currData.segment = wall.createSegment(point, currData.point);
      globalState.isDrawing = 'line';
    }
  }
  keyboard(event) {
    if (event.keyCode === 8 || event.keyCode === 46) {
      if (currData.wall && currData.point && this.wallSegmentStack.length) {
        let oldPoint = currData.point;
        // currData.wall.removePoint(oldPoint);

        let pointA = currData.segment.pointA;
        currData.wall.removeSegment(currData.segment);
        if (pointA.segments.length > 1) {
          currData.point = currData.wall.createPoint(currData.point);
          let seg = this.wallSegmentStack.pop();
          currData.segment = currData.wall.createSegment(seg.pointA, currData.point);
          currData.wall.removeSegment(seg);
          if (pointA.segments.length === 2 && this.wallBreakSegmentPointList.indexOf(pointA) >= 0) {
            currData.wall.joinSegment(pointA.segments[0], pointA.segments[1], pointA);
          }
        } else {
          currData.point = pointA;
          currData.segment = this.wallSegmentStack.pop();
        }
        this.updatePoint(oldPoint);
        return true;
      }
    }
  }
  updatePoint(pos, adsorb) {
    let segment = currData.segment;
    let dragData = globalState.dragData;
    let needUpdate = globalState.isDrawing || dragData && (dragData.type === 'breakControl' || dragData.type === 'arcControl');
    if (segment && needUpdate) {
      let pointA = segment.pointA;
      let shiftKey = adsorb && adsorb.event && adsorb.event.shiftKey;
      let adsorbdirection = shiftKey ? 90 : this.owner.settings.adsorbdirection;
      let directionToDegree = 180 / Math.PI;
      let degreeToDegree = Math.PI / 180;
      if (adsorb && adsorb.enableAdsorb) {
        if (adsorb.point) {
          if (!adsorb.segment) {
            pos.x = adsorb.point.x;
            pos.y = adsorb.point.y;
          } else {
            if (!adsorb.segment.isArc) {
              let dx = pos.x - pointA.x;
              let dy = pos.y - pointA.y;
              let r = Math.round(Math.atan2(dy, dx) * directionToDegree / adsorbdirection) * adsorbdirection * degreeToDegree;
              let point = lineIntersect(adsorb.segment.pointA.xz, adsorb.segment.pointB.xz, pointA, {
                x: pointA.x + Math.cos(r),
                y: pointA.y + Math.sin(r)
              });
              if (point) {
                pos.x = point.x;
                pos.y = point.y;
              } else {
                let d = Math.sqrt(dx * dx + dy * dy);
                pos.x = Math.round(Math.cos(r) * d * 10) * 0.1 + pointA.x;
                pos.y = Math.round(Math.sin(r) * d * 10) * 0.1 + pointA.y;
              }
            } else {
              pos.x = adsorb.point.x;
              pos.y = adsorb.point.y;
            }
          }
        } else if (adsorb.pointX && adsorb.pointY) {
          pos.x = adsorb.pointX.x;
          pos.y = adsorb.pointY.y;
        } else if (adsorb.pointX) {
          pos.x = adsorb.pointX.x;
          let dx = pos.x - pointA.x;
          let dy = pos.y - pointA.y;
          let r = Math.round(Math.atan2(dy, dx) * directionToDegree / adsorbdirection) * adsorbdirection * degreeToDegree;
          pos.y = (r / Math.PI + 2) % 1 === 0.5 ? pos.y : dx * Math.tan(r) + pointA.y;
        } else if (adsorb.pointY) {
          pos.y = adsorb.pointY.y;
          let dx = pos.x - pointA.x;
          let dy = pos.y - pointA.y;
          let r = Math.round(Math.atan2(dy, dx) * directionToDegree / adsorbdirection) * adsorbdirection * degreeToDegree;
          pos.x = (r / Math.PI + 2) % 1 === 0 ? pos.x : dy / Math.tan(r) + pointA.x;
        } else {
          let dx = pos.x - pointA.x;
          let dy = pos.y - pointA.y;
          let r = Math.round(Math.atan2(dy, dx) * directionToDegree / adsorbdirection) * adsorbdirection * degreeToDegree;
          if (shiftKey) {
            if (Math.abs(dx) < Math.abs(dy)) {
              pos.y = (r / Math.PI + 2) % 1 === 0.5 ? pos.y : dx * Math.tan(r) + pointA.y;
            } else {
              pos.x = (r / Math.PI + 2) % 1 === 0 ? pos.x : dy / Math.tan(r) + pointA.x;
            }
          } else {
            let d = Math.sqrt(dx * dx + dy * dy);
            pos.x = Math.round(Math.cos(r) * d * 10) * 0.1 + pointA.x;
            pos.y = Math.round(Math.sin(r) * d * 10) * 0.1 + pointA.y;
          }
        }
        if (shiftKey) {
          if (Math.abs(pos.x - pointA.x) < Math.abs(pos.y - pointA.y)) {
            pos.x = pointA.x;
          } else {
            pos.y = pointA.y;
          }
        }
      }
      pos.x = Math.floor(pos.x);
      pos.y = Math.floor(pos.y);
      if (globalState.drawingMode === 'arc') {
        let arcData = this._arcData;
        if (arcData.step === 0) {
          let dx = pos.x - arcData.center.x;
          let dy = pos.y - arcData.center.y;
          arcData.radius = Math.sqrt(dx * dx + dy * dy);
          currData.segment.radius = arcData.radius;
          currData.segment.makeDirty();
          currData.points[0].x = pos.x;
          currData.points[0].y = pos.y;
          currData.points[0].makeDirty();
          currData.points[1].x = pos.x;
          currData.points[1].y = pos.y;
          currData.points[1].makeDirty();
        } else if (arcData.step === 1) {
          arcData.angle = Math.PI;
          let angle0 = Math.atan2(currData.points[0].y - arcData.center.y, currData.points[0].x - arcData.center.x);
          let angle1 = Math.atan2(pos.y - arcData.center.y, pos.x - arcData.center.x);
          let PI2 = Math.PI * 2;
          let angle = (angle1 - angle0 - PI2) % PI2;
          currData.segment.angle = Math.abs(angle) < 0.00001 ? PI2 : angle;
          currData.segment.makeDirty();
          currData.points[1].x = Math.cos(angle1) * arcData.radius + arcData.center.x;
          currData.points[1].y = Math.sin(angle1) * arcData.radius + arcData.center.y;
          currData.points[1].makeDirty();
        }
      } else if (globalState.drawingMode === 'rect' && currData.points.length === 4) {
        currData.points[1].x = pos.x;
        currData.points[2].x = pos.x;
        currData.points[2].y = pos.y;
        currData.points[3].y = pos.y;
        currData.points[1].makeDirty();
        currData.points[2].makeDirty();
        currData.points[3].makeDirty();
      } else if (currData.point) {
        currData.point.x = pos.x;
        currData.point.y = pos.y;
        currData.point.makeDirty();
      } else if (dragData) {
        this.dragData = dragData;
        if (dragData.type === 'breakControl') {
          let point = currData.wall.createPoint(pos);
          let segs = segment.breakSegment(point);
          currData.point = point;
          currData.segment = segs[0];
          this.wallBreakSegmentPointList.push(point);
        } else if (dragData.type === 'arcControl') {
          let pointB = segment.pointB;
          let angleA = Math.atan2(pointA.y - pos.y, pointA.x - pos.x);
          let angleB = Math.atan2(pointB.y - pos.y, pointB.x - pos.x);
          let PI4 = Math.PI * 4;
          let angle = ((angleB - angleA) * 2 + PI4) % PI4 - Math.PI * 2;
          if (Math.abs(angle) < 0.015) {
            angle = 0;
          }
          segment.angle = angle;
          segment.makeDirty();
        }
      }
    }
  }
  pickPoint(pos, ray, picker) {
    let currPoints = currData.points;
    let walls = this.collection.walls;
    let d2 = picker.distance2;
    let point;
    let pickWallPoint = function (wall) {
      if (!wall || !wall.finalVisible) {
        return;
      }
      for (let p of wall.points) {
        if (currPoints.includes(p)) {
          continue;
        }
        let d2_ = Vector2.DistanceSquared(pos, p);
        if (d2 > d2_) {
          d2 = d2_;
          point = p;
        }
      }
    };
    pickWallPoint(currData.wall);
    for (let i = 0; i < walls.length; i++) {
      let wall = walls[i];
      if (wall === currData.wall) {
        continue;
      }
      pickWallPoint(wall);
    }
    if (d2 < picker.tolerant2 && point) {
      picker.distance2 = d2;
      picker.points.push(point);
      return point;
    }
  }
  pickVerticalPoint(pos, ray, picker) {
    let currSegments = currData.segments;
    let walls = this.collection.walls;
    let d2 = picker.distance2;
    let point;
    let segment;
    let segmentLocalPosition;
    let segmentFront;
    let pickWallVerticalPoint = function (wall) {
      if (!wall || !wall.finalVisible) {
        return;
      }
      let segments = wall.segments;
      for (let i = 0; i < segments.length; i++) {
        let s = segments[i];
        if (currSegments.includes(s)) {
          continue;
        }
        let p = s.isArc ? verticalArc2DPoint(s.center, s.radius, s.angle, s.directionA, pos) : verticalLine2DPoint(s.pointA, s.pointB, pos);
        if (p) {
          let d2_ = Vector2.DistanceSquared(pos, p);
          if (d2 > d2_) {
            d2 = d2_;
            point = p;
            segment = s;
            segmentLocalPosition = Math.sqrt(Math.pow(s.pointA.x - p.x, 2) + Math.pow(s.pointA.y - p.y, 2));
            segmentFront = s.normal.x * (pos.x - p.x) + s.normal.y * (pos.y - p.y) >= 0;
          }
        }
      }
    };
    pickWallVerticalPoint(currData.wall);
    for (let i = 0; i < walls.length; i++) {
      let wall = walls[i];
      if (wall === currData.wall) {
        continue;
      }
      pickWallVerticalPoint(wall);
    }
    if (d2 < picker.tolerant2 && point) {
      picker.distance2 = d2;
      picker.points.push(point);
      picker.segments.push(segment);
      picker.segmentFront = segmentFront;
      picker.segmentLocalPosition = segmentLocalPosition;
      return point;
    }
  }
  pickXYPoints(pos, ray, picker) {
    let currPoint = currData.point;
    let walls = this.collection.walls;
    let dx = picker.distanceX;
    let dy = picker.distanceY;
    let lx = picker.LengthX;
    let ly = picker.LengthY;
    let l_ = picker.tolerant;
    let pointX;
    let pointY;
    let pickWallXYPoints = function (wall) {
      if (!wall || !wall.finalVisible) {
        return;
      }
      for (let p of wall.points) {
        if (p === currPoint) {
          continue;
        }
        let dx_ = Math.abs(pos.x - p.x);
        let dy_ = Math.abs(pos.y - p.y);
        if (dx > dx_ || Math.abs(dx - dx_) < l_ && lx > dy_) {
          dx = dx_;
          lx = dy_;
          pointX = p;
        }
        if (dy > dy_ || Math.abs(dy - dy_) < l_ && ly > dx_) {
          dy = dy_;
          ly = dx_;
          pointY = p;
        }
      }
    };
    pickWallXYPoints(currData.wall);
    for (let i = 0; i < walls.length; i++) {
      let wall = walls[i];
      if (wall === currData.wall) {
        continue;
      }
      pickWallXYPoints(wall);
    }
    if (dx < picker.tolerant && pointX) {
      picker.pointX = pointX;
      picker.distanceX = dx;
      picker.LengthX = lx;
    }
    if (dy < picker.tolerant && pointY) {
      picker.pointY = pointY;
      picker.distanceY = dy;
      picker.LengthY = ly;
    }
  }
  pickByAreaBox(pos, ray, picker) {
    let walls = this.collection.walls;
    let pos0 = picker.pos0;
    let pos1 = pos;
    let x0 = Math.min(pos0.x, pos1.x);
    let x1 = Math.max(pos0.x, pos1.x);
    let y0 = Math.min(pos0.y, pos1.y);
    let y1 = Math.max(pos0.y, pos1.y);
    for (let wall of walls) {
      if (wall.isDeleted || !wall.finalVisible) {
        continue;
      }
      let bbox = wall.boundingBox;
      if (bbox && bbox[0] <= x1 && bbox[2] <= y1 && bbox[3] >= x0 && bbox[5] >= y0) {
        for (let seg of wall.segments) {
          if (!seg.isArc) {
            if (intersectCheckSegmentAndBox(seg.pointA, seg.pointB, x0, y0, x1, y1)) {
              picker.objs.push(wall);
              break;
            }
          } else {
            let radius = seg.radius * Math.sign(seg.angle);
            let center = seg.center;
            let startAngle = seg.directionA;
            let tessellation = seg.tessellation;
            let step = seg.stepAngle;
            if ((center.x - seg.radius < x1 || center.x + seg.radius > x0) && (center.y - seg.radius < y1 || center.y + seg.radius > y0)) {
              let p0 = {
                x: 0,
                y: 0
              };
              let p1 = {
                x: seg.pointA.x,
                y: seg.pointA.y
              };
              let isMatch = false;
              // arc
              for (let j = 1; j < tessellation + 2; j++) {
                let angle = startAngle + step * j;
                let cos_ = Math.cos(angle);
                let sin_ = Math.sin(angle);
                p1.x = center.x + radius * cos_;
                p1.y = center.y + radius * sin_;
                if (intersectCheckSegmentAndBox(p0, p1, x0, y0, x1, y1)) {
                  isMatch = true;
                  break;
                }
              }
              if (isMatch) {
                picker.objs.push(wall);
                break;
              }
            }
          }
        }
      }
    }
  }
  pickPointByAreaBox(pos, ray, picker) {
    let wall = currData.currList[0];
    if (!wall || wall.type !== 'wall' || wall.isDeleted || !wall.finalVisible) {
      return;
    }
    let pos0 = picker.pos0;
    let pos1 = pos;
    let x0 = Math.min(pos0.x, pos1.x);
    let x1 = Math.max(pos0.x, pos1.x);
    let y0 = Math.min(pos0.y, pos1.y);
    let y1 = Math.max(pos0.y, pos1.y);
    let bbox = wall.boundingBox;
    if (bbox && bbox[0] <= x1 && bbox[2] <= y1 && bbox[3] >= x0 && bbox[5] >= y0) {
      for (let p of wall.points) {
        if (p.x >= x0 && p.x <= x1 && p.y >= y0 && p.y <= y1) {
          picker.points.push(p);
        }
      }
    }
  }
  pickSegmentByAreaBox(pos, ray, picker) {
    let wall = currData.currList[0];
    if (!wall || wall.type !== 'wall' || wall.isDeleted || !wall.finalVisible) {
      return;
    }
    let pos0 = picker.pos0;
    let pos1 = pos;
    let x0 = Math.min(pos0.x, pos1.x);
    let x1 = Math.max(pos0.x, pos1.x);
    let y0 = Math.min(pos0.y, pos1.y);
    let y1 = Math.max(pos0.y, pos1.y);
    let bbox = wall.boundingBox;
    if (bbox && bbox[0] <= x1 && bbox[2] <= y1 && bbox[3] >= x0 && bbox[5] >= y0) {
      for (let seg of wall.segments) {
        if (!seg.isArc) {
          if (intersectCheckSegmentAndBox(seg.pointA, seg.pointB, x0, y0, x1, y1)) {
            picker.segments.push(seg);
          }
        } else {
          let radius = seg.radius * Math.sign(seg.angle);
          let center = seg.center;
          let startAngle = seg.directionA;
          let tessellation = seg.tessellation;
          let step = seg.stepAngle;
          if ((center.x - seg.radius < x1 || center.x + seg.radius > x0) && (center.y - seg.radius < y1 || center.y + seg.radius > y0)) {
            let p0 = {
              x: 0,
              y: 0
            };
            let p1 = {
              x: seg.pointA.x,
              y: seg.pointA.y
            };
            // arc
            for (let j = 1; j < tessellation + 2; j++) {
              let angle = startAngle + step * j;
              let cos_ = Math.cos(angle);
              let sin_ = Math.sin(angle);
              p0.x = p1.x;
              p0.y = p1.y;
              p1.x = center.x + radius * cos_;
              p1.y = center.y + radius * sin_;
              if (intersectCheckSegmentAndBox(p0, p1, x0, y0, x1, y1)) {
                picker.segments.push(seg);
                break;
              }
            }
          }
        }
      }
    }
  }
  pickObject(pos, ray, picker) {
    if (picker.objs.length) {
      return;
    }
    let walls = this.collection.walls;
    let meshBuilder = window.sm2DMeshBuilder;
    let pickWall = function (wall) {
      if (!wall || !wall.finalVisible || !meshBuilder.objects[wall.nanoId]) {
        return;
      }
      let meshes = meshBuilder.objects[wall.nanoId].pickMeshes;
      if (meshes) {
        for (let i = 0; i < meshes.length; i++) {
          meshes[i]._updateBoundingInfo();
          let pickingInfo = ray.intersectsMesh(meshes[i]);
          if (pickingInfo.pickedMesh) {
            return wall;
          }
        }
      }
    };
    let res = pickWall(currData.wall);
    if (res) {
      this.pickVerticalPoint(pos, ray, picker);
      picker.objs.push(res);
      return picker;
    }
    for (let i = 0; i < walls.length; i++) {
      let wall = walls[i];
      if (wall === currData.wall) {
        continue;
      }
      res = pickWall(wall);
      if (res) {
        this.pickVerticalPoint(pos, ray, picker);
        picker.objs.push(res);
        return picker;
      }
    }
  }
}
export { SmWallCreator };