import { computed } from 'vue';
import numInput from "./numInput";
import { closeMenu, openMenu } from "@/components/widgets/contextmenu/contextmenu2";
import { smDatas } from "@/model/smCollectionData";
import { globalState, currData } from "@/model/globalData";
export default {
  name: "vertexCoordinatesProp",
  components: {
    numInput
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  setup(props) {
    let vertexList = computed(() => {
      return props.data ? props.data.points : [];
    });
    const handleClickVertex = function (item) {
      currData.point = item;
    };
    const doubleClickVertex = function (item) {
      currData.point = item;
      window.sm3DMeshBuilder.focusOnPoint();
      window.sm2DMeshBuilder.focusOnPoint();
    };
    const handleContextmenu = function (event, point) {
      openMenu(event, {
        menu: [{
          label: Kf.t('Delete'),
          type: 'delete'
        }],
        fun: function (event, item) {
          if (item.type === 'delete') {
            props.data.removePoint(point);
            props.data.setNeedSave();
            if (props.data.type === 'area') {
              currData.modeArea = currData.area.points.length > 1 ? 'point' : 'polygon';
            }
            closeMenu();
          }
        }
      });
    };
    const doInputContent = function (val, coordinate) {
      currData.point[coordinate] = Math.round(parseFloat(val) * 10);
      currData.point.makeDirty();
    };
    return {
      vertexList,
      handleClickVertex,
      doubleClickVertex,
      smDatas,
      currData,
      doInputContent,
      handleContextmenu
    };
  }
};