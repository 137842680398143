import _defineProperty from "F:/workspace/\u7A7A\u95F4\u6CBB\u7406\u5DE5\u5177/\u7A7A\u95F4\u6CBB\u7406CAD/space-management/spaceManager/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { Drawing } from "./smDrawing";
import { registerSMType, serializeObject, updateProperties } from "./smObject";
class ImageDrawing extends Drawing {
  get url() {
    return this._url;
  }
  set url(value) {
    if (this._url === value) {
      return;
    }
    this._url = value;
    this.makeDirty();
  }
  get invertColor() {
    return this._invertColor;
  }
  set invertColor(value) {
    if (this._invertColor === value) {
      return;
    }
    this._invertColor = value;
    this.makeDirty();
  }
  get forceVisible() {
    return this._forceVisible;
  }
  set forceVisible(value) {
    if (this._forceVisible === value) {
      return;
    }
    this._forceVisible = value;
    this.makeDirty();
  }
  get amount() {
    return this._amount;
  }
  set amount(value) {
    if (this._amount === value) {
      return;
    }
    this._amount = value;
    this.makeDirty();
  }
  get mode() {
    return this._mode;
  }
  set mode(value) {
    if (this._mode === value) {
      return;
    }
    this._mode = value;
    this.makeDirty();
  }
  constructor(collection, options) {
    super(collection, options);
    _defineProperty(this, "_url", void 0);
    _defineProperty(this, "_forceVisible", false);
    _defineProperty(this, "_invertColor", false);
    _defineProperty(this, "_amount", 1);
    _defineProperty(this, "_mode", 'add');
    this.type = 'imageDrawing';

    // this.url = '/textures/FHcy7HTxrxdPFticeryH7.jpeg';
    // this.opacity = 1;
  }
  updateProperties(data) {
    let changed = super.updateProperties(data);
    if (data) {
      let properties = data.properties;
      if (properties) {
        changed |= updateProperties(this, ['url', 'amount', 'invertColor', 'mode', 'forceVisible'], properties);
      }
    }
    if (changed) {
      this.makeDirty();
    }
    return changed;
  }
  serialize() {
    let res = super.serialize();
    if (!res.properties) {
      res.properties = {};
    }
    serializeObject(this, ['url', 'amount', 'invertColor', 'mode', 'forceVisible'], res.properties);
    return res;
  }
}
export { ImageDrawing };