import _defineProperty from "F:/workspace/\u7A7A\u95F4\u6CBB\u7406\u5DE5\u5177/\u7A7A\u95F4\u6CBB\u7406CAD/space-management/spaceManager/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { SMDirtyAll, SMDirtyPosition, SMDirtyReticular } from "./smDirtyFlag";
import { getUID, registerSMType, serializeObject, updateProperties, SMObject, getSMType } from "./smObject";
class Point2D {
  set x(value) {
    this._x = Number(value);
  }
  get x() {
    return this._x;
  }
  set y(value) {
    this._y = Number(value);
  }
  get y() {
    return this._y;
  }
  get xz() {
    return this;
  }
  constructor(x, y) {
    _defineProperty(this, "_x", void 0);
    _defineProperty(this, "_y", void 0);
    Object.defineProperty(this, 'uid', {
      value: getUID()
    });
    this.x = x || 0;
    this.y = y || 0;
  }
  distance(b) {
    let dx = this.x - b.x;
    let dy = this.y - b.y;
    return Math.sqrt(dx * dx + dy * dy);
  }
  updateProperties(data) {
    if (!data) {
      return false;
    }
    let x = data[0] || 0;
    let y = data[1] || 0;
    if (x == this._x && y == this._y) {
      return false;
    }
    this.x = x;
    this.y = y;
    return true;
  }
  serialize() {
    return [this._x, this._y];
  }
  static Parse(data) {
    return new Point2D(data && data.x, data && data.y);
  }
}
registerSMType('Point2D', Point2D);
export { Point2D };