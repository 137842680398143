import "core-js/modules/es.array.push.js";
import { watch } from 'vue';
import { Vector2 } from "@/maths/vector";
import { undoMap } from "@/undo/undoMap";
import { SmCreatorTools } from "./smCreatorTools";
import { SmCreatorSettings } from "./smCreatorSettings";
import { SmWallCreator } from "./smWallCreator";
import { SmAreaCreator } from "./smAreaCreator";
import { SmPipelineCreator } from "./smPipelineCreator";
import { DrawingCreator } from "./smDrawingCreator";
import { ModelCreator } from "@/creator/smModelCreator";
import { globalState, currData } from "@/model/globalData";
import { registerGlobalMouseEvent } from "@/event/globalDom";
import { smDatas } from "@/model/smCollectionData";
import { isCtrlDown, isSpaceDown, isShiftDown } from "@/utils/keyboard";
import { getModelByName } from "@/model/3Dmodel";
class SmCreator {
  constructor(collection, scene) {
    this.collection = collection;
    this.scene = scene;
    this.settings = new SmCreatorSettings();
    this.tools = new SmCreatorTools(collection, scene);
    this.drawingCreator = new DrawingCreator(this);
    this.wallCreator = new SmWallCreator(this);
    this.areaCreator = new SmAreaCreator(this);
    this.pipelineCreator = new SmPipelineCreator(this);
    this.modelCreator = new ModelCreator(this);
    this.creators = [this.drawingCreator, this.modelCreator, this.wallCreator, this.areaCreator, this.pipelineCreator];
    registerGlobalMouseEvent('global.mouse', this.globalHandler.bind(this));
    registerGlobalMouseEvent('global.2d.mouse', this.globalHandler.bind(this));
    registerGlobalMouseEvent('global.keydown', this.globalHandler.bind(this));
    registerGlobalMouseEvent('global.keyup', this.globalHandler.bind(this));
    scene.onPointerObservable.add(p => {
      this.pointHandler(p);
    }, -1, true);

    // scene.onKeyboardObservable.add((p) => {
    //     this.keyboardHandler(p);
    // });

    let modeChanged = (n, o) => {
      let curr = [];
      let mode = globalState.mode;
      if (mode !== 'model') {
        if (currData[mode]) {
          curr.push(currData[mode]);
        }
      } else {
        curr.push(...Object.values(currData.models));
      }
      currData.setCurrList(curr);
      this.invokeCreators('modeChanged', n, o);
      this.tools.enableCalibrateCenter(globalState.mode === 'drawing' && globalState.modeArgs && globalState.modeArgs[0] === 'calibrateCenter');
      this.tools.enableCalibrateSize(globalState.mode === 'drawing' && globalState.modeArgs && globalState.modeArgs[0] === 'calibrateSize');
    };
    watch(() => globalState.mode, modeChanged);
    watch(() => globalState.modeArgs, (n, o) => {
      this.tools.enableCalibrateCenter(globalState.mode === 'drawing' && globalState.modeArgs && globalState.modeArgs[0] === 'calibrateCenter');
      this.tools.enableCalibrateSize(globalState.mode === 'drawing' && globalState.modeArgs && globalState.modeArgs[0] === 'calibrateSize');
    });
  }
  invokeCurrCreator() {
    let creator;
    let args = [];
    let func = arguments[0];
    for (let i = 1; i < arguments.length; i++) {
      args.push(arguments[i]);
    }
    switch (globalState.mode2 ? globalState.mode2 : globalState.mode) {
      case "wall":
        creator = this.wallCreator;
        break;
      case "area":
        creator = this.areaCreator;
        break;
      case "pipeline":
        creator = this.pipelineCreator;
        break;
      case "drawing":
        creator = this.drawingCreator;
        break;
      case "model":
      case "devicePoint":
        creator = this.modelCreator;
        break;
    }
    if (creator && creator[func]) {
      let typeViews = globalState.typeViews;
      if (typeViews[creator.type.substring(0, creator.type.length - 'creator'.length)]) {
        creator[func](...args);
      }
    }
  }
  invokeCreators() {
    let args = [];
    let func = arguments[0];
    for (let i = 1; i < arguments.length; i++) {
      args.push(arguments[i]);
    }
    let typeViews = globalState.typeViews;
    let creators = this.creators;
    for (let i = 0; i < creators.length; i++) {
      let creator = creators[i];
      if (creator[func]) {
        if (typeViews[creator.type.substring(0, creator.type.length - 'creator'.length)]) {
          creator[func](...args);
        }
      }
    }
  }
  keyboardHandler(keyboardInfo) {
    if (!smDatas.building || !smDatas.building.nanoId) {
      return;
    }
    if (keyboardInfo.event.type === 'keydown') {
      this.invokeCreators('keyboard', keyboardInfo.event);
    }
    this.updateText();
  }
  pointHandler(pointerInfo) {
    if (!smDatas.building || !smDatas.building.nanoId) {
      return;
    }
    let ray = pointerInfo.pickInfo.ray;
    let k = ray.intersectsPlane(this.scene.activeCamera.plane);
    globalState.mouse.in2dCanvas = true;
    if (k) {
      let res = false;
      let pos = new Vector2(Math.round((ray.origin.x + k * ray.direction.x) * 10) * 0.1, Math.round((ray.origin.z + k * ray.direction.z) * 10) * 0.1);
      this.tools.mouseMove(pos, pointerInfo.event);

      // currData.ray = ray;
      //
      // currData.pos = pos;
      // currData.pointerInfo = pointerInfo;

      // console.log(pointerInfo.type);
      if (globalState.dragObject) {
        if (globalState.dragPosOffset) {
          pos.x += globalState.dragPosOffset.x;
          pos.y += globalState.dragPosOffset.y;
        }
      }
      let picker = this.pickPos(pos, ray);
      picker.event = pointerInfo.event;
      picker.point = picker.points[0];
      picker.segment = picker.segments[0];
      switch (pointerInfo.type) {
        case window.Kingfisher.PointerEventTypes.POINTERTAP:
          if (pointerInfo.event.button === 0) {
            res = this.mouseTap(picker, pos, ray);
          } else if (pointerInfo.event.button === 2) {
            res = this.mouseRelease(picker, pos, ray);
          }
          break;
        case window.Kingfisher.PointerEventTypes.POINTERDOUBLETAP:
          if (pointerInfo.event.button === 0) {
            res = this.mouseDoubleTap(picker, pos, ray);
          }
          break;
        case window.Kingfisher.PointerEventTypes.POINTERDOWN:
          if (pointerInfo.event.button === 0) {
            res = this.invokeCreators('pickObject', pos, ray, picker);
            let pickedMesh = pointerInfo.pickInfo.pickedMesh;
            let pickedObj = picker.objs[0];
            if (pickedObj && (globalState.mode === 'model' || globalState.mode === 'devicePoint') && currData.currList.indexOf(pickedObj) >= 0) {
              pointerInfo.pickInfo.userUsed = true;
              globalState.dragObject = pickedObj;
              globalState.dragPosOffset = new Vector2(pickedObj.position.x - pos.x, pickedObj.position.z - pos.y);
              globalState.dragWaitClone = pointerInfo.event.ctrlKey;
              globalState.dragCloned = false;
            } else if (scene._lineControl === pickedMesh) {
              pointerInfo.pickInfo.userUsed = true;
              globalState.dragData = {
                type: isCtrlDown.value ? 'arcControl' : 'breakControl',
                position: pos
              };
            }
          }
          res = this.mouseDown(picker, pos, ray);
          break;
        case window.Kingfisher.PointerEventTypes.POINTERUP:
          globalState.dragWaitClone = false;
          globalState.dragCloned = false;
          res = this.mouseUp(picker, pos, ray);
          if (globalState.dragObject) {
            globalState.dragObject = null;
          }
          if (globalState.dragData) {
            globalState.dragData = null;
          }
          break;
        case window.Kingfisher.PointerEventTypes.POINTERMOVE:
          let now = Date.now();
          let lastTime = this.lastMoveTime;
          if (lastTime && now - lastTime < 30) {
            return;
          }
          this.lastMoveTime = now;
          res = this.mouseMove(picker, pos, ray);
          break;
      }

      // currData.ray = null;
      // currData.pos = null;
      // currData.pointerInfo = null;

      this.updateText();
    }
  }
  globalHandler(nameSpace, event) {
    if (!smDatas.building || !smDatas.building.nanoId) {
      return;
    }
    if (nameSpace === 'global.mouse') {
      switch (event.type) {
        case 'mousedown':
          {
            this.invokeCreators('globalMousedown', event);
          }
          break;
        case 'mouseleave':
          {
            globalState.mouse.in2dCanvas = false;
            this.invokeCreators('globalMouseleave', event);
          }
          break;
        case 'mouseup':
          {
            this.invokeCreators('globalMouseup', event);
          }
          break;
      }
    } else if (nameSpace === 'global.2d.mouse') {
      switch (event.type) {
        case 'mousedown':
          {
            this.invokeCreators('_2dMouseDown', event);
          }
          break;
        case 'mouseleave':
          {
            globalState.mouse.in2dCanvas = false;
            this.invokeCreators('_2dMouseLeave', event);
          }
          break;
        case 'mouseup':
          {
            // 移除拖拽绑定
            // let dragData = globalState.dragData;
            // if (dragData?.type === 'devicePoint'
            //     && currData.currList.length > 0){
            //     for (let model of currData.currList) {
            //         if (model.deviceId !== dragData.deviceId) {
            //             if (model.deviceId) {
            //                 let obj = smDatas.devicePoints.filter(x => x.deviceId === model.deviceId)[0];
            //                 if (obj) {
            //                     obj.deviceModelList.delete(model.nanoId);
            //                 }
            //             }
            //             dragData.deviceModelList.add(model.nanoId);
            //             model.deviceId = dragData.deviceId;
            //         }
            //         if (dragData.deviceName && dragData.deviceName !== 'null') {
            //             model.name = dragData.deviceName;
            //         }
            //         model.customId = dragData.deviceMapId;
            //         model.setNeedSave();
            //     }
            // }
          }
          break;
      }
    } else if (nameSpace === 'global.keydown') {
      if (globalState.mouse.in2dCanvas) {
        this.invokeCreators('keyboard', event);
        this.updateText();
      }
    }
  }
  mouseRelease(picker, pos, ray) {
    this.invokeCurrCreator('mouseRelease', pos, ray);
    currData.point = null;
    if (currData.undoData) {
      if (currData.undoData.cmd !== 'updateObj' || currData.undoData.target && JSON.stringify(currData.undoData.value) !== JSON.stringify(currData.undoData.target.serialize())) {
        // 有变化
        undoMap.push(currData.undoData);
      }
      currData.undoData = null;
    }
    this.updateText();
  }
  updateText() {
    this.invokeCurrCreator('updateText');
  }
  afterPicked(mode, picker) {
    let shiftKey = event.shiftKey;
    let res = [];
    let temp = [];
    if (mode === 'object') {
      res = picker.objs;
      temp = this.currListTemp;
    } else if (mode === 'point') {
      res = picker.points;
      temp = this.pointsTemp;
    } else if (mode === 'line') {
      res = picker.segments;
      temp = this.segmentsTemp;
    }
    if (shiftKey) {
      temp = temp.slice();
      for (let o of res) {
        let index = temp.indexOf(o);
        if (index === -1) {
          temp.push(o);
        } else {
          temp.splice(index, 1);
        }
      }
      res = temp;
    }
    if (mode === 'object') {
      currData.setCurrList(res);
    } else if (mode === 'point') {
      currData.points = res;
    } else if (mode === 'line') {
      currData.segments = res;
    }
  }
  mouseTap(picker, pos, ray) {
    let event = picker.event;
    if (globalState.operation === 'pick') {
      // picker = this._createPicker();
      let mode = 'object';
      let func = 'pickObject';
      if (currData.currList.length && (globalState.mode === 'wall' || globalState.mode === 'area' || globalState.mode === 'pipeline')) {
        if (globalState.drawingMode === 'point') {
          func = 'pickPoint';
          mode = 'point';
        } else if (globalState.drawingMode === 'line') {
          func = 'pickSegment';
          mode = 'line';
        }
      }
      this.invokeCurrCreator(func, pos, ray, picker);
      this.afterPicked(mode, picker);
    } else if (globalState.operation === 'draw') {
      this.updateText();
      this.invokeCurrCreator('mouseTap', pos, ray, picker);
    }
  }
  mouseDoubleTap(picker, pos, ray) {
    if (globalState.operation === 'pick') {
      // picker = this._createPicker();
      let res = [];
      this.invokeCreators('pickObject', pos, ray, picker);
      let obj = picker.objs[0];
      if (obj) {
        if (globalState.mode !== obj.type) {
          globalState.mode = obj.type;
          currData.setCurrList([obj]);
          currData.segment = picker.segment ? picker.segment : null;
        } else {
          // globalState.operation = 'draw';
        }
      } else {
        currData.setCurrList([]);
      }
    } else {
      this.invokeCurrCreator('mouseDoubleTap', pos, ray, picker);
      this.updateText();
    }
  }
  mouseDown(picker, pos, ray) {
    this.invokeCurrCreator('mouseDown', pos, ray, picker);
    this.mouseDownPos = pos;
    this.currListTemp = [...currData.currList];
    this.pointsTemp = [...currData.points];
    this.segmentsTemp = [...currData.segments];
  }
  mouseUp(picker, pos, ray) {
    this.invokeCurrCreator('mouseUp', pos, ray, picker);
    this.mouseDownPos = null;
    this.tools.updateAreaBox();
  }
  mouseMove(picker, pos, ray) {
    let event = picker.event;
    if (globalState.dragObject || globalState.dragData && globalState.dragData.type === 'model') {
      this.modelCreator.mouseMove(pos, ray, picker);
    } else if (globalState.dragData && globalState.dragData.type === 'devicePoint') {
      if (getModelByName(globalState.dragData.spaceComponentName)) {
        this.modelCreator.mouseMove(pos, ray, picker);
      }
    } else {
      this.invokeCurrCreator('mouseMove', pos, ray, picker);
      if (this.mouseDownPos && globalState.isPickMode && !globalState.dragCloned) {
        let func = 'pickByAreaBox';
        let mode = 'object';
        picker.objs = [];
        picker.points = [];
        picker.segments = [];
        picker.point = null;
        picker.segment = null;
        if (currData.currList.length && (globalState.mode === 'wall' || globalState.mode === 'area' || globalState.mode === 'pipeline')) {
          if (globalState.drawingMode === 'point') {
            func = 'pickPointByAreaBox';
            mode = 'point';
          } else if (globalState.drawingMode === 'line') {
            func = 'pickSegmentByAreaBox';
            mode = 'line';
          }
        }
        this.invokeCurrCreator(func, pos, ray, picker);
        this.tools.updateAreaBox(this.mouseDownPos, pos, ray, picker);
        this.afterPicked(mode, picker);
      }
    }
    this.updateText();
    this.tools.updateAdsorbLine(pos, ray, picker);
  }
  objectsMove(picker, pos, ray) {
    this.invokeCurrCreator('objectsMove', pos, ray, picker);
    this.updateText();
    this.tools.updateAdsorbLine(pos, ray, picker);
    if (this.mouseDownPos && isCtrlDown.value) {
      this.tools.updateAreaBox(this.mouseDownPos, pos, ray, picker);
    }
  }
  _createPicker() {
    let picker = {};
    picker.objs = [];
    picker.points = [];
    picker.segments = [];
    picker.enableAdsorb = true;
    picker.scale = 100 / this.scene.activeCamera.distance;
    picker.scale2 = picker.scale * picker.scale;
    picker.distance2 = 10000000000000000 * picker.scale2;
    picker.tolerant2 = 1 / picker.scale2;
    picker.distanceX = 1000000000000 * picker.scale;
    picker.distanceY = picker.distanceX;
    picker.tolerant = 0.6 / picker.scale;
    picker.enableAdsorb = globalState.enableAdsorbent && !isCtrlDown.value;
    picker.pos0 = this.mouseDownPos;

    // picker.curr = {
    //     objs: this.currListTemp,
    //     points: this.pointsTemp,
    //     segments: this.segmentsTemp,
    // };

    return picker;
  }
  pickPos(pos, ray) {
    let picker = this._createPicker();
    if (picker.enableAdsorb) {
      this.invokeCreators('pickPoint', pos, ray, picker);
      if (!picker.points.length) {
        this.invokeCreators('pickVerticalPoint', pos, ray, picker);
      }
      this.invokeCreators('pickXYPoints', pos, ray, picker);
    }
    return picker;
  }
}
export { SmCreator };