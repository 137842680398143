import KingfisherService from '../utils/request';
import FetchService from '../utils/fetch';
import Cookies from 'js-cookie';
import axios from 'axios';
class DxfService {
  /**
   * Creates an instance of DxfService.
   * @memberOf  DxfService
   */
  constructor() {
    this.module = 'dxf';
    this.kingfisherService = new KingfisherService({});
    this.fetchService = new FetchService({});
  }

  /**
   * 获取完整URL
   * @returns url
   */
  getUrl(url) {
    if (!url.startsWith("http")) {
      return this.kingfisherService.host + url;
    }
    return url;
  }

  /**
   * 上传dxf文件
   * @returns {jQuery|Deferred} Ajax请求返回并处理后的Promise
   */
  uploadDXF(projectId, file) {
    const fd = new FormData();
    fd.append('file', file, file.name);
    fd.append('projectId', projectId);
    const header = {
      'Accept': '*/*',
      'Content-Type': 'multipart/form-data;'
    };
    return this.kingfisherService.post("/repo/kf-cad-kabeja/uploadDXF", fd, header).then(response => {
      return response;
    });
  }

  /**
   * 查询所有文件
   * @returns {jQuery|Deferred} Ajax请求返回并处理后的Promise
   */
  getAllDxfFile(projectId) {
    return this.kingfisherService.get(`/repo/kf-cad-kabeja/getAllDxfFile/${projectId}`).then(response => {
      return response;
    });
  }

  /**
   * 根据文件id查询layer统计信息
   * @returns {jQuery|Deferred} Ajax请求返回并处理后的Promise
   */
  getLayerInfo(fileId) {
    return this.kingfisherService.get(`/repo/kf-cad-kabeja/getLayerInfo/${fileId}`).then(response => {
      return response;
    });
  }

  /**
   * 查询layer数据
   * @returns {jQuery|Deferred} Ajax请求返回并处理后的Promise
   */
  getLayerByNameOrType(params) {
    return this.kingfisherService.post(`/repo/kf-cad-kabeja/getLayerByNameOrType`, params).then(response => {
      return response;
    });
  }

  /**
   * dwg转换为dxf
   * @returns {jQuery|Deferred} Ajax请求返回并处理后的Promise
   */
  dwg2dxf(formData) {
    return axios.post(`/cadapi/dwg2dxf`, formData, {
      responseType: 'blob'
    }).then(response => {
      return response;
    });
  }
}
export default new DxfService();