import "core-js/modules/es.array.push.js";
import { ref, watch, computed, nextTick } from 'vue';
import { smDatas } from "@/model/smCollectionData";
import { currData, globalState, loadDxf } from "@/model/globalData";
import { wallOptions, pipelineOptions } from "@/model/purposeOptions";
import { getObjectName, getFloorName, getObjectPurpose, showContextMenu, setProperty, doItemClick, doItemDBClick, loadBodyScrollTop, getObjectHight, getObjectOpacity, drawingFileUpload, drawingFile, uploadDrawingMode, doObjectMenuClick } from "../index.js";
import { importAsset } from "@/utils/importAssets";
import DxfService from "@/service/dxf";
export default {
  name: "KfList",
  props: {
    type: {
      type: String,
      default: 'floor'
    },
    dataKey: {
      type: String,
      default: 'floor'
    },
    list: {
      type: Array,
      default: () => {
        return [];
      }
    },
    isFilter: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    let isActive = function (item) {
      return currData.currListMap.has(item);
    };
    let currFilterType = ref('all');
    let visibleObjs = computed(() => {
      let res = [];
      // console.log("-visibleObjs--",smDatas);
      let _list = smDatas[props.dataKey] && smDatas[props.dataKey].length > 0 ? smDatas[props.dataKey].filter(x => !x.isDeleted && x.floorVisible) : [];
      if (_list.length > 0) {
        if (currFilterType.value === 'all') {
          res = _list;
        } else {
          res = _list.filter(x => currFilterType.value === x.type);
        }
      }
      return res;
    });
    const getTdValue = function (data, item) {
      if (item.td === 'name') {
        return getObjectName(data);
      } else if (item.td === 'purpose') {
        return getObjectPurpose(data);
      } else if (item.td === 'opacity') {
        return getObjectOpacity(data);
      } else if (item.td === 'height' || item.td === 'elevation') {
        return getObjectHight(data, item.td);
      } else if (item.td === 'diameter') {
        return (data[item.td] * 0.1).toFixed(1);
      } else if (item.td === 'area') {
        return (data[item.td] * 0.000001).toFixed(2);
      } else {
        return data[item.td];
      }
    };
    let bodyManager = ref(null);
    watch(() => currData.currListFlag, () => {
      loadBodyScrollTop(bodyManager, props.dataKey, props.type);
    });
    const switchValue = function (data, item) {
      if (item) {
        if (item.tdEventType === 'switch') {
          setProperty(data, item.td, !data[item.td]);
        } else if (item.tdEventType === 'focus') {
          nextTick(() => {
            let args = {
              minDistance: 4000
            };
            let args3d = Object.assign({}, args);
            if (args3d.minDistance) {
              args3d.minDistance *= 0.001;
            }
            window.sm3DMeshBuilder.focusOnObject(args3d);
            window.sm2DMeshBuilder.focusOnObject(args);
          });
        }
      }
    };
    const filterList = computed(() => {
      let res = [{
        value: 'all',
        label: Kf.t('All')
      }];
      if (props) {
        if (props.type === 'wall' && wallOptions.length > 0) {
          res = res.concat(wallOptions);
        } else if (props.type === 'pipeline' && pipelineOptions.length > 0) {
          res = res.concat(pipelineOptions);
        } else if (props.type === 'drawing') {
          res.push({
            label: 'CAD',
            value: 'dxfDrawing'
          });
          res.push({
            label: Kf.t('Picture'),
            value: 'imageDrawing'
          });
        }
      }
      return res;
    });
    let isCreateNewFile = false;
    let getFileType = function () {
      if (!currData.drawing || isCreateNewFile) {
        return '.png,.jpg,.jpeg,.tag,.bmp,.dds,.webp,.dxf';
      }
      return currData.drawing.type === 'dxfDrawing' ? '.dxf' : '.png,.jpg,.jpeg,.tag,.bmp,.dds,.webp';
    };
    let createDrawingFile = function () {
      isCreateNewFile = true;
      drawingFileUpload.value.click();
    };
    let fileUploaded = function (e) {
      let files = event.target.files;
      let file = drawingFile;
      if (!files || !files.length) {
        return;
      }
      file.value = files[0];
      let reader = new FileReader(); //读取文件

      reader.onload = e => {
        if (uploadDrawingMode.value !== 'update') {
          let name = file.value.name.toLowerCase();
          doObjectMenuClick({
            label: Kf.t('New'),
            key: 'create'
          }, name.endsWith('.dwg') || name.endsWith('.dxf') ? 'dxfDrawing' : 'drawing');
        }
        let uploadDrawing = currData.drawing;
        if (!uploadDrawing) {
          return;
        }
        if (file.value.name) {
          uploadDrawing.name = file.value.name;
        }
        if (uploadDrawing.type === 'dxfDrawing') {
          let updateDxf = data => {
            undoMap.push({
              cmd: 'updateDxf',
              target: uploadDrawing,
              value: uploadDrawing.url
            });
            let url = importAsset({
              data,
              dir: '/dxfs',
              type: 'dxf'
            });
            uploadDrawing.url = url + '.dxf';
            uploadDrawing.needSave = true;
            loadDxf(uploadDrawing, url, dxf => {
              uploadDrawing.makeDirty();
            });
          };
          if (file.value.name.toLowerCase().endsWith('.dxf')) {
            updateDxf(e.target['result']);
          } else {
            let formData = new FormData();
            formData.append("files", file.value);
            uploadDrawing.loadingState = 1;
            uploadDrawing.loadingProgress = 0;
            DxfService.dwg2dxf(formData).then(res => {
              if (res.status == 200) {
                let reader = new FileReader();
                reader.onload = e => {
                  console.log(e.target.result);
                  updateDxf(e.target.result);
                };
                reader.readAsArrayBuffer(res.data);
              } else {
                console.error('failed to convert dwg to dxf');
                console.error(res.message);
              }
            }).catch(err => {
              uploadDrawing.loadingState = 3;
              uploadDrawing.loadingProgress = 1;
              console.error('failed to convert dwg to dxf');
              console.error(err);
            });
          }
        } else {
          undoMap.push({
            cmd: 'updateImage',
            target: uploadDrawing,
            value: uploadDrawing.url
          });
          let url = importAsset({
            data: e.target['result'],
            dir: '/textures',
            type: file.value.type
          });
          uploadDrawing.url = url;
          uploadDrawing.needSave = true;
        }
        drawingFileUpload.value.value = null;
        uploadDrawingMode.value = '';
      };
      reader.onerror = () => {
        uploadDrawingMode.value = '';
      };
      reader.readAsArrayBuffer(file.value);
    };
    let pictureFileUpload = ref(null);
    let createPictureFile = function () {
      drawingFileUpload.value.click();
    };
    let pictureUploaded = function (e) {
      let files = event.target.files;
      if (!files || !files.length) {
        return;
      }
      console.log("--pictureUploaded", files);
      //todo picture upload
    };
    const handleClickFloor = function (menu) {
      doObjectMenuClick({
        key: menu
      }, 'floor');
      // todo  上  下
    };
    return {
      isActive,
      visibleObjs,
      currFilterType,
      getTdValue,
      bodyManager,
      doItemClick,
      doItemDBClick,
      showContextMenu,
      switchValue,
      filterList,
      createDrawingFile,
      fileUploaded,
      getFileType,
      pictureUploaded,
      pictureFileUpload,
      createPictureFile,
      drawingFileUpload,
      handleClickFloor
    };
  }
};