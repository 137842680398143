import {
    Vector2,
    distanceSquaredPointToSegment,
    intersectCheckSegmentAndBox,
    intersectCheckLineLine,
    intersectCheckPointAndBox,
    intersectCheckPointAndPolygon
} from "@/maths/vector";
import {undoMap} from "@/undo/undoMap";
import {globalState, currData} from "@/model/globalData";
import {smDatas} from "@/model/smCollectionData";
// import {isPick} from "@/model/globalData";


class SmAreaCreator {
    constructor(creator) {
        this.type = 'areaCreator';
        this.owner = creator;
        this.collection = creator.collection;
        this.tools = creator.tools;
        this.pointStack = [];
        this.modeArea = '';
    }

    mouseRelease(pos, ray) {
        if (currData.area && globalState.isDrawing) {
            if (globalState.isDrawing === 'arc' || globalState.isDrawing === 'rect' || currData.area.points.length < 3) {
                currData.area.cleanPoint();
            }
            else if (currData.point) {
                currData.area.removePoint(currData.point);
            }
            currData.area.updateArea();
            currData.point = null;
            currData.segment = null;
        }
        this.pointStack.length = 0;
        globalState.isDrawing = '';
        this.modeArea = '';
    }

    modeChanged() {
        this.mouseRelease();
    }

    updateText() {
        if (globalState.isDrawing && currData.point && currData.point) {
            this.tools.updateTextDegree(currData.point, 0,
                (Math.atan2(currData.point.y - currData.point.y, currData.point.x - currData.point.x) * 180 / Math.PI).toFixed(1) + '°');
            this.tools.updateTextLength(currData.point, 0,
                Vector2.Distance(currData.point, currData.point).toFixed() + 'cm');
        } else {
            this.tools.updateTextDegree();
            this.tools.updateTextLength();
        }
    }

    mouseTap(pos, ray, adsorb) {
        // if (isPick()) {
        //     let area = this.pickObject(pos, ray, adsorb);
        //     if (area) {
        //         currData.area = area;
        //     }
        //     return;
        // }
        if (!currData.area) {
            let area = this.pickObject(pos, ray, adsorb);
            if (area) {
                currData.area = area;
                return;
            }

            if (currData.floor && !currData.floor.visible) {
                return;
            }

            if (!currData.floor) {
                let floor = smDatas.createFloor();
                floor.setNeedSave();
                currData.floor = floor;
            }
            area = this.collection.createArea();
            area.floor = currData.floor.nanoId;

            area.setNeedSave();
            undoMap.push({
                cmd: 'create',
                value: area,
            });

            currData.area = area;
        }

        if (currData.area && currData.area.finalVisible && !currData.area.locked) {
            if (globalState.drawingMode === 'arc' && globalState.isDrawing) {
                if (this._arcData.step === 0) {
                    this._arcData.step = 1;
                }
                else if (this._arcData.step === 1) {
                    this._arcData = null;
                    currData.points = [];
                    currData.segments = [];
                    globalState.isDrawing = '';
                }
            }
            else if (globalState.drawingMode === 'rect' && globalState.isDrawing) {
                currData.points = [];
                currData.segments = [];
                globalState.isDrawing = '';
            }
            else if (!adsorb || !adsorb.point || adsorb.point !== currData.point) {
                if (!currData.undoData) {
                    currData.undoData = {
                        cmd: 'updateObj',
                        target: currData.area,
                        value: currData.area.serialize(),
                    }
                }
                this.createPoint(pos, adsorb);
                currData.area.setNeedSave();
                currData.setCurrList(currData.area);
            }
        }
    }

    mouseUp(pos, ray, adsorb) {
        let dragData = this.dragData;
        if (dragData) {
            this.dragData = null;
            globalState.isDrawing = '';
            this.modeArea = '';
            if (dragData.type === 'breakControl') {
                currData.point = null;
            }
        }
    }

    mouseMove(pos, ray, adsorb) {
        pos.x = Math.ceil(pos.x);
        pos.y = Math.ceil(pos.y);
        if (currData.point) {
            this.updatePoint(pos, adsorb);
        }
    }

    createPoint(pos, adsorb) {
        let area = currData.area;
        if (adsorb) {
            if (adsorb.point) {
                pos.x = adsorb.point.x;
                pos.y = adsorb.point.y;
            } else if (adsorb.pointX) {
                pos.x = adsorb.pointX.x;
            } else if (adsorb.pointY) {
                pos.y = adsorb.pointY.y;
            }
        }
        pos.x = Math.ceil(pos.x);
        pos.y = Math.ceil(pos.y);

        let pointStack = this.pointStack;
        let lastPoint = pointStack[pointStack.length - 1];
        if (lastPoint && lastPoint.x === pos.x && lastPoint.y === pos.y) {
            return;
        }

        if (globalState.drawingMode === 'arc') {
            if (!globalState.isDrawing) {
                this._arcData = {
                    center: {x: pos.x, y: pos.y},
                    radius: 0,
                    startAngle: 0,
                    angle: Math.PI * 2,
                    step: 0,
                }
                if (area.points.length) {
                    area = this.collection.createArea();
                    area.floor = currData.floor.nanoId;

                    area.setNeedSave();
                    undoMap.push({
                        cmd: 'create',
                        value: area,
                    });

                    currData.area = area;
                }
                globalState.isDrawing = 'arc';
                let points = [area.createPoint(pos), area.createPoint(pos), area.createPoint(pos)];
                currData.points = points;
                let segment = area.segments[1];
                currData.segments = [segment];
                segment.angle = this._arcData.angle;
                segment.center = new Vector2(pos.x, pos.y);
            }
        }
        else if (globalState.drawingMode === 'rect') {
            if (!currData.point || !globalState.isDrawing) {
                if (area.points.length) {
                    area = this.collection.createArea();
                    area.floor = currData.floor.nanoId;

                    area.setNeedSave();
                    undoMap.push({
                        cmd: 'create',
                        value: area,
                    });

                    currData.area = area;
                }
                let points = [area.createPoint(pos), area.createPoint(pos), area.createPoint(pos), area.createPoint(pos)];
                currData.points = points;
                globalState.isDrawing = 'rect';
            }
        }
        else {
            if (!this.modeArea) {
                this.modeArea = area.points.length > 1 ? 'point' : 'polygon';
            }
            if (this.modeArea === 'polygon') {
                if (!currData.undoData) {
                    currData.undoData = {
                        cmd: 'updateObj',
                        target: area,
                        value: area.serialize(),
                    }
                }
                if (!currData.point) {
                    currData.point = area.createPoint(pos);
                }
                pointStack.push(currData.point);
                currData.point = area.createPoint(pos);
            }
            else if (this.modeArea === 'point') {
                let d = Number.MAX_VALUE;
                let posIndex = -1;
                let points = area.points;
                let size = points.length;
                let point = new Vector2(pos.x, pos.y);

                for (let index = 0; index < size; index++) {
                    let _pindex2 = index + 1 === size ? 0 : index + 1;
                    let pointA = new Vector2(points[index].x, points[index].y);
                    let pointB = new Vector2(points[_pindex2].x, points[_pindex2].y);
                    let l = distanceSquaredPointToSegment(point, pointA, pointB);
                    if(l < d) {
                        d = l;
                        posIndex = index;
                    }
                }

                if (!currData.undoData) {
                    currData.undoData = {
                        cmd: 'updateObj',
                        target: area,
                        value: area.serialize(),
                    }
                }
                if (currData.point) {
                    pointStack.push(currData.point);
                }
                currData.point = area.createPoint(pos, posIndex + 1);
            }
            globalState.isDrawing = 'line';
        }

        if (area) {
            area.updateArea();
        }
    }

    keyboard(event) {
        if (event.keyCode === 8 || event.keyCode === 46) {
            if (currData.area && currData.point && this.pointStack.length) {
                let oldPoint = currData.point;
                currData.area.removePoint(oldPoint);

                currData.point = this.pointStack.pop();

                this.updatePoint(oldPoint);
                return true;
            }
        }
    }

    updatePoint(pos, adsorb) {
        if (currData.point && globalState.isDrawing) {
            if (adsorb && adsorb.enableAdsorb) {
                let pointStack = this.pointStack;
                let points = currData.area.points;
                let lastPoint;
                if (points.length > 1) {
                    lastPoint = points[(points.indexOf(currData.point) - 1 + points.length) % points.length];
                }

                let shiftKey = adsorb && adsorb.event && adsorb.event.shiftKey;
                let adsorbdirection = shiftKey ? 90 : this.owner.settings.adsorbdirection;
                let directionToDegree = 180 / Math.PI;
                let degreeToDegree = Math.PI / 180;

                if (adsorb.point) {
                    pos.x = adsorb.point.x;
                    pos.y = adsorb.point.y;
                } else if (adsorb.pointX && adsorb.pointY) {
                    pos.x = adsorb.pointX.x;
                    pos.y = adsorb.pointY.y;
                } else if (adsorb.pointX && lastPoint) {
                    pos.x = adsorb.pointX.x;
                    let dx = pos.x - lastPoint.x;
                    let dy = pos.y - lastPoint.y;
                    let r = Math.round(Math.atan2(dy, dx) * directionToDegree / adsorbdirection) * adsorbdirection * degreeToDegree;

                    pos.y = (r / Math.PI + 2) % 1 === 0.5 ? pos.y : dx * Math.tan(r) + lastPoint.y;
                } else if (adsorb.pointY && lastPoint) {
                    pos.y = adsorb.pointY.y;
                    let dx = pos.x - lastPoint.x;
                    let dy = pos.y - lastPoint.y;
                    let r = Math.round(Math.atan2(dy, dx) * directionToDegree / adsorbdirection) * adsorbdirection * degreeToDegree;

                    pos.x = (r / Math.PI + 2) % 1 === 0 ? pos.x : dy / Math.tan(r) + lastPoint.x;
                } else if (lastPoint) {
                    let dx = pos.x - lastPoint.x;
                    let dy = pos.y - lastPoint.y;
                    let r = Math.round(Math.atan2(dy, dx) * directionToDegree / adsorbdirection) * adsorbdirection * degreeToDegree;

                    if (shiftKey) {
                        if (Math.abs(dx) < Math.abs(dy)) {
                            pos.y = (r / Math.PI + 2) % 1 === 0.5 ? pos.y : dx * Math.tan(r) + lastPoint.y;
                        }
                        else {
                            pos.x = (r / Math.PI + 2) % 1 === 0 ? pos.x : dy / Math.tan(r) + lastPoint.x;
                        }
                    }
                    else {
                        let d = Math.sqrt(dx * dx + dy * dy);

                        pos.x = Math.round(Math.cos(r) * d * 10) * 0.1 + lastPoint.x;
                        pos.y = Math.round(Math.sin(r) * d * 10) * 0.1 + lastPoint.y;
                    }
                }

                if (shiftKey && lastPoint) {
                    if (Math.abs(pos.x - lastPoint.x) < Math.abs(pos.y - lastPoint.y)) {
                        pos.x = lastPoint.x;
                    }
                    else {
                        pos.y = lastPoint.y;
                    }
                }
            }

            pos.x = Math.floor(pos.x);
            pos.y = Math.floor(pos.y);
            if (globalState.drawingMode === 'arc') {
                let arcData = this._arcData;
                if (arcData.step === 0) {
                    let dx = pos.x - arcData.center.x;
                    let dy = pos.y - arcData.center.y;
                    arcData.radius = Math.sqrt(dx * dx + dy * dy);
                    currData.segment.radius = arcData.radius;
                    currData.segment.makeDirty();
                    currData.points[1].x = pos.x;
                    currData.points[1].y = pos.y;
                    currData.points[1].makeDirty();
                    currData.points[2].x = pos.x;
                    currData.points[2].y = pos.y;
                    currData.points[2].makeDirty();
                }
                else if (arcData.step === 1) {
                    arcData.angle = Math.PI;
                    let angle0 = Math.atan2(currData.points[1].y - arcData.center.y, currData.points[1].x - arcData.center.x);
                    let angle1 = Math.atan2(pos.y - arcData.center.y, pos.x - arcData.center.x);
                    let PI2 = Math.PI * 2;
                    let angle = (angle1 - angle0 - PI2) % PI2;
                    currData.segment.angle = Math.abs(angle) < 0.00001 ? PI2 : angle;
                    currData.segment.makeDirty();
                    currData.points[2].x = Math.cos(angle1) * arcData.radius + arcData.center.x;
                    currData.points[2].y = Math.sin(angle1) * arcData.radius + arcData.center.y;
                    currData.points[2].makeDirty();
                }
            }
            else if (globalState.drawingMode === 'rect' && currData.points.length === 4) {
                currData.points[1].x = pos.x;
                currData.points[2].x = pos.x;
                currData.points[2].y = pos.y;
                currData.points[3].y = pos.y;
                currData.points[1].makeDirty();
                currData.points[2].makeDirty();
                currData.points[3].makeDirty();
            }
            else if (currData.point) {
                currData.point.x = pos.x;
                currData.point.y = pos.y;
                currData.point.makeDirty();
            }

            if (currData.area) {
                currData.area.updateArea();
            }
        }
    }

    pickPoint(pos, ray, picker) {
        let currPoint = currData.point;
        let areas = this.collection.areas;

        let d2 = picker.distance2;
        let point;
        for (let i = 0; i < areas.length; i++) {
            let area = areas[i];
            if (!area.finalVisible) {
                continue;
            }

            let points = area.points;
            for (let i = 0; i < points.length; i++) {
                if (points[i] === currPoint) {
                    continue;
                }
                let d2_ = Vector2.DistanceSquared(pos, points[i]);
                if (d2 > d2_) {
                    d2 = d2_;
                    point = points[i];
                }
            }
        }

        if (d2 < picker.tolerant2 && point) {
            picker.distance2 = d2;
            picker.points.push(point);
            return point;
        }
    }

    pickXYPoints(pos, ray, picker) {
        let currPoint = currData.point;
        let areas = this.collection.areas;

        let dx = picker.distanceX;
        let dy = picker.distanceY;
        let lx = picker.LengthX;
        let ly = picker.LengthY;
        let l_ = picker.tolerant;

        let pointX;
        let pointY;

        for (let i = 0; i < areas.length; i++) {
            let area = areas[i];
            if (!area.finalVisible) {
                continue;
            }

            let points = area.points;

            for (let i = 0; i < points.length; i++) {
                if (points[i] === currPoint) {
                    continue;
                }
                let dx_ = Math.abs(pos.x - points[i].x);
                let dy_ = Math.abs(pos.y - points[i].y);
                if (dx > dx_ || (Math.abs(dx - dx_) < l_ && lx > dy_)) {
                    dx = dx_;
                    lx = dy_;
                    pointX = points[i];
                }
                if (dy > dy_ || (Math.abs(dy - dy_) < l_ && ly > dx_)) {
                    dy = dy_;
                    ly = dx_;
                    pointY = points[i];
                }
            }
        }

        if (dx < picker.tolerant && pointX) {
            picker.pointX = pointX;
            picker.distanceX = dx;
            picker.LengthX = lx;
        }

        if (dy < picker.tolerant && pointY) {
            picker.pointY = pointY;
            picker.distanceY = dy;
            picker.LengthY = ly;
        }
    }

    pickByAreaBox(pos, ray, picker) {
        let areas = this.collection.areas

        let pos0 = picker.pos0;
        let pos1 = pos;
        let x0 = Math.min(pos0.x, pos1.x);
        let x1 = Math.max(pos0.x, pos1.x);
        let y0 = Math.min(pos0.y, pos1.y);
        let y1 = Math.max(pos0.y, pos1.y);
        let p0 = {x: x0, y: y0};
        let p1 = {x: x1, y: y0};
        let p2 = {x: x1, y: y1};
        let p3 = {x: x0, y: y1};

        for (let area of areas) {
            if (area.isDeleted || !area.finalVisible) {
                continue;
            }
            let bbox = area.boundingBox;
            if (bbox && bbox[0] <= x1 &&
                bbox[2] <= y1 &&
                bbox[3] >= x0 &&
                bbox[5] >= y0) {
                let isMatch = false;
                for (let p of area.points) {
                    if (intersectCheckPointAndBox(p, x0, y0, x1, y1)) {
                        isMatch = true;
                        break;
                    }
                }
                isMatch = isMatch || intersectCheckPointAndPolygon(p0, area.points) ||
                    intersectCheckPointAndPolygon(p1, area.points) ||
                    intersectCheckPointAndPolygon(p2, area.points) ||
                    intersectCheckPointAndPolygon(p3, area.points);

                if (!isMatch) {
                    for (let i = 0; i < area.points.length; i++) {
                        let p0 = area.points[i];
                        let p1 = area.points[(i + 1) % area.points.length];
                        if (intersectCheckLineLine(p0.x, p0.y, p1.x, p1.y, x0, y0, x1, y0) ||
                            intersectCheckLineLine(p0.x, p0.y, p1.x, p1.y, x1, y0, x1, y1) ||
                            intersectCheckLineLine(p0.x, p0.y, p1.x, p1.y, x1, y1, x0, y1) ||
                            intersectCheckLineLine(p0.x, p0.y, p1.x, p1.y, x0, y1, x0, y0)) {
                            isMatch = true;
                            break;
                        }
                    }
                }
                if (isMatch) {
                    picker.objs.push(area);
                }
            }
        }
    }

    pickPointByAreaBox(pos, ray, picker) {
        let area = currData.currList[0];
        if (!area || area.type !== 'area' || area.isDeleted || !area.finalVisible) {
            return;
        }

        let pos0 = picker.pos0;
        let pos1 = pos;
        let x0 = Math.min(pos0.x, pos1.x);
        let x1 = Math.max(pos0.x, pos1.x);
        let y0 = Math.min(pos0.y, pos1.y);
        let y1 = Math.max(pos0.y, pos1.y);

        let bbox = area.boundingBox;
        if (bbox && bbox[0] <= x1 &&
            bbox[2] <= y1 &&
            bbox[3] >= x0 &&
            bbox[5] >= y0) {
            for (let p of area.points) {
                if (p.x >= x0 && p.x <= x1 && p.y >= y0 && p.y <= y1) {
                    picker.points.push(p);
                }
            }
        }
    }

    pickSegmentByAreaBox(pos, ray, picker) {
        let area = currData.currList[0];
        if (!area || area.type !== 'area' || area.isDeleted || !area.finalVisible) {
            return;
        }

        let pos0 = picker.pos0;
        let pos1 = pos;
        let x0 = Math.min(pos0.x, pos1.x);
        let x1 = Math.max(pos0.x, pos1.x);
        let y0 = Math.min(pos0.y, pos1.y);
        let y1 = Math.max(pos0.y, pos1.y);

        let bbox = area.boundingBox;
        if (bbox && bbox[0] <= x1 &&
            bbox[2] <= y1 &&
            bbox[3] >= x0 &&
            bbox[5] >= y0) {
            for (let seg of area.segments) {
                if (!seg.isArc) {
                    if (intersectCheckSegmentAndBox(seg.pointA, seg.pointB, x0, y0, x1, y1)) {
                        picker.segments.push(seg);
                    }
                }
            }
        }
    }

    pickObject(pos, ray, picker) {
        if (picker.objs.length) {
            return;
        }

        let areas = this.collection.areas;
        let meshBuilder = window.sm2DMeshBuilder;

        let pickArea = function (area) {
            if (!area || !area.finalVisible || !meshBuilder.objects[area.nanoId]) {
                return;
            }

            let meshes = meshBuilder.objects[area.nanoId].pickMeshes;
            for (let i = 0; i < meshes.length; i++) {
                let pickingInfo = ray.intersectsMesh(meshes[i]);
                if (pickingInfo.pickedMesh) {
                    return area;
                }
            }
        }

        let res = pickArea(currData.area);
        if (res) {
            picker.objs.push(res);
            return picker;
        }

        for (let i = 0; i < areas.length; i++) {
            let area = areas[i];
            if (area === currData.area) {
                continue;
            }

            res = pickArea(area);
            if (res) {
                picker.objs.push(res);
                return picker;
            }
        }
    }
}

export {
    SmAreaCreator
}
