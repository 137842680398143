import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import { ref, watch, computed, nextTick } from 'vue';
import { smDatas, getDataTree } from "@/model/smCollectionData";
import { currData, globalState } from "@/model/globalData";
import { getTypeName } from "@/model/modelOptions";
import tools from '@/utils/tools';
import { getObjectName, getFloorName, setProperty, doItemClick, doItemDBClick, showContextMenu } from "../index.js";
import { compMapByName, getModelById, getModelByName, selectedThemeId } from "@/model/3Dmodel";
import oemManager from '@/utils/oem';
export default {
  name: "KfTree",
  props: {
    type: {
      type: String,
      default: 'floor'
    },
    dataKey: {
      type: String,
      default: 'floor'
    },
    list: {
      type: Array,
      default: () => {
        return [];
      }
    },
    isFilter: {
      type: Boolean,
      default: false
    },
    isFilterInput: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    let bodyManager = ref(null);
    let dataTree = ref(null);
    let currFilterType = ref('all');
    let currSelectBuild = ref('all');
    let currSelectFloor = ref('all');
    let filterName = ref('');
    const filterList = computed(() => {
      let res = [{
        value: 'all',
        label: Kf.t('All')
      }];
      if ((props === null || props === void 0 ? void 0 : props.type) === 'model') {
        res.push({
          label: Kf.t('Mapped'),
          value: 'mapped'
        });
        res.push({
          label: Kf.t('Unmapped'),
          value: 'unmapped'
        });
      }
      return res;
    });
    let count = ref(0);
    let locale = ref(window.Kf.locale.value.replace("-", ""));
    let expandedKeys = ref([]);
    const groupCache = ref({});
    const loadSceneList = function () {
      let res = [];
      let data = smDatas.models || [];
      if (currFilterType.value === 'mapped') {
        data = data.filter(x => x.deviceId);
      }
      if (currFilterType.value === 'unmapped') {
        data = data.filter(x => !x.deviceId);
      }
      data = data.filter(x => {
        return !filterName.value || tools.pinyinMatch(x.name, filterName.value);
      });
      count.value = data.length;
      let treeMap = new Map();
      for (let item of data) {
        if (item.isDeleted || !item.floorVisible) {
          continue;
        }
        let secondNode = treeMap.get(item.modelName);
        if (!secondNode) {
          if (!smDatas.modelsGroup.has(item.modelName)) {
            let temp = {
              id: item.modelName,
              modelId: item.uuid,
              label: getLabel(item.modelName),
              subType: item.subType,
              children: [],
              _visible: ref(true),
              isGroup: true,
              level: 2
            };
            temp.modelLost = computed(() => {
              return !getModelByName(item.modelName);
            });
            temp.finalVisibleProxy = computed(() => {
              if (!temp._visible.value) {
                return false;
              }
              let parent = smDatas.modelsGroup.get(item.subType);
              return !parent || parent.visible;
            });
            Object.defineProperty(temp, 'visible', {
              set(value) {
                if (value === temp._visible.value) {
                  return;
                }
                let oldFinalVisible = temp.finalVisible;
                temp._visible.value = value;
                if (temp.finalVisible !== oldFinalVisible) {
                  if (temp.children) {
                    for (let child of temp.children) {
                      child.makeDirty();
                    }
                  }
                }
              },
              get() {
                return temp._visible.value;
              }
            });
            Object.defineProperty(temp, 'finalVisible', {
              get() {
                return temp.finalVisibleProxy.value;
              }
            });
            smDatas.modelsGroup.set(item.modelName, temp);
          }
          secondNode = smDatas.modelsGroup.get(item.modelName);
          secondNode.children = [];
          let firstNode = treeMap.get(item.subType);
          if (!firstNode) {
            if (!smDatas.modelsGroup.has(item.subType)) {
              let temp = {
                id: item.subType,
                label: getTypeName(item.subType),
                children: [],
                _visible: ref(true),
                isGroup: true,
                level: 1
              };
              smDatas.modelsGroup.set(item.subType, temp);
              Object.defineProperty(temp, 'visible', {
                set(value) {
                  if (value === temp._visible.value) {
                    return;
                  }
                  temp._visible.value = value;
                  for (let childGroup of temp.children) {
                    if (childGroup.visible) {
                      for (let child of childGroup.children) {
                        child.makeDirty();
                      }
                    }
                  }
                },
                get() {
                  return temp._visible.value;
                }
              });
            }
            firstNode = smDatas.modelsGroup.get(item.subType);
            firstNode.children = [];
            firstNode.modelLost = computed(() => {
              for (let child of firstNode.children) {
                if (child.modelLost) {
                  return true;
                }
              }
              return false;
            });
            treeMap.set(firstNode.id, firstNode);
            res.push(firstNode);
          }
          treeMap.set(secondNode.id, secondNode);
          firstNode.children.push(secondNode);
        }
        item.id = item._nanoId;
        item.modelLost = secondNode.modelLost;
        secondNode.children.push(item);
      }
      return res;
    };
    const loadPointList = function () {
      let res = [];
      let data = smDatas.devicePointsProxy.value || [];
      count.value = data.length;
      for (let item of data) {
        if (item.isDeleted) {
          continue;
        }
        if (currSelectBuild.value !== 'all' && item.deviceBuilding !== currSelectBuild.value) {
          continue;
        }
        if (currSelectFloor.value !== 'all' && item.floor !== currSelectFloor.value) {
          continue;
        }
        let group = item.deviceType || item.deviceTypeCode || item.deviceName;
        let deviceNode = res.find(node => node.id === group);
        if (!deviceNode) {
          deviceNode = {
            id: group,
            modelId: item.uuid,
            label: group,
            isGroup: true,
            level: 1,
            children: []
          };
          res.push(deviceNode);
        }
        let _floorName = item.floor || Kf.t('Default');
        let floorNode = deviceNode ? deviceNode.children.find(node => node.id === _floorName) : null;
        if (!floorNode) {
          floorNode = {
            id: _floorName,
            modelId: item.uuid,
            label: _floorName,
            isGroup: true,
            level: 2,
            children: []
          };
          deviceNode.children.push(floorNode);
        }
        let _node = floorNode ? floorNode.children.find(node => node.id === item.deviceId) : null;
        if (!_node) {
          _node = Object.assign({
            id: item.deviceId,
            label: item.deviceName
          }, item);
          floorNode.children.push(_node);
        }
      }
      return res;
    };
    let visibleObjs = computed(() => {
      let res = [];
      if ((props === null || props === void 0 ? void 0 : props.type) === 'model') {
        res = loadSceneList();
      } else if ((props === null || props === void 0 ? void 0 : props.type) === 'devicePoint') {
        res = loadPointList();
      }
      return res;
    });
    const checkedKeys = computed(() => {
      return currData.currList.map(x => x._nanoId);
    });
    const getLabel = function (modelName) {
      let t = getModelByName(modelName);
      if (t) {
        if (t.labelInterpret && typeof t.labelInterpret === "string") {
          try {
            t.labelInterpret = JSON.parse(t.labelInterpret);
          } catch (e) {
            console.error(e);
          }
        }
        modelName = (t.labelInterpret ? t.labelInterpret[locale.value] : '') || t.name;
      }
      return modelName;
    };
    watch(compMapByName, () => {
      for (let [key, group] of smCollection.modelsGroup) {
        if (group.level === 2) {
          group.label = getLabel(group.id);
        }
      }
    });
    const loadBodyScrollTop = function () {
      let model = currData.currList[currData.currList.length - 1];
      if (!model || model.type !== 'model') {
        return;
      }
      let eKeys = expandedKeys.value;
      let firstGroup = visibleObjs.value.find(x => x.id === model.subType);
      let secondGroup = firstGroup.children.find(x => x.id === model.modelName);
      if (eKeys.indexOf(firstGroup.id) === -1) {
        eKeys.push(firstGroup.id);
      }
      if (eKeys.indexOf(secondGroup.id) === -1) {
        eKeys.push(secondGroup.id);
      }
      for (let id of eKeys) {
        let node = dataTree.value.getNode(id);
        if (node) {
          node.expand();
        }
      }
      if (bodyManager.value) {
        let getIndex = () => {
          let index = 0;
          for (let i = 0; i < visibleObjs.value.length; i++) {
            index++;
            let group = visibleObjs.value[i];
            if (eKeys.indexOf(group.id) >= 0) {
              for (let j = 0; j < group.children.length; j++) {
                index++;
                let child = group.children[j];
                if (eKeys.indexOf(child.id) >= 0) {
                  if (child.id === model.modelName) {
                    index += child.children.indexOf(model);
                    return index;
                  } else {
                    index += child.children.length;
                  }
                }
              }
            }
          }
          return index;
        };
        let index = getIndex();
        setTimeout(() => {
          let top = index * 30;
          let clientHeight = bodyManager.value.clientHeight;
          let scrollTop = bodyManager.value.scrollTop;
          if (scrollTop < top - clientHeight + 30 || scrollTop > top - 30) {
            bodyManager.value.scrollTop = index * 30;
            setTimeout(() => {
              //不知道为啥，经常设置了不管用。
              bodyManager.value.scrollTop = index * 30;
            }, 0.5);
          }
        }, 0.2);
      }
    };
    watch(checkedKeys, (newValue, oldValue) => {
      if (!dataTree.value) {
        return;
      }
      loadBodyScrollTop();
      // dataTree.value.setCheckedKeys(checkedKeys.value);

      let newSet = new Set(newValue);
      let oldSet = new Set(oldValue);
      for (let k of oldValue) {
        let node = dataTree.value.getNode(k);
        if (node && !newSet.has(k)) {
          node.setChecked(false, true);
        }
      }
      for (let k of newValue) {
        let node = dataTree.value.getNode(k);
        if (node && !oldSet.has(k)) {
          node.setChecked(true, true);
        }
      }
    });
    let isActive = function (item) {
      return currData.currListMap.has(item);
    };
    const handleNodeExpand = function (data, node, instance) {
      expandedKeys.value.push(data.id);
    };
    const handleNodeCollapse = function (data, node, instance) {
      let _index = expandedKeys.value.findIndex(item => item === data.id);
      if (_index !== null) {
        expandedKeys.value.splice(_index, 1);
      }
    };
    const buildingOptions = computed(() => {
      let points = smDatas.devicePointsProxy.value;
      let buildings = [{
        label: Kf.t('All') + ` (${points.length})`,
        value: 'all'
      }];
      let buildingMap = new Map();
      for (let item of points) {
        if (!buildingMap.has(item.deviceBuilding)) {
          buildingMap.set(item.deviceBuilding, {
            label: item.deviceBuilding,
            value: item.deviceBuilding,
            count: 0
          });
          buildings.push(buildingMap.get(item.deviceBuilding));
        }
        buildingMap.get(item.deviceBuilding).count++;
      }
      for (let item of buildingMap.values()) {
        item.label = item.label + ` (${item.count})`;
      }
      return buildings;
    });
    const floorOptions = computed(() => {
      let points = smDatas.devicePointsProxy.value;
      let floors = [{
        label: Kf.t('All') + ` (${points.length})`,
        value: 'all'
      }];
      let floorMap = new Map();
      for (let item of points) {
        if (!floorMap.has(item.floor)) {
          floorMap.set(item.floor, {
            label: item.floor,
            value: item.floor,
            count: 0
          });
          floors.push(floorMap.get(item.floor));
        }
        floorMap.get(item.floor).count++;
      }
      for (let item of floorMap.values()) {
        item.label = item.label + ` (${item.count})`;
      }
      return floors;
    });
    return {
      filterList,
      currFilterType,
      count,
      visibleObjs,
      checkedKeys,
      handleNodeCollapse,
      handleNodeExpand,
      isActive,
      dataTree,
      bodyManager,
      doItemClick,
      getObjectName,
      getFloorName,
      doItemDBClick,
      platformType: oemManager.platformType,
      showContextMenu,
      expandedKeys,
      setProperty,
      buildingOptions,
      floorOptions,
      currSelectBuild,
      currSelectFloor,
      filterName
    };
  }
};