import { ref } from 'vue';
import { formatOptions4SelectUIPurpose2Name } from "@/components/mainContent/rightPanel/property";
export const areaSubTypeOptions = [{
  name: Kf.t('Undefined'),
  value: ''
}, {
  name: Kf.t('Floor2'),
  value: 'floor'
}, {
  name: Kf.t('Room'),
  value: 'room'
}, {
  name: Kf.t('FunctionalArea'),
  value: 'function'
}];
export const areaSubTypeMap = {
  '': Kf.t('Undefined'),
  'floor': Kf.t('Floor2'),
  'room': Kf.t('Room'),
  'function': Kf.t('FunctionalArea')
};
export const areaOptionsData = ref([
  // {name: '地板', states: [{name: '默认', color: '#7F7F7F', style: '样式一'}]},
  // {name: '公共区域', states: [{name: '默认', color: '#FFC110', style: '样式一'}]},
]);
export const wallOptions = [{
  value: 'normal',
  label: Kf.t('Normal')
}, {
  value: 'bearing',
  label: Kf.t('Loadbearing')
}, {
  value: 'shear',
  label: Kf.t('Shear')
}, {
  value: 'glass',
  label: Kf.t('Glass')
}, {
  value: 'partition',
  label: Kf.t('Partition')
}, {
  value: 'screen',
  label: Kf.t('Screen')
}];
export const pipelineOptions = [{
  value: 'normal',
  label: Kf.t('Normal')
}, {
  value: 'liquid',
  label: Kf.t('Liquid')
}, {
  value: 'wire',
  label: Kf.t('Wire')
}, {
  value: 'gas',
  label: Kf.t('Gas')
}];
let nameMap = {
  'normal': Kf.t('Normal'),
  'bearing': Kf.t('Loadbearing'),
  'glass': Kf.t('Glass'),
  'meeting': Kf.t('ConferenceRoom'),
  'office': Kf.t('Office'),
  'device': Kf.t('BetweenDevices'),
  'corridor': Kf.t('Corridor'),
  'elevator': Kf.t('ElevatorLobby'),
  'staircase': Kf.t('Stairwell'),
  'recreation': Kf.t('LeisureArea'),
  'hall': Kf.t('Lobby'),
  'fire': Kf.t('FireLane'),
  'washroom': Kf.t('Bathroom'),
  'parking': Kf.t('Parking')
};
let typeMap = {};
typeMap[Kf.t('Normal')] = 'normal';
typeMap[Kf.t('Loadbearing')] = 'bearing';
typeMap[Kf.t('Glass')] = 'glass';
typeMap[Kf.t('ConferenceRoom')] = 'meeting';
typeMap[Kf.t('Office')] = 'office';
typeMap[Kf.t('BetweenDevices')] = 'device';
typeMap[Kf.t('Corridor')] = 'corridor';
typeMap[Kf.t('ElevatorLobby')] = 'elevator';
typeMap[Kf.t('Stairwell')] = 'staircase';
typeMap[Kf.t('LeisureArea')] = 'recreation';
typeMap[Kf.t('Lobby')] = 'hall';
typeMap[Kf.t('FireLane')] = 'fire';
typeMap[Kf.t('Bathroom')] = 'washroom';
typeMap[Kf.t('Parking')] = 'parking';