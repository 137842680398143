import { menuData, showContextmenu2, contextMenuStyle, closeMenu } from "@/components/widgets/contextmenu/contextmenu2";
export default {
  name: "contextmenu2",
  setup() {
    return {
      contextMenuStyle,
      showContextmenu2,
      menuData,
      closeMenu
    };
  }
};