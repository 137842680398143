const modelMap = {
  undefined: Kf.t('Undefined'),
  null: Kf.t('Undefined'),
  '': Kf.t('Undefined'),
  door: Kf.t('Door'),
  window: Kf.t('Window'),
  building: Kf.t('Building'),
  camera: Kf.t('Surveillance'),
  furniture: Kf.t('Furniture'),
  sensor: Kf.t('Sensors'),
  light: Kf.t('Lights'),
  desk: Kf.t('Table'),
  chair: Kf.t('Chair'),
  equipment: Kf.t('Device'),
  parking: Kf.t('Parking')
};
export const getTypeName = function (name) {
  return modelMap[name] || name;
};
export default {
  modelMap
};