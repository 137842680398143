import axios from 'axios';
import Const from './const';
import Cookies from 'js-cookie';
let _headers = {};
let HOST = "/digitaltwins";
export let _handle = {
  redirect: {},
  showMsg: {}
};
class FetchService {
  /**
   * Creates an instance of fetchService.
   *
   * @memberOf fetchService
   */
  constructor(param = {}) {
    if (Const.develop) {
      this.host = param.host || 'http://dev.kingfisher.live:8000/digitaltwins';
    } else {
      this.host = param.host || '/digitaltwins';
    }
    this.errorStatus = {
      message: '',
      statusCode: -1,
      screen: ''
    };
    this.cookieKey = "DESIGNER_TOKEN";
    this.ticket = "";
    this.token = {};
  }

  /**
   * 设置Headers
   * @param Headers
   */
  static setHeader(headers) {
    Object.keys(headers).forEach(key => {
      _headers[key] = headers[key];
    });
  }

  /**
   *
   * @param key 是否有key
   */
  static hasHeader(key) {
    return !!_headers[key];
  }

  /**
   * 获取 http 请求头部信息
   * @readonly
   * @static
   * @memberof FetchService
   */
  static get header() {
    return _headers;
  }

  /**
   * 设置HOST
   * @param HOST 传入的host 地址
   */
  static setHost(host) {
    HOST = host;
  }

  /**
   * 服务端地址
   * @readonly
   * @static
   * @memberof FetchService
   */
  static get serverIP() {
    return HOST;
  }

  /**
   * 设置回调函数
   * @param funcName 传入的函数名
   * @param func 传入的函数
   */
  static setHandle(funcName, func) {
    _handle[funcName] = func;
  }

  /**
   *  获取URL
   * @param parameters 发送请求需要的参数
   * @returns {jQuery.Deferred} jQuery的Deferred对象
   */
  getURL(url) {
    let result = this.host ? this.host : HOST;
    if (url.startsWith("http://") || url.startsWith("https://")) {
      result = url;
    } else {
      if (url.indexOf(".") == -1) {
        result += url + ".client";
      } else {
        result += url;
      }
    }
    return result;
  }

  /**
   * 发送请求
   * @param method 请求类型（'get', 'post'）
   * @param parameters
   **/
  sendFetch(method, args) {
    let url = this.getURL(args[0]);
    let axiosOptions = {
      method: method,
      url: url,
      data: args[1],
      timeout: 120000,
      headers: Object.assign(args[2] ? {} : {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'kingfisher-token': Cookies.get(this.cookieKey),
        'Authentication': localStorage.getItem("KC_TOKEN")
      }, args[2])
    };
    if (args[2]) {
      Object.assign(axiosOptions, args[2]);
    }
    if (url.indexOf("http") == 0) {
      let host = url.substring(0, url.indexOf("/", 8));
      if (this.token[host]) {
        axiosOptions.headers["kingfisher-token"] = this.token[host];
      }
    }
    return axios(axiosOptions);
  }

  /**
   * 用Promise封装 fetch, 方便手动resolve ，用于阻拦请求失败后不进入之后的then
   **/
  fetchPromise(method, args) {
    return new Promise((resolve, reject) => {
      this.sendFetch(method, args).then(res => {
        if (res.statusText === 'OK' || res.status === 200) {
          resolve(res.data);
        } else {
          reject(this.processFailed(res.data));
        }
      }, err => {
        console.warn(this.getURL(args[0]));
        if (err.response) {
          reject(this.processFailed(err.response));
        } else if (typeof err.message === 'string') {
          reject(this.processFailed(err.message));
        } else {
          reject(this.processFailed(err.message.response || {
            message: Kf.t('NetworkError')
          }));
        }
      });
    });
  }

  /**
   * Get方式
   *
   * @returns Promise对象
   *
   * @memberOf fetchService
   */
  get() {
    return this.fetchPromise('GET', arguments);
  }

  /**
   * Post方式
   *
   * @returns 返回Promise对象
   *
   * @memberOf fetchService
   */
  post() {
    return this.fetchPromise('POST', arguments);
  }

  /**
   * Put方式
   *
   * @returns 返回Promise对象
   *
   * @memberOf fetchService
   */
  put() {
    return this.fetchPromise('PUT', arguments);
  }

  /**
   * Delete方式
   *
   * @returns 返回Promise对象
   *
   * @memberOf fetchService
   */
  delete() {
    return this.fetchPromise('DELETE', arguments);
  }

  /**
   * 处理失败的请求
   * @param {Object} response 请求返回的内容
   * @returns {jQuery|Deferred}
   * @memberof fetchService
   */
  processFailed(response) {
    if (typeof response === 'string') {
      this.errorStatus = {
        message: response,
        statusCode: null,
        screen: ''
      };
      return {
        response,
        errorStatus: this.errorStatus
      };
    }
    if (!response.status) {
      this.errorStatus = {
        message: response.message,
        statusCode: 0
      };
      return {
        response,
        errorStatus: this.errorStatus
      };
    }
    this.errorStatus = {
      message: '',
      statusCode: response.status,
      screen: ''
    };
    switch (response.status) {
      case -1:
        this.errorStatus.message = response.message;
        return {
          response,
          errorStatus: this.errorStatus
        };
      case 0:
        console.error({
          type: 'warning',
          message: Kf.t('PleaseCheckNetworkConnection')
        });
        return;
      case 401:
        console.error({
          type: 'warning',
          message: Kf.t('LoginTimedOut')
        });
        return;
      default:
        {
          let message = '发送请求失败：' + JSON.stringify(response.data);
          if (response.data.requestId) {
            message = `[${response.data.requestId}] ${response.data.message}`;
          }
          if (process.env.PLATFORM === 'web') {
            console.error({
              type: 'warning',
              message: message
            });
          }
          this.errorStatus.message = message;
          return {
            response,
            errorStatus: this.errorStatus
          };
        }
    }
  }
}
export default FetchService;