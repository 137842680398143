
import {matrix2DInvert} from "../maths/vector";
import { getUID, registerSMType, serializeObject, SMObject } from "../objects/smObject";
import {smDatas} from "../model/smCollectionData";
import {ref} from 'vue'

const maxStack = 100;

class UndoMap {
    _undoList = ref([]);
    _redoList = ref([]);
    undoList = this._undoList.value;
    redoList = this._redoList.value;

    cmdMap = {
        'create': 'delete',
        'delete': 'create',
    }

    _isEqual(a, b, property) {
        if (a === b) {
            return true;
        }
        let aIsArray = a instanceof Array;
        let bIsArray = b instanceof Array;
        if (aIsArray !== bIsArray) {
            return false;
        }
        if (aIsArray) {
            if (a.length !== b.length) {
                return false;
            }

            let i = 0;
            for (; i < a.length; i++) {
                if (a[i] !== b[i]) {
                    if (!property) {
                        break;
                    }
                    if (a[i][property] !== b[i][property]) {
                        break;
                    }
                }
            }

            return i === a.length;
        }

        return property && a[property] === b[property];
    }

    clean() {
        this.undoList = [];
        this.redoList = [];
    }

    push(data) {
        this.undoList.push(data);
        if (this.undoList.length > maxStack) {
            this.undoList.shift();
        }
        this.redoList = [];
    }

    pushWithCheck(data, newValues) {
        let last = this.undoList[this.undoList.length - 1];
        if (last && last.cmd === 'updateProperty' && data.cmd === 'updateProperty' &&
            last.property === data.property) {
            if (!data.items) {
                if (data.target === last.target) {
                    if (data.value === newValues) {
                        this.undoList.pop();
                    }
                    return;
                }
            }
            else if (this._isEqual(last.items, data.items, 'target')) {
                if (newValues instanceof Array) {
                    let i = 0;
                    for (; i < newValues.length; i++) {
                        if (newValues[i] !== last.items[i].value) {
                            break;
                        }
                    }
                    if (i === newValues.length) {
                        undoMap.pop();
                    }
                }
                return;
            }
        }

        this.push(data);
    }

    pop() {
        return this.undoList.pop();
    }

    undo(isRedo) {
        let data = isRedo ? this.redoList.pop() : this.undoList.pop();
        if (!data) {
            return;
        }

        let cmd = data.cmd;
        if (!isRedo) {
            cmd = this.cmdMap[cmd] || cmd;
        }
        console.log(cmd, data);
        if (this['_' + cmd]) {
            this['_' + cmd](data, isRedo ? this.undoList : this.redoList)
        }
        else {
            // switch (data.cmd) {
            //     case 'create':
            //         this._delete(data);
            //         break;
            //     case 'delete':
            //         this._create(data);
            //         break;
            //     case 'calibrate':
            //         this._calibrate(data);
            //         break;
            //     case 'updateImage':
            //         this._updateImage(data);
            //         break;
            //     case 'draw':
            //         this._draw(data);
            //         break;
            // }
        }
    }

    redo() {
        this.undo(true);
    }

    _create(data, undoRedo) {
        let items = data.items || [data];
        for (let item of items) {
            if (item.target instanceof SMObject) {
                item.target.isDeleted = false;
                item.target.setNeedSave();
            }
        }
        undoRedo.push(data);
    }

    _delete(data, undoRedo) {
        let items = data.items || [data];
        for (let item of items) {
            if (item.target instanceof SMObject) {
                item.target.isDeleted = true;
                item.target.setNeedSave();
            }
        }
        undoRedo.push(data);
    }

    _updateProperty(data, undoRedo) {
        let path = data.property.split('.');
        let pl = path.length;
        let items = data.items || [data];
        for (let item of items) {
            let target = item.target;
            let _target = target;
            for (let i = 0; i < pl - 1; i++) {
                _target = _target && _target[path[i]];
            }
            if (_target) {
                let oldValue = _target[path[pl - 1]];
                _target[path[pl - 1]] = item.value;
                if (item.value !== oldValue) {
                    item.value = oldValue;
                    if (data.type === 'size' && target.keepRatio) {
                        _target['x'] = item.value;
                        _target['y'] = item.value;
                        _target['z'] = item.value;
                    }
                    if (data.type === 'size') {
                        target.updateBoundingBox();
                    }
                    if (target.makeDirty) {
                        target.makeDirty();
                    }
                    if (target.setNeedSave) {
                        target.setNeedSave();
                    }
                }
            }
        }
        undoRedo.push(data);
    }

    _updateObj(data, undoRedo) {
        let items = data.items || [data];
        let values = items.map(x => x.target.serialize());
        for (let i = 0; i < items.length; i++) {
            let item = items[i];
            let target = item.target;
            let value = values[i];
            target.updateProperties(item.value);
            target.makeDirty();
            target.setNeedSave();
            item.value = value;
        }
        undoRedo.push(data);
    }

    _setValue(data, undoRedo) {
        let value = data.target[data.property];
        data.target[data.property] = data.value;
        data.value = value;
        undoRedo.push(data);
    }

    _calibrate(data, undoRedo) {
        let drawing = data.target;
        let value = data.value;

        let matrix = matrix2DInvert(value.matrix);

        undoRedo.push({
            cmd: 'calibrate',
            target: drawing,
            value: {scaling: drawing.scaling, centerX: drawing.centerX, centerY: drawing.centerY, matrix: matrix},
        });

        drawing.scaling = value.scaling;
        drawing.centerX = value.centerX;
        drawing.centerY = value.centerY;

        drawing.makeDirty();

        let walls = window.smCollection.walls;
        for (let i = 0; i < walls.length; i++) {
            walls[i].transform(matrix);
        }

        let areas = window.smCollection.areas;
        for (let i = 0; i < areas.length; i++) {
            areas[i].transform(matrix);
        }
    }

    _updateImage(data, undoRedo) {
        let drawing = data.target;

        undoRedo.push({
            cmd: 'updateImage',
            target: drawing,
            value: drawing.url
        });

        drawing.url = data.value;
    }
}

const undoMap = new UndoMap();

window.undoMap = undoMap;

export {
    undoMap
}
