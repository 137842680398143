import hotkeys from 'hotkeys-js';
import { globalState } from "@/model/globalData";
import { ref, computed } from "vue";
export function registerKeys(key, handler, option) {
  hotkeys(key, option, handler);
}
const keyboardValueProxy = new ref({});
export const keyboardValue = keyboardValueProxy.value;
export const isSpaceDown = computed(() => {
  return keyboardValueProxy.value[' '];
});
export const isCtrlDown = computed(() => {
  return keyboardValueProxy.value['Control'] || keyboardValueProxy.value['Meta'];
});
export const isShiftDown = computed(() => {
  return keyboardValueProxy.value['Shift'];
});
export function kb(event, keyUp) {
  const e = event;
  let focusedElementName = document.activeElement.tagName.toLowerCase();
  if (focusedElementName === "input" || focusedElementName === "textarea") {
    return;
  }
  if (keyUp) {
    delete keyboardValue[e.key];
  } else {
    keyboardValue[e.key] = true;
  }
  if (keyUp) {
    return;
  }
  const key = e.keyCode;
  const isCtrl = e.ctrlKey;
  const isAlt = e.altKey;
  const isShift = e.shiftKey;
  if (key === 46 || key === 8) {
    // delete
    if (globalState.operation !== 'draw' || !currData.point) {
      window.deleteSceneObj();
    }
    if (window.location.href.indexOf("/content") === -1) {
      e.preventDefault();
    }
  } else if (key === 69) {
    // e
    globalState.operation = 'draw';
    e.preventDefault();
  } else if (key === 86) {
    // v
    globalState.operation = 'pick';
    e.preventDefault();
  } else if (key === 70) {
    var _window;
    if (isCtrl) {
      // 查找 ctrl + f
    } else if ((_window = window) !== null && _window !== void 0 && _window.sm3DMeshBuilder) {
      window.sm3DMeshBuilder.focusOnObject({
        minDistance: 4
      });
      window.sm2DMeshBuilder.focusOnObject({
        minDistance: 4000
      });
    }
    e.preventDefault();
  }
}
window.onkeydown = e => {
  kb(e, false);
};
window.onkeyup = e => {
  kb(e, true);
};
window.onfocus = (a, b) => {
  Object.keys(keyboardValue).map(key => delete keyboardValue[key]);
};