import { ref } from 'vue';
import productService from '@/service/product';
import _ from 'lodash';
const customization = ref({});
const loadKeys = {};
const init = function () {
  productService.getEnvVariable().then(res => {
    if (res) {
      res.map(item => {
        _.set(customization.value, item.code, item.info);
      });
    }
  });
};
const loadRule = function (desc, key, subKey) {
  if (subKey) {
    loadKeys[key + "." + subKey] = desc;
  } else {
    loadKeys[key] = desc;
  }
  let value = customization.value[key];
  if (subKey && value != undefined) {
    value = value[subKey];
  }
  return value;
};
const loadSettingDesc = function (key, subKey) {
  if (subKey) {
    return loadKeys[key + "." + subKey];
  } else {
    return loadKeys[key];
  }
};
const isEnable = function (desc, key, subKey, defValue = true) {
  let _rules = loadRule(desc, key, subKey);
  let result = (_rules == undefined ? defValue : false) || _rules == true || _rules == 'true';
  console.log("翠鸟设置", loadSettingDesc(key, subKey), result);
  return result;
};
init();
export default {
  init,
  loadRule,
  isEnable,
  loadKeys
};