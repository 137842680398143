import _defineProperty from "F:/workspace/\u7A7A\u95F4\u6CBB\u7406\u5DE5\u5177/\u7A7A\u95F4\u6CBB\u7406CAD/space-management/spaceManager/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { Drawing } from "./smDrawing";
import { registerSMType, serializeObject, updateProperties } from "./smObject";
import { smDatas } from "../model/smCollectionData";

// class DxfDrawingLayerItem {
//     count;
//     type;
//     _visible = true;
//
//     get visible() {
//         return this._visible;
//     }
//     set visible(value) {
//         if (value === this._visible) {
//             return;
//         }
//         this._visible = value;
//         smDatas.objects[this.drawing].makeDirty();
//     }
//
//     constructor(drawing, options) {
//         this.drawing = drawing;
//         this.count = options.count;
//         this.type = options.type;
//     }
//
//     serialize() {
//         return {
//             type: this.type,
//             count: this.count,
//         };
//     }
// }

class DxfDrawingBlock {
  get visible() {
    return this._visible;
  }
  set visible(value) {
    if (value === this._visible) {
      return;
    }
    this._visible = value;
    smDatas.objects[this.drawing].makeDirty();
  }
  constructor(drawing, options) {
    _defineProperty(this, "name", void 0);
    _defineProperty(this, "_visible", true);
    _defineProperty(this, "drawing", void 0);
    _defineProperty(this, "boundingBox", void 0);
    _defineProperty(this, "usedCount", 0);
    this.type = 'dxfDrawingBlock';
    this.drawing = drawing;
    this.updateProperties(options);
  }
  updateProperties(data) {
    updateProperties(this, ['name', 'usedCount', 'visible', 'boundingBox'], data);
  }
  serialize() {
    let res = {};
    serializeObject(this, ['name', 'usedCount', 'visible', 'boundingBox'], res);
    return res;
  }
}
class DxfDrawingLayer {
  // children = [];

  get visible() {
    return this._visible;
  }
  set visible(value) {
    if (value === this._visible) {
      return;
    }
    this._visible = value;
    smDatas.objects[this.drawing].makeDirty();
  }
  constructor(drawing, options) {
    _defineProperty(this, "layerID", void 0);
    _defineProperty(this, "layerName", void 0);
    _defineProperty(this, "color", 0xffffff);
    _defineProperty(this, "_visible", true);
    _defineProperty(this, "count", 0);
    _defineProperty(this, "boundingBox", []);
    this.type = 'dxfDrawingLayer';
    this.drawing = drawing;
    this.updateProperties(options);
  }
  updateProperties(data) {
    updateProperties(this, ['layerID', 'layerName', 'color', 'count', 'type', 'visible', 'boundingBox'], data);
    // if (data.children) {
    //     this.children = data.children.map((x) => new DxfDrawingLayerItem(this.drawing, x));
    // }
  }
  serialize() {
    let res = {};
    serializeObject(this, ['layerID', 'layerName', 'color', 'count', 'type', 'visible', 'boundingBox'], res);
    // if (this.children.length) {
    //     res.children = this.children.map((x) => x.serialize(0));
    // }

    return res;
  }
}
class DxfDrawing extends Drawing {
  constructor(collection, options) {
    super(collection, options);
    _defineProperty(this, "fileId", void 0);
    _defineProperty(this, "data", void 0);
    _defineProperty(this, "layers", []);
    _defineProperty(this, "blocks", {});
    this.type = 'dxfDrawing';
  }
  updateProperties(data) {
    let changed = super.updateProperties(data);
    if (data) {
      let properties = data.properties;
      if (properties) {
        changed |= updateProperties(this, ['fileId', 'fileName'], properties);
        if (properties.layers) {
          let oldLayers = this.layers;
          this.layers = properties.layers.map(x => {
            let layer = oldLayers.find(y => y.layerID === x.layerID) || new DxfDrawingLayer(this.nanoId);
            layer.updateProperties(x);
            return layer;
          });
        }
        if (properties.blocks) {
          let blocks = {};
          for (let block of properties.blocks) {
            blocks[block.name] = new DxfDrawingBlock(this.nanoId, block);
          }
          this.blocks = blocks;
        }
      }
    }
    if (changed) {
      this.makeDirty();
    }
    return changed;
  }
  serialize() {
    let res = super.serialize();
    if (!res.properties) {
      res.properties = {};
    }
    serializeObject(this, ['fileId', 'fileName'], res.properties);
    if (this.layers.length) {
      res.properties.layers = this.layers.map(x => x.serialize());
    }
    if (Object.values(this.blocks)) {
      res.properties.blocks = Object.values(this.blocks).map(x => x.serialize());
    }
    return res;
  }
}
export { DxfDrawing };