import { ref, watch, computed, onMounted } from 'vue';
import modelData, { compGroupList, selectedThemeId, themeList, selectedType, currThemeWidgets } from "@/model/3Dmodel";
import { each } from "@/utils/tools";
import { toolBarState } from "@/model/globalData";
import { globalState, globalStateProxy, currData } from "@/model/globalData";
import kfMenuItem from "@/components/mainContent/rightPanel/kfMenuItem.vue";
import kfCollapseItem from "@/components/mainContent/rightPanel/kfCollapseItem.vue";
export default {
  name: "kfMenuItem",
  components: {
    kfMenuItem
  },
  props: {
    currSelectValue: {
      type: String,
      default: '0'
    },
    index: {
      type: String,
      default: '0'
    },
    currLabel: {
      type: String,
      default: ''
    },
    typeList: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  setup(props, {
    emit
  }) {
    let handleSelect = function (index, label, type) {
      emit('handleSelect', index, label, type);
    };
    return {
      handleSelect
    };
  }
};