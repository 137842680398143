import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array-buffer.detached.js";
import "core-js/modules/es.array-buffer.transfer.js";
import "core-js/modules/es.array-buffer.transfer-to-fixed-length.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.find-last.js";
import "core-js/modules/es.typed-array.find-last-index.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.to-reversed.js";
import "core-js/modules/es.typed-array.to-sorted.js";
import "core-js/modules/es.typed-array.with.js";
import { Vector2, lineIntersect } from "../maths/vector";
import { buildPipelineMesh } from "./smPipelineBuildTools";
import { globalState, currData } from "@/model/globalData";
import { smMesh2DCache } from "../model/smCollectionData";
class SMPipeline2DBuilder {
  update(data) {
    let scene = data.scene;
    let pipeline = data.obj;
    if (!data.inited) {
      if (!pipeline.finalVisible) {
        return false;
      }
      data.inited = true;

      //init
      let pipelineMesh = new Kingfisher.Mesh(pipeline.name + 'pipeline', scene);
      pipelineMesh.parent = scene._pipelineRoot;
      pipelineMesh.alphaIndex = 20;
      pipelineMesh.renderingGroupId = 1;
      pipelineMesh.visibility = 0.6;
      let pipelineBuilder = new Kingfisher.GeoSplineBuilder(pipelineMesh);
      pipelineBuilder.tessellation = 1;
      pipelineBuilder.vScale = 1000;
      data.pipelineMesh = pipelineMesh;
      data.pipelineBuilder = pipelineBuilder;

      //line init
      let lineMesh = new Kingfisher.Mesh(pipeline.name + '_line', scene);
      lineMesh.parent = scene._pipelineRoot;
      lineMesh.alphaIndex = 21;
      lineMesh.renderingGroupId = 1;
      lineMesh.enableState = 0;
      let lineGeometry = new Kingfisher.Geometry('line' + pipeline.nanoId, scene, undefined, true, lineMesh);
      if (!scene.lineMaterial) {
        let lineMaterial = new Kingfisher.KMaterial('line', scene);
        let c = new Kingfisher.KMatChannel(lineMaterial);
        let lightLine = new Kingfisher.KMatStageNoLight(lineMaterial, c);
        lightLine.colorBase.set(0, 0, 0);
        lineMaterial.alpha = 0.4;
        lineMaterial.wireframe = 1;
        lineMaterial.transparencyMode = 3;
        scene.lineMaterial = lineMaterial;
      }
      lineMesh.material = scene.lineMaterial;
      data.lineMesh = lineMesh;
      data.lineGeometry = lineGeometry;

      //point init
      let pointMesh = new Kingfisher.Mesh(pipeline.name + '_point', scene);
      pointMesh.parent = scene._pipelineRoot;
      let pointGeometry = new Kingfisher.Geometry('point' + pipeline.nanoId, scene, undefined, true, pointMesh);
      pointMesh.renderingGroupId = 1;
      pointMesh.alphaIndex = 22;
      pointMesh.material = scene.getMaterialByName('point');
      data.pointMesh = pointMesh;
      data.pointGeometry = pointGeometry;
      data.pickMeshes = [pipelineMesh];
      data.highlightMeshes = [pipelineMesh];
      data.dispose = function () {
        data.pipelineMesh.dispose();
        data.lineMesh.dispose();
        data.lineGeometry.dispose();
        data.pointMesh.dispose();
        data.pointGeometry.dispose();
      };
      data.focusObject = pipelineMesh;
      data.selected = false;
      data.setSelected = function (selected, mode) {
        data.selected = selected;
        if (selected) {
          lineMesh.enableState = mode === 'line' ? 1 : 0;
          pointMesh.enableState = mode === 'point' ? 1 : 0;
        } else {
          lineMesh.enableState = 0;
          pointMesh.enableState = 0;
        }
      };
    }
    if (!pipeline.finalVisible) {
      data.pointMesh.enableState = false;
      data.lineMesh.enableState = false;
      data.pipelineMesh.enableState = false;
      return false;
    } else {
      data.pointMesh.enableState = true;
      data.lineMesh.enableState = true;
      data.pipelineMesh.enableState = true;
    }
    data.pipelineMesh.material = data.scene.getMaterialByName('pipeline-' + pipeline.purpose) || data.scene.getMaterialByName('pipeline') || Kingfisher.KMaterial.CreateNoLightMaterial('pipeline', scene);
    let i;
    //dirty check
    for (i = 0; i < pipeline.segments.length; i++) {
      let s = pipeline.segments[i];
      s.checkDirty();
    }
    for (i = 0; i < pipeline.points.length; i++) {
      let p = pipeline.points[i];
      p.checkDirty();
    }

    // line build
    let lineGeometry = data.lineGeometry;
    // let positions = new Float32Array(pipeline.segments.length * 2 * 3);
    // let indices = new Uint32Array(pipeline.segments.length * 3);
    let positions = [];
    let indices = [];
    for (i = 0; i < pipeline.segments.length; i++) {
      let s = pipeline.segments[i];
      let b = positions.length / 3;
      positions.push(s.pointA.x, 0, s.pointA.z);
      positions.push(s.pointB.x, 0, s.pointB.z);
      indices.push(b, b, b + 1);
    }
    lineGeometry.setVerticesData(Kingfisher.VertexBuffer.PositionKind, positions, false);
    lineGeometry.setIndices(indices, null, true);

    //point build
    let pointGeometry = data.pointGeometry;
    positions = new Float32Array(pipeline.points.length * 3);
    indices = new Uint32Array(pipeline.points.length * 3);
    for (i = 0; i < pipeline.points.length; i++) {
      let p = pipeline.points[i];
      let t = i * 3;
      positions[t + 0] = p.x;
      positions[t + 1] = 0;
      positions[t + 2] = p.z;
      indices[t + 0] = i;
      indices[t + 1] = i;
      indices[t + 2] = i;
    }
    pointGeometry.setVerticesData(Kingfisher.VertexBuffer.PositionKind, positions, false);
    pointGeometry.setIndices(indices, null, true);
    buildPipelineMesh(pipeline);
    let pipelineBuilder = data.pipelineBuilder;
    pipelineBuilder.splineGroup = smMesh2DCache[pipeline.nanoId].spline2d;
    pipelineBuilder.radius = pipeline.diameter * 0.5;
    if (currData.currList.includes(pipeline)) {
      sm2DMeshBuilder.updateSelectPointAndSegment();
    }
    return true;
  }
}
export default new SMPipeline2DBuilder();