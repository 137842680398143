import { ref, watch } from 'vue';
import pageModel from "@/model/page";
export default {
  name: "textareaProp",
  props: {
    props: {
      type: Object,
      default: () => {
        return {};
      }
    },
    model: {
      type: Object,
      default: {}
    }
  },
  setup(props, {
    emit
  }) {
    const value = ref(pageModel.getPropValue(props.model, props.props.name));
    const handleClear = function () {
      props.model[props.props.name] = "";
      emit("propChange", {
        name: props.props.name,
        value: ""
      });
    };
    const changeContent = function (content) {
      emit("propChange", {
        name: props.props.name,
        value: content
      });
    };
    watch(props, (newValue, oldValue) => {
      value.value = pageModel.getPropValue(props.model, props.props.name);
    });
    return {
      value,
      changeContent,
      handleClear
    };
  }
};