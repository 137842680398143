import { ref, watch, computed } from 'vue';
import { smDatas } from "@/model/smCollectionData";
import { currData, globalState } from "@/model/globalData";
import { wallOptions } from "@/model/purposeOptions";
import { getObjectName, getFloorName, getObjectPurpose, showContextMenu, setProperty, doItemClick, doItemDBClick, loadBodyScrollTop } from "./index.js";
export default {
  name: "wall",
  setup() {
    let currWallType = ref('all');
    let bodyManager = ref(null);
    let visibleObjs = computed(() => {
      console.log("--wall", smDatas.walls.filter(x => !x.isDeleted && x.floorVisible));
      let res = [];
      let _list = smDatas.walls.filter(x => !x.isDeleted && x.floorVisible);
      if ((_list === null || _list === void 0 ? void 0 : _list.length) > 0) {
        if (currWallType.value === 'all') {
          res = _list;
        } else {
          res = _list.filter(x => currWallType.value === x._purpose);
        }
      }
      return res;
    });
    watch(() => currData.currListFlag, () => {
      loadBodyScrollTop(bodyManager, 'walls', 'wall');
    });
    let isActive = function (item) {
      return currData.currListMap.has(item);
    };
    const wallList = computed(() => {
      let res = [{
        value: 'all',
        label: Kf.t('All')
      }];
      if (wallOptions.length > 0) {
        res = res.concat(wallOptions);
      }
      console.log("-wallList-", Kf.t('All'), res);
      return res;
    });
    return {
      smDatas,
      currData,
      doItemDBClick,
      getFloorName,
      getObjectName,
      getObjectPurpose,
      showContextMenu,
      setProperty,
      doItemClick,
      bodyManager,
      visibleObjs,
      isActive,
      wallList,
      currWallType
    };
  }
};