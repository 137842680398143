import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = ["onClick"];
const _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_menu_item = _resolveComponent("el-menu-item");
  const _component_kf_menu_item = _resolveComponent("kf-menu-item", true);
  const _component_el_sub_menu = _resolveComponent("el-sub-menu");
  const _component_el_menu_item_group = _resolveComponent("el-menu-item-group");
  return _openBlock(), _createElementBlock("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.typeList, (item, i) => {
    return _openBlock(), _createBlock(_component_el_menu_item_group, null, {
      default: _withCtx(() => [item.children.length == 0 && item.data.length > 0 ? (_openBlock(), _createBlock(_component_el_menu_item, {
        key: 0,
        index: $props.index + '-' + i
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(item.label), 1)]),
        _: 2
      }, 1032, ["index"])) : _createCommentVNode("", true), item.children.length > 0 ? (_openBlock(), _createBlock(_component_el_sub_menu, {
        key: 1,
        index: $props.index + '-' + i
      }, {
        title: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(item.label), 1), _createElementVNode("div", {
          class: "eventClickDivTitle",
          style: _normalizeStyle({
            'background': $props.currSelectValue == $props.index + '-' + i && $props.currLabel == item.label ? '#367BF5' : 'transparent'
          }),
          onClick: $event => $setup.handleSelect($props.index + '-' + i, item.label, 'none')
        }, null, 12, _hoisted_1)]),
        default: _withCtx(() => [_createElementVNode("div", {
          class: "eventClickDiv",
          onClick: $event => $setup.handleSelect($props.index + '-' + i, item.label)
        }, null, 8, _hoisted_2), item.children ? (_openBlock(), _createBlock(_component_kf_menu_item, {
          key: 0,
          typeList: item.children,
          currSelectValue: $props.currSelectValue,
          index: $props.index + '-' + i,
          currLabel: $props.currLabel,
          onHandleSelect: $setup.handleSelect
        }, null, 8, ["typeList", "currSelectValue", "index", "currLabel", "onHandleSelect"])) : _createCommentVNode("", true)]),
        _: 2
      }, 1032, ["index"])) : _createCommentVNode("", true)]),
      _: 2
    }, 1024);
  }), 256))]);
}