import "core-js/modules/es.array.push.js";
import { Vector2, Vector3, verticalLine3DPoint, lineIntersect, intersectCheckSegmentAndBox } from "@/maths/vector";
import { undoMap } from "@/undo/undoMap";
import { keyboardValue } from "@/utils/keyboard";
import { globalState, currData } from "@/model/globalData";
import { smDatas } from "@/model/smCollectionData";
import pipeline from "@/components/mainContent/rightPanel/property/pipeline.vue";
class SmPipelineCreator {
  constructor(creator) {
    this.type = 'pipelineCreator';
    this.owner = creator;
    this.collection = creator.collection;
    this.tools = creator.tools;
    this.segmentStack = [];
    this.breakSegmentPointList = [];
  }
  mouseRelease(pos, ray) {
    let segment = currData.segment;
    if (globalState.isDrawing && segment) {
      currData.segment = null;
      currData.pipeline.removeSegment(segment);
      let points = this.breakSegmentPointList;
      let lastBreakPoint = points[points.length - 1];
      if (lastBreakPoint && lastBreakPoint === segment.pointA && lastBreakPoint.segments.length === 2) {
        let point = segment.pointA;
        currData.pipeline.joinSegment(point.segments[0], point.segments[1], point);
        currData.pipeline.setAccessoriesNeedSave();
      }
    }
    this.segmentStack = [];
    this.breakSegmentPointList = [];
    globalState.isDrawing = '';
  }
  modeChanged() {
    this.mouseRelease();
  }
  updateText() {
    let segment = currData.segment;
    let currPoint = currData.point;
    if (globalState.isDrawing && segment) {
      let pointA = currData.segment.pointA;
      this.tools.updateTextDegree(segment.pointA, 0, (Math.atan2(currPoint.z - pointA.z, currPoint.x - pointA.x) * 180 / Math.PI).toFixed(1) + '°');
      this.tools.updateTextLength(currPoint, 0, (0.1 * pointA.distance(currPoint)).toFixed() + 'cm');
    } else {
      this.tools.updateTextDegree();
      this.tools.updateTextLength();
    }
  }
  mouseTap(pos, ray, adsorb) {
    if (!currData.pipeline) {
      let pipeline = this.pickObject(pos, ray, adsorb);
      if (pipeline) {
        currData.pipeline = pipeline;
        return;
      }
      if (currData.floor && !currData.floor.visible) {
        return;
      }
      if (!currData.floor) {
        let floor = smDatas.createFloor();
        floor.setNeedSave();
        currData.floor = floor;
      }
      pipeline = this.collection.createPipeline();
      pipeline.floor = currData.floor.nanoId;
      pipeline.setNeedSave();
      undoMap.push({
        cmd: 'create',
        value: pipeline
      });
      currData.pipeline = pipeline;
    }
    if (currData.pipeline && currData.pipeline.finalVisible && !currData.pipeline.locked && !currData.subType) {
      if (!adsorb || !globalState.isDrawing || !currData.segment || adsorb.point3d !== currData.segment.pointA) {
        if (!currData.undoData) {
          currData.undoData = {
            cmd: 'updateObj',
            target: currData.pipeline,
            value: currData.pipeline.serialize()
          };
        }
        if (!globalState.isDrawing) {
          currData.point = null;
        }
        this.createPoint(pos, ray, adsorb);
        currData.pipeline.setNeedSave();
        currData.setCurrList(currData.pipeline);
      }
    }
  }
  mouseUp(pos, ray, adsorb) {
    let dragData = this.dragData;
    if (dragData) {
      this.dragData = null;
      globalState.isDrawing = '';
      if (dragData.type === 'breakControl') {
        currData.point = null;
      }
    }
  }
  mouseMove(pos, ray, adsorb) {
    this.updatePoint(pos, adsorb);
  }
  createPoint(pos, ray, adsorb) {
    let pipeline = currData.pipeline;
    let currSegment = currData.segment;
    if (adsorb) {
      if (adsorb.point) {
        pos.x = adsorb.point.x;
        pos.y = adsorb.point.y;
        if (pipeline.points.indexOf(adsorb.point3d) >= 0) {
          if (currSegment) {
            let pointA = currSegment.pointA;
            let seg = pipeline.createSegment(pointA, adsorb.point3d);
            pipeline.removeSegment(currSegment);
            currData.segment = seg;
            currSegment = seg;
          }
          currData.point = adsorb.point3d;
        }
      } else {
        if (adsorb.pointX) {
          pos.x = adsorb.pointX.x;
        }
        if (adsorb.pointY) {
          pos.y = adsorb.pointY.y;
        }
      }
    }
    let height = 0;
    if (currData.point) {
      height = currData.point.y;
    } else {
      let params = globalState.toolBarParams.pipeline;
      height = params.height;
    }
    let pos3 = {
      x: pos.x,
      y: height,
      z: pos.y
    };
    let point = currData.point || pipeline.createPoint(pos3);
    point.x = Math.floor(point.x);
    point.y = Math.floor(point.y);
    point.z = Math.floor(point.z);
    currData.point = pipeline.createPoint(pos3);
    if (currSegment) {
      this.segmentStack.push(currSegment);
    }
    if (adsorb.segment && pipeline.segments.indexOf(adsorb.segment) >= 0) {
      pipeline.breakSegment(adsorb.segment, point);
      pipeline.setAccessoriesNeedSave();
      this.breakSegmentPointList.push(point);
    }
    currData.segment = pipeline.createSegment(point, currData.point);
    globalState.isDrawing = 'line';
  }
  keyboard(event) {
    if (event.keyCode === 8 || event.keyCode === 46) {
      if (currData.pipeline && currData.point && this.segmentStack.length) {
        let oldPoint = currData.point;
        // currData.wall.removePoint(oldPoint);

        let pointA = currData.segment.pointA;
        currData.pipeline.removeSegment(currData.segment);
        if (pointA.segments.length > 1) {
          currData.point = currData.pipeline.createPoint(currData.point);
          let seg = this.segmentStack.pop();
          currData.segment = currData.pipeline.createSegment(seg.pointA, currData.point);
          currData.pipeline.removeSegment(seg);
          if (pointA.segments.length === 2 && this.breakSegmentPointList.indexOf(pointA) >= 0) {
            currData.pipeline.joinSegment(pointA.segments[0], pointA.segments[1], pointA);
          }
        } else {
          currData.point = pointA;
          currData.segment = this.segmentStack.pop();
        }
        this.updatePoint(oldPoint);
        return true;
      }
    }
  }
  updatePoint(pos, adsorb) {
    let segment = currData.segment;
    let dragData = globalState.dragData;
    let needUpdate = globalState.isDrawing || dragData && dragData.type === 'breakControl';
    if (segment && needUpdate) {
      let pointA = segment.pointA.xz;
      let shiftKey = adsorb && adsorb.event && adsorb.event.shiftKey;
      let adsorbdirection = shiftKey ? 90 : this.owner.settings.adsorbdirection;
      let directionToDegree = 180 / Math.PI;
      let degreeToDegree = Math.PI / 180;
      if (adsorb && adsorb.enableAdsorb) {
        if (adsorb.point) {
          if (!adsorb.segment) {
            pos.x = adsorb.point.x;
            pos.y = adsorb.point.y;
          } else {
            if (!adsorb.segment.isArc) {
              let dx = pos.x - pointA.x;
              let dy = pos.y - pointA.y;
              let r = Math.round(Math.atan2(dy, dx) * directionToDegree / adsorbdirection) * adsorbdirection * degreeToDegree;
              let point = lineIntersect(adsorb.segment.pointA.xz, adsorb.segment.pointB.xz, pointA, {
                x: pointA.x + Math.cos(r),
                y: pointA.y + Math.sin(r)
              });
              if (point) {
                pos.x = point.x;
                pos.y = point.y;
              } else {
                let d = Math.sqrt(dx * dx + dy * dy);
                pos.x = Math.round(Math.cos(r) * d * 10) * 0.1 + pointA.x;
                pos.y = Math.round(Math.sin(r) * d * 10) * 0.1 + pointA.y;
              }
            } else {
              pos.x = adsorb.point.x;
              pos.y = adsorb.point.y;
            }
          }
        } else if (adsorb.pointX && adsorb.pointY) {
          pos.x = adsorb.pointX.x;
          pos.y = adsorb.pointY.y;
        } else if (adsorb.pointX) {
          pos.x = adsorb.pointX.x;
          let dx = pos.x - pointA.x;
          let dy = pos.y - pointA.y;
          let r = Math.round(Math.atan2(dy, dx) * directionToDegree / adsorbdirection) * adsorbdirection * degreeToDegree;
          pos.y = (r / Math.PI + 2) % 1 === 0.5 ? pos.y : dx * Math.tan(r) + pointA.y;
        } else if (adsorb.pointY) {
          pos.y = adsorb.pointY.y;
          let dx = pos.x - pointA.x;
          let dy = pos.y - pointA.y;
          let r = Math.round(Math.atan2(dy, dx) * directionToDegree / adsorbdirection) * adsorbdirection * degreeToDegree;
          pos.x = (r / Math.PI + 2) % 1 === 0 ? pos.x : dy / Math.tan(r) + pointA.x;
        } else {
          let dx = pos.x - pointA.x;
          let dy = pos.y - pointA.y;
          let r = Math.round(Math.atan2(dy, dx) * directionToDegree / adsorbdirection) * adsorbdirection * degreeToDegree;
          if (shiftKey) {
            if (Math.abs(dx) < Math.abs(dy)) {
              pos.y = (r / Math.PI + 2) % 1 === 0.5 ? pos.y : dx * Math.tan(r) + pointA.y;
            } else {
              pos.x = (r / Math.PI + 2) % 1 === 0 ? pos.x : dy / Math.tan(r) + pointA.x;
            }
          } else {
            let d = Math.sqrt(dx * dx + dy * dy);
            pos.x = Math.round(Math.cos(r) * d * 10) * 0.1 + pointA.x;
            pos.y = Math.round(Math.sin(r) * d * 10) * 0.1 + pointA.y;
          }
        }
        if (shiftKey) {
          if (Math.abs(pos.x - pointA.x) < Math.abs(pos.y - pointA.y)) {
            pos.x = pointA.x;
          } else {
            pos.y = pointA.y;
          }
        }
      }
      pos.x = Math.floor(pos.x);
      pos.y = Math.floor(pos.y);
      if (currData.point) {
        currData.point.x = pos.x;
        currData.point.z = pos.y;
        currData.point.makeDirty();
      } else if (dragData) {
        this.dragData = dragData;
        if (dragData.type === 'breakControl') {
          let pos3 = {
            x: pos.x,
            y: 0,
            z: pos.y
          };
          let point = currData.pipeline.createPoint(pos3);
          let segs = segment.breakSegment(point);
          currData.point = point;
          currData.segment = segs[0];
          this.breakSegmentPointList.push(point);
        }
      }
    }
  }
  pickPoint(pos, ray, picker) {
    let currPoint = currData.point;
    let px = pos.x;
    let py = currPoint ? currPoint.y : 0;
    let pz = pos.y;
    let pipelines = this.collection.pipelines;
    let d2 = picker.distance2;
    let point;
    let distanceSquared = function (p) {
      let dx = px - p.x;
      let dy = py - p.y;
      let dz = pz - p.z;
      return dx * dx + dy * dy + dz * dz;
    };
    let pickPipelinePoint = function (pipeline) {
      if (!pipeline || !pipeline.finalVisible) {
        return;
      }
      for (let p of pipeline.points) {
        if (p === currPoint) {
          continue;
        }
        let d2_ = distanceSquared(p);
        if (d2 > d2_) {
          d2 = d2_;
          point = p;
        }
      }
    };
    pickPipelinePoint(currData.pipeline);
    for (let i = 0; i < pipelines.length; i++) {
      let pipeline = pipelines[i];
      if (pipeline === currData.pipeline) {
        continue;
      }
      pickPipelinePoint(pipeline);
    }
    if (d2 < picker.tolerant2 && point) {
      picker.distance2 = d2;
      picker.points.push(point.xz);
      picker.point3d = point;
      return point;
    }
  }
  pickVerticalPoint(pos, ray, picker) {
    let currSegment = currData.segment;
    let pipelines = this.collection.pipelines;
    let d2 = picker.distance2;
    let point;
    let segment;
    let segmentLocalPosition;
    let segmentFront = null;
    let px = pos.x;
    let py = pos.y;
    let distanceSquared = function (p) {
      let dx = px - p.x;
      let dy = py - p.y;
      return dx * dx + dy * dy;
    };
    let pickPipelineVerticalPoint = function (pipeline) {
      if (!pipeline || !pipeline.finalVisible) {
        return;
      }
      let segments = pipeline.segments;
      for (let i = 0; i < segments.length; i++) {
        let s = segments[i];
        if (s === currSegment) {
          continue;
        }
        let p = verticalLine3DPoint(s.pointA, s.pointB, pos);
        if (p) {
          let d2_ = distanceSquared(p);
          if (d2 > d2_) {
            d2 = d2_;
            point = p;
            segment = s;
            segmentLocalPosition = Math.sqrt(Math.pow(s.pointA.x - p.x, 2) + Math.pow(s.pointA.z - p.y, 2));
          }
        }
      }
    };
    pickPipelineVerticalPoint(currData.pipeline);
    for (let i = 0; i < pipelines.length; i++) {
      let pipeline = pipelines[i];
      if (pipeline === currData.pipeline) {
        continue;
      }
      pickPipelineVerticalPoint(pipeline);
    }
    if (d2 < picker.tolerant2 && point) {
      picker.distance2 = d2;
      picker.points.push(point);
      picker.point3d = null;
      picker.segments.push(segment);
      picker.segmentFront = segmentFront;
      picker.segmentLocalPosition = segmentLocalPosition;
      return point;
    }
  }
  pickXYPoints(pos, ray, picker) {
    let currPoint = currData.point;
    let pipelines = this.collection.pipelines;
    let dx = picker.distanceX;
    let dy = picker.distanceY;
    let lx = picker.LengthX;
    let ly = picker.LengthY;
    let l_ = picker.tolerant;
    let pointX;
    let pointY;
    let pickPipelineXYPoints = function (pipeline) {
      if (!pipeline || !pipeline.finalVisible) {
        return;
      }
      for (let p of pipeline.points) {
        if (p === currPoint) {
          continue;
        }
        let dx_ = Math.abs(pos.x - p.x);
        let dy_ = Math.abs(pos.y - p.z);
        if (dx > dx_ || Math.abs(dx - dx_) < l_ && lx > dy_) {
          dx = dx_;
          lx = dy_;
          pointX = p;
        }
        if (dy > dy_ || Math.abs(dy - dy_) < l_ && ly > dx_) {
          dy = dy_;
          ly = dx_;
          pointY = p;
        }
      }
    };
    pickPipelineXYPoints(currData.pipeline);
    for (let i = 0; i < pipelines.length; i++) {
      let pipeline = pipelines[i];
      if (pipeline === currData.pipeline) {
        continue;
      }
      pickPipelineXYPoints(pipeline);
    }
    if (dx < picker.tolerant && pointX) {
      picker.pointX = pointX.xz;
      picker.pointX3d = pointX;
      picker.distanceX = dx;
      picker.LengthX = lx;
    }
    if (dy < picker.tolerant && pointY) {
      picker.pointY = pointY.xz;
      picker.pointY3d = pointY;
      picker.distanceY = dy;
      picker.LengthY = ly;
    }
  }
  pickByAreaBox(pos, ray, picker) {
    let pipelines = this.collection.pipelines;
    let pos0 = picker.pos0;
    let pos1 = pos;
    let x0 = Math.min(pos0.x, pos1.x);
    let x1 = Math.max(pos0.x, pos1.x);
    let y0 = Math.min(pos0.y, pos1.y);
    let y1 = Math.max(pos0.y, pos1.y);
    for (let pipeline of pipelines) {
      if (pipeline.isDeleted || !pipeline.finalVisible) {
        continue;
      }
      let bbox = pipeline.boundingBox;
      if (bbox && bbox[0] <= x1 && bbox[2] <= y1 && bbox[3] >= x0 && bbox[5] >= y0) {
        for (let seg of pipeline.segments) {
          if (!seg.isArc) {
            if (intersectCheckSegmentAndBox(seg.pointA, seg.pointB, x0, y0, x1, y1)) {
              picker.objs.push(pipeline);
              break;
            }
          }
        }
      }
    }
  }
  pickPointByAreaBox(pos, ray, picker) {
    let pipeline = currData.currList[0];
    if (!pipeline || pipeline.type !== 'pipeline' || pipeline.isDeleted || !pipeline.finalVisible) {
      return;
    }
    let pos0 = picker.pos0;
    let pos1 = pos;
    let x0 = Math.min(pos0.x, pos1.x);
    let x1 = Math.max(pos0.x, pos1.x);
    let y0 = Math.min(pos0.y, pos1.y);
    let y1 = Math.max(pos0.y, pos1.y);
    let bbox = pipeline.boundingBox;
    if (bbox && bbox[0] <= x1 && bbox[2] <= y1 && bbox[3] >= x0 && bbox[5] >= y0) {
      for (let p of pipeline.points) {
        if (p.x >= x0 && p.x <= x1 && p.z >= y0 && p.z <= y1) {
          picker.points.push(p);
        }
      }
    }
  }
  pickSegmentByAreaBox(pos, ray, picker) {
    let pipeline = currData.currList[0];
    if (!pipeline || pipeline.type !== 'pipeline' || pipeline.isDeleted || !pipeline.finalVisible) {
      return;
    }
    let pos0 = picker.pos0;
    let pos1 = pos;
    let x0 = Math.min(pos0.x, pos1.x);
    let x1 = Math.max(pos0.x, pos1.x);
    let y0 = Math.min(pos0.y, pos1.y);
    let y1 = Math.max(pos0.y, pos1.y);
    let bbox = pipeline.boundingBox;
    if (bbox && bbox[0] <= x1 && bbox[2] <= y1 && bbox[3] >= x0 && bbox[5] >= y0) {
      for (let seg of pipeline.segments) {
        if (!seg.isArc) {
          if (intersectCheckSegmentAndBox(seg.pointA, seg.pointB, x0, y0, x1, y1)) {
            picker.segments.push(seg);
          }
        }
      }
    }
  }
  pickObject(pos, ray, picker) {
    if (picker.objs.length) {
      return;
    }
    let pipelines = this.collection.pipelines;
    let meshBuilder = window.sm2DMeshBuilder;
    let pickPipeline = function (pipeline) {
      if (!pipeline || !pipeline.finalVisible || !meshBuilder.objects[pipeline.nanoId]) {
        return;
      }
      let meshes = meshBuilder.objects[pipeline.nanoId].pickMeshes;
      if (meshes) {
        for (let i = 0; i < meshes.length; i++) {
          meshes[i]._updateBoundingInfo();
          let pickingInfo = ray.intersectsMesh(meshes[i]);
          if (pickingInfo.pickedMesh) {
            return pipeline;
          }
        }
      }
    };
    let res = pickPipeline(currData.pipeline);
    if (res) {
      this.pickVerticalPoint(pos, ray, picker);
      picker.objs.push(res);
      return picker;
    }
    for (let i = 0; i < pipelines.length; i++) {
      let pipeline = pipelines[i];
      if (pipeline === currData.pipeline) {
        continue;
      }
      res = pickPipeline(pipeline);
      if (res) {
        this.pickVerticalPoint(pos, ray, picker);
        picker.objs.push(res);
        return picker;
      }
    }
  }
}
export { SmPipelineCreator };