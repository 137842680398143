import _defineProperty from "F:/workspace/\u7A7A\u95F4\u6CBB\u7406\u5DE5\u5177/\u7A7A\u95F4\u6CBB\u7406CAD/space-management/spaceManager/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import { SMDirtyPosition, SMDirtyReticular, SMDirtyAll } from './smDirtyFlag';
import { matrix2DIsIdentity, transform2DPoint } from "@/maths/vector";
import { Vector2 } from "../maths/vector";
import { updateProperties, registerSMType, serializeObject, SMObject, getUID } from "./smObject";
import { Point2D } from "./sm2DPoint";
import { base64ArrayBuffer } from "@/utils/tools";
import { buildAreaMesh } from "@/builder/smAreaBuildTools";
import { calcArea, getCenter } from "@/utils/math";
import { smDatas, smMesh3DCache } from "../model/smCollectionData";
class AreaPoint extends Point2D {
  constructor(x, y, area) {
    super(x, y);
    _defineProperty(this, "area", void 0);
    _defineProperty(this, "segments", []);
    _defineProperty(this, "dirtyFlag", SMDirtyAll);
    this.area = area;
  }
  makeDirty(flag = SMDirtyAll) {
    this.dirtyFlag |= flag;
    if (flag & SMDirtyPosition) {
      smDatas.objects[this.area].makeDirty(flag);
    }
  }
  checkDirty() {
    if (!this.dirtyFlag) {
      return;
    }
    this.dirtyFlag = false;
  }
  setNeedSave() {
    smDatas.objects[this.area].setNeedSave();
  }
  updateProperties(data) {
    if (super.updateProperties(data)) {
      this.makeDirty(SMDirtyPosition);
      return true;
    }
    return false;
  }
}
class AreaSegment {
  makeDirty(flag = SMDirtyAll) {
    if ((this.dirtyFlag & flag) === flag) {
      return;
    }
    this.dirtyFlag |= flag;
    this.pointA.makeDirty(flag);
    this.pointB.makeDirty(flag);
    smDatas.objects[this.area].makeDirty(flag);
  }
  constructor(a, b, area, options) {
    _defineProperty(this, "pointA", void 0);
    _defineProperty(this, "pointB", void 0);
    _defineProperty(this, "angle", void 0);
    _defineProperty(this, "dirtyFlag", SMDirtyAll);
    Object.defineProperty(this, 'uid', {
      value: getUID()
    });
    this.area = area;
    this.pointA = a;
    this.pointB = b;
    if (options) {
      this.angle = options.angle;
    }
  }
  get isArc() {
    if (this.angle && !Number.isNaN(this.angle)) {
      this.checkDirty();
      return true;
    }
    return false;
  }
  checkDirty() {
    if (!this.dirtyFlag) {
      return;
    }
    this.dirtyFlag = 0;
    let PI = Math.PI;
    let PI2 = PI * 2;
    let dx = this.pointB.x - this.pointA.x;
    let dy = this.pointB.y - this.pointA.y;
    let length = Math.sqrt(dx * dx + dy * dy);
    if (this.isArc) {
      if (!this.center) {
        this.center = new Vector2();
      }
      if (!this.normalA) {
        this.normalA = new Vector2();
        this.normalB = new Vector2();
      }
      if (length || this.angle) {
        let angle = this.angle;
        let angle_2 = angle * 0.5;
        if (length) {
          let x = dx / length;
          let y = dy / length;
          this.dx = x;
          this.dy = y;
          this.direction = (Math.atan2(-x, y) + PI2) % PI2;
          let xd = (this.pointB.x + this.pointA.x) * 0.5;
          let yd = (this.pointB.y + this.pointA.y) * 0.5;
          let k = length * 0.5 / Math.tan(angle_2);
          this.radius = Math.sqrt(length * length * 0.25 + k * k);
          this.center.set(xd - y * k, yd + x * k);
        } else {
          let xd = this.pointB.x - this.center.x;
          let yd = this.pointB.y - this.center.y;
          let radius = Math.sqrt(xd * xd + yd * yd);
          let x = radius ? xd / radius : 0;
          let y = radius ? yd / radius : 0;
          if (radius) {
            this.dx = x;
            this.dy = y;
            this.direction = (Math.atan2(-y, -x) + PI2) % PI2;
          } else {
            this.dx = 0;
            this.dy = 0;
            this.direction = 0;
          }
          this.radius = radius;
        }
        let directionA = (this.direction - angle_2 + PI2) % PI2;
        let directionB = (this.direction + angle_2 + PI) % PI2;
        this.directionA = directionA;
        this.directionB = directionB;
        this.normalA.set(Math.cos(directionA), Math.sin(directionA));
        this.normalB.set(-Math.cos(directionB), -Math.sin(directionB));
        this.tessellation = Math.ceil(Math.abs(angle) * Math.min(25, this.radius * 0.02));
        this.stepAngle = angle / (this.tessellation + 1);
        this.length = Math.abs(this.radius * angle);
      } else {
        this.dx = 0;
        this.dy = 0;
        this.length = length;
        this.direction = 0;
        this.directionA = 0;
        this.directionB = 0;
        this.directionA = 0;
        this.directionB = 0;
      }
    } else {
      let x = dx / length;
      let y = dy / length;
      this.dx = x;
      this.dy = y;
      this.length = length;
      this.direction = (Math.atan2(-x, y) + PI2) % PI2;
      this.directionA = this.direction;
      this.directionB = (this.direction + PI) % PI2;
    }
  }
  updateProperties(data) {
    let angle = data ? data.angle : undefined;
    let changed = false;
    if (this.angle !== angle) {
      changed = true;
      this.angle = angle;
    }
    if (data && data.center) {
      if (!this.center) {
        this.center = new Vector2();
      }
      if (this.center.x !== data.center[0] || this.center.y !== data.center[1]) {
        this.center.set(data.center[0], data.center[1]);
        changed = true;
      }
    }
    if (changed) {
      this.makeDirty(SMDirtyReticular);
    }
    return changed;
  }
  serialize() {
    let area = smDatas.objects[this.area];
    let a = area.points.findIndex(x => x.uid === this.pointA.uid);
    let b = area.points.findIndex(x => x.uid === this.pointB.uid);
    let res = [a, b];
    let options = null;
    if (this.angle && !Number.isNaN(this.angle)) {
      if (!options) {
        options = {};
      }
      this.checkDirty();
      options.angle = this.angle;
      if (Math.abs(Math.abs(this.angle) - Math.PI * 2) < 0.0001) {
        options.center = [this.center.x, this.center.y];
      }
    }
    if (options) {
      res.push(options);
    }
    return res;
  }
}
class Area extends SMObject {
  constructor(collection, options) {
    super(collection, options);
    _defineProperty(this, "points", []);
    _defineProperty(this, "segments", []);
    _defineProperty(this, "area", 0);
    _defineProperty(this, "textPos", new Vector2(0, 0));
    _defineProperty(this, "fixAreaValue", false);
    _defineProperty(this, "fixTextPos", false);
    _defineProperty(this, "_showName", true);
    _defineProperty(this, "_showCustomId", true);
    _defineProperty(this, "_showArea", false);
    _defineProperty(this, "_purpose", '');
    this.type = 'area';
  }
  set purpose(value) {
    if (this._purpose === value) {
      return;
    }
    this._purpose = value;
    this.makeDirty();
  }
  get purpose() {
    return this._purpose;
  }
  set showName(value) {
    if (this._showName === value) {
      return;
    }
    this._showName = value;
    this.makeDirty();
  }
  get showName() {
    return this._showName;
  }
  get showCustomId() {
    return this._showCustomId;
  }
  set showArea(value) {
    if (this._showArea === value) {
      return;
    }
    this._showArea = value;
    this.makeDirty();
  }
  set showCustomId(value) {
    if (this._showCustomId === value) {
      return;
    }
    this._showCustomId = value;
    this.makeDirty();
  }
  get showArea() {
    return this._showArea;
  }
  createPoint(point, index = -1) {
    let points = this.points;
    if (index < 0 || index > points.length) {
      index = points.length;
    } else if (index < 0) {
      index = 0;
    }
    let p = point ? new AreaPoint(point.x, point.y, this.nanoId) : new AreaPoint(0, 0, this.nanoId);
    points.splice(index, 0, p);
    if (points.length > 1) {
      let segments = this.segments;
      let seg = segments[(index - 1 + segments.length) % segments.length];
      seg.pointB = p;
    }
    let newSeg = new AreaSegment(p, this.points[(index + 1) % points.length], this.nanoId);
    this.segments.splice(index, 0, newSeg);
    this.makeDirty(SMDirtyReticular);
    return p;
  }
  removePoint(point) {
    let points = this.points;
    let index = points.findIndex(x => x.uid === point.uid);
    if (index < 0) {
      return;
    }
    points.splice(index, 1);
    if (points.length) {
      let segments = this.segments;
      segments.splice(index, 1);
      let length = points.length;
      index = index % length;
      let lastIndex = (index - 1 + length) % length;
      segments[lastIndex].pointB = points[index];
    } else {
      this.segments = [];
    }
    this.makeDirty(SMDirtyReticular);
  }
  cleanPoint() {
    if (this.points.length) {
      this.points.splice(0, this.points.length);
      this.segments = [];
      this.makeDirty(SMDirtyReticular);
    }
  }
  makeDirty(flag = SMDirtyAll) {
    super.makeDirty(flag);
    smMesh3DCache[this.nanoId] = null;
  }
  transform(matrix) {
    if (matrix2DIsIdentity(matrix)) {
      return;
    }
    this.makeDirty(SMDirtyPosition);
    let points = this.points;
    for (let i = 0; i < points.length; i++) {
      let p = points[i];
      transform2DPoint(p, matrix);
      p.dirtyFlag |= SMDirtyPosition;
    }
    transform2DPoint(this.textPos, matrix);
  }
  updateArea() {
    if (!this.fixAreaValue) {
      this.area = calcArea(this);
    }
    if (!this.fixTextPos) {
      this.textPos = getCenter(this.points);
    }
  }
  updateProperties(data) {
    let changed = super.updateProperties(data);
    if (data) {
      let properties = data.properties;
      let geometry = data.geometry;
      if (geometry) {
        let points = this.points;
        let segments = this.segments;
        if (points.length > geometry.coordinates.length) {
          points.splice(geometry.coordinates.length, points.length - geometry.coordinates.length);
          segments.splice(geometry.coordinates.length, segments.length - geometry.coordinates.length);
          changed = true;
        }
        for (let i = 0; i < geometry.coordinates.length; i++) {
          let p = this.points[i];
          if (!p) {
            p = new AreaPoint(0, 0, this.nanoId);
            points.push(p);
            changed = true;
          }
          changed |= p.updateProperties(geometry.coordinates[i]);
        }
        for (let i = 0; i < points.length; i++) {
          let seg = segments[i];
          if (!seg) {
            seg = new AreaSegment(points[i], points[(i + 1) % points.length], this.nanoId);
            segments.push(seg);
          } else {
            seg.pointA = points[i];
            seg.pointB = points[(i + 1) % points.length];
          }
          let temp = properties.segments ? properties.segments[i] : null;
          changed |= seg.updateProperties(temp ? temp[2] : null);
        }
      }
      if (properties) {
        changed |= updateProperties(this, ['area', 'purpose', 'showName', 'showArea', 'textPos', 'showCustomId', 'fixAreaValue', 'fixTextPos'], properties);
        this.properties = data.properties.properties || {};
      }
    }
    if (changed) {
      this.makeDirty();
    }
    return changed;
  }
  serialize() {
    let res = super.serialize();
    if (!res.properties) {
      res.properties = {};
    }
    serializeObject(this, ['area', 'purpose', 'showName', 'showArea', 'textPos', 'showCustomId', 'fixAreaValue', 'fixTextPos'], res.properties);
    res.properties.properties = this.properties;
    if (!res.geometry) {
      res.geometry = {
        type: 'Polygon',
        coordinates: []
      };
    }
    let mesh3d = (smMesh3DCache[this.nanoId] || buildAreaMesh(this)).toArrayBuffers();
    res.geometry.mesh3d = mesh3d.map(x => base64ArrayBuffer(x));
    let coordinates = res.geometry.coordinates;
    for (let p of this.points) {
      coordinates.push(p.serialize());
    }
    if (this.segments.find(x => x.isArc)) {
      res.properties.segments = this.segments.map(x => x.serialize());
    }
    return res;
  }
}
registerSMType('areaPoint', AreaPoint);
export { Area };