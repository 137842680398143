import "core-js/modules/es.array.push.js";
import KingfisherService from '../utils/request';
import FetchService from '../utils/fetch';
import Cookies from 'js-cookie';
import contentModel from "@/model/newContent";
class ProjectService {
  /**
   * Creates an instance of ProjectService.
   * @memberOf  ProjectService
   */
  constructor() {
    this.module = 'project';
    this.kingfisherService = new KingfisherService({});
    this.fetchService = new FetchService({});
  }

  /**
   * 获取完整URL
   * @returns url
   */
  getUrl(url) {
    if (!url.startsWith("http")) {
      return this.kingfisherService.host + url;
    }
    return url;
  }

  /**
   * 获取所有
   * @returns {jQuery|Deferred} Ajax请求返回并处理后的Promise
   */
  getAll() {
    return this.kingfisherService.get("/repo/kf-project/get-all").then(response => {
      return response;
    });
  }

  /**
   * 获取市场项目
   * @returns {jQuery|Deferred} Ajax请求返回并处理后的Promise
   */
  getAllMarket() {
    return this.kingfisherService.get("/repo/kf-project/get-all-market").then(response => {
      return response;
    });
  }

  /**
   * 心跳
   * @returns {jQuery|Deferred} Ajax请求返回并处理后的Promise
   */
  heartbeat() {
    try {
      console.log("发送心跳");
      let memoryRatio = window.performance.memory.totalJSHeapSize / window.performance.memory.jsHeapSizeLimit * 100;
      if (memoryRatio < 50) {
        console.log("内存使用:" + memoryRatio.toFixed(2) + "%");
      } else if (memoryRatio < 80) {
        console.warn("内存使用:" + memoryRatio.toFixed(2) + "%, 内存使用接近临界");
      } else {
        console.error("内存使用:" + memoryRatio.toFixed(2) + "%, 内存使用超过临界");
      }
      if (window.ventuz) {
        window.ventuz.heartbeat = new Date().getTime().toString();
      }
      return this.kingfisherService.get(`/repo/kf-project/heart`).then(response => {
        console.log("收到心跳, 服务器正常");
        return response;
      });
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * 登录
   * @returns {jQuery|Deferred} Ajax请求返回并处理后的Promise
   */
  login() {
    return this.fetchService.post("/sso", {
      username: "admin",
      password: "kfdev178"
    }).then(response => {
      Cookies.set("DESIGNER_TOKEN", response.data.createBy);
      return response.data;
    });
  }
  getProject(projectId) {
    return this.kingfisherService.get(`/repo/kf-project/${projectId}`).then(response => {
      return response;
    });
  }

  /**
   * 查询场景
   * @returns {Promise<T>}
   */
  getSpace(params) {
    return this.kingfisherService.post(`/repo/kf-space/find-building/${params.projectId}/`, JSON.stringify(params)).then(response => {
      return response;
    });
  }

  /**
   * 查询场景内资源
   * @returns {Promise<T>}
   */
  findSpace(params) {
    return this.kingfisherService.post(`/repo/kf-space/find-space-geo/`, JSON.stringify(params)).then(response => {
      return response;
    });
  }

  /**
   * 新建场景内资源
   * @returns {Promise<T>}
   */
  saveSpace(params) {
    return this.kingfisherService.post(`/repo/kf-space/save-space/`, JSON.stringify(params)).then(response => {
      return response;
    });
  }

  /**
   * 更新场景内资源
   * @returns {Promise<T>}
   */
  updateSpace(params) {
    return this.kingfisherService.post(`/repo/kf-space/update-space/`, JSON.stringify(params)).then(response => {
      return response;
    });
  }

  /**
   * 删除场景内资源
   * @returns {Promise<T>}
   */
  deleteSpace(params) {
    return this.kingfisherService.delete(`/repo/kf-space/del-space/`, JSON.stringify(params)).then(response => {
      return response;
    });
  }

  /**
   * 删除建筑
   * @returns {Promise<T>}
   */
  deleteBuilding(uuid) {
    return this.kingfisherService.delete(`/repo/kf-space/del-space/${uuid}`).then(response => {
      return response;
    });
  }

  // /**
  //  * 创建场景
  //  * @returns {jQuery|Deferred} Ajax请求返回并处理后的Promise
  //  */
  // createScene(params) {
  //   return this.kingfisherService.post("/repo/kf-scene-3d/create-scene/",JSON.stringify(params)).then(response => {
  //     return response;
  //   });
  // }
  //
  //
  // /**
  //  * 删除场景
  //  * @returns {jQuery|Deferred} Ajax请求返回并处理后的Promise
  //  */
  // deleteScene(sceneId) {
  //   return this.kingfisherService.delete(`/repo/kf-scene-3d/del-scene/${sceneId}`).then(response => {
  //     return response;
  //   });
  // }
  //
  /**
   * 更新场景
   * @param projectId 项目ID
   * @returns {Promise<T>}
   */
  updateScenes(params) {
    return this.kingfisherService.post("/repo/kf-space/update-space/", [params]).then(response => {
      return response;
    });
  }

  /**
   * 保存场景资源文件
   * @param projectUuid 项目Id
   * @param assets 资源
   * @returns {*}
   */
  saveSceneAssets(projectUuid, assets) {
    // if (assets) {
    //   assets.forEach(_a => {
    //     if (_a.content) {
    //       _a.content = Array.from(new Uint8Array(_a.content));
    //     }
    //   });
    // }

    const _assetVo = {
      projectUuid: projectUuid,
      infos: assets
    };
    return this.kingfisherService.post(`/repo/kf-space/upload-File`, _assetVo).then(res => {
      return res;
    });
  }

  /**
   * 获取项目版本列表
   * @returns {jQuery|Deferred} Ajax请求返回并处理后的Promise
   */
  getSpaceVersion(projectId) {
    return this.kingfisherService.get(`/repo/kf-space/get-space-version/${projectId}`).then(response => {
      return response;
    });
  }

  /**
   * 保存当前版本
   * @returns {jQuery|Deferred} Ajax请求返回并处理后的Promise
   */
  addSpaceVersion(project) {
    return this.kingfisherService.post(`/repo/kf-space/add-version?projectId=` + project, '').then(res => {
      return res;
    });
  }

  /**
   * 回退当前版本
   * @returns {jQuery|Deferred} Ajax请求返回并处理后的Promise
   */
  backSpaceVersion(project, version) {
    return this.kingfisherService.post(`/repo/kf-space/back-version?projectId=` + project + `&version=` + version, '').then(res => {
      return res;
    });
  }

  /**
   * 创建项目
   * @returns {jQuery|Deferred} Ajax请求返回并处理后的Promise
   */
  createProject(params) {
    return this.kingfisherService.post("/repo/kf-project/createProject", JSON.stringify(params)).then(response => {
      return response;
    });
  }

  /**
   * 获取区域属性配置
   * @returns {jQuery|Deferred} Ajax请求返回并处理后的Promise
   */
  findAreaPurpose(projectId) {
    return this.kingfisherService.get(`/repo/kf-space/find-area-purpose/${projectId}`).then(response => {
      return response;
    });
  }

  /**
   * 保存区域属性配置
   * @returns {jQuery|Deferred} Ajax请求返回并处理后的Promise
   */
  saveAreaPurpose(params) {
    return this.kingfisherService.post(`/repo/kf-space/save-area-purpose`, JSON.stringify(params)).then(response => {
      return response;
    });
  }

  /**
   *
   *
   * */
  /*  uploadDevicesPoint(file,projectId) {
      const fd = new FormData();
      fd.append('file', file, file.name);
      fd.append('projectId', -1);
      fd.append('connId', -1);
      fd.append('clean', false);
      fd.append('provider', "demo");
      const header = {
        'Accept': '*!/!*',
        'Content-Type': 'multipart/form-data;'
      }
     return this.kingfisherService.post("/repo/kf-dataconnector/upload-devices", fd, header).then(response => {
        return response;
      });
    }*/

  // 获取项目的数据源列表
  getConnectedList(projectId) {
    return this.kingfisherService.get(`/repo/kf-dataconnector/get-connector-list/${projectId}`).then(res => res);
  }

  // 工创5.1 获取 数据连接TSL模板
  getConnectionTslTemplate(connId) {
    return this.kingfisherService.get(`/repo/kf-dataconnector/get-conn-tsl-config/${connId}`).then(res => res);
  }
  getStaticDevice(params) {
    return this.kingfisherService.post("/repo/kf-dataconnector/get-static-device-list", params).then(response => {
      let newRes = [];
      response.forEach(res => res.provider === "demo" ? newRes.push(res) : newRes.unshift(res));
      return newRes;
    });
  }
  /**
   * 工创4.0获取我的文件夹-类型组件数量
   * @returns {jQuery|Deferred} Ajax请求返回并处理后的Promise
   */
  getDefaultProject(code) {
    if (code === 'file') {
      //我的文件
      return this.kingfisherService.get("/repo/kf-project/get-default-project").then(response => {
        return response;
      });
    } else if (code === 'library') {
      //库 /api/repo/kf-project/findComponentLib
      return this.kingfisherService.get("/repo/kf-project/findComponentLib").then(response => {
        return response;
      });
    } else {
      return [];
    }
  }

  /**
   * 工创4.0获取我的文件夹项目
   * param projectId
   * @returns {jQuery|Deferred} Ajax请求返回并处理后的Promise
   */
  getCategoryPart(projectId, param, sysFlag) {
    if (param.currMenuCode === 'library') {
      let systemFlag = 1;
      // 判断有没有传入 官方和用户标志
      if (!sysFlag) {
        systemFlag = contentModel.currLibTab.value.code === 'userContent' ? 2 : 1;
      } else {
        systemFlag = sysFlag;
      }
      // if (param.currHeaderTabCode === 'space') {
      return this.kingfisherService.get(`/repo/kf-space/get-category/${projectId}`).then(response => {
        return response;
      });
      // } else {
      //   return this.kingfisherService.get(`/repo/kf-part/get-category-part-count/${projectId}`).then(response => {
      //     return response;
      //   });
      // }
    }
  }

  /**
   * 工创4.0获取我的教程的视频列表
   * @returns {jQuery|Deferred} Ajax请求返回并处理后的Promise
   */
  getVideoList(category) {
    return this.kingfisherService.get(`/repo/kf-part/get-video-list?category=${category}`).then(response => {
      return response;
    });
  }

  /**
   * 查询category的场景（主要是我的文件，库，教程里的）
   * @param projectId 项目ID category 分类  flag 1：我的组件 2：我添加的组件
   * @returns {Promise<T>}
   */
  queryScene(projectId, category, flag) {
    let systemFlag = contentModel.currLibTab.value.code === 'userContent' ? 2 : 1;
    let url = `/repo/kf-scene-3d/query-list/${projectId}`;
    if (category !== undefined && category !== null) {
      url += `?category=${category}`;
    }
    if (flag !== undefined && flag !== null) {
      url = `/repo/kf-scene-3d/query-list/${projectId}?flag=${flag}`;
    }
    if (contentModel.currMenu.value.code === 'library' && !!systemFlag) {
      url += `&systemFlag=${systemFlag}`;
    }
    return this.kingfisherService.get(url).then(response => {
      return response;
    });
  }

  /**
   * 查询主题 groups
   * @param {integer} projectId
   * @returns
   */
  queryTronThemeGroups(projectId, systemFlag) {
    return this.kingfisherService.get(`/repo/kf-tron-theme/findTronThemeGroups?projectId=${projectId}&systemFlag=${systemFlag}`).then(response => {
      return response;
    });
  }
  /**
   * 已使用空间
   * @returns Promise Ajax请求返回并处理后的Promise
   */
  getUseSpace() {
    return this.kingfisherService.get(`/repo/kf-project/get-used-space`).then(response => {
      return response;
    });
  }
  /**
   * 回收站按照日期和count进行分类的接口
   */
  getRecycleDateCountList(type) {
    if (type === 'kf-space') {
      return this.kingfisherService.post(`/repo/kf-space/find-delete-building`).then(res => res);
    } else {
      return this.kingfisherService.get(`/repo/${type}/get-delete-count`).then(res => res);
    }
  }
  /**
   *  回收站获取具体照日期分类下的数据列表
   *  */
  getRecycleListBySpace(params) {
    return this.kingfisherService.post(`/repo/kf-space/find-delete-building?date=${params.date}`).then(res => res);
  }

  /**
   *  项目 回收站获取具体照日期分类下的数据列表
   *  */
  getRecycleListByProject(params) {
    return this.kingfisherService.get(`/repo/kf-project/load-recycle-bin?date=${params.date}`).then(res => res);
  }

  /**
   *  恢复已经删除的项目 : projectUuid
   */
  restoreProjectByPro(projectUuid) {
    return this.kingfisherService.get(`/repo/kf-project/rollback/${projectUuid}`).then(res => res);
  }

  /**
   *  场景 回收站-恢复（0）-永久删除（2）
   */
  recycleRestoreBySpace(param) {
    let url = `/repo/kf-space/del-space-back/${param.uuid}/${param.flag}`;
    return this.kingfisherService.delete(url).then(res => res);
  }

  /**
   * 清空回收站
   */
  recycleClear(type) {
    //flag: 0 恢复，1 清空
    let url = `/repo/kf-${type}/clear`;
    if (type === 'project') {
      url += '?flag=1';
    }
    if (type === 'space') {
      return this.kingfisherService.delete(url).then(res => res);
    } else {
      return this.kingfisherService.get(url).then(res => res);
    }
  }

  /**
   *  项目永久删除
   */
  recycleDelByProject(param) {
    let url = `/repo/kf-project/update-delete/${param.projectId}`;
    return this.kingfisherService.get(url).then(res => res);
  }
  /**
   * 获取我的教程得category
   * @returns {jQuery|Deferred} Ajax请求返回并处理后的Promise
   */
  getVideoCategory() {
    return this.kingfisherService.get(`/repo/kf-part/get-video-count`).then(response => {
      return response;
    });
  }

  /**
   * 获取库分类
   * param projectId
   * @returns {jQuery|Deferred} Ajax请求返回并处理后的Promise
   */
  getCategoryBylibrary(projectId) {
    return this.kingfisherService.get(`/repo/kf-space/get-category/${projectId}`).then(response => {
      return response;
    });
  }

  /**
   * 工创4.0编辑组件信息(移动到分组/重命名)
   * param projectId sourceCategory targetCategory
   * @returns {jQuery|Deferred} Ajax请求返回并处理后的Promise
   */
  updatePartInfo(param, code) {
    if (code === 'scene') {
      return this.kingfisherService.post(`/repo/kf-scene-3d/update-scene-info`, param).then(res => {
        return res;
      });
    } else if (code === 'page') {
      return this.kingfisherService.post(`/repo/kf-page3d/update-info`, param).then(res => {
        return res;
      });
    } else if (code === 'space') {
      return this.kingfisherService.post(`/repo/kf-space/update-building`, param).then(res => {
        return res;
      });
    } else {
      return this.kingfisherService.post(`/repo/kf-part/update-part-info`, param).then(res => {
        return res;
      });
    }
  }

  /**
   * 用户头像-上传
   * param file
   * @returns {jQuery|Deferred} Ajax请求返回并处理后的Promise
   */
  uploadUserImg(param) {
    return this.kingfisherService.post(`/repo/kf-customer/update-user-image`, param, {
      'content-type': 'multipart/form-data'
    }).then(res => {
      return res;
    });
  }

  /**
   * 库里面主题下的组件删除---删除/永久删除/恢复
   * param projectId uuid flag-2永久删除,0恢复,1删除  systemFlag  tronThemeUuid
   * @returns {jQuery|Deferred} Ajax请求返回并处理后的Promise
   */
  deteleParts(projectId, uuid, flag, tronThemeUuid, systemFlag) {
    let _url = `/repo/kf-part/update-delete-some?projectId=${projectId}&uuids=${uuid}&flag=${flag}`;
    if (tronThemeUuid !== null) {
      _url += `&tronThemeUuid=${tronThemeUuid}`;
    }
    if (systemFlag !== null) {
      _url += `&systemFlag=${systemFlag}`;
    }
    return this.kingfisherService.get(_url).then(res => {
      return res;
    });
  }

  /**
   * 更新项目
   * @returns {jQuery|Deferred} Ajax请求返回并处理后的Promise
   */
  updateProject(params) {
    return this.kingfisherService.post("/repo/kf-project/updateProject", JSON.stringify(params)).then(response => {
      return response;
    });
  }

  /**
   * 我的文件夹-重命名/解散分组到默认/移动到分组
   * param projectId sourceCategory targetCategory
   * @returns {jQuery|Deferred} Ajax请求返回并处理后的Promise
   */
  updateCategory(projectId, sourceCategory, targetCategory, code, sysFlag) {
    let systemFlag = 1;
    // 判断有没有传入 官方和用户标志
    if (!sysFlag) {
      systemFlag = contentModel.currLibTab.value.code === 'userContent' ? 2 : 1;
    } else {
      systemFlag = sysFlag;
    }
    let _sourceCategory = sourceCategory;
    let _targetCategory = targetCategory;
    if (_sourceCategory == null) {
      _sourceCategory = '';
    }
    if (_targetCategory == null) {
      _targetCategory = '';
    }
    if (code === 'scene') {
      return this.kingfisherService.post(`/repo/kf-scene-3d/update-category?projectId=${projectId}&sourceCategory=${_sourceCategory}&targetCategory=${_targetCategory}&systemFlag=${systemFlag}`).then(res => {
        return res;
      });
    } else {
      return this.kingfisherService.post(`/repo/kf-part/update-category?projectId=${projectId}&sourceCategory=${_sourceCategory}&targetCategory=${_targetCategory}&systemFlag=${systemFlag}`).then(res => {
        return res;
      });
    }
  }
  /**
   * 删除项目到回收站
   * @returns {jQuery|Deferred} Ajax请求返回并处理后的Promise
   */
  deleteProject(projectId) {
    return this.kingfisherService.delete(`/repo/kf-project/del/${projectId}`).then(response => {
      return response;
    });
  }

  /**
   * 工创4.0编辑组件信息(移动到分组/重命名)
   * param projectId sourceCategory targetCategory
   * @returns {jQuery|Deferred} Ajax请求返回并处理后的Promise
   */
  moveToProject(uuid, projectId, code, editItem) {
    return this.kingfisherService.post(`/repo/kf-part/move-part/${uuid}/${projectId}`).then(res => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  /**
   * 修改场景所在项目
   * param projectId sourceCategory targetCategory
   * @returns {jQuery|Deferred} Ajax请求返回并处理后的Promise
   */
  moveBuilding(uuid, projectId) {
    return this.kingfisherService.get(`/repo/kf-space/move_building/${projectId}/${uuid}`).then(res => {
      return res;
    }).catch(err => {
      return err;
    });
  }
}
export default new ProjectService();