import "core-js/modules/es.array-buffer.detached.js";
import "core-js/modules/es.array-buffer.transfer.js";
import "core-js/modules/es.array-buffer.transfer-to-fixed-length.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.find-last.js";
import "core-js/modules/es.typed-array.find-last-index.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.to-reversed.js";
import "core-js/modules/es.typed-array.to-sorted.js";
import "core-js/modules/es.typed-array.with.js";
import PinyinMatch from 'pinyin-match';
export const angleToRadian = function (angle) {
  return Math.PI / 180 * angle;
};
export const radianToAngle = function (radian) {
  return parseFloat((radian / Math.PI * 180).toFixed(2));
};
function debounce(fn, time) {
  let timeout = null;
  return function () {
    let args = arguments;
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      fn(...args);
    }, time);
  };
}
let op = Object.prototype;
let ostring = op.toString;
/**
 * Returns the first parameter if not undefined, otherwise the second parameter.
 * Useful for setting a default value for a parameter.
 *
 * @function
 *
 * @param {*} a
 * @param {*} b
 * @returns {*} Returns the first parameter if not undefined, otherwise the second parameter.
 *
 * @example
 * param = defaultValue(param, 'default');
 */
export const defaultValue = function (a, b) {
  if (a !== undefined && a !== null) {
    return a;
  }
  return b;
};
export const isFunction = function (it) {
  return ostring.call(it) === '[object Function]';
};
const isArray = function (arr) {
  return Array.isArray(arr);
};
export const isObject = function (obj) {
  return Object.prototype.toString.call(obj) === '[object Object]';
};
/**
 *验证输入的参数是否被定义，是否为null，如果包含未定义的参数，或者为null的参数，将返回false，反之返回true
 * @return boolean
 */
export const isDefined = function () {
  let result = true;
  for (let i = arguments.length; i > 0; i--) {
    if (typeof arguments[i - 1] === 'undefined' || arguments[i - 1] === null) {
      result = false;
      break;
    }
  }
  return result;
};
/**
* @description 循环函数
* @param {[]} array 需要循环的数据
* @param {Function} fn 循环函数体,函数体返回-1时，each函数会终止循环
* @param {Number} [order] 可选参数，默认为1，非1的时候为逆序遍历循环
*
*
* */
export const each = function (array, fn, order = 1) {
  if (!array) {
    console.error('each 参数未定义');
    return;
  }
  if (!isArray(array)) {
    console.error('each 非数组类型参数');
    return Kf.t('NonarrayTypeParameter');
  }
  if (!isFunction(fn)) {
    console.error('each 函数类型错误');
    return Kf.t('FunctionTypeError');
  }
  if (order === 1) {
    for (let i = 0, l = array.length; i < l; i += 1) {
      let state = defaultValue(fn(array[i], i), 1);
      if (state === -1) {
        break;
      }
    }
  } else {
    for (let l = array.length; l > 0; l -= 1) {
      let state = defaultValue(fn(array[l - 1], l - 1), 1);
      if (state === -1) {
        break;
      }
    }
  }
};
export const simplyCopy = function (obj) {
  return JSON.parse(JSON.stringify(obj));
};
export const eachTimes = function (numb, fn) {
  if (!isFunction(fn)) {
    return Kf.t('FunctionTypeError');
  }
  for (let i = 0; i < numb; i++) {
    let state = defaultValue(fn(i), 1);
    if (state === -1) {
      break;
    }
  }
};
/**
 * @description 循环函数
 * @param {Object} object 需要循环的数据
 * @param {Function} fn 循环函数体,函数体返回-1时，each函数会终止循环
 * @param {Number} [order] 可选参数，默认为1，非1的时候为逆序遍历循环
 *
 *
 * */
export const eachObject = function (object, fn, order = 1) {
  let keys = Object.keys(object);
  if (!isFunction(fn)) {
    return Kf.t('FunctionTypeError');
  }
  if (order === 1) {
    for (let i = 0, l = keys.length; i < l; i += 1) {
      let state = defaultValue(fn(object[keys[i]], i, keys[i]), 1);
      if (state === -1) {
        break;
      }
    }
  } else {
    for (let l = keys.length; l > 0; l -= 1) {
      let state = defaultValue(fn(object[keys[l - 1]], l - 1, keys[l - 1]), 1);
      if (state === -1) {
        break;
      }
    }
  }
};
Array.prototype.removeByVal = function (val) {
  var index = this.indexOf(val);
  this.removeByIndex(index);
};
Array.prototype.removeByIndex = function (index) {
  if (index > -1) {
    this.splice(index, 1);
  }
};
export const base64ArrayBuffer = function (arrayBuffer) {
  var base64 = '';
  var encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
  var bytes = arrayBuffer instanceof Uint8Array ? arrayBuffer : new Uint8Array(arrayBuffer);
  var byteLength = bytes.byteLength;
  var byteRemainder = byteLength % 3;
  var mainLength = byteLength - byteRemainder;
  var a, b, c, d;
  var chunk;

  // Main loop deals with bytes in chunks of 3
  for (var i = 0; i < mainLength; i = i + 3) {
    // Combine the three bytes into a single integer
    chunk = bytes[i] << 16 | bytes[i + 1] << 8 | bytes[i + 2];

    // Use bitmasks to extract 6-bit segments from the triplet
    a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
    b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
    c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
    d = chunk & 63; // 63       = 2^6 - 1

    // Convert the raw binary segments to the appropriate ASCII encoding
    base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
  }

  // Deal with the remaining bytes and padding
  if (byteRemainder === 1) {
    chunk = bytes[mainLength];
    a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

    // Set the 4 least significant bits to zero
    b = (chunk & 3) << 4; // 3   = 2^2 - 1

    base64 += encodings[a] + encodings[b] + '==';
  } else if (byteRemainder === 2) {
    chunk = bytes[mainLength] << 8 | bytes[mainLength + 1];
    a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
    b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

    // Set the 2 least significant bits to zero
    c = (chunk & 15) << 2; // 15    = 2^4 - 1

    base64 += encodings[a] + encodings[b] + encodings[c] + '=';
  }
  return base64;
};
export const getNanoID = window.Kingfisher.getNanoID;
const pinyinMatch = function (content, search) {
  return !search || PinyinMatch.match(content ? content.toLowerCase() : "", search ? search.toLowerCase() : "");
};
export default {
  debounce,
  pinyinMatch
};
export function deepCopyObject(obj) {
  let copy = {};
  for (let key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      copy[key] = deepCopyObject(obj[key]); // 递归处理对象的属性
    } else {
      copy[key] = obj[key];
    }
  }
  return copy;
}