import "core-js/modules/es.array.push.js";
import { saveSpace, smDatas } from "@/model/smCollectionData";
import { ref, watch, nextTick } from 'vue';
import { undoMap } from "@/undo/undoMap";
import { contextMenuStyle } from "@/components/widgets/contextmenu/contextmenu";
import { globalState, currData, modeName } from "@/model/globalData";
import { each, eachTimes } from "@/utils/tools";
import { cloneCurrList, deleteSceneObj } from "@/ui-dispatch/scenObj";
import { ElMessage } from 'element-plus';
import { wallOptions, pipelineOptions } from "@/model/purposeOptions";
import { autoCreateModelFromDrawing, checkModelsBindStateAndBatchBind, deleteMapping, bindModelsToDevice } from "@/components/mainContent/rightPanel/point";
export const calibrateDrawingDialog = ref({
  show: false,
  value: 0
});
export const isShowContextMenu = ref(0);
/** 鼠标右键的数学模型 **/
export const menuCurrItem = {
  value: null
};
export const drawingFileUpload = ref(null);
export const drawingFile = {
  value: null,
  args: []
};
export const uploadDrawingMode = ref('');
export const calibrateCallback = {
  value: null
};
export const calibrateCancelCallback = {
  value: null
};
export const activeTab = ref(Kf.t('Properties'));
export const createFloor = function () {
  doObjectMenuClick({
    label: Kf.t('New'),
    key: 'create'
  }, 'floor');
};
export const doMenuClick = function (item) {
  isShowContextMenu.value = 0;
  switch (globalState.mode) {
    case 'model':
    case 'scene':
    case 'floor':
    case 'wall':
    case 'drawing':
    case 'area':
    case 'pipeline':
    case 'construct':
    case 'devicePoint':
      return doObjectMenuClick(item, globalState.mode);
  }
};
watch(() => globalState.mode, () => {
  isShowContextMenu.value = 0;
});
export const doItemClick = function (event, item, ObjList) {
  isShowContextMenu.value = 0;
  // activeTab.value = Kf.t('Properties');
  //由于这个是右击处理方法 所以.mode是上层模式
  let mode = globalState.mode;
  if (globalState[mode] === item) {
    return;
  }
  if (event && event.type === 'click' && (event.shiftKey || event.ctrlKey || event.metaKey)) {
    let updateCurrListItem = function (item, mutex = true, isUpdate = true) {
      if (mutex) {
        if (currData.currListMap.has(item)) {
          currData.removeFromCurrList(item, isUpdate);
        } else {
          currData.addToCurrList(item, isUpdate);
        }
        if (currData[mode] === item) {
          currData[mode] = currData.currList[0] ? currData.currList[0] : null;
        }
      } else {
        if (!currData.currListMap.has(item)) {
          currData.addToCurrList(item, isUpdate);
        }
      }
    };
    if (event.ctrlKey || event.metaKey) {
      updateCurrListItem(item);
    } else {
      let top = -1;
      let down = -1;
      let list = ObjList;
      if (item.type === 'model') {
        list = list.find(x => x.id === item.subType).children.find(y => y.id === item.modelName).children;
      }
      each(currData.currList, oldItem => {
        let index = list.indexOf(oldItem);
        if (index >= 0) {
          top = top === -1 ? index : top > index ? index : top;
          down = down === -1 ? index : down < index ? index : down;
        }
      });
      let newIndex = list.indexOf(item);
      let updateCurrList = function (top, down) {
        let times = down - top + 1;
        eachTimes(times, i => {
          let _item = list[top + i];
          updateCurrListItem(_item, false, false);
        });
        currData.currListFlag++;
      };
      if (top === -1) {
        updateCurrListItem(item, false, false);
      } else {
        if (newIndex < top) {
          updateCurrList(newIndex, down - 1);
        } else if (newIndex > down) {
          updateCurrList(top + 1, newIndex);
        } else {
          updateCurrList(top, down);
        }
      }
    }
  } else {
    // right click
    if (event.button === 2 && currData.currListMap.has(item)) {
      return;
    }
    if (mode === 'floor') {
      currData.wall = null;
      currData.area = null;
    }
    if (mode !== 'model') {
      currData[mode] = item;
    } else {
      currData.models = {};
      currData.models[item.nanoId] = item;
    }
    currData.setCurrList([item]);
  }
};
export const doItemDBClick = function ($event, item, visibleObjs, args = {
  minDistance: 4000
}) {
  isShowContextMenu.value = 0;
  if (item.type === 'devicePoint') {
    currData.models = Array.from(item.deviceModelList || []).map(x => smCollection.objects[x]).filter(x => x);
    currData.currListFlag++;
  }
  doItemClick($event, item, visibleObjs);
  nextTick(() => {
    let args3d = Object.assign({}, args);
    if (args3d.minDistance) {
      args3d.minDistance *= 0.001;
    }
    window.sm3DMeshBuilder.focusOnObject(args3d);
    window.sm2DMeshBuilder.focusOnObject(args);
  });
};
export const getFloorName = function (floor) {
  return floor ? smDatas.objects[floor].name || '-' : '-';
};
export const getObjectName = function (obj) {
  let name = obj.name;
  if (obj.needSave) {
    name = '*' + name;
  }
  return name;
};
export const getObjectHight = function (obj, key) {
  if (!obj) {
    return 0;
  }
  if (obj.type === 'wall') {
    return (obj[key] * 0.1).toFixed(1);
  }
  if (obj.type === 'floor') {
    return (obj[key] * 0.001).toFixed(1);
  }
};
export const getObjectOpacity = function (obj) {
  if (!obj) {
    return Kf.t('Default');
  }
  return (obj.opacity * 100).toFixed(0) + '%';
};
export const getObjectPurpose = function (obj) {
  if (!obj) {
    return Kf.t('Default');
  }
  if (obj.type === 'wall') {
    let option = wallOptions.find(x => x.value === obj.purpose);
    return option ? option.label : Kf.t('Default');
  }
  if (obj.type === 'pipeline') {
    let option = pipelineOptions.find(x => x.value === obj.purpose);
    return option ? option.label : Kf.t('Default');
  }
  if (obj.type === 'area') {
    return obj.purpose;
  }
};
export const showContextMenu = function (e, item, visibleObjs) {
  if (globalState.mode === 'model' && !item) {
    return;
  }
  if (item && item.type !== 'dxfDrawingBlock') {
    doItemClick(e, item, visibleObjs);
  }
  isShowContextMenu.value++;
  menuCurrItem.value = item;
  contextMenuStyle.value.left = e.clientX + 'px';
  contextMenuStyle.value.top = e.clientY + 'px';
};
export const setProperty = function (target, prop, value) {
  console.log(prop, value, target[prop]);
  undoMap.push({
    cmd: 'updateProperty',
    target: target,
    property: prop,
    value: target[prop]
  });
  target[prop] = value;
  if (target.setNeedSave) {
    target.setNeedSave();
  }
};
let getCalibrateLength = function (length, callback, cancelCallback) {
  calibrateDrawingDialog.value.show = true;
  calibrateDrawingDialog.value.value = (length * 0.1).toFixed(1);
  calibrateCallback.value = callback;
  calibrateCancelCallback.value = cancelCallback;
  return length;
};
export const doObjectMenuClick = function (item, type) {
  console.log('menu click', item, type);
  switch (item.key) {
    case 'create':
      {
        if (!smDatas.building || !smDatas.building.nanoId) {
          ElMessage({
            type: "error",
            message: "建筑无效"
          });
          return;
        }
        if (type !== 'floor' && currData.floor && !currData.floor.visible) {
          ElMessage({
            type: "warning",
            message: Kf.t('FloorNotVisible')
          });
          return;
        }
        let temp = type;
        if (item.args && item.args[0] === 'dxf') {
          temp = 'dxfDrawing';
        }
        temp = temp.slice(0, 1).toUpperCase() + temp.substr(1);
        let obj = smDatas['create' + temp]();
        obj.setNeedSave();
        if (type === 'dxfDrawing') {
          type = 'drawing';
        }
        currData[type] = obj;
        currData.currList[0] = obj;
        currData.currList.length = 1;
        currData.currListFlag++;
        if (type !== 'floor') {
          if (!currData.floor) {
            let floor = smDatas.createFloor();
            floor.setNeedSave();
            undoMap.push({
              cmd: 'create',
              target: floor,
              value: floor.serialize()
            });
            currData.floor = floor;
          }
          obj.floor = currData.floor.nanoId;
        }
        undoMap.push({
          cmd: 'create',
          target: obj,
          value: obj.serialize()
        });
      }
      break;
    case 'delete':
      deleteSceneObj(item);
      break;
    // case 'unDelete': {
    //     if (menuCurrItem.value.isDeleted) {
    //         undoMap.push({
    //             cmd: 'setValue',
    //             value: menuCurrItem.value.isDeleted,
    //             property: 'isDeleted',
    //             target: menuCurrItem.value,
    //             needSave: menuCurrItem.value.needSave,
    //         });
    //
    //         menuCurrItem.value.isDeleted = false;
    //     }
    // }
    //     break;
    case 'save':
      {
        saveSpace(menuCurrItem.value);
      }
      break;
    case 'saveAll':
      {
        saveSpace(smDatas[globalState.mode + 's']);
      }
      break;
    case 'updateImage':
    case 'updateDxf':
      uploadDrawingMode.value = 'update';
      drawingFile.value = null;
      drawingFile.args = item.args;
      drawingFileUpload.value.click();
      break;
    case 'calibrateCenter':
    case 'calibrateSize':
    case 'calibrateRotation':
      globalState.modeTemp = globalState.mode;
      globalState.mode = 'drawing';
      globalState.modeArgs = [item.key, item.flag];
      globalState.operationTemp = globalState.operation;
      globalState.operation = 'draw';
      currData.getCalibrateLength = getCalibrateLength;
      break;
    case 'clone':
      cloneCurrList();
      break;
    case 'bindModelsToDevice':
      bindModelsToDevice(currData.currList[0], currData.models);
      break;
    case 'deleteMapping':
      deleteMapping(currData.currList);
      break;
    case 'bindDevicePoint':
      checkModelsBindStateAndBatchBind();
      break;
    case 'autoCreateModelFromDrawing':
      autoCreateModelFromDrawing();
      break;
    case 'replaceModel':
      replaceModels();
      break;
  }
};
export const replaceModels = function () {
  globalState.isReplacingModel = true;
};
export const loadBodyScrollTop = function (bodyManager, listName, itemName) {
  let list = smDatas[listName].filter(x => !x.isDeleted && x.floorVisible);
  let index;
  if (list && list.length > 0) {
    index = list.indexOf(currData[itemName]);
    let top = index * 32;
    if (bodyManager.value) {
      let clientHeight = bodyManager.value.clientHeight;
      let scrollTop = bodyManager.value.scrollTop;
      if (scrollTop < top - clientHeight + 32 || scrollTop > top - 32) {
        setTimeout(() => {
          if (bodyManager.value) {
            bodyManager.value.scrollTop = index * 32;
          }
        }, 0);
      }
    }
  }
};