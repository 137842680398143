import "core-js/modules/es.array-buffer.detached.js";
import "core-js/modules/es.array-buffer.transfer.js";
import "core-js/modules/es.array-buffer.transfer-to-fixed-length.js";
import DxfParser from "./parser/DxfParser";

/** Fetches and parses DXF file. */
export class DxfFetcher {
  constructor(url, encoding = "utf-8") {
    this.url = url;
    this.encoding = encoding;
  }

  /** @param progressCbk {Function} (phase, receivedSize, totalSize) */
  async Fetch(progressCbk = null) {
    let buffer;
    if (Kingfisher) {
      let loadFile = async url => {
        return new Promise((resolve, reject) => {
          Kingfisher.FileTools.RequestFile(url, data => {
            resolve(data);
          }, () => {}, scene ? scene.offlineProvider : null, false, () => {
            reject("Failed to load file: " + url);
          });
        });
      };
      buffer = await loadFile(this.url);
      if (typeof buffer !== "string") {
        let decoder = new TextDecoder(this.encoding);
        buffer = decoder.decode(buffer, {
          stream: false
        });
      }
    } else {
      const response = await fetch(this.url);
      const totalSize = +response.headers.get('Content-Length');
      const reader = response.body.getReader();
      let receivedSize = 0;
      //XXX streaming parsing is not supported in dxf-parser for now (its parseStream() method
      // just accumulates chunks in a string buffer before parsing. Fix it later.
      buffer = "";
      let decoder = new TextDecoder(this.encoding);
      while (true) {
        const {
          done,
          value
        } = await reader.read();
        if (done) {
          buffer += decoder.decode(new ArrayBuffer(0), {
            stream: false
          });
          break;
        }
        buffer += decoder.decode(value, {
          stream: true
        });
        receivedSize += value.length;
        if (progressCbk !== null) {
          progressCbk("fetch", receivedSize, totalSize);
        }
      }
      if (progressCbk !== null) {
        progressCbk("parse", 0, null);
      }
    }
    const parser = new DxfParser();
    return parser.parseSync(buffer);
  }
}