import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  slot: "footer",
  class: "dialog-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_card = _resolveComponent("el-card");
  const _component_el_main = _resolveComponent("el-main");
  const _component_el_container = _resolveComponent("el-container");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_dialog, {
    "custom-class": "kingfisher_dialog_create",
    title: _ctx.$t('ShortcutKeys'),
    modelValue: $setup.showDialog,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $setup.showDialog = $event),
    width: "540px",
    top: "200px",
    "close-on-click-modal": false
  }, {
    footer: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_createVNode(_component_el_button, {
      type: "primary",
      onClick: _cache[0] || (_cache[0] = $event => $setup.showDialog = false)
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('ClickOkToConfirm2')), 1)]),
      _: 1
    })])]),
    default: _withCtx(() => [_createVNode(_component_el_container, null, {
      default: _withCtx(() => [_createVNode(_component_el_main, {
        style: {
          "margin-top": "0.4rem"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_card, {
          class: "box-card"
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.hotKey, (arr, index) => {
            return _openBlock(), _createBlock(_component_el_row, {
              style: {
                "margin-bottom": "8px"
              },
              key: index
            }, {
              default: _withCtx(() => [_createVNode(_component_el_col, {
                span: 10,
                style: {
                  "text-align": "right",
                  "height": "24px"
                }
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(arr[0]), 1)]),
                _: 2
              }, 1024), _createVNode(_component_el_col, {
                span: 10,
                style: {
                  "padding-left": "22px",
                  "height": "24px"
                }
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(arr[1]), 1)]),
                _: 2
              }, 1024)]),
              _: 2
            }, 1024);
          }), 128))]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }, 8, ["title", "modelValue"])]);
}