import { ref, computed } from 'vue';
import oemManager from '@/utils/oem';
export const typeList = computed(() => {
  let res = [{
    name: Kf.t('Scene'),
    type: 'model',
    dataKey: 'model',
    active: false,
    compName: 'KfTree',
    isFilter: false,
    isFilterInput: true
  }, {
    name: Kf.t('Wall2'),
    type: 'wall',
    active: true,
    dataKey: 'walls',
    compName: 'KfList',
    list: [{
      th: Kf.t('Name'),
      tdType: 'text',
      td: 'name',
      tdClass: 'kf-tr-index1'
    }, {
      th: Kf.t('Type'),
      tdType: 'text',
      td: 'purpose',
      tdClass: 'kf-tr-index1'
    }, {
      th: Kf.t('Height') + '(cm)',
      tdType: 'text',
      td: 'height',
      tdClass: 'kf-tr-index4'
    }, {
      th: Kf.t('Floor'),
      tdType: 'text',
      td: 'floorName',
      tdClass: 'kf-tr-index4'
    }, {
      th: Kf.t('Positioning'),
      tdType: 'icon',
      td: 'name',
      icon1: 'icon-mubiao',
      tdClass: 'kf-tr-index4',
      tdEventType: 'focus'
    }, {
      th: Kf.t('Display'),
      tdType: 'icon',
      td: 'visible',
      icon1: 'icon-xianshi',
      icon2: 'icon-yincang',
      tdClass: 'kf-tr-index4',
      tdEventType: 'switch'
    }],
    isFilter: true
  }, {
    name: Kf.t('Regional'),
    type: 'area',
    active: false,
    compName: 'KfList',
    dataKey: 'areas',
    list: [{
      th: Kf.t('Name'),
      tdType: 'text',
      td: 'name',
      tdClass: 'kf-tr-index9'
    }, {
      th: Kf.t('Type'),
      tdType: 'text',
      td: 'purpose',
      tdClass: 'kf-tr-index6'
    }, {
      th: Kf.t('Area') + '(m²)',
      tdType: 'text',
      td: 'area',
      tdClass: 'kf-tr-index6'
    }, {
      th: Kf.t('Floor'),
      tdType: 'text',
      td: 'floorName',
      tdClass: 'kf-tr-index1'
    }, {
      th: Kf.t('Positioning'),
      tdType: 'icon',
      td: 'name',
      icon1: 'icon-mubiao',
      tdClass: 'kf-tr-index1',
      tdEventType: 'focus'
    }, {
      th: Kf.t('Display'),
      tdType: 'icon',
      td: 'visible',
      icon1: 'icon-xianshi',
      icon2: 'icon-yincang',
      tdClass: 'kf-tr-index1',
      tdEventType: 'switch'
    }],
    isFilter: true
  }, {
    name: Kf.t('DrawPipeline'),
    type: 'pipeline',
    active: false,
    compName: 'KfList',
    dataKey: 'pipelines',
    list: [{
      th: Kf.t('Name'),
      tdType: 'text',
      td: 'name',
      tdClass: 'kf-tr-index1'
    }, {
      th: Kf.t('Type'),
      tdType: 'text',
      td: 'purpose',
      tdClass: 'kf-tr-index1'
    }, {
      th: Kf.t('Diameter') + '(cm)',
      tdType: 'text',
      td: 'diameter',
      tdClass: 'kf-tr-index4'
    }, {
      th: Kf.t('Floor'),
      tdType: 'text',
      td: 'floorName',
      tdClass: 'kf-tr-index4'
    }, {
      th: Kf.t('Positioning'),
      tdType: 'icon',
      td: 'name',
      icon1: 'icon-mubiao',
      tdClass: 'kf-tr-index4',
      tdEventType: 'focus'
    }, {
      th: Kf.t('Display'),
      tdType: 'icon',
      td: 'visible',
      icon1: 'icon-xianshi',
      icon2: 'icon-yincang',
      tdClass: 'kf-tr-index4',
      tdEventType: 'switch'
    }],
    isFilter: true
  }, /* {name: Kf.t('DrawRoute'), icon: 'icon-chuangjianlujing', title: Kf.t('DrawRoute'),type: 'route', active: false, compName: 'kfPath', dataKey: 'path'},*/
  {
    name: Kf.t('Diagrams'),
    type: 'drawing',
    active: false,
    compName: 'KfList',
    dataKey: 'drawings',
    list: [{
      th: Kf.t('Name'),
      tdType: 'text',
      td: 'name',
      tdClass: 'kf-tr-index8'
    }, {
      th: Kf.t('Transparency'),
      tdType: 'text',
      td: 'opacity',
      tdClass: 'kf-tr-index1'
    }, {
      th: Kf.t('Floor'),
      tdType: 'text',
      td: 'floorName',
      tdClass: 'kf-tr-index1'
    }, {
      th: Kf.t('Positioning'),
      tdType: 'icon',
      td: 'name',
      icon1: 'icon-mubiao',
      tdClass: 'kf-tr-index1',
      tdEventType: 'focus'
    }, {
      th: Kf.t('Display'),
      tdType: 'icon',
      td: 'visible',
      icon1: 'icon-xianshi',
      icon2: 'icon-yincang',
      tdClass: 'kf-tr-index1',
      tdEventType: 'switch'
    }],
    isFilter: true
  }, {
    name: Kf.t('Floor'),
    type: 'floor',
    active: false,
    compName: 'KfList',
    dataKey: 'floors',
    list: [{
      th: Kf.t('Floor'),
      tdType: 'text',
      td: 'name',
      tdClass: 'kf-tr-index1'
    }, {
      th: Kf.t('Name'),
      tdType: 'text',
      td: 'name',
      tdClass: 'kf-tr-index9'
    }, {
      th: Kf.t('FloorHeight') + '(cm)',
      tdType: 'text',
      td: 'elevation',
      tdClass: 'kf-tr-index6'
    }, {
      th: Kf.t('Altitude') + '(cm)',
      tdType: 'text',
      td: 'height',
      tdClass: 'kf-tr-index6'
    }, {
      th: Kf.t('Display'),
      tdType: 'icon',
      td: 'visible',
      icon1: 'icon-xianshi',
      icon2: 'icon-yincang',
      tdClass: 'kf-tr-index1',
      tdEventType: 'switch'
    }],
    isFilter: false
  }];
  /* if (oemManager.platformType.value !== 'saas') {
       res.push({name: Kf.t('Position'), type: 'devicePoint', active: false, compName: 'KfTree',isFilter:true})
   }*/
  return res;
});