import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "kingfisher_props_container2"
};
const _hoisted_2 = ["title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["kingfisher_props_container", $setup.show ? 'kingfisher_input kingfisher_input_active' : 'kingfisher_input']),
    style: {
      "width": "100%"
    }
  }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_input, {
    placeholder: $props.props.placeholder,
    modelValue: $setup.inputValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.inputValue = $event),
    onChange: $setup.doInputContent,
    clearable: "",
    readonly: $setup.lodash.get($props.props, 'editor.option.readonly')
  }, {
    prepend: _withCtx(() => [_createElementVNode("span", {
      title: $props.props.name
    }, _toDisplayString($props.props.label.zh), 9, _hoisted_2)]),
    _: 1
  }, 8, ["placeholder", "modelValue", "onChange", "readonly"])])], 2);
}