import "core-js/modules/es.array.push.js";
import { lineIntersect, Vector2, Vector3 } from "../maths/vector";
import { smDatas } from "../model/smCollectionData";
import { smMesh2DCache, smMesh3DCache } from "../model/smCollectionData";
let v2Temp0 = new Vector2();
let v2Temp1 = new Vector2();
let v2Temp2 = new Vector2();
let v2Temp3 = new Vector2();
let distance4 = (x0, y0, x1, y1) => {
  let dx = x0 - x1;
  let dy = y0 - y1;
  return Math.sqrt(dx * dx + dy * dy);
};
const buildPipelineMesh = function (pipeline) {
  if (smMesh3DCache[pipeline.nanoId]) {
    return;
  }
  let minX = Number.MAX_VALUE;
  let minY = Number.MAX_VALUE;
  let minZ = Number.MAX_VALUE;
  let maxX = -Number.MAX_VALUE;
  let maxY = -Number.MAX_VALUE;
  let maxZ = -Number.MAX_VALUE;
  for (let point of pipeline.points) {
    minX = Math.min(minX, point.x);
    minY = Math.min(minZ, point.y);
    minZ = Math.min(minZ, point.z);
    maxX = Math.max(maxX, point.x);
    maxY = Math.max(maxZ, point.y);
    maxZ = Math.max(maxZ, point.z);
  }
  pipeline.boundingBox = [minX, minY, minZ, maxX, maxZ, maxZ];
  let spline2d = scene.getSplineGroupByName(pipeline.nanoId);
  if (!spline2d) {
    spline2d = new Kingfisher.KSplineGroup(pipeline.nanoId, scene);
    let modifier = new Kingfisher.KSplineModifierChamfer(spline2d);
    modifier.threshold = 0.5236;
  }
  let spline3d = scene3d.getSplineGroupByName(pipeline.nanoId);
  if (!spline3d) {
    spline3d = new Kingfisher.KSplineGroup(pipeline.nanoId, scene3d);
    let modifier = new Kingfisher.KSplineModifierChamfer(spline3d);
  }
  let chamfer = Math.max(1, pipeline.chamfer);
  spline2d.modifiers[0].radius = chamfer;
  spline3d.modifiers[0].radius = chamfer;
  let lineGroups = [];
  for (let seg of pipeline.segments) {
    let lineGroup = lineGroups.find(x => {
      if (x[0].pointA === x[x.length - 1].pointB) {
        //loop
        return false;
      }
      if (seg.pointA.segments.length === 2 && seg.pointA === x[x.length - 1].pointB) {
        x.push(seg);
        return true;
      }
      if (seg.pointB.segments.length === 2 && seg.pointB === x[0].pointA) {
        x.unshift(seg);
        return true;
      }
    });
    if (!lineGroup) {
      lineGroups.push([seg]);
    }
  }
  for (let i = 0; i < lineGroups.length; i++) {
    let lineGroup = lineGroups[i];
    let s2d = spline2d.splines[i] || new Kingfisher.KSplinePath(spline2d);
    s2d.enabled = true;
    s2d.points.length = 0;
    s2d.makeDirty();
    let s3d = spline3d.splines[i] || new Kingfisher.KSplinePath(spline3d);
    s3d.enabled = true;
    s3d.points.length = 0;
    s3d.makeDirty();
    for (let line of lineGroup) {
      let pointA = line.pointA;
      s2d.points.push(new Vector3(pointA.x, 0, pointA.z));
      s3d.points.push(new Vector3(pointA.x, pointA.y, pointA.z));
    }
    let isClosed = lineGroup.length > 1 && lineGroup[0].pointA === lineGroup[lineGroup.length - 1].pointB;
    s2d.closed = isClosed;
    s3d.closed = isClosed;
    if (!isClosed) {
      let pointB = lineGroup[lineGroup.length - 1].pointB;
      s2d.points.push(new Vector3(pointB.x, 0, pointB.z));
      s3d.points.push(new Vector3(pointB.x, pointB.y, pointB.z));
    }
  }
  for (let i = lineGroups.length; i < spline2d.splines.length; i++) {
    spline2d.splines[i].enabled = false;
  }
  for (let i = lineGroups.length; i < spline3d.splines.length; i++) {
    spline3d.splines[i].enabled = false;
  }
  smMesh2DCache[pipeline.nanoId] = {
    spline2d
  };
  smMesh3DCache[pipeline.nanoId] = {
    spline3d
  };
};
export { buildPipelineMesh };