import "core-js/modules/es.array.push.js";
import { smMesh2DCache, smMesh3DCache } from "../model/smCollectionData";
const buildAreaMesh = function (area) {
  if (smMesh3DCache[area.nanoId]) {
    return;
  }
  let meshData = new Kingfisher.KfMeshData('', false, false, false);
  let poss2d = [];
  let segments = area.segments;
  for (let i = 0; i < segments.length; i++) {
    let s = segments[i];
    let pointA = s.pointA;
    poss2d.push(pointA.x, pointA.y);
    if (s.isArc) {
      let center = s.center;
      let radius = s.radius * Math.sign(s.angle);
      let startAngle = s.directionA;
      let tessellation = s.tessellation;
      let step = s.stepAngle;
      for (let j = 1; j < tessellation; j++) {
        let angle = startAngle + step * j;
        let sin_ = Math.sin(angle);
        let cos_ = Math.cos(angle);
        poss2d.push(center.x + radius * cos_, center.y + radius * sin_);
      }
    }
  }
  let positions = meshData.poss;
  // let elevation = area.floor ? area.floor.elevation : 0;

  let minX = Number.MAX_VALUE;
  let minZ = Number.MAX_VALUE;
  let maxX = -Number.MAX_VALUE;
  let maxZ = -Number.MAX_VALUE;
  for (let i = 0; i < poss2d.length; i += 2) {
    let x = poss2d[i];
    let y = poss2d[i + 1];
    positions.push(x);
    positions.push(0);
    positions.push(y);
    minX = Math.min(minX, x);
    minZ = Math.min(minZ, y);
    maxX = Math.max(maxX, x);
    maxZ = Math.max(maxZ, y);
  }
  area.boundingBox = [minX, area.height, minZ, maxX, area.height, maxZ];

  //area build
  if (poss2d.length > 4 && window.earcut) {
    meshData.indices = window.earcut(poss2d, null, 2);
  }
  smMesh2DCache[area.nanoId] = {
    poss: poss2d,
    indices: meshData.indices
  };
  smMesh3DCache[area.nanoId] = meshData;
  return meshData;
};
export { buildAreaMesh };