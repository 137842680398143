import _defineProperty from "F:/workspace/\u7A7A\u95F4\u6CBB\u7406\u5DE5\u5177/\u7A7A\u95F4\u6CBB\u7406CAD/space-management/spaceManager/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import { SMDirtyPosition, SMDirtyReticular, SMDirtyAll } from './smDirtyFlag';
import { Vector2, matrix2DIsIdentity, transform2DPoint } from "../maths/vector";
import { getUID, registerSMType, serializeObject, updateProperties, SMObject } from "./smObject";
import { Point2D } from "./sm2DPoint";
import { buildWallMesh } from "./../builder/smWallBuildTools";
import { base64ArrayBuffer } from "../utils/tools";
import { smDatas, smMesh3DCache } from "../model/smCollectionData";
class WallPoint extends Point2D {
  constructor(x, y, wall) {
    super(x, y);
    _defineProperty(this, "wall", void 0);
    _defineProperty(this, "segments", []);
    _defineProperty(this, "fills", []);
    _defineProperty(this, "dirtyFlag", SMDirtyAll);
    this.wall = wall;
    this.finalSegments = [];
  }
  addSegment(s) {
    this.dirtyFlag |= SMDirtyReticular;
    this.segments.push(s);
  }
  removeSegment(s) {
    let index = this.segments.findIndex(x => x.uid === s.uid);
    if (index === -1) {
      return;
    }
    this.segments.splice(index, 1);
    this.makeDirty(SMDirtyReticular);
  }
  makeDirty(flag = SMDirtyAll) {
    this.dirtyFlag |= flag;
    if (flag) {
      for (let segment of this.segments) {
        segment.makeDirty(flag);
      }
    }
    smDatas.objects[this.wall].makeDirty(flag);
  }
  setNeedSave() {
    smDatas.objects[this.wall].setNeedSave();
  }
  checkDirty() {
    if (!this.dirtyFlag) {
      return;
    }
    this.dirtyFlag = 0;
    let finalSegments = this.segments.filter(x => x.isValid);
    if (finalSegments.length > 2) {
      let uid = this.uid;
      finalSegments.sort((x, y) => {
        let rx = x.pointA.uid === uid ? x.directionA : x.directionB;
        let ry = y.pointA.uid === uid ? y.directionA : y.directionB;
        return ry - rx;
      });
    }
    this.finalSegments = finalSegments;
  }
  updateProperties(data) {
    if (super.updateProperties(data)) {
      this.makeDirty(SMDirtyPosition);
      return true;
    }
    return false;
  }
}
class WallSegment {
  constructor(a, b, wall, options) {
    _defineProperty(this, "pointA", void 0);
    _defineProperty(this, "pointB", void 0);
    _defineProperty(this, "angle", void 0);
    _defineProperty(this, "type", void 0);
    _defineProperty(this, "thickness", void 0);
    _defineProperty(this, "direction", void 0);
    _defineProperty(this, "length", void 0);
    _defineProperty(this, "accessories", []);
    _defineProperty(this, "dirtyFlag", SMDirtyAll);
    Object.defineProperty(this, 'uid', {
      value: getUID()
    });
    this.wall = wall;
    this.normal = new Vector2(0, 0);
    this.tangent = new Vector2(0, 0);
    this.direction = 0;
    this.dx = 0;
    this.dy = 0;
    this.pointA = a;
    this.pointB = b;
    if (options) {
      this.angle = options.angle;
    }
  }
  setPointA(point) {
    if (point.uid !== this.pointA.uid) {
      this.pointA.removeSegment(this);
      point.addSegment(this);
      this.pointA = point;
      this.pointA.makeDirty(SMDirtyReticular);
      this.makeDirty();
      return true;
    }
    return false;
  }
  setPointB(point) {
    if (point.uid !== this.pointB.uid) {
      this.pointB.removeSegment(this);
      point.addSegment(this);
      this.pointB = point;
      this.pointA.makeDirty(SMDirtyReticular);
      this.makeDirty();
      return true;
    }
    return false;
  }
  setNeedSave() {
    smDatas.objects[this.wall].setNeedSave();
  }
  makeDirty(flag = SMDirtyAll) {
    if ((this.dirtyFlag & flag) === flag) {
      return;
    }
    this.dirtyFlag |= flag;
    this.pointA.makeDirty(flag);
    this.pointB.makeDirty(flag);
    smDatas.objects[this.wall].makeDirty(flag);
  }
  breakSegment(pos) {
    return smDatas.objects[this.wall].breakSegment(this, pos);
  }
  checkDirty() {
    if (!this.dirtyFlag) {
      return;
    }
    this.dirtyFlag = 0;
    let PI = Math.PI;
    let PI2 = PI * 2;
    let dx = this.pointB.x - this.pointA.x;
    let dy = this.pointB.y - this.pointA.y;
    let length = Math.sqrt(dx * dx + dy * dy);
    if (this.isArc) {
      if (!this.center) {
        this.center = new Vector2();
      }
      if (!this.normalA) {
        this.normalA = new Vector2();
        this.normalB = new Vector2();
      }
      if (length || this.angle) {
        let angle = this.angle;
        let angle_2 = angle * 0.5;
        if (length) {
          let x = dx / length;
          let y = dy / length;
          this.dx = x;
          this.dy = y;
          this.tangent.set(x, y);
          this.normal.set(y, -x);
          this.direction = (Math.atan2(-x, y) + PI2) % PI2;
          let xd = (this.pointB.x + this.pointA.x) * 0.5;
          let yd = (this.pointB.y + this.pointA.y) * 0.5;
          let k = length * 0.5 / Math.tan(angle_2);
          this.radius = Math.sqrt(length * length * 0.25 + k * k);
          this.center.set(xd - this.normal.x * k, yd - this.normal.y * k);
        } else {
          let xd = this.pointB.x - this.center.x;
          let yd = this.pointB.y - this.center.y;
          let radius = Math.sqrt(xd * xd + yd * yd);
          let x = radius ? xd / radius : 0;
          let y = radius ? yd / radius : 0;
          if (radius) {
            this.dx = x;
            this.dy = y;
            this.tangent.set(-y, x);
            this.normal.set(x, y);
            this.direction = (Math.atan2(-y, -x) + PI2) % PI2;
          } else {
            this.dx = 0;
            this.dy = 0;
            this.tangent.set(0, 1);
            this.normal.set(1, 0);
            this.direction = 0;
          }
          this.radius = radius;
        }
        let directionA = (this.direction - angle_2 + PI2) % PI2;
        let directionB = (this.direction + angle_2 + PI) % PI2;
        this.directionA = directionA;
        this.directionB = directionB;
        this.normalA.set(Math.cos(directionA), Math.sin(directionA));
        this.normalB.set(-Math.cos(directionB), -Math.sin(directionB));
        this.tessellation = Math.ceil(Math.abs(angle) * Math.min(25, this.radius * 0.02));
        this.stepAngle = angle / (this.tessellation + 1);
        this.length = Math.abs(this.radius * angle);
      } else {
        this.dx = 0;
        this.dy = 0;
        this.tangent.set(0, 1);
        this.normal.set(1, 0);
        this.length = length;
        this.direction = 0;
        this.directionA = 0;
        this.directionB = 0;
        this.directionA = 0;
        this.directionB = 0;
      }
    } else {
      let x = dx / length;
      let y = dy / length;
      this.dx = x;
      this.dy = y;
      this.tangent.set(x, y);
      this.normal.set(y, -x);
      this.length = length;
      this.direction = (Math.atan2(-x, y) + PI2) % PI2;
      this.directionA = this.direction;
      this.directionB = (this.direction + PI) % PI2;
    }
  }
  get isValid() {
    return this.length > 2;
  }
  get isArc() {
    if (this.angle && !Number.isNaN(this.angle)) {
      this.checkDirty();
      return true;
    }
    return false;
  }
  addAccessory(obj) {
    if (this.accessories.indexOf(obj.nanoId) >= 0) {
      return;
    }
    this.accessories.push(obj.nanoId);
    this.makeDirty(SMDirtyReticular);
  }
  removeAccessory(obj) {
    let index = this.accessories.indexOf(obj.nanoId);
    if (index < 0) {
      return;
    }
    this.accessories.splice(index, 1);
    this.makeDirty(SMDirtyReticular);
  }
  dispose() {
    this.pointA.removeSegment(this);
    this.pointB.removeSegment(this);
    let accessories = [...this.accessories];
    let objects = smDatas.objects;
    for (let i = 0; i < accessories.length; i++) {
      objects[accessories[i]].setSegment(null);
    }
  }
  updateProperties(data) {
    let angle = data ? data.angle : undefined;
    let changed = false;
    if (this.angle !== angle) {
      changed = true;
      this.angle = angle;
    }
    if (data && data.center) {
      if (!this.center) {
        this.center = new Vector2();
      }
      if (this.center.x !== data.center[0] || this.center.y !== data.center[1]) {
        this.center.set(data.center[0], data.center[1]);
        changed = true;
      }
    }
    let models = data ? data.models : undefined;
    let objects = smDatas.objects;
    for (let a of [...this.accessories]) {
      if (!models || models.indexOf(a) === -1) {
        objects[a].setSegment(null);
      }
    }
    if (models) {
      for (let a of models) {
        if (objects[a]) {
          objects[a].setSegment(this);
        }
      }
    }
    if (changed) {
      this.makeDirty(SMDirtyReticular);
    }
    return changed;
  }
  serialize() {
    let wall = smDatas.objects[this.wall];
    let a = wall.points.findIndex(x => x.uid === this.pointA.uid);
    let b = wall.points.findIndex(x => x.uid === this.pointB.uid);
    let res = [a, b];
    let options = null;
    if (this.accessories.length) {
      options = {
        models: [...this.accessories]
      };
    }
    if (this.angle && !Number.isNaN(this.angle)) {
      if (!options) {
        options = {};
      }
      this.checkDirty();
      options.angle = this.angle;
      if (Math.abs(Math.abs(this.angle) - Math.PI * 2) < 0.0001) {
        options.center = [this.center.x, this.center.y];
      }
    }
    if (options) {
      res.push(options);
    }
    return res;
  }
}
class WallFill {
  constructor(wall) {
    _defineProperty(this, "wall", void 0);
    _defineProperty(this, "points", []);
    _defineProperty(this, "edges", null);
    this.wall = wall;
  }
  addPoint(point) {
    if (this.points.findIndex(x => x.uid === point.uid) >= 0) {
      return;
    }
    this.points.push(point);
    point.fills.push(this);
    smDatas.objects[this.wall].makeDirty();
  }
  removePoint(point) {
    let index = this.points.findIndex(x => x.uid === point.uid);
    if (index < 0) {
      return;
    }
    this.points.splice(index, 1);
    point.fills.splice(point.fills.indexOf(this), 1);
    smDatas.objects[this.wall].makeDirty();
  }
  updateProperties(data) {
    let wall = smDatas.objects[this.wall];
    let wallPoints = wall.points;
    let oldPoints = [...this.points];
    this.points = [];
    let points = data.points;
    let changed = false;
    for (let i = 0; i < points.length; i++) {
      let p = wallPoints[points[i]];
      let oldIndex = oldPoints.findIndex(x => x.uid === p.uid);
      if (oldIndex < 0) {
        this.points.push(p);
        p.fills.push(this);
        changed = true;
      } else {
        if (oldIndex !== 0) {
          changed = true;
        }
        oldPoints.splice(oldIndex, 1);
      }
    }
    for (let oldPoint of oldPoints) {
      oldPoint.fills.splice(oldPoint.fills.indexOf(this), 1);
      changed = true;
    }
    if (changed) {
      wall.makeDirty();
    }
    return changed;
  }
  reverse() {
    this.points.reverse();
    smDatas.objects[this.wall].makeDirty();
  }
  serialize() {
    let wall = smDatas.objects[this.wall];
    let points = wall.points;
    let res = [];
    for (let p of this.points) {
      res.push(points.findIndex(x => x.uid === p.uid));
    }
    return {
      points: res
    };
  }
  dispose() {
    for (let p of this.points) {
      p.fills.splice(p.fills.indexOf(this), 1);
    }
  }
}
class Wall extends SMObject {
  get height() {
    return this._height;
  }
  set height(value) {
    if (this._height === value) {
      return;
    }
    this._height = value;
    this.makeDirty();
  }
  constructor(collection, options) {
    super(collection, options);
    _defineProperty(this, "segments", []);
    _defineProperty(this, "points", []);
    _defineProperty(this, "fills", []);
    _defineProperty(this, "thickness", 120);
    _defineProperty(this, "_height", 2800);
    _defineProperty(this, "_purpose", 'normal');
    _defineProperty(this, "_floor", void 0);
    this.type = 'wall';
  }
  set floor(value) {
    if (this._floor === value) {
      return;
    }
    super.floor = value;
    for (let i = 0; i < this.segments.length; i++) {
      let s = this.segments[i];
      let objects = smDatas.objects;
      for (let j = 0; j < s.accessories.length; j++) {
        let temp = objects[s.accessories[j]];
        if (temp.floor !== value) {
          temp.floor = value;
          temp.setNeedSave();
        }
      }
    }
  }
  get floor() {
    return this._floor;
  }
  set purpose(value) {
    if (this._purpose === value) {
      return;
    }
    this._purpose = value;
    this.makeDirty();
  }
  get purpose() {
    return this._purpose;
  }
  createPoint(point) {
    let p = point ? new WallPoint(point.x, point.y, this.nanoId) : new WallPoint(0, 0, this.nanoId);
    this.points.push(p);
    this.makeDirty(SMDirtyReticular);
    return this.points[this.points.length - 1];
  }
  removePoint(point) {
    let index = this.points.findIndex(x => x.uid === point.uid);
    if (index < 0) {
      return;
    }
    let segments = [...point.segments];
    for (let i = 0; i < segments.length; i++) {
      this.removeSegment(segments[i]);
    }
    index = this.points.findIndex(x => x.uid === point.uid);
    if (index >= 0) {
      this.points.splice(index, 1);
    }
    this.makeDirty(SMDirtyReticular);
  }
  createSegment(a, b) {
    if (this.subType) {
      return;
    }
    let s = new WallSegment(a, b, this.nanoId);
    this.segments.push(s);
    s = this.segments[this.segments.length - 1];
    a.addSegment(s);
    b.addSegment(s);
    return s;
  }
  breakSegment(s, p) {
    if (this.subType) {
      return;
    }
    let index = this.segments.findIndex(x => x.uid === s.uid);
    if (index < 0) {
      return;
    }
    let sA = this.createSegment(s.pointA, p);
    let sB = this.createSegment(p, s.pointB);
    sA.updateProperties(s);
    sB.updateProperties(s);
    if (s.isArc) {
      s.checkDirty();
      ///???
      let PI2 = Math.PI * 2;
      let a = Math.atan2(p.y - s.center.y, p.x - s.center.x);
      if (s.angle < 0) {
        a += Math.PI;
      }
      a = (a + PI2) % PI2;
      let da = a - s.directionA;
      if (s.angle > 0) {
        if (da < 0) {
          da += PI2;
        }
      } else {
        if (da > 0) {
          da -= PI2;
        }
      }
      sA.angle = da;
      sB.angle = s.angle - da;
    }
    let objects = smDatas.objects;
    let accessories = [...s.accessories];
    for (let i = 0; i < accessories.length; i++) {
      let a = objects[accessories[i]];
      if (Math.abs(a.position.x - s.pointA.x) < Math.abs(p.x - s.pointA.x) || Math.abs(a.position.z - s.pointA.y) < Math.abs(p.y - s.pointA.y)) {
        a.setSegment(sA);
      } else {
        a.setSegment(sB);
      }
    }
    this.segments.splice(index, 1);
    s.dispose();
    return [sA, sB];
  }
  joinSegment(s0, s1, p) {
    if (this.subType) {
      return;
    }
    if (s0 === s1) {
      return;
    }
    let pointA;
    let pointB = s1.pointA === p ? s1.pointB : s1.pointA;
    if (s0.pointB === p) {
      pointA = s0.pointA;
    } else {
      pointA = pointB;
      pointB = s0.pointB;
    }
    let s = this.createSegment(pointA, pointB);
    s.updateProperties(s0);
    if (s0.isArc) {
      s0.checkDirty();
      ///???
      s.angle = Math.atan2(-(pointB.y - s0.center.y), -(pointB.x - s0.center.x)) - Math.atan2(-(pointA.y - s0.center.y), -(pointA.x - s0.center.x));
      if (s.angle * s0.angle < 0) {
        if (s0.angle < 0) {
          s.angle -= Math.PI * 2;
        } else {
          s.angle += Math.PI * 2;
        }
      }
    }
    let objects = smDatas.objects;
    let accessories = s0.accessories.concat(s1.accessories);
    for (let i = 0; i < accessories.length; i++) {
      objects[accessories[i]].setSegment(s);
    }
    this.removeSegment(s0, false);
    this.removeSegment(s1);
    return s;
  }
  removeSegment(s, disposeEmptyPoint = true) {
    if (this.subType) {
      return;
    }
    let index = this.segments.findIndex(x => x.uid === s.uid);
    if (index >= 0) {
      this.segments.splice(index, 1);
      s.dispose();
      if (disposeEmptyPoint) {
        if (s.pointA.segments.length === 2) {
          s.pointA.segments[0].checkDirty();
          s.pointA.segments[1].checkDirty();
          if (Math.abs(s.pointA.segments[0].direction - s.pointA.segments[1].direction) < 0.0002) {
            this.joinSegment(s.pointA.segments[0], s.pointA.segments[1], s.pointA);
          }
        } else if (!s.pointA.segments.length) {
          let i = this.points.findIndex(x => x.uid === s.pointA.uid);
          if (i >= 0) {
            this.points.splice(i, 1);
          }
        }
        if (s.pointB.segments.length === 2) {
          s.pointB.segments[0].checkDirty();
          s.pointB.segments[1].checkDirty();
          if (Math.abs(s.pointB.segments[0].direction - s.pointB.segments[1].direction) < 0.0002) {
            this.joinSegment(s.pointB.segments[0], s.pointB.segments[1], s.pointB);
          }
        } else if (!s.pointB.segments.length) {
          let i = this.points.findIndex(x => x.uid === s.pointB.uid);
          if (i >= 0) {
            this.points.splice(i, 1);
          }
        }
      }
    }
  }
  createFill() {
    let f = new WallFill(this.nanoId);
    this.fills.push(f);
    return f;
  }
  makeDirty(flag = SMDirtyAll) {
    super.makeDirty(flag);
    smMesh3DCache[this.nanoId] = null;
  }

  // setNeedSave() {
  //     console.log('setNeedSave', this.nanoId)
  //     super.setNeedSave();
  //     this.serialize();
  // }

  setAccessoriesNeedSave() {
    let objects = smDatas.objects;
    for (let s of this.segments) {
      for (let a of s.accessories) {
        objects[a].setNeedSave();
      }
    }
  }
  transform(matrix) {
    if (matrix2DIsIdentity(matrix)) {
      return;
    }
    this.makeDirty(SMDirtyPosition);
    let points = this.points;
    for (let i = 0; i < points.length; i++) {
      let p = points[i];
      transform2DPoint(p, matrix);
      p.dirtyFlag |= SMDirtyPosition;
    }
    let segments = this.segments;
    for (let i = 0; i < segments.length; i++) {
      this.segments[i].dirtyFlag |= SMDirtyPosition;
    }
  }
  updateProperties(data) {
    let changed = super.updateProperties(data);
    if (data) {
      let geometry = data.geometry;
      let properties = data.properties;
      changed |= updateProperties(this, ['position', 'thickness', 'height', 'purpose'], properties);
      if (geometry) {
        if (this.points.length > geometry.coordinates.length) {
          this.points.splice(geometry.coordinates.length, this.points.length - geometry.coordinates.length);
          changed = true;
        }
        for (let i = 0; i < geometry.coordinates.length; i++) {
          let p = this.points[i];
          if (!p) {
            p = new WallPoint(0, 0, this.nanoId);
            this.points.push(p);
            changed = true;
          }
          changed |= p.updateProperties(geometry.coordinates[i]);
        }
      }
      if (properties && properties.segments) {
        if (this.segments.length > properties.segments.length) {
          while (this.segments.length > properties.segments.length) {
            let s = this.segments.pop();
            s.dispose();
          }
          changed = true;
        }
        let points = this.points;
        for (let i = 0; i < properties.segments.length; i++) {
          let s = this.segments[i];
          let temp = properties.segments[i];
          if (s) {
            changed |= s.setPointA(points[temp[0]]);
            changed |= s.setPointB(points[temp[1]]);
            changed |= s.updateProperties(temp[2]);
          } else {
            s = this.createSegment(points[temp[0]], points[temp[1]]);
            if (temp[2]) {
              s.updateProperties(temp[2]);
            }
            changed = true;
          }
        }
      }
      if (properties && properties.fills) {
        if (this.fills.length > properties.fills.length) {
          while (this.fills.length > properties.fills.length) {
            let f = this.fills.pop();
            f.dispose();
          }
          changed = true;
        }
        for (let i = 0; i < properties.fills.length; i++) {
          let f = this.fills[i] || this.createFill();
          changed |= f.updateProperties(properties.fills[i]);
        }
      }
    }
    if (changed) {
      this.makeDirty();
    }
    return changed;
  }
  serialize() {
    let res = super.serialize();
    if (!res.properties) {
      res.properties = {};
    }
    if (!res.geometry) {
      res.geometry = {
        type: 'LineString',
        coordinates: []
      };
    }
    buildWallMesh(this);
    let mesh3d = smMesh3DCache[this.nanoId].toArrayBuffers();
    res.geometry.mesh3d = mesh3d.map(x => base64ArrayBuffer(x));
    let coordinates = res.geometry.coordinates;
    for (let p of this.points) {
      coordinates.push(p.serialize());
    }
    serializeObject(this, ['segments', 'fills', 'thickness', 'height', 'purpose'], res.properties);
    let segments = res.properties.segments.filter(x => x[0] !== -1 && x[1] !== -1);
    if (res.properties.segments.length !== segments.length) {
      console.error('Wall segments have invalid points', this.name, this);
      res.properties.segments = segments;
    }
    return res;
  }
}
registerSMType('wallPoint', WallPoint);
registerSMType('wallSegment', WallSegment);
registerSMType('wallFill', WallFill);
export { Wall };