import { ref } from 'vue';
export const contextMenuStyle = ref('');
export const showContextmenu2 = ref(false);
export const menuData = ref({
  menu: [],
  caller: {},
  fun: function () {}
});
export const closeMenu = function () {
  menuData.value = {
    menu: [],
    caller: {},
    fun: function () {}
  };
  showContextmenu2.value = false;
};
export const openMenu = function (event, data) {
  menuData.value = data;
  let listLength = data.menu.length;
  let y;
  let x;
  if (window.innerHeight - listLength * 20 - event.y < 40) {
    y = `;bottom:${window.innerHeight - event.y - 2}px;`;
  } else {
    y = `;top:${event.y - 2}px;`;
  }
  if (window.innerWidth - 120 - event.x < 40) {
    x = `;left:${event.x - 120 - 2}px;`;
  } else {
    x = `;left:${event.x - 2}px;`;
  }
  showContextmenu2.value = true;
  contextMenuStyle.value = `position:fixed;${y};${x};`;
};