import {computed, ref, watch} from 'vue'
import {DxfWorker} from "@/dxflib/DxfWorker";
import {afterLoadDxf} from "@/maths/dxfTools";
import {isSpaceDown} from "@/utils/keyboard";

export const modeName = {
    model: Kf.t('Scene'),
    drawing: Kf.t('Diagrams'),
    wall: Kf.t('Wall2'),
    area: Kf.t('Regional'),
    pipeline: Kf.t('Pipeline'),
    floor: Kf.t('Floor'),
    devicePoint: Kf.t('Position'),
}
const globalStateProxy = ref({
    mode: 'wall',
    operation: 'move',
    drawingMode: 'object',
    isDrawing: '',
    dragData: null, //拖拽UI中的组件
    selectedData: null, //选中UI中的组件
    wallMode: '',
    mouse:{
        in2dCanvas:false
    },
    dragObject: null, //通过三维渲染拖拽
    dragPosOffset: null,
    dragWaitClone: false,
    dragCloned: false,
    modeArgs: [],
    isBindingModel: false,
    isReplacingModel: false,
    enableAdsorbent: true,
    enableGridView: true,
    typeViews: {
        model: true,
        wall: true,
        area: true,
        pipeline: true,
        drawing: true,
    },
    dxfSelected: {
        blockName: null,
        blocks: [],
        index: -1,
    },
    is3dDragging: false,
    toolBarParams: {
        model: {
            name: '未选择',
            quantity: 10,
            height: 0,
            row: 10,
            column: 2,
        },
        pipeline: {
            height: 0,
            elevation: 50,
        }
    }
});

const globalState = globalStateProxy.value;

globalState.isPickMode = computed(() => {
    return !isSpaceDown.value && globalState.operation === 'pick';
})
globalState.isMoveMode = computed(() => {
    return isSpaceDown.value || globalState.operation === 'move';
})
globalState.isDrawMode = computed(() => {
    return !isSpaceDown.value && globalState.operation === 'draw';
})

Object.defineProperty(globalState, 'enableDrawingView', {
        get() {
            return globalState.typeViews.drawing;
        },
        set(value) {
            globalState.typeViews.drawing = value;
        }
    });

watch(() => [globalState.selectedData], () => {
    let model = globalState.selectedData;
    if (!model) {
        globalState.toolBarParams.model.name = '未选择';
        return;
    }
    if (typeof model.labelInterpret === 'string') {
        model.labelInterpret = JSON.parse(model.labelInterpret);
    }
    let locale = window.Kf.locale.value;
    locale = locale ? locale.replace("-", "") : 'zhCN';
    globalState.toolBarParams.model.name = (model.labelInterpret ? model.labelInterpret[locale] : '') || model.name;
});

const globalNeedSave = ref(false);

const viewPortDataProxy = ref({
    view2D: true, view3D: true
});

const viewPortData = viewPortDataProxy.value;

let _area;
let currDataValue = {
    floor: null,
    wall: null,
    area: null,
    pipeline: null,
    currList: [],
    currListFlag: 0,
    models: {},
    model2: null,
    devicePoint:null,
};

Object.defineProperty(currDataValue, 'segments',
    {
        get() {
            let mode = globalState.mode;
            if (['wall', 'area', 'pipeline'].indexOf(mode) < 0) {
                return [];
            }
            let obj = currData[mode];
            if (!obj || obj.isDeleted || !obj.finalVisible) {
                return [];
            }

            return obj._currSegments || [];
        },
        set(value) {
            let mode = globalState.mode;
            if (['wall', 'area', 'pipeline'].indexOf(mode) < 0) {
                return;
            }
            if (!currData[mode]) {
                return;
            }
            currData[mode]._currSegments = value || [];
        }
    });

Object.defineProperty(currDataValue, 'segment',
    {
        get() {
            return currData.segments[0];
        },
        set(value) {
            currData.segments = value ? [value] : [];
        }
    });

Object.defineProperty(currDataValue, 'points',
    {
        get() {
            let mode = globalState.mode;
            if (['wall', 'area', 'pipeline'].indexOf(mode) < 0) {
                return [];
            }
            let obj = currData[mode];
            if (!obj || obj.isDeleted || !obj.finalVisible) {
                return [];
            }

            return obj._currPoints || [];
        },
        set(value) {
            let mode = globalState.mode;
            if (['wall', 'area', 'pipeline'].indexOf(mode) < 0) {
                return;
            }
            if (!currData[mode]) {
                return;
            }
            currData[mode]._currPoints = value || [];
        }
    });

Object.defineProperty(currDataValue, 'point',
    {
        get() {
            return currData.points[0];
        },
        set(value) {
            return currData.points = value ? [value] : [];
        }
    });

const currDataProxy = ref(currDataValue);

const currData = currDataProxy.value;
currData.currListMap = computed(() => {
    return new Set(currData.currList);
})

currData.finalCurrList = computed(() => {
    let list = currData.currList;

    list = list.map(x => x.isGroup ? x.children : x).flat();
    list = list.map(x => x.isGroup ? x.children : x).flat();

    return list;
});

currData.currObject = computed(() => {
    return currData.finalCurrList[0];
});

let _isArrayEqual = (a, b) => {
    if (a === b) {
        return true;
    }
    if (a.length !== b.length) {
        return false;
    }

    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) {
            return false;
        }
    }

    return true;
}

currData.cleanCurrList = () => {
    if (globalState.mode === 'model') {
        currData.models = {};
    }
    currData.currList.length = 0;
    currData.currListFlag++;
}
currData.setCurrList = (objs) => {
    if (!(objs instanceof Array)) {
        if (objs) {
            objs = [objs];
        }
        else {
            objs = [];
        }
    }
    if (_isArrayEqual(objs, currData.currList)) {
        return;
    }
    if (!objs.length) {
        currData.cleanCurrList();
    }
    else {
        if (objs[0].type === 'model') {
            for (let key of Object.keys(currData.models)) {
                if (!objs.find(x => x.nanoId === key)) {
                    delete currData.models[key];
                }
            }
            for (let o of objs) {
                currData.models[o.nanoId] = o;
            }
        }
        if (objs[0].type === 'wall') {
            currData.wall = objs[0];
        }
        if (objs[0].type === 'area') {
            currData.area = objs[0];
        }
        if (objs[0].type === 'pipeline') {
            currData.pipeline = objs[0];
        }
        currData.currList = [...objs];
        currData.currListFlag++;
    }
}

currData.addToCurrList = (objs, isUpdate = true) => {
    if (objs) {
        let obj;
        if (objs instanceof Array) {
            if (objs.length) {
                obj = objs[0];
                if (objs[0].type === 'model') {
                    for (let o of objs) {
                        currData.models[o.nanoId] = o;
                    }
                }
                currData.currList.push(...objs);
            }
        } else {
            obj = objs;
            if (objs.type === 'model') {
                currData.models[objs.nanoId] = objs;
            }
            currData.currList.push(objs);
        }

        if (obj) {
            switch (obj.type) {
                case 'wall':
                case 'area':
                case 'pipeline':
                    currData[obj.type] = obj;
                    break;
            }
        }
    }
    if (isUpdate) {
        currData.currListFlag++;
    }
}

currData.removeFromCurrList = (objs, isUpdate = true) => {
    if (objs) {
        if (!(objs instanceof Array)) {
            objs = [objs];
        }

        let currList = currData.currList;
        for (let o of objs) {
            currList.removeByVal(o);
            switch (o.type) {
                case 'wall':
                case 'area':
                case 'pipeline':
                    currData[o.type] = o;
                    break;
                case 'model':
                    delete currData.models[o.nanoId];
                    break;
            }
        }
    }
    if (isUpdate) {
        currData.currListFlag++;
    }
}

window.currData = currData;

const loadingDxfCache = {};
export const loadDxf = (drawing, url, callback) => {
    drawing.loadingState = 1;
    drawing.loadingProgress = 0;
    if (loadingDxfCache[url]) {
        loadingDxfCache[url].cache.set(drawing, {drawing, callback});
        return;
    }

    loadingDxfCache[url] = {cache: new Map()};
    if (callback) {
        loadingDxfCache[url].cache.set(drawing, {drawing, callback});
    }

    let url_ = url;
    if (url_.indexOf('.dxf') < 0) {
        url_ += '.dxf';
    }

    let _loadDxf = async () => {
        let error = null;
        try {
            let dxfWorker = new DxfWorker();
            const {scene, dxf} = await dxfWorker.Load(url_, [
                // Kingfisher.FileTools.GetAbsoluteUrl("lib://fonts/Roboto-LightItalic.ttf"),
                // Kingfisher.FileTools.GetAbsoluteUrl("lib://fonts/NotoSansDisplay-SemiCondensedLightItalic.ttf"),
                // Kingfisher.FileTools.GetAbsoluteUrl("lib://fonts/HanaMinA.ttf"),
                // Kingfisher.FileTools.GetAbsoluteUrl("lib://fonts/NanumGothic-Regular.ttf"),
                "lib://fonts/Alibaba-PuHuiTi-Regular.ttf",
            ], {});
            await dxfWorker.Destroy();
            afterLoadDxf(scene);

            scene.fileId = url;
            smDxfCache[url] = scene;

            let layers = scene.layers.map(x => {
                let res = {
                    layerID: x.name,
                    layerName: x.displayName,
                    color: x.color,
                }
                if (x.bounds) {
                    res.boundingBox = [x.bounds.minX, x.bounds.minY, x.bounds.maxX, x.bounds.maxY];
                    res.count = x.batches.length;
                }
                return res;
            });

            let blocks = [];
            for (let key in scene.blocks) {
                // if (key[0] === '*') {
                //     continue;
                // }
                let x = scene.blocks[key];
                if (x.entities.length) {
                    blocks.push({
                        name: key,
                        boundingBox: [x.bounds.minX, x.bounds.minY, x.bounds.maxX, x.bounds.maxY],
                        usedCount: x.entities.length,
                    });
                }
            }

            blocks.sort((a, b) => b.usedCount - a.usedCount);
            for (let cb of loadingDxfCache[url].cache.values()) {
                cb.drawing.fileId = scene.fileId;
                cb.drawing.updateProperties({
                    properties: {
                        layers,
                        blocks,
                    }
                });
                cb.drawing.loadingState = 2;
                cb.drawing.loadingProgress = 1;
                if (cb.callback) {
                    cb.callback(scene);
                }
            }
        }
        catch (e) {
            console.error(e);
            error = e;
            smDxfCache[url] = 'error';
            for (let cb of loadingDxfCache[url].cache.values()) {
                cb.drawing.fileId = url;
                cb.drawing.loadingState = 3;
                cb.drawing.loadingProgress = 1;
                if (cb.callback) {
                    cb.callback();
                }
            }
        }
        delete loadingDxfCache[url];
    };

    _loadDxf();
}

export const loadDxfData1 = (fileId, callback) => {
    if (loadingDxfCache[fileId]) {
        if (callback) {
            loadingDxfCache[fileId].callbacks.push(callback);
        }
        return;
    }

    loadingDxfCache[fileId] = {callbacks: []};
    if (callback) {
        loadingDxfCache[fileId].callbacks.push(callback);
    }

    // DxfService.getLayerByNameOrType({
    //     fileId,
    //     name: '_',
    //     type: '_',
    // }).then((layers) => {
    //     console.log(layers);
    //     let temp = {
    //         data: layers,
    //         layers: {},
    //     };
    //
    //     let cacheMap = temp.layers;
    //     for (let layer of layers) {
    //         if (!cacheMap[layer.layerName]) {
    //             cacheMap[layer.layerName] = [];
    //         }
    //         if (!cacheMap[layer.layerName][layer.type]) {
    //             cacheMap[layer.layerName][layer.type] = [];
    //         }
    //         cacheMap[layer.layerName][layer.type].push(layer);
    //     }
    //
    //     smDxfCache[fileId] = temp;
    //     for (let cb of loadingDxfCache[fileId].callbacks) {
    //         cb(layers, temp);
    //     }
    //     delete loadingDxfCache[fileId];
    // });
}

export {
    globalState, globalNeedSave, viewPortData, currData
}

window.globalState = globalState;

window.globalNeedSave = globalNeedSave;

