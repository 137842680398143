import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "kingfisher_props_container2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_num_input = _resolveComponent("num-input");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["kingfisher_props_container", $setup.show ? 'kingfisher_input kingfisher_input_active' : 'kingfisher_input']),
    style: {
      "width": "100%"
    }
  }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_num_input, {
    placeholder: $setup.placeholder,
    propValue: $setup.inputValue,
    label: $props.props.label.zh,
    title: $props.props.name,
    onChange: $setup.doInputContent,
    isNumber: true,
    step: $setup.step,
    max: $setup.max,
    min: $setup.min,
    precision: $setup.precision,
    unit: $setup.unit
  }, null, 8, ["placeholder", "propValue", "label", "title", "onChange", "step", "max", "min", "precision", "unit"])])], 2);
}