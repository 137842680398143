var _window$smCollection;
import "core-js/modules/es.array.push.js";
// import {smDatas} from "./smCollectionData";

const __propStroe = {};
const registerObjPropModel = function (name, model) {
  let res = {
    properties: {}
  };
  let base = model.base || 'base';
  let assignProperties = function (a, b) {
    if (!a) {
      return b;
    }
    if (!b) {
      return a;
    }
    let temp = [];
    for (let i = 0; i < a.length; i++) {
      temp.push(a[i]);
    }
    for (let i = 0; i < b.length; i++) {
      let name = b[i].name;
      let index = temp.findIndex(x => x.name === name);
      if (index < 0) {
        temp.push(b[i]);
      } else {
        temp[index] = b[i];
      }
    }
    return temp;
  };
  if (__propStroe[base]) {
    res.properties = assignProperties(res.properties, __propStroe[base].properties);
  }
  res.properties = assignProperties(res.properties, model.properties);
  __propStroe[name] = res;
};
const getPropModel = function (obj) {
  if (!obj) {
    return {};
  }
  let type = obj.type || '';
  return __propStroe[type] || __propStroe[type.substring(2).toLowerCase()] || __propStroe['base'];
};
const getComponentType = function (prop) {
  if (!prop) {
    return 'noProp';
  }
  if (prop.type === 'string') {
    // console.log('inputProp');
    return 'inputProp';
  }
  // console.log(prop.type + 'Prop');
  return prop.type + 'Prop';
};
const base = {
  properties: [{
    name: 'name',
    label: {
      zh: Kf.t('Name')
    },
    type: 'string',
    defaultValue: '',
    major: true
  }, {
    name: 'floor',
    label: {
      zh: Kf.t('Floor')
    },
    type: 'enum',
    options: (_window$smCollection = window.smCollection) === null || _window$smCollection === void 0 ? void 0 : _window$smCollection.floors
  }]
};
const floor = {
  properties: [{
    name: 'elevation',
    label: {
      zh: Kf.t('Elevation')
    },
    type: 'number',
    defaultValue: 0,
    unit: 'cm'
  }]
};
const drawing = {
  properties: [{
    name: 'opacity',
    label: {
      zh: Kf.t('Transparency')
    },
    type: 'number',
    defaultValue: 1,
    min: 0,
    max: 1,
    precision: 1,
    step: 0.1
  }]
};
const imagedrawing = {
  base: 'drawing'
};
const wall = {
  properties: [{
    name: 'height',
    label: {
      zh: Kf.t('Height')
    },
    type: 'number',
    defaultValue: 0,
    min: 0,
    unit: 'cm'
  }]
};
const area = {};
registerObjPropModel('base', base);
registerObjPropModel('floor', floor);
registerObjPropModel('drawing', drawing);
registerObjPropModel('imagedrawing', imagedrawing);
registerObjPropModel('wall', wall);
registerObjPropModel('area', area);
export { registerObjPropModel, getPropModel, getComponentType };