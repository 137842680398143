import "core-js/modules/es.array.push.js";
import { ref, watch, computed } from 'vue';
import { currData, globalState } from "@/model/globalData";
import { angleToRadian, each, eachTimes, radianToAngle } from "@/utils/tools";
import { pipelineOptions, wallOptions } from "@/model/purposeOptions";
import { smDatas } from "@/model/smCollectionData";
import { undoMap } from "@/undo/undoMap";
export const isMultiple = computed(() => {
  return currData.currList.length > 1;
});
export const formatWallOptions4SelectUILable2Name = function (options) {
  let newOptions = [];
  each(options, o => {
    newOptions.push({
      name: o.label
    });
  });
  return newOptions;
};
export const formatWallOptions4SelectUIPurpose2Name = function (options, optionsType = wallOptions) {
  let newOptions = [];
  each(options, o => {
    each(optionsType, wo => {
      if (wo.value === o) {
        newOptions.push(wo.label);
        return -1;
      }
    });
  });
  return newOptions;
};
export const formatWallOptions4WallObject = function (data, optionsType = wallOptions) {
  let val = null;
  let _data = new Array(data.length);
  each(optionsType, o => {
    if (o.label === data[0]) {
      val = o.value;
      return -1;
    }
  });
  _data.fill(val);
  return _data;
};
export const getItemProperty = function (property, type) {
  let currList = currData.finalCurrList;
  let l0 = currList[0];
  if (!l0 || globalState.mode === 'drawing' && !l0.type.toLowerCase().includes("drawing") || globalState.mode !== 'drawing' && l0.type !== globalState.mode) {
    return [];
  }
  let path = property.split('.');
  let result = [];
  //格式化path
  if (type) {
    switch (type) {
      case 'size':
        {
          let name = path.pop();
          name = name === 'x' ? 0 : name === 'y' ? 1 : 2;
          path.push(name);
        }
        break;
    }
  }
  //获取对象属性值
  if (currList.length === 0) {
    return [];
  }
  each(currList, target => {
    let _t;
    if (type === 'floor') {
      _t = {
        value: target.floor,
        label: target._floorName
      };
    } else {
      _t = target;
      each(path, _p => {
        _t = _t[_p];
      });
      if (type === 'rotation') {
        _t = radianToAngle(_t);
      }
    }
    result.push(_t);
  });
  //格式化属性值
  if (type === 'wall-purpose') {
    result = formatWallOptions4SelectUIPurpose2Name(result);
  }
  if (type === 'pipeline-purpose') {
    result = formatWallOptions4SelectUIPurpose2Name(result, pipelineOptions);
  }
  return result; // && result[0] === undefined ?[]:result
};
export const updateItemProperty = function (data, property, type) {
  let currList = currData.finalCurrList;
  let path = property.split('.');
  if (type === 'wall-purpose') {
    data = formatWallOptions4WallObject(data);
  } else if (type === 'pipeline-purpose') {
    data = formatWallOptions4WallObject(data, pipelineOptions);
  }
  let _p = path[path.length - 1];
  let l = path.length;
  let undoItems = [];
  let newValues = [];
  each(currList, (target, i) => {
    let _target = target;
    if (type === 'floor') {
      _target.floor = data[i].value === 'undefined' ? null : data[i].value;
      _target._floorName = data[i].value === 'undefined' ? null : data[i].label;
    } else {
      eachTimes(l - 1, ii => {
        _target = target[path[ii]];
      });
      let value = data[i];
      if (type === 'size') {
        let size = target.properties.originalBoundingInfo.size;
        let original = size[_p] || 1;
        value = (target.mirrored ? -1 : 1) * data[i] / original;
      }
      if (type === 'rotation') {
        value = angleToRadian(value);
      }
      undoItems.push({
        target,
        value: _target[_p]
      });
      _target[_p] = value;
      if (type === 'size' && target.keepRatio) {
        _target['x'] = value;
        _target['y'] = value;
        _target['z'] = value;
      }
      newValues.push(value);
    }
    if (type === 'size' || type === 'keepRatio') {
      target.updateBoundingBox();
    }
    // if (target.type === 'model' && path[0] === 'customId') {
    //     target.deviceId = '';
    //     for (let devicePoint of smDatas.devicePoints) {
    //         devicePoint.deviceModelList.delete(target.nanoId);
    //         if (target.customId && target.customId === devicePoint.deviceMapId) {
    //             devicePoint.deviceModelList.add(target.nanoId);
    //             target.deviceId = devicePoint.deviceId;
    //             if (devicePoint.deviceName && devicePoint.deviceName !== 'null') {
    //                 target.name = devicePoint.deviceName;
    //             }
    //         }
    //     }
    // }
    target.makeDirty();
    target.setNeedSave();
  });
  undoMap.pushWithCheck({
    cmd: 'updateProperty',
    property,
    type,
    items: undoItems
  }, newValues);
};
export const floorListOptions = computed(() => {
  let options = [{
    label: Kf.t('Undefined'),
    value: 'undefined'
  }];
  options.push(...smDatas.floorsProxy.value.filter(x => !x.isDeleted).sort((a, b) => a.elevation - b.elevation).map(x => {
    return {
      label: x.name,
      value: x._nanoId
    };
  }));
  return options;
});