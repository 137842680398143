import { ref, watch, computed } from 'vue';
import { smDatas } from "@/model/smCollectionData";
import { currData } from "@/model/globalData";
import { getObjectName, getFloorName, getObjectPurpose, showContextMenu, setProperty, doItemClick, doItemDBClick, loadBodyScrollTop } from "./index.js";
export default {
  name: "kfArea",
  setup() {
    let bodyManager = ref(null);
    let visibleObjs = computed(() => {
      console.log("--area-res", smDatas.areas.filter(x => !x.isDeleted && x.floorVisible));
      return smDatas.areas.filter(x => !x.isDeleted && x.floorVisible);
    });
    watch(() => currData.currListFlag, () => {
      loadBodyScrollTop(bodyManager, 'areas', 'area');
    });
    let isActive = function (item) {
      return currData.currListMap.has(item);
    };
    return {
      smDatas,
      currData,
      getFloorName,
      getObjectName,
      getObjectPurpose,
      showContextMenu,
      setProperty,
      doItemClick,
      doItemDBClick,
      bodyManager,
      visibleObjs,
      isActive
    };
  }
};