<template>
  <div>
    <el-dialog custom-class="kingfisher_dialog_create" :title="$t('CreateProject')" v-model="showDialog"
               width="540px"
               top="200px" :close-on-click-modal="false">
      <el-container>
        <el-main style="margin-top: 0.625rem;padding: 0px;">
          <el-card class="box-card">
            <el-row style="margin-bottom: 10px;">
              <el-col :span="6" style="text-align: right;  ">{{ $t('ProjectName') }}
              </el-col>
              <el-col :span="16" style="padding-left: 22px">
                <input v-model="newProject.name"
                       maxlength="20"
                       style="padding: 2px 15px;height: 40px;color: #999999;background-color: #181818;width: 75%"
                       :placeholder="$t('NameLength20Characters')"
                />
              </el-col>
            </el-row>
            <el-row style="margin-bottom: 10px;" >
              <el-col :span="6" style="text-align: right;" >{{ $t('Grouping') }}
              </el-col>
              <el-col :span="16" style="padding-left: 22px">
                <el-form class="kf-select-container">
                  <el-form-item prop="name">
                    <el-select v-model="newProject.groups" popper-class="kf-select" :placeholder="$t('PleaseSelect')">
                      <el-option
                          :key="index"
                          :label="item"
                          :value="item"
                          v-for="(item,index) in Object.keys(projectList)"></el-option>
                    </el-select>
                  </el-form-item>
                </el-form>
              </el-col>
            </el-row>
            <el-row style="margin-top: 0.625rem">
              <el-col :span="6" style="text-align: right; ">{{ $t('ProjectDescription') }}
              </el-col>
              <el-col :span="16" style="padding-left: 22px;height: 140px">
                <textarea v-model="newProject.description"
                          style="height: 120px;padding: 2px 15px;color: #999999;background-color: #181818;width: 75%;resize: none"
                          :placeholder="$t('NameLength80Characters')" maxlength="80"/>
              </el-col>
            </el-row>
          </el-card>
        </el-main>
      </el-container>
      <template #footer>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" :loading="loadingBtn" @click="doCreateProject">{{ $t('ClickOkToConfirm2') }}</el-button>
    </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {computed, ref, watch} from 'vue'
import env from '@/model/env';
import TextareaProp from "@/components/propWidget/textareaProp";
import projectService from "@/service/project";
import projectModel from "@/model/project";
import {ElMessage} from 'element-plus';
import {getUser} from "@/utils/auth";

export default {
  name: "KFCreatProject",
  components: {TextareaProp},
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {emit}) {
    let showDialog = ref(false);
    let loadingBtn = ref(false);
    let newProject = ref({name: '', author: "", description: "", code: '', groups: Kf.t('Default')});
    watch(props, (newValue, oldValue) => {
      showDialog.value = newValue.show;
    })

    watch(showDialog, (newValue, oldValue) => {
      if (newValue) {
        return;
      }
      emit("propChange", {type: "close", 'value': showDialog.value});
    })

    const doCreateProject = function () {
      let user = JSON.parse(getUser());
      if (newProject.value.name === '') {
        ElMessage({type: 'error', message: Kf.t('PleaseFillInTheProjectName'), duration: 1000,group:true});
        return;
      }
      newProject.value.code = newProject.value.name;
      newProject.value.author = user.userName;
      showDialog.value = true;
      projectService.createProject(newProject.value).then(result => {
        showDialog.value = false;
        if (result === -1 || result === '') {
          ElMessage({type: 'warning', message: Kf.t('NameIsADuplicate')});
          return;
        } else {
          ElMessage({type: 'success', message: Kf.t('CreatedSuccessful')});
          projectModel.loadProject();
          emit("propChange", {type: "submitOK", 'value': result});
          showDialog.value = false;
        }
      });
    }

    return {
      showDialog,
      newProject,
      updateTime: env.updateTime,
      version: env.version,
      doCreateProject,
      loadingBtn,
      projectList: projectModel.projectGroupList,
    }
  }
}
</script>

<style>
.kf-select-container input {
  border: 0px!important;
  border-radius: 0px;
  background-color: #181818!important;
}
</style>
