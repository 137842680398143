import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = {
  class: "w-100"
};
const _hoisted_2 = {
  key: 0,
  class: "w-100"
};
const _hoisted_3 = {
  class: "kf-model-collapse-content",
  draggable: "false"
};
const _hoisted_4 = {
  class: "kf-model-collapse-content1 gap-4",
  draggable: "false"
};
const _hoisted_5 = ["title", "onMouseover", "onMouseout", "onMousedown", "onClick"];
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  class: "kf-widget-detail-txt"
};
const _hoisted_8 = {
  style: {
    "white-space": "nowrap",
    "overflow": "hidden",
    "text-overflow": "ellipsis"
  },
  class: "ml10"
};
const _hoisted_9 = {
  class: "mr10"
};
const _hoisted_10 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$props$props$childre;
  const _directive_loading = _resolveDirective("loading");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [((_$props$props$childre = $props.props.children) === null || _$props$props$childre === void 0 ? void 0 : _$props$props$childre.length) > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_2)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.data, (item2, index2) => {
    return _withDirectives((_openBlock(), _createElementBlock("div", {
      class: "kf-model-item",
      key: item2.uuid + '-' + index2,
      title: $setup.getLabel($setup.currWidget),
      onMouseover: $event => $setup.widgetMouseover($event, item2),
      onMouseout: _withModifiers($event => $setup.widgetMouseout($event, item2), ["stop"]),
      onMousedown: $event => $setup.widgetMousedown($event, item2),
      onClick: $event => $setup.widgetClick(item2),
      draggable: "false"
    }, [_createElementVNode("img", {
      src: $setup.getImageUrl(item2),
      class: "h100 w100",
      draggable: "false"
    }, null, 8, _hoisted_6), $setup.globalState.mode === 'model' ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      class: _normalizeClass(["kf-model-item-mark", [$setup.globalState.selectedData && item2.uuid === $setup.globalState.selectedData.uuid ? 'kf-model-item-active-mark' : '']])
    }, null, 2)) : _createCommentVNode("", true)], 40, _hoisted_5)), [[_directive_loading]]);
  }), 128))])]), $setup.showDetail ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: "kf-widget-detail",
    style: _normalizeStyle($setup.detailStyle)
  }, [_createElementVNode("div", {
    class: "kf-widget-detail-con",
    style: _normalizeStyle($setup.getImageUrlCss($setup.currWidget))
  }, null, 4), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, _toDisplayString($setup.getLabel($setup.currWidget)), 1), _createElementVNode("div", _hoisted_9, "V" + _toDisplayString($setup.currWidget.version), 1)])], 4)) : _createCommentVNode("", true), $setup.showDragImg ? (_openBlock(), _createElementBlock("img", {
    key: 2,
    class: "kf-widget-drag-img",
    src: $setup.getImageUrl($setup.currWidget),
    style: _normalizeStyle($setup.dragImgStyle),
    draggable: "false"
  }, null, 12, _hoisted_10)) : _createCommentVNode("", true)]);
}