import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0638b314"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "scoped-batchModelBindDevicePointInfo-container"
};
const _hoisted_2 = {
  class: "scoped-batchModelBindDevicePointInfo-tile"
};
const _hoisted_3 = {
  class: "scoped-batchModelBindDevicePointInfo-content"
};
const _hoisted_4 = {
  class: "scoped-batchModelBindDevicePointInfo-tile"
};
const _hoisted_5 = {
  class: "scoped-batchModelBindDevicePointInfo-content"
};
const _hoisted_6 = {
  class: "scoped-batchModelBindDevicePointInfo-tile"
};
const _hoisted_7 = {
  class: "scoped-batchModelBindDevicePointInfo-content"
};
const _hoisted_8 = {
  class: "scoped-batchModelBindDevicePointInfo-tile"
};
const _hoisted_9 = {
  class: "scoped-batchModelBindDevicePointInfo-content"
};
const _hoisted_10 = {
  class: "scoped-batchModelBindDevicePointInfo-tile"
};
const _hoisted_11 = {
  class: "scoped-batchModelBindDevicePointInfo-content"
};
import { batchBindInfo } from "@/components/mainContent/rightPanel/point";
export default {
  __name: 'BatchModelBindDevicePointInfo',
  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('SuccessfullyBinded')), 1), _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(batchBindInfo).successBindObjects.size), 1)]), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('ModelWithoutADeviceCode')), 1), _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(batchBindInfo).haveNotCustomId.size), 1)]), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('AModelThatWasBoundBeforeTheBindingWasPerformed')), 1), _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(batchBindInfo).haveBindModel.size), 1)]), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('PointsThatWereBoundBeforeTheBindingWasPerformed')), 1), _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(batchBindInfo).haveBindDevicePoint.size), 1)]), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('UnboundObject')), 1), _createElementVNode("div", _hoisted_11, _toDisplayString(_unref(batchBindInfo).unBindModel.size), 1)])]);
    };
  }
};