import "core-js/modules/es.array.push.js";
import { undoMap } from "@/undo/undoMap";
import { globalState, currData } from "@/model/globalData";
import { registerDialogCallback, showCalibrateCenter, showKFDialog } from "@/components/widgets/contextmenu/KF2DDialog";
import { BatchingKey } from "@/dxflib/BatchingKey";
import { dxfDrawingPick } from "@/maths/dxfTools";
import { Vector2 } from "@/maths/vector";
class DrawingCreator {
  constructor(creator) {
    this.type = 'drawingCreator';
    this.owner = creator;
    this.collection = creator.collection;
    this.tools = creator.tools;
    registerDialogCallback('calibrateCenter', this.uiCalibrateCenter.bind(this));
  }
  mouseRelease(pos, ray) {
    currData.calibrateStartPoint = null;
  }
  modeChanged() {
    this.mouseRelease();
  }
  updateText() {}
  transformObjects(matrix, floor) {
    let walls = this.collection.walls;
    for (let obj of walls) {
      if (floor === obj.floor) {
        obj.transform(matrix);
        obj.setNeedSave();
      }
    }
    let areas = this.collection.areas;
    for (let obj of areas) {
      if (floor === obj.floor) {
        obj.transform(matrix);
        obj.setNeedSave();
      }
    }
    let models = this.collection.models;
    for (let obj of models) {
      if (floor === obj.floor) {
        obj.transform(matrix);
        obj.setNeedSave();
      }
    }
  }
  uiCalibrateCenter(currArgs, origArgs) {
    let state = currArgs[1];
    if (!origArgs || !state) {
      if (globalState.modeTemp) {
        globalState.mode = globalState.modeTemp;
      }
      if (globalState.operationTemp) {
        globalState.operation = globalState.operationTemp;
      }
      globalState.modeTemp = '';
      globalState.operationTemp = '';
      globalState.modeArgs = [];
      return;
    }
    let uiPos = currArgs[0];
    let clickPos = origArgs[2];
    clickPos.x -= uiPos.x;
    clickPos.y -= uiPos.y;
    let drawing = origArgs[1];
    let flag = origArgs[3];
    this._calibrateCenter(drawing, clickPos, flag);
    if (globalState.modeTemp) {
      globalState.mode = globalState.modeTemp;
    }
    if (globalState.operationTemp) {
      globalState.operation = globalState.operationTemp;
    }
    globalState.modeTemp = '';
    globalState.operationTemp = '';
    globalState.modeArgs = [];
  }
  _calibrateCenter(drawing, pos, flag) {
    let matrix = [1, 0, 0, 1, -pos.x, -pos.y];
    undoMap.push({
      cmd: 'calibrate',
      target: drawing,
      value: {
        scaling: drawing.scaling,
        centerX: drawing.centerX,
        centerY: drawing.centerY,
        matrix: matrix
      }
    });
    drawing.centerX += pos.x;
    drawing.centerY += pos.y;
    drawing.makeDirty();
    drawing.setNeedSave();
    if (flag === 'floor') {
      this.transformObjects(matrix, drawing.floor);
    }
    let cameraArgs = this.owner.scene.activeCamera.args;
    cameraArgs.target.x -= pos.x;
    cameraArgs.target.z -= pos.y;
    this.owner.scene.activeCamera.focusOn(cameraArgs, 0);
  }
  calibrateCenter(drawing, pos, flag) {
    showKFDialog('calibrateCenter', drawing, pos, flag);
  }
  calibrateSize(drawing, pos, flag) {
    if (!currData.calibrateStartPoint) {
      currData.calibrateStartPoint = pos;
    } else {
      let startPoint = currData.calibrateStartPoint;
      currData.calibrateStartPoint = null;
      let dx = pos.x - startPoint.x;
      let dy = pos.y - startPoint.y;
      let length = Math.round(Math.sqrt(dx * dx + dy * dy));
      if (!length) {
        return;
      }
      currData.getCalibrateLength(length, cLength => {
        if (cLength && cLength !== length) {
          let scaling = cLength / length;
          let dx_ = drawing.centerX / length * (cLength - length);
          let dy_ = drawing.centerY / length * (cLength - length);
          let matrix = [scaling, 0, 0, scaling, 0, 0];
          undoMap.push({
            cmd: 'calibrate',
            target: drawing,
            value: {
              scaling: drawing.scaling,
              centerX: drawing.centerX,
              centerY: drawing.centerY,
              matrix: matrix
            }
          });
          drawing.scaling *= scaling;
          drawing.centerX += dx_;
          drawing.centerY += dy_;
          drawing.makeDirty();
          drawing.setNeedSave();
          if (flag === 'floor') {
            this.transformObjects(matrix, drawing.floor);
          }
          let cameraArgs = this.owner.scene.activeCamera.args;
          cameraArgs.target.x = cameraArgs.target.x * scaling;
          cameraArgs.target.z = cameraArgs.target.z * scaling;
          cameraArgs.distance *= scaling;
          this.owner.scene.activeCamera.focusOn(cameraArgs, 0);
        }
        this.mouseRelease();
        if (globalState.modeTemp) {
          globalState.mode = globalState.modeTemp;
        }
        if (globalState.operationTemp) {
          globalState.operation = globalState.operationTemp;
        }
        globalState.modeTemp = '';
        globalState.operationTemp = '';
        globalState.modeArgs = [];
      }, () => {
        this.mouseRelease();
        if (globalState.modeTemp) {
          globalState.mode = globalState.modeTemp;
        }
        if (globalState.operationTemp) {
          globalState.operation = globalState.operationTemp;
        }
        globalState.modeTemp = '';
        globalState.operationTemp = '';
        globalState.modeArgs = [];
      });
    }
  }
  mouseTap(pos, ray, adsorb) {
    console.log('drawing tap');
    let drawing = currData.drawing;
    if (drawing) {
      switch (globalState.modeArgs[0]) {
        case 'calibrateCenter':
          return this.calibrateCenter(drawing, pos, globalState.modeArgs[1]);
        case 'calibrateSize':
          let p0 = currData.calibrateStartPoint;
          if (p0 && adsorb.event.shiftKey) {
            let pos1 = pos.clone();
            if (Math.abs(pos.y - p0.y) > Math.abs(pos.x - p0.x)) {
              pos1.x = p0.x;
            } else {
              pos1.y = p0.y;
            }
            pos = pos1;
          }
          return this.calibrateSize(drawing, pos, globalState.modeArgs[1]);
        case 'calibrateRotation':
          return;
      }
      if (drawing.type === 'dxfDrawing') {
        let picked = dxfDrawingPick(drawing, pos, adsorb);
        if (picked) {
          console.log(picked);
          globalState.dxfSelected.blocks = [picked];
          globalState.dxfSelected.index = -1;
          currData.setCurrList(drawing);
        }
      }
    }
  }
  mouseMove(pos, ray, adsorb) {
    let drawing = currData.drawing;
    switch (globalState.modeArgs[0]) {
      case 'calibrateCenter':
        this.tools.calibrateCenterMouseMove(pos);
        break;
      case 'calibrateSize':
        let p0 = currData.calibrateStartPoint;
        if (p0 && adsorb.event.shiftKey) {
          let pos1 = pos.clone();
          if (Math.abs(pos.y - p0.y) > Math.abs(pos.x - p0.x)) {
            pos1.x = p0.x;
          } else {
            pos1.y = p0.y;
          }
          pos = pos1;
        }
        this.tools.calibrateSizeMouseMove(p0 || pos, pos);
        break;
      case 'calibrateRotation':
        break;
    }
  }
  createPoint(pos, adsorb) {}
  keyBackspace() {}
  updatePoint(pos, adsorb) {}
  pickPoint(pos, ray, picker) {}
  pickXYPoints(pos, ray, picker) {}
}
export { DrawingCreator };