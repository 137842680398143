import { smDatas } from "@/model/smCollectionData";
import { currData } from "@/model/globalData";
import numInput from '../../../propWidget/numInput';
import { floorListOptions, getItemProperty, updateItemProperty } from "@/components/mainContent/rightPanel/property/index";
import extendInput from '@/components/propWidget/extendInput';
export default {
  name: "drawing",
  methods: {},
  components: {
    numInput,
    extendInput
  },
  setup() {
    let doChange = function (event, name, k) {
      currData.drawing[name] = Math.round(parseFloat(event)) * k;
      currData.drawing.makeDirty();
      currData.drawing.setNeedSave();
    };
    let doSelectChange = function (event, name, val) {
      currData.drawing[name] = val;
      currData.drawing.makeDirty();
      currData.drawing.setNeedSave();
    };
    let modeOptions = [{
      value: 'add',
      label: Kf.t('Normal')
    }, {
      value: 'multiply',
      label: Kf.t('Mulitply')
    }];
    return {
      smDatas,
      currData,
      doChange,
      doSelectChange,
      modeOptions,
      floorListOptions,
      getItemProperty,
      updateItemProperty
    };
  }
};