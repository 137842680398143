import KingfisherService from '../utils/request';
class SpaceService {
  /**
   * Creates an instance of ProjectService.
   * @memberOf ProjectService
   */
  constructor() {
    this.module = 'space';
    this.kingfisherService = new KingfisherService({});
  }
  getUrl(url) {
    return this.kingfisherService.getURL(url);
  }

  /**
   * 获取项目版本列表
   * @returns {jQuery|Deferred} Ajax请求返回并处理后的Promise
   */
  getSpaceVersion(projectId) {
    return this.kingfisherService.get(`/repo/kf-space/get-space-version/${projectId}`).then(response => {
      return response;
    });
  }

  /**
   * 查询建筑列表
   * @param projectId
   * @returns {PromiseLike<T> | Promise<T> | *}
   */
  getBuildings(projectId, category) {
    if (projectId != undefined) {
      let url = `/repo/kf-space/find-building/${projectId}`;
      if (category != null) {
        url += `?category=${category}`;
      }
      return this.kingfisherService.post(url).then(response => {
        return response;
      });
    }
  }

  /**
   * 删除建筑列表
   * @param id
   * @returns {PromiseLike<T> | Promise<T> | *}
   */
  deleteSpace(id) {
    return this.kingfisherService.delete(`/repo/kf-space/del-space/${id}`).then(response => response);
  }
  updateSpace(params) {
    return this.kingfisherService.post("/repo/kf-space/update-building", JSON.stringify(params)).then(response => response);
  }

  /**
   * 查询楼层列表
   * @param projectId
   * @param buildingId
   * @returns {PromiseLike<T> | Promise<T> | *}
   */
  getFloors(projectId, buildingId) {
    return this.kingfisherService.post(`/repo/kf-space/find-space-geo`, {
      projectId: projectId,
      buildingId: buildingId,
      type: 'floor'
    }).then(response => {
      return response;
    });
  }

  /**
   * 查询部件类型列表
   * @returns {PromiseLike<T> | Promise<T> | *}
   */
  getPartTypes() {
    return this.kingfisherService.get(`/repo/kf-space/find-types`).then(response => {
      return response;
    });
  }

  /**
   * 查询所有-分类空间治理组件库
   * @returns {Promise} Ajax请求返回并处理后的Promise
   */
  getSpacePart(projectId) {
    let url = '/repo/kf-space/get-space-part';
    if (projectId) {
      url = '/repo/kf-space/get-space-part?projectId=' + projectId;
    }
    return this.kingfisherService.get(url).then(response => {
      return response;
    });
  }

  /**
   * 保存至空间治理组件库
   * @returns {Promise} Ajax请求返回并处理后的Promise
   */
  saveSpaceParts(parts) {
    if (!(parts instanceof Array)) {
      parts = [parts];
    }
    return this.kingfisherService.post(`/repo/kf-space/save-space-parts`, parts).then(response => {
      return response;
    });
  }

  /**
   * 删除空间治理组件
   * @returns {Promise} Ajax请求返回并处理后的Promise
   */
  deleteSpacePart(partId) {
    return this.kingfisherService.delete(`/repo/kf-space/delete-space-part/` + partId).then(response => {
      return response;
    });
  }

  /**
   * 更新空间治理组件
   * @returns {Promise} Ajax请求返回并处理后的Promise
   */
  updateSpacePart(part) {
    return this.kingfisherService.post(`/repo/kf-space/update-space-part/`, part).then(response => {
      return response;
    });
  }

  /**
   * 查询场景
   * @returns {Promise<T>}
   */
  getSpace(params) {
    return this.kingfisherService.post(`/repo/kf-space/find-building/${params.projectId}/`, JSON.stringify(params)).then(response => {
      return response;
    });
  }

  /**
   * 查询所有-分类空间治理组件库
   * @returns {Promise} Ajax请求返回并处理后的Promise
   */
  findSpaceGeo(params) {
    let api = '/repo/kf-space/find-space-geo';
    if (params && params.version !== undefined) {
      api += '?version=' + params.version;
    }
    return this.kingfisherService.post(api, params).then(response => {
      return response;
    });
  }

  /**
   * 查询所有-区域属性状态配置
   * @returns {Promise} Ajax请求返回并处理后的Promise
   */
  findAreaPurpose(projectUuid) {
    return this.kingfisherService.get('/repo/kf-space/find-area-purpose/' + projectUuid).then(response => {
      return response;
    });
  }

  /**
   * 统计区域
   * @returns {Promise} Ajax请求返回并处理后的Promise
   */
  aggArea(projectUuid, buildingId, floorId, type) {
    let url = `/repo/kf-space/agg-area-type/${projectUuid}?`;
    if (buildingId) {
      url += 'buildingId=' + encodeURIComponent(buildingId) + "&";
    }
    if (floorId) {
      url += 'floorId=' + encodeURIComponent(floorId) + "&";
    }
    if (type) {
      url += 'type=' + encodeURIComponent(type) + "&";
    }
    return this.kingfisherService.get(url.substring(0, url.length - 1)).then(response => {
      return response;
    });
  }

  /**
   * 统计数量
   * @returns {Promise} Ajax请求返回并处理后的Promise
   */
  countByGroup(projectUuid, buildingId, floorId, type) {
    let url = `/repo/kf-space/group-by-type/${projectUuid}?`;
    if (buildingId) {
      url += 'buildingId=' + encodeURIComponent(buildingId) + "&";
    }
    if (floorId) {
      url += 'floorId=' + encodeURIComponent(floorId) + "&";
    }
    if (type) {
      url += 'type=' + encodeURIComponent(type) + "&";
    }
    return this.kingfisherService.get(url.substring(0, url.length - 1)).then(response => {
      return response;
    });
  }

  /**
   * 新建场景内资源
   * @returns {Promise<T>}
   */
  saveSpace(params) {
    return this.kingfisherService.post(`/repo/kf-space/create-building`, JSON.stringify(params)).then(response => {
      return response;
    });
  }

  /**
   * 查询主题列表
   * @param {*} projectId
   * @param {*} group
   * @returns
   */
  querySpaceThemeList(params) {
    return this.kingfisherService.get(`/repo/kf-tron-theme/findTronTheme?projectId=${params.projectId}&groups=${params.group || ''}&type=space`).then(response => {
      return response;
    });
  }

  /**
   * 查询主题文件夹下的 空间组件列表
   * @param {*} projectId
   * @param {*} themeId
   * @returns
   */
  querySpaceListByTheme(params) {
    return this.kingfisherService.get(`/repo/kf-space/get-space-part-theme?projectId=${params.projectId}&tronThemeId=${params.themeId}`).then(response => {
      return response;
    });
  }

  /**
   * 工创5.0 导入空间场景
   * param file
   * @returns {jQuery|Deferred} Ajax请求返回并处理后的Promise
   */
  importSpace(param) {
    return this.kingfisherService.post(`/repo/kf-space/import-space-building`, param, {
      'content-type': 'multipart/form-data'
    }).then(res => {
      return res;
    });
  }

  /**
   * 导出空间场景
   * @returns {Promise<T>}
   */
  exportScene(fromProId, uuids) {
    return this.kingfisherService.get(`/repo/kf-space/export-space-building?fromProId=${fromProId}&uuids=${uuids}`, null, undefined, {
      'responseType': 'blob'
    }).then(response => {
      return response;
    }).catch(error => {
      return error && error.response ? error.response : error;
    });
  }

  /**
   * 导入组件
   * param file
   * @returns {jQuery|Deferred} Ajax请求返回并处理后的Promise
   */
  importWidget(param, projectUuid) {
    return this.kingfisherService.post(`/repo/kf-part/import-scene-parts?projectUuid=${projectUuid}`, param, {
      'content-type': 'multipart/form-data'
    }).then(res => {
      return res;
    }).catch(error => {
      return error && error.response ? error.response : error;
    });
  }
}
export default new SpaceService();