let Vector2 = window.Kingfisher.Vector2;
let Vector3 = window.Kingfisher.Vector3;
import { Matrix3 } from "@/maths/matrix3";
const matrix2DIsIdentity = function (m) {
  return m[0] === 1 && m[1] === 0 && m[2] === 0 && m[3] === 1 && m[4] === 0 && m[5] === 0;
};
const matrix2DInvert = function (m) {
  let l0 = m[0];
  let l1 = m[1];
  let l2 = m[2];
  let l3 = m[3];
  let l4 = m[4];
  let l5 = m[5];
  let det = m[0] * m[3] - m[1] * m[2];
  if (det < 0.00001) {
    return [0, 0, 0, 0, 0, 0];
  }
  let detDiv = 1 / det;
  let det4 = l2 * l5 - l3 * l4;
  let det5 = l1 * l4 - l0 * l5;
  let res = [];
  res[0] = l3 * detDiv;
  res[1] = -l1 * detDiv;
  res[2] = -l2 * detDiv;
  res[3] = l0 * detDiv;
  res[4] = det4 * detDiv;
  res[5] = det5 * detDiv;
  return res;
};
const transform2DPoint = function (p, m) {
  let x = p.x * m[0] + p.y * m[2] + m[4];
  let y = p.x * m[1] + p.y * m[3] + m[5];
  p.x = Math.round(x);
  p.y = Math.round(y);
};
const transform2DPointXZ = function (p, m) {
  let x = p.x * m[0] + p.z * m[2] + m[4];
  let y = p.x * m[1] + p.z * m[3] + m[5];
  p.x = Math.round(x);
  p.z = Math.round(y);
};
const transform2DVector = function (p, m) {
  let x = p.x * m[0] + p.y * m[2];
  let y = p.x * m[1] + p.y * m[3];
  p.x = Math.round(x);
  p.y = Math.round(y);
};
const verticalLine2DPoint = function (a, b, c) {
  let ab_x = b.x - a.x;
  let ab_y = b.y - a.y;
  let ac_x = c.x - a.x;
  let ac_y = c.y - a.y;
  let f = ab_x * ac_x + ab_y * ac_y;
  if (f < 0) {
    return;
  }
  let d = ab_x * ab_x + ab_y * ab_y;
  if (f > d) {
    return;
  }
  f /= d;
  let d_x = a.x + f * ab_x;
  let d_y = a.y + f * ab_y;
  return {
    x: d_x,
    y: d_y
  };
};
const verticalLine3DPoint = function (a, b, c) {
  let ab_x = b.x - a.x;
  let ab_y = b.z - a.z;
  let ac_x = c.x - a.x;
  let ac_y = c.y - a.z;
  let f = ab_x * ac_x + ab_y * ac_y;
  if (f < 0) {
    return;
  }
  let d = ab_x * ab_x + ab_y * ab_y;
  if (f > d) {
    return;
  }
  f /= d;
  let d_x = a.x + f * ab_x;
  let d_y = a.z + f * ab_y;
  return {
    x: d_x,
    y: d_y
  };
};
const lineIntersect = function (p0, p1, p2, p3) {
  let A1 = p1.y - p0.y,
    B1 = p0.x - p1.x,
    C1 = A1 * p0.x + B1 * p0.y,
    A2 = p3.y - p2.y,
    B2 = p2.x - p3.x,
    C2 = A2 * p2.x + B2 * p2.y,
    denominator = A1 * B2 - A2 * B1;
  let t = Math.abs((A1 * B2 - A2 * B1) / (A1 * A2 + B1 * B2));
  //let d = (Math.atan2(B1, A1) - Math.atan2(B2, A2) + Math.PI * 4) % Math.PI;
  if (Math.abs(t) < 0.01) {
    return null;
  }
  return new Vector2((B2 * C1 - B1 * C2) / denominator, (A1 * C2 - A2 * C1) / denominator);
};
const verticalArc2DPoint = function (center, radius, angle, startAngle, pos) {
  let x = pos.x - center.x;
  let y = pos.y - center.y;
  let PI2 = Math.PI * 2;
  if (angle < 0) {
    startAngle = (startAngle + Math.PI + angle + PI2) % PI2;
    angle = -angle;
  }
  let angle1 = (Math.atan2(y, x) + PI2) % PI2;
  if (startAngle < angle1) {
    if (startAngle + angle < angle1) {
      return null;
    }
  } else {
    if (startAngle + angle < angle1 + PI2) {
      return null;
    }
  }
  return new Vector2(center.x + Math.cos(angle1) * radius, center.y + Math.sin(angle1) * radius);
};
const distanceSquaredPointToSegment = function (point, segPointA, segPointB) {
  let clamp = function (a, x, y) {
    return a <= x ? x : a >= y ? y : a;
  };
  let pa = point.subtract(segPointA);
  let ba = segPointB.subtract(segPointA);
  // h表示pa在ba上投影的长度占ba长度的比例，限定到[0,1]
  let h = clamp(Vector2.Dot(pa, ba) / Vector2.Dot(ba, ba), 0.0, 1.0);
  // ba*h 是以a为起点，方向与ba相同，长度等于pa在ba方向上投影的长度的向量
  // pa视为斜边，ba*h是直角边，那么pa - ba*h则是另一条直角边，也就是从p点做垂线垂直于ab，显然该垂线的长度就是所求最短距离
  return pa.subtract(ba.multiplyByFloats(h, h)).lengthSquared();
};
const intersectCheckLineLine = function (p1x, p1y, p2x, p2y, p3x, p3y, p4x, p4y) {
  let s1_x = p2x - p1x;
  let s1_y = p2y - p1y;
  let s2_x = p4x - p3x;
  let s2_y = p4y - p3y;
  let s = (-s1_y * (p1x - p3x) + s1_x * (p1y - p3y)) / (-s2_x * s1_y + s1_x * s2_y);
  let t = (s2_x * (p1y - p3y) - s2_y * (p1x - p3x)) / (-s2_x * s1_y + s1_x * s2_y);
  return s >= 0 && s <= 1 && t >= 0 && t <= 1;
};

// 求点与矩形是否相交
const intersectCheckPointAndBox = function (point, minX, minY, maxX, maxY) {
  let x = point.x;
  let y = point.z === undefined ? point.y : point.z;
  return x >= minX && x <= maxX && y >= minY && y <= maxY;
};

// 求线段与矩形是否相交
const intersectCheckSegmentAndBox = function (segPointA, segPointB, minX, minY, maxX, maxY) {
  let x1 = segPointA.x;
  let y1 = segPointA.z === undefined ? segPointA.y : segPointA.z;
  let x2 = segPointB.x;
  let y2 = segPointB.z === undefined ? segPointB.y : segPointB.z;
  if (x1 < minX && x2 < minX || x1 > maxX && x2 > maxX || y1 < minY && y2 < minY || y1 > maxY && y2 > maxY) {
    return false;
  }
  if (x2 >= minX && x2 <= maxX && y2 >= minY && y2 <= maxY) {
    return true;
  }
  if (x1 >= minX && x1 <= maxX && y1 >= minY && y1 <= maxY) {
    return true;
  }
  if (x2 >= minX && x2 <= maxX && y2 >= minY && y2 <= maxY) {
    return true;
  }
  return intersectCheckLineLine(x1, y1, x2, y2, minX, minY, maxX, maxY) || intersectCheckLineLine(x1, y1, x2, y2, minX, maxY, maxX, minY);
};

// 求点与多边形是否相交
const intersectCheckPointAndPolygon = function (point, points) {
  let x = point.x;
  let y = point.y;
  let length = points.length;
  let inside = false;
  for (let i = 0, j = length - 1; i < length; j = i++) {
    let xi = points[i].x;
    let yi = points[i].y;
    let xj = points[j].x;
    let yj = points[j].y;
    let intersect = yi > y !== yj > y && x < (xj - xi) * (y - yi) / (yj - yi) + xi;
    if (intersect) {
      inside = !inside;
    }
  }
  return inside;
};
const pointInTriangle = function (p, pa, pb, pc) {
  let x0 = p.x;
  let y0 = p.y;
  let x1 = pa.x;
  let y1 = pa.y;
  let x2 = pb.x;
  let y2 = pb.y;
  let x3 = pc.x;
  let y3 = pc.y;
  let divisor = (y2 - y3) * (x1 - x3) + (x3 - x2) * (y1 - y3);
  let a = ((y2 - y3) * (x0 - x3) + (x3 - x2) * (y0 - y3)) / divisor;
  let b = ((y3 - y1) * (x0 - x3) + (x1 - x3) * (y0 - y3)) / divisor;
  let c = 1 - a - b;
  return a >= 0 && a <= 1 && b >= 0 && b <= 1 && c >= 0 && c <= 1;
};

//Green公式 判断多边形是顺时针还是逆时针
const isReversal = function (points) {
  let sum = 0;
  let length = points.length;
  for (let i = 0; i < length; i++) {
    let p0 = points[i];
    let p1 = points[(i + 1) % length];
    sum += (p1.x - p0.x) * (p1.y + p0.y);
  }
  return sum > 0;
};
Vector2.prototype.applyMatrix3 = function (m) {
  const x = this.x,
    y = this.y;
  const e = m.elements;
  this.x = e[0] * x + e[3] * y + e[6];
  this.y = e[1] * x + e[4] * y + e[7];
  return this;
};
Vector2.prototype.applyMatrix2x3 = function (m) {
  const x = this.x,
    y = this.y;
  const e = m.elements;
  this.x = e[0] * x + e[2] * y + e[4];
  this.y = e[1] * x + e[3] * y + e[5];
  return this;
};

/**
 * @param {Vector2} position
 * @param {Number} rotation
 * @param {Vector2} scale
 * */
const composeMatrix2x3 = function (position, rotation, scale) {
  let res = [];
  let cos = Math.cos(rotation);
  let sin = Math.sin(rotation);
  res[0] = scale.x * cos;
  res[1] = scale.x * sin;
  res[2] = -scale.y * sin;
  res[3] = scale.y * cos;
  res[4] = position.x;
  res[5] = position.y;
  return res;
};
const decomposeMatrix2x3 = function (m) {
  let a = m[0];
  let b = m[1];
  let c = m[2];
  let d = m[3];
  let sx = Math.sqrt(a * a + b * b);
  let sy = Math.sqrt(c * c + d * d);
  if (m[0] * m[3] - m[1] * m[2] < 0) {
    sx = -sx;
    a = -a;
    b = -b;
  }
  let rotation = Math.atan2(b, a);
  return {
    translation: new Vector2(m[4], m[5]),
    rotation: rotation,
    scale: new Vector2(sx, sy)
  };
};
const creatMatrix2x3 = function (translation, rotation, scale) {
  let res = [];
  let cos = Math.cos(rotation);
  let sin = Math.sin(rotation);
  res[0] = scale.x * cos;
  res[1] = scale.x * sin;
  res[2] = -scale.z * sin;
  res[3] = scale.z * cos;
  res[4] = translation.x;
  res[5] = translation.z;
  return res;
};
const multiplyMatrix2x3 = function (m1, m2) {
  let res = [];
  res[0] = m1[0] * m2[0] + m1[2] * m2[1];
  res[1] = m1[1] * m2[0] + m1[3] * m2[1];
  res[2] = m1[0] * m2[2] + m1[2] * m2[3];
  res[3] = m1[1] * m2[2] + m1[3] * m2[3];
  res[4] = m1[0] * m2[4] + m1[2] * m2[5] + m1[4];
  res[5] = m1[1] * m2[4] + m1[3] * m2[5] + m1[5];
  return res;
};
class Box2 {
  expandByPoint(p) {
    this.min.x = Math.min(p.x, this.min.x);
    this.min.y = Math.min(p.y, this.min.y);
    this.max.x = Math.max(p.x, this.max.x);
    this.max.y = Math.max(p.y, this.max.y);
  }
  constructor() {
    this.min = new Vector2(Number.MAX_VALUE, Number.MAX_VALUE);
    this.max = new Vector2(-Number.MAX_VALUE, -Number.MAX_VALUE);
  }
}
export { Vector2, Vector3, Matrix3, Box2, matrix2DIsIdentity, matrix2DInvert, transform2DPoint, transform2DPointXZ, transform2DVector, verticalLine2DPoint, verticalLine3DPoint, lineIntersect, verticalArc2DPoint, distanceSquaredPointToSegment, intersectCheckLineLine, intersectCheckPointAndBox, intersectCheckSegmentAndBox, intersectCheckPointAndPolygon, pointInTriangle, isReversal, decomposeMatrix2x3, creatMatrix2x3, multiplyMatrix2x3, composeMatrix2x3 };