import { ref } from "vue";
let callbacks = {};
let tempCallbackParams = {};
export const registerDialogCallback = function (name, callback) {
  callbacks[name] = callback;
};
export const onchange = function (name, flag = true) {
  if (callbacks[name]) {
    let args = [...arguments].slice(2);
    callbacks[name](args, tempCallbackParams[name]);
    if (flag) {
      delete tempCallbackParams[name];
    }
  }
};
export const showKFDialog = function (name) {
  if (name === 'calibrateCenter') {
    showCalibrateCenter.value = true;
  }
  tempCallbackParams[name] = arguments;
};
export const showCalibrateCenter = ref(false);
export const calibrateCenterX = ref(0);
export const calibrateCenterZ = ref(0);