import { currData, globalState } from "@/model/globalData";
import { watch, ref, onMounted, onUnmounted, computed } from "vue";
import vertexCoordinatesProp from "@/components/propWidget/vertexCoordinatesProp";
import { areaSubTypeOptions, areaOptionsData } from "@/model/purposeOptions";
import { floorListOptions, getItemProperty, isMultiple, updateItemProperty } from "@/components/mainContent/rightPanel/property/index";
import extendInput from '@/components/propWidget/extendInput';
export default {
  name: "smArea",
  methods: {},
  components: {
    vertexCoordinatesProp,
    extendInput
  },
  setup() {
    /** 用拼音主要是area被占用了 **/
    const mianJi = computed(() => {
      if (currData.area) {
        return [((currData.area.area || 0) / 1000000).toFixed(2) + '㎡'];
      }
    });
    const setArea = function (data, path) {
      let _mianJi = parseFloat(data[0].replace("㎡", ''));
      if (isNaN(_mianJi)) {
        return;
      }
      updateItemProperty([_mianJi * 1000000], path);
    };
    return {
      isMultiple,
      getItemProperty,
      updateItemProperty,
      currData,
      areaSubTypeOptions,
      areaOptionsData,
      floorListOptions,
      setArea,
      mianJi
    };
  }
};