import "core-js/modules/es.array.push.js";
import "core-js/modules/es.string.replace-all.js";
import { Vector2, Matrix3 } from "../maths/vector";
import { ParseSpecialChars } from "./TextRenderer";

/**
 * @property {{color: ?number, start: Vector2, end: Vector2}[]} lines
 * @property {{color: ?number, vertices: Vector2[]}[], indices: number[]} triangles On or more
 *  triangles in each item.
 * @property {{text: string, size: number, angle: number, color: number, position: Vector2}[]} texts
 *   Each item position is specified as middle point of the rendered text.
 */
export class DimensionLayout {
  constructor() {
    this.lines = [];
    this.triangles = [];
    this.texts = [];
  }
  AddLine(start, end, color = null) {
    this.lines.push({
      start,
      end,
      color
    });
  }

  /** Add one or more triangles. */
  AddTriangles(vertices, indices, color = null) {
    this.triangles.push({
      vertices,
      indices,
      color
    });
  }
  AddText(text, size, angle, color, position) {
    this.texts.push({
      text,
      size,
      angle,
      color,
      position
    });
  }
}
const arrowHeadShape = {
  vertices: [new Vector2(0, 0), new Vector2(1, -0.25), new Vector2(1, 0.25)],
  indices: [0, 1, 2]
};

/** Encapsulates all calculations about linear dimensions layout. */
export class LinearDimension {
  /**
   * @typedef LinearDimensionParams
   * @property {Vector2} p1 First definition point.
   * @property {Vector2} p2 Second definition point.
   * @property {Vector2} anchor Anchor point defines dimension line location.
   * @property {?number} angle Rotation angle for rotated dimension, deg.
   * @property {boolean} isAligned Dimension line is parallel to base line for aligned dimension.
   * @property {?string} text Dimension text pattern.
   * @property {?Vector2} textAnchor Text location (middle point) override.
   * @property {?number} textRotation Rotation angle of the dimension text away from its default
   *  orientation (the direction of the dimension line)
   */

  /**
   * @param {LinearDimensionParams} params
   * @param {Function<any(string)>} styleResolver Provides value for a requested style parameter.
   * @param {Function<number(string, number)>} textWidthCalculator Get text width in model space
   *  units for a given text and font size (height).
   */
  constructor(params, styleResolver, textWidthCalculator) {
    this.params = params;
    this.styleResolver = styleResolver;
    this.textWidthCalculator = textWidthCalculator;
    /* Can be set to indicate some invalid geometric solution.  */
    this.isValid = true;
    this._CalculateGeometry();
  }
  IsValid() {
    return this.isValid;
  }
  GetTexts() {
    return [this._GetText()];
  }

  /**
   * @return {DimensionLayout}
   */
  GenerateLayout() {
    var _this$styleResolver, _this$styleResolver2, _this$styleResolver3, _this$styleResolver4, _this$styleResolver5, _this$params$textRota, _this$styleResolver6, _this$styleResolver7, _this$styleResolver8, _this$styleResolver9;
    /* See https://ezdxf.readthedocs.io/en/stable/tables/dimstyle_table_entry.html */
    const result = new DimensionLayout();

    /* Dimension line(s). */
    const dimSize = this.d1.distance(this.d2);
    const dimColor = this.styleResolver("DIMCLRD");
    let dimScale = (_this$styleResolver = this.styleResolver("DIMSCALE")) !== null && _this$styleResolver !== void 0 ? _this$styleResolver : 1;
    if (dimScale == 0) {
      /* No any auto calculation implemented, since no support for paper space. */
      dimScale = 1;
    }
    const text = this._GetText();
    const fontSize = ((_this$styleResolver2 = this.styleResolver("DIMTXT")) !== null && _this$styleResolver2 !== void 0 ? _this$styleResolver2 : 1) * dimScale;
    const textWidth = this.textWidthCalculator(text, fontSize);
    const textColor = this.styleResolver("DIMCLRT");
    const arrowSize = ((_this$styleResolver3 = this.styleResolver("DIMASZ")) !== null && _this$styleResolver3 !== void 0 ? _this$styleResolver3 : 1) * dimScale;
    const tickSize = ((_this$styleResolver4 = this.styleResolver("DIMTSZ")) !== null && _this$styleResolver4 !== void 0 ? _this$styleResolver4 : 0) * dimScale;
    let textAnchor = this.params.textAnchor;
    let flipArrows = false;
    const start = this.d1;
    const dimExt = ((_this$styleResolver5 = this.styleResolver("DIMDLE")) !== null && _this$styleResolver5 !== void 0 ? _this$styleResolver5 : 0) * dimScale;
    if (dimExt != 0) {
      start.add(this.vDim.scale(-dimExt));
    }
    const end = this.d2;
    if (dimExt != 0) {
      end.add(this.vDim.scale(dimExt));
    }
    result.AddLine(start, end, dimColor);
    if (dimSize < arrowSize * 2) {
      flipArrows = true;
    }
    if (!textAnchor) {
      //XXX for now just always draw the text above dimension line with fixed gap
      textAnchor = this.vDim.scale(this.d1.distance(this.d2) / 2).add(this.d1).add(this.vDimNorm.scale(fontSize * 0.75));
    }

    // const angle = this.vDimNorm.angle() * 180 / Math.PI - 90 +
    //     (this.params.textRotation ?? 0)
    let angle = Math.atan2(-this.vDimNorm.y, -this.vDimNorm.x) + Math.PI;
    angle = angle * 180 / Math.PI - 90 + ((_this$params$textRota = this.params.textRotation) !== null && _this$params$textRota !== void 0 ? _this$params$textRota : 0);
    result.AddText(text, fontSize, angle, textColor, textAnchor);

    /* Extension lines. */
    const extColor = this.styleResolver("DIMCLRE");
    const extOffset = ((_this$styleResolver6 = this.styleResolver("DIMEXO")) !== null && _this$styleResolver6 !== void 0 ? _this$styleResolver6 : 0) * dimScale;
    const extExt = ((_this$styleResolver7 = this.styleResolver("DIMEXE")) !== null && _this$styleResolver7 !== void 0 ? _this$styleResolver7 : 0) * dimScale;
    const DrawExtLine = (basePt, dimPt) => {
      const vExt = dimPt.subtract(basePt);
      const dist = vExt.lengthSquared();
      if (dist == 0) {
        return;
      }
      vExt.normalize();
      const start = basePt;
      if (extOffset != 0) {
        start.add(vExt.scale(extOffset));
      }
      const end = dimPt;
      if (extExt != 0) {
        end.add(vExt.scale(extExt));
      }
      result.AddLine(start, end, extColor);
    };
    if (!((_this$styleResolver8 = this.styleResolver("DIMSE1")) !== null && _this$styleResolver8 !== void 0 ? _this$styleResolver8 : 0)) {
      DrawExtLine(this.params.p1, this.d1);
    }
    if (!((_this$styleResolver9 = this.styleResolver("DIMSE2")) !== null && _this$styleResolver9 !== void 0 ? _this$styleResolver9 : 0)) {
      DrawExtLine(this.params.p2, this.d2);
    }

    /* Draw arrows (or anything defined as dimension shape). Assuming shape is defined
     * horizontally for left side with the origin in the dimension point, scale corresponding to
     * size 1. Calculate appropriate transform for the shape.
     */
    //XXX check suppression by DIMSOXD, DIMSD1, DIMSD2
    for (let i = 0; i < 2; i++) {
      const dimPt = i == 0 ? this.d1 : this.d2;
      let flip = i == 1;
      if (flipArrows) {
        flip = !flip;
      }
      let transform = new Matrix3().identity();
      if (tickSize > 0) {
        transform.scale(tickSize, tickSize);
      } else {
        transform.scale(arrowSize, arrowSize);
        /* Tick is not flipped. */
        if (flip) {
          transform.scale(-1, 1);
        }
      }

      //const angle = -this.vDim.angle()
      const angle = Math.atan2(-this.vDim.y, -this.vDim.x) + Math.PI;
      transform.rotate(angle);
      transform.translate(dimPt.x, dimPt.y);
      if (tickSize > 0) {
        this._CreateTick(result, transform, dimColor);
      } else {
        this._CreateArrowShape(result, transform, dimColor);
      }
    }
    return result;
  }
  _CreateArrowShape(layout, transform, color) {
    const vertices = [];
    for (const v of arrowHeadShape.vertices) {
      vertices.push(v.clone().applyMatrix3(transform));
    }
    layout.AddTriangles(vertices, arrowHeadShape.indices, color);
  }
  _CreateTick(layout, transform, color) {
    layout.AddLine(new Vector2(0.5, 0.5).applyMatrix3(transform), new Vector2(-0.5, -0.5).applyMatrix3(transform), color);
  }

  /** Calculate and set basic geometric parameters (some points and vectors which define the
   * dimension layout).
   */
  _CalculateGeometry() {
    /* Base vector. */
    this.vBase = this.params.p2.subtract(this.params.p1).normalize();

    /* Dimension vector. */
    if (this.params.isAligned) {
      this.vDim = this.vBase;
    } else {
      var _this$params$angle;
      /* Angle is defined as angle between X axis and dimension line (CCW is positive). */
      const angle = ((_this$params$angle = this.params.angle) !== null && _this$params$angle !== void 0 ? _this$params$angle : 0) * Math.PI / 180;
      this.vDim = new Vector2(Math.cos(angle), Math.sin(angle));
    }

    /* Dimension points. Calculate them by projecting base points to dimension line. */
    this.d1 = this.vDim.scale( /* Projected signed length. */
    this.params.p1.subtract(this.params.anchor).dot(this.vDim)).add(this.params.anchor);
    this.d2 = this.vDim.scale( /* Projected signed length. */
    this.params.p2.subtract(this.params.anchor).dot(this.vDim)).add(this.params.anchor);
    if (this.d1.distanceSquared(this.d2) == 0) {
      this.isValid = false;
    }

    /* Ensure dimension vector still points from d1 to d2 after rotation. */
    this.vDim.copyFrom(this.d2).subtractInPlace(this.d1).normalize();

    /* Dimension normal vector is perpendicular to dimension line and is either above or on its
     * left side.
     * 90deg rotated vector is either [y; -x] or [-y; x]. Select most suitable from them
     * (y > x).
     */
    if (this.vDim.y < -this.vDim.x) {
      this.vDimNorm = new Vector2(this.vDim.y, -this.vDim.x);
    } else {
      this.vDimNorm = new Vector2(-this.vDim.y, this.vDim.x);
    }
  }
  _GetText() {
    var _this$params$text, _this$styleResolver10, _this$styleResolver11, _this$styleResolver12, _this$styleResolver13, _this$styleResolver14, _this$styleResolver15, _this$params$text2;
    if (this.params.text == " ") {
      /* Space indicates empty text. */
      return "";
    }
    if (((_this$params$text = this.params.text) !== null && _this$params$text !== void 0 ? _this$params$text : "") != "" && this.params.text.indexOf("<>") == -1) {
      /* No value placeholder, just return the text. */
      return ParseSpecialChars(this.params.text);
    }
    let measurement = this.d2.distance(this.d1);
    measurement *= (_this$styleResolver10 = this.styleResolver("DIMLFAC")) !== null && _this$styleResolver10 !== void 0 ? _this$styleResolver10 : 1;
    const rnd = (_this$styleResolver11 = this.styleResolver("DIMRND")) !== null && _this$styleResolver11 !== void 0 ? _this$styleResolver11 : 0;
    if (rnd > 0) {
      const n = Math.round(measurement / rnd);
      measurement = rnd * n;
    }
    const zeroSupp = (_this$styleResolver12 = this.styleResolver("DIMZIN")) !== null && _this$styleResolver12 !== void 0 ? _this$styleResolver12 : 0;
    const leadZeroSupp = (zeroSupp & 4) != 0;
    const trailingZeroSupp = (zeroSupp & 8) != 0;
    let measText = measurement.toFixed((_this$styleResolver13 = this.styleResolver("DIMDEC")) !== null && _this$styleResolver13 !== void 0 ? _this$styleResolver13 : 2);
    if (trailingZeroSupp) {
      measText = measText.replace(/.0+$/, "");
    }
    if (leadZeroSupp) {
      measText = measText.replace(/^0+/, "");
    }
    if (measText.startsWith(".")) {
      measText = "0" + measText;
    } else if (measText == "") {
      measText = "0";
    }
    if (measText.endsWith(".")) {
      measText = measText.substring(0, measText.length - 1);
    }
    let decSep = (_this$styleResolver14 = this.styleResolver("DIMDSEP")) !== null && _this$styleResolver14 !== void 0 ? _this$styleResolver14 : ".";
    if (!isNaN(decSep)) {
      decSep = String.fromCharCode(decSep);
    }
    if (decSep != ".") {
      measText = measText.replace(".", decSep);
    }
    const suffix = (_this$styleResolver15 = this.styleResolver("DIMPOST")) !== null && _this$styleResolver15 !== void 0 ? _this$styleResolver15 : "";
    if (suffix != "") {
      if (suffix.indexOf("<>") != -1) {
        measText = suffix.replaceAll("<>", measText);
      } else {
        measText += suffix;
      }
    }
    if (((_this$params$text2 = this.params.text) !== null && _this$params$text2 !== void 0 ? _this$params$text2 : "") != "") {
      measText = this.params.text.replaceAll("<>", measText);
    }
    return ParseSpecialChars(measText);
  }
}