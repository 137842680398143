<template>
  <div class="kf-panel-floors-box  kingfisher_scroll" v-if="currData.pipeline">
    <div class="kf-panel-floors-row">
      <div class="kf-panel-floors-row-title" >
        {{ $t('Name') }}
      </div>
      <div class="kf-panel-floors-row-content-2">
        <div class="kf-input-box">
        <extend-input
            v-if="getItemProperty('name')"
            :targets="getItemProperty('name')"
            inputType="text"
            @change="updateItemProperty($event,'name')"></extend-input>
        </div>
      </div>
    </div>
    <div class="kf-panel-floors-row">
      <div class="kf-panel-floors-row-title" >
        {{ $t('Floor') }}
      </div>
      <div class="kf-panel-floors-row-content-2 kf-select">
        <extend-input
            inputType="select"
            :placeholder="$t('FloorSelection')"
            :options="floorListOptions"
            :targets="getItemProperty('','floor')"
            @change="updateItemProperty($event,'','floor')">
        </extend-input>
      </div>
    </div>
    <div class="kf-panel-floors-row">
      <div class="kf-panel-floors-row-title" >
        {{ $t('BaseHeight') }}
      </div>
      <div class="kf-panel-floors-row-content-2">
        <div class="kf-input-box  pr num-input-container">
          <extend-input
              :targets="getItemProperty('elevation')"
              input-type='number'
              :step=100
              @change="updateItemProperty($event,'elevation')"
          ></extend-input>
          <span class="kf-input-unit">CM</span>
        </div>

      </div>
    </div>
    <div class="kf-panel-floors-row">
      <div class="kf-panel-floors-row-title" >
        {{ $t('Diameter') }}
      </div>
      <div class="kf-panel-floors-row-content-2">
        <div class="kf-input-box  pr num-input-container">
          <extend-input
              :targets="getItemProperty('diameter')"
              input-type='number'
              :step=100
              @change="updateItemProperty($event,'diameter')"
          ></extend-input>
          <span class="kf-input-unit">CM</span>
        </div>

      </div>
    </div>

    <div class="kf-panel-floors-row">
      <div class="kf-panel-floors-row-title" >
        {{ $t('Chamfer') }}
      </div>
      <div class="kf-panel-floors-row-content-2">
        <div class="kf-input-box  pr num-input-container">
         <extend-input
              :targets="getItemProperty('chamfer')"
              input-type='number'
              :step=100
              @change="updateItemProperty($event,'chamfer')"
          ></extend-input>
          <span class="kf-input-unit">CM</span>
        </div>
      </div>
    </div>
    <div class="kf-panel-floors-row">
      <div class="kf-panel-floors-row-title" >
        {{ $t('Properties') }}
      </div>
      <div class="kf-panel-floors-row-content-2 kf-select">
        <extend-input
            inputType="select"
            :placeholder="$t('Properties')"
            :options="pipelineOptions4UI"
            :targets="getItemProperty('purpose','pipeline-purpose')"
            @change="updateItemProperty($event,'purpose','pipeline-purpose')">
        </extend-input>
      </div>
    </div>
<!--    <div class="kf-panel-floors-row" v-if="!isMultiple">-->
<!--      <div class="kf-panel-floors-row-title" style="flex-basis: 100%">-->
<!--        {{ $t('WallSegment') }}-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="kf-panel-floors-row" style="height: auto" v-if="!isMultiple">-->
<!--      <segmentProp-->
<!--          :data="currData.pipeline"-->
<!--      ></segmentProp>-->
<!--    </div>-->
<!--    <div class="kf-panel-floors-row" v-if="!isMultiple">-->
<!--      <div class="kf-panel-floors-row-title" >-->
<!--        {{ $t('VertexCoordinates') }}-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="kf-panel-floors-row" style="height: auto;padding-bottom: 30px" v-if="!isMultiple">-->
<!--      <vertex-coordinates-prop-->
<!--          :data="currData.pipeline"-->
<!--      ></vertex-coordinates-prop>-->
<!--    </div>-->

  </div>

</template>

<script>
import {currData} from "@/model/globalData";
import vertexCoordinatesProp from "@/components/propWidget/vertexCoordinatesProp";
import segmentProp from "@/components/propWidget/segmentProp";
import numInput from "@/components/propWidget/numInput";
import {pipelineOptions} from "@/model/purposeOptions";
import extendInput from '@/components/propWidget/extendInput'
import {
  formatWallOptions4SelectUILable2Name,
  getItemProperty,
  isMultiple,
  updateItemProperty, floorListOptions
} from "@/components/mainContent/rightPanel/property/index";

export default {
  name: "pipeline",
  components: {
    vertexCoordinatesProp,
    numInput,
    segmentProp,
    extendInput
  },
  setup() {
    let pipelineOptions4UI = formatWallOptions4SelectUILable2Name(pipelineOptions);

    return {
      isMultiple,
      getItemProperty,
      updateItemProperty,
      currData,
      pipelineOptions4UI,
      floorListOptions
    }
  }

}
</script>

<style lang="scss">

</style>
