import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import { ref } from "vue";
import { ElMessage } from 'element-plus';
import { smDatas } from "@/model/smCollectionData";
import { dialogObject } from "@/components/dialog/dialogModel";
import { isObject } from "lodash";
import { currData, globalState } from "@/model/globalData";
import { compGroupList } from "@/model/3Dmodel";
import { creatMatrix2x3, decomposeMatrix2x3, multiplyMatrix2x3 } from "@/maths/vector";
export const batchBindInfo = ref({});

// export const updateDeviceGroups = function () {
//     let _deviceGroups = {};
//     for (let i = 0; i < smDatas.devicePoints.length; i++) {
//         let item = smDatas.devicePoints[i];
//         if (item.isDeleted) {
//             continue;
//         }
//         if (!_deviceGroups[item.deviceType || item.deviceTypeCode]) {
//             _deviceGroups[item.deviceType || item.deviceTypeCode] = {name: item.deviceType || item.deviceTypeCode || item.deviceName, data: [], isOpen: false};
//         }
//         _deviceGroups[item.deviceType || item.deviceTypeCode].data.push(item);
//     }
//
//     deviceGroups.value = _deviceGroups;
// }

export const initDevicePointBindingState = function () {
  let updateCount = 0;
  let errorCount = 0;
  for (let model of smDatas.models) {
    if (!model.deviceId) {
      continue;
    }
    let devicePoint = smDatas.devicePoints.find(x => x.deviceId === model.deviceId);
    if (devicePoint) {
      devicePoint.deviceModelList.add(model.nanoId);
      model.devicePointModelName = devicePoint.spaceComponentName;
    } else {
      if (model.customId) {
        let devicePoint = smDatas.devicePoints.find(x => x.deviceMapId === model.customId);
        if (devicePoint) {
          model.deviceId = devicePoint.deviceId;
          model.devicePointModelName = devicePoint.spaceComponentName;
          devicePoint.deviceModelList.add(model.nanoId);
          model.setNeedSave();
          updateCount++;
          continue;
        }
      }
      model.deviceLost = true;
      errorCount++;
    }
  }
  if (updateCount) {
    console.log('update binding device count:', updateCount);
    ElMessage({
      type: "info",
      message: `更新绑定：${updateCount}个设备！`
    });
  }
  if (errorCount) {
    console.log('binding failed device count:', errorCount);
    ElMessage({
      type: "error",
      message: `绑定错误：${errorCount}个设备！`
    });
  }
};
export const checkModelsBindStateAndBatchBind = function (models) {
  console.log('checkModelsBindStateAndBatchBind');
  let record = {
    haveBindModel: new Set(),
    haveNotCustomId: new Set(),
    haveBindDevicePoint: new Set(),
    successBindObjects: new Set(),
    unBindModel: new Set()
  };
  models = models ? models : currData.finalCurrList;
  models = Array.from(new Set(models));
  for (let model of models) {
    if (model.deviceId) {
      for (let devicePoint of smDatas.devicePoints) {
        if (devicePoint.deviceId === model.deviceId) {
          let needSave = false;
          if (devicePoint.deviceName && devicePoint.deviceName !== 'null') {
            if (model.name !== devicePoint.deviceName) {
              model.name = devicePoint.deviceName;
              needSave = true;
            }
          }
          if (needSave) {
            model.setNeedSave();
          }
          break;
        }
      }
      record.haveBindModel.add(model);
      continue;
    }
    if (!model.customId) {
      record.haveNotCustomId.add(model);
      continue;
    }
    let success = false;
    for (let devicePoint of smDatas.devicePoints) {
      if (devicePoint.deviceMapId === model.customId) {
        if (devicePoint.deviceModelList.size) {
          record.haveBindDevicePoint.add(devicePoint);
        }
        model.deviceId = devicePoint.deviceId;
        model.devicePointModelName = devicePoint.spaceComponentName;
        if (devicePoint.deviceName && devicePoint.deviceName !== 'null') {
          model.name = devicePoint.deviceName;
        }
        devicePoint.deviceModelList.add(model.nanoId);
        devicePoint.setNeedSave();
        model.setNeedSave();
        record.successBindObjects.add({
          model: model,
          devicePoint: devicePoint
        });
        success = true;
        break;
      }
    }
    if (!success) {
      record.unBindModel.add(model);
    }
  }
  batchBindInfo.value = record;
  dialogObject.value = {};
  dialogObject.value.path = 'BatchModelBindDevicePointInfo';
  dialogObject.value.ttile = Kf.t('BatchBindingDevicePointInformation');
};
export const autoCreateModelFromDrawing = function (blocks) {
  console.log('autoCreateModelFromDrawing');
  console.log(currData);
  if (currData.drawing.type !== 'dxfDrawing') {
    return;
  }
  let drawing = currData.drawing;
  let file = smDxfCache[drawing.fileId];
  for (let blocks of Object.values(file.blocks)) {
    let models = createModelFromDrawingBlocks(blocks);
    if (models && models.length > 0) {
      let fileMatrix = drawing.getMatrix();
      let originalBoundingInfo = models[0].properties.originalBoundingInfo;
      let max = originalBoundingInfo.max;
      let min = originalBoundingInfo.min;
      let size = originalBoundingInfo.size;
      let blockScale = Math.abs(blocks.entities[0].entity.xScale || 1);
      let drawingScale = drawing.scaling;
      let blockDrawingScale = blockScale * drawingScale;
      let blockBounds = blocks.bounds;
      blockBounds = {
        minX: blockBounds.minX * blockDrawingScale,
        maxX: blockBounds.maxX * blockDrawingScale,
        minY: blockBounds.minY * blockDrawingScale,
        maxY: blockBounds.maxY * blockDrawingScale
      }; //真实空间

      let worldCenter = {
        x: (blockBounds.minX + blockBounds.maxX) / 2,
        y: (blockBounds.minY + blockBounds.maxY) / 2
      }; //空间位置偏移
      let scaling = {
        x: blockBounds.maxX - blockBounds.minX,
        z: blockBounds.maxY - blockBounds.minY,
        y: size.y
      }; //空间大小
      let translation = {
        x: worldCenter.x - (min.x + max.x) / 2,
        z: worldCenter.y - (min.z + max.z) / 2,
        y: 0
      };
      let scale = {
        x: scaling.x / size.x / blockDrawingScale,
        z: scaling.z / size.z / blockDrawingScale
      };
      let localMatrix = creatMatrix2x3({
        x: translation.x / blockDrawingScale,
        z: translation.z / blockDrawingScale,
        y: translation.y
      }, Math.PI, scale);
      const _updateBlockModel = function (model, fileMatrix, localMatrix) {
        let transform = decomposeMatrix2x3(multiplyMatrix2x3(multiplyMatrix2x3(fileMatrix, model._blockTransforms), localMatrix));
        model.position.x = transform.translation.x;
        model.position.z = transform.translation.y;
        // model.position.y = translation.y;
        model.rotation.y = -transform.rotation;
        model.scaling.x = transform.scale.x;
        // model.scaling.y = transform.scale.x;
        model.scaling.z = transform.scale.y;
        model.makeDirty();
        model.updateBoundingBox();
      };
      for (let model of models) {
        _updateBlockModel(model, fileMatrix, localMatrix);
      }
    }
  }
};
export const checkBlocksDeviceMapState = function (blocks) {
  let record = {
    canBeBindList: new Set(),
    notCanBeBindList: new Set()
  };
  let drawing = currData.drawing;
  let layerVisibleMap = new Map();
  for (let layer of drawing.layers) {
    layerVisibleMap.set(layer.layerName, layer.visible);
  }
  let blocksCodeList = [];
  for (let i = 0; i < blocks.entities.length; i++) {
    let block = blocks.entities[i];
    blocksCodeList[i] = {
      block,
      code: []
    };
    let texts = block._texts.filter(text => {
      if (!layerVisibleMap.get(text.text.layer)) {
        return false;
      }
      if (text.d2 && !text.text._blocks[0].block.entities.includes(block)) {
        return false;
      }
      return true;
    });
    if (texts.length) {
      if (isObject(texts[0].text.textEntities)) {
        for (let entity of texts[0].text.textEntities.entities) {
          let content = entity.content;
          if (content) {
            if (typeof content === 'string') {
              blocksCodeList[i].code.push(content);
            } else {
              for (let _content of content) {
                if (_content.type === 0) {
                  blocksCodeList[i].code.push(_content.content);
                }
              }
            }
          }
        }
      } else {
        blocksCodeList[i].code = [texts[0].text.textEntities];
      }
      for (let blockCode of blocksCodeList) {
        for (let devicePoint of smDatas.devicePoints) {
          record.notCanBeBindList.add(blockCode);
          if (blockCode.code.includes(devicePoint.deviceMapId)) {
            record.canBeBindList.add(blockCode);
            record.notCanBeBindList.delete(blockCode);
            break;
          }
        }
      }
    }
  }
  return record;
};
export const createModelFromDrawingBlocks = function (blocks) {
  let models = [];
  let devicePointMap = new Map();
  let modelCount = new Map();
  let textDevicePointMap = new Map();
  let drawing = currData.drawing;
  let layerVisibleMap = new Map();
  for (let layer of drawing.layers) {
    layerVisibleMap.set(layer.layerName, layer.visible);
  }
  for (let i = 0; i < blocks.entities.length; i++) {
    let block = blocks.entities[i];
    let texts = block._texts.filter(text => {
      if (!layerVisibleMap.get(text.text.layer)) {
        return false;
      }
      if (text.d2 && !text.text._blocks[0].block.entities.includes(block)) {
        return false;
      }
      return true;
    });
    for (let devicePoint of smDatas.devicePoints) {
      let drawingTexts = [];
      for (let text of texts) {
        if (isObject(text.text.textEntities)) {
          for (let entity of text.text.textEntities.entities) {
            let content = entity.content;
            if (content) {
              if (typeof content === 'string') {
                drawingTexts.push(content);
              } else {
                for (let _content of content) {
                  if (_content.type === 0) {
                    drawingTexts.push(_content.content);
                  }
                }
              }
            }
          }
        } else {
          drawingTexts = [text.text.textEntities];
        }
        let textIndex = drawingTexts.indexOf(devicePoint.deviceMapId);
        if (textIndex < 0) {
          continue;
        }
        let value = Math.pow(0.8, textIndex);
        modelCount.set(devicePoint.deviceType, modelCount.has(devicePoint.deviceType) ? modelCount.get(devicePoint.deviceType) + value : value);
        textDevicePointMap.set(text, devicePoint);
      }
    }
  }
  if (!modelCount.size) {
    return;
  }
  let currModel = [...modelCount.entries()].sort((a, b) => b[1] - a[1])[0][0];
  for (let devicePoint of smDatas.devicePoints.filter(x => x.deviceType === currModel)) {
    for (let currComponent of Object.values(compGroupList.value)) {
      for (let model of Object.values(currComponent.models)) {
        if (devicePoint.deviceType === 'camera' && model.subType === 'camera' && model.name === Kf.t('HemisphericCameraSurveillance') || devicePoint.deviceType === 'desk' && model.subType === 'desk' && model.name === '书桌_140x67.8x77') {
          devicePointMap.set(devicePoint, model);
          break;
        } else if (devicePoint.deviceType === 'light' && model.subType === 'light') {
          devicePointMap.set(devicePoint, model);
          break;
        }
        if (devicePoint.deviceName.indexOf(model.name) >= 0) {
          devicePointMap.set(devicePoint, model);
          break;
        }
      }
    }
  }
  for (let i = 0; i < blocks.entities.length; i++) {
    let block = blocks.entities[i];
    let currText = block._texts.filter(text => {
      let devicePoint = textDevicePointMap.get(text);
      return devicePoint && currModel === devicePoint.deviceType;
    })[0];
    let currDevicePoint = textDevicePointMap.get(currText) || {};
    let currModelData = devicePointMap.get(currDevicePoint);
    let drawingTexts = [];
    if (currText) {
      if (isObject(currText.text.textEntities)) {
        for (let entity of currText.text.textEntities.entities) {
          let content = entity.content;
          if (content) {
            if (typeof content === 'string') {
              drawingTexts.push(content);
            } else {
              for (let _content of content) {
                if (_content.type === 0) {
                  drawingTexts.push(_content.content);
                }
              }
            }
          }
        }
      } else {
        drawingTexts = [currText.text.textEntities];
      }
    }
    if (currModelData) {
      smCreator.modelCreator.currModelData = currModelData;
      let model = smDatas.createModel(smCreator.modelCreator._createModelData());
      model._blockTransforms = block.entity.transforms;
      model.drawingTexts = drawingTexts;
      if (currDevicePoint.deviceName && currDevicePoint.deviceName !== 'null') {
        model.name = currDevicePoint.deviceName;
      }
      model.deviceId = currDevicePoint.deviceId;
      model.devicePointModelName = currDevicePoint.spaceComponentName;
      currDevicePoint.deviceModelList.add(model.nanoId);
      model.setNeedSave();
      model.updateBoundingBox();
      if (currData.floor) {
        model.floor = currData.floor.nanoId;
      }
      model.needSave = true;
      undoMap.push({
        cmd: 'create',
        value: model,
        needSave: model.needSave
      });
      models.push(model);
    }
  }
  smCreator.modelCreator.currModelData = null;
  return models;
};
export const deleteMapping = function (objs) {
  for (let item of currData.currList) {
    if (item.type === 'devicePoint') {
      for (let modelNanoId of item.deviceModelList) {
        let model = smDatas.objects[modelNanoId];
        if (model) {
          model.deviceId = '';
          model.devicePointModelName = '';
          model.setNeedSave();
        }
      }
      item.deviceModelList.clear();
    }
    if (item.type === 'model') {
      if (!item.deviceId) {
        continue;
      }
      let point = smDatas.devicePoints.filter(x => x.deviceId === item.deviceId)[0];
      if (point) {
        point.deviceModelList.delete(item.nanoId);
      }
      item.deviceId = '';
      item.devicePointModelName = '';
      item.setNeedSave();
    }
  }
};
export const bindModelsToDevice = function (devicePoint, models) {
  devicePoint = smDatas.devicePoints.find(x => x.deviceId === devicePoint.deviceId);
  if (!devicePoint) {
    return;
  }
  let deviceId = devicePoint.deviceId;
  for (let model of Object.values(models)) {
    if (model.deviceId === deviceId) {
      continue;
    }
    if (model.deviceId) {
      let point = smDatas.devicePoints.find(x => x.deviceId === model.deviceId);
      if (point) {
        point.deviceModelList.delete(model.nanoId);
      }
    }
    model.deviceId = deviceId;
    model.devicePointModelName = devicePoint.spaceComponentName;
    if (devicePoint.deviceName && devicePoint.deviceName !== 'null') {
      model.name = devicePoint.deviceName;
    }
    devicePoint.deviceModelList.add(model.nanoId);
    model.setNeedSave();
  }
};