import { eachObject, getNanoID } from "@/utils/tools";
import { registerGlobalKeydownEvent } from './global.keydown';
const globalDomEvent = {
  'global.mouse': {},
  //2d canvas 鼠标移除事件
  'global.key': {},
  //2d canvas 鼠标移除事件
  'global.2d.mouse': {},
  //2d canvas 鼠标移除事件
  'global.keydown': {},
  //2d canvas 鼠标移除事件
  'global.keyup': {},
  //2d canvas 鼠标移除事件
  'global.2d.key': {} //2d canvas 鼠标移除事件
};
export const registerGlobalMouseEvent = function (eventName, callBack) {
  let handle = getNanoID();
  globalDomEvent[eventName][handle] = callBack;
  return handle;
};
export const dispatchGlobalMouseEvent = function (eventName, event) {
  let eventFunctions = globalDomEvent[eventName];
  if (eventFunctions) {
    eachObject(eventFunctions, callBack => {
      callBack(eventName, event);
    });
  }
};
document.addEventListener('keydown', function (event) {
  dispatchGlobalMouseEvent('global.keydown', event);
});
document.addEventListener('keyup', function (event) {
  dispatchGlobalMouseEvent('global.keyup', event);
});
registerGlobalKeydownEvent();