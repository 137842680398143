import { watch } from 'vue';
import { calibrateCenterX, calibrateCenterZ, onchange, showCalibrateCenter } from "@/components/widgets/contextmenu/KF2DDialog";
import extendInput from '@/components/propWidget/extendInput';
export default {
  name: "KF-2d-Dialog",
  components: {
    extendInput
  },
  setup() {
    watch(showCalibrateCenter, () => {
      if (!showCalibrateCenter.value) {
        calibrateCenterX.value = 0;
        calibrateCenterZ.value = 0;
        onchange('calibrateCenter', true, {
          x: calibrateCenterX.value * 10,
          y: calibrateCenterZ.value * 10
        }, false);
      }
    });
    let selfCalibrateCenter = function (state) {
      onchange('calibrateCenter', true, {
        x: parseFloat(calibrateCenterX.value) * 10,
        y: parseFloat(calibrateCenterZ.value) * 10
      }, state);
      showCalibrateCenter.value = false;
    };
    return {
      onchange,
      selfCalibrateCenter,
      showCalibrateCenter,
      calibrateCenterX,
      calibrateCenterZ
    };
  }
};