import "core-js/modules/es.array.push.js";
class Construct2DBuilder {
  update(data) {
    let con = data.obj;
    let updateView = (select, view) => {
      let mesh = data.selected ? data.selectedMesh : data.mesh;
      if (select !== data.selected) {
        if (data.onView) {
          let index = mesh.objArray.indexOf(data);
          if (index >= 0) {
            mesh.objArray.splice(index, 1);
            mesh.thinInstanceCount = index;
            for (let i = index; i < mesh.objArray.length; i++) {
              mesh.objArray[i].index = i;
              mesh.thinInstanceAdd(mesh.objArray[i].matrix);
            }
            data.onView = false;
          }
        }
        data.selected = select;
        mesh = data.selected ? data.selectedMesh : data.mesh;
      }
      if (view) {
        let elevation = 0;
        Kingfisher.Matrix.FromAxisToRef(new Kingfisher.Vector3(con.position.x, elevation, con.position.y), new Kingfisher.Vector3(0, -con.direction, 0), null, null, data.matrix);
        if (!data.onView) {
          data.index = mesh.objArray.length;
          mesh.objArray.push(data);
          mesh.thinInstanceAdd(data.matrix);
          data.onView = true;
        } else {
          mesh.thinInstanceSetMatrixAt(data.index, data.matrix, true);
        }
      } else {
        if (data.onView) {
          let index = mesh.objArray.indexOf(data);
          if (index >= 0) {
            mesh.objArray.splice(index, 1);
            mesh.thinInstanceCount = index;
            for (let i = index; i < mesh.objArray.length; i++) {
              mesh.objArray[i].index = i;
              mesh.thinInstanceAdd(mesh.objArray[i].matrix);
            }
            data.onView = false;
          }
        }
      }
    };
    if (!data.inited) {
      if (!con.finalVisible) {
        return false;
      }
      data.inited = true;
      let c = data.scene.getMeshByName(con.properties.type);
      if (!c) {
        console.error('can not find the construct, type = ' + con.properties.type);
        return;
      }
      if (!c.objArray) {
        c.objArray = [];
        c.renderingGroupId = 1;
      }
      let c_selected = data.scene.getMeshByName(con.properties.type + '_selected');
      if (!c_selected) {
        c_selected = c.clone();
        c_selected.name = con.properties.type + '_selected';
        c_selected.objArray = [];
      }
      data.mesh = c;
      data.selectedMesh = c_selected;
      data.matrix = Kingfisher.Matrix.Identity();
      data.highlightMeshes = [c_selected];
      data.pickMeshes = [c, c_selected];
      data.setSelected = function (value) {
        if (data.selected === value) {
          return;
        }
        updateView(value, con.finalVisible);
      };
      data.dispose = function () {
        updateView(data.selected, false);
      };
    }
    updateView(data.selected, con.finalVisible);
    return true;
  }
}
export default new Construct2DBuilder();