import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "kf-3dc-dialog-body"
};
const _hoisted_2 = {
  style: {
    "user-select": "text"
  }
};
const _hoisted_3 = {
  key: 0,
  class: "kf-3dc-dialog-contents mt16"
};
const _hoisted_4 = {
  class: "kf-3dc-dialog-select-label kf-3dc-contact-font"
};
const _hoisted_5 = {
  class: "kf-3dc-dialog-select-con kf-3dc-contact-font",
  style: {
    "height": "35px"
  }
};
const _hoisted_6 = {
  class: "kf-3dc-dialog-contents mt16"
};
const _hoisted_7 = {
  class: "kf-3dc-dialog-select-label kf-3dc-contact-font"
};
const _hoisted_8 = {
  class: "kf-3dc-dialog-select-con kf-3dc-contact-font",
  style: {
    "height": "35px"
  }
};
const _hoisted_9 = {
  key: 1,
  class: "kf-3dc-dialog-contents mt16"
};
const _hoisted_10 = {
  class: "kf-3dc-dialog-select-label kf-3dc-contact-font"
};
const _hoisted_11 = ["title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_dialog, {
    modelValue: $setup.showDialog,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.showDialog = $event),
    "close-on-click-modal": true,
    title: _ctx.$t('ContactUs'),
    "custom-class": "kf-3dc-dialog3",
    width: "540px"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_ctx.platformType === 'saas' ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('ProductName')), 1), _createElementVNode("div", _hoisted_5, _toDisplayString($setup.title), 1)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('UpdateTime')), 1), _createElementVNode("div", _hoisted_8, _toDisplayString($setup.updateTime), 1)]), _ctx.platformType !== 'saas' ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('VersionNumber')), 1), _createElementVNode("div", {
      title: $setup.packTime,
      class: "kf-3dc-dialog-select-con kf-3dc-contact-font",
      style: {
        "height": "35px"
      }
    }, _toDisplayString($setup.version), 9, _hoisted_11)])) : _createCommentVNode("", true)])])]),
    _: 1
  }, 8, ["modelValue", "title"])]);
}