import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "kf-model-container"
};
const _hoisted_2 = {
  class: "kf-model-serach"
};
const _hoisted_3 = {
  class: "kf-model-content mt2 kingfisher_scroll"
};
const _hoisted_4 = {
  class: "kf-kf-model-collapse-title"
};
const _hoisted_5 = {
  class: "kf-model-collapse-content"
};
const _hoisted_6 = {
  class: "kf-model-collapse-content1"
};
const _hoisted_7 = {
  class: "kf-model-item"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_collapse_item = _resolveComponent("el-collapse-item");
  const _component_el_collapse = _resolveComponent("el-collapse");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_input, {
    placeholder: _ctx.$t('SearchModelName'),
    clearable: "",
    modelValue: $setup.modelSearch,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.modelSearch = $event)
  }, null, 8, ["placeholder", "modelValue"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_collapse, {
    modelValue: $setup.activeName,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $setup.activeName = $event),
    accordion: "",
    class: "kf-model-collapse"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.modelTypeList, (item, index) => {
      return _openBlock(), _createBlock(_component_el_collapse_item, {
        key: index
      }, {
        title: _withCtx(() => [_createElementVNode("div", _hoisted_4, _toDisplayString(item.name), 1)]),
        default: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(20, (item2, index2) => {
          return _createElementVNode("div", _hoisted_7);
        }), 64))])])]),
        _: 2
      }, 1024);
    }), 128))]),
    _: 1
  }, 8, ["modelValue"])])]);
}