import "core-js/modules/es.array.push.js";
class SmCreatorTools {
  constructor(collection, scene) {
    this.collection = collection;
    this.scene = scene;
    this.createMousePositionLine(scene);
    this.createAdsorbLine(scene);
    this.createAreaBox(scene);
    this.createCalibrateCenter(scene);
    this.createCalibrateSize(scene);
    this.textDegree = new Kingfisher.HtmlAnnotation('textDegree', scene);
    this.textLength = new Kingfisher.HtmlAnnotation('textLength', scene);
    this.textLength.shiftYInPixel = -24;
    this.textDegree.htmlElement.style.pointerEvents = 'none';
    this.textDegree.htmlElement.style.color = 'White';
    this.textLength.htmlElement.style.pointerEvents = 'none';
    this.textLength.htmlElement.style.color = 'White';
  }
  createMousePositionLine(scene) {
    let lineMaterial = new Kingfisher.KMaterial('line', scene);
    lineMaterial.wireframe = 1;
    lineMaterial.transparencyMode = 3;
    let channel = new Kingfisher.KMatChannel(lineMaterial, {
      name: Kf.t('Texture1')
    });
    let light = new Kingfisher.KMatStageNoLight(lineMaterial, channel);
    light.colorBase.copyFromFloats(0.33, 0.6, 0.75);
    light.opacity = 0.2;
    let xline = Kingfisher.MeshBuilder.CreatePlane("x-line", {
      XZPlan: true,
      width: 1,
      height: 1
    }, scene);
    xline.scaling.x = 100000000;
    xline.scaling.z = 0;
    xline.doNotSerialize = true;
    xline.hideInDesigner = true;
    xline.enableMirror = false;
    xline.disignerOnly = true;
    xline.neverPickable = true;
    xline.receiveShadows = false;
    xline.castShadows = false;
    xline.renderingGroupId = 2;
    xline.material = lineMaterial;
    let yline = Kingfisher.MeshBuilder.CreatePlane("x-line", {
      XZPlan: true,
      width: 0.1,
      height: 1
    }, scene);
    yline.scaling.z = 100000000;
    yline.scaling.x = 0;
    yline.doNotSerialize = true;
    yline.hideInDesigner = true;
    yline.enableMirror = false;
    yline.disignerOnly = true;
    yline.neverPickable = true;
    yline.receiveShadows = false;
    yline.castShadows = false;
    yline.renderingGroupId = 2;
    yline.material = lineMaterial;

    // let mouseXlineText = document.createElement('div');
    // let mouseYlineText = document.createElement('div');
    // mouseXlineText.style.pointerEvents = 'none';
    // mouseXlineText.style.color = 'White';
    // mouseYlineText.style.pointerEvents = 'none';
    // mouseYlineText.style.color = 'White';
    // mouseXlineText.style.zIndex = "2";
    // mouseYlineText.style.zIndex = "2";
    //
    // mouseXlineText.innerHTML = '';
    // mouseYlineText.innerHTML = '';

    this.mouseXline = xline;
    this.mouseYline = yline;
    // this.mouseXlineText = mouseXlineText;
    // this.mouseYlineText = mouseYlineText;
  }
  mouseMove(pos, event) {
    this.mouseXline.position.z = pos.y;
    this.mouseYline.position.x = pos.x;

    // this.mouseXlineText.innerHTML = event.x.toFixed(0);
    // this.mouseYlineText.innerHTML = event.y.toFixed(0);
    // this.mouseXlineText.style.y = event.y + 'px';
    // this.mouseYlineText.style.x = event.x + 'px';
  }
  createAdsorbLine(scene) {
    let lineMaterial = new Kingfisher.KMaterial('line', scene);
    lineMaterial.wireframe = 1;
    lineMaterial.transparencyMode = 3;
    let channel = new Kingfisher.KMatChannel(lineMaterial, {
      name: Kf.t('Texture1')
    });
    let light = new Kingfisher.KMatStageNoLight(lineMaterial, channel);
    light.opacity = 0.3;
    light.colorBase.copyFromFloats(1, 1, 0);
    let xline = Kingfisher.MeshBuilder.CreatePlane("x-line", {
      XZPlan: true,
      width: 1,
      height: 1
    }, scene);
    xline.scaling.z = 0;
    xline.doNotSerialize = true;
    xline.hideInDesigner = true;
    xline.enableMirror = false;
    xline.disignerOnly = true;
    xline.neverPickable = true;
    xline.receiveShadows = false;
    xline.castShadows = false;
    xline.renderingGroupId = 2;
    xline.material = lineMaterial;
    let yline = Kingfisher.MeshBuilder.CreatePlane("x-line", {
      XZPlan: true,
      width: 1,
      height: 1
    }, scene);
    yline.scaling.x = 0;
    yline.doNotSerialize = true;
    yline.hideInDesigner = true;
    yline.enableMirror = false;
    yline.disignerOnly = true;
    yline.neverPickable = true;
    yline.receiveShadows = false;
    yline.castShadows = false;
    yline.renderingGroupId = 2;
    yline.material = lineMaterial;
    this.AdsorbXLine = xline;
    this.AdsorbYLine = yline;
  }
  createAreaBox(scene) {
    let boxMaterial = new Kingfisher.KMaterial('box', scene);
    boxMaterial.wireframe = 1;
    boxMaterial.transparencyMode = 3;
    let channel = new Kingfisher.KMatChannel(boxMaterial, {
      name: '材质1'
    });
    let light = new Kingfisher.KMatStageNoLight(boxMaterial, channel);
    light.opacity = 0.3;
    light.colorBase.copyFromFloats(1, 1, 0);
    let lines = [];
    for (let i = 0; i < 4; i++) {
      let line = Kingfisher.MeshBuilder.CreatePlane("x-line", {
        XZPlan: true,
        width: 1,
        height: 1
      }, scene);
      line.doNotSerialize = true;
      line.hideInDesigner = true;
      line.enableMirror = false;
      line.disignerOnly = true;
      line.neverPickable = true;
      line.receiveShadows = false;
      line.castShadows = false;
      line.renderingGroupId = 2;
      line.material = boxMaterial;
      lines.push(line);
    }
    lines[0].scaling.z = 0;
    lines[1].scaling.z = 0;
    lines[2].scaling.x = 0;
    lines[3].scaling.x = 0;
    this.AreaBoxLines = lines;
  }
  updateAdsorbLine(pos, ray, adsorb) {
    if (!adsorb) {
      this.AdsorbXLine.enableState = false;
      this.AdsorbYLine.enableState = false;
      return;
    }
    if (adsorb.pointX) {
      let yLine = this.AdsorbYLine;
      yLine.enableState = true;
      yLine.scaling.z = Math.abs(adsorb.pointX.y - pos.y);
      yLine.position.z = (adsorb.pointX.y + pos.y) * 0.5;
      yLine.position.x = adsorb.pointX.x;
    } else {
      this.AdsorbYLine.enableState = false;
    }
    if (adsorb.pointY) {
      let xLine = this.AdsorbXLine;
      xLine.enableState = true;
      xLine.scaling.x = Math.abs(adsorb.pointY.x - pos.x);
      xLine.position.x = (adsorb.pointY.x + pos.x) * 0.5;
      xLine.position.z = adsorb.pointY.y;
    } else {
      this.AdsorbXLine.enableState = false;
    }
  }
  updateAreaBox(pos0, pos1, ray, adsorb) {
    if (!pos0 || !pos1) {
      for (let line of this.AreaBoxLines) {
        line.enableState = false;
      }
      return;
    }
    for (let i = 0; i < 4; i++) {
      let line = this.AreaBoxLines[i];
      line.enableState = true;
      if (i === 0 || i === 1) {
        line.position.x = (pos0.x + pos1.x) * 0.5;
        line.position.z = i === 0 ? pos0.y : pos1.y;
        line.scaling.x = Math.abs(pos1.x - pos0.x);
      } else {
        line.position.z = (pos0.y + pos1.y) * 0.5;
        line.position.x = i === 2 ? pos0.x : pos1.x;
        line.scaling.z = Math.abs(pos1.y - pos0.y);
      }
    }
  }
  updateTextDegree(pos, rotation, text) {
    if (pos) {
      var _pos$z;
      this.textDegree.position.x = pos.x;
      this.textDegree.position.z = (_pos$z = pos.z) !== null && _pos$z !== void 0 ? _pos$z : pos.y;
    }
    this.textDegree.innerHTML = text || '';
  }
  updateTextLength(pos, rotation, text) {
    if (pos) {
      var _pos$z2;
      this.textLength.position.x = pos.x;
      this.textLength.position.z = (_pos$z2 = pos.z) !== null && _pos$z2 !== void 0 ? _pos$z2 : pos.y;
    }
    this.textLength.innerHTML = text || '';
  }
  createCalibrateCenter(scene) {
    let calibrateMaterial = new Kingfisher.KMaterial('calibrateCenter', scene);
    calibrateMaterial.wireframe = 1;
    calibrateMaterial.transparencyMode = 3;
    let channel = new Kingfisher.KMatChannel(calibrateMaterial, {
      name: Kf.t('Texture1')
    });
    let light = new Kingfisher.KMatStageNoLight(calibrateMaterial, channel);
    light.opacity = 0.2;
    let xline = Kingfisher.MeshBuilder.CreatePlane("calibrateCenterX", {
      XZPlan: true,
      width: 1,
      height: 1
    }, scene);
    xline.scaling.x = 1;
    xline.scaling.z = 0;
    xline.doNotSerialize = true;
    xline.hideInDesigner = true;
    xline.enableMirror = false;
    xline.disignerOnly = true;
    xline.neverPickable = true;
    xline.receiveShadows = false;
    xline.castShadows = false;
    xline.renderingGroupId = 2;
    xline.material = calibrateMaterial;
    let yline = Kingfisher.MeshBuilder.CreatePlane("calibrateCenterY", {
      XZPlan: true,
      width: 0.1,
      height: 1
    }, scene);
    yline.scaling.z = 1;
    yline.scaling.x = 0;
    yline.doNotSerialize = true;
    yline.hideInDesigner = true;
    yline.enableMirror = false;
    yline.disignerOnly = true;
    yline.neverPickable = true;
    yline.receiveShadows = false;
    yline.castShadows = false;
    yline.renderingGroupId = 2;
    yline.material = calibrateMaterial;
    this.calibrateCenterX = xline;
    this.calibrateCenterY = yline;
    let textCalibrateCenter = new Kingfisher.HtmlAnnotation('textcalibrateCenter', scene);
    textCalibrateCenter.htmlElement.style.pointerEvents = 'none';
    textCalibrateCenter.htmlElement.style.color = 'White';
    textCalibrateCenter.shiftXInPixel = 8;
    textCalibrateCenter.shiftYInPixel = -24;
    this.textCalibrateCenter = textCalibrateCenter;
  }
  enableCalibrateCenter(value) {
    if (value) {
      this.calibrateCenterX.enableState = true;
      this.calibrateCenterY.enableState = true;
      this.textCalibrateCenter.innerHTML = '';
    } else {
      this.calibrateCenterX.enableState = false;
      this.calibrateCenterY.enableState = false;
      this.textCalibrateCenter.innerHTML = '';
    }
  }
  createCalibrateSize(scene) {
    let calibrateSize = new Kingfisher.KMaterial('calibrateSize', scene);
    calibrateSize.wireframe = 1;
    calibrateSize.transparencyMode = 3;
    let channel = new Kingfisher.KMatChannel(calibrateSize, {
      name: Kf.t('Texture1')
    });
    let light = new Kingfisher.KMatStageNoLight(calibrateSize, channel);
    light.opacity = 1;
    light.colorBase.copyFromFloats(1, 0, 0);
    let line = Kingfisher.MeshBuilder.CreatePlane("calibrateCenterX", {
      XZPlan: true,
      width: 1,
      height: 1
    }, scene);
    line.scaling.x = 1;
    line.scaling.z = 0;
    line.doNotSerialize = true;
    line.hideInDesigner = true;
    line.enableMirror = false;
    line.disignerOnly = true;
    line.neverPickable = true;
    line.receiveShadows = false;
    line.castShadows = false;
    line.renderingGroupId = 2;
    line.material = calibrateSize;
    this.calibrateSize = line;
    let textCalibrateSize = new Kingfisher.HtmlAnnotation('textcalibrateSize', scene);
    textCalibrateSize.htmlElement.style.pointerEvents = 'none';
    textCalibrateSize.htmlElement.style.color = 'White';
    textCalibrateSize.shiftXInPixel = 8;
    textCalibrateSize.shiftYInPixel = -24;
    this.textCalibrateSize = textCalibrateSize;
  }
  enableCalibrateSize(value) {
    if (value) {
      this.calibrateSize.enableState = true;
      this.textCalibrateSize.innerHTML = '';
    } else {
      this.calibrateSize.enableState = false;
      this.textCalibrateSize.innerHTML = '';
    }
  }
  calibrateCenterMouseMove(pos) {
    let size = this.scene.activeCamera.distance * 0.1;
    this.calibrateCenterX.scaling.x = size;
    this.calibrateCenterY.scaling.z = size;
    this.calibrateCenterX.position.x = pos.x;
    this.calibrateCenterX.position.z = pos.y;
    this.calibrateCenterY.position.x = pos.x;
    this.calibrateCenterY.position.z = pos.y;
    this.textCalibrateCenter.position.x = pos.x;
    this.textCalibrateCenter.position.z = pos.y;
    this.textCalibrateCenter.innerHTML = (pos.x * 0.1).toFixed(1) + 'cm, ' + (pos.y * 0.1).toFixed(1) + 'cm';
  }
  calibrateSizeMouseMove(pos0, pos1) {
    let dx = pos0.x - pos1.x;
    let dy = pos0.y - pos1.y;
    let r = Math.atan2(dy, dx);
    let x = (pos0.x + pos1.x) * 0.5;
    let y = (pos0.y + pos1.y) * 0.5;
    let l = Math.sqrt(dx * dx + dy * dy);
    this.calibrateSize.rotation.y = -r;
    this.calibrateSize.scaling.x = l;
    this.calibrateSize.position.x = x;
    this.calibrateSize.position.z = y;
    this.textCalibrateSize.position.x = x;
    this.textCalibrateSize.position.z = y;
    this.textCalibrateSize.innerHTML = (l * 0.1).toFixed(1) + 'cm';
  }
}
export { SmCreatorTools };