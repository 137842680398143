import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = ["title", "onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return $setup.showContextmenu2 ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    onMouseleave: _cache[0] || (_cache[0] = (...args) => $setup.closeMenu && $setup.closeMenu(...args)),
    style: _normalizeStyle($setup.contextMenuStyle),
    class: "kf-panel_contextmenu"
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.menuData.menu, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: "kf-panel_contextmenu_con mn",
      key: index
    }, [_createElementVNode("div", {
      class: "kingfisher_material_contextmenu_con_txt mn",
      title: item.label,
      onClick: $event => $setup.menuData.fun($event, item, $setup.menuData.caller)
    }, _toDisplayString(item.label), 9, _hoisted_1)]);
  }), 128))], 36)) : _createCommentVNode("", true);
}