import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array-buffer.detached.js";
import "core-js/modules/es.array-buffer.transfer.js";
import "core-js/modules/es.array-buffer.transfer-to-fixed-length.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.find-last.js";
import "core-js/modules/es.typed-array.find-last-index.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.to-reversed.js";
import "core-js/modules/es.typed-array.to-sorted.js";
import "core-js/modules/es.typed-array.with.js";
import { BatchingKey } from "@/dxflib/BatchingKey";
import { distanceSquaredPointToSegment, matrix2DInvert, pointInTriangle, Vector2 } from "@/maths/vector";
import { DxfWorker } from "@/dxflib/DxfWorker";
const boundsPick = (pos, threshold, bounds) => {
  return bounds && !(pos.x + threshold < bounds.minX || pos.y + threshold < bounds.minY || pos.x > bounds.maxX + threshold || pos.y > bounds.maxY + threshold);
};
const linePick = (pos, threshold, batch) => {
  let posA = new Vector2();
  let posB = new Vector2();
  let tolerant2 = threshold * threshold;
  for (let chunk of batch.chunks || [batch]) {
    for (let source of chunk.sources) {
      if (!boundsPick(pos, threshold, source.bounds)) {
        continue;
      }
      let vertices = chunk.vertices;
      let vertexEnd = source.vertexStart + source.vertexCount;
      for (let i = source.vertexStart; i < vertexEnd; i += 2) {
        let i2 = i * 2;
        posA.set(vertices[i2], vertices[i2 + 1]);
        posB.set(vertices[i2 + 2], vertices[i2 + 3]);
        let d2 = distanceSquaredPointToSegment(pos, posA, posB);
        if (tolerant2 >= d2) {
          let res = {
            a: posA,
            b: posB,
            source
          };
          if (batch !== chunk) {
            res.chunk = chunk;
          }
          return res;
        }
      }
    }
  }
};
const indexedLinePick = (pos, threshold, batch) => {
  let posA = new Vector2();
  let posB = new Vector2();
  let tolerant2 = threshold * threshold;
  for (let chunk of batch.chunks || [batch]) {
    for (let source of chunk.sources) {
      // if (!boundsPick(pos, threshold, source.bounds)) {
      //     continue;
      // }

      let vertices = chunk.vertices;
      let indices = chunk.indices;
      let indexEnd = source.indexStart + source.indexCount;
      for (let i = source.indexStart; i < indexEnd; i += 2) {
        let a = indices[i] * 2;
        let b = indices[i + 1] * 2;
        posA.set(vertices[a], vertices[a + 1]);
        posB.set(vertices[b], vertices[b + 1]);
        let d2 = distanceSquaredPointToSegment(pos, posA, posB);
        if (tolerant2 >= d2) {
          let res = {
            a: posA,
            b: posB,
            source
          };
          if (batch !== chunk) {
            res.chunk = chunk;
          }
          return res;
        }
      }
    }
  }
};
const trianglePick = (pos, threshold, batch) => {
  let posA = new Vector2();
  let posB = new Vector2();
  let posC = new Vector2();
  for (let chunk of batch.chunks || [batch]) {
    for (let source of chunk.sources) {
      if (!boundsPick(pos, threshold, source.bounds)) {
        continue;
      }
      let vertices = chunk.vertices;
      let vertexEnd = source.vertexStart + source.vertexCount;
      for (let i = source.vertexStart; i < vertexEnd; i += 3) {
        let i2 = i * 2;
        posA.set(vertices[i2], vertices[i2 + 1]);
        posB.set(vertices[i2 + 2], vertices[i2 + 3]);
        posC.set(vertices[i2 + 4], vertices[i2 + 5]);
        if (pointInTriangle(pos, posA, posB, posC)) {
          let res = {
            a: posA,
            b: posB,
            c: posC,
            source
          };
          if (batch !== chunk) {
            res.chunk = chunk;
          }
          return res;
        }
      }
    }
  }
};
const indexedTrianglePick = (pos, threshold, batch) => {
  let posA = new Vector2();
  let posB = new Vector2();
  let posC = new Vector2();
  for (let chunk of batch.chunks || [batch]) {
    for (let source of chunk.sources) {
      if (!boundsPick(pos, threshold, source.bounds)) {
        continue;
      }
      let vertices = chunk.vertices;
      let indices = chunk.indices;
      let indexEnd = source.indexStart + source.indexCount;
      for (let i = source.indexStart; i < indexEnd; i += 3) {
        let a = indices[i] * 2;
        let b = indices[i + 1] * 2;
        let c = indices[i + 2] * 2;
        posA.set(vertices[a], vertices[a + 1]);
        posB.set(vertices[b], vertices[b + 1]);
        posC.set(vertices[c], vertices[c + 1]);
        if (pointInTriangle(pos, posA, posB, posC)) {
          let res = {
            a: posA,
            b: posB,
            c: posC,
            source
          };
          if (batch !== chunk) {
            res.chunk = chunk;
          }
          return res;
        }
      }
    }
  }
};
const batchPick = (pos, threshold, batch) => {
  if (batch.transformsArray) {
    let sources = batch.sources;
    let transformsArray = batch.transformsArray;
    let pos_ = pos.clone();
    const transform2DPoint = function (p, m) {
      let x = p.x * m[0] + p.y * m[2] + m[4];
      let y = p.x * m[1] + p.y * m[3] + m[5];
      p.x = x;
      p.y = y;
      return p;
    };
    for (let t = 0; t < sources.length; t++) {
      let source = sources[t];
      if (!boundsPick(pos, threshold, source.bounds)) {
        continue;
      }
      let i = t * 6;
      let matrix = matrix2DInvert([transformsArray[i], transformsArray[i + 3], transformsArray[i + 1], transformsArray[i + 4], transformsArray[i + 2], transformsArray[i + 5]]);
      let p = transform2DPoint(pos_, matrix);
      for (let batch1 of batch.block.batches) {
        if (!boundsPick(p, threshold, batch1.bounds)) {
          continue;
        }
        let picked = batchPick(p, threshold, batch1);
        if (picked) {
          picked.instanceID = t;
          picked.source = source;
          return picked;
        }
      }
    }
  } else {
    let geometryType = batch.key.geometryType;
    switch (geometryType) {
      case BatchingKey.GeometryType.LINES:
        return linePick(pos, threshold, batch);
      case BatchingKey.GeometryType.INDEXED_LINES:
        return indexedLinePick(pos, threshold, batch);
      case BatchingKey.GeometryType.TRIANGLES:
        return trianglePick(pos, threshold, batch);
      case BatchingKey.GeometryType.INDEXED_TRIANGLES:
        return indexedTrianglePick(pos, threshold, batch);
    }
  }
};
const dxfDrawingPick = (drawing, pos, adsorb) => {
  let p = pos.scale(1 / drawing.scaling);
  let threshold = adsorb.tolerant / drawing.scaling;
  let dxfData = smDxfCache[drawing.fileId];
  if (!dxfData) {
    return;
  }
  let picked;
  for (let layerIndex = 0; layerIndex < drawing.layers.length; layerIndex++) {
    if (!drawing.layers[layerIndex].visible) {
      continue;
    }
    let layer = dxfData.layers[layerIndex];
    if (!boundsPick(p, threshold, layer.bounds)) {
      continue;
    }
    let batches = layer.batches;
    if (batches) {
      for (let batch of batches) {
        if (!boundsPick(p, threshold, batch.bounds)) {
          continue;
        }
        picked = batchPick(p, threshold, batch);
        if (picked) {
          picked.layer = layer;
          picked.batch = batch;
          return picked;
        }
      }
    }
  }
};
const createBounds = () => {
  return {
    maxX: -Number.MAX_VALUE,
    maxY: -Number.MAX_VALUE,
    minX: Number.MAX_VALUE,
    minY: Number.MAX_VALUE
  };
};
const updateBounds = (box, x, y) => {
  box.minX = Math.min(box.minX, x);
  box.minY = Math.min(box.minY, y);
  box.maxX = Math.max(box.maxX, x);
  box.maxY = Math.max(box.maxY, y);
};
const extrusionBounds = (boxA, boxB) => {
  boxA.minX = Math.min(boxA.minX, boxB.minX);
  boxA.minY = Math.min(boxA.minY, boxB.minY);
  boxA.maxX = Math.max(boxA.maxX, boxB.maxX);
  boxA.maxY = Math.max(boxA.maxY, boxB.maxY);
};
const afterLoadDxf = scene => {
  let blocks = {};
  for (const batch of scene.batches) {
    if (batch.key.blockName !== null && batch.key.geometryType !== BatchingKey.GeometryType.BLOCK_INSTANCE && batch.key.geometryType !== BatchingKey.GeometryType.POINT_INSTANCE) {
      if (!blocks[batch.key.blockName]) {
        blocks[batch.key.blockName] = {
          batches: [],
          bounds: createBounds(),
          entitiesBounds: createBounds(),
          entities: []
        };
      }
      blocks[batch.key.blockName].batches.push(batch);
      let blockBounds = blocks[batch.key.blockName].bounds;
      let batchBounds = createBounds();
      let isChunks = batch.chunks;
      for (let chunk of batch.chunks || [batch]) {
        let chunkBounds = createBounds();
        const vertices = new Float32Array(scene.vertices, chunk.verticesOffset * Float32Array.BYTES_PER_ELEMENT, chunk.verticesSize);
        if (chunk.sources) {
          for (let source of chunk.sources) {
            let sourceBounds = createBounds();
            if (!source.entity.bounds) {
              source.entity.bounds = createBounds();
            }
            let entityBounds = source.entity.bounds;
            let vertexEnd = source.vertexStart + source.vertexCount;
            for (let i = source.vertexStart; i < vertexEnd; i++) {
              let i2 = i * 2;
              let x = vertices[i2];
              let y = vertices[i2 + 1];
              updateBounds(sourceBounds, x, y);
              updateBounds(entityBounds, x, y);
            }
            source.bounds = sourceBounds;
            extrusionBounds(chunkBounds, sourceBounds);
          }
        }
        chunk.vertices = vertices;
        if (isChunks) {
          const indices = new Uint16Array(scene.indices, chunk.indicesOffset * Uint16Array.BYTES_PER_ELEMENT, chunk.indicesSize);
          chunk.indices = indices;
        }
        chunk.bounds = chunkBounds;
        extrusionBounds(batchBounds, chunkBounds);
      }
      batch.bounds = batchBounds;
      extrusionBounds(blockBounds, batchBounds);
    }
  }
  for (const batch of scene.batches) {
    if (batch.key.blockName !== null && batch.key.geometryType !== BatchingKey.GeometryType.BLOCK_INSTANCE && batch.key.geometryType !== BatchingKey.GeometryType.POINT_INSTANCE) {
      continue;
    }
    let layerName = batch.key.layerName || '';
    let geometryType = batch.key.geometryType;
    let layer = scene.layers.find(x => x.name === layerName);
    if (!layer) {
      continue;
    }
    if (!layer.batches) {
      layer.batches = [];
      layer.bounds = createBounds();
    }
    layer.batches.push(batch);
    let batchBounds = createBounds();
    if (geometryType == BatchingKey.GeometryType.BLOCK_INSTANCE || geometryType == BatchingKey.GeometryType.POINT_INSTANCE) {
      let block = blocks[batch.key.blockName];
      if (!block) {
        continue;
      }
      const transformsArray = new Float32Array(scene.transforms, batch.transformsOffset * Float32Array.BYTES_PER_ELEMENT, batch.transformsSize);
      batch.transformsArray = transformsArray;
      batch.block = block;
      let entities = block.entities;
      let entitiesBounds = block.entitiesBounds;
      const transform2DPoint = function (p, m) {
        let x = p.x * m[0] + p.y * m[2] + m[4];
        let y = p.x * m[1] + p.y * m[3] + m[5];
        return {
          x,
          y
        };
      };
      let points = [{
        x: block.bounds.minX,
        y: block.bounds.minY
      }, {
        x: block.bounds.minX,
        y: block.bounds.maxY
      }, {
        x: block.bounds.maxX,
        y: block.bounds.minY
      }, {
        x: block.bounds.maxX,
        y: block.bounds.maxY
      }];
      for (let i = 0; i < transformsArray.length; i += 6) {
        let matrix = [transformsArray[i], transformsArray[i + 3], transformsArray[i + 1], transformsArray[i + 4], transformsArray[i + 2], transformsArray[i + 5]];
        let blockBounds = createBounds();
        let source = batch.sources[i / 6];
        if (!source.entity.bounds) {
          source.entity.bounds = createBounds();
        }
        let entityBounds = source.entity.bounds;
        for (let p0 of points) {
          let p = transform2DPoint(p0, matrix);
          updateBounds(blockBounds, p.x, p.y);
          updateBounds(entityBounds, p.x, p.y);
        }
        source.bounds = blockBounds;
        extrusionBounds(batchBounds, blockBounds);
        extrusionBounds(entitiesBounds, entityBounds);
        entities.push(source);
        source.entity.transforms = matrix;
        source.entity.block = block;
      }
    } else {
      let isChunks = batch.chunks;
      for (let chunk of batch.chunks || [batch]) {
        let chunkBounds = createBounds();
        const vertices = new Float32Array(scene.vertices, chunk.verticesOffset * Float32Array.BYTES_PER_ELEMENT, chunk.verticesSize);
        if (chunk.sources) {
          for (let source of chunk.sources) {
            let sourceBounds = createBounds();
            if (!source.entity.bounds) {
              source.entity.bounds = createBounds();
            }
            let entityBounds = source.entity.bounds;
            let vertexEnd = source.vertexStart + source.vertexCount;
            for (let i = source.vertexStart; i < vertexEnd; i++) {
              let i2 = i * 2;
              let x = vertices[i2];
              let y = vertices[i2 + 1];
              updateBounds(sourceBounds, x, y);
              updateBounds(entityBounds, x, y);
            }
            source.bounds = sourceBounds;
            extrusionBounds(chunkBounds, sourceBounds);
          }
        }
        chunk.bounds = chunkBounds;
        extrusionBounds(batchBounds, chunkBounds);
        chunk.vertices = vertices;
        if (isChunks) {
          const indices = new Uint16Array(scene.indices, chunk.indicesOffset * Uint16Array.BYTES_PER_ELEMENT, chunk.indicesSize);
          chunk.indices = indices;
        }
      }
    }
    batch.bounds = batchBounds;
    extrusionBounds(layer.bounds, batchBounds);
  }
  scene.blocks = blocks;
  textsBinding(scene);
};
const getDistanceSquaredOfBounds = function (bounds1, bounds2) {
  // if ((bounds1.minX > bounds2.minX && bounds1.minX < bounds2.maxX && bounds1.minY > bounds2.minY && bounds1.minY < bounds2.maxY) ||
  //     (bounds1.maxX > bounds2.minX && bounds1.maxX < bounds2.maxX && bounds1.minY > bounds2.minY && bounds1.minY < bounds2.maxY) ||
  //     (bounds1.minX > bounds2.minX && bounds1.minX < bounds2.maxX && bounds1.maxY > bounds2.minY && bounds1.maxY < bounds2.maxY) ||
  //     (bounds1.maxX > bounds2.minX && bounds1.maxX < bounds2.maxX && bounds1.maxY > bounds2.minY && bounds1.maxY < bounds2.maxY)) {
  //     return 0;
  // }
  if (!bounds1 || !bounds2) {
    return Number.MAX_VALUE;
  }
  if (bounds2.minX <= bounds1.maxX && bounds2.minY <= bounds1.maxY && bounds2.maxX >= bounds1.minX && bounds2.maxY >= bounds1.minY) {
    return 0;
  }
  let d = 10 * Math.min(bounds1.maxX - bounds1.minX + bounds1.maxY - bounds1.minY, bounds2.maxX - bounds2.minX + bounds2.maxY - bounds2.minY);
  if (bounds1.minX - bounds2.maxX > d || bounds2.minX - bounds1.maxX > d || bounds1.minY - bounds2.maxY > d || bounds2.minY - bounds1.maxY > d) {
    return Number.MAX_VALUE;
  }
  if (bounds1.minX > bounds2.maxX) {
    if (bounds1.minY > bounds2.maxY) {
      return (bounds1.minX - bounds2.maxX) * (bounds1.minX - bounds2.maxX) + (bounds1.minY - bounds2.maxY) * (bounds1.minY - bounds2.maxY);
    } else if (bounds2.minY > bounds1.maxY) {
      return (bounds1.minX - bounds2.maxX) * (bounds1.minX - bounds2.maxX) + (bounds2.minY - bounds1.maxY) * (bounds2.minY - bounds1.maxY);
    } else {
      return (bounds1.minX - bounds2.maxX) * (bounds1.minX - bounds2.maxX);
    }
  } else if (bounds2.minX > bounds1.maxX) {
    if (bounds1.minY > bounds2.maxY) {
      return (bounds2.minX - bounds1.maxX) * (bounds2.minX - bounds1.maxX) + (bounds1.minY - bounds2.maxY) * (bounds1.minY - bounds2.maxY);
    } else if (bounds2.minY > bounds1.maxY) {
      return (bounds2.minX - bounds1.maxX) * (bounds2.minX - bounds1.maxX) + (bounds2.minY - bounds1.maxY) * (bounds2.minY - bounds1.maxY);
    } else {
      return (bounds2.minX - bounds1.maxX) * (bounds2.minX - bounds1.maxX);
    }
  } else {
    if (bounds1.minY > bounds2.maxY) {
      return (bounds1.minY - bounds2.maxY) * (bounds1.minY - bounds2.maxY);
    } else if (bounds2.minY > bounds1.maxY) {
      return (bounds2.minY - bounds1.maxY) * (bounds2.minY - bounds1.maxY);
    } else {
      return 0;
    }
  }
};
const textsBinding = dxfScene => {
  for (let key in dxfScene.blocks) {
    let block = dxfScene.blocks[key];
    for (let entity of block.entities) {
      entity._texts = [];
    }
  }
  for (let text of dxfScene.texts) {
    text._blocks = [];
  }
  for (let key in dxfScene.blocks) {
    let block = dxfScene.blocks[key];
    for (let entity of block.entities) {
      for (let text of dxfScene.texts) {
        // text must be in the same layer with entity
        if (!entity.entity || text.layer !== entity.entity.layer) {
          continue;
        }
        let d2 = getDistanceSquaredOfBounds(entity.bounds, text.bounds);
        if (d2 === Number.MAX_VALUE) {
          continue;
        }
        entity._texts.push({
          d2,
          text
        });
        text._blocks.push({
          d2,
          block
        });
      }
    }
  }
  for (let key in dxfScene.blocks) {
    let block = dxfScene.blocks[key];
    for (let entity of block.entities) {
      entity._texts.sort((a, b) => a.d2 - b.d2);
    }
  }
  for (let text of dxfScene.texts) {
    text._blocks.sort((a, b) => a.d2 - b.d2);
  }
};
export { dxfDrawingPick, afterLoadDxf };