import _defineProperty from "F:/workspace/\u7A7A\u95F4\u6CBB\u7406\u5DE5\u5177/\u7A7A\u95F4\u6CBB\u7406CAD/space-management/spaceManager/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import { getNanoID } from '@/utils/tools';
import { SMDirtyAll, SMDirtyReticular } from "./smDirtyFlag";
import { smDatas } from "@/model/smCollectionData";
import { globalNeedSave } from "@/model/globalData";
let uid = 1;
const getUID = function () {
  return uid++;
};
const SMTypes = {};
const registerSMType = function (name, type) {
  SMTypes[name] = type;
};
const getSMType = function (name) {
  return SMTypes[name];
};
const serializeObject = function (obj, keyList, res) {
  res = res || {};
  if (!obj) {
    return res;
  }
  const serizlize = function (value) {
    if (value === null) {
      return value;
    }
    let type = typeof value;
    if (type === 'string' || type === 'number' || type === 'boolean') {
      return value;
    } else if (type === 'object') {
      if (value instanceof Array) {
        let array = [];
        for (let i = 0; i < value.length; i++) {
          array.push(serizlize(value[i]));
        }
        return array;
      } else if (value.serialize) {
        return value.serialize();
      } else if (value.asArray) {
        return value.asArray();
      }
    }
  };
  for (let k = 0; k < keyList.length; k++) {
    let key = keyList[k];
    let value = serizlize(obj[key]);
    if (value !== undefined) {
      res[key] = value;
    }
  }
  return res;
};
const updateProperties = function (obj, keyList, data) {
  if (!obj || !data) {
    return false;
  }
  let changed = false;
  for (let k = 0; k < keyList.length; k++) {
    let key = keyList[k];
    let value = data[key];
    if (value !== undefined && value !== obj[key]) {
      let type = typeof value;
      if (type === 'string' || type === 'number' || type === 'boolean') {
        obj[key] = value;
        changed = true;
      } else if (type === 'object' && obj[key]) {
        if (obj[key].updateProperties) {
          obj[key].updateProperties(value);
        } else if (obj[key].fromArray) {
          obj[key].fromArray(value);
        } else if (value instanceof Array) {
          let temp = [];
          let keys = [];
          for (let i = 0; i < value.length; i++) {
            keys.push(i);
          }
          updateProperties(temp, keys, value);
          obj[key] = temp;
        }
      }
    }
  }
  return changed;
};
class SMObject {
  set needSave(value) {
    if (this._needSave === value) {
      return;
    }
    this._needSave = value;
    globalNeedSave.value = true;
  }
  get needSave() {
    return this._needSave;
  }
  setNeedSave() {
    this.needSave = true;
  }
  set isDeleted(value) {
    if (this._isDeleted === value) {
      return;
    }
    this._isDeleted = value;
    this.makeDirty();
    this.setNeedSave();
  }
  get isDeleted() {
    return this._isDeleted;
  }
  get isValid() {
    return !this._isDeleted;
  }
  constructor(collection, options) {
    _defineProperty(this, "_needSave", false);
    _defineProperty(this, "_uuid", void 0);
    _defineProperty(this, "_nanoId", void 0);
    _defineProperty(this, "_name", void 0);
    _defineProperty(this, "type", void 0);
    _defineProperty(this, "subType", void 0);
    _defineProperty(this, "parent", void 0);
    _defineProperty(this, "_floor", void 0);
    _defineProperty(this, "_building", void 0);
    _defineProperty(this, "_isDeleted", false);
    _defineProperty(this, "_visible", true);
    _defineProperty(this, "_locked", void 0);
    _defineProperty(this, "customId", void 0);
    this.type = 'object';

    // this.collection = collection;
    this._uuid = options && options.uuid ? options.uuid : 0;
    this._nanoId = options && options.nanoId ? options.nanoId : getNanoID();
    Object.defineProperty(this, 'uuid', {
      get() {
        return this._uuid;
      },
      set(value) {
        if (this._uuid) {
          return;
        }
        this._uuid = value;
      }
    });
    Object.defineProperty(this, 'nanoId', {
      get() {
        return this._nanoId;
      }
    });
    this._building = smDatas.building && smDatas.building.nanoId;
    if (options) {
      this.dirtyCallback = options.dirtyCallback;
    }
  }
  set floor(value) {
    if (this._floor === value) {
      return;
    }
    if (this._floor) {
      let floor = smDatas.floors.find(x => x.nanoId === this._floor);
      if (floor) {
        let index = floor.objects.indexOf(this.nanoId);
        if (index >= 0) {
          floor.objects.splice(index, 1);
        }
      }
    }
    if (value) {
      let floor = smDatas.floors.find(x => x.nanoId === value);
      if (floor) {
        floor.objects.push(this.nanoId);
      }
    }
    this._floor = value;
    this.makeDirty();
  }
  get floor() {
    return this._floor;
  }
  get floorName() {
    let floor = smDatas.objects[this._floor];
    return floor && floor.name || '';
  }
  get floorVisible() {
    let floor = smDatas.objects[this._floor];
    return !floor || floor.visible;
  }
  get building() {
    return this._building;
  }
  set name(value) {
    this._name = value;
  }
  get name() {
    return this._name;
  }
  set visible(value) {
    if (this._visible === value) {
      return;
    }
    this._visible = value;
    this.makeDirty();
  }
  get visible() {
    return this._visible;
  }
  get finalVisible() {
    // return this._visible && !this._isDeleted && (!this.floor || (smDatas.objects[this.floor] && smDatas.objects[this.floor].visible));
    return this._visible && !this._isDeleted && this.floorVisible;
  }
  set locked(value) {
    if (this._locked === value) {
      return;
    }
    this._locked = value;
    this.makeDirty();
  }
  get locked() {
    return this._locked;
  }
  makeDirty(flag = SMDirtyAll) {
    if (this.dirtyCallback) {
      this.dirtyCallback(this, flag);
    }
  }
  updateProperties(data) {
    let changed = updateProperties(this, ['name', 'customId', 'subType'], data);
    if (data && data.properties) {
      changed |= updateProperties(this, ['visible', 'locked'], data.properties);
    }
    if (data.floorId !== undefined) {
      this.floor = data.floorId; //smDatas.floors.find((x) => x.nanoId === data.floorId);
    }
    if (data.parentId !== undefined) {
      this.parent = data.parentId; //smDatas.objects[data.parentId];
    }
    if (changed) {
      this.makeDirty();
    }
    return changed;
  }
  serialize() {
    let res = serializeObject(this, ['uuid', 'nanoId', 'type', 'subType', 'name', 'customId']);
    res.properties = {};
    res.buildingId = this._building || ''; //this._building.nanoId;

    res.floorId = this._floor || ''; //this._floor.nanoId;

    res.parentId = this.parent || ''; // this.parent.nanoId;

    serializeObject(this, ['visible', 'locked'], res.properties);
    return res;
  }
  static Parse(data) {
    if (data && data.type === 'imageDrawing') {
      debugger;
    }
    let type = getSMType(data && data.type);
    if (!type) {
      console.error('Cannot get the type: ' + (data && data.type));
      return;
    }
    return new type(data);
  }
}
export { getUID, registerSMType, getSMType, serializeObject, updateProperties, SMObject };