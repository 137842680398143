import "core-js/modules/es.array.push.js";
import { getModelByName } from "@/model/3Dmodel";
class Model2DBuilder {
  update(data) {
    let loader;
    let obj = data.obj;
    let scene = data.scene;
    if (data.modelName && data.modelName !== obj.modelName) {
      data.dispose();
    }
    data.inited = true;
    if (!data.loader) {
      data.modelName = obj.modelName;
      let model = getModelByName(obj.modelName);
      let modelId = model ? model.k3dcId : '';
      if (modelId) {
        if (!scene.getKPartByID(modelId)) {
          // let item = getModelById(obj.modelId);
          // if (!item) {
          //     return false;
          // }
          let part = new Kingfisher.KPart(scene, {
            id: modelId
          });
          let partData = {
            // name: item.name,
            // category: item.category,
            // attribute: item.attribute,
            // type: item.type,
            url: '$k3dc://parts/' + modelId + '.k2dc'
          };
          part.updateProperties(partData);
        }
        loader = new Kingfisher.KPartLoader(obj.name, scene, undefined, {
          resourceId: modelId
        });
        loader.parent = scene._modelRoot;
        loader.visibility = 0.7;
        if (loader.resource.isReady()) {
          let meshes = loader.getChildMeshes();
          data.pickMeshes = meshes;
          data.highlightMeshes = meshes;
          for (let mesh of meshes) {
            mesh.alphaIndex = 30;
            mesh.renderingGroupId = 2;
          }
        } else {
          loader.resource.onLoadObservable.addOnce(() => {
            let meshes = loader.getChildMeshes();
            data.pickMeshes = meshes;
            data.highlightMeshes = meshes;
            for (let mesh of meshes) {
              mesh.alphaIndex = 30;
              mesh.renderingGroupId = 2;
            }
          });
        }
        data.modelError = false;
      } else {
        loader = Kingfisher.MeshBuilder.CreatePlane(obj.name, {
          XZPlan: true
        }, scene);
        loader.isPickable = true;
        loader.material = scene.errorMat;
        data.modelError = true;
        data.pickMeshes = [loader];
      }
      data.loader = loader;
      data.focusObject = loader;
      data.textPoint = new Kingfisher.TextPoint();
      data.textPoint.position = new Kingfisher.Vector3(0, 0, 0);
      data.textMesh = obj.deviceId ? obj.deviceLost ? data.scene.modelBindedFailed : data.scene.modelBinded : data.scene.modelName;
      data.textMesh.points.push(data.textPoint);
      data.dispose = function () {
        if (data.loader) {
          data.loader.dispose();
          let textIndex = data.textMesh.points.indexOf(data.textPoint);
          if (textIndex >= 0) {
            data.textMesh.points.splice(textIndex, 1);
            data.textMesh.build();
          }
          data.loader = null;
          data.inited = false;
        }
      };
      data.selected = false;
      data.setSelected = function (selected) {
        if (data.selected === selected) {
          return;
        }
        data.selected = selected;
        if (selected) {
          loader.visibility = 1.0;
        } else {
          loader.visibility = 0.7;
        }
      };
    } else {
      loader = data.loader;
    }
    loader.position.x = obj.position.x;
    loader.position.z = obj.position.z;
    loader.scaling.copyFrom(obj.scaling);
    loader.rotation.copyFrom(obj.rotation);
    loader.scalingDeterminant = obj.properties.ratio;
    if (data.modelError) {
      let boundingBox = obj.boundingBox;
      loader.position.x += (boundingBox[0] + boundingBox[3]) * 0.5;
      loader.position.z += (boundingBox[2] + boundingBox[5]) * 0.5;
      loader.scaling.x = (boundingBox[3] - boundingBox[0]) * 0.001;
      loader.scaling.z = (boundingBox[5] - boundingBox[2]) * 0.001;
    }
    loader.enableState = obj.finalVisible;
    let needBuild = false;
    if (!obj.finalVisible) {
      if (data.textPoint.visible) {
        data.textMesh.build();
        data.textPoint.visible = false;
      }
      return false;
    } else {
      if (!data.textPoint.visible) {
        needBuild = true;
        data.textPoint.visible = true;
      }
    }
    let textMesh = obj.deviceId ? obj.deviceLost ? data.scene.modelBindedFailed : data.scene.modelBinded : data.scene.modelName;
    if (data.textMesh !== textMesh) {
      let textIndex = data.textMesh.points.indexOf(data.textPoint);
      if (textIndex >= 0) {
        data.textMesh.points.splice(textIndex, 1);
        data.textMesh.build();
      }
      textMesh.points.push(data.textPoint);
      data.textMesh = textMesh;
      needBuild = true;
    }
    if (data.textPoint.text !== obj.name) {
      data.textPoint.text = obj.name;
      needBuild = true;
    }
    if (data.textPoint.position.x !== obj.position.x || data.textPoint.position.z !== obj.position.z) {
      data.textPoint.position.x = obj.position.x;
      data.textPoint.position.z = obj.position.z;
      needBuild = true;
    }
    if (needBuild) {
      data.textMesh.build();
    }
    return true;
  }
}
export default new Model2DBuilder();