<template>
  <div class="kf-panel-floors-box  kingfisher_scroll scoped-property-floor" v-if="currData.drawing">
    <div class="kf-panel-floors-row">
      <div class="kf-panel-floors-row-title" >
        {{ $t('Name') }}
      </div>
      <div class="kf-panel-floors-row-content-2">
        <div class="kf-input-box">
          <extend-input :targets="getItemProperty('name')" inputType="text" @change="updateItemProperty($event,'name')"></extend-input>
        </div>
      </div>
    </div>
    <div class="kf-panel-floors-row">
      <div class="kf-panel-floors-row-title" >
        {{ $t('Floor') }}
      </div>
      <div class="kf-panel-floors-row-content-2 kf-select">
        <extend-input
            inputType="select"
            :placeholder="$t('FloorSelection')"
            :options="floorListOptions"
            :targets="getItemProperty('','floor')"
            @change="updateItemProperty($event,'','floor')">
        </extend-input>
      </div>
    </div>
    <div class="kf-panel-floors-row">
      <div class="kf-panel-floors-row-title" >
        {{ $t('Transparency') }}
      </div>
      <div class="kf-panel-floors-row-content-2">
        <div class="kf-input-box  pr num-input-container">
          <num-input :propValue="(currData.drawing.opacity * 100).toFixed(0)"
                     @change="doChange($event, 'opacity', 0.01)"
                     :isNumber="true" :step="1">
          </num-input>
          <span class="kf-input-unit">%</span>
        </div>

      </div>
    </div>

    <div class="kf-panel-floors-row" style="width: 100%">
      <div class="kf-panel-floors-row-title" style="flex-basis: 100%">
        {{ $t('LayerInformation') }}
      </div>
    </div>
    <div class="kf-panel-floors-row" style="height: auto">
      <div class="kf-vertex-container" style="width: 100%;">

        <div class="kf-vertex-con" style="width: 100%;">
          <div class="kf-vertex-list-box kf-vertex-list-box1 kingfisher_scroll" ref="listManager">
            <div class="kf-vertex-list-item"
                 v-for="(item,index) in currData.drawing.layers"
                 @dblclick="ondblclickLayer(item)"
            >
              <div class="ml10 kf-draw-ck kf-ck1">
                <el-checkbox v-model="item.visible"></el-checkbox>
              </div>
              <div class="kf-vertex-list-item2 ml5"  :title="item.layerName"
                   :style="{color:`rgb(${((item.color >> 16) & 0xff) },${((item.color >> 8) & 0xff) },${((item.color >> 0) & 0xff) })`}"
                   @contextmenu.prevent.stop="handleContextmenu($event, item)">
                  {{ item.layerName }}</div>
              <div class="kf-vertex-list-item3 kf-draw-txt1"
              :title="item.count + $t('IndiviualElements') ">{{ item.count }}{{ $t('IndiviualElements') }}</div>
              <!--              <div class="kf-vertex-list-item1 mr10 kf-txt_link" @click="handleOpen(item)">{{ $t('Open') }}</div>-->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="kf-panel-floors-row">
      <div class="kf-panel-floors-row-title" style="flex-basis: 100%">
        {{ $t('ThumbnailInformation') }}
      </div>
    </div>
    <div class="kf-panel-floors-row" style="height: auto">
      <div class="kf-vertex-container" style="width: 100%;">

        <div class="kf-vertex-con" style="width: 100%;">
          <div class="kf-vertex-list-box kf-vertex-list-box1 kingfisher_scroll" ref="listManager">
            <div   v-for="(item,index) in currData.drawing.blocks" class="w100">
              <div class="scope-kf-vertex-list-item"
                   @click="onclickBlocks(item)"
                   @dblclick="ondblclickBlocks(item)"
                   @contextmenu.prevent.stop="handleContextmenu($event, item)"
              >
<!--                  <div class="ml10 kf-draw-ck kf-ck1">-->
<!--                      <el-checkbox v-model="item.visible"></el-checkbox>-->
<!--                  </div>-->
                <div class="scope-kf-vertex-list-item1 ml5" :title="item.name">{{ item.name }}</div>
                <div class="scope-kf-vertex-list-item2 mr15 flex-end-center">{{ item.usedCount }}</div>
                <!--              <div class="kf-vertex-list-item3 kf-draw-txt1">{{item.children.map((x) => x.count).reduce((a, b) => a + b)}}{{ $t('IndiviualElements') }}</div>-->
                <!--              <div class="kf-vertex-list-item1 mr10 kf-txt_link" @click="handleOpen(item)">{{ $t('Open') }}</div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref, watch} from 'vue'
import {smDatas} from "@/model/smCollectionData";
import {currData, globalState, loadDxf} from "@/model/globalData";
import numInput from '../../../propWidget/numInput';
import {
  floorListOptions,
  getItemProperty,
  updateItemProperty
} from "@/components/mainContent/rightPanel/property/index";
import extendInput from '@/components/propWidget/extendInput';
import {showContextMenu} from "@/components/mainContent/rightPanel";
import {closeMenu, openMenu} from "@/components/widgets/contextmenu/contextmenu2";
import {checkBlocksDeviceMapState} from "@/components/mainContent/rightPanel/point";
import {ElMessage} from 'element-plus';
import {BatchingKey} from "@/dxflib/BatchingKey";
import {isReversal, multiplyMatrix2x3} from "@/maths/vector";

export default {
  name: "dxfDrawing",
  methods: {},
  components: {numInput, extendInput},
  setup() {
    let showInfo = ref(false);
    let currInfo = ref({});
    watch(() => currData.drawing, () => {
      showInfo.value = false;
      currInfo.value = {};
    });

    let doChange = function (event, name, k) {
      currData.drawing[name] = Math.round(parseFloat(event)) * k;
      currData.drawing.makeDirty();
      currData.drawing.setNeedSave();
    };
    let doSelectChange = function (event, name, val) {
      currData.drawing[name] = val;
      currData.drawing.makeDirty();
      currData.drawing.setNeedSave();
    };

    const handleOpen = function (item) {
      showInfo.value = true;
      currInfo.value = item;
    }

    const ondblclickLayer = function (item) {
      let drawingId = item.drawing;
      let drawing = smDatas.objects[drawingId];
      window.sm2DMeshBuilder.focusOnBoundingBox(drawing.boundingBoxToWorld(item.boundingBox));
    }

    const onclickBlocks = function (item) {
      let drawingId = item.drawing;
      let file = smDxfCache[smDatas.objects[drawingId].fileId];
      if (file) {
        if (globalState.dxfSelected.blockName !== item.name) {
          globalState.dxfSelected.blockName = item.name;
          globalState.dxfSelected.blocks = file.blocks[item.name].entities.map(entity => {
            return {source: entity}
          });
          globalState.dxfSelected.index = -1;
        }
      }
      currData.setCurrList(smDatas.objects[drawingId]);
    }
    const ondblclickBlocks = function (item) {
      if (item.name !== globalState.dxfSelected.blockName) {
        return;
      }
      let drawingId = item.drawing;
      let drawing = smDatas.objects[drawingId];
      let file = smDxfCache[drawing.fileId];
      if (file) {
        let block = file.blocks[item.name];
        let bounds;
        if (globalState.dxfSelected.index < 0) {
          bounds = block.entitiesBounds;
        }
        else {
          bounds = block.entities[globalState.dxfSelected.index].bounds;
        }
        globalState.dxfSelected.index ++;
        if (globalState.dxfSelected.index === block.entities.length) {
          globalState.dxfSelected.index = -1;
        }
        let boundingBox = [bounds.minX, bounds.minY, bounds.maxX, bounds.maxY];
        window.sm2DMeshBuilder.focusOnBoundingBox(drawing.boundingBoxToWorld(boundingBox));
      }
    }

    const createWallOrPipeline = (item, type = 'wall', isFill = false) => {
      if (type !== 'wall') {
        isFill = false;
      }
      let drawingId = item.drawing;
      let drawing = smDatas.objects[drawingId];
      let dxfData = smDxfCache[drawing.fileId];

      let layer = dxfData.layers.filter(x => x.displayName === item.layerName)[0];
      if (layer) {
        let blocks = dxfData.blocks;
        let options = {name: item.layerName};
        if (type === 'wall' && isFill) {
          options.subType = 'fill';
        }
        let obj = type === 'wall' ? smCollection.createWall(options) : smCollection.createPipeline(options);
        obj.setNeedSave();

        const transform2DPoint = function (p, m) {
          let x = (p.x * m[0]) + (p.y * m[2]) + m[4];
          let y = (p.x * m[1]) + (p.y * m[3]) + m[5];
          return {x, y};
        };

        let createFill = (batchOrChunk, geometryType, instance) => {
          let vertices = batchOrChunk.vertices;
          let indices = batchOrChunk.indices;
          if (isFill) {
            for (let source of batchOrChunk.sources) {
              let entity = source.entity;
              if (!entity.isSolid || entity.type !== 'HATCH') {
                continue;
              }
              for (let loop of entity.boundaryLoops) {
                let points = [];
                let start1 = loop.vertexStart + source.vertexStart;
                let lastX = null;
                let lastY = null;
                for (let i = 0; i < loop.vertexCount; i++) {
                  let i_ = (start1 + i) * 2;
                  let x = vertices[i_];
                  let y = vertices[i_ + 1];
                  if (i > 0 && x === lastX && y === lastY) {
                    continue;
                  }
                  lastX = x;
                  lastY = y;
                  points.push({x, y});
                }
                if (points[0].x === points[points.length - 1].x &&
                    points[0].y === points[points.length - 1].y) {
                  points.pop();
                }
                if (points.length) {
                  if (instance) {
                    let transformsArray = instance;
                    for (let i = 0; i < transformsArray.length; i += 6) {
                      let matrix = [transformsArray[i], transformsArray[i + 3],
                        transformsArray[i + 1], transformsArray[i + 4],
                        transformsArray[i + 2], transformsArray[i + 5]];
                      let fill = obj.createFill();
                      for (let point of points) {
                        fill.addPoint(obj.createPoint(transform2DPoint(point, matrix)));
                      }
                      if (!isReversal(fill.points)) {
                        fill.reverse();
                      }
                    }
                  } else {
                    let fill = obj.createFill();
                    for (let point of points) {
                      fill.addPoint(obj.createPoint(point));
                    }
                    if (!isReversal(fill.points)) {
                      fill.reverse();
                    }
                  }
                }
              }
            }
          }
          else {
            let points = [];
            let lines = [];
            let count = vertices.length / 2;
            let idMap = {};
            for (let i = 0; i < count; i++) {
              let i2 = i * 2;
              let x = vertices[i2];
              let y = vertices[i2 + 1];
              let ip = points.findIndex(p => p.x === x && p.y === y);
              if (ip >= 0) {
                idMap[i] = ip;
              } else {
                idMap[i] = points.length;
                points.push({x, y});
              }
            }
            if (geometryType === BatchingKey.GeometryType.LINES) {
              for (let i = 0; i < count; i += 2) {
                lines.push(idMap[i], idMap[i + 1]);
              }
            } else if (geometryType === BatchingKey.GeometryType.INDEXED_LINES) {
              let count_2 = Math.floor(indices.length / 2);
              for (let i = 0; i < count_2; i++) {
                let i2 = i * 2;
                let a = idMap[indices[i2]];
                let b = idMap[indices[i2 + 1]];
                if (a !== b) {
                  lines.push(idMap[indices[i2]], idMap[indices[i2 + 1]]);
                }
              }
            }

            if (lines.length) {
              let createPoint;
              if (type === 'pipeline') {
                createPoint = (opts) => {
                  let p = obj.createPoint();
                  p.x = opts.x;
                  p.z = opts.y;
                  return p;
                }
              } else {
                createPoint = (opts) => {
                  return obj.createPoint(opts);
                }
              }

              if (instance) {
                let transformsArray = instance;
                for (let i = 0; i < transformsArray.length; i += 6) {
                  let matrix = [transformsArray[i], transformsArray[i + 3],
                    transformsArray[i + 1], transformsArray[i + 4],
                    transformsArray[i + 2], transformsArray[i + 5]];
                  let q = obj.points.length;
                  for (let point of points) {
                    createPoint(transform2DPoint(point, matrix));
                  }
                  for (let i = 0; i < lines.length; i += 2) {
                    obj.createSegment(obj.points[lines[i] + q], obj.points[lines[i + 1] + q]);
                  }
                }
              } else {
                let q = obj.points.length;
                for (let point of points) {
                  createPoint(point);
                }
                for (let i = 0; i < lines.length; i += 2) {
                  obj.createSegment(obj.points[lines[i] + q], obj.points[lines[i + 1] + q]);
                }
              }
            }
          }
        }
        let createBatch = (batch, instanceBatch) => {
          let type = batch.key.geometryType;
          if (isFill ? (type !== BatchingKey.GeometryType.TRIANGLES &&
              type !== BatchingKey.GeometryType.INDEXED_TRIANGLES) : (
              type !== BatchingKey.GeometryType.LINES &&
              type !== BatchingKey.GeometryType.INDEXED_LINES)) {
            return;
          }

          let chunks = batch.chunks;
          if (chunks) {
            for (let chunkIndex = 0; chunkIndex < chunks.length; chunkIndex++) {
              createFill(chunks[chunkIndex], batch.key.geometryType, instanceBatch && instanceBatch.transformsArray);
            }
          } else {
            createFill(batch, batch.key.geometryType, instanceBatch && instanceBatch.transformsArray);
          }
        }
        for (let batch of layer.batches) {
          let geometryType = batch.key.geometryType;
          if (geometryType == BatchingKey.GeometryType.BLOCK_INSTANCE ||
              geometryType == BatchingKey.GeometryType.POINT_INSTANCE) {
            if (batch.hasOwnProperty("transformsOffset")) {
              let instanceBatch = blocks[batch.key.blockName];
              if (!instanceBatch) {
                continue;
              }
              for (let batch_ of instanceBatch.batches) {
                createBatch(batch_, batch);
              }
            }
          } else {
            if (batch.key.blockName !== null) {
              continue;
            }
            createBatch(batch);
          }
        }

        let matrix = drawing.getMatrix()
        if (dxfData.origin) {
          // matrix = multiplyMatrix2x3(matrix, [1, 0, 0, 1, -dxfData.origin.x || 0, -dxfData.origin.y || 0]);
        }
        obj.transform(matrix);
      }
    }
    const handleContextmenu = function (event, drawingItem) {
      openMenu(event, {
        menu: drawingItem.type === "dxfDrawingLayer" ? [
          {label: Kf.t('CreateWallNormal'), type: 'createWall'},
          {label: Kf.t('CreateWallFill'), type: 'createWallFill'},
          {label: Kf.t('CreatePipeline'), type: 'createPipeline'},
        ] : [
          {label: Kf.t('BindModels'), type: 'bindModel'},
        ], fun: function (event, item) {
          let drawingId = drawingItem.drawing;
          let drawing = smDatas.objects[drawingId];
          let dxfData = smDxfCache[drawing.fileId];

          let function_ = () => {
            if (item.type === 'createWall') {
              createWallOrPipeline(drawingItem, 'wall');
            }
            else if (item.type === 'createWallFill') {
              createWallOrPipeline(drawingItem, 'wall', true);
            }
            else if (item.type === 'createPipeline') {
              createWallOrPipeline(drawingItem, 'pipeline');
            }
            else if (item.type === 'bindModel') {
              globalState.isBindingModel = true;
              globalState.bindingModelBlocks = drawingItem;
              let name = drawingItem.name;
              let blockList = smDxfCache[drawing.fileId].blocks[name];
              globalState.blocksDeviceMapState = checkBlocksDeviceMapState(blockList);
            }
            closeMenu();
          }

          if (dxfData) {
            function_();
          }
          else if (drawing.fileId) {
            ElMessage({type: "info", message: Kf.t('LoadingDiagrams')});
            loadDxf(drawing, drawing.fileId, () => {
              function_();
            });
          }
          else {
            ElMessage({type: "warning", message: Kf.t('DiagramsNotSupported')});
          }
        }
      })
    }

    return {
      smDatas,
      currData,
      doChange,
      doSelectChange,
      floorListOptions,
      getItemProperty,
      updateItemProperty,
      handleOpen,
      showContextMenu,
      showInfo,
      currInfo,
      handleContextmenu,
      ondblclickLayer,
      onclickBlocks,
      ondblclickBlocks,
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/styles/css/components/main/baseIndex';

.scope-kf-vertex-list-item {
  width: 100%;
  height: $size-20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: $size-12px;
  color: $background-project;

  &:hover {
    background: rgba($color-select-active, 0.5);
  }

  .scope-kf-vertex-list-item-name {
    margin-left: $size-10px;
    height: $size-20px;
    display: flex;
    align-items: center;
    width: 60%;
  }

  .scope-kf-vertex-list-item-values {
    margin-right: $size-10px;
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}

.scope-kf-vertex-list-item2 {
  width: calc(100% - 105px);
color:rgba(255, 255, 255, 0.5)
}
.flex-end-center{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.mr5{
  margin-right: 5px;
}
.mr15{
  margin-right: 15px;
}
.scope-kf-vertex-list-item{
  .scope-kf-vertex-list-item1{
    width: calc(100% - 45px);
    display: inline-block;
    line-height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .scope-kf-vertex-list-item2{
    width: 45px;
  }
}


</style>

