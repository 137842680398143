import _defineProperty from "F:/workspace/\u7A7A\u95F4\u6CBB\u7406\u5DE5\u5177/\u7A7A\u95F4\u6CBB\u7406CAD/space-management/spaceManager/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import { watch } from 'vue';
import SMWall3DBuilder from "./smWall3DBuilder";
import Area3DBuilder from "./smArea3DBuilder";
import SmPipeline3DBuilder from "./smPipeline3DBuilder";
import Construct3DBuilder from "./smConstruct3DBuilder";
import Model3DBuilder from "./smMode3DBuilder";
import { globalState, currData } from "@/model/globalData";
import SmImageDrawing3DBuilder from "@/builder/smImageDrawing3DBuilder";
import { compInited, selectedThemeId } from "@/model/3Dmodel";
import { smDatas } from "@/model/smCollectionData";
class SmMesh3DBuilder {
  constructor(smCollection, scene) {
    _defineProperty(this, "_renderDiv", 0);
    this.scene = scene;
    this.smCollection = smCollection;
    this.objects = {};
    this._dirtyObjects = {};
    this.currList = {};
    for (let i = 0; i < smCollection.floors; i++) {
      this.add(smCollection.floors[i]);
    }
    for (let i = 0; i < smCollection.drawings; i++) {
      this.add(smCollection.drawings[i]);
    }
    for (let i = 0; i < smCollection.walls; i++) {
      this.add(smCollection.walls[i]);
    }
    for (let i = 0; i < smCollection.areas; i++) {
      this.add(smCollection.areas[i]);
    }
    for (let i = 0; i < smCollection.pipelines; i++) {
      this.add(smCollection.pipelines[i]);
    }
    // for (let i = 0; i < smCollection.constructs; i++) {
    //     this.add(smCollection.constructs[i]);
    // }

    smCollection.addCallbacks.push(obj => {
      this.add(obj);
    });
    smCollection.removeCallbacks.push(obj => {
      this.remove(obj);
    });
    smCollection.updateCallbacks.push(obj => {
      this.update(obj);
    });
    watch(() => currData.currListFlag, (n, o) => {
      this.setCurrList(globalState.mode === 'devicePoint' ? Object.values(currData.models) : currData.finalCurrList);
    });
    watch(() => selectedThemeId.value, (n, o) => {
      for (let key in this.objects) {
        let obj = this.objects[key];
        if (obj.obj.type === 'model') {
          this._dirtyObjects[key] = obj;
          if (obj.dispose) {
            obj.dispose();
          }
        }
      }
    });
  }
  add(obj) {
    if (!obj) {
      return;
    }
    this.objects[obj.nanoId] = {
      obj: obj,
      scene: this.scene
    };
    this._dirtyObjects[obj.nanoId] = this.objects[obj.nanoId];
  }
  remove(obj) {
    if (!obj) {
      return;
    }
    if (this.objects[obj.nanoId]) {
      if (this.objects[obj.nanoId].dispose) {
        this.objects[obj.nanoId].dispose();
      }
      delete this.objects[obj.nanoId];
      delete this._dirtyObjects[obj.nanoId];
      if (this.currList[obj.nanoId]) {
        obj.selected = false;
        if (obj.setSelected) {
          obj.setSelected(false);
        } else if (obj.highlightMeshes) {
          let highlightLayer = this.scene.highlightLayer;
          for (let i = 0; i < obj.highlightMeshes.length; i++) {
            highlightLayer.removeMesh(obj.highlightMeshes[i]);
          }
        }
        delete this.currList[obj.nanoId];
      }
    }
  }
  update(obj) {
    if (!obj || !this.objects[obj.nanoId]) {
      return;
    }
    this._dirtyObjects[obj.nanoId] = this.objects[obj.nanoId];
  }
  updateSelectPoint() {
    if (this.scene._flushGizmoPoints) {
      this.scene._flushGizmoPoints();
    }
  }

  // _pointToModels(curr) {
  //     if (curr && curr.obj.type === 'devicePoint') {
  //         return (Array.from(curr.obj.deviceModelList || [])).map(x => this.objects[x]).filter(x => x);
  //     }
  //     return [curr];
  // }

  setCurrList(objs) {
    let highlightLayer = this.scene.highlightLayer;
    highlightLayer.removeAllMeshes();
    for (let key in this.currList) {
      let obj = this.currList[key];
      if (obj.setSelected) {
        obj.setSelected(false);
      }
    }
    this.currList = {};
    for (let i = 0; objs && i < objs.length; i++) {
      if (!objs[i]) {
        continue;
      }
      let key = objs[i].nanoId;
      let obj = this.objects[key];
      if (obj) {
        let models = [obj]; //this._pointToModels(obj);
        for (let m of models) {
          if (m.obj.finalVisible && m.inited) {
            if (m.setSelected) {
              m.setSelected(true);
            }
            if (m.highlightMeshes) {
              for (let i = 0; i < m.highlightMeshes.length; i++) {
                highlightLayer.addMesh(m.highlightMeshes[i], this.scene.hightLightColor);
              }
            }
          }
          this.currList[m.obj.nanoId] = m;
        }
        obj.selected = true;
      }
    }
  }
  focusOnObject(args) {
    let currObjects = [];
    for (let key in this.currList) {
      let curr = this.currList[key];
      let models;
      if (curr.obj.type === 'devicePoint') {
        currObjects = [];
        models = currData.models.map(x => this.objects[x.nanoId]).filter(x => x);
      } else {
        models = [curr]; //this._pointToModels(curr);
      }
      for (let m of models) {
        if (m.focusObject) {
          currObjects.push(m.focusObject);
        }
      }
    }
    this.scene.activeCamera.focusOnObject(currObjects, undefined, args);
  }
  focusOnSegment(focusArgs) {
    let segment = currData.segment;
    if (!segment) {
      return;
    }
    let camera = this.scene.activeCamera;
    let args = camera.args;
    let ratio = 0.9;
    if (focusArgs) {
      if (focusArgs.ratio) {
        ratio = focusArgs.ratio;
      }
    }
    let distance = segment.length / (2 * ratio * Math.atan(camera.fov / 2));
    if (distance > camera.minZ * 0.01) {
      args.distance = distance;
    }
    args.target.x = (segment.pointA.x + segment.pointB.x) * 0.5;
    args.target.z = (segment.pointA.y + segment.pointB.y) * 0.5;
    camera.focusOn(args);
  }
  focusOnPoint() {
    let point = currData.point;
    if (!point) {
      return;
    }
    let camera = this.scene.activeCamera;
    let args = camera.args;
    args.target.x = point.x;
    args.target.z = point.y;
    camera.focusOn(args);
  }
  render() {
    if (++this._renderDiv < 3) {
      return;
    }
    this._renderDiv = 0;
    let highlightLayer = this.scene.highlightLayer;
    let flag = 0;
    for (let key in this._dirtyObjects) {
      let obj = this._dirtyObjects[key];
      let func;
      switch (obj.obj.type) {
        case 'drawing':
          break;
        case 'imageDrawing':
          func = SmImageDrawing3DBuilder;
          break;
        case 'wall':
          func = SMWall3DBuilder;
          break;
        case 'area':
          func = Area3DBuilder;
          break;
        case 'pipeline':
          func = SmPipeline3DBuilder;
          break;
        case 'construct':
          func = Construct3DBuilder;
          break;
        case 'model':
          if (compInited.value) {
            func = Model3DBuilder;
          }
          break;
      }
      if (func && func.update) {
        if (func.update(obj)) {
          delete this._dirtyObjects[key];
          if (obj.obj.finalVisible && obj.inited && obj.setSelected) {
            obj.setSelected(this.currList[key]);
          }
          flag++;
        }
        if (this.currList[key] && obj.highlightMeshes) {
          if (obj.obj.finalVisible && obj.inited) {
            for (let i = 0; i < obj.highlightMeshes.length; i++) {
              highlightLayer.addMesh(obj.highlightMeshes[i], this.scene.hightLightColor);
            }
          } else {
            for (let i = 0; i < obj.highlightMeshes.length; i++) {
              highlightLayer.removeMesh(obj.highlightMeshes[i]);
            }
          }
        }
        if (flag > 50) {
          return; // build only 50 object per frame
        }
      }
    }
  }
}
export { SmMesh3DBuilder };