<template>
  <div class="container  flex flex-jc-sb kf-header-box tool-box">
    <div class="tool-left">
      <div class="tool-left-menu-box">
        <div class="tool-left-menu-item"
             v-for="(item) in viewMenu"
             :key="item"
             @click="handleViewMenu(item)"
             :title="item.title || item.name"
             :class="[currViewPort === item.name?'tool-left-menu-item-active':'tool-left-menu-item']">
          <i class="iconfont" :class="item.icon" style="font-size: 16px"></i>
        </div>
      </div>

      <div class="tool-left-menu-box GG ml20" >
        <div class="tool-left-menu-item"
             v-for="(item,index) in toolBarMenu"
             :key="index"
             :title="item.title || item.name"
             @click="switchMenuActive('operation', item)"
             :class="[globalState.operation === item.type?'tool-left-menu-item-active':'tool-left-menu-item']">
          <i class="iconfont" :class="item.icon"></i>
        </div>
      </div>
      <!-- <div class="tool-left-menu-box ml20">
           <div class="tool-left-menu-item pr"
                v-for="(item,index) in toolBarState.operations"
                :key="item"
                @click="handleMenuMode(item)"
                :class="[item.name===globalState.mode?'tool-left-menu-item-active':'tool-left-menu-item']">
               <i class="iconfont" :class="item.icon"></i>
               <div class="kf-icon-detail">
                   <i class="iconfont icon-xiala "  v-if="item.children && item.children.length>0"
                      style="position: absolute;bottom: 0;right: 0;font-size: 12px"></i>
                   <div class="tool-left-menu-item-details">
                       <div class="tool-left-menu-item-details-item" v-for="(item2,index2) in item.children"
                       :key="item2" @click="handleMenuMode(item, item2)"
                       > <i class="iconfont" :class="item2.icon"></i></div>
                   </div>
               </div>
           </div>
       </div>-->

      <div class="tool-left-menu-box ml20" v-if="globalState.operation === 'draw'">
        <div class="tool-left-menu-item pr"
             v-for="(item,index) in toolBarMenu[2].childrenBar"
             :key="index"
             :title="(item.title || item.name) + (item.children ? ('(' + (item.children[item.childId].title || item.children[item.childId].name) + ')') : '')"
             @click="switchMenuActive('mode', item)"
             :class="[globalState.mode === item.type?'tool-left-menu-item-active':'tool-left-menu-item']">
          <i class="iconfont" :class="item.children ? item.children[item.childId].icon : item.icon"></i>
          <div class="kf-icon-detail">
            <i class="iconfont icon-xiala "  v-if="item.children && item.children.length>0"
               style="position: absolute;bottom: 0;right: 0;font-size: 12px"></i>
            <div class="tool-left-menu-item-details">
              <div class="tool-left-menu-item-details-item" v-for="(item2,index2) in item.children"
                   :key="item2" @click="handleMenuMode(item, index2, item2)"
              > <i class="iconfont" :class="item2.icon"></i></div>
            </div>
          </div>
        </div>
      </div>
      <div class="tool-left-menu-box ml20" v-if="currModeChildrenList.length>0">
        <div class="tool-left-menu-item pr"
             v-for="(item,index) in currModeChildrenList"
             :key="index"
             :title="item.title || item.name"
             @click="switchMenuActive('drawingMode', item)"
             :class="[globalState.drawingMode === item.type?'tool-left-menu-item-active':'tool-left-menu-item']">
          <i class="iconfont" :class="item.icon"></i>
          <div class="kf-icon-detail">
            <i class="iconfont icon-xiala "  v-if="item.children && item.children.length>0"
               style="position: absolute;bottom: 0;right: 0;font-size: 12px"></i>
            <div class="tool-left-menu-item-details">
              <div class="tool-left-menu-item-details-item" v-for="(item2,index2) in item.children"
                   :key="item2" @click="handleMenuMode(item, index2, item2)"
              > <i class="iconfont" :class="item2.icon"></i></div>
            </div>
          </div>
        </div>
      </div>

      <div class="tool-left-menu-box ml20 " v-if="currModeChildrenListChildren.length>0">
         <div class="tool-left-menu-item pr" style="width: 100%;gap:4px;background-color: #303030; !important; padding: 0 0 0 4px;">
           <div class="tool-left-menu-item-input pr" v-for="(item,index) in currModeChildrenListChildren"  :key="index">
             <span style="margin-right: 6px"  v-if="typeof item.value !='boolean'">{{item.name}}</span>
<!--             <input type="text" class="tool-left-menu-item-input-input" v-model="item.value" v-if="!item.options">-->
<!--             <div class="kf-light-select pr" v-if="item.options">-->
<!--               <i class="iconfont kf-light-selected-i" ></i>-->
<!--               <el-select v-model="item.value"-->
<!--                          @change="changeSelect"-->
<!--                          class="kf-light-select"-->
<!--                          popper-class="kf-light-select-list1 pr">-->
<!--                 <el-option v-for="(item,index) in item.options"-->
<!--                            :key="item.value+index" :label="item.name" :value="item.value">-->
<!--                  <div style="display: flex;justify-content: space-between">-->
<!--                    <div style="width:68px">{{item.name}}</div>-->
<!--                    <i class="iconfont icon-select" style="font-size: 12px" ></i>-->
<!--                  </div>-->
<!--                 </el-option>-->
<!--               </el-select>-->
<!--             </div>-->
<!--             <div class="pr num-input-container tool-left-menu-item-input-input" v-else-if="item.showType =='number'" >-->
<!--             <num-input :propValue="parseFloat(item.value).toFixed(0)"-->
<!--                        class="tool-left-menu-item-input-input"-->
<!--                        @change="doChange($event, item.property, 0.1, item)"-->
<!--                        :isNumber="true" :step="1">-->
<!--             </num-input>-->
<!--             </div>-->
             <div class="kf-panel-floors-row-content-2 kf-select">
               <extend-input
                   :inputType="item.showType"
                   :label="item.name"
                   :targets="getItemValue(item)"
                   :placeholder="item.title || item.name"
                   :options="item.options"
                   :step="item.step || 50"
                   :unitScale="item.unitScale || 0.1"
                   :precision="item.precision"
                   :min="item.min"
                   @change="setItemValue(item, $event)">
               </extend-input>
             </div>
           </div>
<!--           <i class="iconfont icon-close"  style="width: 24px;height: 24px;line-height: 24px;text-align: center" @click="doClick('Cancel')"></i>-->
<!--           <i class="iconfont icon-select" style="width: 24px;height: 24px;line-height: 24px;color:#2FA84F;text-align: center" @click="doClick('Confirm')"></i>-->
         </div>
      </div>

<!--      <div class="tool-left-menu-box ml20" v-if="toolBarChildMenu[globalState.mode]">-->
<!--          <div class="tool-left-menu-item"-->
<!--               v-for="(item,index) in toolBarChildMenu[globalState.mode].menu"-->
<!--               :key="index"-->
<!--               :title="item.title"-->
<!--               @click="updateChildMenuState(globalState.mode,item)"-->
<!--               :class="[globalState[globalState.mode+'Mode'] === item.type?'tool-left-menu-item-active':'tool-left-menu-item']">-->
<!--            <i class="iconfont" :class="item.icon"></i>-->
<!--          </div>-->
<!--        </div>-->

    </div>
    <div class="tool-right">
      <div class="tool-left-menu-box mr20">
        <div class="tool-left-menu-item1"
             @click="handleRedoUndoMenu('undo')"
             :class="[undoMap.undoList.length ? 'tool-left-menu-item1' : 'tool-left-menu-item1-unactive']">
          <i class="iconfont icon-kf-houtui"></i>
        </div>
        <div class="tool-left-menu-item1"
             @click="handleRedoUndoMenu('redo')"
             :class="[undoMap.redoList.length ? 'tool-left-menu-item1' : 'tool-left-menu-item1-unactive']">
          <i class="iconfont icon-kf-qianjin"></i>
        </div>
      </div>
      <div class="tool-right-box">
        <div class="tool-right-item item-br" @click="doExport"><i class="iconfont icon-shangchuan"> </i></div>
        <div class="tool-right-item"
             @click="saveAll()"
             :class="[globalNeedSave ? 'tool-right-item' : 'tool-right-item-unactive']">
          <i class="iconfont icon-baocun"> </i></div>
      </div>

    </div>
  </div>
</template>

<script>
import {ref, computed, watch} from 'vue'
import {smDatas, saveAll} from "@/model/smCollectionData";
import {undoMap} from "@/undo/undoMap";
import {
  viewPortData, currData
} from "@/model/globalData";
import {globalState, globalNeedSave} from "@/model/globalData";
import modelService from '@/service/model'
import projectModel from '@/model/project'
import {ElMessage} from 'element-plus'
import numInput from "@/components/propWidget/numInput.vue";
import extendInput from '@/components/propWidget/extendInput'
import {radianToAngle, angleToRadian} from "@/utils/tools";
import {formatWallOptions4SelectUIPurpose2Name} from "@/components/mainContent/rightPanel/property";

export default {
  name: "toolBar",
  components: {numInput,extendInput},
  setup() {

    const viewMenu = [
      {name: 'view2D', icon: 'icon-a-2D1', title: '2D'},
      {name: 'view32D', icon: 'icon-a-2D3D', title: '2D+3D'},
      {name: 'view3D', icon: 'icon-a-3D1', title: '3D'},
    ];
    const redoUndoMenu = [
      {name: 'undo', icon: 'icon-kf-houtui'},
      {name: 'redo', icon: 'icon-kf-qianjin'},
    ];

    const toolBarMenu = [
        {name: Kf.t('PickUp'), icon: 'icon-xuanze2', title: Kf.t('PickUp'), type: 'pick', childrenBar: [
            {name: Kf.t('CreateModel'), icon: 'icon-chuangjianqiangti', type: 'model', childrenBar: [
                {name: Kf.t('ObjectMode'), icon: 'icon-sanwei', type: 'object', childrenBar: [
                    {name: Kf.t('Name'), icon: '', showType:'text', property: 'name'},
                    {name: 'X(cm)', icon: '', showType:'number', property: 'position.x'},
                    {name: 'Y(cm)', icon: '', showType:'number', property: 'position.y'},
                    {name: 'Z(cm)', icon: '', showType:'number', property: 'position.z'},
                    {name: Kf.t('Rotate')+'(°)', icon: '', showType:'number', property: 'rotation.y', type: 'rotation', step: 2, unitScale: 1},
                  ]},
              ]},
            {name: Kf.t('CreateWall'), icon: 'icon-chuangjianqiangti', type: 'wall', childrenBar: [
                {name: Kf.t('ObjectMode'), icon: 'icon-sanwei', type: 'object',  childrenBar: [
                    {name: Kf.t('Name'), icon: '', showType:'text', property: 'name'},
                    {name: Kf.t('Thickness')+'(cm)', icon: '', showType:'number', property: 'thickness'},
                    {name: Kf.t('Height')+'(cm)', icon: '', showType:'number', property: 'height'},
                  ]},
                {name: Kf.t('PointMode'), icon: 'icon-dian', type: 'point', childrenBar: [
                    {name: Kf.t('Name'), icon: '', showType:'text', property: 'name'},
                    {name: 'X(cm)', icon: '', showType:'number', target: 'points', property: 'x'},
                    {name: 'Y(cm)', icon: '', showType:'number', target: 'points', property: 'y'},
                    {name: Kf.t('Thickness')+'(cm)', icon: '', showType:'number', property: 'thickness'},
                    {name: Kf.t('Height')+'(cm)', icon: '', showType:'number', property: 'height'},
                  ]},
                {name: Kf.t('LineMode'), icon: 'icon-zhixian', type: 'line',  childrenBar: [
                    {name: Kf.t('Name'), icon: '', showType:'text', property: 'name'},
                    {name: Kf.t('Thickness')+'(cm)', icon: '', showType:'number', property: 'thickness'},
                    {name: Kf.t('Height')+'(cm)', icon: '', showType:'number', property: 'height'},
                  ]},
              ]},
            {name: Kf.t('CreateArea'), icon: 'icon-chuangjianqiangti', type: 'area', childrenBar: [
                {name: Kf.t('ObjectMode'), icon: 'icon-sanwei', type: 'object',  childrenBar: [
                    {name: Kf.t('Name'), icon: '', showType:'text', property: 'name'},
                  ]},
                {name: Kf.t('PointMode'), icon: 'icon-dian', type: 'point', childrenBar: [
                    {name: Kf.t('Name'), icon: '', showType:'text', property: 'name'},
                    {name: 'X(cm)', icon: '', showType:'number', target: 'points', property: 'x'},
                    {name: 'Y(cm)', icon: '', showType:'number', target: 'points', property: 'y'},
                  ]},
                {name: Kf.t('LineMode'), icon: 'icon-zhixian', type: 'line',  childrenBar: [
                    {name: Kf.t('Name'), icon: '', showType:'text', property: 'name'},
                  ]},
              ]},
            {name: Kf.t('DrawPipeline'), icon: 'icon-guandao', type: 'pipeline',childrenBar: [
                {name: Kf.t('ObjectMode'), icon: 'icon-sanwei', type: 'object',  childrenBar: [
                    {name: Kf.t('Name'), icon: '', showType:'text', property: 'name'},
                    {name: Kf.t('BaseHeight')+'(cm)', icon: '', showType:'number', property: 'elevation'},
                    // {name: Kf.t('Type'), icon: '', showType:'number',options:[{
                    //     name: Kf.t('WallType1'), value: '1'
                    //   }]},
                  ]},
                {name: Kf.t('PointMode'), icon: 'icon-dian', type: 'point', childrenBar: [
                    {name: Kf.t('Name'), icon: '', showType:'text', property: 'name'},
                    {name: 'X(cm)', icon: '', showType:'number', target: 'points', property: 'x'},
                    {name: 'Y(cm)', icon: '', showType:'number', target: 'points', property: 'y'},
                    {name: 'Z(cm)', icon: '', showType:'number', target: 'points', property: 'z'},
                    {name: Kf.t('BaseHeight')+'(cm)', icon: '', showType:'number', property: 'elevation'},
                  ]},
                {name: Kf.t('LineMode'), icon: 'icon-zhixian', type: 'line',  parentType: 'wall',childrenBar: [
                    {name: Kf.t('Name'), icon: '', showType:'text', property: 'name'},
                    {name: Kf.t('BaseHeight')+'(cm)', icon: '', showType:'number', property: 'elevation'},
                    // {name: Kf.t('Type'), icon: '', showType:'number',options:[{
                    //     name: Kf.t('WallType1'), value: '1'
                    //   }]},
                  ]},
              ]},
        ]},
        {name: Kf.t('Move'), icon: 'icon-yidong2', type: 'move'},
        {name: Kf.t('Edit'), icon: 'icon-bianji', type: 'draw', childrenBar: [
            {name: Kf.t('Model'), icon: 'icon-chuangjianzhuzi', type: 'model', childrenBar: [
              {name: Kf.t('PointMode'), icon: 'icon-dian', type: 'point', childrenBar: [
                  {name: Kf.t('Name'), icon: '', showType:'text', property: 'name'},
                  {name: 'X(cm)', icon: '', showType:'number', property: 'position.x'},
                  {name: 'Y(cm)', icon: '', showType:'number', property: 'position.y'},
                  {name: 'Z(cm)', icon: '', showType:'number', property: 'position.z'},
                  {name: Kf.t('Rotate')+'(°)', icon: '', showType:'number', property: 'rotation.y', type: 'rotation', step: 2, unitScale: 1},
                ]},
              {name: Kf.t('LineMode'), icon: 'icon-zhixian', type: 'line',  childrenBar: [
                  {name: Kf.t('Name'), icon: '', showType:'text', params: 'model.name'},
                  {name: Kf.t('Quantity'), icon: '', showType:'number', step: 1, min: 1, precision: 0, unitScale: 1, params: 'model.quantity'},
                  {name: Kf.t('Height')+'(cm)', icon: '', showType:'number', params: 'model.height'},
                ]},
              {name: Kf.t('AreaModel'), icon: 'icon-juxing', type: 'area',  childrenBar: [
                  {name: Kf.t('Name'), icon: '', showType:'text', params: 'model.name'},
                  {name: Kf.t('RowQuantity'), icon: '', showType:'number', step: 1, min: 1, precision: 0, unitScale: 1, params: 'model.row'},
                  {name: Kf.t('ColumnQuantity'), icon: '', showType:'number', step: 1, min: 1, precision: 0, unitScale: 1, params: 'model.column'},
                  {name: Kf.t('Height')+'(cm)', icon: '', showType:'number', params: 'model.height'},
                ]},
            ]},
            {name: Kf.t('CreateWall'), icon: 'icon-chuangjianqiangti', type: 'wall', childrenBar: [
              // {name: Kf.t('PointMode'), icon: 'icon-dian', type: 'point', childrenBar: [
              //     {name: Kf.t('Name'), icon: '', showType:'text', property: 'name'},
              //     {name: Kf.t('PointModelX')+'(cm)', icon: '', showType:'number', target: 'points', property: 'x'},
              //     {name: Kf.t('PointModelY')+'(cm)', icon: '', showType:'number', target: 'points', property: 'y'},
              //   ]},
              {name: Kf.t('LineMode'), icon: 'icon-zhixian', type: 'line',  childrenBar: [
                  {name: Kf.t('Name'), icon: '', showType:'text', property: 'name'},
                  {name: 'X(cm)', icon: '', showType:'number', target: 'points', property: 'x'},
                  {name: 'Y(cm)', icon: '', showType:'number', target: 'points', property: 'y'},
                  {name: Kf.t('Thickness')+'(cm)', icon: '', showType:'number', property: 'thickness'},
                  {name: Kf.t('Height')+'(cm)', icon: '', showType:'number', property: 'height'},
                  // {name: Kf.t('Type'), icon: '', value: 10,showType:'number',type: '',options:[{
                  //     name: '普通墙',
                  //     value: '1'
                  //   },{
                  //     name: '隔断墙',
                  //     value: '2'
                  //   }]},
                ]},
              {name: Kf.t('ArcModel'), icon: 'icon-huxian', type: 'arc',  childrenBar: [
                  {name: Kf.t('Name'), icon: '', showType:'text', property: 'name'},
                  {name: Kf.t('Thickness')+'(cm)', icon: '', showType:'number', property: 'thickness'},
                  {name: Kf.t('Height')+'(cm)', icon: '', showType:'number', property: 'height'},
                ]},
              {name: Kf.t('AreaModel'), icon: 'icon-juxing', type: 'rect',  childrenBar: [
                  {name: Kf.t('Name'), icon: '', showType:'text', property: 'name'},
                  {name: Kf.t('Thickness')+'(cm)', icon: '', showType:'number', property: 'thickness'},
                  {name: Kf.t('Height')+'(cm)', icon: '', showType:'number', property: 'height'},
                ]},
            ]},
            {name: Kf.t('CreateRegion'), icon: 'icon-chuangjianquyu', type: 'area', childrenBar: [
              {name: Kf.t('LineMode'), icon: 'icon-zhixian', type: 'line',  childrenBar: [
                  {name: Kf.t('Name'), icon: '', showType:'text', property: 'name'},
                  // {name: Kf.t('Type'), icon: '', showType:'number',type: '',options:[{
                  //     name: Kf.t('WallType1'),
                  //     value: '1'
                  //   }]},
                ]},
              {name: Kf.t('ArcModel'), icon: 'icon-huxian', type: 'arc',  childrenBar: [
                  {name: Kf.t('Name'), icon: '', showType:'text', property: 'name'},
                ]},
              {name: Kf.t('AreaModel'), icon: 'icon-juxing', type: 'rect',  childrenBar: [
                  {name: Kf.t('Name'), icon: '', showType:'text', property: 'name'},
                  // {name: Kf.t('Type'), icon: '', showType:'number',type: '',options:[{
                  //     name: Kf.t('WallType1'),
                  //     value: '1'
                  //   }]},
                ]},
            ]},
            {name: Kf.t('DrawPipeline'), icon: 'icon-guandao', type: 'pipeline',childrenBar: [
              {name: Kf.t('LineMode'), icon: 'icon-zhixian', type: 'line',  parentType: 'wall',childrenBar: [
                  {name: Kf.t('Name'), icon: '', showType:'text', property: 'name'},
                  {name: 'X(cm)', icon: '', showType:'number', target: 'points', property: 'x'},
                  {name: 'Y(cm)', icon: '', showType:'number', params: 'pipeline.height', target: 'points', property: 'y'},
                  {name: 'Z(cm)', icon: '', showType:'number', target: 'points', property: 'z'},
                  {name: Kf.t('BaseHeight')+'(cm)', icon: '', showType:'number', property: 'elevation'},
                ]},
            ]},
          ]},
        // {name: Kf.t('DrawRoute'), icon: 'icon-chuangjianlujing', title: Kf.t('DrawRoute'), type: 'route',childrenBar: [
        //     {
        //       name: Kf.t('PointMode'),
        //       icon: 'icon-dian',
        //       title: Kf.t('PointMode'),
        //       type: 'point',
        //       parentType: 'wall',
        //       childrenBar: [
        //         {name: 'X', icon: '', title: Kf.t('PointModelX'), value: 10,showType:'number',type: 'X'},
        //         {name: 'Y', icon: '', title: Kf.t('PointModelY'), value: 10,showType:'number',type: 'Y'},
        //       ]
        //     },
        //     {name: Kf.t('LineMode'), icon: 'icon-zhixian', title: Kf.t('LineMode'), type: 'line',  parentType: 'wall',childrenBar: [
        //         {name: Kf.t('Name'), icon: '', title: Kf.t('Name'), value: 10,type: 'name'},
        //         {name: Kf.t('Type'), icon: '', title: Kf.t('Type'), value: 10,showType:'number',type: '',options:[{
        //             name: Kf.t('WallType1'),
        //             value: '1'
        //           }]},
        //         {name: Kf.t('Radius'), icon: '', title: Kf.t('Radius'), value: 10,showType:'number',type: 'Radius'},
        //         {name: Kf.t('Height')+'(cm)', icon: '', title: Kf.t('Height')+'(cm)', value: 10,showType:'number',type: 'Height'},
        //         {name: Kf.t('Category'), icon: '', title: Kf.t('Category'), value: 10,type: 'Category'},
        //       ]},
        //   ]},
    ];

    let currViewPort = computed(() => {
      let t = '';
      if (viewPortData['view3D']) {
        t += '3';
      }
      if (viewPortData['view2D']) {
        t += '2';
      }
      return `view${t}D`;
    });

    let currModeChildrenList = computed(() => {
      let operation = globalState.operation;
      let item = null
      if (operation === 'pick') {
        item = toolBarMenu[0].childrenBar.find(item => item.type === globalState.mode);
      }
      if (operation === 'draw') {
        item = toolBarMenu[2].childrenBar.find(item => item.type === globalState.mode);
      }
      if (item) {
        let oldMode = globalState.drawingMode;
        globalState.drawingMode = (item.childrenBar.find(x => x.type === oldMode) || item.childrenBar[0]).type || '';
        return item.childrenBar;
      }

      return [];
    })

    let currModeChildrenListChildren = computed(() => {
      let mode = globalState.drawingMode;
      let item = currModeChildrenList.value.find(item => item.type === mode);

      return item ? item.childrenBar : [];
    });

    const handleViewMenu = function (item) {
      switch (item.name) {
        case 'view2D':
          viewPortData['view2D'] = true;
          viewPortData['view3D'] = false;
          break;
        case 'view32D':
          viewPortData['view2D'] = true;
          viewPortData['view3D'] = true;
          break;
        case 'view3D':
          viewPortData['view2D'] = false;
          viewPortData['view3D'] = true;
          break;
      }
    }

    const handleMenuMode = function (item, index2, item2) {
      globalState.mode = item.mode;
      if (item2) {
        item.childId = index2;
        globalState.modelMode = item2.mode;
      }
    }

    const switchMenuActive = function (groupName, item) {
      globalState[groupName] = item.type;
    }

    const handleRedoUndoMenu = function (item) {
      if (item === 'undo') {
        undoMap.undo();
      } else {
        undoMap.redo();
      }
    }
    let isReadOnly = function (item) {
      if (!item) {
        return true;
      }
      let value = currData[item.target || 'currList'];
      if (value === undefined || value === null) {
        return true;
      }

      if (Array.isArray(value)) {
        return value.length === 0;
      }

      return typeof value !== 'object';
    }

    let doChange = function (event, name, k, item) {
      item.value=event;
    };

    let getItemProperty = function (target, property, type) {
      if (!property) {
        return [];
      }
      let targets = currData[target || 'finalCurrList'];
      if (!targets) {
        return [];
      }
      if (!Array.isArray(targets)) {
        targets = [targets];
      }

      let path = property.split('.');
      let result = [];

      for (let target of targets) {
        let _t = target;
        let i = 0;
        for (; i < path.length; i++) {
          if (!_t) {
            break;
          }
          _t = _t[path[i]];
        }

        if (i < path.length) {
          continue;
        }
        if (type === 'rotation') {
          _t = radianToAngle(_t)
        }
        result.push(_t)
      }

      return result;
    }

    let getItemValue = (item) => {
      let res = getItemProperty(item.target, item.property, item.type);
      if (!res.length && item.params) {
        item._params = item._params || item.params.split('.');
        let ps = item._params;
        let value = globalState.toolBarParams;
        for (let i = 0; i < ps.length; i++) {
          value = value[ps[i]];
          if (value === null || value === undefined) {
            break;
          }
        }
        res = [value];
      }
      return res;
    }

    let updateItemProperty = function (target, data, property, type) {
      let targets = currData[target || 'finalCurrList'];
      if (!targets) {
        return;
      }

      if (!Array.isArray(targets)) {
        targets = [targets];
      }

      let path = (property || '').split('.');

      let undoItems = [];
      let newValues = [];
      for (let j = 0; j < targets.length; j++) {
        let target = targets[j];
        let _t = target;
        for (let i = 0; i < path.length - 1; i++) {
          if (!_t) {
            break;
          }
          _t = _t[path[i]];
        }

        if (!_t) {
          continue;
        }

        let value = data[j];
        if (type === 'rotation') {
          value = angleToRadian(value);
        }
        let lastP = path[path.length - 1];
        undoItems.push({target, value: _t[lastP]});
        _t[lastP] = value;
        newValues.push(value);

        target.makeDirty();
        target.setNeedSave();
      }

      if (undoItems.length) {
        undoMap.pushWithCheck({
          cmd: 'updateProperty',
          property,
          type,
          items: undoItems
        }, newValues);
      }
    }

    let setItemValue = (item, data) => {
      if (item.params) {
        item._params = item._params || item.params.split('.');
        let ps = item._params;
        let value = globalState.toolBarParams;
        for (let i = 0; i < ps.length - 1; i++) {
          value = value[ps[i]];
          if (value === null || value === undefined) {
            return;
          }
        }
        value[ps[ps.length - 1]] = data[0];
      }
      updateItemProperty(item.target, data, item.property, item.type);
    }

    let doClick = function (type) {
      if (type === 'Confirm') {
        console.log('Confirm');
      } else {
        console.log('Cancel');
      }
    }
    let changeSelect = function (val) {
      console.log(val);
    }

    const doExport=function (){
        if(projectModel.currProject.value?.uuid && projectModel.currScene.value?.buildingId){
            let p={
                projectId:projectModel.currProject.value.uuid,
                spaceBuildingId:projectModel.currScene.value.buildingId
            }
             modelService.downloadDevices(p).then(res =>{
                 if(res?.status===404){
                     ElMessage({type: 'error', message: res?.data?.message || Kf.t('DataNotAvailable')+Kf.t('Download')});
                 }
             })
        }else{
            ElMessage({type: 'warning', message:Kf.t('PleaseSelectTheProjectAndSceneFirst')});
        }
    }
    return {
      viewMenu,
      toolBarMenu,
      globalState,
      globalNeedSave,
      //menuMode,
      redoUndoMenu,
      smDatas,
      currData,
      undoMap,
      isReadOnly,
      doChange,
      doClick,
      getItemProperty,
      getItemValue,
      updateItemProperty,
      setItemValue,
      //toolBarChildMenu,
      handleViewMenu,
      switchMenuActive,
      handleMenuMode,
      handleRedoUndoMenu,
      saveAll,
      currViewPort,
      currModeChildrenList,
      currModeChildrenListChildren,
      doExport,
      changeSelect
    }
  }
}
</script>

<style scoped>

</style>
