import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import { ref, computed } from 'vue';
import ProjectService from "../service/project";
import { areaOptionsData } from "./purposeOptions";
import userModel from '@/model/user';
import contentModel, { isTronThemeDetails } from './newContent';
import spaceService from '@/service/space';
import authorityService from '@/service/authority';
let projectList = ref([]); //项目列表

let projectGroupList = ref({}); //分组后项目列表

let currProject = ref({});
let currScene = ref({});
let videoList = ref([]);
let loadingData = ref(false);
let sceneList = ref([]);
let splitIndex = ref(null);
let spaceList = ref([]);
let authorityList = ref([]);
const getUserInfo = function () {
  let _user = {};
  if (userModel.user.value.userName === undefined) {
    let user = JSON.parse(localStorage.getItem('KC_USER'));
    if (user === null) {
      router.replace({
        path: '/'
      });
    } else {
      _user = user;
      userModel.updateUser(user);
    }
  } else {
    _user = userModel.user.value;
  }
  return _user;
};
const loginUserInfo = computed(() => {
  var _userModel$user$value;
  let user = JSON.parse(localStorage.getItem('KC_USER'));
  if ((_userModel$user$value = userModel.user.value) !== null && _userModel$user$value !== void 0 && _userModel$user$value.userName) {
    user = userModel.user.value;
  }
  return user;
});
window.sceneList = sceneList;
const customSort = function (a, b) {
  if (loginUserInfo.value.userId === undefined) {
    getUserInfo();
  }
  let _currUserId = loginUserInfo.value.userId;
  if (a.createdBy === _currUserId && b.createdBy !== _currUserId) {
    return -1;
  } else if (a.createdBy !== _currUserId && b.createdBy === _currUserId) {
    return 1;
  } else if (b.createdDate !== null && a.createdDate !== null) {
    return b.createdDate.localeCompare(a.createdDate);
  } else {
    return 1;
  }
};
const findSplitLine = function (list) {
  let value = null;
  if ((list === null || list === void 0 ? void 0 : list.length) > 0) {
    list.some((item, index) => {
      if (item.createdBy !== loginUserInfo.value.userId) {
        value = index;
        return true;
      }
      return false;
    });
  }
  return value;
};
const loadProject = function (group, callback) {
  projectList.value = [];
  /* return  ProjectService.getAll().then(result => {
      if (result && result.length > 0) {
        projectList.value = result;
        projectGroupList.value = loadGroupProject(result);
      }
      return result;
    });*/

  ProjectService.getAll().then(result => {
    if (result && result.length > 0) {
      projectList.value = result.sort(customSort);
      splitIndex.value = findSplitLine(projectList.value);
      projectGroupList.value = loadGroupProject(result);
    } else {
      projectList.value = result;
      let _obj = {};
      _obj[Kf.t('Default')] = [];
      projectGroupList.value = _obj;
    }
    if (group) {
      contentModel.currGroup.value = group;
    }
    if ((result === null || result === void 0 ? void 0 : result.length) > 0) {
      let _projectId = localStorage.getItem('currUIProject');
      if (_projectId) {
        let _project = result.find(i => {
          return i.uuid == _projectId;
        });
        if (_project) {
          contentModel.currLeftContent.value = JSON.parse(JSON.stringify(_project));
          localStorage.setItem('currUIProject', '');
        } else {
          contentModel.currLeftContent.value = JSON.parse(JSON.stringify(projectList.value[0]));
        }
      } else {
        contentModel.currLeftContent.value = JSON.parse(JSON.stringify(projectList.value[0]));
      }
    }
    if (callback) {
      callback(result);
    }
  });
};
const loadGroupProject = function (project) {
  projectGroupList.value = {};
  let obj = {};
  obj[Kf.t('Default')] = [];
  if (project.length > 0) {
    project.forEach(item => {
      if (item.groups !== undefined && item.groups && item.groups !== "") {
        if (obj.hasOwnProperty(item.groups)) {
          obj[item.groups].push(item);
        } else {
          obj[item.groups] = [];
          obj[item.groups].push(item);
        }
      } else {
        obj[Kf.t('Default')].push(item);
      }
    });
  }
  return obj;
  //projectGroupList.value=obj;
};
const loadVideoList = function (item) {
  videoList.value = [];
  if (item !== null && item !== void 0 && item.category) {
    ProjectService.getVideoList(item.category).then(result => {
      if (result) {
        videoList.value = result;
      }
      loadingData.value = false;
    });
  }
};
// 获取空间列表
const loadSpaceList = function (project, category, isSearchFloor) {
  spaceList.value = [];
  spaceService.getBuildings(project.uuid, category).then(async res => {
    spaceList.value = res;
    if (isSearchFloor) {
      for (const item of spaceList.value) {
        item.children = await spaceService.getFloors(project.uuid, item.buildingId);
      }
      loadingData.value = false;
    } else {
      loadingData.value = false;
    }
  });
};
const loadAuthorityList = function (project) {
  authorityList.value = [];
  if (project !== null && project !== void 0 && project.uuid) {
    authorityService.getProjectRole(project.uuid).then(res => {
      console.log("---getProjectRole", res);
      authorityList.value = res;
    });
  }
};
const switchProject = function (project, item, category, flag, callback) {
  // debugger
  if ((project === null || project === void 0 ? void 0 : project.uuid) !== undefined) {
    // loadingData.value = true;
    isTronThemeDetails.value = false;
    if (contentModel.currMenu.value.code === 'tutorial') {
      currProject.value = {};
      loadVideoList(item);
    } else {
      currProject.value = project;
      if (contentModel.currHeaderTab.value.code === 'space') {
        if (item && (item.category != null || item.flag != null)) {
          loadSpaceList(project, item.category, item.flag);
        } else {
          loadSpaceList(project);
        }
      } else if (contentModel.currHeaderTab.value.code === 'permissions') {
        loadAuthorityList(project);
      }
    }
  }
};
const selectProject = function (project) {
  currProject.value = project;
  let params = {
    "type": "building",
    "projectId": project.uuid
  };
  window.Kingfisher.FileTools.RegisterSchemes({
    'root://': ProjectService.getUrl('/cloud/api/repo/kf-space/find-file?path=' + project.uuid)
  });
  ProjectService.findAreaPurpose(project.uuid).then(result => {
    if (result) {
      areaOptionsData.value = result.data || [
        // {name: '地板', states: [{name: '默认', color: '#7F7F7F', style: '样式一'}]},
        // {name: '公共区域', states: [{name: '默认', color: '#FFC110', style: '样式一'}]}
      ];
    }
  });
  return ProjectService.getSpace(params).then(result => {
    if (result && result.length > 0) {
      sceneList.value = result;
    } else {
      sceneList.value = [];
    }
    return sceneList;
  });
};
const getNameMaxNumber = function (list, name, newName) {
  let key = 'name';
  if (name) {
    key = name;
  }
  let num = 0;
  const extractNumber = str => {
    const matches = str.match(/\d+/g);
    return matches ? parseInt(matches[0], 10) : 0;
  };
  if ((list === null || list === void 0 ? void 0 : list.length) > 0) {
    num = list.reduce((max, item) => {
      let _name = item[key];
      if (newName !== undefined) {
        _name = _name.substring(newName.length);
      }
      const number = extractNumber(_name);
      return number > max ? number : max;
    }, 0);
  }
  return num + 1;
};
export const getNameByCopy = function (name, dataList) {
  let copyStr = '-' + Kf.t('Copy5');
  let newName = name + copyStr;
  if ((dataList === null || dataList === void 0 ? void 0 : dataList.length) > 0) {
    let list = dataList.filter(item => {
      return item.name === newName || item.name.indexOf(newName + '_') > -1;
    });
    if ((list === null || list === void 0 ? void 0 : list.length) > 0) {
      newName = newName + '_' + getNameMaxNumber(list, 'name', newName);
    }
  }
  return newName;
};
export default {
  projectList,
  projectGroupList,
  currProject,
  loadProject,
  selectProject,
  getNameByCopy,
  currScene,
  getNameMaxNumber,
  sceneList,
  loadGroupProject,
  splitIndex,
  switchProject,
  spaceList,
  loadingData,
  videoList,
  loadAuthorityList,
  authorityList
};