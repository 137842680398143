class ImageDrawing3DBuilder {
  update(data) {
    let drawing = data.obj;
    if (!data.inited) {
      if (!drawing.finalVisible || !drawing.forceVisible) {
        return false;
      }
      data.inited = true;
      let mesh = Kingfisher.MeshBuilder.CreatePlane(drawing.name, {
        XZPlan: true,
        invertY: false
      }, data.scene);
      let mat = new Kingfisher.KMaterial(drawing.name, data.scene);
      mesh.material = mat;
      mat.transparencyMode = 3;
      let channel = new Kingfisher.KMatChannel(mat, {
        name: Kf.t('Texture1')
      });
      let light = new Kingfisher.KMatStageNoLight(mat, channel);
      // light.colorEmissive.copyFromFloats(0, 0, 0);

      let tex = new Kingfisher.KMatStageTexture(mat, channel, {});
      tex.colorTarget = 1;
      tex.alphaTarget = 0;
      tex.amountEnable = true;
      data.mesh = mesh;
      data.material = mat;
      data.texture = tex;
      data.dispose = function () {
        data.mesh.dispose();
        data.material.dispose();
        data.texture.dispose();
      };
    }
    data.mesh.enableState = drawing.finalVisible && drawing.forceVisible && !drawing.isDeleted;
    if (drawing.finalVisible && drawing.forceVisible) {
      let mat = data.material;
      mat.alpha = drawing.opacity;
      let tex = data.texture;
      tex.colorTarget = Kingfisher.KMatTarget.colorMask;
      tex.alphaTarget = Kingfisher.KMatTarget.alpha;
      tex.url = drawing.url;
      tex.amount = drawing.amount;
      tex.invertColor = drawing.invertColor;
      tex.premultAlpha = false;
      if (!mat.context) {
        return false;
      }
      if (!tex.sampler || !tex.sampler.texture || !tex.sampler.texture.isReady() || tex.url !== tex.sampler.texture.url) {
        return false;
      }
      let size = tex.sampler.texture.getSize();
      let mesh = data.mesh;
      let scaling = drawing.scaling;
      mesh.scaling.x = size.width * scaling;
      mesh.scaling.z = size.height * scaling;
      mesh.position.x = -drawing.centerX;
      mesh.position.z = -drawing.centerY;
    }
    return true;
  }
}
export default new ImageDrawing3DBuilder();