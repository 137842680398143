import _defineProperty from "F:/workspace/\u7A7A\u95F4\u6CBB\u7406\u5DE5\u5177/\u7A7A\u95F4\u6CBB\u7406CAD/space-management/spaceManager/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { Vector3 } from "@/maths/vector";
import { matrix2DIsIdentity, transform2DPoint, transform2DVector } from "@/maths/vector";
import { registerSMType, SMObject } from "@/objects/smObject";
import { serializeObject, updateProperties } from "./smObject";
import { currData } from "@/model/globalData";
import { smDatas } from "@/model/smCollectionData";
import { SMDirtyPosition } from "./smDirtyFlag";
class Model extends SMObject {
  constructor(collection, options) {
    super(collection, options);
    _defineProperty(this, "segment", void 0);
    _defineProperty(this, "position", new Vector3(0, 0, 0));
    _defineProperty(this, "rotation", new Vector3(0, 0, 0));
    _defineProperty(this, "scaling", new Vector3(1, 1, 1));
    _defineProperty(this, "_showName", true);
    _defineProperty(this, "_showCustomId", false);
    _defineProperty(this, "_isPickable", true);
    _defineProperty(this, "boundingBox", [0, 0, 0, 0, 0, 0]);
    _defineProperty(this, "size", [0, 0, 0]);
    _defineProperty(this, "subType", void 0);
    _defineProperty(this, "modelId", void 0);
    _defineProperty(this, "modelName", void 0);
    _defineProperty(this, "_keepRatio", false);
    _defineProperty(this, "attached", void 0);
    _defineProperty(this, "deviceLost", false);
    this.type = 'model';
    this.subType = options.subType;
    this.drawingTexts = options.drawings ? options.drawings : [];
  }
  set name(value) {
    if (this._name === value) {
      return;
    }
    this._name = value;
    this.makeDirty();
  }
  get name() {
    return this._name;
  }
  set deviceId(value) {
    if (this._deviceId === value) {
      return;
    }
    this._deviceId = value;
    this.deviceLost = false;
    this.makeDirty();
  }
  get deviceId() {
    return this._deviceId;
  }
  set floor(value) {
    if (this._floor === value) {
      return;
    }
    super.floor = value;
  }
  get floor() {
    return this._floor;
  }
  set mirrored(value) {
    if (!value === this.scaling.x < 0) {
      this.scaling.x *= -1;
      this.updateBoundingBox();
      this.makeDirty();
    }
  }
  get mirrored() {
    return this.scaling.x < 0;
  }
  set showName(value) {
    if (this._showName === value) {
      return;
    }
    this._showName = value;
    this.makeDirty();
  }
  set showCustomId(value) {
    if (this._showCustomId === value) {
      return;
    }
    this._showCustomId = value;
    this.makeDirty();
  }
  get showName() {
    return this._showName;
  }
  get showCustomId() {
    return this._showCustomId;
  }
  get isPickable() {
    return this._isPickable;
  }
  set isPickable(value) {
    this._isPickable = value;
  }
  get keepRatio() {
    return this._keepRatio;
  }
  set keepRatio(value) {
    if (this._keepRatio === value) {
      return;
    }
    this._keepRatio = value;
    if (value) {
      let scaling = Math.abs(this.scaling.x);
      this.scaling.y = this.scaling.z = scaling;
    }
  }
  get finalVisible() {
    if (!super.finalVisible) {
      return false;
    }
    let modelGroup = smDatas.modelsGroup.get(this.modelName);
    return !modelGroup || modelGroup.finalVisible;
  }
  transform(matrix) {
    if (matrix2DIsIdentity(matrix)) {
      return;
    }
    let position = {
      x: this.position.x,
      y: this.position.z
    };
    transform2DPoint(position, matrix);
    this.position.x = position.x;
    this.position.z = position.y;

    // let scaling = {x: this.scaling.x, y: this.scaling.z};
    // transform2DVector(scaling, matrix);
    // this.scaling.x = scaling.x;
    // this.scaling.z = scaling.y;
    //
    // this.updateBoundingBox();
    this.makeDirty();
  }
  updateBoundingBox() {
    let max = this.properties.originalBoundingInfo.max;
    let min = this.properties.originalBoundingInfo.min;
    let size = this.properties.originalBoundingInfo.size;
    let scaling = this.scaling;
    this.boundingBox = [Math.round(min.x * Math.abs(scaling.x)), Math.round(min.y * Math.abs(scaling.y)), Math.round(min.z * Math.abs(scaling.z)), Math.round(max.x * Math.abs(scaling.x)), Math.round(max.y * Math.abs(scaling.y)), Math.round(max.z * Math.abs(scaling.z))];
    this.size = [Math.round(size.x * Math.abs(scaling.x)), Math.round(size.y * Math.abs(scaling.y)), Math.round(size.z * Math.abs(scaling.z))];
  }
  setSegment(segment) {
    if (segment) {
      let wall = smDatas.objects[segment.wall];
      if (!wall || wall.type !== 'wall') {
        segment = null;
      }
    }
    if (this.segment === segment) {
      return;
    }
    if (this.segment) {
      this.segment.removeAccessory(this);
      this.parent = null;
    }
    this.segment = segment;
    if (segment) {
      let wall = smDatas.objects[segment.wall];
      this.parent = wall.nanoId;
      this.floor = wall.floor;
      if (segment) {
        segment.addAccessory(this);
      }
    }
    this.makeDirty();
  }
  makeDirty() {
    // this.updateBoundingBox();
    super.makeDirty();
    if (this.segment) {
      this.segment.makeDirty();
    }
  }
  setNeedSave() {
    super.setNeedSave();
    if (this.segment) {
      this.segment.setNeedSave();
    }
  }
  serialize() {
    let res = super.serialize();
    res.subType = this.subType;
    serializeObject(this, ['modelId', 'modelName', 'deviceId', 'position', 'rotation', 'scaling', 'size', 'boundingBox', 'showName', 'attached', 'showCustomId', 'drawingTexts', 'isPickable', 'keepRatio'], res.properties);
    res.properties.properties = this.properties;
    if (this.segment) {
      res.properties.segmentNub = smDatas.objects[this.segment.wall].segments.indexOf(this.segment);
    }
    return res;
  }
  updateProperties(data) {
    let changed = super.updateProperties(data);
    if (data && data.properties) {
      changed |= updateProperties(this, ['modelId', 'modelName', 'deviceId', 'position', 'rotation', 'scaling', 'size', 'boundingBox', 'showName', 'attached', 'showCustomId', 'drawingTexts', 'isPickable', 'keepRatio'], data.properties);
      this.properties = data.properties.properties;
      let parent = smDatas.objects[this.parent];
      if (parent && parent.segments && data.properties.segmentNub !== undefined) {
        this.setSegment(parent.segments[data.properties.segmentNub]);
      } else {
        this.setSegment(null);
      }
    }
    if (changed) {
      this.makeDirty();
    }
    return changed;
  }
  dispose() {}
}
registerSMType('model', Model);
export { Model };