import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array-buffer.detached.js";
import "core-js/modules/es.array-buffer.transfer.js";
import "core-js/modules/es.array-buffer.transfer-to-fixed-length.js";
import { base64ArrayBuffer } from '../utils/tools';
import projectService from "../service/project";
import projectModel from "@/model/project";
let importAssets = {};
const importAsset = function (asset) {
  if (!asset) {
    return;
  }
  let url = asset.url;
  if (url) {
    importAssets[url] = asset.data;
  } else {
    url = Kingfisher.FileTools.RegisterImportAssets(asset.data, asset.dir, asset.type);
    importAssets[url] = Kingfisher.FileTools.GetImportAssets(url).data;
  }
  return url;
};
const saveAllAssets = function (callback, failCallBack) {
  if (!Object.keys(importAssets).length) {
    if (callback) {
      callback();
    }
    return;
  }
  let assets = [];
  for (let key in importAssets) {
    assets.push({
      name: key,
      // content: data
      content: importAssets[key] instanceof ArrayBuffer ? base64ArrayBuffer(importAssets[key]) : importAssets[key]
    });
  }
  let promise = projectService.saveSceneAssets(projectModel.currProject.value.uuid, assets);
  promise.then(() => {
    importAssets = {};
    if (callback) {
      callback();
    }
  }).catch(e => {
    failCallBack(e);
  });
  return promise;
};
export { importAsset, saveAllAssets };