import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  slot: "footer",
  class: "dialog-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_card = _resolveComponent("el-card");
  const _component_el_main = _resolveComponent("el-main");
  const _component_el_container = _resolveComponent("el-container");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_dialog, {
    "custom-class": "kingfisher_dialog_create",
    title: _ctx.$t('About'),
    modelValue: $setup.showDialog,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $setup.showDialog = $event),
    width: "540px",
    top: "200px",
    "close-on-click-modal": false
  }, {
    footer: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_createVNode(_component_el_button, {
      type: "primary",
      onClick: _cache[0] || (_cache[0] = $event => $setup.showDialog = false)
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('ClickOkToConfirm2')), 1)]),
      _: 1
    })])]),
    default: _withCtx(() => [_createVNode(_component_el_container, null, {
      default: _withCtx(() => [_createVNode(_component_el_main, {
        style: {
          "margin-top": "0.625rem"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_card, {
          class: "box-card"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_row, {
            style: {
              "margin-bottom": "30px"
            }
          }, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 6,
              style: {
                "text-align": "right"
              }
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('CurrentVersion')), 1)]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 16,
              style: {
                "padding-left": "22px"
              }
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString($setup.version), 1)]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_row, {
            style: {
              "margin-bottom": "30px",
              "margin-top": "0.625rem"
            }
          }, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 6,
              style: {
                "text-align": "right"
              },
              title: $setup.packTime
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('UpdateTime')), 1)]),
              _: 1
            }, 8, ["title"]), _createVNode(_component_el_col, {
              span: 16,
              style: {
                "padding-left": "22px"
              }
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString($setup.updateTime), 1)]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }, 8, ["title", "modelValue"])]);
}