import { ref } from 'vue';
import tools from "@/utils/tools";
export default {
  name: "model",
  setup() {
    let modelSearch = ref('');
    let activeName = ref('0');
    let modelTypeList = ref([{
      name: Kf.t('ComponentCategory')
    }, {
      name: Kf.t('DeviceCategory')
    }, {
      name: Kf.t('PipelineCategory')
    }, {
      name: Kf.t('EnergyConsumptionCategory')
    }, {
      name: Kf.t('ElectricityCategory')
    }, {
      name: Kf.t('FanCategory')
    }]);
    return {
      modelSearch,
      activeName,
      modelTypeList
    };
  }
};