import { registerGlobalMouseEvent } from "@/event/globalDom";
import { copySceneObj, deleteSceneObj, pasteSceneObj } from "@/ui-dispatch/scenObj";
class GlobalKeyDown {
  KeyC(event) {
    if (!event.ctrlKey) {
      return;
    }
    //copySceneObj()
  }
  KeyV(event) {
    if (!event.ctrlKey) {
      return;
    }
    // pasteSceneObj()
  }
  Delete() {
    deleteSceneObj();
  }
  dispatch(eventName, event) {
    let name = event.code;
    if (this[name]) {
      this[name](event);
    }
  }
}
let globalKeyDown = new GlobalKeyDown();
export const registerGlobalKeydownEvent = function () {
  //registerGlobalMouseEvent('global.keydown', globalKeyDown.dispatch.bind(globalKeyDown));
};