import { buildWallMesh } from "./smWallBuildTools";
import { smDatas } from "@/model/smCollectionData";
import { smMesh3DCache } from "@/model/smCollectionData";
import { currData } from "@/model/globalData";
class SmWall3DBuilder {
  update(data) {
    let wall = data.obj;
    if (!data.inited) {
      if (!wall.finalVisible) {
        return false;
      }
      data.inited = true;

      //wall init
      let wallMesh = new Kingfisher.Mesh(wall.name, data.scene, null, null, false, true, {
        id: wall.nanoId
      });
      // wallMesh.renderingGroupId = 1;
      wallMesh.receiveShadows = true;
      wallMesh.castShadows = true;
      wallMesh.scalingDeterminant = 0.001;
      let wallGeometry = new Kingfisher.Geometry('geo' + wall.nanoId, data.scene, undefined, true, wallMesh);
      let wallMaterial = data.scene.getMaterialByName('wall');
      if (!wallMaterial) {
        wallMaterial = new Kingfisher.KMaterial('wall', data.scene);
        let wChannel = new Kingfisher.KMatChannel(wallMaterial);
        let wLight = new Kingfisher.KMatStageLight(wallMaterial, wChannel);
        wLight.colorBase.copyFromFloats(0.8, 0.8, 0.85);
        // wLight.opacity = 0.8;

        // wallMaterial.transparencyMode = 3;
        //wallMaterial.wireframe = 1;
      }
      wallMesh.material = wallMaterial;
      data.highlightMeshes = [wallMesh];
      data.wallMesh = wallMesh;
      data.wallMaterial = wallMaterial;
      data.wallGeometry = wallGeometry;
      data.pickMeshes = [wallMesh];
      data.focusObject = wallMesh;
      data.dispose = function () {
        data.wallMesh.dispose();
        // data.wallMaterial.dispose();
        data.wallGeometry.dispose();
      };
    }
    if (!wall.finalVisible) {
      data.wallMesh.enableState = false;
      return false;
    } else {
      data.wallMesh.enableState = true;
    }
    data.wallMesh.name = data.obj.name + '(Wall)';
    data.wallMesh.material = data.scene.getMaterialByName('wall-' + wall.purpose) || data.scene.getMaterialByName('wall');
    data.wallMesh.position.y = ((smDatas.objects[wall.floor] ? smDatas.objects[wall.floor].elevation : 0) || 0) * 0.001;
    buildWallMesh(wall);
    smMesh3DCache[wall.nanoId].toVertexData().applyToGeometry(data.wallGeometry);
    if (currData.wall === wall) {
      sm3DMeshBuilder.updateSelectPoint();
    }
    return true;
  }
}
export default new SmWall3DBuilder();