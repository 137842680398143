import { ref, watch } from 'vue';
import env from '@/model/env';
import { hotKey } from "@/model/menu";
export default {
  name: "HotKey",
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {
    emit
  }) {
    let showDialog = ref(false);
    watch(props, newValue => {
      showDialog.value = newValue.show;
    });
    watch(showDialog, () => {
      emit("propChange", showDialog.value);
    });
    return {
      showDialog,
      updateTime: env.updateTime,
      version: env.version,
      hotKey
    };
  }
};