import { smDatas } from "@/model/smCollectionData";
import { getModelByName } from "@/model/3Dmodel";
class Model3DBuilder {
  update(data) {
    let loader;
    let obj = data.obj;
    let scene = data.scene;
    if (data.modelName && data.modelName !== obj.modelName) {
      data.dispose();
    }
    data.inited = true;
    if (!data.loader) {
      data.modelName = obj.modelName;
      let model = getModelByName(obj.modelName);
      let modelId = model ? model.k3dcId : '';
      if (modelId) {
        if (!scene.getKPartByID(modelId)) {
          // let item = getModelById(obj.modelId);
          // if (!item) {
          //     return false;
          // }
          let part = new Kingfisher.KPart(scene, {
            id: modelId
          });
          let partData = {
            // name: item.name,
            // category: item.category,
            // attribute: item.attribute,
            // type: item.type,
            url: '$k3dc://parts/' + modelId + '.k3dc'
          };
          part.updateProperties(partData);
        }
        let meshCheck = () => {
          for (let mesh of data.pickMeshes) {
            let material = mesh.material;
            if (material && material.getClassName() === 'KMaterial') {
              if (!material._toPbr) {
                let mat = new Kingfisher.PBRMaterial(mesh.name, scene);
                let light = material.channels[0].stageLight;
                mat.albedoColor = light.colorBase.clone();
                mat.metallic = light.metallic || 0.8;
                mat.roughness = light.roughness || 0.4;
                material._toPbr = mat;
              }
              mesh.material = material._toPbr;
              if (!material.meshes.length) {
                material.dispose();
              }
            }
          }
        };
        loader = new Kingfisher.KPartLoader(obj.name, scene, undefined, {
          resourceId: modelId,
          id: obj.nanoId
        });
        if (loader.resource.isReady()) {
          data.highlightMeshes = loader.getChildMeshes();
          data.pickMeshes = data.highlightMeshes;
          meshCheck();
          for (let n of loader.childrenNode || []) {
            n.name = obj.name + '(' + obj.modelName + ')';
          }
        } else {
          loader.resource.onLoadObservable.addOnce(() => {
            data.highlightMeshes = loader.getChildMeshes();
            data.pickMeshes = data.highlightMeshes;
            meshCheck();
            for (let n of loader.childrenNode || []) {
              n.name = obj.name + '(' + obj.modelName + ')';
            }
          });
        }
        data.modelError = false;
      } else {
        loader = Kingfisher.MeshBuilder.CreateBox(obj.name, {}, scene);
        loader.material = scene.errorMat;
        data.modelError = true;
      }
      data.loader = loader;
      data.dispose = function () {
        if (data.loader) {
          data.loader.dispose();
          data.loader = null;
          data.inited = false;
        }
      };
      data.selected = false;
      data.focusObject = loader;
    } else {
      loader = data.loader;
    }
    loader.name = obj.name;
    for (let n of loader.childrenNode || []) {
      n.name = obj.name + '(' + obj.modelName + ')';
    }
    loader.position.x = obj.position.x * 0.001;
    loader.position.y = (obj.position.y + (smDatas.objects[obj.floor] ? smDatas.objects[obj.floor].elevation : 0) || 0) * 0.001;
    loader.position.z = obj.position.z * 0.001;
    loader.rotation.copyFrom(obj.rotation);
    loader.scaling.copyFrom(obj.scaling);
    loader.scalingDeterminant = obj.properties.ratio * 0.001;
    if (data.modelError) {
      let boundingBox = obj.boundingBox;
      loader.position.x += (boundingBox[0] + boundingBox[3]) * 0.0005;
      loader.position.y += (boundingBox[1] + boundingBox[4]) * 0.0005;
      loader.position.z += (boundingBox[2] + boundingBox[5]) * 0.0005;
      loader.scaling.x = (boundingBox[3] - boundingBox[0]) * 0.001;
      loader.scaling.y = (boundingBox[4] - boundingBox[1]) * 0.001;
      loader.scaling.z = (boundingBox[5] - boundingBox[2]) * 0.001;
    }
    loader.enableState = obj.finalVisible;
    return true;
  }
}
export default new Model3DBuilder();