import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "kingfisher_props_container"
};
const _hoisted_2 = {
  class: "kingfisher_props_container2"
};
const _hoisted_3 = {
  class: "kingfisher_prop_label"
};
const _hoisted_4 = ["title"];
const _hoisted_5 = {
  class: "kingfisher_prop_con"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("span", {
    title: $props.props.name
  }, _toDisplayString($props.props.label.zh), 9, _hoisted_4)]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_el_select, {
    modelValue: $setup.valueObj.selectValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.valueObj.selectValue = $event),
    placeholder: $setup.valueObj.placeholder,
    onChange: $setup.doSelectChange,
    clearable: "",
    "popper-class": "kf-prop-option-box"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.props.options, item => {
      return _openBlock(), _createBlock(_component_el_option, {
        key: item.id,
        label: item.label || item.name,
        value: item
      }, null, 8, ["label", "value"]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue", "placeholder", "onChange"])])])]);
}