import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "kingfisher_prop_con prop_cons",
  style: {
    "width": "100%"
  }
};
const _hoisted_2 = ["title"];
const _hoisted_3 = {
  class: "kingfisher_prop_label"
};
const _hoisted_4 = ["title"];
const _hoisted_5 = {
  class: "kingfisher_prop_label",
  style: {
    "position": "absolute"
  }
};
const _hoisted_6 = ["title"];
const _hoisted_7 = {
  class: "num_input_move_con kingfisher_prop_con"
};
const _hoisted_8 = ["title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  return _openBlock(), _createElementBlock(_Fragment, null, [_withDirectives(_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_input, {
    placeholder: $props.placeholder,
    disabled: $props.disabled,
    modelValue: $options.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.value = $event),
    ref: "ip",
    onInput: $options.input,
    onChange: $options.change,
    onFocus: $options.getInputFocus,
    onBlur: $options.lostFocus,
    onKeyup: _cache[1] || (_cache[1] = _withKeys($event => $options.getInputEnter($event), ["enter", "native"])),
    title: $props.description,
    clearable: ""
  }, {
    prepend: _withCtx(() => [$props.label ? (_openBlock(), _createElementBlock("span", {
      key: 0,
      title: $props.title
    }, _toDisplayString($props.label), 9, _hoisted_2)) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["placeholder", "disabled", "modelValue", "onInput", "onChange", "onFocus", "onBlur", "title"])], 512), [[_vShow, $options.modelType]]), _withDirectives(_createElementVNode("div", {
    class: "el-input input_box",
    onDblclick: _cache[2] || (_cache[2] = (...args) => $options.inputEdit && $options.inputEdit(...args)),
    onMousedown: _cache[3] || (_cache[3] = (...args) => $options.moveEvent && $options.moveEvent(...args))
  }, [_withDirectives(_createElementVNode("div", _hoisted_3, [_createElementVNode("span", {
    title: $props.title
  }, _toDisplayString($props.label), 9, _hoisted_4)], 512), [[_vShow, $props.label]]), _withDirectives(_createElementVNode("div", _hoisted_5, [_createElementVNode("span", {
    title: $props.unit,
    style: {
      "position": "relative",
      "right": "-182px"
    }
  }, _toDisplayString($props.unit), 9, _hoisted_6)], 512), [[_vShow, $props.unit]]), _createElementVNode("div", _hoisted_7, [_createElementVNode("span", {
    class: "num_input_move_cons",
    style: _normalizeStyle('cursor: e-resize; color:' + $props.color),
    title: $props.description
  }, _toDisplayString($options.value), 13, _hoisted_8)])], 544), [[_vShow, $options.modelType === false]])], 64);
}