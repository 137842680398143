import _defineProperty from "F:/workspace/\u7A7A\u95F4\u6CBB\u7406\u5DE5\u5177/\u7A7A\u95F4\u6CBB\u7406CAD/space-management/spaceManager/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import { SMDirtyAll } from './smDirtyFlag';
import { registerSMType, serializeObject, updateProperties, SMObject } from "./smObject";
import { smDatas } from "../model/smCollectionData";
class DevicePoint extends SMObject {
  constructor(collection, options) {
    super(collection, options);
    _defineProperty(this, "_floor", void 0);
    this.floor = options.floor;
    this.type = 'devicePoint';
    this.deviceModelList = new Set();
    this.deviceName = options.deviceName;
    this.subType = options.subType;
    // this.snCode = options.snCode;
    this.connUuid = options.connUuid;
    this.deviceId = options.deviceId;
    this.deviceMapId = options.deviceMapId;
    this.deviceType = options.deviceType;
    this.deviceTypeCode = options.deviceTypeCode;
    this.name = options.name;
    this.deviceBuilding = options.building;
    this.provider = options.provider;
    // this.sn = options.sn;
    this.uuid = options.deviceId;
    if (options.connUuid && options.deviceTypeCode) {
      let tslTemplateMap = collection.connectionTslTemplateMap;
      let model = tslTemplateMap && tslTemplateMap[options.connUuid] && tslTemplateMap[options.connUuid].modelMap[options.deviceTypeCode];
      if (model) {
        this.spaceComponent = model.spaceComponent;
        this.spaceComponentName = model.spaceComponentName;
      }
    }
  }
  makeDirty(flag = SMDirtyAll) {
    if (this.wallSegment) {
      this.wallSegment.makeDirty(flag);
    }
    super.makeDirty(flag);
  }
  serialize() {
    let res = super.serialize();
    res.deviceModelId = this.deviceModelId;
    res.subType = this.subType;
    res.deviceId = this.deviceId;
    serializeObject(this, ['deviceModelId'], res.properties);
    return res;
  }
  updateProperties(data) {
    let changed = super.updateProperties(data);
    if (data && data.properties) {
      changed |= updateProperties(this, ['deviceModelId'], data.properties);
      this.properties = data.properties.properties;
    }
    if (changed) {
      this.makeDirty();
    }
    return changed;
  }
  dispose() {
    console.error("dispose");
  }
  setNeedSave() {
    super.setNeedSave();
  }
}
export { DevicePoint };