import "core-js/modules/es.array.push.js";
import { computed, ref, watch } from 'vue';
import pageModel from '../../model/page';
export default {
  name: "sceneProp",
  props: {
    props: {
      type: Object,
      default: () => {
        return {};
      }
    },
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  setup(props, {
    emit
  }) {
    let value = ref(pageModel.getPropValue(props.model, props.props.name));
    const doSelectChange = function (val) {
      emit("propChange", {
        name: props.props.name,
        value: val
      });
    };
    const selectOptions = computed(() => {
      let result = [];
      if (pageModel.scenes.value) {
        pageModel.scenes.value.forEach(function (scene) {
          result.push({
            label: scene.name,
            value: scene.projectId + "/" + scene.uuid
          });
        });
      }
      return result;
    });
    watch(props, (newValue, oldValue) => {
      value.value = pageModel.getPropValue(props.model, props.props.name);
    });
    return {
      value,
      doSelectChange,
      selectOptions
    };
  }
};