import {nextTick, ref, toRaw} from 'vue'
import {getNanoID} from '@/utils/tools';
import {SMCollection} from "@/builder/smCollection";
import projectService from "../service/project";
import {Building} from "@/objects/smBuilding";
import projectModel from "@/model/project";
import {importAsset, saveAllAssets} from "@/utils/importAssets";
import {ElMessage} from 'element-plus'
import {globalNeedSave, globalState, viewPortData} from './globalData';
import {file} from "../components/mainContent/rightPanel";
import { ElLoading } from 'element-plus'
import {
    initDevicePointBindingState
} from "@/components/mainContent/rightPanel/point";
import ModelService from "@/service/model";
import {selectedThemeId, themeList} from "@/model/3Dmodel";

let loading ;

let _saving = false;
const smDatas = new SMCollection();
const smMesh2DCache = {};
const smMesh3DCache = {};
const smDxfCache = {};
window.smCollection = smDatas;
window.smMesh3DCache = smMesh3DCache;
window.smDxfCache = smDxfCache;

export const createSceneBtnLoading = ref(false);
export const createSceneDialog = ref(false);
const createBuilding = function (options) {
    undoMap.clean();
    smDatas.clean();
    smDatas.buildingProxy.value = new Building(smDatas, options);
    smDatas.building.updateProperties(options);
    globalNeedSave.value = true;
    saveSpace(smDatas.building);
    return smDatas.building;
}

const loadBuilding = async (building) => {
    return new Promise(async (resolve, reject) => {
        console.log(building);

        if (!building || (smDatas.building && building.uuid === smDatas.building.uuid)) {
            resolve();
        }

        let params = {
            "buildingId": building.nanoId,
            "projectId": projectModel.currProject.value.uuid,
        };

        undoMap.clean();
        smDatas.clean();
        smDatas.buildingProxy.value = new Building(smDatas, building);
        smDatas.building.updateProperties(building);

        smDatas.connectionTslTemplateMap = {};

        let connectedList = await projectService.getConnectedList(projectModel.currProject.value.uuid);
        if (connectedList) {
            let kParts = await ModelService.getAllKFPart();
            let kPartMap = {};
            if (kParts) {
                for (let k of kParts) {
                    kPartMap[k.uuid] = k;
                }
            }
            for (let c of connectedList) {
                let temp = await projectService.getConnectionTslTemplate(c.connId);
                if (temp) {
                    temp.modelMap = {};
                    for (let m of temp.models || []) {
                        temp.modelMap[m.name] = m;
                        if (kPartMap[m.spaceComponent]) {
                            m.spaceComponentName = kPartMap[m.spaceComponent].name;
                        }
                    }
                    smDatas.connectionTslTemplateMap[c.connId] = temp;
                }
            }
        }

        let devicePoints = await projectService.getStaticDevice({"projectId": projectModel.currProject.value.uuid});
        if (devicePoints) {
            for (let r of devicePoints) {
                smDatas.createDevicePoint(r);
            }
        }

        let result = await projectService.findSpace(params);
        if (result) {
            let objectsByType = {
                building: [],
                floor: [],
                drawing: [],
                wall: [],
                area: [],
                pipeline: [],
                model: [],
                devicePoint: []
            };
            for (let r of result) {
                let type = r.type;
                if (type.indexOf('Drawing') > 0) {
                    type = 'drawing';
                }
                let typeArray = objectsByType[type];
                if (typeArray) {
                    typeArray.push(r);
                } else {
                    console.error('not support type = ' + r.type, r);
                }
            }
            for (let t of ['building', 'floor', 'drawing', 'wall', 'area', 'pipeline', 'model']) {
                for (let o of objectsByType[t]) {
                    if (o.subType === 'desk' || o.subType === 'chair') {
                        o.subType = 'furniture';
                    }
                    smDatas.createObject(o);
                }
            }

            for (let data of objectsByType['building']) {
                if (data.properties && data.properties.ui) {
                    try {
                        let uiData = JSON.parse(data.properties.ui);
                        if (uiData.currData) {
                            nextTick(() => {
                                currData.setCurrList(uiData.currData.objects.map(x => smDatas.objects[x]).filter(x => x));
                            })
                        }
                    } catch (e) {
                        console.error(e, data);
                    }
                }
            }
        }

        initDevicePointBindingState();

        let themeId = smDatas.building.themeId || -1;
        if (!themeList.value.find(x => x.uuid === themeId)) {
            themeId = (themeList.value[0] || {uuid: null}).uuid;
        }
        selectedThemeId.value = null;
        selectedThemeId.value = themeId;
        resolve();
    });
}

const saveAll = function (cbSuccess, cbError) {
    if (_saving) {
        ElMessage({type: 'success', message: Kf.t('SavingPleaseWait'),group:true});
        console.error(Kf.t('SavingPleaseWait'));
        return;
    }
    if (!globalNeedSave.value) {
        ElMessage({type: 'info', message: Kf.t('NoDataChangesNeedToBeSaved'),group:true});
        return;
    }
    _saving = true;

    let objs = [];
    objs.push(smDatas.building);
    objs.push(...smDatas.floors);
    objs.push(...smDatas.drawings);
    objs.push(...smDatas.walls);
    objs.push(...smDatas.areas);
    objs.push(...smDatas.pipelines);
    objs.push(...smDatas.models);

    scene.getScreenshot((res) => {
        importAsset({data: res.substring(22), url: '/' + smDatas.building.nanoId + '.png'});
        saveAllAssets(() => {
            saveSpace(objs, () => {
                _saving = false;
                if (cbSuccess) {
                    cbSuccess();
                }
            },(e)=>{
                console.error(e);
                _saving = false;
                if (cbError) {
                    cbError(e);
                }
            });
        }, (e) => {
            console.error(e);
            _saving = false;
            if (cbError) {
                cbError(e);
            }
        });
    });
}

const saveSpace = function (objs, cbSuccess, cbError) {
    if (!objs) {
        if (cbSuccess) {
            cbSuccess();
        }
        return;
    }
    loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
    })
    if (!(objs instanceof Array)) {
        objs = [objs];
    }

    let prjUuid = projectModel.currProject.value.uuid;
    let buildingName = smDatas.building.name;
    let buildingId = smDatas.building.nanoId;

    let deleteObjList = [];
    let saveObjList = [];
    let updateObjList = [];

    let deleteList = [];
    let saveList = [];
    let updateList = [];

    let objMap = {};

    let check = function (obj) {
        if (!obj || objMap[obj.nanoId] || (!obj.needSave && obj.type !== 'building') || (obj.buildingId && obj.buildingId !== buildingId)) {
            return;
        }

        objMap[obj.nanoId] = true;

        if (obj.isDeleted) {
            if (obj.uuid) {
                deleteList.push(obj.uuid);
                deleteObjList.push(obj);
            }
            else {
                smDatas.remove(obj);
            }
        }
        else {
            if (obj.floor) {
                check(smDatas.objects[obj.floor]);
            }

            if (obj.parent) {
                check(smDatas.objects[obj.parent]);
            }

            let params = obj.serialize();
            if (!params.buildingId) {
                params.buildingId = buildingId;
            }
            params.projectId = prjUuid;

            if (!obj.uuid) {
                saveList.push(params);
                saveObjList.push(obj);
            }
            else {
                updateList.push(params);
                updateObjList.push(obj);
            }
        }
    }

    for (let i = 0; i < objs.length; i++) {
        check(objs[i]);
    }

    let promiseArray = [];
    if (deleteList.length) {
        promiseArray.push(projectService.deleteSpace(deleteList));
    }

    if (saveList.length) {
        promiseArray.push(projectService.saveSpace(saveList));
    }

    if (updateList.length) {
        promiseArray.push(projectService.updateSpace(updateList));
    }

    if (promiseArray) {
        Promise.all(promiseArray).then(result => {
            let message0 = [];
            let message1 = [];
            let r = 0;
            if (deleteList.length) {
                if (result[r].code === '200') {
                    for (let i = 0; i < deleteObjList.length; i++) {
                        deleteObjList[i].needSave = false;
                        smDatas.remove(deleteObjList[i]);
                    }
                    message0.push(Kf.t('Delete')+`${deleteObjList.length}`+Kf.t('Single'));
                }
                else {
                    message1.push(Kf.t('Delete')+`${deleteObjList.length}`+Kf.t('Single'));
                }
                r ++;
            }
            if (saveObjList.length) {
                if (result[r].code === '200') {
                    for (let i = 0; i < saveObjList.length; i++) {
                        saveObjList[i].uuid = result[r].data[i];
                        saveObjList[i].needSave = false;
                        if (saveObjList[i].type === 'building') {
                            createSceneDialog.value = false;
                            createSceneBtnLoading.value = false;

                            let res = {
                                "building": saveObjList[i].name,
                                "deviceType": "",
                                "data": saveObjList[i].serialize(),
                                "floor": "",
                                "grouping": "building",
                                "name": saveObjList[i].name,
                                "positionText": "",
                                "projectId": saveObjList[i].projectId,
                                "uuid": saveObjList[i].uuid,
                                "nanoId": saveObjList[i].nanoId
                            };
                            projectModel.sceneList.value.unshift(res);
                            projectModel.currScene.value = res;
                            console.log("create new scene: ", res);
                        }
                    }
                    message0.push(Kf.t('Save')+`${saveObjList.length}`+Kf.t('Single'));
                }
                else {
                    message1.push(Kf.t('Save')+`${saveObjList.length}`+Kf.t('Single'));
                }
                r ++;
            }
            if (updateObjList.length) {
                if (result[r].code === '200') {
                    for (let i = 0; i < updateObjList.length; i++) {
                        updateObjList[i].needSave = false;
                    }
                    message0.push(Kf.t('Update')+`${updateObjList.length}`+Kf.t('Single'));
                }
                else {
                    message1.push(Kf.t('Update')+`${updateObjList.length}`+Kf.t('Single'));
                }
                r ++;
            }
            if (message0.length) {
                ElMessage({type: 'success', message: message0.join(Kf.t('Comma')) + Kf.t('Success'),group:true});
                smDatas.needSave = false;
            }
            if (message1.length) {
                ElMessage({type: 'error', message: message1.join(Kf.t('Comma')) + Kf.t('Failed'),group:true});
                console.log(result);
            }

            globalNeedSave.value = false;

            if (cbSuccess) {
                cbSuccess();
            }
            loading.close();
        }).catch(result => {
            ElMessage({type: 'error', message: Kf.t(`SaveFailed`),group:true});
            console.log(result);
            loading.close();
            if (cbError) {
                cbError(result);
            }
        });
    }
}

const saveAs = function (options = {}, cbSuccess, cbError) {
    if (_saving) {
        console.error(Kf.t('SavingPleaseWait'));
        return;
    }
    _saving = true;

    let objs = [];
    options.buildingId = getNanoID();
    objs.push(smDatas.building);
    objs.push(...smDatas.floors);
    objs.push(...smDatas.drawings);
    objs.push(...smDatas.walls);
    objs.push(...smDatas.areas);
    objs.push(...smDatas.pipelines);
    objs.push(...smDatas.models);

    scene.getScreenshot((res) => {
        importAsset({data: res.substring(22), url: '/' + options.buildingId + '.png'});
        saveAllAssets(() => {
            saveSpaceAs(options, objs, (res) => {
                _saving = false;
                if (cbSuccess) {
                    cbSuccess(res);
                }
            }, (err) => {
                _saving = false;
                if (cbError) {
                    cbError(res);
                }
            });
        });
    });
}

const saveSpaceAs = function (options, objs, cbSuccess, cbError) {

    if (!objs) {
        if (cbSuccess) {
            cbSuccess();
        }
        return;
    }
    loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
    })
    if (!(objs instanceof Array)) {
        objs = [objs];
    }

    let prjUuid = projectModel.currProject.value.uuid;
    let buildingId = options.buildingId;
    let buildingName = options.name ? options.name : (smDatas.building.name + '-'+Kf.t('Copy'));

    let saveObjList = [];
    let saveList = [];

    let objMap = {};
    let idMap = {};

    let check = function (obj) {
        if (!obj || objMap[obj.nanoId]) {
            return;
        }

        objMap[obj.nanoId] = true;

        if (!obj.isDeleted) {
            if (obj.floor) {
                check(smDatas.objects[obj.floor]);
            }

            if (obj.parent) {
                check(smDatas.objects[obj.parent]);
            }

            let params = obj.serialize();
            params.uuid = undefined;
            params.buildingId = buildingId;
            params.projectId = prjUuid;

            if (params.type === 'building') {
                params.nanoId = buildingId;
                params.name = buildingName;
            }
            else {
                idMap[params.nanoId] = getNanoID();
                params.nanoId = idMap[params.nanoId];
            }
            saveList.push(params);
            saveObjList.push(obj);
        }
    }

    for (let i = 0; i < objs.length; i++) {
        check(objs[i]);
    }

    for (let params of saveList) {
        if (params.parentId) {
            params.parentId = idMap[params.parentId];
        }

        if (params.floorId) {
            params.floorId = idMap[params.floorId];
        }
    }

    let promiseArray = [];
    if (saveList.length) {
        promiseArray.push(projectService.saveSpace(saveList));
    }

    if (promiseArray) {
        Promise.all(promiseArray).then(result => {
            let message0 = [];
            let message1 = [];
            let r = 0;
            let sceneListValue;
            if (saveObjList.length) {
                if (result[r].code === '200') {
                    for (let i = 0; i < saveObjList.length; i++) {
                        saveList[i].uuid = result[r].data[i];
                        saveList[i].needSave = false;
                        if (saveList[i].type === 'building') {
                            createSceneDialog.value = false;
                            createSceneBtnLoading.value = false;

                            sceneListValue = {
                                "building": saveList[i].name,
                                "deviceType": "",
                                "data": saveList[i],
                                "floor": "",
                                "grouping": "building",
                                "name": saveList[i].name,
                                "positionText": "",
                                "projectId": saveList[i].projectId,
                                "uuid": saveList[i].uuid,
                                "nanoId": saveList[i].nanoId
                            };
                            projectModel.sceneList.value.unshift(sceneListValue);
                            projectModel.currScene.value = sceneListValue;
                            console.log("create new scene: ", sceneListValue);
                        }
                    }
                    message0.push(Kf.t('Save')+`${saveObjList.length}`+Kf.t('Single'));
                }
                else {
                    message1.push(Kf.t('Save')+`${saveObjList.length}`+Kf.t('Single'));
                }
                r ++;
            }
            if (message0.length) {
                ElMessage({type: 'success', message: message0.join(Kf.t('Comma')) +Kf.t('Success'),group:true});
                smDatas.needSave = false;
            }
            if (message1.length) {
                ElMessage({type: 'error', message: message1.join(Kf.t('Comma')) + Kf.t('Failed'),group:true});
                console.log(result);
            }

            globalNeedSave.value = false;

            if (cbSuccess) {
                cbSuccess(sceneListValue);
            }
            loading.close();
        }).catch(result => {
            ElMessage({type: 'error', message: Kf.t(`SaveFailed`),group:true});
            console.log(result);
            loading.close();
            if (cbError) {
                cbError(result);
            }
        });
    }
}

export {
    smDatas,
    smMesh2DCache,
    smMesh3DCache,
    smDxfCache,
    createBuilding,
    loadBuilding,
    saveSpace,
    saveAll,
    saveAs
}

