import { SMDirtyAll, SMDirtyPosition, SMDirtyReticular } from "./smDirtyFlag";
import { getUID, registerSMType, serializeObject, updateProperties, SMObject, getSMType } from "./smObject";
class Point3D {
  get xz() {
    return {
      x: this.x,
      y: this.z
    };
  }
  constructor(x, y, z) {
    Object.defineProperty(this, 'uid', {
      value: getUID()
    });
    this.x = x || 0;
    this.y = y || 0;
    this.z = z || 0;
  }
  distance(b) {
    let dx = this.x - b.x;
    let dy = this.y - b.y;
    let dz = this.z - b.z;
    return Math.sqrt(dx * dx + dy * dy + dz * dz);
  }
  updateProperties(data) {
    if (!data) {
      return false;
    }
    let x = data[0] || 0;
    let y = data[1] || 0;
    let z = data[2] || 0;
    if (x == this.x && y == this.y && z == this.z) {
      return false;
    }
    this.x = x;
    this.y = y;
    this.z = z;
    return true;
  }
  serialize() {
    return [this.x, this.y, this.z];
  }
  static Parse(data) {
    return new Point3D(data && data.x, data && data.y, data && data.z);
  }
}
registerSMType('Point3D', Point3D);
export { Point3D };