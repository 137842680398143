import _defineProperty from "F:/workspace/\u7A7A\u95F4\u6CBB\u7406\u5DE5\u5177/\u7A7A\u95F4\u6CBB\u7406CAD/space-management/spaceManager/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { ref } from 'vue';
import { registerSMType, serializeObject, SMObject, updateProperties } from "./smObject";
class Drawing extends SMObject {
  constructor(collection, options) {
    super(collection, options);
    _defineProperty(this, "loadingState", ref(0));
    _defineProperty(this, "loadingProgress", ref(0));
    this.type = 'drawing';
    this.centerX = options && options.centerX !== undefined ? options.centerX : 0;
    this.centerY = options && options.centerY !== undefined ? options.centerY : 0;
    this.scaling = options && options.scaling !== undefined ? options.scaling : 1;
    this.rotation = options && options.rotation !== undefined ? options.rotation : 0;
    this.opacity = options && options.opacity !== undefined ? options.opacity : 1;
  }
  set opacity(value) {
    if (this._opacity === value) {
      return;
    }
    this._opacity = value;
    this.makeDirty();
  }
  get opacity() {
    return this._opacity;
  }
  // ai 补齐，未验证
  getMatrix() {
    let centerX = this.centerX;
    let centerY = this.centerY;
    let scaling = this.scaling;
    let rotation = this.rotation;
    let cos = Math.cos(rotation);
    let sin = Math.sin(rotation);
    return [cos * scaling, -sin * scaling, sin * scaling, cos * scaling, -centerX, -centerY];
  }

  // ai 补齐，未验证
  positionToLocal(x, y) {
    let centerX = this.centerX;
    let centerY = this.centerY;
    let scaling = this.scaling;
    let rotation = this.rotation;
    let cos = Math.cos(rotation);
    let sin = Math.sin(rotation);
    let dx = x + centerX;
    let dy = y + centerY;
    let x1 = dx * cos + dy * sin;
    let y1 = -dx * sin + dy * cos;
    return {
      x: x1 / scaling,
      y: y1 / scaling
    };
  }

  // ai 补齐，未验证
  positionToWorld(x, y) {
    let centerX = this.centerX;
    let centerY = this.centerY;
    let scaling = this.scaling;
    let rotation = this.rotation;
    let cos = Math.cos(rotation);
    let sin = Math.sin(rotation);
    let x1 = x * scaling;
    let y1 = y * scaling;
    let dx = x1 * cos - y1 * sin;
    let dy = x1 * sin + y1 * cos;
    return {
      x: dx - centerX,
      y: dy - centerY
    };
  }

  // ai 补齐，未验证
  boundingBoxToWorld(boundingBox) {
    if (!boundingBox || !boundingBox.length) {
      return;
    }
    let centerX = this.centerX;
    let centerY = this.centerY;
    let scaling = this.scaling;
    let rotation = this.rotation;
    let cos = Math.cos(rotation);
    let sin = Math.sin(rotation);
    let x1 = boundingBox[0] * scaling;
    let y1 = boundingBox[1] * scaling;
    let x2 = boundingBox[2] * scaling;
    let y2 = boundingBox[3] * scaling;
    let dx1 = x1 * cos - y1 * sin;
    let dy1 = x1 * sin + y1 * cos;
    let dx2 = x2 * cos - y2 * sin;
    let dy2 = x2 * sin + y2 * cos;
    return [dx1 - centerX, dy1 - centerY, dx2 - centerX, dy2 - centerY];
  }
  updateProperties(data) {
    let changed = super.updateProperties(data);
    if (data) {
      let properties = data.properties;
      if (properties) {
        changed |= updateProperties(this, ['centerX', 'centerY', 'scaling', 'rotation', 'opacity'], properties);
      }
    }
    if (changed) {
      this.makeDirty();
    }
    return changed;
  }
  serialize() {
    let res = super.serialize();
    if (!res.properties) {
      res.properties = {};
    }
    serializeObject(this, ['centerX', 'centerY', 'scaling', 'rotation', 'opacity'], res.properties);
    return res;
  }
}
export { Drawing };