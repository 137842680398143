import { watch } from 'vue';
import { globalState } from "@/model/globalData";
import { Sm3DCreatorTools } from "@/creator/sm3DCreatorTools";
class Sm3DCreator {
  constructor(collection, scene) {
    this.collection = collection;
    this.scene = scene;
    this.tools = new Sm3DCreatorTools(collection, scene);
    watch(() => globalState.mode, () => {});
  }
}
export { Sm3DCreator };