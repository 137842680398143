import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import { watch } from 'vue';
import { isCtrlDown, isSpaceDown, isShiftDown } from "@/utils/keyboard";
class Sm3DCreatorTools {
  constructor(collection, scene) {
    this.collection = collection;
    this.scene = scene;
    if (!scene.reservedDataStore) {
      scene.reservedDataStore = {};
    }
    this.initGizmo();
    let gizmoManager = scene.reservedDataStore.gizmoManager;
    let cache = gizmoManager.cache;
    watch(() => [currData.currObject, currData.point, currData.segment, globalState.mode, globalState.drawingMode], () => {
      let mode = globalState.mode;
      gizmoManager.gizmos.positionGizmo.yGizmo.isEnabled = mode === 'model' || mode === 'pipeline';
      let obj = globalState.operation === 'pick' && (mode === 'model' || ['wall', 'area', 'pipeline'].includes(mode) && ['point', 'line'].includes(globalState.drawingMode)) ? currData.currObject : null;
      if (obj) {
        obj = scene.getNodeByID(obj.nanoId);
      }
      let point = null;
      if (obj) {
        let drawingMode = globalState.drawingMode;
        if (drawingMode === 'point') {
          if (mode === 'pipeline') {
            let p = currData.point;
            if (p) {
              cache.point.x = cache.position.x = p.x;
              cache.point.y = cache.position.y = p.y;
              cache.point.z = cache.position.z = p.z;
              point = cache.point;
            } else {
              obj = null;
            }
          } else {
            let p = currData.point;
            if (p) {
              cache.point.x = cache.position.x = p.x;
              cache.point.y = cache.position.y = 0;
              cache.point.z = cache.position.z = p.y;
              point = cache.point;
            } else {
              obj = null;
            }
          }
        } else if (drawingMode === 'line') {
          let segment = currData.segment;
          if (segment) {
            let pA = segment.pointA;
            let pB = segment.pointB;
            if (mode === 'pipeline') {
              cache.point.x = cache.position.x = (pA.x + pB.x) * 0.5;
              cache.point.y = cache.position.y = (pA.y + pB.y) * 0.5;
              cache.point.z = cache.position.z = (pA.z + pB.z) * 0.5;
              point = cache.point;
            } else {
              cache.point.x = cache.position.x = (pA.x + pB.x) * 0.5;
              cache.point.y = cache.position.y = 0;
              cache.point.z = cache.position.z = (pA.y + pB.y) * 0.5;
              point = cache.point;
            }
          } else {
            obj = null;
          }
        }
      } else {
        obj = null;
      }
      gizmoManager.attachToNode(obj);
      gizmoManager.attachToPoint(point);
    });
    scene._flushGizmoPoints = () => {
      let obj = currData.currObject;
      if (globalState.is3dDragging) {
        return;
      }
      let drawingMode = globalState.drawingMode;
      let mode = globalState.mode;
      if (drawingMode === 'point') {
        let point = currData.point;
        if (point) {
          if (mode === 'pipeline') {
            cache.point.x = cache.position.x = point.x;
            cache.point.y = cache.position.y = point.y;
            cache.point.z = cache.position.z = point.z;
          } else {
            cache.point.x = cache.position.x = point.x;
            cache.point.y = cache.position.y = 0;
            cache.point.z = cache.position.z = point.y;
          }
        }
      } else if (drawingMode === 'line') {
        let segment = currData.segment;
        if (segment) {
          let pointA = segment.pointA;
          let pointB = segment.pointB;
          if (mode === 'pipeline') {
            cache.point.x = cache.position.x = (pointA.x + pointB.x) * 0.5;
            cache.point.y = cache.position.y = (pointA.y + pointB.y) * 0.5;
            cache.point.z = cache.position.z = (pointA.z + pointB.z) * 0.5;
          } else {
            cache.point.x = cache.position.x = (pointA.x + pointB.x) * 0.5;
            cache.point.y = cache.position.y = 0;
            cache.point.z = cache.position.z = (pointA.y + pointB.y) * 0.5;
          }
        }
      }
    };
  }
  initGizmo() {
    let scene = this.scene;
    if (!scene.reservedDataStore.gizmoManager) {
      scene.reservedDataStore.gizmoManager = new Kingfisher.GizmoManager(scene);
    }
    let manager = scene.reservedDataStore.gizmoManager;
    manager.positionGizmoEnabled = true;
    manager.gizmos.positionGizmo.scaleRatio = 0.25;
    let cache = {
      position: new Kingfisher.Vector3(),
      point: new Kingfisher.Vector3()
    };
    manager.cache = cache;
    manager.gizmos.positionGizmo.onDragStartObservable.add(() => {
      let node = manager.attachedNode;
      if (node) {
        let drawingMode = globalState.drawingMode;
        let mode = globalState.mode;
        if (mode === 'model') {
          let point = currData.currObject.position;
          cache.point.x = cache.position.x = point.x * 0.001;
          cache.point.y = cache.position.y = point.y * 0.001;
          cache.point.z = cache.position.z = point.z * 0.001;
        }
        if (drawingMode === 'point') {
          let point = currData.point;
          if (point) {
            if (mode === 'pipeline') {
              cache.point.x = cache.position.x = point.x;
              cache.point.y = cache.position.y = point.y;
              cache.point.z = cache.position.z = point.z;
            } else {
              cache.point.x = cache.position.x = point.x;
              cache.point.y = cache.position.y = 0;
              cache.point.z = cache.position.z = point.y;
            }
            if (isCtrlDown.value) {
              // create new point
              let obj = currData.finalCurrList[0];
              point = obj.createPoint(point);
              obj.createSegment(currData.point, point);
              currData.point = point;
              obj.makeDirty();
              obj.setNeedSave();
            }
          } else {
            cache.position.copyFrom(node.position);
          }
        } else if (drawingMode === 'line') {
          let segment = currData.segment;
          if (segment) {
            let pointA = segment.pointA;
            let pointB = segment.pointB;
            if (mode === 'pipeline') {
              cache.point.x = cache.position.x = (pointA.x + pointB.x) * 0.5;
              cache.point.y = cache.position.y = (pointA.y + pointB.y) * 0.5;
              cache.point.z = cache.position.z = (pointA.z + pointB.z) * 0.5;
            } else {
              cache.point.x = cache.position.x = (pointA.x + pointB.x) * 0.5;
              cache.point.y = cache.position.y = 0;
              cache.point.z = cache.position.z = (pointA.y + pointB.y) * 0.5;
            }
          }
        }
        globalState.is3dDragging = true;
      } else {
        globalState.is3dDragging = false;
      }
    });
    manager.gizmos.positionGizmo.onDragObservable.add(() => {
      let node = manager.attachedNode;
      if (node) {
        let drawingMode = globalState.drawingMode;
        let mode = globalState.mode;
        if (mode === 'model') {
          let position = node.position;
          let dx = (position.x - cache.position.x) * 1000;
          let dy = (position.y - cache.position.y) * 1000;
          let dz = (position.z - cache.position.z) * 1000;
          cache.position.x += dx * 0.001;
          cache.position.y += dy * 0.001;
          cache.position.z += dz * 0.001;
          let currList = currData.finalCurrList;
          for (let obj of currList) {
            if (obj.position) {
              obj.position.x += dx;
              obj.position.y += dy;
              obj.position.z += dz;
              obj.makeDirty();
            }
          }
        } else if (drawingMode === 'point') {
          if (currData.point) {
            let position = cache.point;
            let dx = Math.round(position.x - cache.position.x);
            let dy = Math.round(position.y - cache.position.y);
            let dz = Math.round(position.z - cache.position.z);
            cache.position.x = position.x;
            cache.position.y = position.y;
            cache.position.z = position.z;
            let obj = currData.finalCurrList[0];
            let points = currData.points;
            if (obj.type === 'pipeline') {
              for (let p of points) {
                p.x += dx;
                p.y += dy;
                p.z += dz;
                p.makeDirty();
              }
            } else {
              for (let p of points) {
                p.x += dx;
                p.y += dz;
                p.makeDirty();
              }
            }
            obj.makeDirty();
            obj.setNeedSave();
          }
        } else if (drawingMode === 'line') {
          if (currData.segment) {
            let position = cache.point;
            let dx = Math.round(position.x - cache.position.x);
            let dy = Math.round(position.y - cache.position.y);
            let dz = Math.round(position.z - cache.position.z);
            cache.position.x = position.x;
            cache.position.y = position.y;
            cache.position.z = position.z;
            let obj = currData.finalCurrList[0];
            let segments = currData.segments;
            let points = new Set();
            for (let s of segments) {
              points.add(s.pointA);
              points.add(s.pointB);
            }
            points = Array.from(points);
            if (obj.type === 'pipeline') {
              for (let p of points) {
                p.x += dx;
                p.y += dy;
                p.z += dz;
                p.makeDirty();
              }
            } else {
              for (let p of points) {
                p.x += dx;
                p.y += dz;
                p.makeDirty();
              }
            }
            obj.makeDirty();
            obj.setNeedSave();
          }
        }
      }
    });
    manager.gizmos.positionGizmo.onDragEndObservable.add(() => {
      globalState.is3dDragging = false;
      let node = manager.attachedNode;
      if (node) {
        let mode = globalState.mode;
        if (mode === 'model') {
          let currList = currData.finalCurrList;
          for (let obj of currList) {
            obj.makeDirty();
            obj.setNeedSave();
          }
        }
      }
    });
  }
}
export { Sm3DCreatorTools };