import { ref, watch, computed } from 'vue';
import { smDatas } from "@/model/smCollectionData";
import { currData } from "@/model/globalData";
import { getObjectName, getFloorName, showContextMenu, setProperty, doItemClick, loadBodyScrollTop } from "./index.js";
export default {
  name: "floor",
  setup() {
    let bodyManager = ref(null);
    let visibleObjs = computed(() => {
      console.log("==floor", smDatas.floors.filter(x => !x.isDeleted).sort((a, b) => a.elevation - b.elevation));
      return smDatas.floors.filter(x => !x.isDeleted).sort((a, b) => a.elevation - b.elevation);
    });
    watch(() => currData.currListFlag, () => {
      loadBodyScrollTop(bodyManager, 'floors', 'floor');
    });
    let isActive = function (item) {
      return currData.floor === item || currData.currListMap.has(item);
    };
    return {
      smDatas,
      currData,
      getFloorName,
      getObjectName,
      showContextMenu,
      setProperty,
      doItemClick,
      bodyManager,
      visibleObjs,
      isActive
    };
  }
};