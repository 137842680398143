import "core-js/modules/es.array.push.js";
import { ref, computed } from 'vue';
import { smDatas } from "@/model/smCollectionData";
import { globalState, currData } from "@/model/globalData";
export default {
  name: "dataProperty",
  setup() {
    let createDataDialog = ref(false);
    let currItemIndex = ref(0);
    let newData = ref({});
    const handleSelect = function (item, index) {
      currItemIndex.value = index;
    };
    const clearData = function () {
      let properties = currData && currData.currList[0] && currData.currList[0].properties;
      if (properties && properties.data && properties.data.length) {
        properties.data = [];
        currData.currList[0].setNeedSave();
      }
    };
    const deleteData = function (index) {
      data.value.splice(index, 1);
    };
    const createData = function () {
      let properties = currData && currData.currList[0] && currData.currList[0].properties;
      // newData.value.name='';
      // newData.value.val='';
      // newData.value.desc='';
      if (properties) {
        if (!properties.data) {
          properties.data = [];
        }
        newData.value = Object.assign({}, newData.value);
        createDataDialog.value = true;
      }
    };
    const doCreateData = function () {
      let properties = currData && currData.currList[0] && currData.currList[0].properties;
      if (properties && properties.data) {
        let name = newData.value.name;
        if (properties.data.find(x => x.name === name) === undefined) {
          properties.data.push(newData.value);
          currData.currList[0].setNeedSave();
        }
      }
      createDataDialog.value = false;
    };
    const data = computed(() => {
      return currData && currData.currList && currData.currList[0] && currData.currList[0].properties && currData.currList[0].properties.data ? currData.currList[0].properties.data : [];
    });
    let currItem = computed(() => {
      return data && data.value && data.value[currItemIndex.value];
    });
    let nameCheck = computed(() => {
      return currData && currData.value && data.value.find(x => x.name === currData.value.name) !== undefined;
    });
    let dataChanged = function () {
      currData && currData.currList[0] && currData.currList[0].setNeedSave();
    };
    return {
      data,
      handleSelect,
      currData,
      currItem,
      clearData,
      deleteData,
      createData,
      nameCheck,
      createDataDialog,
      newData,
      doCreateData,
      dataChanged
    };
  }
};