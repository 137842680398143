import "core-js/modules/es.array.push.js";
import { ref, computed } from 'vue';
import wall from "./property/wall";
import model from "./property/model";
import smArea from "./property/area";
import pipeline from "./property/pipeline.vue";
import floor from "./property/floor";
import devicePoint from "./property/devicePoint";
import drawing from "./property/drawing";
import dxfDrawing from "./property/dxfDrawing";
import dataProperty from "./property/dataProperty";
import { globalState, currData, modeName } from "@/model/globalData";
import { undoMap } from "@/undo/undoMap";
export default {
  name: "panelProperty",
  components: {
    dataProperty,
    model,
    wall,
    smArea,
    pipeline,
    floor,
    drawing,
    dxfDrawing,
    devicePoint
  },
  setup() {
    let getModeName = function () {
      return modeName[globalState.mode] + Kf.t('Properties') + (currData.currList.length > 1 ? `(${currData.currList.length}) > ` : ' > ');
    };
    let getObjectName = function () {
      return currData.currList.length ? currData.currList[0].name : Kf.t('Undefined');
    };
    let isLocked = function () {
      return currData.currList.length && currData.currList[0].locked;
    };
    const isVisible = computed(() => {
      return currData.currList.length && currData.currList[0].visible;
    });
    let getComponentType = function () {
      let mode = globalState.mode;
      if (mode === 'area') {
        return 'smArea';
      }
      if (mode === 'drawing') {
        if (currData.currList.length && currData.currList[0].type === 'dxfDrawing') {
          return 'dxfDrawing';
        }
      }
      return mode;
    };
    const switchLock = function (val) {
      for (let obj of currData.currList) {
        if (obj.locked !== val) {
          obj.locked = val;
          obj.setNeedSave();
        }
      }
    };
    const switchVisible = function (value) {
      let val = value;
      let targets = currData.currList.filter(x => x.visible !== val);
      undoMap.push({
        cmd: 'updateProperty',
        property: 'visible',
        items: targets.map(x => {
          return {
            target: x,
            value: x.visible
          };
        })
      });
      for (let obj of targets) {
        obj.visible = val;
        obj.setNeedSave();
      }
    };
    let activeLabel = ref('base');
    return {
      getModeName,
      getObjectName,
      getComponentType,
      isLocked,
      isVisible,
      switchLock,
      switchVisible,
      activeLabel
    };
  }
};