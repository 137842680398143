import { ref, watch } from 'vue';
import { registerKeys } from "@/utils/keyboard";
import hotkeys from 'hotkeys-js';
const lastKeys = ref("");
const lastShortcut = ref("");
let keysProcessors = {};
let hotkeyProcessors = {};
let keysDescription = {};
let hotkeyDescription = {};
const cleanKeys = function () {
  lastKeys.value = "";
};
const cleanShortcut = function () {
  lastShortcut.value = "";
};
let cleanKeyTimer = null;
let cleanShortcutTimer = null;
let inited = false;
const storeKey = function (key) {
  lastKeys.value += key;
  console.log(key, lastKeys.value);
  if (cleanKeyTimer) {
    clearTimeout(cleanKeyTimer);
  }
  cleanKeyTimer = setTimeout(cleanKeys, 500);
};
const registerKeysProcessor = function (key, handler, description) {
  if (keysProcessors[key] == null) {
    keysProcessors[key] = handler;
    keysDescription[key] = description;
  } else {
    console.error(`按键系列'${key}'已经被注册了.`);
  }
};
const unRegisterKeysProcessor = function (key) {
  delete keysDescription[key];
};
const registerHotkeyProcessor = function (key, processor, description, option) {
  if (hotkeyProcessors[key] == null) {
    hotkeyProcessors[key] = "true";
    hotkeyDescription[key] = description;
    registerKeys(key, (event, handler) => {
      lastShortcut.value = key;
      if (cleanShortcutTimer) {
        clearTimeout(cleanShortcutTimer);
      }
      cleanShortcutTimer = setTimeout(cleanShortcut, 500);
      if (processor) processor(event, handler);
    }, option);
  } else {
    console.error(`按键系列'${key}'已经被注册了.`);
  }
};
const unRegisterHotkeyProcessor = function (key) {
  delete hotkeyProcessors[key];
};
const init = function () {
  hotkeys.filter = function (event) {
    let tag = event.target || event.srcElement;
    let tagName = tag.tagName;
    return !((tagName.isContentEditable || tagName === 'INPUT' || tagName === 'SELECT' || tagName === 'TEXTAREA') && (tag.className.indexOf("monaco-mouse-cursor-text") === -1 || !event.ctrlKey));
  };
  if (!inited) {
    inited = true;
    let chars = [...Array.apply(null, {
      length: 26
    }).map(function (x, i) {
      return String.fromCharCode(97 + i);
    }), '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    chars.forEach(c => {
      registerKeys(c, (event, handler) => {
        storeKey(c);
      });
    });
  } else {
    keysProcessors = {};
    hotkeyProcessors = {};
    lastKeys.value = "";
  }
  watch(lastKeys, (newValue, oldValue) => {
    let processor = keysProcessors[lastKeys.value];
    if (processor) {
      processor();
    }
  });
};
export default {
  lastKeys,
  lastShortcut,
  init,
  registerKeysProcessor,
  unRegisterKeysProcessor,
  registerHotkeyProcessor,
  unRegisterHotkeyProcessor,
  keysDescription: {
    keys: keysDescription,
    hotkeys: hotkeyDescription
  },
  cleanKeys,
  cleanShortcut
};