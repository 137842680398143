import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import { ref, watch, computed, nextTick } from 'vue';
import { smDatas, getDataTree } from "@/model/smCollectionData";
import { currData, globalState } from "@/model/globalData";
import { getTypeName } from "@/model/modelOptions";
import { getObjectName, getFloorName, setProperty, doItemClick, doItemDBClick, showContextMenu } from "./index.js";
import { compMapByName, getModelById, getModelByName, selectedThemeId } from "@/model/3Dmodel";
import oemManager from '@/utils/oem';
export default {
  name: "scene",
  methods: {
    getModelByName
  },
  setup() {
    let dataTree = ref(null);
    let bodyManager = ref(null);
    let mappingValue = ref('all');
    let mappingList = ref([{
      name: Kf.t('All'),
      value: 'all'
    }, {
      name: Kf.t('Mapped'),
      value: 'mapped'
    }, {
      name: Kf.t('Unmapped'),
      value: 'unmapped'
    }]);
    let expandedKeys = ref([]);
    let locale = ref(window.Kf.locale.value.replace("-", ""));
    let count = ref(0);
    let visibleObjs = computed(() => {
      let data = smDatas.models || [];
      if (mappingValue.value === 'mapped') {
        data = data.filter(x => x.deviceId);
      }
      if (mappingValue.value === 'unmapped') {
        data = data.filter(x => !x.deviceId);
      }
      count.value = data.length;
      let res = [];
      let treeMap = new Map();
      for (let item of data) {
        if (item.isDeleted || !item.floorVisible) {
          continue;
        }
        let secondNode = treeMap.get(item.modelName);
        if (!secondNode) {
          if (!smDatas.modelsGroup.has(item.modelName)) {
            let temp = {
              id: item.modelName,
              modelId: item.uuid,
              label: getLabel(item.modelName),
              subType: item.subType,
              children: [],
              _visible: ref(true),
              isGroup: true,
              level: 2
            };
            temp.modelLost = computed(() => {
              return !getModelByName(item.modelName);
            });
            temp.finalVisibleProxy = computed(() => {
              if (!temp._visible.value) {
                return false;
              }
              let parent = smDatas.modelsGroup.get(item.subType);
              return !parent || parent.visible;
            });
            Object.defineProperty(temp, 'visible', {
              set(value) {
                if (value === temp._visible.value) {
                  return;
                }
                let oldFinalVisible = temp.finalVisible;
                temp._visible.value = value;
                if (temp.finalVisible !== oldFinalVisible) {
                  if (temp.children) {
                    for (let child of temp.children) {
                      child.makeDirty();
                    }
                  }
                }
              },
              get() {
                return temp._visible.value;
              }
            });
            Object.defineProperty(temp, 'finalVisible', {
              get() {
                return temp.finalVisibleProxy.value;
              }
            });
            smDatas.modelsGroup.set(item.modelName, temp);
          }
          secondNode = smDatas.modelsGroup.get(item.modelName);
          secondNode.children = [];
          let firstNode = treeMap.get(item.subType);
          if (!firstNode) {
            if (!smDatas.modelsGroup.has(item.subType)) {
              let temp = {
                id: item.subType,
                label: getTypeName(item.subType),
                children: [],
                _visible: ref(true),
                isGroup: true,
                level: 1
              };
              smDatas.modelsGroup.set(item.subType, temp);
              Object.defineProperty(temp, 'visible', {
                set(value) {
                  if (value === temp._visible.value) {
                    return;
                  }
                  temp._visible.value = value;
                  for (let childGroup of temp.children) {
                    if (childGroup.visible) {
                      for (let child of childGroup.children) {
                        child.makeDirty();
                      }
                    }
                  }
                },
                get() {
                  return temp._visible.value;
                }
              });
            }
            firstNode = smDatas.modelsGroup.get(item.subType);
            firstNode.children = [];
            firstNode.modelLost = computed(() => {
              for (let child of firstNode.children) {
                if (child.modelLost) {
                  return true;
                }
              }
              return false;
            });
            treeMap.set(firstNode.id, firstNode);
            res.push(firstNode);
          }
          treeMap.set(secondNode.id, secondNode);
          firstNode.children.push(secondNode);
        }
        item.id = item._nanoId;
        item.modelLost = secondNode.modelLost;
        secondNode.children.push(item);
      }
      return res;
    });
    const getLabel = function (modelName) {
      let t = getModelByName(modelName);
      if (t) {
        if (t.labelInterpret && typeof t.labelInterpret === "string") {
          try {
            t.labelInterpret = JSON.parse(t.labelInterpret);
          } catch (e) {
            console.error(e);
          }
        }
        modelName = (t.labelInterpret ? t.labelInterpret[locale.value] : '') || t.name;
      }
      return modelName;
    };
    watch(compMapByName, () => {
      for (let [key, group] of smCollection.modelsGroup) {
        if (group.level === 2) {
          group.label = getLabel(group.id);
        }
      }
    });
    const checkedKeys = computed(() => {
      return currData.currList.map(x => x._nanoId);
    });
    const loadBodyScrollTop = function () {
      let model = currData.currList[currData.currList.length - 1];
      if (!model || model.type !== 'model') {
        return;
      }
      let eKeys = expandedKeys.value;
      let firstGroup = visibleObjs.value.find(x => x.id === model.subType);
      let secondGroup = firstGroup.children.find(x => x.id === model.modelName);
      if (eKeys.indexOf(firstGroup.id) === -1) {
        eKeys.push(firstGroup.id);
      }
      if (eKeys.indexOf(secondGroup.id) === -1) {
        eKeys.push(secondGroup.id);
      }
      for (let id of eKeys) {
        let node = dataTree.value.getNode(id);
        if (node) {
          node.expand();
        }
      }
      if (bodyManager.value) {
        let getIndex = () => {
          let index = 0;
          for (let i = 0; i < visibleObjs.value.length; i++) {
            index++;
            let group = visibleObjs.value[i];
            if (eKeys.indexOf(group.id) >= 0) {
              for (let j = 0; j < group.children.length; j++) {
                index++;
                let child = group.children[j];
                if (eKeys.indexOf(child.id) >= 0) {
                  if (child.id === model.modelName) {
                    index += child.children.indexOf(model);
                    return index;
                  } else {
                    index += child.children.length;
                  }
                }
              }
            }
          }
          return index;
        };
        let index = getIndex();
        setTimeout(() => {
          let top = index * 30;
          let clientHeight = bodyManager.value.clientHeight;
          let scrollTop = bodyManager.value.scrollTop;
          if (scrollTop < top - clientHeight + 30 || scrollTop > top - 30) {
            bodyManager.value.scrollTop = index * 30;
            setTimeout(() => {
              //不知道为啥，经常设置了不管用。
              bodyManager.value.scrollTop = index * 30;
            }, 0.5);
          }
        }, 0.2);
      }
    };
    watch(checkedKeys, (newValue, oldValue) => {
      if (!dataTree.value) {
        return;
      }
      loadBodyScrollTop();
      // dataTree.value.setCheckedKeys(checkedKeys.value);

      let newSet = new Set(newValue);
      let oldSet = new Set(oldValue);
      for (let k of oldValue) {
        let node = dataTree.value.getNode(k);
        if (node && !newSet.has(k)) {
          node.setChecked(false, true);
        }
      }
      for (let k of newValue) {
        let node = dataTree.value.getNode(k);
        if (node && !oldSet.has(k)) {
          node.setChecked(true, true);
        }
      }
    });
    let isActive = function (item) {
      return currData.model === item || currData.currListMap.has(item);
    };
    const handleNodeExpand = function (data, node, instance) {
      expandedKeys.value.push(data.id);
    };
    const handleNodeCollapse = function (data, node, instance) {
      let _index = expandedKeys.value.findIndex(item => item === data.id);
      if (_index !== null) {
        expandedKeys.value.splice(_index, 1);
      }
    };
    return {
      dataTree,
      smDatas,
      currData,
      getObjectName,
      getFloorName,
      doItemClick,
      setProperty,
      doItemDBClick,
      showContextMenu,
      bodyManager,
      visibleObjs,
      isActive,
      mappingValue,
      mappingList,
      checkedKeys,
      expandedKeys,
      handleNodeExpand,
      getLabel,
      handleNodeCollapse,
      platformType: oemManager.platformType,
      count
    };
  }
};