import {buildPipelineMesh} from "./smPipelineBuildTools";
import {smDatas, smMesh2DCache} from "@/model/smCollectionData";
import {smMesh3DCache} from "@/model/smCollectionData";
import {currData} from "@/model/globalData";

class SmPipeline3DBuilder {
    update(data) {
        let pipeline = data.obj;
        if (!data.inited) {
            if (!pipeline.finalVisible) {
                return false;
            }
            data.inited = true;

            //pipeline init
            let pipelineMesh = new Kingfisher.Mesh(pipeline.name, data.scene, null, null, false, true, {
                id: pipeline.nanoId
            });
            // wallMesh.renderingGroupId = 1;
            pipelineMesh.receiveShadows = true;
            pipelineMesh.castShadows = true;
            pipelineMesh.scalingDeterminant = 0.001;

            let pipelineBuilder = new Kingfisher.GeoSplineBuilder(pipelineMesh);
            pipelineBuilder.vScale = 1000;
            pipelineBuilder.tessellation = 8;
            data.pipelineMesh = pipelineMesh;
            data.pipelineBuilder = pipelineBuilder;

            data.highlightMeshes = [pipelineMesh];
            data.pipelineMesh = pipelineMesh;
            data.pickMeshes = [pipelineMesh];
            data.focusObject = pipelineMesh;

            data.dispose = function () {
                data.pipelineMesh.dispose();
                // data.wallMaterial.dispose();
                // data.wallGeometry.dispose();
            }
        }

        if (!pipeline.finalVisible) {
            data.pipelineMesh.enableState = false;
            return false;
        }
        else {
            data.pipelineMesh.enableState = true
        }

        data.pipelineMesh.name = data.obj.name + '(pipeline)';

        data.pipelineMesh.material = data.scene.getMaterialByName('pipeline-' + pipeline.purpose) || data.scene.getMaterialByName('pipeline');
        data.pipelineMesh.position.y = (((smDatas.objects[pipeline.floor] ? smDatas.objects[pipeline.floor].elevation : 0) || 0) + pipeline.elevation) * 0.001;
        buildPipelineMesh(pipeline);

        let pipelineBuilder = data.pipelineBuilder;
        pipelineBuilder.splineGroup = smMesh3DCache[pipeline.nanoId].spline3d;
        pipelineBuilder.radius = pipeline.diameter * 0.5;

        if (currData.pipeline === pipeline) {
            sm3DMeshBuilder.updateSelectPoint();
        }

        return true;
    }
}

export default new SmPipeline3DBuilder();