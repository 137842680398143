import { currData } from "@/model/globalData";
import extendInput from '@/components/propWidget/extendInput';
import { floorListOptions, getItemProperty, updateItemProperty } from "@/components/mainContent/rightPanel/property/index";
import oemManager from "@/utils/oem";
export default {
  name: "modelMM",
  methods: {},
  components: {
    extendInput
  },
  setup() {
    return {
      getItemProperty,
      updateItemProperty,
      floorListOptions,
      currData
    };
  }
};