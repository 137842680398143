import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array-buffer.detached.js";
import "core-js/modules/es.array-buffer.transfer.js";
import "core-js/modules/es.array-buffer.transfer-to-fixed-length.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.find-last.js";
import "core-js/modules/es.typed-array.find-last-index.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.to-reversed.js";
import "core-js/modules/es.typed-array.to-sorted.js";
import "core-js/modules/es.typed-array.with.js";
import { globalState, loadDxf, loadDxfData } from "@/model/globalData";
import KingfisherService from "../utils/request";
import { BatchingKey } from "@/dxflib/BatchingKey";
import { Vector3 } from "@/maths/vector";
class DxfDrawingBuilder {
  update(data) {
    let drawing = data.obj;
    if (!data.inited) {
      if (!drawing.visible) {
        return false;
      }
      data.inited = true;
      data.meshes = {};
      data.geometries = {};
      data.parentNode = new Kingfisher.TransformNode('dxf-parent', data.scene);
      data.parentNode.parent = data.scene._drawingRoot;
      data.selectedMesh = Kingfisher.MeshBuilder.CreatePlane("dxf-selected-plane", {
        width: 1,
        height: 1,
        XZPlan: true
      }, data.scene);
      data.selectedMesh.thinInstance = true;
      data.selectedMesh.material = data.scene.getMaterialByName('dxf-selected');
      data.selectedMesh.enableState = false;
      data.selectedMesh.parent = data.parentNode;
      data.focusObject = data.parentNode;
      let lineMaterial = new Kingfisher.KMaterial('line', data.scene);
      lineMaterial.wireframe = 1;
      lineMaterial.alpha = 1;
      lineMaterial.transparencyMode = 3;
      data.lineMaterial = lineMaterial;
      let channel = new Kingfisher.KMatChannel(lineMaterial);
      new Kingfisher.KMatStageNoLight(lineMaterial, channel);
      let vdata1 = new Kingfisher.KMatStageVertexData(lineMaterial, channel);
      vdata1.source = 'randomId';
      let fillMaterial = new Kingfisher.KMaterial('fill', data.scene);
      fillMaterial.alpha = 0.5;
      fillMaterial.transparencyMode = 3;
      fillMaterial.backFaceCulling = false;
      data.fillMaterial = fillMaterial;
      channel = new Kingfisher.KMatChannel(fillMaterial);
      new Kingfisher.KMatStageNoLight(fillMaterial, channel);
      vdata1 = new Kingfisher.KMatStageVertexData(fillMaterial, channel);
      vdata1.source = 'randomId';
      data.dispose = function () {
        data.selectedMesh.dispose();
        data.selectedMesh = null;
        for (let key in data.meshes) {
          for (let mesh of data.meshes[key]) {
            mesh.dispose();
          }
        }
        data.meshes = {};
        for (let key in data.geometries) {
          data.geometries[key].dispose();
        }
        data.geometries = {};
        data.lineMaterial.dispose();
        data.lineMaterial = null;
        data.fillMaterial.dispose();
        data.fillMaterial = null;
      };
      data.setSelected = function (selected) {
        data.selectedMesh.enableState = selected;
        data.selectedMesh.thinInstanceCount = 0;
        let pickedList = globalState.dxfSelected.blocks;
        if (selected && pickedList) {
          if (!pickedList instanceof Array) {
            pickedList = [pickedList];
          }
          let position = Kingfisher.Vector3.Zero();
          let scaling = Kingfisher.Vector3.One();
          for (let picked of pickedList) {
            let source = picked.source;
            let bounds = source.entity.bounds;
            if (!bounds) {
              continue;
            }
            let s = Math.max(bounds.maxX - bounds.minX, bounds.maxY - bounds.minY) * 0.01;
            let maxX = bounds.maxX + s;
            let minX = bounds.minX - s;
            let maxY = bounds.maxY + s;
            let minY = bounds.minY - s;
            scaling.x = maxX - minX;
            scaling.z = maxY - minY;
            position.x = (maxX + minX) / 2;
            position.z = (maxY + minY) / 2;
            let matrix = Kingfisher.Matrix.FromAxis(position, null, scaling);
            data.selectedMesh.thinInstanceAdd(matrix);
          }
        }
      };
    }
    data.parentNode.enableState = 0;
    for (let key in data.meshes) {
      for (let mesh of data.meshes[key]) {
        mesh.enableState = 0;
      }
    }
    if (!globalState.enableDrawingView || !drawing.visible || drawing.isDeleted) {
      return true;
    }
    let dxfData = smDxfCache[drawing.fileId];
    if (!dxfData) {
      if (drawing.fileId) {
        loadDxf(drawing, drawing.fileId);
      }
      return false;
    }
    if (typeof dxfData !== 'object') {
      data.parentNode.enableState = 0;
      drawing.loadingState = 3;
      drawing.loadingProgress = 1;
      return true;
    }
    if (data.dxfData !== dxfData) {
      data.dxfData = dxfData;
      drawing.loadingState = 2;
      drawing.loadingProgress = 1;
      console.log('display dxf data');
      for (let key in data.meshes) {
        for (let mesh of data.meshes[key]) {
          mesh.dispose();
        }
      }
      data.meshes = {};
      if (dxfData) {
        if (dxfData.batches) {
          let blocks = dxfData.blocks;
          let layerVertexData = {};
          let createMesh = (layer, type, color, vertices, indices, instance) => {
            let count = vertices.length / 2;
            let positions = new Float32Array(count * 3);
            for (let i = 0; i < count; i++) {
              let i2 = i * 2;
              let i3 = i * 3;
              positions[i3] = vertices[i2];
              positions[i3 + 2] = vertices[i2 + 1];
            }
            let indices0;
            let isLine = false;
            if (type === BatchingKey.GeometryType.LINES) {
              isLine = true;
              count = vertices.length / 2;
              let count_2 = Math.floor(count / 2);
              indices0 = new Uint32Array(count_2 * 3);
              for (let i = 0; i < count_2; i++) {
                let i2 = i * 2;
                let i3 = i * 3;
                indices0[i3] = indices0[i3 + 1] = i2;
                indices0[i3 + 2] = i2 + 1;
              }
            } else if (type === BatchingKey.GeometryType.INDEXED_LINES) {
              isLine = true;
              let count_2 = Math.floor(indices.length / 2);
              indices0 = new Uint32Array(count_2 * 3);
              for (let i = 0; i < count_2; i++) {
                let i2 = i * 2;
                let i3 = i * 3;
                indices0[i3] = indices0[i3 + 1] = indices[i2];
                indices0[i3 + 2] = indices[i2 + 1];
              }
            } else if (type === BatchingKey.GeometryType.INDEXED_TRIANGLES) {
              indices0 = indices;
            }
            if (indices0 && indices0.length) {
              if (instance) {
                let mesh = new Kingfisher.Mesh(layer.layerName, data.scene);
                mesh.neverPickable = true;
                mesh.parent = data.parentNode;
                let geometry = new Kingfisher.Geometry(Kingfisher.getNanoID(), data.scene, undefined, true, mesh);
                geometry._boundingInfo = new Kingfisher.BoundingInfo(new Vector3(), new Vector3());
                geometry.setVerticesData(Kingfisher.VertexBuffer.PositionKind, positions, false);
                geometry.setIndices(indices0, null, true);
                mesh.randomId.set((color >> 16 & 0xff) / 255, (color >> 8 & 0xff) / 255, (color >> 0 & 0xff) / 255);
                mesh.material = isLine ? data.lineMaterial : data.fillMaterial;
                mesh.thinInstance = true;
                let transformsArray = instance;
                mesh.thinInstanceCount = transformsArray.length / 6;
                for (let i = 0; i < transformsArray.length; i += 6) {
                  let matrix = Kingfisher.Matrix.FromValues(transformsArray[i + 0], 0, transformsArray[i + 3], 0, 0, 1, 0, 0, transformsArray[i + 1], 0, transformsArray[i + 4], 0, transformsArray[i + 2], 0, transformsArray[i + 5], 1);
                  mesh.thinInstanceAdd(matrix);
                }
                if (!data.meshes[layer.layerID]) {
                  data.meshes[layer.layerID] = [];
                }
                data.meshes[layer.layerID].push(mesh);
              } else {
                let key = [layer.layerID, color, isLine].join('_');
                if (!layerVertexData[key]) {
                  layerVertexData[key] = {
                    layerID: layer.layerID,
                    color: color,
                    isLine: isLine,
                    positions: [],
                    positionCount: 0,
                    indices: [],
                    indexCount: 0
                  };
                }
                let data = layerVertexData[key];
                let indicesOffset = data.positionCount;
                data.positions.push(positions);
                data.positionCount += positions.length / 3;
                let newIndices = new Uint32Array(indices0.length);
                for (let i = 0; i < indices0.length; i++) {
                  newIndices[i] = indices0[i] + indicesOffset;
                }
                data.indices.push(newIndices);
                data.indexCount += newIndices.length;
              }
            } else {
              debugger;
            }
          };
          let createBatch = (layer, batch, instanceBatch) => {
            let geometryType = batch.key.geometryType;
            let color;
            if (instanceBatch) {
              if (instanceBatch.key.color === -2) {
                color = batch.key.color;
              } else if (instanceBatch.key.color === -1) {
                color = layer.color;
              } else {
                color = instanceBatch.key.color;
              }
            } else {
              color = batch.key.color;
            }
            if (color === undefined) {
              color = 255 << 16;
            }
            let chunks = batch.chunks;
            if (chunks) {
              for (let chunkIndex = 0; chunkIndex < chunks.length; chunkIndex++) {
                createMesh(layer, geometryType, color, chunks[chunkIndex].vertices, chunks[chunkIndex].indices, instanceBatch && instanceBatch.transformsArray);
              }
            } else {
              createMesh(layer, geometryType, color, batch.vertices, null, instanceBatch && instanceBatch.transformsArray);
            }
          };
          for (let batchIndex = 0; batchIndex < dxfData.batches.length; batchIndex++) {
            let batch = dxfData.batches[batchIndex];
            let layer = drawing.layers.find(x => x.layerID === (batch.key.layerName || ''));
            if (!layer || !layer.visible) {
              continue;
            }
            let geometryType = batch.key.geometryType;
            if (geometryType == BatchingKey.GeometryType.BLOCK_INSTANCE || geometryType == BatchingKey.GeometryType.POINT_INSTANCE) {
              if (batch.hasOwnProperty("transformsOffset")) {
                let instanceBatch = blocks[batch.key.blockName];
                if (!instanceBatch) {
                  continue;
                }
                for (let batch_ of instanceBatch.batches) {
                  createBatch(layer, batch_, batch);
                }
              }
            } else {
              if (batch.key.blockName !== null) {
                /* Block definition. */
                continue;
              }
              createBatch(layer, batch);
            }
          }
          for (let key in layerVertexData) {
            let vertexData = layerVertexData[key];
            let mesh = new Kingfisher.Mesh(key, data.scene);
            mesh.neverPickable = true;
            mesh.parent = data.parentNode;
            let geometry = new Kingfisher.Geometry(Kingfisher.getNanoID(), data.scene, undefined, true, mesh);
            geometry._boundingInfo = new Kingfisher.BoundingInfo(new Vector3(), new Vector3());
            let positions = vertexData.positions;
            if (positions.length <= 1) {
              positions = vertexData.positions[0];
            } else {
              positions = new Float32Array(vertexData.positionCount * 3);
              let offset = 0;
              for (let pos of vertexData.positions) {
                positions.set(pos, offset);
                offset += pos.length;
              }
            }
            geometry.setVerticesData(Kingfisher.VertexBuffer.PositionKind, positions, false);
            let indices = vertexData.indices;
            if (indices.length <= 1) {
              indices = vertexData.indices[0];
            } else {
              indices = new Uint32Array(vertexData.indexCount);
              let offset = 0;
              for (let index of vertexData.indices) {
                indices.set(index, offset);
                offset += index.length;
              }
            }
            geometry.setIndices(indices, null, true);
            let color = vertexData.color;
            mesh.randomId.set((color >> 16 & 0xff) / 255, (color >> 8 & 0xff) / 255, (color >> 0 & 0xff) / 255);
            mesh.material = vertexData.isLine ? data.lineMaterial : data.fillMaterial;
            let layerID = vertexData.layerID;
            if (!data.meshes[layerID]) {
              data.meshes[layerID] = [];
            }
            data.meshes[layerID].push(mesh);
          }
        }
      }
    } else {
      let layerVisible = new Map(drawing.layers.map(x => [x.layerID, x.visible]));
      for (let key in data.meshes) {
        if (layerVisible.get(key)) {
          for (let mesh of data.meshes[key]) {
            mesh.enableState = 1;
          }
        }
      }
    }
    data.lineMaterial.alpha = drawing.opacity;
    data.fillMaterial.alpha = drawing.opacity;
    let scaling = drawing.scaling;
    data.parentNode.enableState = 1;
    data.parentNode.scaling.x = scaling;
    data.parentNode.scaling.z = scaling;
    data.parentNode.position.x = -drawing.centerX;
    data.parentNode.position.z = -drawing.centerY;
    return true;
  }
}
export default new DxfDrawingBuilder();