import { ref, watch } from 'vue';
import env from '@/model/env';
import oemManager from '@/utils/oem';
export default {
  name: 'contactUs',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {
    emit
  }) {
    let showDialog = ref(false);
    let logo = ref('');
    let title = ref('');
    watch(props, newValue => {
      showDialog.value = newValue.show;
    });
    watch(showDialog, () => {
      emit('propChange', showDialog.value);
    });
    return {
      showDialog,
      updateTime: env.updateTime,
      version: env.version,
      packTime: window.packTime,
      title,
      logo
    };
  }
};