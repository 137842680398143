import KingfisherService from '../utils/request';
import FetchService from '../utils/fetch';
class AuthorityService {
  /**
   * Creates an instance of AuthorityService.
   * @memberOf  AuthorityService
   */
  constructor() {
    this.module = 'authority';
    this.kingfisherService = new KingfisherService({});
    this.fetchService = new FetchService({});
  }

  /**
   * 获取用户
   * @returns Promise Ajax请求返回并处理后的Promise
   */
  getProjectRole(id) {
    return this.kingfisherService.get(`/repo/user/find-project-role?projectId=${id}`).then(response => {
      return response;
    });
  }

  /**
   * 删除用户权限
   * @returns Promise Ajax请求返回并处理后的Promise
   */
  deleteUser(row) {
    return this.kingfisherService.delete("/repo/user/del-user-role", row).then(response => {
      return response;
    });
  }

  /**
   * 查询用户列表
   * @returns Promise Ajax请求返回并处理后的Promise
   */
  getUser(id) {
    return this.kingfisherService.get(`/repo/user/get-all-user/${id}`).then(response => {
      return response;
    });
  }

  /**
   * 更新用户权限
   * @returns Promise Ajax请求返回并处理后的Promise
   */
  update(param) {
    return this.kingfisherService.post("/repo/user/update-user-role", param).then(response => {
      return response;
    });
  }
}
export default new AuthorityService();