import Stats from 'stats.js';
import Const from '../../utils/const';
import ProjectService from '../../service/project';
import _ from 'lodash';
import oemManager from '@/utils/oem';
import { computed, getCurrentInstance, onMounted, onUnmounted, ref } from 'vue';
export default {
  name: 'KfWeb3d',
  props: {
    props: {
      type: Object,
      default: () => {
        return {
          sceneUrl: "",
          simpleSceneUrl: "",
          animation: "",
          simpleAnimation: "",
          id: "web3d" + new Date().getTime(),
          showFps: false,
          showInspector: false,
          background: "",
          useSimpleScene: false,
          showPoem: true,
          msgSync: false,
          group: "kingfisher",
          role: "main",
          fullscreen: false
        };
      }
    },
    initScene: {
      type: Function,
      default: () => {
        return null;
      }
    },
    eventScript: {
      type: String
    }
  },
  widgetConfig: {
    parent: "props",
    rank: 2,
    label: {
      zh: Kf.t('3dComponent')
    },
    defaultSize: {
      x: 300,
      y: 200
    },
    component: "KfWeb3d",
    category: Kf.t('3d'),
    display: true,
    properties: [{
      name: "sceneUrl",
      propertyGroup: "propModel",
      rank: 2,
      label: {
        zh: Kf.t('Scene')
      },
      type: "scene",
      notnull: false,
      defaultValue: ""
    }, {
      name: "id",
      propertyGroup: "propModel",
      rank: 1,
      label: {
        zh: "ID"
      },
      type: "string",
      notnull: false,
      defaultValue: "Web3d" + new Date().getTime()
    }, {
      name: "camera",
      propertyGroup: "propModel",
      rank: 3,
      label: {
        zh: Kf.t('InitialCamera')
      },
      type: "camera",
      notnull: false,
      defaultValue: ""
    }, {
      name: "puzzleEvent",
      propertyGroup: "propModel",
      rank: 3,
      label: {
        zh: Kf.t('TriggerEvent')
      },
      type: "puzzleEvent",
      notnull: false,
      defaultValue: ""
    }, {
      name: "logo",
      propertyGroup: "propModel",
      rank: 10,
      label: {
        zh: "logo"
      },
      type: "string",
      notnull: false,
      defaultValue: null
    }, {
      name: "logoSize",
      propertyGroup: "propModel",
      rank: 15,
      label: {
        zh: "Logo" + Kf.t('Dimensions')
      },
      type: "string",
      notnull: false,
      defaultValue: null
    }, {
      name: "msgSync",
      propertyGroup: "propModel",
      rank: 25,
      label: {
        zh: Kf.t('MultiscreenSynchronization')
      },
      type: "boolean",
      notnull: false,
      defaultValue: false
    }, {
      name: "group",
      propertyGroup: "propModel",
      rank: 30,
      label: {
        zh: Kf.t('ScreenGroup')
      },
      type: "string",
      notnull: false,
      defaultValue: "kingfisher"
    }, {
      name: "role",
      propertyGroup: "propModel",
      rank: 35,
      label: {
        zh: Kf.t('ScreenRoles')
      },
      type: "string",
      notnull: false,
      defaultValue: "main"
    }, {
      name: "backgroundColor",
      propertyGroup: "background",
      rank: 2,
      label: {
        zh: Kf.t('BackgroundColor')
      },
      type: "color",
      notnull: false,
      defaultValue: "&&backgroundColor"
    }, {
      name: "borderColor",
      propertyGroup: "border",
      rank: 2,
      label: {
        zh: Kf.t('BorderLineColor')
      },
      type: "color",
      notnull: false,
      defaultValue: "&&borderColor"
    }, {
      name: "fullscreen",
      propertyGroup: "propModel",
      rank: 40,
      label: {
        zh: Kf.t('FullScreenControl')
      },
      type: "boolean",
      notnull: false,
      defaultValue: false
    }, {
      name: "pipeline",
      propertyGroup: "propModel",
      rank: 50,
      label: {
        zh: Kf.t('PostEffect')
      },
      type: "pipelines",
      notnull: false,
      defaultValue: []
    }, {
      name: "pipeline_sel",
      propertyGroup: "propModel",
      rank: 60,
      label: {
        zh: Kf.t('PostEffect')
      },
      type: "pipeline",
      notnull: false,
      defaultValue: null
    }],
    methods: [],
    events: [{
      name: "load",
      rank: 1,
      label: {
        zh: "场景加载后事件"
      }
    }, {
      name: "tap",
      rank: 1,
      label: {
        zh: "点击事件"
      }
    }, {
      name: "loadingHide",
      rank: 1,
      label: {
        zh: "加载画面隐藏后"
      }
    }, {
      name: "render",
      rank: 1,
      label: {
        zh: "渲染事件"
      }
    }]
  },
  setup(props) {
    // watch(()=>props.props.sceneUrl,(n,o)=>{
    //   loadSceneFromUrl(n);
    // });

    let version = 0;
    let loadingTextIndex = 0;
    const domCanvas = ref();
    const {
      proxy
    } = getCurrentInstance();
    let netMsgRouter = null;
    let stats = null;
    let loadingTextTimer = null;
    let loadStartTime = 0;
    let expectedLoadingTime = 0;
    let tapHandlers = [];
    let currSceneId = 0;
    let puzzle = null;
    let loaded = false;
    let handleVueEvent = null;
    let currScene = null;
    const issueEvent = function (event, params) {
      window.postMessage({
        action: "puzzleEvent",
        event: {
          name: event,
          args: _.cloneDeep(params)
        }
      }, "*");
    };
    let frameProp = ref({
      style: {},
      className: 'none',
      tip: ''
    });
    const createEngine = function () {
      let global = window.Kc || {};
      window.Kc = global;
      let canvas = domCanvas.value;
      canvas.oncontextmenu = function (e) {
        e.preventDefault();
        e.stopPropagation();
      };
      global[props.props.id + "_canvas"] = canvas;
      let engine = new window.Kingfisher.Engine(canvas, true);
      console.log("create engine.");
      engine.netMsgRouter = netMsgRouter;
      try {
        window.Kf.requireLib(engine);
      } catch (e) {
        console.log(e);
      }
      global[props.props.id + "_engine"] = engine;
      let scalingLevel = getHardwareScalingLevel(canvas.clientWidth, canvas.clientHeight);
      engine.setHardwareScalingLevel(scalingLevel);
      function resizeHandler() {
        let scaling = getHardwareScalingLevel(canvas.clientWidth, canvas.clientHeight);
        engine.setHardwareScalingLevel(scaling);
        engine.resize(canvas.clientWidth, canvas.clientHeight);
      }
      window.addEventListener("resize", resizeHandler);
      let resizeObserver = new ResizeObserver(resizeHandler);
      resizeObserver.observe(canvas);
      initLoader();
    };
    const getCanvas = function () {
      window.Kc = window.Kc || {};
      return window.Kc[props.props.id + "_canvas"];
    };
    const getEngine = function () {
      window.Kc = window.Kc || {};
      return window.Kc[props.props.id + "_engine"];
    };
    const getSceneManager = function () {
      window.Kc = window.Kc || {};
      return window.Kc[props.props.id + "_sceneManager"];
    };
    const getScene = function (id) {
      if (id == null) {
        id = props.props.id;
      }
      window.Kc = window.Kc || {};
      return window.Kc[id + "_scene"];
    };
    const initStats = function () {
      if (Const.develop && props.props.showFps) {
        stats = new Stats();
        stats.setMode(0);
        stats.domElement.style.position = 'absolute';
        stats.domElement.style.left = '5px';
        stats.domElement.style.top = '5px';
        domCanvas.value.parentNode.appendChild(stats.domElement);
      }
    };
    const getHardwareScalingLevel = function (width, height) {
      const k = 2.5;
      const w = 1920 * k;
      const h = 1080 * k;
      if (width <= w && height <= h) {
        return 1 / window.devicePixelRatio;
      }
      if (width / height > w / h) {
        return width / w;
      } else {
        return height / h;
      }
    };
    let defaultHandler = ref({});
    const eventHandler = computed(() => defaultHandler.value);
    const onLoaderHide = function () {
      clearInterval(loadingTextTimer);
      localStorage.setItem(props.props.sceneUrl + "_loadingtime", new Date().getTime() - loadStartTime - 1000);
      if (window.onSceneLoaded) {
        window.onSceneLoaded();
      }
      if (eventHandler.value.loadingHide) {
        eventHandler.value.loadingHide();
      }
    };
    const showLoadingText = function () {
      let loadingText = "";
      if (props.props.showPoem) {
        /*loadingText = lines[Math.floor(loadingTextIndex / 5)];
        loadingTextIndex++;
          if (loadingTextIndex === lines.length * 5) {
          loadingTextIndex = 0;
        }*/
      }
      let engine = getEngine();
      if (engine != null && engine.loadingScreen != null) {
        let loadingTime = new Date().getTime() - loadStartTime;
        let percentage = (loadingTime / expectedLoadingTime).toFixed(2);
        if (percentage > 0.99) {
          percentage = 0.99;
        }
        //engine.loadingScreen.loadingUIText = loadingText + "<br/>" + (percentage * 100).toFixed(0) + "%";
        if (engine.loadingScreen.updateProgress) {
          engine.loadingScreen.updateProgress((percentage * 100).toFixed(0));
        }
      }
    };
    const initLoader = function () {
      let canvas = getCanvas();
      let engine = getEngine();
      let loadingScreen = new (window.Kingfisher.Runtime.LoadingScreenV2 || window.Kingfisher.Runtime.LoadingScreen)(canvas);
      if (props.props.logo) {
        loadingScreen.customLogoSrc = props.props.logo;
        loadingScreen.style = 'custom';
        if (props.props.logoSize) {
          let size = props.props.logoSize.split(",");
          if (size.length === 2) {
            loadingScreen.logoSize = {
              width: size[0],
              height: size[1]
            };
          }
        }
        loadingScreen.hideMiniLogo = true;
      } else if (!oemManager.beSame('product', 'logo')) {
        loadingScreen.logoSrc = oemManager.getImage('product', 'logo', 'space');
        loadingScreen.hideMiniLogo = true;
      }
      engine.loadingScreen = loadingScreen;
      loadingScreen.onHide = onLoaderHide;
    };
    const pointHandler = function (pointerInfo) {
      let ray = pointerInfo.pickInfo.ray;
      let k = ray.intersectsPlane(currScene.activeCamera.plane);
      if (k) {
        switch (pointerInfo.type) {
          case window.Kingfisher.PointerEventTypes.POINTERTAP:
            break;
          case window.Kingfisher.PointerEventTypes.POINTERDOWN:
            break;
          case window.Kingfisher.PointerEventTypes.POINTERMOVE:
            break;
          case window.Kingfisher.PointerEventTypes.POINTERUP:
            break;
        }
      }
    };
    let puzzleLogic = null;
    const loadSceneFromUrl = function (sceneUrl, animation, loadListener, tapListener) {
      let p = sceneUrl.indexOf("/");
      let scenePart = [sceneUrl.substring(0, p), sceneUrl.substring(p + 1)];
      ProjectService.getSceneById(scenePart[1]).then(function (res) {
        let schemes = {
          'root://': '/cloud/scene/' + scenePart[0],
          'resources://': '/cloud/resources/',
          'lib://': '/cloud/space/static/'
        };
        window.Kingfisher.FileTools.RegisterSchemes(schemes);
        let url = "root://" + scenePart[1];
        if (url.indexOf('.') < 0) {
          url += ".json";
        }
        loadScene(sceneUrl, url, "", function (scene) {
          try {
            if (window.puzzleDispose) {
              window.puzzleDispose();
            }
            if (loadListener) loadListener();
          } catch (e) {
            console.error(e);
          }
        }, tapListener);
      });
    };
    const loadScene = function (id, scenePath, animation, onLoad, onTap) {
      currSceneId = id;
      tapHandlers = tapHandlers || {};
      tapHandlers[id] = onTap;
      let sceneManager = window.Kc[props.props.id + "_sceneManager"];
      let rootUrl = scenePath.substring(0, scenePath.lastIndexOf("/") + 1);
      let sceneFile = scenePath.substring(scenePath.lastIndexOf("/") + 1);
      loadingTextTimer = setInterval(showLoadingText, 1000);
      loadStartTime = new Date().getTime();
      puzzle.switchScene(window.scene, sceneManager, id, rootUrl, sceneFile, 1, 1, (loadedScene, isFirstTime) => {
        currScene = loadedScene.subScene;
        window.scene = currScene;
        window.needFireAfterLoad = true;
        window.Kc[props.props.id + "_scene"] = currScene;
        if (currScene) {
          if (props.props.showInspector && window.Kingfisher.Inspector && Const.develop) {
            window.Kingfisher.Inspector.Show(currScene, {
              overlay: true
            });
          }
          if (isFirstTime) {
            //currScene.onPointerObservable.add(pointHandler);
            currScene.activeCamera.attachControl(getCanvas(), true);
            currScene.forcePickable = true;
            initTools();
            currScene.activeCamera.enableLimit = false;
          }
        }
      });
    };
    const setSceneRenderEnabled = function (id, value, duration, callback) {
      let sceneManager = window.Kc[props.props.id + "_sceneManager"];
      if (sceneManager) {
        sceneManager.setRenderEnabled(id, value, duration, callback);
      }
    };
    const initScene = function () {
      if (props.props.disabled) {
        loaded = true;
        return;
      }
      window.Kingfisher.Runtime.Puzzle = window.Kingfisher.Runtime.PuzzleInterface;
      puzzle = window.Kingfisher.Runtime.Puzzle;
      if (props.props.msgSync) {
        try {
          netMsgRouter = new window.Kingfisher.Runtime.NetMsgRouter(props.props.group, props.props.role, findWsUrl());
          handleVueEvent = new window.Kingfisher.Runtime.HandleVueEvent(netMsgRouter, undefined, {
            context: proxy
          });
        } catch (e) {
          console.error(e);
        }
      }
      createEngine();
      let sceneManager = window.Kc[props.props.id + "_sceneManager"] || new window.Kingfisher.SceneManager(getEngine());
      window.Kc[props.props.id + "_sceneManager"] = sceneManager;
      window.Kingfisher.Database.IDBStorageEnabled = true;
      let engine = getEngine();
      if (props.props.useSimpleScene && props.props.simpleSceneUrl) {
        //loadSceneById(props.props.simpleSceneUrl, props.props.simpleAnimation, eventHandler.value.load, eventHandler.value.tap);
      } else if (props.props.sceneUrl) {
        //loadSceneById(props.props.sceneUrl, props.props.animation, eventHandler.value.load, eventHandler.value.tap);
      } else {
        window.Kingfisher.FileTools.RegisterSchemes({
          'lib://': '/cloud/space/static/'
        });
        if (props.initScene) {
          props.initScene(sceneManager);
        }
      }
      engine.runRenderLoop(() => {
        try {
          let currEngine = getEngine();
          if (currEngine && !currEngine.isDisposed) {
            try {
              if (eventHandler.value.render) {
                eventHandler.value.render(engine, sceneManager);
              }
            } catch (e) {
              console.error(e);
            }
            sceneManager.rootScene.render();
          }
        } catch (e) {
          console.error(e);
        }
        if (stats) {
          stats.update();
        }
      });
    };
    const initBackground = function () {
      /*if (props.props.background && props.props.background != "") {
            domCanvas.value.parentNode.style.backgroundImage = "url('" + require("@/" + props.props.background) + "')";
            domCanvas.value.parentNode.style.backgroundSize = "100% 100%";
          }*/
    };
    const findWsUrl = function () {
      if (Const.develop) {
        return "ws://dev.kingfisher.live:8000/ws";
      } else {
        let host = window.location.href.substring(window.location.href.indexOf("://") + 3);
        let isHttps = window.location.href.startsWith("https://");
        return (isHttps ? "wss://" : "ws://") + host.substring(0, host.indexOf("/")) + "/ws";
      }
    };
    const invokeMethod = function (target, method, ...params) {
      handleVueEvent.invokeVueMethod(target, method, ...params);
    };
    const updateData = function (target, field, value) {
      handleVueEvent.updateVueData(target, field, value);
    };
    let fullscreen = ref(false);
    let root = ref();
    const doFullscreen = function () {
      if (fullscreen.value) {
        fullscreen.value = false;
        exitFullscreen();
      } else {
        fullscreen.value = true;
        requestFullscreen(root.value);
      }
    };
    const requestFullscreen = function (elem) {
      try {
        if (elem.requestFullscreen) elem.requestFullscreen();else if (elem.mozRequestFullScreen) elem.mozRequestFullScreen();else if (elem.webkitRequestFullscreen) elem.webkitRequestFullscreen();else if (elem.msRequestFullscreen) elem.msRequestFullscreen();
      } catch (e) {}
    };
    const exitFullscreen = function () {
      if (document.exitFullScreen) {
        document.exitFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    };
    const handleMessage = function (e) {
      if (typeof e.data == "object") {
        let message = e.data;
        if (message.action === "refreshScene") {
          if (message.scene === props.props.sceneUrl) {}
        }
      }
    };
    const initTools = function () {
      if (!currScene || currScene.isDisposed) {
        return;
      }
      if (!currScene.c3dTools) {
        currScene.c3dTools = {};
      }
    };
    onMounted(() => {
      let login = function () {
        if (window.Kf && window.Kf.login && window.Kf.ssoReady) {
          clearInterval(timer);
          console.log('进入3D组件');
          initBackground();
          initStats();
          let loadingTime = localStorage.getItem(props.props.id + "_loadingtime");
          if (loadingTime == null) {
            loadingTime = 10 * 1000;
          }
          expectedLoadingTime = loadingTime;
          let initSceneID = 'initScene' + props.props.id;
          window[initSceneID] = () => {
            initScene();
            delete window[initSceneID];
          };
          console.log('initSceneID ' + initSceneID);
          window.Kf.loadLib("static/kingfisher.lib", "static/kingfisher.lic", initSceneID);
          window.addEventListener('message', handleMessage);

          // loadSceneFromUrl("12503/revisions/31206_23");
        }
      };
      let timer = setInterval(login, 50);
    });
    onUnmounted(() => {
      try {
        console.log("销毁三维组件");
        let engine = getEngine();
        if (engine) {
          if (loadingTextTimer) {
            clearInterval(loadingTextTimer);
          }
          if (engine.loadingScreen) {
            engine.loadingScreen.hideLoadingUI();
          }
          engine.dispose();
          console.log("引擎已经销毁");
          delete window.Kc[props.props.id + "_engine"];
          delete window.Kc[props.props.id + "_scene"];
          delete window.Kc[props.props.id + "_sceneManager"];
          delete window.Kc[props.props.id + "_container"];
        }
        console.log("组件已经销毁");
      } catch (e) {
        console.error(e);
      }
    });
    return {
      domCanvas,
      getSceneManager,
      getScene,
      getEngine,
      getCanvas,
      loadScene,
      invokeMethod,
      updateData,
      setSceneRenderEnabled,
      issueEvent,
      loaded,
      eventHandler,
      frameProp,
      doFullscreen,
      root,
      fullscreen
    };
  }
};