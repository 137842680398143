import "core-js/modules/es.array.push.js";
import { ref, computed } from 'vue';
import { getTypeName } from "@/model/modelOptions";
export const commCompsLib = ref(); //公共组件库信息
export const themeList = ref([]);
export const selectedThemeId = ref(-1);
export const compInited = ref(false);
export const compMapById = ref(new Map());
export const compMapByName = ref(new Map());
export const compGroupList = ref({});
export const currThemeWidgets = ref([]);
export const selectedType = ref('');
export const getModelById = function (id) {
  return compMapById.value.get(id);
};
export const getModelByName = function (id) {
  return compMapByName.value.get(id);
};
let locale = window.Kf.locale.value.replace("-", "");
const widgetTreeData = computed(() => {
  var _currThemeWidgets$val;
  let res = [];
  if (((_currThemeWidgets$val = currThemeWidgets.value) === null || _currThemeWidgets$val === void 0 ? void 0 : _currThemeWidgets$val.length) > 0) {
    let _list = currThemeWidgets.value.filter(item => {
      if (selectedType.value === '') {
        return item.subType === undefined || item.subType === null || item.subType === 'null' || item.subType === '';
      } else {
        return item.subType === selectedType.value;
      }
    });
    res = loadTreeData(_list);
  }
  console.log("--widgetTreeData-", res, currThemeWidgets.value);
  return res;
});
const getCategory = function (item) {
  let _category = item.category;
  if (item.languageInterpret && typeof item.languageInterpret === 'string') {
    var _data$category;
    let _data = JSON.parse(item.languageInterpret);
    if (_data !== null && _data !== void 0 && (_data$category = _data.category) !== null && _data$category !== void 0 && _data$category[locale]) {
      _category = _data.category[locale];
    }
  }
  return _category;
};
const loadTreeData = function (list, type) {
  let res = [];
  if ((list === null || list === void 0 ? void 0 : list.length) > 0) {
    list.forEach(item => {
      item.label = item.label === undefined || item.label === null || item.label === '' ? item.name : item.label && item.label[locale] ? item.label[locale] : item.label;
      item.value = item.value === undefined || item.value === null || item.value === '' ? item.uuid : item.value;
      let _category = getCategory(item);
      let parts = [];
      if (_category === undefined || _category === null || _category === 'null') {
        parts = [Kf.t('Default')];
      } else {
        parts = _category.split('/');
      }
      let currentLevel = res;
      parts.forEach((part, index) => {
        var _existingNode;
        let existingNode = currentLevel.find(node => node.label === part);
        if (!existingNode) {
          existingNode = {
            id: part + item.uuid,
            _uuid: item.uuid,
            label: part,
            value: part,
            children: [],
            data: []
          };
          currentLevel.push(existingNode);
        }
        if ((_existingNode = existingNode) !== null && _existingNode !== void 0 && _existingNode.children) {
          currentLevel = existingNode.children;
        }
        if (index === parts.length - 1) {
          let node = existingNode && existingNode.data ? existingNode.data.find(node => node.uuid === item.uuid) : null;
          if (!node) {
            node = Object.assign({
              id: item.uuid,
              label: item.name,
              value: item.uuid
            }, item);
            existingNode.data.push(node);
          }
        }
      });
    });
  }
  return res;
};
export default {
  widgetTreeData,
  loadTreeData
};