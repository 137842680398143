import "core-js/modules/es.array.push.js";
import { intersectCheckSegmentAndBox, lineIntersect, Vector2 } from "../maths/vector";
import { smDatas } from "../model/smCollectionData";
import { smMesh2DCache, smMesh3DCache } from "../model/smCollectionData";
let v2Temp0 = new Vector2();
let v2Temp1 = new Vector2();
let v2Temp2 = new Vector2();
let v2Temp3 = new Vector2();
let distance4 = (x0, y0, x1, y1) => {
  let dx = x0 - x1;
  let dy = y0 - y1;
  return Math.sqrt(dx * dx + dy * dy);
};
let wallIntersect = (wall, wallPoints, point, segment, pointCache) => {
  let thickness = wall.thickness * -0.5;
  let p0, p1;
  let n = segment.isArc ? segment.pointA.uid === point.uid ? segment.normalA : segment.normalB : segment.normal;
  let t = n.scale(thickness);
  if (point.finalSegments.length === 1) {
    p0 = new Vector2(point.x + t.x, point.y + t.y);
    p1 = new Vector2(point.x - t.x, point.y - t.y);
  } else {
    let finalSegements = point.finalSegments;
    let sIndex = finalSegements.findIndex(x => x.uid === segment.uid);
    let s0 = finalSegements[(sIndex + 1) % finalSegements.length];
    let s1 = finalSegements[(sIndex + finalSegements.length - 1) % finalSegements.length];
    if (segment.pointA.uid === point.uid) {
      let st = s0;
      s0 = s1;
      s1 = st;
    }
    let k0 = segment.pointA.uid === point.uid ? segment.uid + '>' + s0.uid : s0.uid + '>' + segment.uid;
    let k1 = segment.pointA.uid === point.uid ? s1.uid + '>' + segment.uid : segment.uid + '>' + s1.uid;
    if (pointCache[k0]) {
      p0 = pointCache[k0];
    } else {
      let n0 = s0.isArc ? s0.pointA.uid === point.uid ? s0.normalA : s0.normalB : s0.normal;
      let thickness0 = wall.thickness * 0.5;
      if (s0.pointA.uid === point.uid !== (segment.pointA.uid === point.uid)) {
        thickness0 *= -1;
      }
      let t0 = n0.scale(thickness0);
      v2Temp0.set(point.x + t.x, point.y + t.y);
      v2Temp1.set(v2Temp0.x + n.y, v2Temp0.y - n.x);
      v2Temp2.set(point.x + t0.x, point.y + t0.y);
      v2Temp3.set(v2Temp2.x + n0.y, v2Temp2.y - n0.x);
      p0 = lineIntersect(v2Temp0, v2Temp1, v2Temp2, v2Temp3);
      if (!p0) {
        p0 = new Vector2(point.x + t.x, point.y + t.y);
      } else {
        pointCache[k0] = p0;
      }
    }
    if (pointCache[k1]) {
      p1 = pointCache[k1];
    } else {
      let n1 = s1.isArc ? s1.pointA.uid === point.uid ? s1.normalA : s1.normalB : s1.normal;
      let thickness1 = wall.thickness * 0.5;
      if (s1.pointA.uid === point.uid !== (segment.pointA.uid === point.uid)) {
        thickness1 *= -1;
      }
      let t1 = n1.scale(thickness1);
      v2Temp0.set(point.x - t.x, point.y - t.y);
      v2Temp1.set(v2Temp0.x + n.y, v2Temp0.y - n.x);
      v2Temp2.set(point.x - t1.x, point.y - t1.y);
      v2Temp3.set(v2Temp2.x + n1.y, v2Temp2.y - n1.x);
      p1 = lineIntersect(v2Temp0, v2Temp1, v2Temp2, v2Temp3);
      if (!p1) {
        p1 = new Vector2(point.x - t.x, point.y - t.y);
      } else {
        pointCache[k1] = p1;
      }
    }
  }
  wallPoints.push(p0.x, p0.y, p1.x, p1.y);
};
const buildNormalWallMesh = function (wall) {
  let i;
  //dirty check
  for (i = 0; i < wall.segments.length; i++) {
    let s = wall.segments[i];
    s.checkDirty();
  }
  for (i = 0; i < wall.points.length; i++) {
    let p = wall.points[i];
    p.checkDirty();
  }
  let objects = smDatas.objects;
  let wallSegments = [];
  let pointCache = {};
  //wall build
  for (i = 0; i < wall.segments.length; i++) {
    let s = wall.segments[i];
    if (!s.isValid) {
      continue;
    }
    let wallPoints = [];
    wallPoints.segment = s;
    wallIntersect(wall, wallPoints, s.pointA, s, pointCache);
    if (s.isArc) {
      let center = s.center;
      let radius = s.radius * Math.sign(s.angle);
      let radius0 = radius - wall.thickness * 0.5;
      let radius1 = radius + wall.thickness * 0.5;
      let startAngle = s.directionA;
      let tessellation = s.tessellation;
      let step = s.stepAngle;
      for (let j = 1; j < tessellation; j++) {
        let angle = startAngle + step * j;
        let sin_ = Math.sin(angle);
        let cos_ = Math.cos(angle);
        wallPoints.push(center.x + radius0 * cos_, center.y + radius0 * sin_);
        wallPoints.push(center.x + radius1 * cos_, center.y + radius1 * sin_);
      }
    }
    wallIntersect(wall, wallPoints, s.pointB, s, pointCache);
    wallSegments.push(wallPoints);
  }
  let meshData0 = new Kingfisher.KfMeshData('3d', true, false, false); //正面
  let meshData1 = new Kingfisher.KfMeshData('3d', true, false, false); //背面
  let meshData2 = new Kingfisher.KfMeshData('3d', true, false, false); //顶面
  let meshData3 = new Kingfisher.KfMeshData('3d', true, false, false); //底面
  let meshData4 = new Kingfisher.KfMeshData('3d', true, false, false); //侧面

  let poss0 = meshData0.poss;
  let uvs0 = meshData0.uvs;
  let indices0 = meshData0.indices;
  let poss1 = meshData1.poss;
  let uvs1 = meshData1.uvs;
  let indices1 = meshData1.indices;
  let poss2 = meshData2.poss;
  let uvs2 = meshData2.uvs;
  let indices2 = meshData2.indices;
  let poss3 = meshData3.poss;
  let uvs3 = meshData3.uvs;
  let indices3 = meshData3.indices;
  let poss4 = meshData4.poss;
  let uvs4 = meshData4.uvs;
  let indices4 = meshData4.indices;
  for (i = 0; i < wallSegments.length; i++) {
    let s = wallSegments[i];
    let height = wall.height;
    let elevation = 0; //wall.floor ? wall.floor.elevation : 0;
    let heightElevation = elevation + height;
    let segment = s.segment;
    let thickness = wall.thickness;
    let normal = s.segment.normal;
    let uscaling = height ? 1 / height : 1;
    let u1 = s.segment.length * uscaling;
    let u2 = thickness * uscaling;

    //创建上下边
    let q = meshData2.vertexCount;
    if (segment.pointA.finalSegments.length > 2) {
      poss2.push(segment.pointA.x, elevation, segment.pointA.y);
      poss3.push(segment.pointA.x, heightElevation, segment.pointA.y);
      indices2.push(q, q + 1, q + 2);
      indices3.push(q + 1, q, q + 2);
      q++;
    }
    let length_4 = s.length - 4;
    for (let j = 0; j < s.length; j += 4) {
      poss2.push(s[j], elevation, s[j + 1]);
      poss2.push(s[j + 2], elevation, s[j + 3]);
      poss3.push(s[j], heightElevation, s[j + 1]);
      poss3.push(s[j + 2], heightElevation, s[j + 3]);
      uvs2.push(0, 1);
      uvs2.push(0, 1);
      uvs3.push(0, 0);
      uvs3.push(0, 0);
      if (j < length_4) {
        indices2.push(q + 1, q, q + 2);
        indices2.push(q + 3, q + 1, q + 2);
        indices3.push(q, q + 1, q + 2);
        indices3.push(q + 1, q + 3, q + 2);
        q += 2;
      }
    }
    if (segment.pointB.finalSegments.length > 2) {
      poss2.push(segment.pointB.x, elevation, segment.pointB.y);
      poss3.push(segment.pointB.x, heightElevation, segment.pointB.y);
      indices2.push(q + 1, q, q + 2);
      indices3.push(q, q + 1, q + 2);
    }

    //创建起始、结束侧边
    q = meshData4.vertexCount;
    if (s.segment.pointA.finalSegments.length === 1) {
      poss4.push(s[0], elevation, s[1]);
      poss4.push(s[0], heightElevation, s[1]);
      poss4.push(s[2], elevation, s[3]);
      poss4.push(s[2], heightElevation, s[3]);
      uvs4.push(0, 1);
      uvs4.push(0, 0);
      uvs4.push(u2, 1);
      uvs4.push(u2, 0);
      indices4.push(q + 1, q, q + 2);
      indices4.push(q + 3, q + 1, q + 2);
    }
    q = meshData4.vertexCount;
    if (s.segment.pointB.finalSegments.length === 1) {
      let p = s.length - 4;
      poss4.push(s[p], elevation, s[p + 1]);
      poss4.push(s[p], heightElevation, s[p + 1]);
      poss4.push(s[p + 2], elevation, s[p + 3]);
      poss4.push(s[p + 2], heightElevation, s[p + 3]);
      uvs4.push(u2, 1);
      uvs4.push(u2, 0);
      uvs4.push(0, 1);
      uvs4.push(0, 0);
      indices4.push(q, q + 1, q + 2);
      indices4.push(q + 1, q + 3, q + 2);
    }

    //创建正面
    let accessories = s.segment.accessories.filter(x => {
      let a = objects[x];
      return a && a.isValid && a.attached === 'embed';
    });
    if (!accessories.length) {
      //普通矩形正面、背面
      q = meshData0.vertexCount;
      let length_4 = s.length - 4;
      ///????
      let ua = 0;
      let ub = 0;
      for (let j = 0; j < s.length; j += 4) {
        poss0.push(s[j], elevation, s[j + 1]);
        poss0.push(s[j], heightElevation, s[j + 1]);
        poss1.push(s[j + 2], elevation, s[j + 3]);
        poss1.push(s[j + 2], heightElevation, s[j + 3]);
        uvs0.push(ua, 1, ua, 0);
        uvs1.push(ub, 1, ub, 0);
        if (j < length_4) {
          ua += distance4(s[j], s[j + 1], s[j + 4], s[j + 5]) * uscaling;
          ub += distance4(s[j + 2], s[j + 3], s[j + 6], s[j + 7]) * uscaling;
          indices0.push(q, q + 1, q + 2);
          indices0.push(q + 1, q + 3, q + 2);
          indices1.push(q + 1, q, q + 2);
          indices1.push(q + 3, q + 1, q + 2);
          q += 2;
        }
      }
    } else {
      //挖空矩形
      let nx = normal.x * thickness * 0.5;
      let ny = normal.y * thickness * 0.5;
      let polygon = [0, elevation, 0, heightElevation, s.segment.length, heightElevation, s.segment.length, elevation];
      let holl = [];

      //挖空矩形侧面
      for (let i = 0; i < accessories.length; i++) {
        let a = objects[accessories[i]];
        let boundingBox = a.boundingBox;
        let floor = Math.max(0, a.position.y + boundingBox[1]);
        let ceil = Math.min(height, a.position.y + boundingBox[4]);
        floor += elevation;
        ceil += elevation;
        let reverse = Math.abs(Math.abs(a.direction - s.segment.direction) - Math.PI) < 0.1;
        let bMinX = boundingBox[0];
        let bMinY = boundingBox[2];
        let bMaxX = boundingBox[3];
        let bMaxY = boundingBox[5];
        let minX;
        let minY;
        let maxX;
        let maxY;
        if (reverse) {
          minX = -bMaxX * -normal.y + a.position.x;
          minY = -bMaxX * normal.x + a.position.z;
          maxX = -bMinX * -normal.y + a.position.x;
          maxY = -bMinX * normal.x + a.position.z;
        } else {
          minX = bMinX * -normal.y + a.position.x;
          minY = bMinX * normal.x + a.position.z;
          maxX = bMaxX * -normal.y + a.position.x;
          maxY = bMaxX * normal.x + a.position.z;
        }

        // left
        q = meshData4.vertexCount;
        poss4.push(minX - nx, floor, minY - ny);
        poss4.push(minX - nx, ceil, minY - ny);
        poss4.push(minX + nx, floor, minY + ny);
        poss4.push(minX + nx, ceil, minY + ny);

        // meshData.normals.push(-normal.y, 0, normal.x);
        // meshData.normals.push(-normal.y, 0, normal.x);
        // meshData.normals.push(-normal.y, 0, normal.x);
        // meshData.normals.push(-normal.y, 0, normal.x);

        let vt = (heightElevation - ceil) * uscaling;
        let vb = (heightElevation - floor) * uscaling;
        uvs4.push(u2, vb);
        uvs4.push(u2, vt);
        uvs4.push(0, vb);
        uvs4.push(0, vt);
        indices4.push(q, q + 1, q + 2);
        indices4.push(q + 1, q + 3, q + 2);

        // right
        q = meshData4.vertexCount;
        poss4.push(maxX + nx, floor, maxY + ny);
        poss4.push(maxX + nx, ceil, maxY + ny);
        poss4.push(maxX - nx, floor, maxY - ny);
        poss4.push(maxX - nx, ceil, maxY - ny);

        // meshData.normals.push(normal.y, 0, -normal.x);
        // meshData.normals.push(normal.y, 0, -normal.x);
        // meshData.normals.push(normal.y, 0, -normal.x);
        // meshData.normals.push(normal.y, 0, -normal.x);

        uvs4.push(u2, vb);
        uvs4.push(u2, vt);
        uvs4.push(0, vb);
        uvs4.push(0, vt);
        indices4.push(q, q + 1, q + 2);
        indices4.push(q + 1, q + 3, q + 2);

        // top
        if (ceil < elevation) {
          q = meshData4.vertexCount;
          poss4.push(minX - nx, ceil, minY - ny);
          poss4.push(maxX - nx, ceil, maxY - ny);
          poss4.push(minX + nx, ceil, minY + ny);
          poss4.push(maxX + nx, ceil, maxY + ny);

          // meshData.normals.push(0, -1, 0);
          // meshData.normals.push(0, -1, 0);
          // meshData.normals.push(0, -1, 0);
          // meshData.normals.push(0, -1, 0);

          // uvs.push((minX - nx) * uscaling, (minY - ny) * uscaling);
          // uvs.push((maxX - nx) * uscaling, (maxY - ny) * uscaling);
          // uvs.push((minX + nx) * uscaling, (minY + ny) * uscaling);
          // uvs.push((maxX + nx) * uscaling, (maxY + ny) * uscaling);
          uvs4.push(0, vt);
          uvs4.push(0, vt);
          uvs4.push(0, vt);
          uvs4.push(0, vt);
          indices4.push(q, q + 1, q + 2);
          indices4.push(q + 1, q + 3, q + 2);
        }
        // bottom
        if (floor > elevation) {
          q = meshData4.vertexCount;
          poss4.push(minX + nx, floor, minY + ny);
          poss4.push(maxX + nx, floor, maxY + ny);
          poss4.push(minX - nx, floor, minY - ny);
          poss4.push(maxX - nx, floor, maxY - ny);

          // meshData.normals.push(0, 1, 0);
          // meshData.normals.push(0, 1, 0);
          // meshData.normals.push(0, 1, 0);
          // meshData.normals.push(0, 1, 0);

          // uvs.push((minX + nx) * uscaling, (minY + ny) * uscaling);
          // uvs.push((maxX + nx) * uscaling, (maxY + ny) * uscaling);
          // uvs.push((minX - nx) * uscaling, (minY - ny) * uscaling);
          // uvs.push((maxX - nx) * uscaling, (maxY - ny) * uscaling);
          uvs4.push(0, vb);
          uvs4.push(0, vb);
          uvs4.push(0, vb);
          uvs4.push(0, vb);
          indices4.push(q, q + 1, q + 2);
          indices4.push(q + 1, q + 3, q + 2);
        }
        holl.push(polygon.length / 2);
        let dax = a.position.x - s.segment.pointA.x;
        let day = a.position.z - s.segment.pointA.y;
        let dbx = a.position.x - s.segment.pointB.x;
        let dby = a.position.z - s.segment.pointB.y;
        let t = Math.sqrt(dax * dax + day * day);
        let tb = Math.sqrt(dbx * dbx + dby * dby);
        let l = s.segment.length;
        if (tb > l && tb > t) {
          t = -t;
        }
        if (reverse) {
          polygon.push(-bMaxX + t, floor, -bMaxX + t, ceil, -bMinX + t, ceil, -bMinX + t, floor);
        } else {
          polygon.push(bMinX + t, floor, bMinX + t, ceil, bMaxX + t, ceil, bMaxX + t, floor);
        }
      }

      //挖空矩形正面
      let res = window.earcut(polygon, holl, 2);

      // front
      q = meshData0.vertexCount;
      poss0.push(s[2], elevation, s[3]);
      poss0.push(s[2], heightElevation, s[3]);
      poss0.push(s[6], heightElevation, s[7]);
      poss0.push(s[6], elevation, s[7]);

      // meshData.normals.push(normal.x, 0, normal.y);
      // meshData.normals.push(normal.x, 0, normal.y);
      // meshData.normals.push(normal.x, 0, normal.y);
      // meshData.normals.push(normal.x, 0, normal.y);

      uvs0.push(0, 1);
      uvs0.push(0, 0);
      uvs0.push(u1, 0);
      uvs0.push(u1, 1);
      for (let k = 8; k < polygon.length; k += 2) {
        let px = polygon[k];
        let py = polygon[k + 1];
        poss0.push(px * -normal.y + thickness * 0.5 * normal.x + s.segment.pointA.x, py, px * normal.x + thickness * 0.5 * normal.y + s.segment.pointA.y);
        // meshData.normals.push(normal.x, 0, normal.y);
        uvs0.push(px * uscaling, (heightElevation - py) * uscaling);
      }
      for (let k = 0; k < res.length; k++) {
        indices0.push(res[k] + q);
      }

      //back
      q = meshData1.vertexCount;
      poss1.push(s[0], elevation, s[1]);
      poss1.push(s[0], heightElevation, s[1]);
      poss1.push(s[4], heightElevation, s[5]);
      poss1.push(s[4], elevation, s[5]);
      uvs1.push(u1, 1);
      uvs1.push(u1, 0);
      uvs1.push(0, 0);
      uvs1.push(0, 1);
      for (let k = 8; k < polygon.length; k += 2) {
        let px = polygon[k];
        let py = polygon[k + 1];
        poss1.push(px * -normal.y - thickness * 0.5 * normal.x + s.segment.pointA.x, py, px * normal.x - thickness * 0.5 * normal.y + s.segment.pointA.y);
        // meshData.normals.push(-normal.x, 0, -normal.y);
        uvs1.push(u1 - px * uscaling, (heightElevation - py) * uscaling);
      }
      for (let k = 0; k < res.length; k += 3) {
        indices1.push(res[k + 1] + q);
        indices1.push(res[k] + q);
        indices1.push(res[k + 2] + q);
      }
    }
  }
  let poss2d = [];
  let indices2d = [];
  for (i = 0; i < wallSegments.length; i++) {
    let s = wallSegments[i];
    let segment = s.segment;
    let thickness = wall.thickness;
    let normal = segment.normal;
    let tangent = segment.tangent;
    let q = poss2d.length / 2;
    let accessories = segment.accessories.filter(x => {
      let a = objects[x];
      return a && a.isValid && a.attached === 'embed';
    });
    if (!accessories.length) {
      if (segment.pointA.finalSegments.length > 2) {
        poss2d.push(segment.pointA.x, segment.pointA.y);
        indices2d.push(q + 1, q, q + 2);
        q++;
      }
      let length_4 = s.length - 4;
      for (let j = 0; j < s.length; j += 4) {
        poss2d.push(s[j], s[j + 1], s[j + 2], s[j + 3]);
        if (j < length_4) {
          indices2d.push(q, q + 1, q + 2);
          indices2d.push(q + 1, q + 3, q + 2);
          q += 2;
        }
      }
      if (segment.pointB.finalSegments.length > 2) {
        poss2d.push(segment.pointB.x, segment.pointB.y);
        indices2d.push(q, q + 1, q + 2);
      }
    } else {
      if (segment.pointA.finalSegments.length > 2 || segment.pointB.finalSegments.length > 2) {
        if (segment.pointA.finalSegments.length > 2) {
          poss2d.push(segment.pointA.x, segment.pointA.y);
          poss2d.push(s[0], s[1], s[2], s[3]);
          indices2d.push(q + 1, q, q + 2);
          q += 3;
        }
        if (segment.pointB.finalSegments.length > 2) {
          poss2d.push(segment.pointB.x, segment.pointB.y);
          poss2d.push(s[4], s[5], s[6], s[7]);
          indices2d.push(q, q + 1, q + 2);
          q += 3;
        }
      }
      let pointA = segment.pointA;
      let pointB = segment.pointB;
      let length = segment.length;
      let accPos = [];
      for (let id of accessories) {
        let a = objects[id];
        let dx = pointA.x - a.position.x;
        let dy = pointA.y - a.position.z;
        let d = Math.sqrt(dx * dx + dy * dy);
        accPos.push([d + a.boundingBox[0], d + a.boundingBox[3]]);
      }
      accPos.sort((x, y) => x[0] - y[0]);
      let ps = [];
      let i = 0;
      let p = 0;
      let lastA = [0, 0];
      let a;
      for (; i < accPos.length; i++) {
        a = accPos[i];
        if (a[1] <= p) {
          continue;
        }
        if (a[0] <= p) {
          lastA = a;
          p = a[1];
          continue;
        }
        ps.push(lastA[1]);
        ps.push(a[0]);
        lastA = a;
      }
      if (lastA[1] < length) {
        ps.push(lastA[1]);
        ps.push(length);
      }
      let nx = normal.x * thickness * 0.5;
      let ny = normal.y * thickness * 0.5;
      for (let i = 0; i < ps.length; i += 2) {
        let q_ = poss2d.length / 2;
        let p0 = ps[i];
        let p1 = ps[i + 1];
        if (p0 === 0) {
          poss2d.push(s[0], s[1], s[2], s[3]);
        } else {
          let x = pointA.x + p0 * tangent.x;
          let y = pointA.y + p0 * tangent.y;
          poss2d.push(x - nx);
          poss2d.push(y - ny);
          poss2d.push(x + nx);
          poss2d.push(y + ny);
        }
        if (p1 === length) {
          poss2d.push(s[4], s[5], s[6], s[7]);
        } else {
          let x = pointA.x + p1 * tangent.x;
          let y = pointA.y + p1 * tangent.y;
          poss2d.push(x - nx);
          poss2d.push(y - ny);
          poss2d.push(x + nx);
          poss2d.push(y + ny);
        }
        indices2d.push(q_, q_ + 1, q_ + 2);
        indices2d.push(q_ + 1, q_ + 3, q_ + 2);
      }
    }
  }
  meshData0.createSubSet();
  meshData0.addMesh(meshData1);
  meshData0.createSubSet();
  meshData0.addMesh(meshData2);
  meshData0.createSubSet();
  meshData0.addMesh(meshData3);
  meshData0.createSubSet();
  meshData0.addMesh(meshData4);
  meshData0.createSubSet();
  smMesh2DCache[wall.nanoId] = {
    poss: poss2d,
    indices: indices2d
  };
  smMesh3DCache[wall.nanoId] = meshData0;
};
const buildPolygonWallMesh = function (wall) {
  let poss2d = [];
  let indices2d = [];
  let meshData0 = new Kingfisher.KfMeshData('3d', true, false, false); //正面
  let meshData1 = new Kingfisher.KfMeshData('3d', true, false, false); //背面
  let meshData2 = new Kingfisher.KfMeshData('3d', true, false, false); //顶面
  let meshData3 = new Kingfisher.KfMeshData('3d', true, false, false); //底面
  let meshData4 = new Kingfisher.KfMeshData('3d', true, false, false); //侧面

  let poss0 = meshData0.poss;
  let uvs0 = meshData0.uvs;
  let indices0 = meshData0.indices;
  let poss1 = meshData1.poss;
  let uvs1 = meshData1.uvs;
  let indices1 = meshData1.indices;
  let poss2 = meshData2.poss;
  let uvs2 = meshData2.uvs;
  let indices2 = meshData2.indices;
  let poss3 = meshData3.poss;
  let uvs3 = meshData3.uvs;
  let indices3 = meshData3.indices;
  let poss4 = meshData4.poss;
  let uvs4 = meshData4.uvs;
  let indices4 = meshData4.indices;
  let height = wall.height;
  let elevation = 0; //wall.floor ? wall.floor.elevation : 0;
  let heightElevation = elevation + height;
  for (let fill of wall.fills) {
    let polygon = [];
    for (let p of fill.points) {
      polygon.push(p.x, p.y);
    }
    let res = window.earcut(polygon, [], 2);

    // 2d
    let offset2 = poss2d.length / 2;
    poss2d.push(...polygon);
    if (offset2) {
      for (let i = 0; i < res.length; i++) {
        indices2d.push(res[i] + offset2);
      }
    } else {
      indices2d.push(...res);
    }

    // 3d
    // 顶面 底面
    let offsetPos2 = poss2.length / 3;
    let offsetPos3 = poss3.length / 3;
    for (let p of fill.points) {
      poss2.push(p.x, elevation, p.y);
      poss3.push(p.x, heightElevation, p.y);
      uvs2.push(0, 1);
      uvs3.push(0, 0);
    }
    for (let i = 0; i < res.length; i += 3) {
      indices2.push(res[i + 1] + offsetPos2);
      indices2.push(res[i] + offsetPos2);
      indices2.push(res[i + 2] + offsetPos2);
      indices3.push(res[i] + offsetPos3);
      indices3.push(res[i + 1] + offsetPos3);
      indices3.push(res[i + 2] + offsetPos3);
    }

    // 正面
    let q = meshData0.vertexCount;
    for (let i = 0; i < polygon.length; i += 2) {
      let x = polygon[i];
      let y = polygon[i + 1];
      poss0.push(x, elevation, y);
      poss0.push(x, heightElevation, y);
      uvs0.push(x, y);
      uvs0.push(x, y);
      indices0.push(q, q + 1, q + 2);
      indices0.push(q + 1, q + 3, q + 2);
      q += 2;
    }
    poss0.push(polygon[0], elevation, polygon[1]);
    poss0.push(polygon[0], heightElevation, polygon[1]);
    uvs0.push(0, 0);
    uvs0.push(0, 0);
  }
  meshData0.createSubSet();
  meshData0.addMesh(meshData1);
  meshData0.createSubSet();
  meshData0.addMesh(meshData2);
  meshData0.createSubSet();
  meshData0.addMesh(meshData3);
  meshData0.createSubSet();
  meshData0.addMesh(meshData4);
  meshData0.createSubSet();
  smMesh2DCache[wall.nanoId] = {
    poss: poss2d,
    indices: indices2d
  };
  smMesh3DCache[wall.nanoId] = meshData0;
};
const buildWallMesh = function (wall) {
  if (smMesh3DCache[wall.nanoId]) {
    return;
  }
  let minX = Number.MAX_VALUE;
  let minZ = Number.MAX_VALUE;
  let maxX = -Number.MAX_VALUE;
  let maxZ = -Number.MAX_VALUE;
  for (let point of wall.points) {
    minX = Math.min(minX, point.x);
    minZ = Math.min(minZ, point.y);
    maxX = Math.max(maxX, point.x);
    maxZ = Math.max(maxZ, point.y);
  }
  switch (wall.subType) {
    case 'fill':
      buildPolygonWallMesh(wall);
    default:
      buildNormalWallMesh(wall);
      for (let seg of wall.segments) {
        if (seg.isArc) {
          let radius = seg.radius * Math.sign(seg.angle);
          let center = seg.center;
          let startAngle = seg.directionA;
          let tessellation = seg.tessellation;
          let step = seg.stepAngle;
          for (let j = 1; j < tessellation + 1; j++) {
            let angle = startAngle + step * j;
            let cos_ = Math.cos(angle);
            let sin_ = Math.sin(angle);
            let x = center.x + radius * cos_;
            let y = center.y + radius * sin_;
            minX = Math.min(minX, x);
            minZ = Math.min(minZ, y);
            maxX = Math.max(maxX, x);
            maxZ = Math.max(maxZ, y);
          }
        }
      }
  }
  wall.boundingBox = [minX, 0, minZ, maxX, wall.height, maxZ];
};
export { buildWallMesh };