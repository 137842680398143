import {registerSMType, serializeObject, updateProperties, SMObject} from "./smObject";
import {SMDirtyAll} from "./smDirtyFlag";
import {getNanoID} from "@/utils/tools";
import {getModelById, getModelByName, selectedThemeId} from "@/model/3Dmodel";
import {smDatas} from "@/model/smCollectionData";
import {globalState, viewPortData} from "@/model/globalData";


class Building extends SMObject {
    constructor(collection, options) {
        super(collection, options);
        this.type = 'building';
        this._building = this.nanoId;
    }

    get themeId() {
        return selectedThemeId.value;
    }
    set themeId(value) {
        if (value !== undefined && selectedThemeId.value !== value) {
            selectedThemeId.value = value;
            console.log('switch theme', value);
        }
    }

    serialize() {
        let res = super.serialize();
        if (!res.properties) {
            res.properties = {};
        }

        serializeObject(this, ['themeId'], res.properties);

        let modesSet = new Set();
        let typesSet = new Set(['camera', 'furniture', 'sensor', 'light', 'equipment', 'parking']);
        let count = 0;
        for (let model of smDatas.models) {
            if (model.isDeleted) {
                continue;
            }
            let m = getModelByName(model.modelName) || getModelById(model.modelId);
            if (m) {
                modesSet.add(m.uuid);
            }
            else {
                console.error('model not found', model);
            }
            if (typesSet.has(model.subType)) {
                count++;
            }
        }

        res.properties.models = Array.from(modesSet);
        res.properties.checksum = count;


        let uiData = {};
        for (let p of ['mode', 'operation', 'drawingMode', 'enableAdsorbent', 'enableGridView', 'enableDrawingView', 'typeViews']) {
            uiData[p] = globalState[p];
        }
        uiData['viewPort'] = {
            'view2D': {
                enabled: viewPortData.view2D,
                target: {
                    x: scene.activeCamera.target.x,
                    y: scene.activeCamera.target.z,
                },
                distance: scene.activeCamera.distance,
            },
            'view3D': {
                enabled: viewPortData.view3D,
                target: {
                    x: scene3d.activeCamera.target.x,
                    y: scene3d.activeCamera.target.y,
                    z: scene3d.activeCamera.target.z,
                },
                distance: scene3d.activeCamera.distance,
                yaw: scene3d.activeCamera.yaw,
                pitch: scene3d.activeCamera.pitch,
            }
        };

        uiData['currData'] = {
            objects: currData.currList.map(x => x.nanoId),
        }

        res.properties.ui = JSON.stringify(uiData);

        // res.floors = this.collection.floors.length;
        // res.constructs = this.collection.constructs.length;

        return res;
    }

    updateProperties(data) {
        let changed = super.updateProperties(data);

        if (data && data.properties) {
            changed |= updateProperties(this, ['themeId'], data.properties);
        }

        if (changed) {
            this.makeDirty();
        }

        let scene = window.scene;
        let scene3d = window.scene3d;
        if (scene && scene3d && data.properties && data.properties.ui) {
            try {
                let uiData = JSON.parse(data.properties.ui);
                for (let p of ['mode', 'operation', 'drawingMode', 'typeViews', 'enableAdsorbent', 'enableGridView', 'enableDrawingView']) {
                    if (uiData[p] !== undefined) {
                        globalState[p] = uiData[p];
                    }
                }

                if (uiData.viewPort) {
                    let view2D = uiData.viewPort.view2D;
                    let view3D = uiData.viewPort.view3D;
                    viewPortData.view2D = view2D.enabled;
                    viewPortData.view3D = view3D.enabled;
                    scene.activeCamera.setTargetX(view2D.target.x);
                    scene.activeCamera.setTargetZ(view2D.target.y);
                    scene.activeCamera.setDistance(view2D.distance);

                    scene3d.activeCamera.setTargetX(view3D.target.x);
                    scene3d.activeCamera.setTargetY(view3D.target.y);
                    scene3d.activeCamera.setTargetZ(view3D.target.z);
                    scene3d.activeCamera.setDistance(view3D.distance);
                    scene3d.activeCamera.setYaw(view3D.yaw);
                    scene3d.activeCamera.setPitch(view3D.pitch);
                }
            }
            catch (e) {
                console.error(e, this, data.properties.ui);
            }
        }

        return changed;
    }
}

registerSMType('building', Building);

export {
    Building
}