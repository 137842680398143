import { computed, ref, watch } from 'vue';
import pageModel from "@/model/page";
import numInput from "@/components/propWidget/numInput";
import _ from 'lodash';
export default {
  name: "inputProp",
  props: {
    props: {
      type: Object,
      default: () => {
        return {};
      }
    },
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: {
    numInput
  },
  setup(props, {
    emit
  }) {
    let inputValue = ref("");
    inputValue.value = pageModel.getPropValue(props.model, props.props.name);
    let show = ref(false);
    const doInputContent = function (val) {
      if (typeof val === 'string') {
        if (precision.value == 0) {
          emit("propChange", {
            name: props.props.name,
            value: parseInt(val)
          });
        } else {
          emit("propChange", {
            name: props.props.name,
            value: parseFloat(val)
          });
        }
      }
    };
    const text = ref("");
    watch(props, (newValue, oldValue) => {
      inputValue.value = pageModel.getPropValue(props.model, props.props.name);
    });
    const placeholder = computed(() => {
      return _.get(props, "props.placeholder");
    });
    const step = computed(() => {
      return _.get(props, "props.step");
    });
    const max = computed(() => {
      return _.get(props, "props.max");
    });
    const min = computed(() => {
      return _.get(props, "props.min");
    });
    const precision = computed(() => {
      return _.get(props, "props.precision");
    });
    const unit = computed(() => {
      return _.get(props, "props.unit");
    });
    return {
      show,
      doInputContent,
      text,
      inputValue,
      placeholder,
      step,
      max,
      min,
      precision,
      unit
    };
  }
};