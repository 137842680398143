import "core-js/modules/es.array.push.js";
import { computed, ref, watch } from 'vue';
import numInput from "./numInput";
import { closeMenu, openMenu } from "@/components/widgets/contextmenu/contextmenu2";
import { smDatas } from "@/model/smCollectionData";
import { currData } from "@/model/globalData";
import { loadBodyScrollTop } from "@/components/mainContent/rightPanel";
import { undoMap } from "@/undo/undoMap";
export default {
  name: "segmentProp",
  components: {
    numInput
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  setup(props) {
    let listManager = ref(null);
    let segmentList = computed(() => {
      return props.data ? props.data.segments : [];
    });
    const loadBodyScrollTop = function () {
      let list = props.data ? props.data.segments : [];
      let index;
      if (list && list.length > 0) {
        index = list.indexOf(currData.segment);
        let top = index * 20;
        if (listManager.value) {
          let clientHeight = listManager.value.clientHeight;
          let scrollTop = listManager.value.scrollTop;
          if (scrollTop < top - clientHeight - 20 || scrollTop > top) {
            listManager.value.scrollTop = index * 20;
          }
        }
      }
    };
    watch(() => currData.currListFlag, () => {
      loadBodyScrollTop();
    });
    let segmentIndex = ref({});
    const handleClickSegment = function (item, index) {
      currData.segment = item;
      segmentIndex = index;
    };
    const doubleClickSegment = function (item, index) {
      currData.segment = item;
      segmentIndex = index;
      window.sm3DMeshBuilder.focusOnSegment({
        ratio: 0.7
      });
      window.sm2DMeshBuilder.focusOnSegment({
        ratio: 0.7
      });
    };
    const handleContextmenu = function (event, segment) {
      openMenu(event, {
        menu: [{
          label: Kf.t('Delete'),
          type: 'delete'
        }],
        fun: function (event, item) {
          if (item.type === 'delete') {
            debugger;
            undoMap.push({
              cmd: 'updateObj',
              target: props.data,
              value: props.data.serialize()
            });
            props.data.setAccessoriesNeedSave();
            props.data.removeSegment(segment);
            props.data.setNeedSave();
            currData.segment = null;
            closeMenu();
          }
        }
      });
    };
    const doInputContent = function (val, name) {
      val = Math.round(parseFloat(val) * 10);
      let obj = {
        'start_x': function () {
          currData.segment.pointA.x = val;
        },
        'start_y': function () {
          currData.segment.pointA.y = val;
        },
        'end_x': function () {
          currData.segment.pointB.x = val;
        },
        'end_y': function () {
          currData.segment.pointB.y = val;
        }
      };
      obj[name]();
      currData.segment.makeDirty();
      currData.segment.setNeedSave();
      sm2DMeshBuilder.updateSelectSegment();
    };
    return {
      currData,
      segmentList,
      handleClickSegment,
      doubleClickSegment,
      doInputContent,
      handleContextmenu,
      listManager
    };
  }
};