import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = ["onClick"];
const _hoisted_2 = ["title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.list, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: "kf-panel_contextmenu_con mn",
      key: index,
      onClick: $event => $setup.doMenuClick(item)
    }, [_createElementVNode("div", {
      class: "kingfisher_material_contextmenu_con_txt mn",
      title: item.label
    }, _toDisplayString(item.label), 9, _hoisted_2)], 8, _hoisted_1);
  }), 128);
}