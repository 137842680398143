import "core-js/modules/es.array.push.js";
import { ref, computed } from 'vue';
import { smDatas, smDxfCache } from "@/model/smCollectionData";
import { currData, loadDxf } from "@/model/globalData";
import { getObjectName, getFloorName, showContextMenu, setProperty, doItemClick, drawingFile, drawingFileUpload, doObjectMenuClick, uploadDrawingMode } from "./index.js";
import InputProp from "@/components/propWidget/numberProp";
import { undoMap } from "@/undo/undoMap";
import { importAsset } from "@/utils/importAssets";
import DxfService from "@/service/dxf";
import projectModel from "@/model/project";
import { DxfWorker } from "@/dxflib/DxfWorker";
export default {
  name: "drawing",
  components: {
    InputProp
  },
  setup() {
    let showAddDrawingDialog = ref(false);
    let fileUploaded = function (e) {
      let files = event.target.files;
      let file = drawingFile;
      if (!files || !files.length) {
        return;
      }
      file.value = files[0];
      let reader = new FileReader(); //读取文件

      reader.onload = e => {
        if (uploadDrawingMode.value !== 'update') {
          let name = file.value.name.toLowerCase();
          doObjectMenuClick({
            label: Kf.t('New'),
            key: 'create'
          }, name.endsWith('.dwg') || name.endsWith('.dxf') ? 'dxfDrawing' : 'drawing');
        }
        let uploadDrawing = currData.drawing;
        if (!uploadDrawing) {
          return;
        }
        if (file.value.name) {
          uploadDrawing.name = file.value.name;
        }
        if (uploadDrawing.type === 'dxfDrawing') {
          let updateDxf = data => {
            undoMap.push({
              cmd: 'updateDxf',
              target: uploadDrawing,
              value: uploadDrawing.url
            });
            let url = importAsset({
              data,
              dir: '/dxfs',
              type: 'dxf'
            });
            uploadDrawing.url = url + '.dxf';
            uploadDrawing.needSave = true;
            loadDxf(uploadDrawing, url, dxf => {
              uploadDrawing.makeDirty();
            });
          };
          if (file.value.name.toLowerCase().endsWith('.dxf')) {
            updateDxf(e.target['result']);
          } else {
            let formData = new FormData();
            formData.append("files", file.value);
            uploadDrawing.loadingState = 1;
            uploadDrawing.loadingProgress = 0;
            DxfService.dwg2dxf(formData).then(res => {
              if (res.status == 200) {
                let reader = new FileReader();
                reader.onload = e => {
                  console.log(e.target.result);
                  updateDxf(e.target.result);
                };
                reader.readAsArrayBuffer(res.data);
              } else {
                console.error('failed to convert dwg to dxf');
                console.error(res.message);
              }
            }).catch(err => {
              uploadDrawing.loadingState = 3;
              uploadDrawing.loadingProgress = 1;
              console.error('failed to convert dwg to dxf');
              console.error(err);
            });
          }
        } else {
          undoMap.push({
            cmd: 'updateImage',
            target: uploadDrawing,
            value: uploadDrawing.url
          });
          let url = importAsset({
            data: e.target['result'],
            dir: '/textures',
            type: file.value.type
          });
          uploadDrawing.url = url;
          uploadDrawing.needSave = true;
        }
        drawingFileUpload.value.value = null;
        uploadDrawingMode.value = '';
      };
      reader.onerror = () => {
        uploadDrawingMode.value = '';
      };
      reader.readAsArrayBuffer(file.value);
    };
    let visibleObjs = computed(() => {
      return smDatas.drawings.filter(x => !x.isDeleted);
    });
    let isActive = function (item) {
      return currData.drawing === item || currData.currListMap.has(item);
    };
    let isCreateNewFile = false;
    let getFileType = function () {
      if (!currData.drawing || isCreateNewFile) {
        return '.png,.jpg,.jpeg,.tag,.bmp,.dds,.webp,.dxf';
      }
      return currData.drawing.type === 'dxfDrawing' ? '.dxf' : '.png,.jpg,.jpeg,.tag,.bmp,.dds,.webp';
    };
    let createDrawingFile = function () {
      isCreateNewFile = true;
      drawingFileUpload.value.click();
    };
    let focusItem = function (e, item) {
      let boundingBox = [];
      console.log('focusItem');
      if (item.type === 'dxfDrawing') {
        for (let layer of item.layers.filter(x => x.visible)) {
          let layerBox = layer.boundingBox;
          if (layerBox) {
            if (layerBox.length === 0) {
              continue;
            }
            if (Number.isNaN(layerBox[0])) {
              continue;
            }
            if (boundingBox.length === 0) {
              boundingBox[0] = layerBox[0];
              boundingBox[1] = layerBox[1];
              boundingBox[2] = layerBox[2];
              boundingBox[3] = layerBox[3];
            } else {
              boundingBox[0] = Math.min(boundingBox[0], layerBox[0]);
              boundingBox[1] = Math.min(boundingBox[1], layerBox[1]);
              boundingBox[2] = Math.max(boundingBox[2], layerBox[2]);
              boundingBox[3] = Math.max(boundingBox[3], layerBox[3]);
            }
          }
        }
      }
      window.sm2DMeshBuilder.focusOnBoundingBox(item.boundingBoxToWorld(boundingBox));
    };
    let pictureFileUpload = ref(null);
    let createPictureFile = function () {
      drawingFileUpload.value.click();
    };
    let pictureUploaded = function (e) {
      let files = event.target.files;
      if (!files || !files.length) {
        return;
      }
      console.log("--pictureUploaded", files);
      //todo picture upload
    };
    return {
      smDatas,
      currData,
      getFloorName,
      getObjectName,
      showContextMenu,
      setProperty,
      doItemClick,
      focusItem,
      fileUploaded,
      drawingFile,
      drawingFileUpload,
      uploadDrawingMode,
      createDrawingFile,
      showAddDrawingDialog,
      visibleObjs,
      isActive,
      getFileType,
      createPictureFile,
      pictureFileUpload,
      pictureUploaded
    };
  }
};