import { SMDirtyPosition, SMDirtyReticular, SMDirtyAll } from './smDirtyFlag';

import {Vector2, matrix2DIsIdentity, transform2DPointXZ} from "../maths/vector";
import { getUID, registerSMType, serializeObject, updateProperties, SMObject } from "./smObject";
import {Point3D} from "./sm3DPoint";

import {buildWallMesh} from "./../builder/smWallBuildTools";
import {base64ArrayBuffer} from "../utils/tools";
import {smDatas, smMesh3DCache} from "../model/smCollectionData";

class PipelinePoint extends Point3D {
    pipeline;
    segments = [];
    dirtyFlag = 0;

    constructor(x, y, z, pipeline) {
        super(x, y, z)

        this.pipeline = pipeline;
    }

    addSegment(s) {
        this.segments.push(s);
        this.makeDirty();
    }

    removeSegment(s) {
        let index = this.segments.findIndex(x => x.uid === s.uid);
        if (index === -1) {
            return;
        }
        this.segments.splice(index, 1);
        this.makeDirty();
    }

    makeDirty() {
        this.dirtyFlag = true;
        for (let segment of this.segments) {
            segment.makeDirty();
        }

        smDatas.objects[this.pipeline].makeDirty();
    }

    setNeedSave() {
        smDatas.objects[this.pipeline].setNeedSave();
    }

    checkDirty() {
        if (!this.dirtyFlag) {
            return;
        }

        this.dirtyFlag = 0;
        let finalSegments = this.segments.filter((x) => x.isValid);
        if (finalSegments.length > 2) {
            let uid = this.uid;
            finalSegments.sort((x, y) => {
                let rx = x.pointA.uid === uid ? x.directionA : x.directionB;
                let ry = y.pointA.uid === uid ? y.directionA : y.directionB;

                return ry - rx;
            });
        }

        this.finalSegments = finalSegments;
    }

    updateProperties(data) {
        if (super.updateProperties(data)) {
            this.makeDirty(SMDirtyPosition);
            return true;
        }
        return false;
    }
}

class PipelineSegment {
    pointA;
    pointB;
    angle;
    type;
    thickness;
    direction;
    length;
    accessories = [];

    dirtyFlag = SMDirtyAll;
    constructor(a, b, pipeline, options) {
        Object.defineProperty(this, 'uid', { value: getUID() } );
        this.pipeline = pipeline;

        this.pointA = a;
        this.pointB = b;
    }

    setPointA(point) {
        if (point.uid !== this.pointA.uid) {
            this.pointA.removeSegment(this);
            point.addSegment(this);
            this.pointA = point;

            this.pointA.makeDirty(SMDirtyReticular);

            this.makeDirty();

            return true;
        }
        return false;
    }

    setPointB(point) {
        if (point.uid !== this.pointB.uid) {
            this.pointB.removeSegment(this);
            point.addSegment(this);
            this.pointB = point;

            this.pointA.makeDirty(SMDirtyReticular);

            this.makeDirty();
            
            return true;
        }
        return false;
    }

    setNeedSave() {
        smDatas.objects[this.pipeline].setNeedSave();
    }

    makeDirty(flag = SMDirtyAll) {
        if ((this.dirtyFlag & flag) === flag) {
            return;
        }
        this.dirtyFlag |= flag;
        this.pointA.makeDirty(flag);
        this.pointB.makeDirty(flag);

        smDatas.objects[this.pipeline].makeDirty(flag);
    }

    breakSegment(pos) {
        return smDatas.objects[this.pipeline].breakSegment(this, pos);
    }

    checkDirty() {
    }

    get isValid() {
        return this.length > 2;
    }

    addAccessory(obj) {
        if (this.accessories.indexOf(obj.nanoId) >= 0) {
            return;
        }

        this.accessories.push(obj.nanoId);
        this.makeDirty(SMDirtyReticular);
    }

    removeAccessory(obj) {
        let index = this.accessories.indexOf(obj.nanoId);
        if (index < 0) {
            return;
        }

        this.accessories.splice(index, 1);
        this.makeDirty(SMDirtyReticular);
    }

    dispose() {
        this.pointA.removeSegment(this);
        this.pointB.removeSegment(this);
        let accessories = [...this.accessories];
        let objects = smDatas.objects;
        for (let i = 0; i < accessories.length; i++) {
            objects[accessories[i]].setSegment(null);
        }
    }

    updateProperties(data) {
        let angle = data ? data.angle : undefined;
        let changed = false;
        if (this.angle !== angle) {
            changed = true;
            this.angle = angle;
        }

        let models = data ? data.models : undefined;
        let objects = smDatas.objects;
        for (let a of [...this.accessories]) {
            if (!models || models.indexOf(a) === -1) {
                objects[a].setSegment(null);
            }
        }
        if (models) {
            for (let a of models) {
                if (objects[a]) {
                    objects[a].setSegment(this);
                }
            }
        }

        if (changed) {
            this.makeDirty(SMDirtyReticular);
        }

        return changed;
    }

    serialize() {
        let pipeline = smDatas.objects[this.pipeline];
        let a = pipeline.points.findIndex(x => x.uid === this.pointA.uid);
        let b = pipeline.points.findIndex(x => x.uid === this.pointB.uid);
        let res = [a, b];
        let options = null;
        if (this.accessories.length) {
            options = {models: [...this.accessories]};
        }
        if (this.angle && !Number.isNaN(this.angle)) {
            if (!options) {
                options = {};
            }
            options.angle = this.angle;
        }

        if (options) {
            res.push(options);
        }
        return res;
    }
}

class Pipeline extends SMObject {
    segments = [];
    points = [];
    _floor;
    _elevation = 50;
    _diameter = 75;
    _chamfer = 100;
    _purpose='normal';

    constructor(collection, options) {
        super(collection, options);

        this.type = 'pipeline';
    }

    set floor(value) {
        if (this._floor === value) {
            return;
        }

        super.floor = value;
        for (let i = 0; i < this.segments.length; i++) {
            let s = this.segments[i];
            let objects = smDatas.objects;
            for (let j = 0; j < s.accessories.length; j++) {
                let temp = objects[s.accessories[j]];
                if (temp.floor !== value) {
                    temp.floor = value;
                    temp.setNeedSave();
                }
            }
        }
    }

    get floor() {
        return this._floor;
    }

    set elevation(value) {
        if (this._elevation === value) {
            return;
        }

        this._elevation = value;
        this.makeDirty();
    }

    get elevation() {
        return this._elevation;
    }

    set purpose(value) {
        if (this._purpose === value) {
            return;
        }

        this._purpose = value;
        this.makeDirty();
    }

    get purpose() {
        return this._purpose;
    }

    set diameter(value) {
        if (this._diameter === value) {
            return;
        }

        this._diameter = value;
        this.makeDirty();
    }

    get diameter() {
        return this._diameter;
    }

    set chamfer(value) {
        if (this._chamfer === value) {
            return;
        }

        this._chamfer = value;
        this.makeDirty();
    }

    get chamfer() {
        return this._chamfer;
    }

    createPoint(point) {
        let p = point ? new PipelinePoint(point.x, point.y, point.z, this.nanoId) : new PipelinePoint(0, 0, 0, this.nanoId);
        this.points.push(p);

        this.makeDirty(SMDirtyReticular);
        return this.points[this.points.length - 1];
    }

    removePoint(point) {
        let index = this.points.findIndex(x => x.uid === point.uid);
        if (index < 0) {
            return;
        }

        let segments = [...point.segments];
        for (let i = 0; i < segments.length; i++) {
            this.removeSegment(segments[i]);
        }

        index = this.points.findIndex(x => x.uid === point.uid);
        if (index >= 0) {
            this.points.splice(index, 1);
        }
        this.makeDirty(SMDirtyReticular);
    }

    createSegment(a, b) {
        if (this.subType) {
            return;
        }

        let s = new PipelineSegment(a, b, this.nanoId);
        s.makeDirty();
        this.segments.push(s);

        s = this.segments[this.segments.length - 1];
        a.addSegment(s);
        b.addSegment(s);

        return s;
    }

    breakSegment(s, p) {
        if (this.subType) {
            return;
        }

        let index = this.segments.findIndex(x => x.uid === s.uid);
        if (index < 0) {
            return;
        }

        let sA = this.createSegment(s.pointA, p);
        let sB = this.createSegment(p, s.pointB);

        sA.updateProperties(s);
        sB.updateProperties(s);

        if (s.isArc) {
            s.checkDirty();
            ///???
            let PI2 = Math.PI * 2;
            let a = Math.atan2(p.y - s.center.y, p.x - s.center.x);
            if (s.angle < 0) {
                a += Math.PI;
            }
            a = (a + PI2) % PI2;
            let da = a - s.directionA;
            if (s.angle > 0) {
                if (da < 0) {
                    da += PI2;
                }
            }
            else {
                if (da > 0) {
                    da -= PI2;
                }
            }
            sA.angle = da;
            sB.angle = s.angle - da;
        }

        let objects = smDatas.objects;
        let accessories = [...s.accessories];
        for (let i = 0; i < accessories.length; i++) {
            let a = objects[accessories[i]];
            if (Math.abs(a.position.x - s.pointA.x) < Math.abs(p.x - s.pointA.x) ||
                Math.abs(a.position.z - s.pointA.y) < Math.abs(p.y - s.pointA.y)) {
                a.setSegment(sA);
            }
            else {
                a.setSegment(sB);
            }
        }

        this.segments.splice(index, 1);
        s.dispose();

        return [sA, sB];
    }

    joinSegment(s0, s1, p) {
        if (this.subType) {
            return;
        }

        if (s0 === s1) {
            return;
        }

        let pointA;
        let pointB = s1.pointA === p ? s1.pointB : s1.pointA;
        if (s0.pointB === p) {
            pointA = s0.pointA;
        }
        else {
            pointA = pointB;
            pointB = s0.pointB;
        }

        let s = this.createSegment(pointA, pointB);
        s.updateProperties(s0);

        if (s0.isArc) {
            s0.checkDirty();
            ///???
            s.angle = Math.atan2(-(pointB.y - s0.center.y), -(pointB.x - s0.center.x)) - Math.atan2(-(pointA.y - s0.center.y), -(pointA.x - s0.center.x));
            if (s.angle * s0.angle < 0) {
                if (s0.angle < 0) {
                    s.angle -= Math.PI * 2
                }
                else {
                    s.angle += Math.PI * 2
                }
            }
        }

        let objects = smDatas.objects;

        let accessories = s0.accessories.concat(s1.accessories);
        for (let i = 0; i < accessories.length; i++) {
            objects[accessories[i]].setSegment(s);
        }

        this.removeSegment(s0, false);
        this.removeSegment(s1);

        return s;
    }

    removeSegment(s, disposeEmptyPoint = true) {
        if (this.subType) {
            return;
        }
        let index = this.segments.findIndex(x => x.uid === s.uid);
        if (index >= 0) {
            this.segments.splice(index, 1);
            s.dispose();

            if (disposeEmptyPoint) {
                if (s.pointA.segments.length === 2) {
                    s.pointA.segments[0].checkDirty();
                    s.pointA.segments[1].checkDirty();
                    if (Math.abs(s.pointA.segments[0].direction - s.pointA.segments[1].direction) < 0.0002) {
                        this.joinSegment(s.pointA.segments[0], s.pointA.segments[1], s.pointA);
                    }
                }
                else if (!s.pointA.segments.length) {
                    let i = this.points.findIndex(x => x.uid === s.pointA.uid);
                    if (i >= 0) {
                        this.points.splice(i, 1);
                    }
                }
                if (s.pointB.segments.length === 2) {
                    s.pointB.segments[0].checkDirty();
                    s.pointB.segments[1].checkDirty();
                    if (Math.abs(s.pointB.segments[0].direction - s.pointB.segments[1].direction) < 0.0002) {
                        this.joinSegment(s.pointB.segments[0], s.pointB.segments[1], s.pointB);
                    }
                }
                else if (!s.pointB.segments.length) {
                    let i = this.points.findIndex(x => x.uid === s.pointB.uid);
                    if (i >= 0) {
                        this.points.splice(i, 1);
                    }
                }
            }
        }
    }

    makeDirty(flag = SMDirtyAll) {
        super.makeDirty(flag);
        smMesh3DCache[this.nanoId] = null;
    }

    setAccessoriesNeedSave() {
        let objects = smDatas.objects;
        for (let s of this.segments) {
            for (let a of s.accessories) {
                objects[a].setNeedSave();
            }
        }
    }

    transform(matrix) {
        if (matrix2DIsIdentity(matrix)) {
            return;
        }

        this.makeDirty(SMDirtyPosition);

        let points = this.points;
        for (let i = 0; i < points.length; i++) {
            let p = points[i];

            transform2DPointXZ(p, matrix);
            p.dirtyFlag |= SMDirtyPosition;
        }

        let segments = this.segments;
        for (let i = 0; i < segments.length; i++) {
            this.segments[i].dirtyFlag |= SMDirtyPosition;
        }
    }

    updateProperties(data) {
        let changed = super.updateProperties(data);
        if (data) {
            let geometry = data.geometry;
            let properties = data.properties;
            changed |= updateProperties(this, ['position', 'elevation', 'diameter', 'chamfer', 'purpose'], properties);
            if (geometry) {
                if (this.points.length > geometry.coordinates.length) {
                    this.points.splice(geometry.coordinates.length, this.points.length - geometry.coordinates.length);
                    changed = true;
                }
                for (let i = 0; i < geometry.coordinates.length; i++) {
                    let p = this.points[i];
                    if (!p) {
                        p = new PipelinePoint(0, 0, 0, this.nanoId);
                        this.points.push(p);
                        changed = true;
                    }
                    changed |= p.updateProperties(geometry.coordinates[i]);
                }
            }

            if (properties && properties.segments) {
                if (this.segments.length > properties.segments.length) {
                    while (this.segments.length > properties.segments.length) {
                        let s = this.segments.pop();
                        s.dispose();
                    }
                    changed = true;
                }
                let points = this.points;
                for (let i = 0; i < properties.segments.length; i++) {
                    let s = this.segments[i];
                    let temp = properties.segments[i];
                    if (s) {
                        changed |= s.setPointA(points[temp[0]]);
                        changed |= s.setPointB(points[temp[1]]);
                        changed |= s.updateProperties(temp[2]);
                    } else {
                        s = this.createSegment(points[temp[0]], points[temp[1]]);
                        if (temp[2]) {
                            s.updateProperties(temp[2]);
                        }
                        changed = true;
                    }
                }
            }
        }

        if (changed) {
            this.makeDirty();
        }

        return changed;
    }

    serialize() {
        let res = super.serialize();
        if (!res.properties) {
            res.properties = {};
        }
        if (!res.geometry) {
            res.geometry = {
                type: 'LineString',
                coordinates: [],
            };
        }

        // buildWallMesh(this);
        // let mesh3d = smMesh3DCache[this.nanoId].toArrayBuffers();
        // res.geometry.mesh3d = mesh3d.map((x) => base64ArrayBuffer(x));

        let coordinates = res.geometry.coordinates;
        for (let p of this.points) {
            coordinates.push(p.serialize());
        }
        serializeObject(this, ['segments', 'elevation', 'diameter', 'chamfer', 'purpose'], res.properties);

        return res;
    }
}

registerSMType('pipelinePoint', PipelinePoint);
registerSMType('pipelineSegment', PipelineSegment);

export {
    Pipeline
}
