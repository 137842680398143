import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array-buffer.detached.js";
import "core-js/modules/es.array-buffer.transfer.js";
import "core-js/modules/es.array-buffer.transfer-to-fixed-length.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.find-last.js";
import "core-js/modules/es.typed-array.find-last-index.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.to-reversed.js";
import "core-js/modules/es.typed-array.to-sorted.js";
import "core-js/modules/es.typed-array.with.js";
import { Vector2, lineIntersect } from "../maths/vector";
import { buildWallMesh } from "./smWallBuildTools";
import { globalState, currData } from "@/model/globalData";
import { smMesh2DCache } from "../model/smCollectionData";
class SmWall2DBuilder {
  update(data) {
    let scene = data.scene;
    let wall = data.obj;
    if (!data.inited) {
      if (!wall.finalVisible) {
        return false;
      }
      data.inited = true;

      //wall init
      let wallMesh = new Kingfisher.Mesh(wall.name + 'wall', scene);
      wallMesh.parent = scene._wallRoot;
      wallMesh.alphaIndex = 20;
      wallMesh.renderingGroupId = 1;
      wallMesh.visibility = 0.6;
      let wallGeometry = new Kingfisher.Geometry('geo' + wall.nanoId, scene, undefined, true, wallMesh);
      data.wallMesh = wallMesh;
      data.wallGeometry = wallGeometry;

      //line init
      let lineMesh = new Kingfisher.Mesh(wall.name + '_line', scene);
      lineMesh.parent = scene._wallRoot;
      lineMesh.alphaIndex = 21;
      lineMesh.renderingGroupId = 1;
      lineMesh.enableState = 0;
      let lineGeometry = new Kingfisher.Geometry('line' + wall.nanoId, scene, undefined, true, lineMesh);
      if (!scene.lineMaterial) {
        let lineMaterial = new Kingfisher.KMaterial('line', scene);
        let c = new Kingfisher.KMatChannel(lineMaterial);
        let lightLine = new Kingfisher.KMatStageNoLight(lineMaterial, c);
        lightLine.colorBase.set(0, 0, 0);
        lineMaterial.alpha = 0.4;
        lineMaterial.wireframe = 1;
        lineMaterial.transparencyMode = 3;
        scene.lineMaterial = lineMaterial;
      }
      lineMesh.material = scene.lineMaterial;
      data.lineMesh = lineMesh;
      data.lineGeometry = lineGeometry;

      //point init
      let pointMesh = new Kingfisher.Mesh(wall.name + '_point', scene);
      pointMesh.parent = scene._wallRoot;
      let pointGeometry = new Kingfisher.Geometry('point' + wall.nanoId, scene, undefined, true, pointMesh);
      pointMesh.renderingGroupId = 1;
      pointMesh.alphaIndex = 22;
      pointMesh.material = scene.getMaterialByName('point');
      data.pointMesh = pointMesh;
      data.pointGeometry = pointGeometry;
      data.pickMeshes = [wallMesh];
      data.highlightMeshes = [wallMesh];
      data.dispose = function () {
        data.wallMesh.dispose();
        data.wallGeometry.dispose();
        data.lineMesh.dispose();
        data.lineGeometry.dispose();
        data.pointMesh.dispose();
        data.pointGeometry.dispose();
      };
      data.focusObject = wallMesh;
      data.selected = false;
      data.setSelected = function (selected, mode) {
        data.selected = selected;
        if (selected) {
          lineMesh.enableState = mode === 'line' ? 1 : 0;
          pointMesh.enableState = mode === 'point' ? 1 : 0;
        } else {
          lineMesh.enableState = 0;
          pointMesh.enableState = 0;
        }
      };
    }
    if (!wall.finalVisible) {
      data.pointMesh.enableState = false;
      data.lineMesh.enableState = false;
      data.wallMesh.enableState = false;
      return false;
    } else {
      data.pointMesh.enableState = true;
      data.lineMesh.enableState = true;
      data.wallMesh.enableState = true;
    }
    data.wallMesh.material = data.scene.getMaterialByName('wall-' + wall.purpose) || data.scene.getMaterialByName('wall');
    let i;
    //dirty check
    for (i = 0; i < wall.segments.length; i++) {
      let s = wall.segments[i];
      s.checkDirty();
    }
    for (i = 0; i < wall.points.length; i++) {
      let p = wall.points[i];
      p.checkDirty();
    }

    // line build
    let lineGeometry = data.lineGeometry;
    // let positions = new Float32Array(wall.segments.length * 2 * 3);
    // let indices = new Uint32Array(wall.segments.length * 3);
    let positions = [];
    let indices = [];
    for (i = 0; i < wall.segments.length; i++) {
      let s = wall.segments[i];
      let b = positions.length / 3;
      if (!s.isArc) {
        positions.push(s.pointA.x, 0, s.pointA.y);
        positions.push(s.pointB.x, 0, s.pointB.y);
        indices.push(b, b, b + 1);
      } else {
        let center = s.center;
        let radius = s.radius * Math.sign(s.angle);
        let startAngle = s.directionA;
        let tessellation = s.tessellation;
        let step = s.stepAngle;
        for (let j = 0; j < tessellation + 2; j++) {
          let angle = startAngle + step * j;
          positions.push(center.x + radius * Math.cos(angle), 0, center.y + radius * Math.sin(angle));
          if (j < tessellation + 1) {
            indices.push(b, b, b + 1);
            b++;
          }
        }
      }
    }
    lineGeometry.setVerticesData(Kingfisher.VertexBuffer.PositionKind, positions, false);
    lineGeometry.setIndices(indices, null, true);

    //point build
    let pointGeometry = data.pointGeometry;
    positions = new Float32Array(wall.points.length * 3);
    indices = new Uint32Array(wall.points.length * 3);
    for (i = 0; i < wall.points.length; i++) {
      let p = wall.points[i];
      let t = i * 3;
      positions[t + 0] = p.x;
      positions[t + 1] = 0;
      positions[t + 2] = p.y;
      indices[t + 0] = i;
      indices[t + 1] = i;
      indices[t + 2] = i;
    }
    pointGeometry.setVerticesData(Kingfisher.VertexBuffer.PositionKind, positions, false);
    pointGeometry.setIndices(indices, null, true);
    buildWallMesh(wall);
    let mesh2d = smMesh2DCache[wall.nanoId];
    let poss = mesh2d.poss;
    let wPositions = new Float32Array(poss.length * 1.5);
    for (let i = 0; i < poss.length / 2; i++) {
      wPositions[i * 3] = poss[i * 2];
      wPositions[i * 3 + 1] = 0;
      wPositions[i * 3 + 2] = poss[i * 2 + 1];
    }
    let wallGeometry = data.wallGeometry;
    wallGeometry.setVerticesData(Kingfisher.VertexBuffer.PositionKind, wPositions, false);
    wallGeometry.setIndices(mesh2d.indices, null, true);
    if (currData.currList.includes(wall)) {
      sm2DMeshBuilder.updateSelectPointAndSegment();
    }
    return true;
  }
}
export default new SmWall2DBuilder();