import "core-js/modules/es.array.push.js";
import { menuCurrItem, isShowContextMenu, doMenuClick } from "@/components/mainContent/rightPanel";
import { globalState, currData } from "@/model/globalData";
import { watch, ref } from "vue";
import oemManager from "@/utils/oem";
export default {
  name: "contextmenu",
  setup() {
    let list = ref([]);
    watch(isShowContextMenu, () => {
      if (!isShowContextMenu) {
        list.value = [];
      }
      let item = currData.currList[0];
      // to do

      let res = [];
      if (globalState.mode !== 'model' && globalState.mode !== 'devicePoint') {
        res.push({
          label: Kf.t('New'),
          key: 'create'
        });
      }
      switch (globalState.mode) {
        case 'floor':
          res.push({
            label: Kf.t('Clone'),
            key: 'clone'
          });
          break;
        case 'model':
          if (menuCurrItem.value) {
            if (!menuCurrItem.value.isGroup) {
              res.push({
                label: Kf.t('Clone'),
                key: 'clone'
              });
            }
            // res.push({
            //     label: Kf.t('BindDevicePoints'),
            //     key: 'bindDevicePoint'
            // })
            res.push({
              label: Kf.t('ReplaceModel'),
              key: 'replaceModel'
            });
            if (menuCurrItem.value.devicePointId) {
              res.push({
                label: Kf.t('DeleteMapping'),
                key: 'deleteMapping'
              });
            }
          }
          break;
        case 'drawing':
          if (menuCurrItem.value) {
            let type = menuCurrItem.value.type;
            if (type === 'imageDrawing') {
              res.push({
                label: Kf.t('UpdateDrawings'),
                key: 'updateImage'
              });
            } else if (type === 'dxfDrawing') {
              res.push({
                label: Kf.t('UpdateCad'),
                key: 'updateDxf',
                args: ['dxf']
              });
            }
            res.push({
              label: Kf.t('CalibrateDrawingCenter'),
              key: 'calibrateCenter',
              flag: 'drawing'
            }, {
              label: Kf.t('CalibrateDrawingSize'),
              key: 'calibrateSize',
              flag: 'drawing'
            }, {
              label: Kf.t('CalibrateFloorCenter'),
              key: 'calibrateCenter',
              flag: 'floor'
            }, {
              label: Kf.t('CalibrateFloorSizes'),
              key: 'calibrateSize',
              flag: 'floor'
              // }, {
              //   label: '校准角度',
              //   key: 'calibrateRotation'
            });
            // res.push({
            //     label: Kf.t('AutomaticallyGeneratePoint'),
            //     key: 'autoCreateModelFromDrawing',
            //     flag: 'floor',
            // })
          } else {
            res.push({
              label: Kf.t('New') + '(CAD)',
              key: 'create',
              args: ['dxf']
            });
          }
          break;
        case 'devicePoint':
          if (Object.keys(currData.models).length) {
            res.push({
              label: '绑定模型',
              key: 'bindModelsToDevice'
            });
          }
          if (menuCurrItem.value) {
            if (menuCurrItem.value.deviceModelList.size) {
              res.push({
                label: Kf.t('DeleteMapping'),
                key: 'deleteMapping'
              });
            }
          }
          break;
      }
      if (item) {
        if (item.isDeleted) {
          // res.push({
          //   label: Kf.t('UndoDeletion'),
          //   key: 'unDelete'
          // });
        } else {
          if (globalState.mode !== 'devicePoint') {
            res.push({
              label: Kf.t('Delete'),
              key: 'delete'
            });
          }
        }
      }
      if (globalState.mode === 'floor') {
        res.push({
          label: Kf.t('Delete') + '(' + Kf.t('OnlyFloor') + ')',
          key: 'delete',
          flag: 'onlyFloor'
        });
      }
      if (!res.length) {
        isShowContextMenu.value = 0;
      }
      list.value = res;
    });
    return {
      doMenuClick,
      list
    };
  }
};