import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array-buffer.detached.js";
import "core-js/modules/es.array-buffer.transfer.js";
import "core-js/modules/es.array-buffer.transfer-to-fixed-length.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.find-last.js";
import "core-js/modules/es.typed-array.find-last-index.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.to-reversed.js";
import "core-js/modules/es.typed-array.to-sorted.js";
import "core-js/modules/es.typed-array.with.js";
import { each } from "../utils/tools";
import { currData } from "@/model/globalData";
import { buildAreaMesh } from "@/builder/smAreaBuildTools";
import { smMesh2DCache } from "@/model/smCollectionData";
class Area2DBuilder {
  update(data) {
    let scene = data.scene;
    let area = data.obj;
    if (!data.inited) {
      if (!area.finalVisible) {
        return false;
      }
      data.inited = true;

      //area init
      let areaMesh = new Kingfisher.Mesh(area.name + 'area', scene);
      areaMesh.parent = scene._areaRoot;
      areaMesh.alphaIndex = 10;
      areaMesh.renderingGroupId = 1;
      areaMesh.visibility = 0.45;
      let areaGeometry = new Kingfisher.Geometry('geo' + area.nanoId, scene, undefined, true, areaMesh);
      //areaMaterial.wireframe = 1;

      data.areaMesh = areaMesh;
      // data.areaMaterial = areaMaterial;
      data.areaGeometry = areaGeometry;

      //line init
      let lineMesh = new Kingfisher.Mesh(area.name + '_area', scene);
      lineMesh.parent = scene._wallRoot;
      lineMesh.alphaIndex = 11;
      lineMesh.renderingGroupId = 1;
      let lineGeometry = new Kingfisher.Geometry('line' + area.nanoId, scene, undefined, true, lineMesh);
      let lineMaterial = new Kingfisher.KMaterial('line', scene);
      lineMaterial.wireframe = 1;
      lineMaterial.alpha = 0.2;
      lineMaterial.transparencyMode = 3;
      lineMesh.material = lineMaterial;
      data.lineMesh = lineMesh;
      data.lineMaterial = lineMaterial;
      data.lineGeometry = lineGeometry;

      //point init
      let pointMesh = new Kingfisher.Mesh(area.name + '_point', scene);
      pointMesh.parent = scene._areaRoot;
      pointMesh.geometry = lineGeometry;
      pointMesh.alphaIndex = 22;
      pointMesh.renderingGroupId = 1;
      pointMesh.material = scene.getMaterialByName('point');
      data.pointMesh = pointMesh;
      data.pickMeshes = [areaMesh];
      data.highlightMeshes = [areaMesh];
      data.focusObject = areaMesh;
      data.selected = false;
      data.setSelected = function (selected, mode) {
        data.selected = selected;
        if (selected) {
          lineMesh.enableState = mode === 'line' ? 1 : 0;
          pointMesh.enableState = mode === 'point' ? 1 : 0;
        } else {
          lineMesh.enableState = 0;
          pointMesh.enableState = 0;
        }
      };
      data.textPoint = new Kingfisher.TextPoint();
      data.textPoint.position = new Kingfisher.Vector3(0, 0, 0);
      scene._textAreaMesh.points.push(data.textPoint);
      data.dispose = function () {
        data.areaMesh.dispose();
        data.areaGeometry.dispose();
        data.lineMesh.dispose();
        data.lineMaterial.dispose();
        data.lineGeometry.dispose();
        data.pointMesh.dispose();
        let textIndex = scene._textAreaMesh.points.indexOf(data.textPoint);
        if (textIndex >= 0) {
          scene._textAreaMesh.points.splice(textIndex, 1);
          scene._textAreaMesh.build();
        }
      };
    }
    if (!area.finalVisible) {
      data.pointMesh.enableState = false;
      data.lineMesh.enableState = false;
      data.areaMesh.enableState = false;
      if (data.textPoint.visible) {
        scene._textAreaMesh.build();
      }
      data.textPoint.visible = false;
      return false;
    } else {
      data.pointMesh.enableState = true;
      data.lineMesh.enableState = true;
      data.areaMesh.enableState = area.points.length > 2;
      if (!data.textPoint.visible) {
        scene._textAreaMesh.build();
      }
      data.textPoint.visible = true;
    }
    data.areaMesh.material = scene.getMaterialByName('area-' + area.purpose) || data.scene.getMaterialByName('area');
    let points = area.points;
    let i;
    //dirty check
    for (i = 0; i < points.length; i++) {
      points[i].checkDirty();
    }

    //line build
    //point build
    let lineGeometry = data.lineGeometry;
    let totalLength = area.points.length;
    let positions = new Float32Array(totalLength * 3);
    let indices = new Uint32Array(totalLength * 3);
    for (i = 0; i < points.length; i++) {
      let t = i * 3;
      let point = points[i];
      positions[t + 0] = point.x;
      positions[t + 1] = 0;
      positions[t + 2] = point.y;
      indices[t + 0] = i;
      indices[t + 1] = i;
      indices[t + 2] = i === 0 ? points.length - 1 : i - 1;
    }
    lineGeometry.setVerticesData(Kingfisher.VertexBuffer.PositionKind, positions, false);
    lineGeometry.setIndices(indices, null, true);

    //area build
    buildAreaMesh(area);
    let mesh2d = smMesh2DCache[area.nanoId];
    let poss = mesh2d.poss;
    let wPositions = new Float32Array(poss.length * 1.5);
    for (let i = 0; i < poss.length / 2; i++) {
      wPositions[i * 3] = poss[i * 2];
      wPositions[i * 3 + 1] = 0;
      wPositions[i * 3 + 2] = poss[i * 2 + 1];
    }
    let areaGeometry = data.areaGeometry;
    areaGeometry.setVerticesData(Kingfisher.VertexBuffer.PositionKind, wPositions, false);
    areaGeometry.setIndices(mesh2d.indices, null, true);
    let texts = [];
    if (points.length > 2) {
      if (area.showName && area.name) {
        texts.push(area.name);
      }
      if (area.showCustomId && area.customId !== null && area.customId !== '' && area.customId !== undefined) {
        texts.push(area.customId);
      }
      if (area.showArea) {
        texts.push((area.area * 0.000001).toFixed(1) + '㎡');
      }
    }
    let text = texts.join('\r\n');
    let textBuild = text !== data.textPoint.text || text && (data.textPoint.position.x !== area.textPos.x || data.textPoint.position.z !== area.textPos.y);
    if (textBuild) {
      data.textPoint.text = text;
      data.textPoint.position.x = area.textPos.x;
      data.textPoint.position.z = area.textPos.y;
      scene._textAreaMesh.build();
    }
    if (currData.currList.includes(area)) {
      sm2DMeshBuilder.updateSelectPointAndSegment();
    }
    return true;
  }
}
export default new Area2DBuilder();