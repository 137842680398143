import { ref, watch, computed } from 'vue';
import pageModel from "@/model/page";
import _ from 'lodash';
export default {
  name: "inputProp",
  props: {
    props: {
      type: Object,
      default: () => {
        return {};
      }
    },
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  setup(props, {
    emit
  }) {
    let inputValue = ref("");
    inputValue.value = pageModel.getPropValue(props.model, props.props.name);
    let show = ref(false);
    const doInputContent = function (val) {
      emit("propChange", {
        name: props.props.name,
        value: val
      });
    };
    const text = ref("");
    watch(props, (newValue, oldValue) => {
      inputValue.value = pageModel.getPropValue(props.model, props.props.name);
    });
    return {
      show,
      doInputContent,
      text,
      inputValue,
      lodash: _
    };
  }
};