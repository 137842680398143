<template>
    <div class="container  flex flex-jc-sb kf-header-box">
        <div class="flex">
            <div class="kf-header-menu-icon">
                <!--        <i class="iconfont icon-caidan ml10 kf-icon1"></i>-->
                <img :src="logo" style="max-width:100px;display: flex;"/>
            </div>
            <div class="kf_header_menu ml10">
                <el-menu class="el-menu-demo" popper-class="kf_header_menu_sub" mode="horizontal" :ellipsis="false"
                >
                    <el-sub-menu :index="item.code"
                                 :key="item.code"
                                 popper-class="kf_header_menu_sub"
                                 v-for="(item,index) in menuList"
                    >
                        <template #title>{{ item.name }}</template>
                        <el-menu-item v-for="(item2,index2) in item.children"
                                      :index="item2.code"
                                      :key="item2.code"
                                      @click="menuOpenMenu(item2)"
                                      v-if="item.children&&item.children.length>0"
                                      :class="item2.code==='1-3'||
                                      item2.code==='1-5'||
                                      item2.code==='2-0'||
                                      item2.code==='2-1'||
                                      item2.code==='2-2'||
                                      item2.code==='2-4'||
                                      item2.code==='2-8'||
                                      item2.code==='2-7'?'menu_border':''">
                            <template #title v-if="!item2.children">
                            <!--              <i class="iconfont  el_menu_i" :class="item2.icon"></i>-->
                            <span class="el_menu_span ml10">{{ item2.name }}</span>
                            </template>
                            <el-sub-menu v-if="item2.children" :index="index2.toString()"
                                         popper-class="kf-menu-popper-box">
                                <template #title>
                                    <span class="el_menu_span"
                                          style="display: inline-block;width: 80px">{{ item2.name }}</span>
                                </template>
                                <el-menu-item index="2-4-1" v-for="(item3, index3) in item2.children"
                                              @click="handleSubMenu(item3,item2)">
                                    {{ item3.name }}
                                </el-menu-item>
                            </el-sub-menu>
                        </el-menu-item>
                    </el-sub-menu>
                </el-menu>
            </div>
        </div>
        <div class="flex">

            <div class="kf-user-box mr16">
                <el-dropdown @command="doClickChange" trigger="click">
                    <span class="el-dropdown-link">
                  <div class="right-content_box"><img src="../../styles/img/header/user.png" style="margin-right: 5px">
                      <div class="right-content_box-name" :title="loginUserInfo.userName">{{
                          loginUserInfo.userName
                          }}</div>
                  </div>
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                    <template #dropdown>
                        <el-dropdown-menu v-if="customization.isEnable($t('AllowClickUser'), 'user', 'click')">
                            <el-dropdown-item v-for="(item,index) in options"
                                              :key="index"
                                              :command="item"
                            >{{ item.name }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>

        </div>
    </div>
    <div style="position: absolute;top:0;width: 100%;pointer-events: none;margin-top: 9px" class="flex flex-jc-c">
        <div class="flex" style="width: 560px">
            <div style="width: 50%;color: #949494;font-size: 14px" class="flex flex-jc-fe">
                <div style="text-align: right">
                    <!--          <span style="vertical-align: sub;"> --> {{ currProject.name }}<!--</span>-->
                </div>
                <div style="padding: 0 5px"><!--<span style="vertical-align: sub;">-->/<!--</span>--></div>
            </div>
            <div
                    style="width: 50%;color: #FFFFFF;font-size: 14px;pointer-events: auto;"
                    @dblclick="openCurrSceneNameEdit($event)"
                    @mouseleave="rebuildSceneName($event)"
                    :contenteditable="isOpenCurrSceneNameEdit"
                    class="self_building_input"
            >
                {{ currScene.name }}
                <!--        <span style="vertical-align: sub;"> {{ currScene.name }}</span>-->
            </div>
        </div>
    </div>

    <el-dialog custom-class="kf-3dc-dialog" :title="$t('Project')" v-model="projectDialog" width="70%"
               top="100px" :close-on-click-modal="false" :show-close="false">
        <template #title>
            <div class="kf-3dc-dialog-header">
                <div class="kf-3dc-dialog-headers">
                    <div class="kf-3dc-dialog-headers-left">
                        <i class="iconfont icon-Vector4 mr3" style="font-size: 13px"></i>
                        <div class="kf-3dc-dialog-headers-title mr2">{{ $t('ProjectManagement') }}</div>
                        <div class="kf-3dc-dialog-headers-title2"
                             v-if="currProject && currProject.name && currProject.name!==''"> > {{ currProject.name }}
                        </div>
                    </div>
                    <div class="kf-3dc-dialog-headers-close" @click="closeProjectDialog()">
                        <i class="iconfont icon-close"></i>
                    </div>
                </div>
            </div>
        </template>
        <div class="kf-3dc-dialog-body">
            <div class="kf-3dc-dialog-content">
                <div class="kf-3dc-dialog-contents">
                    <div class="kf-dialog-right">
                        <div class="kf-dialog-right1">
                            <div class="kf-dialog-right-header1 mt10">
                                {{ $t('ProjectList') }}
                                <div class="kf-project-header-con">
                                    <div class="kf-dialog-right-header1-input mr10">
                                        <el-input
                                                :placeholder="$t('Search')"
                                                prefix-icon="Search"
                                                clearable
                                                v-model="projectSearch"
                                        >
                                        </el-input>
                                    </div>
                                    <div class="kf-dialog-right-header1-input">
                                        <el-select v-model="groupValue" popper-class="kf-select"
                                                   :placeholder="$t('PleaseSelect')" @change="groupSelect">
                                            <el-option
                                                    :key="index"
                                                    :label="item"
                                                    :value="item"
                                                    v-for="(item,index) in [$t('ShowAllGroups')].concat(Object.keys(projectList))"></el-option>
                                        </el-select>
                                    </div>
                                </div>
                            </div>
                            <div class="kingfisher_scroll kf-list-box mt10">
                                <div class="kf-list-box-item" v-for="(group,index1) in Object.keys(projectAll)"
                                     :key="group">
                                    <div class="kf-dialog-right-header mt10" v-show="getProjects(group).length>0">
                                        {{ group }}
                                        <div class="ml5 project-number">({{ getProjects(group).length }})</div>
                                    </div>
                                    <div class="kf-dialog-right-con mt5 " v-show="getProjects(group).length>0">

                                        <div class='kf-dialog-right-cons kf-dialog-right-cons-bg'
                                             v-if="customization.isEnable($t('CreateProject'), 'project', 'create') && group === $t('Default')"
                                             @click="showCreateProjectDialog">
                                            <div class='kf-dialog-right-cons1 pr selfAdd'
                                                 style="justify-content: center;color: #313846">
                                                <i class="iconfont scoped-icon  kf-sd-icon-hover icon-jiahao2fill"
                                                   style='font-size: 26px;color:#FF8C4A'></i>
                                            </div>
                                        </div>


                                        <div class="kf-dialog-right-cons"
                                             :key="item.uuid"
                                             v-for="(item,index) in getProjects(group)"
                                             :class="[currProject===item?'kf-dialog-right-cons-active':'kf-dialog-right-cons-bg']"
                                             @click="selectProject(item)"
                                             @contextmenu.prevent="doRightClick($event,item)">
                                            <div class="kf-dialog-right-cons1 pr">
                                                <div class="kingfisher_scenes_component"
                                                     v-if="item.categoryUuid === '1001'">{{ $t('Component') }}
                                                </div>
                                                <div class="kf-dialog-right-cons1-name">{{ item.name }}</div>
                                            </div>
                                        </div>
                                        <!-- <div class="kf-dialog-right-cons"
                                              :key="item.uuid"
                                              v-for="(item,index) in getProjects(group)"
                                              :class="[currProject===item?'kf-dialog-right-cons-active':'kf-dialog-right-cons-bg']"
                                              @click="selectProject(item)"
                                              @contextmenu.prevent="doRightClick($event,item)">
                                           <div class="kf-dialog-right-cons1 pr">
                                             <div class="kingfisher_scenes_component"
                                                  v-if="item.categoryUuid === '1001'">{{ $t('Component') }}
                                             </div>
                                             <div class="kf-dialog-right-cons1-name">{{ item.name }}</div>
                                           </div>
                                         </div>-->
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="kf-3dc-dialog-footer">
            </div>

        </div>


    </el-dialog>

    <el-dialog custom-class="kf-3dc-dialog" :title="$t('Scene')" v-model="sceneDialog" width="70%"
               top="100px" :close-on-click-modal="false" :show-close="false">
        <template #title>
            <div class="kf-3dc-dialog-header">
                <div class="kf-3dc-dialog-headers">
                    <div class="kf-3dc-dialog-headers-left">
                        <i class="iconfont icon-Vector4 mr3" style="font-size: 13px"></i>
                        <div class="kf-3dc-dialog-headers-title mr2" @click="backToProject"
                             style="cursor: pointer;text-decoration: underline">{{ $t('ProjectManagement') }}
                        </div>
                        <div class="kf-3dc-dialog-headers-title2"
                             v-if="currProject && currProject.name && currProject.name!=''"> > <span
                        >{{ currProject.name }}</span>
                        </div>
                        <div class="kf-3dc-dialog-headers-title2"
                             v-if="currScene && currScene.name && currScene.name!=''"> > {{ currScene.name }}
                        </div>
                    </div>
                    <!--<div class="kf-3dc-dialog-headers-back" @click="backToProject">
                        {{ $t('ReturnProjectList') }}
                    </div>-->
                    <div class="kf-3dc-dialog-headers-close" @click="sceneDialog=false">
                        <i class="iconfont icon-close"></i>
                    </div>
                </div>
            </div>
        </template>
        <div class="kf-3dc-dialog-body">
            <div class="kf-3dc-dialog-content">
                <div class="kf-3dc-dialog-scene">
                    <div class="kf-3dc-dialog-scene-header">
                        <div class="kf-3dc-dialog-scene-header-txt">{{ $t('CreateBuilding') }}</div>
                        <div class="kf-3dc-dialog-scene-header-txt1">
                            {{ $t('StartQuickConstructionUsingInitialTemplateComponents') }}！
                        </div>
                        <div class="kf-3dc-dialog-scene-header-txt2 mt10">
                            <div class="kf-3dc-dialog-scene-header-txt3" @click="doCreateScene()">
                                {{ $t('CreateBuilding') }}
                            </div>
                            <div class="kf-3dc-dialog-scene-header-txt3 ml10" @click="addVersion()">
                                {{ $t('PublishVersion') }}
                            </div>
                            <div class="kf-3dc-dialog-scene-header-txt3 ml10 kingfisher_select2"
                                 style="border: none;width: 250px;">
                                <el-select v-model="currProjectVersion" :placeholder="$t('PleaseSelect')"
                                           popper-class="kingfisher_select2-option">
                                    <el-option v-for="item in projectVersion"
                                               :value="item.version"
                                               :key="item.uuid" :label="getVersionLabel(item)"></el-option>
                                </el-select>
                            </div>
                            <div class="kf-3dc-dialog-scene-header-txt3 ml10" @click="backVersion()"
                                 style="background-color: #35363a">{{ $t('RestoreVersion') }}
                            </div>
                        </div>
                    </div>
                    <div class="kf-3dc-dialog-scene-list">
                        <div class="kf-3dc-dialog-scene-list-header">
                            <div class="kf-3dc-dialog-scene-list-txt ml10">{{ $t('BuildingHasBeenCreated') }}</div>
                            <div class="kf-3dc-dialog-scene-list-txt2">（{{ sceneList.length }}）</div>
                            <el-input v-model="searchScene" suffix-icon="Search" placeholder="搜索"
                                      class="kf-3dc-dialog-scene-list-header-input1" clearable></el-input>
                        </div>
                        <div class="kf-3dc-dialog-scene-lists">
                            <div class="kf-3dc-dialog-scene-lists-left">
                                <div class="kingfisher_scenes_con_title kingfisher_scenes_con_title_color">
                                    <div class="kingfisher_scenes_con_name">{{ $t('Name') }}</div>
                                    <!--                                    <div class="kingfisher_scenes_con_size">{{ $t('NumberOfFloors') }}</div>-->
                                    <div class="kingfisher_scenes_con_version">{{ $t('User') }}</div>
                                    <div class="kingfisher_scenes_con_date">{{ $t('LastUpdateTime') }}</div>
                                    <div class="kingfisher_scenes_con_more">{{ $t('Operation') }}</div>
                                </div>
                                <div class="kf-3dc-scene-list-con kingfisher_scroll">
                                    <div class="kingfisher_scenes_con_title1 kingfisher_scenes_con_title_color"
                                         :class="[currScene === item?'kingfisher_scenes_cons_color_active':'kingfisher_scenes_cons_color']"
                                         v-for="(item,index) in sceneList"
                                         :key="item"
                                         @dblclick="scenePopoverOpen(item)"
                                         @click="handleScene(item)">
                                        <div class="kingfisher_scenes_con_name">{{ item.name }}</div>
                                        <!--                                        <div class="kingfisher_scenes_con_size">{{item.data.floors}}</div>-->
                                        <div class="kingfisher_scenes_con_version" :title="item.lastModifiedBy"><span
                                                style="text-overflow: ellipsis;white-space: nowrap; overflow: hidden">{{
                                            item.lastModifiedBy
                                            }}</span></div>
                                        <div class="kingfisher_scenes_con_date">{{
                                            formatDate(item.lastModifiedDate)
                                            }}
                                        </div>
                                        <div class="kingfisher_scenes_con_more">
                                            <el-popover placement="right-start"
                                                        trigger="click"
                                                        popper-class="scene_operate" width="70"
                                                        style="min-width: 70px;">
                                                <div class="scene_operate_con" v-show="scenePopover">
                                                    <div class="scene_operate_con1 scene_operate_con2"
                                                         @click="scenePopoverOpen(item)">{{ $t('Open') }}
                                                    </div>
                                                    <div class="scene_operate_con1 scene_operate_con2"
                                                         @click="scenePopoverEdit(item)">{{ $t('Edit') }}
                                                    </div>
                                                    <div class="scene_operate_con1 scene_operate_con3"
                                                         @click="scenePopoverDelete(item)">{{ $t('Delete') }}
                                                    </div>
                                                </div>
                                                <template #reference>
                                                    <el-button class="iconfont icon-gengduo scene-more"
                                                               @click="scenePopover=true"></el-button>
                                                </template>

                                            </el-popover>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="kf-3dc-dialog-scene-lists-right">
                                <div class="kf-3dc-dialog-scene-img">
                                    <img :src="getBuildingImageUrl()"
                                         style="width: 100%;height: 100%;margin: 0 ;display: block;"
                                         @error="imageError($event)"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="kf-3dc-dialog-footer">
            </div>

        </div>


    </el-dialog>

    <el-dialog custom-class="kf-3dc-dialog" :title="$t('ProjectSettings')" v-model="projectEditDialog" width="40%"
               top="100px" :close-on-click-modal="false" :show-close="false">
        <template #title>
            <div class="kf-3dc-dialog-header">
                <div class="kf-3dc-dialog-headers">
                    <div class="kf-3dc-dialog-headers-left">
                        <i class="iconfont icon-Vector4 mr3" style="font-size: 13px"></i>
                        <div class="kf-3dc-dialog-headers-title mr2">{{ $t('ProjectSettings') }}</div>
                        <div class="kf-3dc-dialog-headers-title2"
                             v-if="rightSelectedProject && rightSelectedProject.name && rightSelectedProject.name!=''">
                            > {{ rightSelectedProject.name }}
                        </div>
                    </div>
                    <div class="kf-3dc-dialog-headers-close" @click="projectEditDialog=false">
                        <i class="iconfont icon-close"></i>
                    </div>
                </div>
            </div>
        </template>
        <div class="kf-3dc-dialog-body">
            <div class="kf-3dc-dialog-content">
                <div class="kf-3dc-dialog-contents">
                    <div class="kf-3dc-dialog-tabs">
                        <div class="kf-3dc-dialog-tabs-header">
                            <div class="kf-3dc-dialog-tabs-title"
                                 :class="[currTabName===item?'kf-3dc-dialog-tabs-title-active':'kf-3dc-dialog-tabs-title']"
                                 v-for="(item,index) in tabNameList"
                                 :key="index"
                                 @click="currTabName=item">{{ item }}
                            </div>
                        </div>
                        <div class="kf-3dc-dialog-tabs-content">
                            <div class="kf-dialog-left-con" v-if="currTabName===$t('PropertySettings')">
                                <div class="kf-dialog-left-cons mt20">
                                    <el-form :model="editProject" :rules="projectRules" ref="createProjectForm">
                                        <div class="kf-dialog-left-con1 ">
                                            <el-form-item :label="$t('ProjectCode')" prop="code">
                                                <el-input type="text" v-model="editProject.code" readonly/>
                                            </el-form-item>

                                        </div>
                                        <div class="kf-dialog-left-con1 mt5 ">
                                            <el-form-item :label="$t('ProjectName')" prop="name">
                                                <el-input type="text" v-model="editProject.name"
                                                          @blur="checkCreateNotNull($event, editProject.name)"
                                                          maxlength="20"/>
                                            </el-form-item>


                                        </div>
                                        <div class="kf-dialog-left-con1 mt5">
                                            <el-form-item :label="$t('Author')" prop="author">
                                                <el-input type="text" v-model="editProject.author"
                                                          @blur="checkCreateNotNull($event, editProject.author)"/>
                                            </el-form-item>

                                        </div>
                                        <div class="kf-dialog-left-con1 mt5">
                                            <label class="kf-dialog-left-con1-label">{{ $t('Description') }}</label>
                                            <div class="kf-dialog-left-con2-input">
                                                <el-input type="textarea " v-model="editProject.description"></el-input>
                                            </div>
                                        </div>
                                    </el-form>
                                </div>
                                <div class="kf-dialog-left-btns" style="justify-content: center">
                                    <div class="kf-dialog-left-btn kf-dialog-left-btn1"
                                         style="width: 120px;margin-right: 20px" @click="projectEditDialog=false">
                                        {{ $t('Cancel') }}
                                    </div>
                                    <div class="kf-dialog-left-btn kf-dialog-left-btn2" style="width: 120px;"
                                         @click="doEditProject">{{ $t('ClickOkToConfirm2') }}
                                    </div>
                                </div>

                            </div>
                            <div class="kf-dialog-left-con2" v-else>
                                <div class="kf-dialog-left-cons ">
                                    <div class="kf-dialog-left-add" @click="addProjectRoute">{{ $t('AddNew') }}</div>
                                    <div class="kf-dialog-left-table mt5" v-if="editProject.routes ">
                                        <el-table border stripe :data="editProject.routes" tooltip-effect="dark"
                                                  height="100%"
                                                  style="width: 100%; height: 100%">
                                            <el-table-column prop="path" :label="$t('Path')" width="200px">
                                                <template slot-scope="scope">
                                                    <el-input v-model="scope.row.path"></el-input>
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="scene" :label="$t('Scene')" width="200">
                                                <template slot-scope="scope">
                                                    <el-select v-model="scope.row.scene" placeholder="Select"
                                                               size="mini">
                                                        <el-option v-for="item in editProject.scenes"
                                                                   :key="item.uuid" :label="item.name"
                                                                   :value="editProject.uuid + '/' + item.uuid + '.json'">
                                                        </el-option>
                                                    </el-select>
                                                </template>
                                            </el-table-column>
                                            <el-table-column :label="$t('Operation')">
                                                <template slot-scope="scope">
                                                    <div class="kingfisher_table_delete"
                                                         @click="delProjectRoute(scope.row)">{{ $t('Delete') }}
                                                    </div>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </div>
                                </div>
                                <div class="kf-dialog-left-btns mt5" style="justify-content: center">
                                    <div class="kf-dialog-left-btn kf-dialog-left-btn1"
                                         style="width: 120px;height: 35px;margin-right: 20px"
                                         @click="projectEditDialog=false">{{ $t('Cancel') }}
                                    </div>
                                    <div class="kf-dialog-left-btn kf-dialog-left-btn2"
                                         style="width: 120px;height: 35px;" @click="doEditProject">
                                        {{ $t('ClickOkToConfirm2') }}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="kf-3dc-dialog-footer">
            </div>
        </div>
    </el-dialog>

    <el-dialog custom-class="kf-3dc-dialog group-dialog" :title="$t('MoveGroup')" v-model="projectGroupDialog"
               width="500px"
               top="15%" :close-on-click-modal="false" :show-close="false">
        <template #title>
            <div class="kf-3dc-dialog-header">
                <div class="kf-3dc-dialog-headers">
                    <div class="kf-3dc-dialog-headers-left">
                        <i class="iconfont icon-Vector4 mr3" style="font-size: 13px"></i>
                        <div class="kf-3dc-dialog-headers-title mr2">{{ $t('ProjectSettings') }}</div>
                        <div class="kf-3dc-dialog-headers-title2"
                             v-if="rightSelectedProject && rightSelectedProject.name && rightSelectedProject.name!=''">
                            > {{ rightSelectedProject.name }}
                        </div>
                    </div>
                    <div class="kf-3dc-dialog-headers-close" @click="projectGroupDialog=false">
                        <i class="iconfont icon-close"></i>
                    </div>
                </div>
            </div>
        </template>
        <div class="kf-3dc-dialog-body">
            <div class="kf-3dc-dialog-content">
                <div class="kf-3dc-dialog-contents" style="height: calc(100% - 60px);">
                    <div class="kf-3dc-dialog-contents2">
                        <div class="kf-3dc-dialog-select">
                            <div class="kf-3dc-dialog-select-label">{{ $t('GroupName') }}</div>
                            <div class="kf-3dc-dialog-select-con">
                                <el-select v-model="editProject.groups" popper-class="kf-select"
                                           :placeholder="$t('PleaseSelect')">
                                    <el-option
                                            :key="index"
                                            :label="item"
                                            :value="item"
                                            v-for="(item,index) in Object.keys(projectList)"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="kf-dialog-left-btns mt5" style="justify-content: center">
                    <div class="kf-dialog-left-btn kf-dialog-left-btn1"
                         style="width: 120px;height: 35px;margin-right: 20px" @click="projectGroupDialog=false">
                        {{ $t('Cancel') }}
                    </div>
                    <div class="kf-dialog-left-btn kf-dialog-left-btn2" style="width: 120px;height: 35px;"
                         @click="doEditProject">{{ $t('ClickOkToConfirm2') }}
                    </div>
                </div>
            </div>
            <div class="kf-3dc-dialog-footer">
            </div>
        </div>
    </el-dialog>

    <el-dialog custom-class="kf-3dc-dialog group-dialog" :title="$t('CreateScene')" v-model="createSceneDialog"
               width="500px"
               top="15%" :close-on-click-modal="false" :show-close="false">
        <template #title>
            <div class="kf-3dc-dialog-header">
                <div class="kf-3dc-dialog-headers">
                    <div class="kf-3dc-dialog-headers-left">
                        <i class="iconfont icon-Vector4 mr3" style="font-size: 13px"></i>
                        <!--<div class="kf-3dc-dialog-headers-title mr2">{{ $t('ProjectSettings') }}</div>-->
                        <div class="kf-3dc-dialog-headers-title"
                             v-if="currProject && currProject.name && currProject.name!=''">{{ currProject.name }}
                        </div>
                        <div class="kf-3dc-dialog-headers-title2">
                            > {{ $t('CreateScene') }}
                        </div>
                    </div>
                    <div class="kf-3dc-dialog-headers-close" @click="createSceneDialog=false">
                        <i class="iconfont icon-close"></i>
                    </div>
                </div>
            </div>
        </template>
        <div class="kf-3dc-dialog-body">
            <div class="kf-3dc-dialog-content">
                <div class="kf-3dc-dialog-contents" style="height: calc(100% - 60px);">
                    <div class="kf-3dc-dialog-contents2">
                        <div class="kf-3dc-dialog-select">
                            <div class="kf-3dc-dialog-select-label">{{ $t('SceneName') }}</div>
                            <div class="kf-3dc-dialog-select-con">
                                <el-input v-model="createSceneOptions.name"></el-input>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="kf-dialog-left-btns mt5" style="justify-content: center">
                    <div class="kf-dialog-left-btn kf-dialog-left-btn1"
                         style="width: 120px;height: 35px;margin-right: 20px"
                         @click="!createSceneBtnLoading?createSceneDialog=false:''"
                         :style="[createSceneBtnLoading?'cursor:no-drop;background: #9b9b9b;border:none':'',]"
                    >{{ $t('Cancel') }}
                    </div>
                    <div class="kf-dialog-left-btn kf-dialog-left-btn2" style="width: 120px;height: 35px;"
                         @click="createScene"
                         :style="[createSceneBtnLoading?'cursor:no-drop;background: #9b9b9b;border:none':'',]">
                        {{ $t('ClickOkToConfirm2') }}
                    </div>
                </div>
            </div>
            <div class="kf-3dc-dialog-footer">
            </div>
        </div>
    </el-dialog>

    <el-dialog custom-class="kf-3dc-dialog" :title="$t('SceneSettings')" v-model="sceneEditDialog" width="40%"
               top="100px" :close-on-click-modal="false" :show-close="false">
        <template #title>
            <div class="kf-3dc-dialog-header">
                <div class="kf-3dc-dialog-headers">
                    <div class="kf-3dc-dialog-headers-left">
                        <i class="iconfont icon-Vector4 mr3" style="font-size: 13px"></i>
                        <div class="kf-3dc-dialog-headers-title mr2">{{ $t('SceneSettings') }}</div>
                        <div class="kf-3dc-dialog-headers-title2"
                             v-if="editScene && editScene.name && editScene.name!=''">
                            > {{ editScene.name }}
                        </div>
                    </div>
                    <div class="kf-3dc-dialog-headers-close" @click="sceneEditDialog=false">
                        <i class="iconfont icon-close"></i>
                    </div>
                </div>
            </div>
        </template>
        <div class="kf-3dc-dialog-body">
            <div class="kf-3dc-dialog-content">
                <div class="kf-3dc-dialog-contents">
                    <div class="kf-3dc-dialog-tabs">
                        <div class="kf-3dc-dialog-tabs-header">
                            <div class="kf-3dc-dialog-tabs-title"
                                 :class="[currSceneEditTab===item?'kf-3dc-dialog-tabs-title-active':'kf-3dc-dialog-tabs-title']"
                                 v-for="(item,index) in sceneEditTab"
                                 :key="index"
                                 :title="item"
                                 @click="currSceneEditTab=item">{{ item }}
                            </div>
                        </div>
                        <div class="kf-3dc-dialog-tabs-content">
                            <div class="kf-dialog-left-con" v-if="currSceneEditTab===$t('BuildingInformation')">
                                <div class="kf-dialog-left-cons mt20">
                                    <el-form :model="editScene" :rules="projectRules" ref="createProjectForm">
                                        <div class="kf-dialog-left-con1  ">
                                            <el-form-item :label="$t('BuildingName')" :title="$t('BuildingName')"
                                                          prop="name">
                                                <el-input type="text" v-model="editScene.name"
                                                          @blur="checkCreateNotNull($event, editScene.name)"
                                                          maxlength="20"/>
                                            </el-form-item>
                                        </div>
                                        <div class="kf-dialog-left-con1  ">
                                            <el-form-item label="ID" prop="nanoId" title="ID">
                                                <el-input type="text" v-model="editScene.nanoId"
                                                          maxlength="20"/>
                                            </el-form-item>
                                        </div>
                                        <div class="kf-dialog-left-con1  mt5">
                                            <el-form-item :label="$t('Category')" :title="$t('Category')"
                                                          prop="category">
                                                <el-input type="text" v-model="editScene.category"
                                                />
                                            </el-form-item>
                                        </div>

                                        <div class="kf-dialog-left-con1  mt5">
                                            <!--<el-form-item :label="$t('Label')" prop="tags">
                                                <el-input type="text" v-model="editScene.tags"
                                                />
                                            </el-form-item>-->
                                            <el-form-item prop="tags" :label="$t('Label')" :title="$t('Label')"
                                                          class="tag-item">
                                                <el-tag :key="tag" style='margin-right:8px ' closable
                                                        v-for="tag in editScene.tagsArray"
                                                        :disable-transitions="false"
                                                        @close="handleCloseTag(tag)">
                                                    {{ tag }}
                                                </el-tag>
                                                <el-input class="input-new-tag" style="width:40%;line-height:32px "
                                                          v-if="inputVisible" v-model="editScene.newTag"
                                                          ref="saveTagInput" size="small"
                                                          @keyup.enter.native="handleInputTagConfirm"
                                                          @blur="handleInputTagConfirm"
                                                >
                                                </el-input>
                                                <el-button v-else class="button-new-tag" style="margin-top: -2px"
                                                           size="small"
                                                           @click="showInputTag">+ {{ $t('AddNew') }}
                                                </el-button>
                                            </el-form-item>
                                        </div>

                                        <div class="kf-dialog-left-con1  mt5">
                                            <el-form-item :label="$t('Version')" :title="$t('Version')" prop="version">
                                                <el-input type="text" v-model="editScene.version"
                                                />
                                            </el-form-item>
                                        </div>

                                        <div class="kf-dialog-left-con1  mt5">
                                            <el-form-item label="k3ds" prop="k3ds">
                                                <!-- <el-input type="text" v-model="editScene.k3ds"
                                                 />-->
                                                <!-- <el-upload
                                                         class="upload-demo"
                                                         action="https://jsonplaceholder.typicode.com/posts/"
                                                         :on-preview="handlePreview"
                                                         :on-remove="handleRemove"
                                                         :before-remove="beforeRemove"
                                                         multiple
                                                         :limit="3"
                                                         :on-exceed="handleExceed"
                                                         :file-list="fileList"
                                                 >
                                                     <el-button size="small" type="primary">Click to upload</el-button>
                                                 </el-upload>-->
                                            </el-form-item>
                                        </div>


                                        <div class="kf-dialog-left-con1 mt5">
                                            <label class="kf-dialog-left-con1-label"
                                                   :title="$t('Description')">{{ $t('Description') }}</label>
                                            <div class="kf-dialog-left-con2-input">
                                                <el-input type="textarea " v-model="editScene.description"></el-input>
                                            </div>
                                        </div>
                                    </el-form>
                                </div>
                                <div class="kf-dialog-left-btns" style="justify-content: center">
                                    <div class="kf-dialog-left-btn kf-dialog-left-btn1"
                                         style="width: 120px;margin-right: 20px" @click="sceneEditDialog=false">
                                        {{ $t('Cancel') }}
                                    </div>
                                    <div class="kf-dialog-left-btn kf-dialog-left-btn2" style="width: 120px;"
                                         @click="doEditScene">{{ $t('ClickOkToConfirm2') }}
                                    </div>
                                </div>

                            </div>
                            <div class="kf-dialog-left-con2" v-else>
                                <div class="kf-dialog-left-cons ">
                                    <div class="kf-dialog-left-btns" @click="addProjectRoute">
                                        {{ $t('Upload') }}
                                        <el-upload style="margin-left: 10px;width: auto"
                                                   class="upload-resource"
                                                   ref="uploadResource"
                                                   action="/space-manager/api/repo/kf-scene-3d/updata-resource"
                                                   :data="{'projectId':currProject.uuid}"
                                                   :limit="1"
                                                   :on-change="doResourceChoseFile"
                                                   :on-success="doImportResourceSuccess"
                                                   :on-error="doImportResourceError"
                                                   :multiple="false"
                                                   accept=".png,.jpg,.mp4"
                                                   :file-list="sceneFileList"
                                                   :auto-upload="false"
                                        >
                                            <template #trigger>
                                                <el-button slot="trigger" size="small" type="primary">
                                                    {{ $t('SelectFile') }}
                                                </el-button>
                                            </template>

                                            <el-button style="margin-left: 10px;" size="small" type="success"
                                                       @click="importResource">{{ $t('UploadToServer') }}
                                            </el-button>
                                        </el-upload>
                                    </div>
                                    <div class="kf-dialog-left-table mt5"
                                         v-if="editProject.routes && editProject.routes.length>0">
                                        <el-table border stripe :data="editProject.routes" tooltip-effect="dark"
                                                  height="100%"
                                                  style="width: 100%; height: 100%">
                                            <el-table-column prop="path" :label="$t('Path')" width="200px">
                                                <template slot-scope="scope">
                                                    <el-input v-model="scope.row.path"></el-input>
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="scene" :label="$t('Scene')" width="200">
                                                <template slot-scope="scope">
                                                    <el-select v-model="scope.row.scene" placeholder="Select"
                                                               size="mini">
                                                        <el-option v-for="item in editProject.scenes"
                                                                   :key="item.uuid" :label="item.name"
                                                                   :value="editProject.uuid + '/' + item.uuid + '.json'">
                                                        </el-option>
                                                    </el-select>
                                                </template>
                                            </el-table-column>
                                            <el-table-column :label="$t('Operation')">
                                                <template slot-scope="scope">
                                                    <div class="kingfisher_table_delete"
                                                         @click="delProjectRoute(scope.row)">{{ $t('Delete') }}
                                                    </div>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </div>
                                </div>
                                <div class="kf-dialog-left-btns mt5" style="justify-content: center">
                                    <div class="kf-dialog-left-btn kf-dialog-left-btn1"
                                         style="width: 120px;height: 35px;margin-right: 20px"
                                         @click="sceneEditDialog=false">{{ $t('Cancel') }}
                                    </div>
                                    <div class="kf-dialog-left-btn kf-dialog-left-btn2"
                                         style="width: 120px;height: 35px;" @click="doEditScene">
                                        {{ $t('ClickOkToConfirm2') }}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="kf-3dc-dialog-footer">
            </div>
        </div>
    </el-dialog>

    <el-dialog custom-class="kf-3dc-dialog group-dialog" :title="$t('SaveSceneAs')" v-model="isShowSaveAsDialog"
               width="500px"
               top="15%" :close-on-click-modal="false" :show-close="false">
        <template #title>
            <div class="kf-3dc-dialog-header">
                <div class="kf-3dc-dialog-headers">
                    <div class="kf-3dc-dialog-headers-left">
                        <i class="iconfont icon-Vector4 mr3" style="font-size: 13px"></i>
                        <div class="kf-3dc-dialog-headers-title"
                             v-if="currProject && currProject.name && currProject.name!=''">{{ currProject.name }}
                        </div>
                        <div class="kf-3dc-dialog-headers-title2">
                            > {{ $t('SaveAs') }}
                        </div>
                    </div>
                    <div class="kf-3dc-dialog-headers-close" @click="isShowSaveAsDialog=false">
                        <i class="iconfont icon-close"></i>
                    </div>
                </div>
            </div>
        </template>
        <div class="kf-3dc-dialog-body">
            <div class="kf-3dc-dialog-content">
                <div class="kf-3dc-dialog-contents" style="height: calc(100% - 60px);">
                    <div class="kf-3dc-dialog-contents2">
                        <div class="kf-3dc-dialog-select">
                            <div class="kf-3dc-dialog-select-label">{{ $t('SaveAsName') }}</div>
                            <div class="kf-3dc-dialog-select-con kingfisher_input-new">
                                <el-input v-model="saveAsOption.name"></el-input>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="kf-dialog-left-btns mt5" style="justify-content: center">
                    <div class="kf-dialog-left-btn kf-dialog-left-btn1"
                         style="width: 120px;height: 35px;margin-right: 20px" @click="isShowSaveAsDialog=false">
                        {{ $t('Cancel') }}
                    </div>
                    <div class="kf-dialog-left-btn kf-dialog-left-btn2" style="width: 120px;height: 35px;"
                         @click="doSaveAs()">{{ $t('ClickOkToConfirm2') }}
                    </div>
                </div>
            </div>
            <div class="kf-3dc-dialog-footer">
            </div>
        </div>
    </el-dialog>

    <div class="kf-3dc-project-operation"
         v-show="showOperation"
         :style="contextMenuStyle"
         ref="operationMenu">
        <div class="kf-3dc-project-operation-item">
            <div class="kf-3dc-project-operation-items" @click="handleProjectEdit(0)"
                 v-if="customization.isEnable($t('AllowPropertySettings'), 'project', 'setProject')">
                {{ $t('PropertySettings') }}
            </div>
        </div>
        <div class="kf-3dc-project-operation-item">
            <div class="kf-3dc-project-operation-items" @click="handleProjectEdit(1)">{{
                $t('RouteConfiguration')
                }}
            </div>
        </div>
        <div class="kf-3dc-project-operation-item bb1"
             v-if="customization.isEnable($t('AllowMovingToGroup'), 'project', 'moveToGroup')">
            <div class="kf-3dc-project-operation-items" @click="handleEditGroup">{{ $t('MoveGroup') }}</div>
        </div>
        <div class="kf-3dc-project-operation-item bb1">
            <div class="kf-3dc-project-operation-items no-delete-color">{{ $t('Delete') }}</div>
        </div>
    </div>

    <el-dialog custom-class="kf-3dc-dialog  create-data-dialog" :title="$t('RegionSettings')" v-model="areaDialog"
               width="50%"
               top="100px" :close-on-click-modal="false" :show-close="false">
        <template #title>
            <div class="kf-3dc-dialog-header">
                <div class="kf-3dc-dialog-headers">
                    <div class="kf-3dc-dialog-headers-left">
                        <i class="iconfont icon-Vector4 mr3" style="font-size: 13px"></i>
                        <div class="kf-3dc-dialog-headers-title mr2">{{ $t('RegionSettings') }}</div>
                    </div>
                    <div class="kf-3dc-dialog-headers-close" @click="closeAreaDialog">
                        <i class="iconfont icon-close"></i>
                    </div>
                </div>
            </div>
        </template>
        <div class="kf-3dc-dialog-body">
            <div class="kf-3dc-dialog-content">
                <div class="kf-3dc-dialog-contents">
                    <div class="kf-dialog-right">
                        <div class="kf-dialog-right1">
                            <div class="kf-dialog-right-header1 mt10" style="justify-content: flex-start">
                                <div class="kf-customer-btn1" @click="addArea">
                                    {{ $t('AddAttribute') }}
                                </div>
<!--                                <div class="kf-customer-btn1 ml10" @click="addAreaStates">
                                    {{ $t('AddStatus') }}
                                </div>-->
                                <div class="kf-customer-btn1 ml10" @click="cloneAreaProps">
                                    {{ $t('CloneProperties') }}
                                </div>
                                <div class="kf-customer-btn1 ml10" @click="deleteArea">
                                    {{ $t('Delete') }}
                                </div>
                            </div>
                            <div class="kingfisher_scroll kf-list-box1 mt10 kf-customer-table1"
                                 style="width: 96%;max-height: 300px;margin-right: auto;margin-left: auto">
                                <div class="kf-customer-table-thead kf-customer-table-tr">
                                    <div class="kf-customer-table-th">{{ $t('Name') }}</div>
                                    <div class="kf-customer-table-th">{{ $t('Type') }}</div>
                                    <div class="kf-customer-table-th">{{ $t('Status') }}</div>
                                    <div class="kf-customer-table-th">{{ $t('Colours') }}</div>
<!--                                    <div class="kf-customer-table-th">{{ $t('Styles') }}</div>-->
                                </div>
                                <div class="kf-customer-table-tbody">
                                    <div class="kf-customer-table-tr"
                                         v-for="(item,index) in  areaStatesList"
                                         @click="handleCurrData(item)"
                                         :key="index"
                                         :class="[item.label==currAreaState.label && item.value==currAreaState.value?'kf-customer-table-tr-active':'']">
                                        <div class="kf-customer-table-th">{{ item.label }}</div>
                                        <div class="kf-customer-table-th">{{ item.type }}</div>
                                        <div class="kf-customer-table-th">{{ item.value.name }}</div>
                                        <div class="kf-customer-table-th">
                                            <div class="kf-table-cell-color"
                                                 :style="'background:'+item.value.color"></div>
                                        </div>
<!--                                        <div class="kf-customer-table-th">{{ item.value.style }}</div>-->
                                    </div>
                                </div>
                            </div>
                            <div class=" kf-list-box2 mt10 " v-if="currAreaState && currAreaState.purpose">
                                <el-row class="box_row mb10 mt10">
                                    <el-col :span="4" class="txtcenter">{{ $t('Name') }}</el-col>
                                    <el-col :span="20" class="abc kf-input2">
                                        <el-input autocomplete="off" v-model="currAreaState.purpose.name"/>
                                    </el-col>
                                </el-row>
                                <el-row class="box_row mb10 mt10">
                                    <el-col :span="4" class="txtcenter">{{ $t('Status') }}</el-col>
                                    <el-col :span="20" class="abc kf-input2">
                                        <el-input autocomplete="off" v-model="currAreaState.value.name"/>
                                    </el-col>
                                </el-row>
                                <el-row class="box_row mb10 mt10">
                                    <el-col :span="4" class="txtcenter">{{ $t('Type') }}</el-col>
                                    <el-col :span="20" class="abc kingfisher_select2 ">
                                        <el-select v-model="currAreaState.purpose.type"
                                                   :placeholder="$t('PleaseSelect')" style="height: 28px;">
                                            <el-option
                                                    v-for="item in areaSubTypeOptions"
                                                    :key="item.value"
                                                    :label="item.name"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-col>
                                </el-row>
                                <el-row class="box_row mb10 mt10">
                                    <el-col :span="4" class="txtcenter">{{ $t('Colours') }}</el-col>
                                    <el-col :span="20" class="abc kf-color1">
                                        <el-color-picker v-model="currAreaState.value.color" show-alpha/>
                                    </el-col>
                                </el-row>
<!--                                <el-row class="box_row mb10 mt10">
                                    <el-col :span="4" class="txtcenter">{{ $t('Styles') }}</el-col>
                                    <el-col :span="20" class="abc kingfisher_select2 ">
                                        <el-select v-model="currAreaState.value.style" :placeholder="$t('PleaseSelect')"
                                                   style="height: 28px;">
                                            <el-option
                                                    v-for="item in styleList"
                                                    :key="item"
                                                    :label="item"
                                                    :value="item">
                                            </el-option>
                                        </el-select>
                                    </el-col>
                                </el-row>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="kf-dialog-left-btns mt5 mb10" style="justify-content: center">
                    <div class="kf-dialog-left-btn kf-dialog-left-btn1"
                         style="width: 120px;height: 35px;margin-right: 20px" @click="closeAreaDialog">{{
                        $t('Cancel')
                        }}
                    </div>
                    <div class="kf-dialog-left-btn kf-dialog-left-btn2" style="width: 120px;height: 35px;"
                         @click="doSaveAreaStatesData">{{ $t('ClickOkToConfirm2') }}
                    </div>
                </div>
            </div>
            <div class="kf-3dc-dialog-footer">
            </div>

        </div>


    </el-dialog>

    <about-dialog :show.sync="dialogKingfisherAbout" v-show="dialogKingfisherAbout" @propChange="change"></about-dialog>
    <HotKey :show.sync="dialogKingfisherHotKey" v-show="dialogKingfisherHotKey" @propChange="changeHotKey"></HotKey>
    <contactUs :show.sync="dialogContactUs" v-show="dialogContactUs" @propChange="changeContactUs"></contactUs>
    <KFCreatProject :show.sync="dialogKFCreateProject" v-show="dialogKFCreateProject"
                    @propChange="changeProjectDialog"></KFCreatProject>
</template>

<script>
import {computed, onMounted, onUnmounted, ref, nextTick, watch, toRaw} from 'vue'
import userModel from '../../model/user'
import menuModel from "@/model/menu";
import projectModel from "@/model/project";
import {ElMessage, ElMessageBox} from 'element-plus';
import projectService from "@/service/project";
import keyModel from "@/model/keys";
import info from '../../utils/const';
import {
    createBuilding,
    createSceneBtnLoading,
    loadBuilding,
    saveAll,
    smDatas,
    createSceneDialog,
    saveAs
} from '@/model/smCollectionData';
import aboutDialog from "./about";
import KFCreatProject from "./KFCreatProject";
import {areaOptionsData, areaSubTypeOptions, areaSubTypeMap} from "@/model/purposeOptions";
import ProjectService from "../../service/project";
import tools, {each} from "@/utils/tools";
import {copySceneObj, pasteSceneObj} from "@/ui-dispatch/scenObj";
import HotKey from "./hoyKey";
import contactUs from "./contactUs";
import {error4buildThumbnail} from "@/model/img.base64";
import oemManager from '../../utils/oem';
import customization from "@/model/customization"
import {useRoute, useRouter} from "vue-router";
import {setToken, getToken} from "@/utils/auth";
import {sysCodeMapping} from "@/utils/sysMsg";
import modelService from "@/service/model";
import {globalNeedSave} from "@/model/globalData";

export default {
    name: "kf-Header",
    components: {aboutDialog, KFCreatProject, HotKey, contactUs},
    setup() {
        const router = useRouter();
        let loginUserInfo = ref({});
        let projectDialog = ref(true);
        let areaDialog = ref(false);
        let sceneDialog = ref(false);
        let projectEditDialog = ref(false);
        let projectGroupDialog = ref(false);
        let searchScene = ref('')

        let newProject = ref({name: '', author: "", description: "", code: '', groups: Kf.t('Default')});
        let editProject = ref({});
        let projectAll = ref({});
        let createSceneOptions = ref({});
        let scenePopover = ref(false);
        let editScene = ref({});
        let sceneEditDialog = ref(false);
        let inputVisible = ref(false);
        let saveTagInput = ref(null);
        let uploadResourceUrl = ref("");
        let uploadResource = ref(null);
        let dialogKingfisherAbout = ref(false);
        let dialogKFCreateProject = ref(false);
        let dialogKingfisherHotKey = ref(false);
        let dialogContactUs = ref(false);
        let isShowSaveAsDialog = ref(false);
        let uploadTarget = ref(info.develop ? '/rest/designer/p/' : 'p/');
        let sceneFileList = ref([]);
        let projectSearch = ref("");
        let saveAsOption = ref({name: ''})
        let groupValue = ref(Kf.t('ShowAllGroups'));
        if (userModel.user === undefined || userModel.user.userName === undefined) {
            loginUserInfo.value = JSON.parse(localStorage.getItem('KC_USER'));
        } else {
            loginUserInfo.value = userModel.user;
        }

        let showOperation = ref(false);
        let contextMenuStyle = ref({
            top: '',
            left: ''
        });
        let operationMenu = ref(null);
        const options = ref([
            /*{name: "退出登录", code: "01"},*/
        ]);

        if (customization.isEnable(Kf.t('Logout'), "user", "logout")) {
            options.value.push({name: Kf.t('Logout'), code: "01"});
        }
        const tabNameList = ref([Kf.t('PropertySettings'), Kf.t('RouteConfiguration')]);
        let currTabName = ref(Kf.t('PropertySettings'));
        const sceneEditTab = ref([Kf.t('BuildingInformation'), Kf.t('Resources')]);
        let currSceneEditTab = ref(Kf.t('BuildingInformation'));
        let selectedScene = ref({});
        let rightSelectedProject = ref({});
        let tempAreaSet = [];

        const projectRules = ref({
            code: [
                {required: true, message: Kf.t('PleaseEnterTheProjectCode'), trigger: 'blur'}
            ],
            name: [
                {required: true, message: Kf.t('PleaseEnterTheProjectName'), trigger: 'blur'}
            ],
            author: [
                {required: true, message: Kf.t('PleaseEnterTheAuthor'), trigger: 'blur'}
            ]
        });
        const doClickChange = function (val) {
            if (val.code === "01") {
                localStorage.setItem('KC_USER', JSON.stringify({}));
                userModel.updateUser({});
                router.replace({path: '/'});
            }
        }
        const menuList = computed(() => {
            return menuModel.menuList.value;
        });


        const menuOpenMenu = function (item) {
            switch (item.key) {
                case 'openProject':
                    doOpenProject();
                    break;
                case 'openScene':
                    doOpenScene();
                    break;
                case 'openArea':
                    doOpenArea();
                    break;
                case 'saveScene':
                    saveAll();
                    break;
                case 'saveAs':
                    doOpenSaveAs();
                    break;
                case 'openAbout':
                    dialogKingfisherAbout.value = true;
                    break;
                case 'openHotKey':
                    dialogKingfisherHotKey.value = true;
                    break;
                case 'use_docs':
                    // if(oemManager.platformType.value!=='saas'){
                    //     window.open('./doc/index.html', "_blank");
                    // }else{
                    let _url = '/cloud/resources/doc/tronDoc.pdf';
                    if (_url.indexOf('3dc/') === -1) {
                        _url = _url.replace('3dc/', '');
                    }
                    if (_url.indexOf('space/') === -1) {
                        _url = _url.replace('space/', '');
                    }
                    window.open(_url, "_blank");
                    // }
                    break;
                case 'contactUs':
                    dialogContactUs.value = true;
                    break;
                case 'clean':
                    if (!Object.keys(smDatas.objects).length) {
                        return;
                    }
                    ElMessageBox.confirm(
                        Kf.t(`ClearAll`),
                        Kf.t('ClearScene'),
                        {
                            confirmButtonText: Kf.t('ConfirmClear'),
                            cancelButtonText: Kf.t('CancelClear'),
                            type: 'warning',
                        }).then(() => {
                        smDatas.deleteAll();
                    });
                    break;
                case 'exportSpaceData':
                    if (projectModel.currProject.value?.uuid && projectModel.currScene.value?.uuid) {
                        modelService.exportScene(projectModel.currProject.value.uuid, projectModel.currScene.value.uuid).then(res => {
                            if (res?.status === 404) {
                                ElMessage({
                                    type: 'error',
                                    message: res?.data?.message || Kf.t('DataNotAvailable') + Kf.t('Download')
                                });
                            }
                        })
                    }
                    break;
                case 'createSpacePointTable':
                    if (projectModel.currProject.value?.uuid && projectModel.currScene.value?.buildingId) {
                        let p = {
                            projectId: projectModel.currProject.value.uuid,
                            spaceBuildingId: projectModel.currScene.value.buildingId
                        }
                        modelService.downloadDevices(p).then(res => {
                            if (res?.status === 404) {
                                ElMessage({
                                    type: 'error',
                                    message: res?.data?.message || Kf.t('DataNotAvailable') + Kf.t('Download')
                                });
                            }
                        })
                    } else {
                        ElMessage({type: 'warning', message: Kf.t('PleaseSelectTheProjectAndSceneFirst')});
                    }
                    break;
            }
        }

        keyModel.registerHotkeyProcessor("ctrl+s", (event, handler) => {
            event.preventDefault();
            saveAll();
            return false;
        })
        keyModel.registerHotkeyProcessor("ctrl+c", (event, handler) => {
            event.preventDefault();
            copySceneObj()
            return false;
        })
        keyModel.registerHotkeyProcessor("ctrl+v", (event, handler) => {
            event.preventDefault();
            pasteSceneObj()
            return false;
        })


        const currAreaState = ref({});
        const styleList = ref([
            Kf.t('Style1'), Kf.t('Style2'), Kf.t('Style3'), Kf.t('Style4'),
            Kf.t('Style5'), Kf.t('Style6'), Kf.t('Style7'), Kf.t('Style8'),
            Kf.t('Style9'), Kf.t('Style10'), Kf.t('Style11'), Kf.t('Style12'),
            Kf.t('Style13'), Kf.t('Style14'), Kf.t('Style15'), Kf.t('Style16')]);

        const doOpenArea = function () {
            console.log("打开区域设置");
            areaDialog.value = true;
        }

        const doOpenScene = function () {
            console.log("打开场景");
            sceneDialog.value = true;
            loadProjectVersions();
        }

        const doOpenSaveAs = function () {
            if (!projectModel || !projectModel.currScene) {
                return;
            }
            console.log("打开另存为");
            saveAsOption.value.name = projectModel.currScene.value.name + '-' + Kf.t('Copy');
            isShowSaveAsDialog.value = true;
        }

        const closeProjectDialog = function () {
            projectDialog.value = false;
        }
        let currProjectVersion = ref('');
        let projectVersion = ref([])

        const loadProjectVersions = function () {
            projectVersion.value = [];
            if (projectModel.currProject.value && projectModel.currProject.value.uuid) {
                projectService.getSpaceVersion(projectModel.currProject.value.uuid).then(result => {
                    projectVersion.value = result;
                })
            }
        }

        const getBuildingImageUrl = function () {
            let projId = projectModel.currProject.value && projectModel.currProject.value.uuid;
            let sceneId = selectedScene.value && selectedScene.value.nanoId;

            if (projId && sceneId) {
                return `/cloud/api/repo/kf-space/find-file?path=/${projId}/${sceneId}.png`;
            }
            return '';
        }

        const formateDare = function (date) {
            let str = '';
            if (date && date != '') {
                let d = new Date(date);
                let curr_date = d.getDate();
                let curr_month = d.getMonth() + 1;
                let h = d.getHours(); //获取小时
                let m = d.getMinutes(); //获取分钟
                let s = d.getSeconds(); //获取秒
                String(curr_month).length < 2 ? (curr_month = "0" + curr_month) : curr_month;
                String(curr_date).length < 2 ? (curr_date = "0" + curr_date) : curr_date;
                String(h).length < 2 ? (h = "0" + h) : h;
                String(m).length < 2 ? (m = "0" + m) : m;
                String(s).length < 2 ? (s = "0" + s) : s;
                str = d.getFullYear() + "-" + curr_month + "-" + curr_date + " " + h + ":" + m + ":" + s;
            }
            return str;
        }
        const addVersion = function () {
            if (projectModel.currProject.value && projectModel.currProject.value.uuid) {
                projectService.addSpaceVersion(projectModel.currProject.value.uuid).then(result => {
                    if (result === 'success') {
                        ElMessage({type: "success", message: Kf.t('SucessfullyPublishedVersion')});
                    } else {
                        ElMessage({type: "error", message: Kf.t('FailedToPublishVersion')});
                    }
                    loadProjectVersions();
                }).catch(error => {
                    console.log(error);
                    ElMessage({type: "error", message: Kf.t('FailedToPublishVersion')});
                });
            }
        }

        const backVersion = function () {
            if (projectModel.currProject.value && projectModel.currProject.value.uuid && currProjectVersion.value) {
                projectService.backSpaceVersion(projectModel.currProject.value.uuid, currProjectVersion.value).then(result => {
                    if (result === 'success') {
                        ElMessage({
                            type: "success",
                            message: Kf.t('RestoreVersion2') + `${currProjectVersion.value}` + Kf.t('Success')
                        });
                    } else {
                        ElMessage({
                            type: "error",
                            message: Kf.t('RestoreVersion2')`${currProjectVersion.value}` + Kf.t('Failed')
                        });
                    }
                    loadProjectVersions();
                })
            }
        }

        const getVersionLabel = function (item) {
            return item.version + '.' + formateDare(item.lastModifiedDate) + '  ' + item.lastModifiedBy
        }
        const route = useRoute();
        let isSource3dc = false;
        const loadCurrProject=function (){
            ProjectService.getProject(route.params.projectUuid).then((res) => {
                projectModel.selectProject(res).then((scenes) => {
                    for (let scene of scenes.value) {
                        if (route.params.buildingUuId == scene.uuid) {
                            globalNeedSave.value = false;
                            scenePopoverOpen(scene)
                            return;
                        }
                    }
                });

            })
        }
        onMounted(() => {
            if (route?.name === '/main2') {
                isSource3dc = true;
            }

            let _token=getToken()
            if(route.params.ticket===_token && route.params.projectUuid){
                projectDialog.value = false;
                loadCurrProject()
            }else if (route.params.ticket) {
                projectDialog.value = false;
                const svsLogin = (ticket) => {
                    window.Kf = window.Kf || {}
                    window.Kf.onLoginSuccess = (result) => {
                        userModel.updateUser(result);
                        loginUserInfo.value = result.data
                        setToken(result.kfSysMsg);
                        localStorage.setItem('KC_USER', JSON.stringify(result.data));
                        localStorage.setItem('KC_TOKEN', result.kfSysMsg);
                        loadCurrProject()
                    }
                    localStorage.setItem('KC_TOKEN', ticket);
                    let autoLogin = function () {
                        if (window.Kf.login && window.Kf.ssoReady) {
                            clearInterval(timer);
                            window.Kf.login("", "");
                        }
                    }
                    let timer = setInterval(autoLogin, 50);

                }
                svsLogin(route.params.ticket);

            } else {
                projectModel.loadProject();
                keyModel.cleanKeys();
                keyModel.cleanShortcut()
            }
        })

        onUnmounted(() => {
            console.log('clean all data and reset currScene!');
            currData.setCurrList([]);
            undoMap.clean();
            smDatas.clean();
            projectModel.currScene.value = {};
            keyModel.unRegisterHotkeyProcessor("ctrl+s");
            keyModel.unRegisterHotkeyProcessor("ctrl+c");
            keyModel.unRegisterHotkeyProcessor("ctrl+v");
        })

        const doOpenProject = function () {
          let openProject = function () {
            const route = useRoute();
            console.log("打开项目", route, isSource3dc);
            if (isSource3dc) {
              localStorage.setItem('currTabInfo', JSON.stringify({name: Kf.t('Space'), code: 'space'}));
              localStorage.setItem('currUIProject', projectModel.currProject.value.uuid);
              let token = localStorage.getItem("KC_TOKEN");
              window.open(`/cloud/3dc/index.html#/content/${projectModel.currProject.value.uuid}/${token}`, "_self");
            } else if (process.env.VUE_APP_SPACE_PROJECT) {
              localStorage.setItem('currTabInfo', JSON.stringify({name: Kf.t('Space'), code: 'space'}));
              localStorage.setItem('currUIProject', projectModel.currProject.value.uuid);
              router.replace({
                path: '/content',
              });
            } else {
              projectDialog.value = true;
            }
          }
          if (globalNeedSave.value) {
            ElMessageBox.confirm(Kf.t('SceneNotSaved'), Kf.t('SceneNotSaved'), {
              confirmButtonText: Kf.t('Save'),
              cancelButtonText: Kf.t('UnSave'),
              type: 'warning',
            }).then(() => {
              saveAll(openProject);
            }).catch((e) => {
              console.log(e);
              openProject();
            });
          }
          else {
            openProject();
          }
        }

        watch(() => projectModel.projectGroupList.value, (newValue, oldValue) => {
            if (newValue !== oldValue) {
                projectAll.value = JSON.parse(JSON.stringify(projectModel.projectGroupList.value));
            }
        })
        const selectProject = function (project) {
            projectModel.selectProject(project);
            projectDialog.value = false;
            sceneDialog.value = true;
            loadProjectVersions()
        }

        const backToProject = function () {
            sceneDialog.value = false;
            projectDialog.value = true;

        }

        const checkCreateNotNull = function (event, value) {
            if (projectDialog.value) {
                if (value == null || value === '') {
                    event.currentTarget.style.border = '1px solid red';
                    ElMessage({type: "warning", message: Kf.t('CannotBeEmpty')});
                } else {
                    event.currentTarget.style.border = '1px solid #333333';
                }
            }
        }

        const clear = function () {
            newProject.value = {name: '', author: "", description: "", code: '', groups: Kf.t('Default')};
        }


        const doEditProject = function () {
            if (editProject.value && editProject.value.name && editProject.value.name !== '' && editProject.value.author && editProject.value.author !== '') {
                projectService.updateProjects(editProject.value).then(result => {
                    if (result === -1) {
                        ElMessage({type: 'warning', message: Kf.t('NameIsADuplicate')});
                        return;
                    } else {
                        projectEditDialog.value = false;
                        ElMessage({type: 'success', message: Kf.t('ModifiedSuccessfully')});
                    }
                });
            } else {
                ElMessage({type: "warning", message: Kf.t('PleaseEnterRequiredItem')});
            }
        }

        const doCreateProject = function () {
            if (newProject.value && newProject.value.name && newProject.value.name !== '' && newProject.value.author && newProject.value.author !== '' && newProject.value.code && newProject.value.code !== '') {
                projectService.createProject(newProject.value).then(result => {
                    if (result === -1) {
                        ElMessage({type: 'warning', message: Kf.t('NameIsADuplicate')});
                        return;
                    } else {
                        ElMessage({type: 'success', message: Kf.t('CreatedSuccessful')});
                        projectModel.projectList.value.unshift(result);
                    }
                });
            } else {
                ElMessage({type: "warning", message: Kf.t('PleaseEnterRequiredItem')});
            }
        }

        const doRightClick = function (e, project) {
            showOperation.value = true;
            contextMenuStyle.value.left = e.clientX + 'px';
            contextMenuStyle.value.top = e.clientY + 'px';
            rightSelectedProject.value = project;
            editProject.value = JSON.parse(JSON.stringify(rightSelectedProject.value));
            editProject.value.groups = editProject.value.groups === undefined || editProject.value.groups === '' ? Kf.t('Default') : editProject.value.groups;
        }

        window.addEventListener('click', function (e) {
            if (!operationMenu.value || !operationMenu.value.contains(e.target)) {
                showOperation.value = false;
            }
        });

        const handleEditGroup = function () {
            projectGroupDialog.value = true;
            showOperation.value = false;
        }

        const handleProjectEdit = function (tabIndex) {
            projectEditDialog.value = true;
            showOperation.value = false;
            currTabName.value = tabNameList.value[tabIndex];

        }

        const formatDate = function (date) {
            let str = '';
            if (date && date !== '') {
                let d = new Date(date);
                let curr_date = d.getDate();
                let curr_month = d.getMonth() + 1;
                let h = d.getHours(); //获取小时
                let m = d.getMinutes(); //获取分钟
                let s = d.getSeconds(); //获取秒
                String(curr_month).length < 2 ? (curr_month = "0" + curr_month) : curr_month;
                String(curr_date).length < 2 ? (curr_date = "0" + curr_date) : curr_date;
                String(h).length < 2 ? (h = "0" + h) : h;
                String(m).length < 2 ? (m = "0" + m) : m;
                String(s).length < 2 ? (s = "0" + s) : s;
                str = d.getFullYear() + "-" + curr_month + "-" + curr_date + " " + h + ":" + m + ":" + s;
            }
            return str;
        }


        const scenePopoverOpen = function (scene) {
            if (!scene) {
                return;
            }
            if (route?.params?.projectUuid && route?.params?.buildingUuId && route?.params?.ticket && scene.uuid!=route.params.buildingUuId) {
                router.replace({
                    path: '/main/' + route.params.projectUuid + '/' + scene.uuid + '/' + route.params.ticket,
                });
            }
            projectDialog.value = false;
            sceneDialog.value = false;
            if (projectModel.currScene.value && projectModel.currScene.value.uuid === scene.uuid) {
                return;
            }
            let changeScene = () => {
                globalNeedSave.value = false;
                scenePopoverClose();
                projectModel.currScene.value = scene;
                loadBuilding(scene);
                //更新点位分组列表
            }
            if (globalNeedSave.value) {
                ElMessageBox.confirm(
                    Kf.t('SceneNotSavedConfirmSwitchTo') + `"${scene.name}"` + Kf.t('?'),
                    Kf.t('Hint'),
                    {
                        confirmButtonText: Kf.t('ClickOkToConfirm2'),
                        cancelButtonText: Kf.t('Cancel'),
                        type: 'warning',
                    }
                )
                    .then(changeScene)
                    .catch((e) => {
                    })
            } else {
                changeScene();
            }
        }

        const scenePopoverClose = function () {
            scenePopover.value = false;
        }

        const scenePopoverEdit = function (scene) {
            editScene.value = JSON.parse(JSON.stringify(scene));
            sceneEditDialog.value = true;

            editScene.value.resources = editScene.value.resources || [];
            if (editScene.value.tags != null && editScene.value.tags !== "") {
                editScene.value.tagsArray = editScene.value.tags.split("|");
            } else {
                editScene.value.tagsArray = [];
            }
        }
        const scenePopoverDelete = function (scene) {
            scenePopoverClose();
            projectModel.currScene.value = scene;
            projectDialog.value = false;
            ElMessageBox.confirm(
                Kf.t('AreYouSureYouWantToDeleteIt') + `"${scene.name}"` + Kf.t('?'),
                Kf.t('Hint'),
                {
                    confirmButtonText: Kf.t('ClickOkToConfirm2'),
                    cancelButtonText: Kf.t('Cancel'),
                    type: 'warning',
                }
            )
                .then(() => {
                    projectService.deleteBuilding(scene.uuid).then(result => {
                        createSceneDialog.value = false;
                        let index1 = 0;
                        projectModel.sceneList.value.forEach((item, index) => {
                            if (item.uuid == scene.uuid) {
                                index1 = index;
                            }
                        });
                        projectModel.sceneList.value.splice(index1, 1);
                        ElMessage({type: "info", message: Kf.t('Delete') + `${scene.name}` + Kf.t('Success')});
                    });
                })
                .catch((e) => {
                    ElMessage({type: "error", message: Kf.t('Delete') + `${scene.name}` + Kf.t('Failed')});
                })
        }

        const handleScene = function (scene) {
            selectedScene.value = scene
        }

        const addProjectRoute = function () {
            if (editProject.value.routes == undefined) {
                editProject.value.routes = [];
            }
            editProject.value.routes.push({path: "/", scene: ""});
        }

        const groupSelect = function (val) {
            groupValue.value = val;
            let list = JSON.parse(JSON.stringify(projectModel.projectGroupList.value));
            if (val === Kf.t('ShowAllGroups')) {
                projectAll.value = list;
            } else {
                projectAll.value = {};
                if (Object.keys(list).length > 0) {
                    Object.keys(list).forEach(param => {
                        if (param == val) {
                            projectAll.value[param] = list[param]
                        }
                    })
                }
            }
        }

        const doCreateScene = function () {
            createSceneDialog.value = true;
            createSceneOptions.value = {name: '', projectUuid: ''};
            createSceneOptions.value.projectUuid = projectModel.currProject.value.uuid;
        }

        const createScene = function () {
            let name = createSceneOptions.value.name;

            name = name ? name.trim() : '';
            if (!name) {
                ElMessage({type: "warning", message: Kf.t('CannotBeEmpty')});
                return;
            }
            if (projectModel.sceneList.value.find((x) => x.name === name)) {
                ElMessage({type: "warning", message: Kf.t('CannotBeRenamed')});
                return;
            }

            createBuilding({name: name});
            if (createSceneBtnLoading.value) {
                return;
            } else {
                createSceneBtnLoading.value = true;
            }

            // projectService.saveSpace([params]).then(result => {
            //     params.uuid = result[0];
            //     sceneDialog.value = false;
            //     projectModel.sceneList.value.unshift(params);
            //     projectModel.currScene.value = params;
            //     console.log("create new scene: ", params);
            // });
        }

        const doEditScene = function () {
            projectService.updateScenes(editScene.value).then(result => {
                if (result && result.code == '200') {
                    sceneEditDialog.value = false;
                    if (projectModel.sceneList.value.length > 0) {
                        let index1 = 0;
                        projectModel.sceneList.value.forEach((item, index) => {
                            if (item.uuid == editScene.value.uuid) {
                                index1 = index;
                            }
                        });
                        projectModel.sceneList.value[index1] = editScene.value;
                    }
                } else {
                    ElMessage({type: "warning", message: Kf.t('UpdateFailed')});
                }

            });
        }

        const handleCloseTag = function (tag) {
            editScene.value.tagsArray.splice(editScene.value.tagsArray.indexOf(tag), 1);
        }

        const handleInputTagConfirm = function () {
            let inputValue = editScene.value.newTag;
            if (inputValue) {
                editScene.value.tagsArray.push(inputValue);
            }
            inputVisible.value = false;
            editScene.value.newTag = '';
        }

        const showInputTag = function () {
            inputVisible.value = true;
            nextTick(() => {
                saveTagInput.value.focus();
            })
        }
        const importResource = function () {
            nextTick(() => {
                uploadResource.value.submit();
            })
        }

        const doResourceChoseFile = function (file) {
            editScene.value.currResource = file.name;
        }

        const doImportResourceSuccess = function (response) {
            ElMessage({type: "success", message: Kf.t('UploadedSuccessfully')});
            editScene.value.resources.push({name: response.name, path: response.path, uuid: response.uuid});
            sceneFileList.value = [];
        }

        const doImportResourceError = function (err) {
            console.error(err);
            ElMessage({type: "warning", message: Kf.t('UploadFailed')});
            sceneFileList.value = [];
        }

        const deleteResource = function (resource) {
            ElMessageBox.confirm(
                Kf.t('AreYouSure'),
                Kf.t('Hint'),
                {
                    confirmButtonText: Kf.t('ClickOkToConfirm2'),
                    cancelButtonText: Kf.t('Cancel'),
                    type: 'warning',
                })
                .then(() => {
                    const index = editScene.value.resources.indexOf(resource);
                    if (index > -1) {
                        editScene.value.resources.splice(index, 1);
                        //接口删除
                    }
                })
                .catch(() => {

                })

        }

        const change = function (val) {
            dialogKingfisherAbout.value = val;
        }

        const getProjects = function (group) {
            return projectAll.value[group].filter(comp => {
                return !projectSearch.value || tools.pinyinMatch(comp.name, projectSearch.value)
                //return comp.name.indexOf(projectSearch.value) !== -1;
            })
        }

        let showCreateProjectDialog = function () {
            dialogKFCreateProject.value = true;
        }

        let changeProjectDialog = function (val) {
            if (val.type === 'submitOK') {
                selectProject(val.value);
            }
            dialogKFCreateProject.value = false;
        }

        const deleteArea = function () {
            if (currAreaState.value && currAreaState.value.label !== undefined) {
                ElMessageBox.confirm(
                    Kf.t('AreYouSureYouWantToDeleteThisData'),
                    Kf.t('Hint'),
                    {
                        confirmButtonText: Kf.t('Delete'),
                        cancelButtonText: Kf.t('Cancel'),
                        type: 'warning',
                    })
                    .then(() => {
                        let temp = toRaw(currAreaState.value.value);
                        let index = currAreaState.value.purpose.states.indexOf(temp);
                        if (index >= 0) {
                            currAreaState.value.purpose.states.splice(currAreaState.value.purpose.states.indexOf(temp), 1);
                            if (currAreaState.value.purpose.states.length === 0) {
                                let index1 = areaOptionsData.value.indexOf(currAreaState.value.purpose);
                                if (index1 >= 0) {
                                    areaOptionsData.value.splice(index1, 1);
                                }
                            }
                            currAreaState.value = {}
                        }
                    })
                    .catch(() => {
                    })
            }
        }

        const areaStatesList = computed(() => {
            let res = [];
            let i = 0;
            for (let a of areaOptionsData.value) {
                i++;
                let first = true;
                for (let s of a.states) {
                    let label = first ? (i + '. ' + a.name) : '';
                    let type = first ? (areaSubTypeMap[a.type] || a.type) : '';
                    res.push({label: label, type: type, purpose: a, value: s});
                    first = false;
                }
            }
            return res;
        });

        const addArea = function () {
            let newData = {
                name: Kf.t('FunctionalArea') + (areaOptionsData.value.length + 1),
                type: '',
                states: [{
                    name: Kf.t('Default'),
                    style: styleList.value[areaOptionsData.value.length % styleList.value.length],
                    color: '#7F7F7FFF'
                }]
            };
            tempAreaSet.push({type: 'Area', obj: newData, container: areaOptionsData.value})

            areaOptionsData.value.push(newData);
            setTimeout(() => {
                currAreaState.value = areaStatesList.value.find((x) => toRaw(x.purpose) === newData);
            }, 1);
        }

        const addAreaStates = function () {
            if (currAreaState.value && currAreaState.value.purpose) {
                let newData = {
                    'name': Kf.t('Status') + (currAreaState.value.purpose.states.length + 1),
                    'style': currAreaState.value.value.style,
                    'color': currAreaState.value.value.color
                };
                currAreaState.value.purpose.states.push(newData);
                tempAreaSet.push({type: 'AreaState', obj: newData, container: currAreaState.value.purpose.states})
                setTimeout(() => {
                    currAreaState.value = areaStatesList.value.find((x) => toRaw(x.value) === newData);
                }, 1);
            } else {
                ElMessage({type: "warning", message: Kf.t('PleaseSelectDataEntry')});
            }
        }

        const cloneAreaProps = function () {
            if (currAreaState.value) {
                let newData = JSON.parse(JSON.stringify(currAreaState.value.purpose));

                newData.name += '-' + Kf.t('Clone');
                let index = areaOptionsData.value.indexOf(currAreaState.value.purpose);
                areaOptionsData.value.splice(index + 1, 0, newData);
                setTimeout(() => {
                    currAreaState.value = areaStatesList.value.find((x) => toRaw(x.purpose) === newData);
                }, 1);
            }
        }

        const handleCurrData = function (currentRow) {
            if (currentRow) {
                currAreaState.value = currentRow;
            }
        }

        const doSaveAreaStatesData = function () {
            tempAreaSet = [];
            areaDialog.value = false;

            ProjectService.saveAreaPurpose({
                projectId: projectModel.currProject.value.uuid,
                data: areaOptionsData.value
            });

            for (let area of smDatas.areas) {
                smCollection.update(area);
            }
        }
        const rebuildSceneName = function (event) {
            let text = event.currentTarget.innerText.trim();
            if (text !== projectModel.currScene.value.name) {
                smDatas.building.name = text;
            }
            isOpenCurrSceneNameEdit.value = false;
        }
        let isOpenCurrSceneNameEdit = ref(false);

        let openCurrSceneNameEdit = function (event) {
            isOpenCurrSceneNameEdit.value = true;
            let element = event.currentTarget;
            let text = element.innerText.trim();

            element.selectionStart = 0; // 设置光标开始的位置
            element.selectionEnd = text.length; // 设置光标结束的位置
            if (element.setSelectionRange) {
                element.focus();
                element.setSelectionRange(0, text.length);
            } else if (element.createTextRange) {
                var range = element.createTextRange();
                range.collapse(true);
                range.moveEnd('character', text.length);
                range.moveStart('character', 0);
                range.select();
            } else if (window.getSelection) {
                var selection = window.getSelection();
                var range = document.createRange();
                range.selectNodeContents(event.currentTarget);
                selection.removeAllRanges();
                selection.addRange(range);
            }
            setTimeout(() => {
                element.focus();
                //element.click();
            })

            /*
                  if (document.body.createTextRange) {
                    var range = document.body.createTextRange();
                    range.moveToElementText(event.currentTarget);
                    range.select();
                  } else if (window.getSelection) {
                    var selection = window.getSelection();
                    var range = document.createRange();
                    range.selectNodeContents(event.currentTarget);
                    selection.removeAllRanges();
                    selection.addRange(range);

                  }*/
        }

        const doSaveAs = function () {
            let list = projectModel.sceneList.value;
            if (list.find((x) => x.name === saveAsOption.value.name)) {
                ElMessage({type: "warning", message: Kf.t('ABuildingWithTheSameNameAlreadyExists')});
                return;
            }
            saveAs(saveAsOption.value, (newScene) => {
                if (newScene) {
                    console.log(Kf.t('SaveSuccessfiul'));
                    ElMessage({type: "success", message: Kf.t('SaveSuccessfiul')});
                    isShowSaveAsDialog.value = false;
                    loadBuilding(newScene);
                }
            });
        }
        const changeHotKey = function (val) {
            dialogKingfisherHotKey.value = val;
        }

        const changeContactUs = function (val) {
            dialogContactUs.value = val;
        }
        const imageError = function (event) {
            event.target.src = error4buildThumbnail;
        }
        const closeAreaDialog = function (event) {
            areaDialog.value = false;
            each(tempAreaSet, item => {
                item.container.removeByVal(item.obj)
            })
            tempAreaSet = [];
        }
        let logo = ref('')
        // oemManager.dependPlatformType(function () {
        //     let _name = 'space'
        //     let name = oemManager.platformType.value === 'saas' ? ('saas' + _name) : _name;
        //    logo.value = oemManager.getImage(name, "logo", "space");
        // });
        //const logo = oemManager.getImage("space", "logo", "space");


        const sceneList = computed(() => {
            return projectModel.sceneList.value.filter(comp => {
                return tools.pinyinMatch(comp.name, searchScene.value);
            })

        })


        const handleSubMenu=function (menu, parentMenu){
          if (!scene3d || (menu.code !== '2-8-1' && menu.code !== '2-8-2' && menu.code !== '2-8-3')) {
            return;
          }

          let shouldExport = (node) =>  {
            if (node.hideInDesigner || node.doNotSerialize || node.enableState <= 0) {
              return false;
            }
            if (!['Mesh', 'KPartLoader'].includes(node.getClassName())){
              return false;
            }

            return true;
          }

          let name = projectModel.currScene.value.name || 'scene';
          if (menu.type === 'gltf') {
            Kingfisher.GLTF2Export.GLTFAsync(scene3d, name, {
              shouldExportNode: (node) => shouldExport(node)
            }).then((glb) => {
              glb.downloadFiles();
            }).catch(reason => {
            });
          }
          else if (menu.type === 'glb') {
            Kingfisher.GLTF2Export.GLBAsync(scene3d, name, {
              shouldExportNode: (node) => shouldExport(node)
            }).then((glb) => {
              glb.downloadFiles();
            }).catch(reason => {
            });
          }
          else if (menu.type === 'obj') {
            let meshes = scene3d.meshes.filter(shouldExport);
            let obj = Kingfisher.OBJExport.OBJ(meshes);
            let link = document.createElement('a');
            document.body.appendChild(link);
            link.setAttribute("type", "hidden");
            link.download = name + ".obj";
            const blob = new Blob([obj], {
              type: 'model/obj'
            });

            let mimeType = { type: "model/obj" };

            link.href = window.URL.createObjectURL(new Blob([blob], mimeType));
            link.click();
          }
        }
        return {
            logo,
            loginUserInfo,
            options,
            doClickChange,
            menuList,
            menuOpenMenu,
            projectDialog,
            closeProjectDialog,
            getBuildingImageUrl,
            newProject,
            projectList: projectModel.projectGroupList,
            currProject: projectModel.currProject,
            selectProject,
            sceneDialog,
            currScene: projectModel.currScene,
            backToProject,
            sceneList,
            projectRules,
            checkCreateNotNull,
            clear,
            doCreateProject,
            showOperation,
            doRightClick,
            contextMenuStyle,
            operationMenu,
            projectEditDialog,
            rightSelectedProject,
            handleProjectEdit,
            formatDate,
            scenePopoverOpen,
            scenePopoverEdit,
            scenePopoverDelete,
            handleScene,
            tabNameList,
            currTabName,
            doEditProject,
            editProject,
            addProjectRoute,
            projectGroupDialog,
            handleEditGroup,
            groupValue,
            groupSelect,
            createSceneDialog,
            doCreateScene,
            createSceneOptions,
            createScene,
            scenePopover,
            sceneEditDialog,
            sceneEditTab,
            currSceneEditTab,
            editScene,
            doEditScene,
            handleCloseTag,
            inputVisible,
            handleInputTagConfirm,
            saveTagInput,
            showInputTag,
            uploadResourceUrl,
            importResource,
            sceneFileList,
            doResourceChoseFile,
            doImportResourceSuccess,
            doImportResourceError,
            deleteResource,
            uploadResource,
            projectAll,
            change,
            dialogKingfisherAbout,
            projectSearch,
            getProjects,
            currProjectVersion,
            projectVersion,
            addVersion,
            getVersionLabel,
            backVersion,
            showCreateProjectDialog,
            dialogKFCreateProject,
            changeProjectDialog,
            areaDialog,
            addArea,
            deleteArea,
            areaStatesList,
            currAreaState,
            handleCurrData,
            styleList,
            areaSubTypeMap,
            areaSubTypeOptions,
            addAreaStates,
            cloneAreaProps,
            doSaveAreaStatesData,
            rebuildSceneName,
            openCurrSceneNameEdit,
            isOpenCurrSceneNameEdit,
            isShowSaveAsDialog,
            saveAsOption,
            doSaveAs,
            createSceneBtnLoading,
            dialogKingfisherHotKey,
            dialogContactUs,
            changeHotKey,
            changeContactUs,
            imageError,
            closeAreaDialog,
            customization,
            searchScene,
            handleSubMenu
        }
    }
}
</script>
<style>
.cell {
    height: auto;
}

.kf-table-cells {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    background: transparent;
}

.kf-table-cells-active {
    background: rgba(37, 83, 120, 0.5);
}

.kf-table-cell-color {
    width: 22px;
    display: flex;
    align-items: center;
    height: 12px;
    border-radius: 2px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.self_building_input:focus-visible, .self_building_input:focus {
    border-radius: 3px;
    border: 1px solid #eeeeee4d;
    outline: none;
}

.kf-3dc-dialog-scene-list-header {
    .el-input.kf-3dc-dialog-scene-list-header-input1 {
        margin-left: 10px;
        width: 250px;
        height: 28px;

        .el-input__inner {
            width: 100%;
            height: 100%;
            background: transparent;
            font-size: 12px;
            color: #949494;
            border: 1px solid #000;
        }
    }
}
</style>



