<template>
    <div class="w-100 h-100">
    <input v-if="isShow && type ===`text`" type="text" class="kingfisher-standard-input w100" :value="value?value:placeholder"
           @change="doChange"
           :style="{color: isReadOnly ? '#606266' : '#E4E4E4'}"
           :readonly="!isReadOnly ? false : 'readonly'"
           style="width: 100%">
    <input v-show="isShow && type ==='number' && modelType"
           class="kingfisher_prop_con prop_cons w100-border test1 tool-left-menu-item-input-input"
           type="text"
           :value="value"
           @change="doChange"
           @focus="getInputFocus"
           @blur="lostFocus"
           @keyup.enter.native="getInputEnter($event)"
           ref="ip"
           :readonly="!isReadOnly ? false : 'readonly'"
    >
    <div v-show="isShow && type ==='number'&& !modelType" class="el-input input_box w100 test2"
         @mousedown="mouseDownEvent"
         @dblclick="inputEdit">
        <div class="num_input_move_con kingfisher_prop_con tool-left-menu-item-input-input"
             :style="{color: isReadOnly ? 'rgb(96, 98, 102)' : '#E4E4E4'}">
          <span class="num_input_move_cons"
                :style="(isReadOnly ? '' : 'cursor: e-resize;') + 'border: none;color:inherit'"
                >{{value}}</span>
        </div>
    </div>

    <el-checkbox
            v-if="isShow && type ==='boolean'"
            v-model="value"
            :indeterminate="indeterminate"
            @change="doChange($event)"
            :label="label"
            :title="label"/>

    <el-select
            v-if="isShow && type ==='select'"
            v-model="value"
            class="m-2"
            :placeholder="placeholder"
            size="small"
            @change="doChange($event)">
        <el-option
                v-for="item in options"
                :key="item.value?item.value:item.name"
                :label="item.label?item.label:item.name"
                :value="item.value?item.value:item.name"
        />
    </el-select>
    </div>

</template>

<script>
import {computed, ref, watch} from 'vue';
import {each, eachTimes, isObject} from "@/utils/tools";

let _set = new Set();
let updateValue = function (props, updateUIValue, value, _targets, options) {
    let {indeterminate} = options;
    let isShow = options.isShow;
    if (!props.targets || props.targets.length === 0) {
        isShow.value = true;
        value.value= props.inputType==='boolean'?false:props.inputType==='number'?0:''
        return
    }
    isShow.value = true;
    if (updateUIValue) {
        return
    }

    _targets.length = 0;
    _set.clear();
    each(props.targets, function (target) {
        if (props.inputType === 'select' && isObject(target)) {
            _set.add(JSON.stringify(target))
            _targets.push(target)
        } else {
            _set.add(target)
            _targets.push(target);
        }
    })
    if (_set.size > 1) {
        value.value = '??';
        if (props.inputType === 'boolean') {
            value.value = true;
            indeterminate.value = true;
        }
    } else {
        let val = Array.from(_set)[0];
        if (props.inputType === 'select' && isObject(props.targets[0])) {
            val = props.targets[0].value
        } else if (props.inputType === 'number') {
            val = ((val || 0) * props.unitScale).toFixed(props.precision);
        } else if (props.inputType === 'boolean') {
            indeterminate.value = false;
        }
        value.value = val;
    }
}


export default {
    name: "extendInput",
    props: {
        targets: {
            type: Array,
            required: true,
            default: []
        },
        options: {
            type: Array,
            required: false,
        },
        label: {
            type: String,
            required: false,
            default: ''
        },
        placeholder: {
            type: String,
            required: false,
            default: ''
        },
        inputType: {
            type: String,
            required: false,
            default: 'text'
        },
        change: {
            type: Function,
            default: () => {
            }
        },
        step: {
            type: Number,
            required: false,
            default: 1
        },
        max: {
            type: Number,
            required: false,
            default: 100000000
        },
        min: {
            type: Number,
            required: false,
            default: -100000000
        },
        precision: {
            type: Number,
            required: false,
            default: 1
        },
        unitScale: {
            type: Number,
            required: false,
            default: 0.1
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    emits: ['change'],

    setup(props, {emit}) {
        //由于内部和外部会同时修改targets数据 外部会修改targets指针 内部会修改targets属性,利用指针是否被修改来判断targets改变的来源
        let updateUIValue = false;
        // 记录原始的数值
        let _targets = [];
        let ip = ref(null);
        let inputMode = 'mouse';//keyboard|mouse

        let keying = ref(false);
        let value = ref();
        let indeterminate = ref(false);
        let isShow = ref(true);

        watch(() => props.targets, () => {
            updateValue(props, updateUIValue, value, _targets, {indeterminate, isShow});
        })
        let modelType = computed(() => {
            return keying.value;
        });

        let isReadOnly = computed(() => {
            return props.readonly || !props.targets.length;
        });
        let doChange = function (event) {
            if (isReadOnly.value) {
                return;
            }
            let val = event;
            if (event instanceof Event) {
                inputMode = 'keyboard';
            } else {
                inputMode = 'mouse';
            }
            let data = [];
            let keyValue;
            if (inputMode === 'keyboard') {
                if (props.inputType === 'number') {
                    keyValue = parseFloat(event.target.value.trim()) / props.unitScale;
                } else {
                    keyValue = event.target.value.trim();
                }
            }
            if (props.inputType === 'select' && isObject(props.targets[0])) {
                each(props.options, option => {
                    if (option.value === val) {
                        val = option;
                        return -1;
                    }
                })
            }
            eachTimes(_targets.length, (i) => {
                if (inputMode === 'keyboard') {
                    data.push(keyValue)
                } else if (props.inputType === 'select' || props.inputType === 'boolean') {
                    data.push(val)
                } else {
                    let temp = _targets[i] + val;
                    if (temp > props.max) {
                      temp = props.max;
                    }
                    if (temp < props.min) {
                      temp = props.min;
                    }
                    data.push(temp)
                }
            })
            if (props.inputType === 'number') {
                if (_targets.length > 1 && _set.size >1) {
                    value.value = (val * 0.1).toFixed(1);
                } else{
                    value.value = (data[0] * props.unitScale).toFixed(props.precision);
                }
            }
            emit('change', data)
        }
        let lostFocus = function () {
            keying.value = false;
        }
        let getInputFocus = function (e) {
            e.currentTarget.select();
        }
        let getInputEnter = function (event) {
            event.currentTarget.blur();
        }
        let mouseDownEvent = function (event) {
            if (isReadOnly.value) {
              return;
            }

            // console.log('mouseDownEvent', event);
            event.stopPropagation();
            updateUIValue = true;
            let firstEvent = true;
            let errCount = 0;
            let ml = 0;

            document.onmousemove = (e) => {
                // console.log('onmousemove', e.movementX, e);
                if (firstEvent) {
                  firstEvent = false;
                  document.activeElement.requestPointerLock();
                  return;
                }
                if (e.movementX === 0) {
                  return;
                }
                if (Math.abs(e.movementX) > 300) {
                  if (errCount++ > 3) {
                    document.exitPointerLock();
                  }
                  return;
                }
                ml += e.movementX;
                const _alt = e.altKey;
                const _ctrl = e.ctrlKey;
                let _size = _alt ? props.step * 10 : (_ctrl ? props.step * 5 : props.step);
                let stepStr = props.step.toString();
                const _precision = stepStr.indexOf('.') === -1 ? 0 : stepStr.length - stepStr.indexOf('.') - 1
                const _step = parseFloat(((ml) / 10 * _size).toFixed(_precision));
                let val = _step.toFixed(props.precision);
                doChange(parseFloat(val));
            };

            document.onmouseup = (e) => {
                // console.log('onmouseup', e);
                document.exitPointerLock();
                updateUIValue = false;
                if (e.button === 2) {
                    emit('change', _targets)
                } else {
                    updateValue(props, updateUIValue, value, _targets, {indeterminate, isShow});
                }
                document.onmousemove = null;
                document.onmouseup = null;
            };
        }
        let inputEdit = function (event) {
            if (isReadOnly.value) {
              return;
            }

            // console.log('inputEdit', event);
            if (!window.event.altKey && !window.event.ctrlKey) {
                event.stopPropagation();
                keying.value = true;
                ip.value.focus();
            }
        }
        updateValue(props, updateUIValue, value, _targets, {indeterminate, isShow});
        return {
            isShow,
            value,
            isReadOnly,
            type: props.inputType,
            label: props.label,
            placeholder: props.placeholder,
            options: props.options,
            doChange,
            lostFocus,
            getInputFocus,
            getInputEnter,
            modelType,
            mouseDownEvent,
            inputEdit,
            ip,
            indeterminate,
        }
    }
}
</script>

<style scoped>

</style>
