import { currData } from "@/model/globalData";
import extendInput from '@/components/propWidget/extendInput';
import { getItemProperty, isMultiple, updateItemProperty } from "@/components/mainContent/rightPanel/property/index";
export default {
  name: "floor",
  components: {
    extendInput
  },
  setup() {
    let doChange = function (event, name) {
      currData.floor[name] = Math.round(parseFloat(event) * 1000);
      currData.floor.makeDirty();
      currData.floor.setNeedSave();
    };
    return {
      currData,
      doChange,
      isMultiple,
      getItemProperty,
      updateItemProperty
    };
  }
};