import _defineProperty from "F:/workspace/\u7A7A\u95F4\u6CBB\u7406\u5DE5\u5177/\u7A7A\u95F4\u6CBB\u7406CAD/space-management/spaceManager/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { registerSMType, serializeObject, updateProperties, SMObject } from "./smObject";
import { SMDirtyAll } from "./smDirtyFlag";
import { Vector3 } from "../maths/vector";
import { smDatas } from "../model/smCollectionData";
class SMFloor extends SMObject {
  constructor(collection, options) {
    super(collection, options);
    _defineProperty(this, "objects", []);
    _defineProperty(this, "height", 2800);
    _defineProperty(this, "position", new Vector3(0, 0, 0));
    this.type = 'floor';
  }
  set floor(value) {}
  get floor() {
    return this;
  }

  // set visible(value) {
  //     super.visible = value;
  //     let objects = smDatas.objects;
  //     for (let obj of this.objects) {
  //         objects[obj].floorVisible = value;
  //     }
  // }
  //
  // get visible() {
  //     return this._visible;
  // }

  set elevation(value) {
    if (this.position.y === value) {
      return;
    }
    this.position.y = value;
    this.makeDirty();
  }
  get elevation() {
    return this.position.y;
  }
  get finalVisible() {
    return this._visible;
  }
  makeDirty(flag = SMDirtyAll) {
    if (this.dirtyCallback) {
      this.dirtyCallback(this, flag);
    }
    for (let i = 0; i < this.objects.length; i++) {
      smDatas.objects[this.objects[i]].makeDirty(flag, false);
    }
  }
  serialize() {
    let res = super.serialize();
    if (!res.properties) {
      res.properties = {};
    }
    serializeObject(this, ['position', 'height'], res.properties);
    return res;
  }
  updateProperties(data) {
    let changed = super.updateProperties(data);
    if (data && data.properties) {
      changed |= updateProperties(this, ['position', 'height'], data.properties);
    }
    if (changed) {
      this.makeDirty();
    }
    return changed;
  }
}
export { SMFloor };