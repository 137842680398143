import { ref, watch, computed, onMounted } from 'vue';
import modelData, { compGroupList, selectedThemeId, themeList, selectedType, currThemeWidgets } from "@/model/3Dmodel";
import { each } from "@/utils/tools";
import { toolBarState } from "@/model/globalData";
import { globalState, globalStateProxy, currData } from "@/model/globalData";
import modelOptions from "@/model/modelOptions";
export default {
  name: "kfCollapseItem",
  props: {
    title: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    },
    props: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  setup(props, {
    emit
  }) {
    let locale = ref(window.Kf.locale.value.replace("-", ""));
    let activeName = ref('0');
    onMounted(() => {
      activeName.value = '0' + props.props.label + 'item';
    });
    // const findChildren = function (item) {
    //   let res = [];
    //   if (item.children && item.children.length > 0) {
    //     item.children.forEach((child) => {
    //       if(child.data){
    //         res = res.concat(child.data);
    //       }
    //       res = res.concat(findChildren(child));
    //     });
    //   }
    //   return res;
    // }
    let data = computed(() => {
      return props.props.data && props.props.data.length > 0 ? props.props.data : [];
    });
    /**
     * 处理img图片地址
     * */
    let getImageUrl = function (item) {
      // return Kingfisher.FileTools.GetAbsoluteUrl("k3dc://parts/" + item.k3dcId + '.jpg');
      return Kingfisher.FileTools.GetAbsoluteUrl("k3dc://parts/" + (item.thumbnail || item.screenshotPath || item.k3dcId + '.jpg'));
    };
    let defaultSelectedKey = ref([0]);
    let defaultProps = ref({
      children: 'children',
      label: 'label',
      class: 'kf-market-customer-box'
    });
    let currSelectedCategory = ref({});
    let getLabel = function (item) {
      let _name = item.name;
      if (item.labelInterpret) {
        if (typeof item.labelInterpret === 'string') {
          item.labelInterpret = JSON.parse(item.labelInterpret);
        }
        if (item.labelInterpret[locale.value]) {
          _name = item.labelInterpret[locale.value];
        }
      }
      return _name;
    };
    /**
     * 处理dev图片背景地址
     * */
    let getImageUrlCss = function (item) {
      return `  background-image: url('${getImageUrl(item)}');`;
    };
    let showDetail = ref(false);
    let detailStyle = ref({
      right: '396px',
      top: '100px'
    });
    let currWidget = ref({});
    const widgetMouseover = function (e, item) {
      if (globalState.dragData) {
        return;
      }
      let height = document.documentElement.clientHeight || document.body.clientHeight;
      let top = 100;
      if (e.clientY) {
        top = e.clientY;
        if (e.clientY + 230 > height) {
          top = height - 230;
        }
      }
      detailStyle.value.top = top + 'px';
      showDetail.value = true;
      currWidget.value = item;
    };
    const widgetMouseout = function (e, item) {
      showDetail.value = false;
    };
    const widgetClick = function (item) {
      globalState.selectedData = item;
    };
    let showDragImg = ref(false);
    let dragImgStyle = ref('');
    const widgetMousedown = function (e, item) {
      globalState.dragData = item;
      let documentMousemove = function (event) {
        showDragImg.value = true;
        if (showDragImg.value) {
          dragImgStyle.value = `z-index:99999;top:${event.clientY - 21}px;left:${event.clientX - 21}px;z-index:100`;
        }
      };
      let documentMouseup = function (event) {
        showDragImg.value = false;
        dragImgStyle.value = '';
        // currData.mode2 = '';
        globalState.dragData = null;
        document.removeEventListener('mousemove', documentMousemove);
        document.removeEventListener('mouseup', documentMouseup);
      };
      document.addEventListener('mousemove', documentMousemove);
      document.addEventListener('mouseup', documentMouseup);
    };
    return {
      globalState,
      activeName,
      showDetail,
      detailStyle,
      currWidget,
      showDragImg,
      getImageUrl,
      getLabel,
      compGroupList,
      getImageUrlCss,
      widgetMouseover,
      widgetMouseout,
      widgetMousedown,
      widgetClick,
      dragImgStyle,
      themeList,
      selectedThemeId,
      selectedType,
      data,
      defaultSelectedKey,
      defaultProps,
      currSelectedCategory
    };
  }
};