<template>
    <div class="kf_container"
         @mousedown.capture="dispatchGlobalMouseEvent('global.mouse',$event)"
         @mouseleave="dispatchGlobalMouseEvent('global.mouse',$event)"
         @mouseup.capture="dispatchGlobalMouseEvent('global.mouse',$event)"
    >
        <div class="kf-header">
            <kf-header></kf-header>
        </div>
        <div class="kf-tool">
            <kf-tool-bar></kf-tool-bar>
        </div>
        <div class="kf-body mt2">

            <kf-content></kf-content>
        </div>
        <!--        <div class="kf-footer mt2">-->
        <!--            <kf-footer></kf-footer>-->
        <!--        </div>-->
    </div>
</template>

<script>
import kfHeader from './header/header'
import kfToolBar from './header/toolBar.vue'

import kfContent from './mainContent/mainContent'
import keyModel from "@/model/keys";
import ModelService from "../service/model";
import ProjectService from "../service/project";
import {watch, onMounted} from 'vue'
import {ElMessage} from 'element-plus'
import {
    commCompsLib,
    compGroupList,
    compInited,
    compMapById,
    compMapByName, currThemeWidgets,
    selectedThemeId,
    themeList
} from "@/model/3Dmodel";
import {each} from "@/utils/tools";
import {dispatchGlobalMouseEvent} from "@/event/globalDom";
import {getTypeName} from "@/model/modelOptions";
import {smDatas} from "@/model/smCollectionData";
import oemManager from "@/utils/oem";
import contentModel from "../model/newContent";
import spaceService from "../service/space";

export default {
    name: "kf-main",
    components: {
        kfHeader,
        kfToolBar,
        kfContent
    },
    setup() {
        let resComponentLibs;
        let resThemeList
        const loadThemeData=function (){
            if(selectedThemeId.value){
                let getKFPartByTheme = async () => {
                    let themeId = selectedThemeId.value;
                    compInited.value = false;
                    let systemFlag=1;
                    systemFlag =resThemeList.find(x => x.uuid === themeId)?.systemFlag||1;
                   /* let res = await ModelService.getKFPartByTheme({
                        projectId: commCompsLib.value.uuid,
                        themeId: selectedThemeId.value
                    },systemFlag) || [];
                    if (selectedThemeId.value !== themeId) {
                        return;
                    }*/
                    let res=await  spaceService.getSpacePart(commCompsLib.value.uuid) || [];
                    currThemeWidgets.value = res;
                    let list = {};
                    for (let model of res) {
                        if (model.subType === '_device') {
                            model.subType = '';
                        }
                        let name = getTypeName(model.subType);
                        if (typeof model.properties === 'string') {
                            model.properties = model.properties ? JSON.parse(model.properties) : {};
                        }
                        if (!list[name]) list[name] = {name, models: []};
                        list[name].models.push(model)
                    }

                    for (let key in list) {
                        let l = list[key];
                        l.name += ' (' + l.models.length + ')';
                    }
                    compGroupList.value = list;
                    compMapById.value = new Map(res.map(model => [model.k3dcId, model]));
                    compMapByName.value = new Map(res.map(model => [model.name, model]));
                    compMapByName.value.forEach((item,index)=>{
                        if(!item.category || item.category==='null' || item.category===null){
                            item.category='默认';
                        }
                    })
                    compInited.value = true;

                    let checkModelCount = 0;
                    let checkModelTypeSet = new Set();
                    for (let model of smDatas.models) {
                        if (!compMapByName.value.has(model.modelName)) {
                            checkModelTypeSet.add(model.modelName);
                            checkModelCount++;
                        }
                    }

                    if (checkModelCount) {
                        console.error('lost model match : ' + checkModelCount, checkModelTypeSet);
                        ElMessage({
                            type: "error",group:true,
                            message: checkModelCount + ' ' + Kf.t('Models') + ', ' + checkModelTypeSet.size + ' ' + Kf.t('Types') + Kf.t('CannotBeMatched') + '!'
                        });
                    }
                }
                getKFPartByTheme();
                console.log(selectedThemeId.value,'selectedThemeId.value')
            }
        }

        watch(() => selectedThemeId.value, (newVal) => {
            if (newVal) {
                loadThemeData()
            }
        });

        window.Kf = window.Kf || {}

        onMounted(() => {
            return new Promise(async (resolve, reject) => {
                 resComponentLibs = await ModelService.getComponentLibs();
                if (resComponentLibs) {
                    commCompsLib.value = resComponentLibs;
                    window.Kingfisher.FileTools.RegisterSchemes({'k3dc://': ProjectService.getUrl(`/cloud/api/repo/kf-space/find-file?path=${resComponentLibs.uuid}`)});
                }
                 resThemeList = await ModelService.getThemeList({projectId: commCompsLib.value.uuid});
                if (resThemeList) {
                    resThemeList = resThemeList.filter(x => x.type === 'space');
                    themeList.value = resThemeList;
                    let _themeId =resThemeList.find(x => x.uuid === selectedThemeId.value);
                    if (!_themeId) {
                        selectedThemeId.value = (themeList.value[0] || {uuid: null}).uuid;
                    }
                }
               loadThemeData()
                window.Kf.onLicenseCheckFail = function (response) {
                    if (response?.msg === 'Session 验证失败, 超时') {
                        ElMessage({type: "warning", message: "您因为太久没有操作, 已经登出系统"});
                    } else if (response?.msg === 'Session 验证失败, 同账号他人登录') {
                        ElMessage({
                            type: "warning",group:true,
                            message: "您已经登出系统, 可能是因为有其他人登录了您的账户, 或者是您在其他浏览器窗口登录了系统"
                        });
                    } else {
                        ElMessage({type: "warning", message: "您已经登出系统, 请重新登录",group:true});
                    }
                }

                keyModel.init();
            });
        });

        return {dispatchGlobalMouseEvent}
    }
}
</script>


