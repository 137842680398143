import {currData} from "../model/globalData";
import {smDatas, smMesh3DCache} from "@/model/smCollectionData";
import {areaOptionsData} from "@/model/purposeOptions";
import {buildAreaMesh} from "@/builder/smAreaBuildTools";

class Area3DBuilder {
    update(data) {
        let area = data.obj;
        if (!data.inited) {
            if (!area.finalVisible) {
                return false;
            }
            data.inited = true;

            //area init
            let areaMesh = new Kingfisher.Mesh(area.name, data.scene, null, null, false, true, {
                id: area.nanoId
            });
            areaMesh.receiveShadows = true;
            areaMesh.castShadows = false;
            areaMesh.scalingDeterminant = 0.001;
            // areaMesh.renderingGroupId = 1;

            let areaGeometry = new Kingfisher.Geometry('geo' + area.nanoId, data.scene, undefined, true, areaMesh);

            // let areaMaterial = new Kingfisher.KMaterial('area' + area.nanoId, data.scene);
            // let wChannel = new Kingfisher.KMatChannel(areaMaterial, {name: '材质1'});
            // let wLight = new Kingfisher.KMatStageLight(areaMaterial, wChannel);
            // wLight.colorBase.copyFromFloats(0.08, 0.13, 0.15);
            // wLight.colorSpecular.copyFromFloats(0.1, 0.1, 0.15);
            // //wLight.opacity = 0.6;
            //
            // //areaMaterial.transparencyMode = 3;
            // areaMaterial.material = areaMaterial;
            // areaMaterial.backFaceCulling = false;
            // areaMaterial.zOffset = 5;

            data.areaMesh = areaMesh;
            data.areaGeometry = areaGeometry;

            data.highlightMeshes = [areaMesh];
            data.pickMeshes = [areaMesh];
            data.focusObject = areaMesh;

            data.textPoint = new Kingfisher.TextPoint();
            data.textPoint.position = new Kingfisher.Vector3(0, 0, 0);
            // data.scene.textMesh.points.push(data.textPoint);

            data.dispose = function () {
                data.areaMesh.dispose();
                data.areaGeometry.dispose();
                let textIndex = data.scene.textMesh.points.indexOf(data.textPoint);
                if (textIndex >= 0) {
                    data.scene.textMesh.points.splice(textIndex, 1);
                    data.scene.textMesh.build();
                }
            }
        }

        data.areaMesh.name = data.obj.name + '(Area)';
        if (!area.finalVisible) {
            data.areaMesh.enableState = false;
            let textBuild = data.textPoint.visibility;
            if (data.textPoint.visible) {
                data.scene.textMesh.build();
            }
            data.textPoint.visible = false;

            return false;
        }
        else {
            let areaMesh = data.areaMesh;
            areaMesh.enableState = area.points.length > 2;
            if (!data.textPoint.visible) {
                data.scene.textMesh.build();
            }
            data.textPoint.visible = true;

            let style = ((areaOptionsData.value.find((x) => x.name === data.obj.purpose) || {}).states || [])[0];
            if (style) {
                let color4 = Kingfisher.Color4.FromHexString(style.color);

                // areaMesh.randomId.x = color4.r;
                // areaMesh.randomId.y = color4.g;
                // areaMesh.randomId.z = color4.b;
                // areaMesh.visibility = color4.a;

                let areaMaterial = data.scene.getMaterialByName('area-' + data.obj.purpose);
                if (!areaMaterial) {
                    areaMaterial = data.scene.getMaterialByName('area').clone();
                    areaMaterial.name = 'area-' + data.obj.purpose;
                }

                areaMaterial.albedoColor.r = color4.r;
                areaMaterial.albedoColor.g = color4.g;
                areaMaterial.albedoColor.b = color4.b;
                areaMaterial.alpha = color4.a;
                areaMesh.material = areaMaterial;
            }
            else {
                areaMesh.material = data.scene.getMaterialByName('area');
            }
        }

        let i;
        //dirty check
        for (i = 0; i < area.points.length; i++) {
            let p = area.points[i];

            p.checkDirty();
        }

        //line build
        let totalLength = area.points.length;

        let positions = new Float32Array(totalLength * 3);
        // let indices = new Uint32Array(totalLength * 3);

        let elevation = smDatas.objects[area.floor] ? smDatas.objects[area.floor].elevation * 0.001 : 0;
        data.areaMesh.position.y = elevation;

        for (i = 0; i < area.points.length; i++) {
            let t = i * 3;
            positions[t + 0] = area.points[i].x;
            positions[t + 1] = 0;
            positions[t + 2] = area.points[i].y;

            // indices[t + 0] = i;
            // indices[t + 1] = i;
            // indices[t + 2] = i === 0 ? area.points.length - 1 : i - 1;
        }

        buildAreaMesh(area);
        smMesh3DCache[area.nanoId].toVertexData().applyToGeometry(data.areaGeometry);

        let texts = [];
        if (area.showName && area.name) {
            texts.push(area.name);
        }
        if (area.showCustomId && area.customId !== null && area.customId !== '' && area.customId !== undefined) {
            texts.push(area.customId);
        }
        if (area.showArea) {
            texts.push((area.area * 0.000001).toFixed(1) + '㎡');
        }

        let text = texts.join('\r\n');
        let textBuild = text !== data.textPoint.text || (text && (
            data.textPoint.position.x !== area.textPos.x * 0.001 ||
            data.textPoint.position.y !== elevation ||
            data.textPoint.position.z !== area.textPos.y * 0.001));
        if (textBuild) {
            data.textPoint.text = text;
            data.textPoint.position.x = area.textPos.x * 0.001;
            data.textPoint.position.y = elevation;
            data.textPoint.position.z = area.textPos.y * 0.001;
            data.scene.textMesh.build();
        }

        if (currData.area === area) {
            sm3DMeshBuilder.updateSelectPoint();
        }

        return true;
    }
}

export default new Area3DBuilder();