import "core-js/modules/es.array.push.js";
import { globalState, currData } from "@/model/globalData";
import { smDatas } from "@/model/smCollectionData";
import { getModelByName } from "@/model/3Dmodel";
import { undoMap } from "@/undo/undoMap";
export class ModelCreator {
  constructor(creator) {
    this.type = 'modelCreator';
    this.owner = creator;
    this.collection = creator.collection;
    this.tools = creator.tools;
    this.currModelData = null;
    this.currCreateModel = null;
    this.hasMoved = false;

    //this.UIModel = {};
    //这个地方就是获取全局的鼠标事件
  }
  _createModelData() {
    let model = this.currModelData;
    if (!model) {
      return;
    }
    if (model.type === 'devicePoint') {
      model = getModelByName(model.spaceComponentName);
      if (!model) {
        return;
      }
    }
    if (typeof model.boundingInfo === 'string') {
      model.boundingInfo = JSON.parse(model.boundingInfo);
    }
    if (typeof model.properties === 'string') {
      model.properties = JSON.parse(model.properties);
    }
    let ratio = model.ratio || 1000;
    let temp = model.boundingInfo;
    let originalBoundingInfo = {
      min: {
        x: temp[0] * ratio,
        y: temp[1] * ratio,
        z: temp[2] * ratio
      },
      max: {
        x: temp[3] * ratio,
        y: temp[4] * ratio,
        z: temp[5] * ratio
      },
      size: {
        x: (temp[3] - temp[0]) * ratio,
        y: (temp[4] - temp[1]) * ratio,
        z: (temp[5] - temp[2]) * ratio
      }
    };
    let modelProperties = model.properties || {};
    if (typeof model.labelInterpret === 'string') {
      model.labelInterpret = JSON.parse(model.labelInterpret);
    }
    let locale = window.Kf.locale.value;
    locale = locale ? locale.replace("-", "") : 'zhCN';
    let name = (model.labelInterpret ? model.labelInterpret[locale] : '') || model.name;
    let properties = {
      modelId: model.k3dcId,
      modelName: model.name,
      attached: modelProperties.attached,
      position: [0, modelProperties.positionY * ratio || 0, 0],
      properties: Object.assign({
        originalBoundingInfo,
        ratio
      }, modelProperties.properties)
    };
    return {
      name,
      type: 'model',
      subType: model.subType,
      properties
    };
  }
  _disposeCurrCreateModel() {
    if (this.currModelData && this.currCreateModel) {
      let model = this.currCreateModel;
      if (model.deviceId) {
        let obj = smDatas.devicePoints.filter(x => x.deviceId === model.deviceId)[0];
        if (obj) {
          obj.deviceModelList.delete(model.nanoId);
        }
        model.deviceId = '';
        model.customId = '';
      }
      this.collection.remove(model);
      if (currData.undoData) {
        currData.undoData = null;
      }
      currData.setCurrList([]);
      this.currModelData = null;
      this.currCreateModel = null;
    }
  }
  globalMouseup(event) {
    if (currData.currList.length && currData.currList[0].type === 'model') {
      let mode = currData.currList[0];
      mode._customId_bk = null;
    }
    if (!globalState.mouse.in2dCanvas) {
      this._disposeCurrCreateModel();
    }
    this.currModelData = null;
    this.currCreateModel = null;
    this.hasMoved = false;
    if (currData.undoData) {
      undoMap.push(currData.undoData);
      currData.undoData = null;
    }
  }
  _2dMouseLeave() {
    globalState.mouse.in2dCanvas = false;
  }
  mouseRelease(pos, ray) {
    if (currData.currList.length && currData.currList[0].type === 'model') {
      let mode = currData.currList[0];
      if (mode._customId_bk) {
        mode.customId = mode._customId_bk;
        mode._customId_bk = null;
      }
    }
    if (globalState.isDrawing && this.currModelData) {
      globalState.isDrawing = '';
      this.currModelData = null;
      this.isDragging = false;
      let models = [...currData.currList];
      for (let model of models) {
        this.collection.remove(model);
      }
    }
    this._disposeCurrCreateModel();
    scene.setSelectBox();
    currData.undoData = null;
  }
  updateModel() {
    for (let key in currData.models) {
      let model = currData.models[key];
      model.updateBoundingBox();
      if (currData.floor) {
        model.floor = currData.floor.nanoId;
      }
      model.setNeedSave();
    }
  }
  updateModelPosition(pos, picker) {
    let models = currData.models;
    let x = pos.x;
    let y = pos.y;
    if (picker) {
      for (let key in models) {
        let model = models[key];
        if ((model.attached === 'embed' || model.attached === 'attach') && !model.locked) {
          let segment = picker.segment;
          if (segment) {
            let wall = smDatas.objects[segment.wall];
            if (!wall || wall.type !== 'wall') {
              segment = null;
            }
          }
          if (segment && !segment.isArc) {
            model.rotation.y = (-(picker.segmentFront ? segment.directionA : segment.directionB) + Math.PI * 3.5) % (Math.PI * 2);
            model.setSegment(segment);
          } else {
            model.setSegment(null);
          }
        }
      }
      if (picker.point) {
        x = picker.point.x;
        y = picker.point.y;
      } else {
        if (picker.pointX) {
          x = picker.pointX.x;
        }
        if (picker.pointY) {
          y = picker.pointY.y;
        }
      }
    }
    let currModel = this.currCreateModel || globalState.dragObject;
    if (picker && picker.segment && currModel && currModel.type === 'model' && currModel.attached === 'attach' && !currModel.locked) {
      let wall = this.collection.objects[picker.segment.wall];
      if (wall) {
        let halfThickness = wall.thickness / 2;
        x -= Math.round(halfThickness * Math.sin(currModel.rotation.y) * 10) * 0.1;
        y -= Math.round(halfThickness * Math.cos(currModel.rotation.y) * 10) * 0.1;
      }
    }
    let dragObject = globalState.dragObject;
    if (dragObject) {
      let dx = x - dragObject.position.x;
      let dy = y - dragObject.position.z;
      for (let model of currData.currList) {
        if (model.type !== 'model' || model.locked) {
          continue;
        }
        model.position.x += dx;
        model.position.z += dy;
        this._pickDrawingText(model.position.x, model.position.z, model);
        model.makeDirty();
        model.setNeedSave();
      }
    } else {
      for (let key in models) {
        let model = models[key];
        if (!model.locked) {
          model.position.x = x;
          model.position.z = y;
          this._pickDrawingText(x, y, model);
          model.makeDirty();
          model.setNeedSave();
        }
      }
    }
  }
  keyboard(event) {
    if (globalState.mode !== 'model' && globalState.mode !== 'devicePoint') {
      return;
    }
    let rotationCheck = function (r) {
      return Math.abs(((r + 0.5 * Math.PI) % Math.PI + Math.PI) % Math.PI - 0.5 * Math.PI) < Math.PI * 0.25;
    };
    let func;
    switch (event.key) {
      case 'r':
      case 'R':
        if (event.shiftKey) {
          func = model => {
            model.rotation.y = (model.rotation.y + Math.PI * 0.5) % (Math.PI * 2);
          };
        }
        break;
      case 'v':
      case 'V':
        if (event.shiftKey) {
          func = model => {
            if (rotationCheck(model.rotation.y)) {
              model.rotation.y = (model.rotation.y + Math.PI) % (Math.PI * 2);
            }
            model.mirrored = !model.mirrored;
          };
        }
        break;
      case 'h':
      case 'H':
        if (event.shiftKey) {
          func = model => {
            if (!rotationCheck(model.rotation.y)) {
              model.rotation.y = (model.rotation.y + Math.PI) % (Math.PI * 2);
            }
            model.mirrored = !model.mirrored;
          };
        }
        break;
      default:
        break;
    }
    if (func) {
      for (let model of Object.values(currData.models)) {
        if (model.type !== 'model' || model.locked) {
          continue;
        }
        func(model);
        model.makeDirty();
        model.setNeedSave();
      }
    }
  }
  mouseUp(pos, ray, picker) {
    if (this.isDragging) {
      this.isDragging = false;
      this.hasMoved = false;
      if (currData.undoData) {
        undoMap.push(currData.undoData);
        currData.undoData = null;
      }
    }
  }
  mouseMove(pos, ray, picker) {
    if (!this.currModelData) {
      if (globalState.dragData) {
        if (currData.floor && !currData.floor.visible) {
          return;
        }
        let dragData = globalState.dragData;
        this.currModelData = dragData;
        this.isDragging = true;
        if (!currData.floor) {
          let floor = smDatas.createFloor();
          floor.setNeedSave();
          undoMap.push({
            cmd: 'create',
            target: floor,
            value: floor.serialize()
          });
          currData.floor = floor;
        }
        let model = this.collection.createModel(this._createModelData());
        model.setNeedSave();
        if (!currData.undoData) {
          currData.undoData = {
            cmd: 'create',
            target: model,
            value: model.serialize()
          };
        }
        this.currCreateModel = model;
        model.floor = currData.floor.nanoId;
        if (globalState.mode === 'model') {
          currData.setCurrList(model);
        } else if (model) {
          currData.models = {};
          currData.models[model.nanoId] = model;
          if (dragData.type === 'devicePoint') {
            dragData.deviceModelList.add(model.nanoId);
            model.deviceId = dragData.deviceId;
            model.customId = dragData.deviceMapId;
            if (dragData.deviceName && dragData.deviceName !== 'null') {
              model.name = dragData.deviceName;
            }
          }
        }
        this.updateModel();
      }
    }
    if (globalState.dragWaitClone) {
      globalState.dragWaitClone = false;
      globalState.dragCloned = true;
      let res = [];
      for (let model of currData.currList) {
        if (model.type !== 'model' || model.locked) {
          continue;
        }
        let nm = this.collection.cloneObject(model);
        res.push(nm);
        if (model === globalState.dragObject) {
          globalState.dragObject = nm;
        }
      }
      currData.setCurrList(res);
      if (!currData.undoData) {
        currData.undoData = {
          cmd: 'create',
          items: Object.values(currData.models).map(x => {
            return {
              target: x,
              value: x.serialize()
            };
          })
        };
      }
      this.hasMoved = true;
      this.updateModelPosition(pos, picker);
    } else if (this.currModelData || globalState.dragObject) {
      if (this.currCreateModel || globalState.dragObject) {
        if (!this.hasMoved) {
          if (!currData.undoData) {
            currData.undoData = {
              cmd: 'updateObj',
              items: Object.values(currData.models).map(x => {
                return {
                  target: x,
                  value: x.serialize()
                };
              })
            };
          }
          this.hasMoved = true;
        }
        this.updateModelPosition(pos, picker);
      } else {
        let models = currData.currList;
        let fromPos = this._Data.pos;
        let row = this._Data.row;
        let column = this._Data.column;
        let stepX = row > 1 ? (pos.x - fromPos.x) / (row - 1) : 0;
        let stepY = column > 1 ? (pos.y - fromPos.y) / (column - 1) : 0;
        let stepY1 = row > 1 && globalState.drawingMode === 'line' ? (pos.y - fromPos.y) / (row - 1) : 0;
        for (let i = 0; i < column; i++) {
          for (let j = 0; j < row; j++) {
            let model = models[i * row + j];
            if (model) {
              model.position.x = fromPos.x + stepX * j;
              model.position.z = fromPos.y + stepY * i + stepY1 * j;
              model.makeDirty();
              model.setNeedSave();
            }
          }
        }
      }
    }
  }
  mouseTap(pos, ray, picker) {
    if (globalState.mode === 'model' && globalState.selectedData) {
      if (this.currModelData) {
        this.currModelData = null;
        globalState.isDrawing = '';
        if (currData.undoData) {
          undoMap.push(currData.undoData);
          currData.undoData = null;
        }
      } else {
        this.currModelData = globalState.selectedData;
        if (!currData.floor) {
          let floor = smDatas.createFloor();
          floor.setNeedSave();
          currData.floor = floor;
        }
        if (globalState.drawingMode === 'point') {
          globalState.isDrawing = 'point';
          let model = this.collection.createModel(this._createModelData());
          model.floor = currData.floor.nanoId;
          this.currCreateModel = model;
          currData.setCurrList(model);
          if (!currData.undoData) {
            currData.undoData = {
              cmd: 'create',
              target: model,
              value: model.serialize()
            };
          }
          this.updateModel();
          this.updateModelPosition(pos, picker);
        } else if (globalState.drawingMode === 'line') {
          globalState.isDrawing = 'line';
          let params = globalState.toolBarParams.model;
          let name = params.name;
          let height = params.height;
          let count = params.quantity;
          this._Data = {
            pos: {
              x: pos.x,
              y: pos.y
            },
            row: count,
            column: 1
          };
          let models = [];
          for (let i = 0; i < count; i++) {
            let model = this.collection.createModel(this._createModelData());
            model.floor = currData.floor.nanoId;
            model.position.x = pos.x;
            model.position.y = height;
            model.position.z = pos.z;
            model.name = name + (i + 1);
            models.push(model);
            model.updateBoundingBox();
            model.setNeedSave();
          }
          currData.setCurrList(models);
          if (!currData.undoData) {
            currData.undoData = {
              cmd: 'create',
              items: models.map(x => {
                return {
                  target: x,
                  value: x.serialize()
                };
              })
            };
          }
        } else if (globalState.drawingMode === 'area') {
          globalState.isDrawing = 'area';
          let params = globalState.toolBarParams.model;
          let name = params.name;
          let height = params.height;
          let row = params.row;
          let column = params.column;
          this._Data = {
            pos: {
              x: pos.x,
              y: pos.y
            },
            row: params.row,
            column: params.column
          };
          let models = [];
          for (let i = 0; i < column; i++) {
            for (let j = 0; j < row; j++) {
              let model = this.collection.createModel(this._createModelData());
              model.floor = currData.floor.nanoId;
              model.position.x = pos.x;
              model.position.y = height;
              model.position.z = pos.z;
              model.name = name + (i * row + j + 1);
              models.push(model);
              model.updateBoundingBox();
              model.setNeedSave();
            }
          }
          currData.setCurrList(models);
          if (!currData.undoData) {
            currData.undoData = {
              cmd: 'create',
              items: models.map(x => {
                return {
                  target: x,
                  value: x.serialize()
                };
              })
            };
          }
        }
      }
    }
  }
  mouseDoubleTap(pos, ray, picker) {
    if (globalState.mode === 'model' && globalState.selectedData) {
      if (!this.currModelData) {
        this.currModelData = globalState.selectedData;
        if (!currData.floor) {
          let floor = smDatas.createFloor();
          floor.setNeedSave();
          currData.floor = floor;
        }
        if (globalState.drawingMode === 'point') {
          let model = this.collection.createModel(this._createModelData());
          model.floor = currData.floor.nanoId;
          currData.setCurrList(model);
          this.updateModel();
          this.updateModelPosition(pos, picker);
        }
      }
      this.currModelData = null;
      this.currCreateModel = null;
    }
  }
  pickPoint(pos, ray, picker) {
    // let worldPoint = getWorldPoint(this, ray);
    let models = this.collection.models;
    let scope = this;
    let position = null;
    let distance;
    let currListMap = currData.currListMap;
    for (let model of models) {
      if (!currListMap.has(model)) {
        let x = model.position.x - pos.x;
        let y = model.position.z - pos.y;
        let _distance = x * x + y * y; //Kingfisher.Vector3.DistanceSquared(worldPoint.point, model.position);
        if (distance && distance > _distance || !distance && _distance < picker.tolerant) {
          distance = _distance;
          position = model.position;
        } /**/
      }
    }
    if (position) {
      picker.distance2 = distance;
      picker.point = {
        x: position.x,
        y: position.z
      };
    }
  }
  pickXYPoints(pos, ray, picker) {
    let models = this.collection.models;
    let dx = picker.distanceX;
    let dy = picker.distanceY;
    let lx = picker.LengthX;
    let ly = picker.LengthY;
    let l_ = picker.tolerant;
    let pointX;
    let pointY;
    let currListMap = currData.currListMap;
    let pickModelXYPoints = model => {
      if (!model || !model.finalVisible || currListMap.has(model)) {
        return;
      }
      let point = model.position;
      let dx_ = Math.abs(pos.x - point.x);
      let dy_ = Math.abs(pos.y - point.z);
      if (dx > dx_ || Math.abs(dx - dx_) < l_ && lx > dy_) {
        dx = dx_;
        lx = dy_;
        pointX = {
          x: point.x,
          y: point.z
        };
      }
      if (dy > dy_ || Math.abs(dy - dy_) < l_ && ly > dx_) {
        dy = dy_;
        ly = dx_;
        pointY = {
          x: point.x,
          y: point.z
        };
      }
    };
    for (let i = 0; i < models.length; i++) {
      pickModelXYPoints(models[i]);
    }
    if (dx < picker.tolerant && pointX) {
      picker.pointX = pointX;
      picker.distanceX = dx;
      picker.LengthX = lx;
    }
    if (dy < picker.tolerant && pointY) {
      picker.pointY = pointY;
      picker.distanceY = dy;
      picker.LengthY = ly;
    }
  }
  pickByAreaBox(pos, ray, picker) {
    let models = this.collection.models;
    let pos0 = picker.pos0;
    let pos1 = pos;
    let x0 = Math.min(pos0.x, pos1.x);
    let x1 = Math.max(pos0.x, pos1.x);
    let y0 = Math.min(pos0.y, pos1.y);
    let y1 = Math.max(pos0.y, pos1.y);
    for (let model of models) {
      if (model.isDeleted || !model.position || !model.boundingBox || !model.finalVisible) {
        continue;
      }
      let position = model.position;
      let bbox = model.boundingBox;
      if (position.x + bbox[0] <= x1 && position.z + bbox[2] <= y1 && position.x + bbox[3] >= x0 && position.z + bbox[5] >= y0) {
        picker.objs.push(model);
      }
    }
  }
  _pickDrawingText(x, y, mode) {
    let floor = mode.floor;
    let selectTextList = [];
    for (let drawing of this.collection.drawings) {
      if (!drawing.visible || drawing.floor && drawing.floor !== floor) {
        continue;
      }
      if (drawing.type === 'dxfDrawing') {
        let _s = 1 / drawing.scaling;
        let _g = 4000000 * _s * _s;
        let k = 0;
        let dx = (x + drawing.centerX) * _s;
        let dy = (y + drawing.centerY) * _s;
        for (let layer of drawing.layers) {
          if (!layer.visible || !layer.textData) {
            continue;
          }
          for (let text of layer.textData) {
            let b = text.bounds;
            if (b) {
              let _x;
              if (b.max_x >= dx && b.min_x <= dx) {
                _x = 0;
              } else {
                _x = Math.min(dx - b.max_x, b.min_x - dx);
              }
              let _y;
              if (b.max_y >= dy && b.min_y <= dy) {
                _y = 0;
              } else {
                _y = Math.min(dy - b.max_y, b.min_y - dy);
              }
              let t = _x * _x + _y * _y;
              if (t < _g) {
                k++;
                let index = selectTextList.length;
                for (; index; index--) {
                  let _ = selectTextList[index - 1];
                  if (_.d2 <= t) {
                    break;
                  }
                }
                if (index < 5) {
                  selectTextList.splice(index, 0, {
                    d2: t,
                    text,
                    drawing
                  });
                }
              }
            }
          }
        }
      }
    }
    if (selectTextList.length) {
      let textList = selectTextList.map(x => x.text.textDoc.text);
      if (!mode._customId_bk) {
        mode._customId_bk = mode.customId;
      }
      mode.customId = textList[0];
      mode.customIdList = textList;
      let t = selectTextList[0].text;
      let drawing = selectTextList[0].drawing;
      console.log(t);
      let s = drawing.scaling;
      let x = t.insertPoint.x * s - drawing.centerX;
      let y = t.insertPoint.y * s - drawing.centerY;
      scene.setSelectBox({
        x,
        y
      }, t.bounds.width * s, t.bounds.height * s);
    }
  }
  pickObject(pos, ray, picker) {
    if (picker.objs.length) {
      return;
    }
    let models = this.collection.models;
    let meshBuilder = window.sm2DMeshBuilder;
    let pickModel = function (model) {
      if (!model || !model.finalVisible || !meshBuilder.objects[model.nanoId]) {
        return;
      }
      let meshes = meshBuilder.objects[model.nanoId].pickMeshes;
      if (meshes) {
        // if (picker.filter && !picker.filter(meshBuilder.objects[model.nanoId].obj)) {
        //     return;
        // }
        for (let i = 0; i < meshes.length; i++) {
          let pickingInfo = ray.intersectsMesh(meshes[i]);
          if (pickingInfo.pickedMesh) {
            return model;
          }
        }
      }
    };
    let res;
    for (let key in currData.models) {
      res = pickModel(currData.models[key]);
      if (res) {
        picker.objs.push(res);
        return picker;
      }
    }
    let topValue = -Number.MAX_VALUE;
    for (let i = 0; i < models.length; i++) {
      let model = models[i];
      if (currData.models[model.nanoId]) {
        continue;
      }
      res = pickModel(model);
      if (res) {
        let value = model.position.y + model.boundingBox[4];
        if (value > topValue) {
          picker.objs.push(res);
          topValue = value;
        }
      }
    }
    return picker;
  }
}