<template>
  <div class="kf-panel-bottom-header" style="border-top-left-radius: 0">
    <div class="kf-panel-bottom-headers" style="justify-content: space-between;width:100%">
      <!--            <div class="kf-panel-bottom-header-name1 flex-row-start-center">{{ $t('ComponentLibrary') }}-->
      <!--                <el-select v-model="selectedType" :placeholder="$t('PleaseSelect')" class="kingfisher_select2 ml10"-->
      <!--                           style="width: 110px">-->
      <!--                    <el-option v-for="(item,index) in typeList" :key="index+'type'" :label="item.label"-->
      <!--                               :value="item.value"></el-option>-->
      <!--                </el-select>-->
      <!--            </div>-->
      <div class="flex-row-end-center" style="padding-right: 8px;">
        <!--                <div class="kf-panel-bottom-header-name1">{{ $t('Theme') }}:</div>-->
<!--        <el-select v-model="selectedThemeId" :placeholder="$t('PleaseSelect')" class="kingfisher_select2"
                   popper-class="kf-light-select-list1"
                   style="width: 135px">
          <template #prefix><i class="iconfont icon-sanwei" style="margin-left: 4px"></i></template>
          <el-option v-for="(item,index) in themeList" :key="index" :label="item.name"
                     :value="item.uuid">
            <div style="display: flex;justify-content: space-between">
              <div style="width:68px">{{item.name}}</div>
              <i class="iconfont icon-select" style="font-size: 12px" ></i>
            </div>
          </el-option>
        </el-select>-->
      </div>
      <div class="kf-3dc-dialog-scene-list-header1" style="width:auto">
        <el-input v-model="searchValue"  @change="searchChange" placeholder="搜索"
                  class="kf-3dc-dialog-scene-list-header-input1" >
          <template #prefix>
            <i  class="iconfont icon-search" @click="searchChange"></i>
          </template>
          <template #suffix>
            <i slot="suffix" class="iconfont icon-close" style="font-size: 14px" @click="searchValue='';handleSelect(currSelectValue,currLabel)"></i>
          </template>
        </el-input>
      </div>

    </div>
  </div>
  <div class="kf-model-content mt2 kingfisher_scroll" draggable="false" >

    <!--        <el-collapse v-model="activeName" class="kf-model-collapse" draggable="false">-->
    <!--            <el-collapse-item v-for="(models,index) in treeNode" :key="index">-->
    <!--                <template #title>-->
    <!--                    <div class="kf-kf-model-collapse-title">{{ models.label }}</div>-->
    <!--                </template>-->
    <!--            </el-collapse-item>-->
    <!--        </el-collapse>-->
    <div class="kf-model-content-list kingfisher_scroll">
      <el-menu
          ref="menu"
          class="el-menu-vertical-demo"
          :default-active="currSelectValue"
          :default-openeds="openeds"
          @open="handleOpen"
          @select="handleSelect"
      >
        <el-sub-menu index="100" >
          <template #title>
            <span>{{name}}</span>
            <div class="eventClickDivTitle"  :style="{
                'background': currSelectValue=='100' &&currLabel=='全部构件'?'#367BF5' : 'transparent',
                }" @click="handleSelect('100','全部构件','none')" ></div>
          </template>
          <div class="eventClickDiv" @click="handleSelect('100','全部构件')"></div>
          <el-menu-item-group v-for="(item,index) in typeList">
            <el-menu-item :index="index.toString()" v-if="item.children.length==0 && item.data.length>0" >{{item.label}}
            </el-menu-item>
            <el-sub-menu :index="index.toString()" v-if="item.children.length>0">
              <template #title>
                <span >{{item.label}}</span>
                <div class="eventClickDivTitle"  :style="{
                'background':currSelectValue==index && currLabel==item.label?'#367BF5' : 'transparent',
                }" @click="handleSelect(index,item.label,'none')"></div>
              </template>
              <div class="eventClickDiv" @click="handleSelect(index,item.label)"></div>
              <kf-menu-item v-if="item.children" :typeList="item.children" :currSelectValue="currSelectValue"
                            :index="index.toString()" :currLabel="currLabel" @handleSelect="handleSelect"></kf-menu-item>
            </el-sub-menu>
          </el-menu-item-group>
        </el-sub-menu>
      </el-menu>
    </div>
    <div class="kf-kf-model-collapse-list kingfisher_scroll w-100">
      <kf-collapse-item :props="treeNodeList" ></kf-collapse-item>
    </div>
  </div>

</template>

<script>
import {ref, watch, computed,onMounted,nextTick} from 'vue'
import modelData, {
  compGroupList,
  selectedThemeId,
  themeList,
  selectedType,
  currThemeWidgets,
  commCompsLib
} from "@/model/3Dmodel";
import {globalState, globalStateProxy, currData} from "@/model/globalData";
import modelOptions from "@/model/modelOptions";
import kfCollapseItem from "@/components/mainContent/rightPanel/kfCollapseItem.vue";
import kfMenuItem from "@/components/mainContent/rightPanel/kfMenuItem.vue";
import {activeTab} from "./index.js";
import projectModel from "@/model/project";
import ModelService from "../../../service/model";
import {smDatas} from "@/model/smCollectionData";
import Const from "@/utils/const";

export default {
  name: "panelWidget",
  components: {kfCollapseItem,kfMenuItem},
  setup() {
    let activeName = ref('0');
    let newTypeList = ref([]);
    let openeds= ref(['100']);
    let currLabel= ref(Kf.t('AllWidgets'));
    let name= ref(Kf.t('AllWidgets'));
    let locale = ref(window.Kf.locale.value.replace("-", ""));
    let searchValue= ref('');
    let getLabel = function (item) {
      let _name = item.name
      if (item.labelInterpret) {
        if (typeof item.labelInterpret === 'string') {
          item.labelInterpret = JSON.parse(item.labelInterpret);
        }
        if (item.labelInterpret[locale.value]) {
          _name = item.labelInterpret[locale.value]
        }
      }
      return _name
    }
    let searchChange=function(){
      handleSelect(currSelectValue.value,currLabel.value)
      let data=treeNodeList.value.data.filter((item)=>{
        return getLabel(item).indexOf(searchValue.value)>-1
      })
      treeNodeList.value.data=data;
    }
    const handleOpen = function (openeds) {
      if(currSelectValue.value==='none'){
        menu.value.close(openeds);
        currSelectValue.value=openeds
      }
    }
    const treeNodeList=ref();

    const isSame= function (res, item) {
          let existingNode = res.findIndex(node => node.label === item.label);
          if(res[existingNode]) {
            item.children.map((child)=>{
              isSame(res[existingNode].children,child)
            })
          }else{
            res.push(item);
          }
    }

    const getTreeChildren = function (itemCon,widgetList) {
      let res = [];
      let Widgets = widgetList
      if (Widgets?.length > 0) {
        let result = [];
        result = modelData.loadTreeData(Widgets)
        result.map((item, index) => {
          isSame(res, item)
        })
      }
            // let _listCon=getTreeChildren('',currThemeWidgets.value);
       // console.log("---treeNode", res);
      return res
    }
    let currSelectValue= ref('100');
    const findChildren=function(res,result){
      if(res?.children && res.children.length>0){
        res.children.map((con,index)=>{
          if(con.children.length>0){
            findChildren(con,result)
          }
          if(con.data.length>0){
            result.data= result.data.concat(con.data);
          }
        })
      }else if(res?.data && res.data.length>0){
        result.data= result.data.concat(res.data);
      }
    }
    const handleSelect=(index,label,type)=>{
      let res={
        children:[],
        data:[]
      };
      switch(index){
        case '100':
          currLabel.value='全部构件';
          typeList.value.map((item,index)=>{
            findChildren(item,res);
          })
          treeNodeList.value=res;
          break;
        default:
          let indexArr=index.toString().split('-');
          let label=typeList.value;
          if(label){
            indexArr.map((item,index)=>{
              if(indexArr.length-1!==index){
                label=label[item].children
              }else if(label[item]){
                label=label[item]
              }
            })
          }
          currLabel.value=label.label;
          findChildren(label,res);
          treeNodeList.value={...res}
          break;
      }
      if(typeof type==='string' && type==='none'){
        currSelectValue.value='none';
      }else{
      currSelectValue.value= index;
      }
    }
    let oldThemeWidgets = ref('');
    const typeList = computed(() => {
      let res = [];
      if (modelOptions.modelMap && currThemeWidgets.value.length > 0) {
        res= getTreeChildren('',currThemeWidgets.value);
         if(oldThemeWidgets.value!==currThemeWidgets.value){
          oldThemeWidgets.value=currThemeWidgets.value;
          currSelectValue.value='100';
          currLabel.value='全部构件';
          if(res.length>0){
            let result={
              children:[],
              data:[]
            };
            res.map((item,index)=>{
              findChildren(item,result);
            })
            treeNodeList.value=result;
          }
         }
      }else{
        treeNodeList.value=[];
      }
      return res
    })
  let menu= ref();
    return {
      treeNodeList,
      currSelectValue,
      globalState,
      activeName,
      menu,
      searchChange,
      compGroupList,
      themeList,
      openeds,
      name,
      selectedThemeId,
      selectedType,
      typeList,
      currLabel,
      searchValue,
      handleOpen,
      handleSelect,

    }
  },

}
</script>

<style lang="scss">
.kf-widget-detail-con {
  background-image: url('../../../styles/img/widget-bg.png');
  background-size: 100% 100%;
}

.kf-panel-bottom-headers {
  .el-select.kingfisher_select2 {
    .select-trigger {
      .el-input {
        background: transparent;
        .el-input__inner {
          height: 24px;
          background: #303030;
          line-height: 24px;
          font-size: 12px;
          background: rgba(0, 0, 0, 1);
          border-radius:  4px;
          border: 1px solid rgba(255, 255, 255, 0.10);
          color: #e4e4e4;
        }
      }
    }
  }
}

.ml10 {
  margin-left: 10px;
}

.flex-row-end-center {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.flex-row-start-center {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.kf-model-content {
  .el-collapse {
    .el-collapse-item {
      .el-collapse-item__content {
        padding-bottom: 0;

        .el-collapse-item {
          .el-collapse-item__header {
            padding-left: 8px;

            .el-icon {
              left: 16px;
            }
          }

          .el-collapse-item__wrap {
            border-bottom: 0;

            .el-collapse-item__content {
              //padding: 2px 16px 0 16px;
              .kf-model-collapse-content {
                padding: 2px 16px 0 16px;
              }
            }
          }
        }
      }

      .el-collapse {
        .el-collapse-item {
          .el-collapse-item__content {
            .el-collapse-item {
              .el-collapse-item__header {
                padding-left: 24px;

                .el-icon {
                  left: 32px;
                }
              }

              .el-collapse-item__wrap {
                border-bottom: 0;

                .el-collapse-item__content {
                  .kf-model-collapse-content {
                    padding: 2px 32px 0 32px
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
