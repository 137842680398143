import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import { computed, ref, watch } from 'vue';
import { smDatas } from "@/model/smCollectionData";
import inputProp from "../../propWidget/inputProp";
import numberProp from "../../propWidget/numberProp";
import enumProp from "../../propWidget/enumProp";
import scene from "./scene";
import KfList from "./listItem/KfList.vue";
import KfTree from "./listItem/KfTree.vue";
import drawing from "./drawing";
import wall from "./wall";
import kfArea from "./area.vue";
import kfFloor from "./floor.vue";
import kfPoint from "./point.vue";
import panelProperty from "./panelProperty";
import panelWidget from "./panelWidget";
import kfModel from "./model";
import contextmenu from "@/components/widgets/contextmenu/contextmenu.vue";
import contextmenu2 from "@/components/widgets/contextmenu/contextmenu2.vue";
import { getComponentType, getPropModel } from "@/model/propModel";
import { activeTab, calibrateCallback, calibrateCancelCallback, calibrateDrawingDialog, createFloor, doMenuClick, isShowContextMenu, setProperty, showContextMenu } from "./index.js";
import { contextMenuStyle } from "@/components/widgets/contextmenu/contextmenu.js";
import { typeList } from "@/model/rightPanel";
import { currData, globalState } from "@/model/globalData";
import { decomposeMatrix2x3, creatMatrix2x3, multiplyMatrix2x3, Vector2 } from "@/maths/vector";
import { isObject } from "lodash";
import extendInput from "@/components/propWidget/extendInput.vue";
import { angleToRadian, radianToAngle } from "@/utils/tools";
import { checkModelsBindStateAndBatchBind } from "@/components/mainContent/rightPanel/point";
import SceneProp from "@/components/propWidget/sceneProp.vue";
import { selectedThemeId } from "@/model/3Dmodel";
export default {
  name: "rightPanel",
  components: {
    SceneProp,
    extendInput,
    panelProperty,
    panelWidget,
    inputProp,
    numberProp,
    enumProp,
    scene,
    drawing,
    wall,
    kfArea,
    contextmenu,
    contextmenu2,
    kfModel,
    kfFloor,
    kfPoint,
    KfList,
    KfTree
  },
  setup() {
    const currComponent = ref(null);
    const translation = ref({
      x: 0,
      y: 0,
      z: 0
    });
    const setupSize = ref({
      x: 0,
      y: 0,
      z: 0
    });
    const rotationY = ref(0);
    const mirror = ref(false);
    const keepRatio = ref(false);
    const preModelsProxy = ref([]);
    const preModels = preModelsProxy.value;
    const onBindingMouseup = () => {
      currComponent.value = globalState.dragData;
      createBindingModel();
    };
    let directionList = ref([{
      name: 'top',
      icon: 'icon-direction-up',
      value: 0
    }, {
      name: 'right',
      icon: 'icon-direction-right',
      value: 90
    }, {
      name: 'bottom',
      icon: 'icon-direction-down',
      value: 180
    }, {
      name: 'left',
      icon: 'icon-direction-left',
      value: 270
    }]);
    let currDirection = ref(directionList.value[0]);
    const directionClick = item => {
      if (currDirection.value.value === item.value) {
        return;
      }
      currDirection.value = item;
      if (globalState.isBindingModel) {
        _updateBlocksModelTransform(true);
      } else if (globalState.isReplacingModel) {
        updateReplaceModels();
      }
    };
    const propertyList = ref({
      name: Kf.t('NotSelected'),
      list: [{
        model: {},
        props: []
      }]
    });
    watch(() => currData.currListFlag, (n, o) => {
      let currList = currData.currList;
      let list = [];
      for (let i = 0; i < currList.length; i++) {
        let obj = currList[i];
        let model = getPropModel(obj);
        if (!model) {
          continue;
        }
        list.push({
          model: obj,
          props: model.properties
        });
        // console.log(propertyList.value);
      }
      propertyList.value.name = Kf.t('NotSelected');
      propertyList.value.list = list;

      // if (currList.length) {
      //   activeTab.value = Kf.t('Properties');
      // }
    });
    let menuBox = ref(null);
    let compName = computed(() => {
      let _mode = globalState.mode || 'floor';
      // console.log("-compName-",_mode,typeList.value,typeList.value.find(x => x.type === _mode));
      return typeList.value.find(x => x.type === _mode);
    });
    let setCalibrateLength = function (yesNo) {
      calibrateDrawingDialog.value.show = false;
      let value = Number(calibrateDrawingDialog.value.value) * 10;
      if (yesNo) {
        if (calibrateCallback.value) {
          calibrateCallback.value(value);
        }
      } else {
        if (calibrateCallback.value) {
          calibrateCancelCallback.value(value);
        }
      }
    };
    let propChanged = function (target, data) {
      setProperty(target, data.name, data.value);
    };
    window.addEventListener('click', function (e) {
      if (!menuBox.value || !menuBox.value.contains(e.target)) {
        isShowContextMenu.value = 0;
      }
    });
    let currType = ref();
    const handleTypeList = function (item) {
      console.log(item);
      globalState.mode = item.type;
      currType.value = item;
    };
    const modelTab = ref([{
      name: Kf.t('Properties')
    }, {
      name: Kf.t('Data')
    }, {
      name: Kf.t('Status')
    }]);
    let currModelTab = ref(Kf.t('Properties'));
    const handleModelTab = function (item) {
      currModelTab.value = item.name;
    };
    let getImageUrl = function (item) {
      return Kingfisher.FileTools.GetAbsoluteUrl("k3dc://parts/" + (item.thumbnail || item.screenshotPath || item.k3dcId + '.jpg'));
    };
    const _updateBlockModel = function (model, fileMatrix, localMatrix) {
      let originalBoundingInfo = model.properties.originalBoundingInfo;
      let size = originalBoundingInfo.size;
      let transform = decomposeMatrix2x3(multiplyMatrix2x3(multiplyMatrix2x3(fileMatrix, model._blockTransforms), localMatrix));
      model.position.x = transform.translation.x;
      model.position.z = transform.translation.y;
      model.position.y = translation.value.y;
      model.rotation.y = -transform.rotation;
      model.scaling.x = transform.scale.x;
      model.scaling.z = transform.scale.y;
      model.scaling.y = size.y ? setupSize.value.y / size.y : 1;
      model.makeDirty();
      model.updateBoundingBox();
    };
    const _updateBlocksModelTransform = function (initFromBlock = false, resetDirection = false) {
      let drawing = smDatas.objects[globalState.bindingModelBlocks.drawing];
      let name = globalState.bindingModelBlocks.name;
      let file = smDxfCache[drawing.fileId];
      let blocks = file.blocks[name];
      if (!blocks.entities.length) {
        return;
      }
      let fileMatrix = drawing.getMatrix();
      let originalBoundingInfo = preModels[0].properties.originalBoundingInfo;
      let defaultPosY = preModels[0].position.y;
      let max = originalBoundingInfo.max;
      let min = originalBoundingInfo.min;
      let size = originalBoundingInfo.size;
      let blockScale = Math.abs(blocks.entities[0].entity.xScale || 1);
      let drawingScale = drawing.scaling;
      let blockDrawingScale = blockScale * drawingScale;
      if (initFromBlock) {
        let blockBounds = blocks.bounds;
        blockBounds = {
          minX: blockBounds.minX * blockDrawingScale,
          maxX: blockBounds.maxX * blockDrawingScale,
          minY: blockBounds.minY * blockDrawingScale,
          maxY: blockBounds.maxY * blockDrawingScale
        }; //真实空间

        if (resetDirection) {
          if (blockBounds.maxX - blockBounds.minX > blockBounds.maxY - blockBounds.minY === size.x > size.z) {
            currDirection.value = directionList.value[0];
          } else {
            currDirection.value = directionList.value[1];
          }
        }
        let worldCenter = {
          x: (blockBounds.minX + blockBounds.maxX) / 2,
          y: (blockBounds.minY + blockBounds.maxY) / 2
        }; //空间位置偏移
        if (keepRatio.value) {
          let k = size.x ? (blockBounds.maxX - blockBounds.minX) / size.x : 1;
          setupSize.value.x = size.x * k;
          setupSize.value.y = size.y * k;
          setupSize.value.z = size.z * k;
        } else {
          //空间大小
          if (!(currDirection.value.value % 180)) {
            setupSize.value = {
              x: blockBounds.maxX - blockBounds.minX,
              z: blockBounds.maxY - blockBounds.minY,
              y: size.y
            };
          } else {
            setupSize.value = {
              x: blockBounds.maxY - blockBounds.minY,
              z: blockBounds.maxX - blockBounds.minX,
              y: size.y
            };
          }
        }
        let modelOffsetX = size.x ? (min.x + max.x) / size.x / 2 : 0;
        let modelOffsetZ = size.z ? (min.z + max.z) / size.z / 2 : 0;
        let temp;
        switch (currDirection.value.value) {
          case 90:
            temp = modelOffsetX;
            modelOffsetX = -modelOffsetZ;
            modelOffsetZ = temp;
            break;
          case 180:
            modelOffsetX = -modelOffsetX;
            modelOffsetZ = -modelOffsetZ;
            break;
          case 270:
            temp = modelOffsetX;
            modelOffsetX = modelOffsetZ;
            modelOffsetZ = -temp;
            break;
        }
        translation.value = {
          x: worldCenter.x - modelOffsetX * (blockBounds.maxX - blockBounds.minX) * blockDrawingScale,
          z: worldCenter.y - modelOffsetZ * (blockBounds.maxY - blockBounds.minY) * blockDrawingScale,
          y: defaultPosY
        };
      }
      let rotation = angleToRadian(rotationY.value + currDirection.value.value);
      let scale = {
        x: size.x ? setupSize.value.x / size.x / blockDrawingScale : 1,
        z: size.z ? setupSize.value.z / size.z / blockDrawingScale : 1
      };
      if (mirror.value) {
        scale.x = -scale.x;
      }
      let localMatrix = creatMatrix2x3({
        x: translation.value.x / blockDrawingScale,
        z: translation.value.z / blockDrawingScale,
        y: translation.value.y
      }, rotation, scale);
      for (let preModel of preModels) {
        _updateBlockModel(preModel, fileMatrix, localMatrix);
      }
    };

    //checkModelsBindStateAndBatchBind
    const createBindingModel = function () {
      if (!currComponent.value) {
        return;
      }
      if (globalState.bindingModelBlocks.length < 0) {
        cancelBindingModel();
        return;
      }
      for (let preModel of preModels) {
        smDatas.remove(preModel);
      }
      preModels.length = 0;
      let drawing = smDatas.objects[globalState.bindingModelBlocks.drawing];
      let layerVisibleMap = new Map();
      for (let layer of drawing.layers) {
        layerVisibleMap.set(layer.layerName, layer.visible);
      }
      let name = globalState.bindingModelBlocks.name;
      let file = smDxfCache[drawing.fileId];
      let blocks = file.blocks[name];
      for (let i = 0; i < blocks.entities.length; i++) {
        let block = blocks.entities[i];
        smCreator.modelCreator.currModelData = currComponent.value;
        let model = smDatas.createModel(smCreator.modelCreator._createModelData());
        model.keepRatio = keepRatio.value;
        model._blockTransforms = block.entity.transforms;
        let texts = block._texts.filter(text => {
          if (!layerVisibleMap.get(text.text.layer)) {
            return false;
          }
          if (text.d2 && !text.text._blocks[0].block.entities.includes(block)) {
            return false;
          }
          return true;
        });
        if (texts.length) {
          if (isObject(texts[0].text.textEntities)) {
            model.drawingTexts = [];
            for (let entity of texts[0].text.textEntities.entities) {
              let content = entity.content;
              if (content) {
                if (typeof content === 'string') {
                  model.drawingTexts.push(content);
                } else {
                  for (let _content of content) {
                    if (_content.type === 0) {
                      model.drawingTexts.push(_content.content);
                    }
                  }
                }
              }
            }
          } else {
            model.drawingTexts = [texts[0].text.textEntities];
          }
          for (let devicePoint of smDatas.devicePoints) {
            if (model.drawingTexts.includes(devicePoint.deviceMapId)) {
              model.deviceId = devicePoint.deviceId;
              if (devicePoint.deviceName && devicePoint.deviceName !== 'null') {
                model.name = devicePoint.deviceName;
              }
              devicePoint.deviceModelList.add(model.nanoId);
              model.setNeedSave();
              break;
            }
          }
          if (!model.customId) {
            model.customId = model.drawingTexts.join(',');
          }
        }

        //model.customId = block._texts[0].text.text
        model.updateBoundingBox();
        if (currData.floor) {
          model.floor = currData.floor.nanoId;
        }
        model.needSave = true;
        preModels.push(model);
      }
      smCreator.modelCreator.currModelData = null;
      mirror.value = false;
      rotationY.value = 0;
      _updateBlocksModelTransform(true, true);
      //cancelBindingModel();
    };
    const bindingModel = function () {
      undoMap.push({
        cmd: 'create',
        items: preModels.map(x => {
          return {
            target: x
          };
        })
      });
      preModels.length = 0;
      cancelBindingModel();
    };
    const cancelBindingModel = function () {
      for (let preModel of preModels) {
        smDatas.remove(preModel);
      }
      globalState.isBindingModel = false;
      globalState.bindingModelBlocks = null;
      currComponent.value = null;
    };
    const updateItemProperty = (data, types) => {
      let type = types.split('.');
      if (type[0] === 'translation') {
        translation.value[type[1]] = data[0];
      }
      if (type[0] === 'rotation') {
        rotationY.value = data[0];
      }
      if (type[0] === 'setupSize') {
        setupSize.value[type[1]] = data[0];
        if (keepRatio.value && preModels.length) {
          let size = preModels[0].properties.originalBoundingInfo.size;
          let k = setupSize.value[type[1]] / (size[type[1]] || 1);
          setupSize.value.x = size.x * k;
          setupSize.value.y = size.y * k;
          setupSize.value.z = size.z * k;
        }
      }
      if (type[0] === 'mirrored') {
        mirror.value = data[0];
      }
      if (type[0] === 'keepRatio') {
        keepRatio.value = data[0];
        if (keepRatio.value && preModels.length) {
          let size = preModels[0].properties.originalBoundingInfo.size;
          let k = setupSize.value.x / (size.x || 1);
          setupSize.value.y = size.y * k;
          setupSize.value.z = size.z * k;
        }
      }
      if (globalState.isBindingModel) {
        _updateBlocksModelTransform(false);
      } else if (globalState.isReplacingModel) {
        updateReplaceModels();
      }
    };
    const updateReplaceModels = () => {
      let dragData = currComponent.value;
      if (!dragData || !preModels.length) {
        return;
      }
      let firstModel = preModels[0];
      let sizeScalingX = setupSize.value.x / Math.max(1, firstModel._oldSize[0]);
      let sizeScalingY = setupSize.value.y / Math.max(1, firstModel._oldSize[1]);
      let sizeScalingZ = setupSize.value.z / Math.max(1, firstModel._oldSize[2]);
      let rotationYByRadian = angleToRadian(rotationY.value + currDirection.value.value);
      for (let model of preModels) {
        if (model.modelId !== dragData.modelId) {
          let boundingInfo = model.properties.originalBoundingInfo;
          let oldBoundingInfo = model._oldOriginalBoundingInfo;
          let oldPosition = model._oldPosition;
          let oldScaling = model._oldScaling;
          let mirrored = !model._oldMirrored !== !mirror.value;
          model.rotation.y = model._oldRotationY + rotationYByRadian;
          model.scaling.x = Math.abs(model._oldSize[0] * sizeScalingX / (boundingInfo.size.x || 1)) * (mirrored ? -1 : 1);
          model.scaling.y = model._oldSize[1] * sizeScalingY / (boundingInfo.size.y || 1);
          model.scaling.z = model._oldSize[2] * sizeScalingZ / (boundingInfo.size.z || 1);
          let tx = translation.value.x;
          let tz = translation.value.z;
          if (model.rotation.y) {
            let cos = Math.cos(model.rotation.y);
            let sin = Math.sin(model.rotation.y);
            let tx_ = tx * cos + tz * sin;
            let tz_ = -tx * sin + tz * cos;
            tx = tx_;
            tz = tz_;
          }
          model.position.x = tx + oldPosition.x - ((boundingInfo.max.x + boundingInfo.min.x) * model.scaling.x - (oldBoundingInfo.max.x + oldBoundingInfo.min.x) * oldScaling.x) * 0.5;
          model.position.y = translation.value.y + oldPosition.y - ((boundingInfo.max.y + boundingInfo.min.y) * model.scaling.y - (oldBoundingInfo.max.y + oldBoundingInfo.min.y) * oldScaling.y) * 0.5;
          model.position.z = tz + oldPosition.z - ((boundingInfo.max.z + boundingInfo.min.z) * model.scaling.z - (oldBoundingInfo.max.z + oldBoundingInfo.min.z) * oldScaling.z) * 0.5;
          model.updateBoundingBox();
          model.makeDirty();
          model.setNeedSave();
        }
      }
    };
    const onReplacingMouseup = () => {
      let dragData = globalState.dragData;
      currComponent.value = dragData;
      if (dragData) {
        if (typeof dragData.boundingInfo === 'string') {
          dragData.boundingInfo = JSON.parse(dragData.boundingInfo);
        }
        if (!dragData.boundingInfo) {
          return;
        }
        let temp = dragData.boundingInfo;
        let ratio = dragData.ratio || 1000;
        let boundingInfo = {
          min: {
            x: temp[0] * ratio,
            y: temp[1] * ratio,
            z: temp[2] * ratio
          },
          max: {
            x: temp[3] * ratio,
            y: temp[4] * ratio,
            z: temp[5] * ratio
          },
          size: {
            x: (temp[3] - temp[0]) * ratio,
            y: (temp[4] - temp[1]) * ratio,
            z: (temp[5] - temp[2]) * ratio
          }
        };
        for (let model of preModels) {
          if (model.modelId !== dragData.modelId) {
            model.modelId = dragData.k3dcId;
            model.modelName = dragData.name;
            model.subType = dragData.subType;
            model.properties.originalBoundingInfo = boundingInfo;
          }
        }
        updateReplaceModels();
      }
    };
    const beginReplaceModels = () => {
      if (preModels.length) {
        return;
      }
      let currList = currData.finalCurrList;
      currList = Array.from(new Set(currList));
      preModels.length = 0;
      preModels.push(...currList);
      for (let model of currList) {
        model._oldModelId = model.modelId;
        model._oldModelName = model.modelName;
        model._oldSubType = model.subType;
        model._oldOriginalBoundingInfo = model.properties.originalBoundingInfo;
        model._oldBoundingBox = [...model.boundingBox];
        model._oldSize = [...model.size];
        model._oldMirrored = model.mirrored;
        model._oldPosition = model.position.clone();
        model._oldScaling = model.scaling.clone();
        model._oldRotationY = model.rotation.y;
      }
      setupSize.value.x = currList[0].size[0];
      setupSize.value.y = currList[0].size[1];
      setupSize.value.z = currList[0].size[2];
    };
    const confirmReplaceModels = isCancel => {
      globalState.isReplacingModel = false;
      currComponent.value = null;
      for (let model of preModels) {
        if (model._oldModelId && model._oldModelId !== model.modelId) {
          if (isCancel) {
            model.modelId = model._oldModelId;
            model.modelName = model._oldModelName;
            model.subType = model._oldSubType;
            model.properties.originalBoundingInfo = model._oldOriginalBoundingInfo;
            model.position.copyFrom(model._oldPosition);
            model.scaling.copyFrom(model._oldScaling);
            model.rotation.y = model._oldRotationY;
          }
          model.updateBoundingBox();
          model.makeDirty();
        }
        delete model._oldModelId;
        delete model._oldModelName;
        delete model._oldSubType;
        delete model._oldOriginalBoundingInfo;
        delete model._oldBoundingBox;
        delete model._oldSize;
        delete model._oldPosition;
        delete model._oldScaling;
        delete model._oldRotationY;
        delete model._oldMirrored;
      }
      preModels.length = 0;
    };
    watch(() => [globalState.isReplacingModel], () => {
      if (globalState.isReplacingModel) {
        translation.value = {
          x: 0,
          y: 0,
          z: 0
        };
        setupSize.value = {
          x: 0,
          y: 0,
          z: 0
        };
        rotationY.value = 0;
        mirror.value = false;
        keepRatio.value = false;
        beginReplaceModels();
      }
    });
    watch(() => [currData.currListFlag], () => {
      if (globalState.isReplacingModel) {
        confirmReplaceModels(true);
      }
    });
    watch(() => [selectedThemeId.value], () => {
      if (globalState.isReplacingModel) {
        confirmReplaceModels(true);
      }
    });
    const dialogConfirm = function () {
      if (globalState.isBindingModel) {
        bindingModel();
      } else if (globalState.isReplacingModel) {
        confirmReplaceModels();
      }
    };
    const dialogCancel = function () {
      if (globalState.isBindingModel) {
        cancelBindingModel();
      } else if (globalState.isReplacingModel) {
        confirmReplaceModels(true);
      }
    };
    let locale = computed(() => {
      return (window.Kf.locale.value || '').replace("-", "");
    });
    const getLabel = function (item) {
      if (!item) {
        return '';
      }
      let _name = item.name;
      if (item.labelInterpret) {
        if (typeof item.labelInterpret === 'string') {
          item.labelInterpret = JSON.parse(item.labelInterpret);
        }
        if (item.labelInterpret[locale.value]) {
          _name = item.labelInterpret[locale.value];
        }
      }
      return _name || '';
    };
    return {
      typeList,
      smDatas,
      globalState,
      propertyList,
      isShowContextMenu,
      contextMenuStyle,
      calibrateDrawingDialog,
      setCalibrateLength,
      handleTypeList,
      showContextMenu,
      getComponentType,
      propChanged,
      createFloor,
      doMenuClick,
      modelTab,
      handleModelTab,
      currModelTab,
      preModelsProxy,
      activeTab,
      compName,
      onBindingMouseup,
      onReplacingMouseup,
      getImageUrl,
      currComponent,
      dialogCancel,
      dialogConfirm,
      updateItemProperty,
      rotationY,
      translation,
      setupSize,
      mirror,
      keepRatio,
      directionList,
      currDirection,
      directionClick,
      getLabel,
      currType
    };
  }
};