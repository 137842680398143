import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1197191b"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "kf-panel-floors-box kingfisher_scroll scope-devicePoint-property-container"
};
const _hoisted_2 = {
  class: "kf-panel-floors-row"
};
const _hoisted_3 = {
  class: "kf-panel-floors-row-title"
};
const _hoisted_4 = {
  class: "kf-panel-floors-row-content-2 scope-disable"
};
const _hoisted_5 = {
  class: "kf-input-box"
};
const _hoisted_6 = ["value"];
const _hoisted_7 = {
  class: "kf-panel-floors-row"
};
const _hoisted_8 = {
  class: "kf-panel-floors-row-title"
};
const _hoisted_9 = {
  class: "kf-panel-floors-row-content-2 scope-disable"
};
const _hoisted_10 = {
  class: "kf-input-box"
};
const _hoisted_11 = ["value"];
const _hoisted_12 = {
  class: "kf-panel-floors-row"
};
const _hoisted_13 = {
  class: "kf-panel-floors-row-title"
};
const _hoisted_14 = {
  class: "kf-panel-floors-row-content-2 scope-disable"
};
const _hoisted_15 = {
  class: "kf-input-box"
};
const _hoisted_16 = ["value"];
const _hoisted_17 = {
  class: "kf-panel-floors-row"
};
const _hoisted_18 = {
  class: "kf-panel-floors-row-title"
};
const _hoisted_19 = {
  class: "kf-panel-floors-row-content-2 scope-disable"
};
const _hoisted_20 = {
  class: "kf-input-box"
};
const _hoisted_21 = ["value"];
import { currData } from "@/model/globalData";
export default {
  __name: 'devicePoint',
  setup(__props) {
    return (_ctx, _cache) => {
      return _unref(currData).devicePoint ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('Name')), 1), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("input", {
        type: "text",
        class: "kingfisher-standard-input",
        value: _unref(currData).devicePoint.deviceName,
        style: {
          "width": "294px"
        },
        onChange: _cache[0] || (_cache[0] = $event => _unref(currData).devicePoint.deviceName = $event.target.value)
      }, null, 40, _hoisted_6)])])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('DiagramEncoding')), 1), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_createElementVNode("input", {
        type: "text",
        class: "kingfisher-standard-input",
        value: _unref(currData).devicePoint.deviceMapId || '',
        style: {
          "width": "294px"
        },
        onChange: _cache[1] || (_cache[1] = $event => _unref(currData).devicePoint.deviceMapId = $event.target.value)
      }, null, 40, _hoisted_11)])])]), _createElementVNode("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('DeviceEncoding')), 1), _createElementVNode("div", _hoisted_14, [_createElementVNode("div", _hoisted_15, [_createElementVNode("input", {
        type: "text",
        class: "kingfisher-standard-input",
        value: _unref(currData).devicePoint.deviceId || '',
        style: {
          "width": "294px"
        },
        onChange: _cache[2] || (_cache[2] = $event => _unref(currData).devicePoint.deviceId = $event.target.value)
      }, null, 40, _hoisted_16)])])]), _createElementVNode("div", _hoisted_17, [_createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t('PointType')), 1), _createElementVNode("div", _hoisted_19, [_createElementVNode("div", _hoisted_20, [_createElementVNode("input", {
        type: "text",
        class: "kingfisher-standard-input",
        value: _unref(currData).devicePoint.deviceType,
        style: {
          "width": "294px"
        }
      }, null, 8, _hoisted_21)])])])])) : _createCommentVNode("", true);
    };
  }
};