import "core-js/modules/es.array.push.js";
import { ref } from 'vue';
import projectService from "../service/project";
import { ElMessage, ElMessageBox } from 'element-plus';
import userModel from "@/model/user";
import projectModel from "@/model/project";
// import messageModel from "@/components/ui/model/message";
import i18n from "@/i18n";
import spaceService from "../service/space";
let currMenu = ref({});
let currGroup = ref('');
let isProject = ref(false);

//我的文件，库，教程中的当前选中
let myProject = ref({});
let myProjectList = ref({});

//我的文件
let myFileData = ref({});
let myFileCategoryList = ref([]);

//库
let libraryData = ref({});
let libraryCategoryList = ref([]);
let currLibTab = ref({});
let newGroup = ref({});

//教程
let tutorialData = ref({});
let tutorialCategoryList = ref([]);
let currHeaderTab = ref({});
let currLeftContentName = ref(''); //我得文件夹下选中的category;项目分组下选中的项目名称
let currLeftContent = ref({}); //我得文件夹下选中的category对象;项目分组下选中的项目对象

let currUser = ref({});
let widgetLoading = ref(false);
let currOrder = ref({
  currOrderParam: 'updateDate',
  currOrderType: true
});
let locale = window.Kf.locale.value.replace("-", "");
const loadPart = function (project, type, flag, callback) {
  myProjectList.value = {};
  let param = {
    currMenuCode: currMenu.value.code,
    currHeaderTabCode: currHeaderTab.value.code
  };
  if (project !== null && project !== void 0 && project.uuid) {
    // projectService.getCategoryPart(project.uuid, param).then(result => {
    //     if (result) {
    //         /*  let defaultData={category:Kf.t('Default')}
    //           let currIndex=null
    //           result.some((item,index)=>{
    //               if(item?.category===Kf.t('Default')){
    //                   defaultData=Object.assign(item,defaultData);
    //                   currIndex=index;
    //                   return true
    //               }else{
    //                   return false
    //               }
    //           })
    //           if(currIndex!=null){
    //               result.splice(currIndex,1)
    //           }
    //           if(currLibTab.value?.code!=='official'){
    //               result.unshift(defaultData)
    //           }*/
    //         if (type === 'library') {
    //             libraryCategoryList.value = result;
    //         } else if (type === 'file') {
    //             myFileCategoryList.value = result;
    //         } else {
    //             myProjectList.value = result;
    //         }
    //         if (result?.length > 0 && (flag === undefined || flag === null)) {
    //             let currInfo = localStorage.getItem('currInfo') !== '' ? JSON.parse(localStorage.getItem('currInfo')) : {}
    //             if (currInfo?.currLeftContent?.category) {
    //                 currLeftContent.value = currInfo.currLeftContent
    //             } else if (!currLeftContent.value.category) {
    //                 currLeftContent.value = JSON.parse(JSON.stringify(result[0]))
    //             }
    //             localStorage.setItem('currInfo', '{}');
    //         }
    //         if (callback) {
    //             callback(result)
    //         }
    //     } else {
    //         ElMessage({type: "warning", message: Kf.t('PleaseContactTheAdministrator'), grouping: true})
    //     }
    // });
  }
};
const loadVideoData = function () {
  projectService.getVideoCategory().then(result => {
    if (result) {
      myProjectList.value = result;
      tutorialCategoryList.value = result;
      if ((result === null || result === void 0 ? void 0 : result.length) > 0) {
        currLeftContent.value = JSON.parse(JSON.stringify(result[0]));
      }
    } else {
      ElMessage({
        type: "warning",
        message: Kf.t('PleaseContactTheAdministrator'),
        grouping: true
      });
    }
  });
};

/*const loadMyProjectList=function (list){
    let arr=[...[],...list];
    if(currMenu.value.code=='library'){
        if(currHeaderTab.value.code=='widget'){
            arr.unshift({'category':'我添加的',showFlag:0,flag:2})
        }
        arr.unshift({'category':Kf.t('UploadedByMe'),showFlag:0,flag:1})
    }
    return arr;
}*/

const loadMyProject = function (code, callback) {
  myProject.value = {};
  if (currMenu.value.code === 'tutorial') {
    //教程暂时还没项目
    myProject.value = {
      uuid: 1
    };
    myProjectList.value = {};
    loadVideoData();
  } else if (currMenu.value.code === 'library') {
    //库
    loadMyProjectData(code, null, res => {
      callback(res);
    });
  } else if (currMenu.value.code === 'file') {
    //我的文件
    loadMyProjectData(code);
  } else {
    loadMyProjectData(code);
  }
};
const loadThemeOfficial = function (callback) {
  projectService.getDefaultProject('library').then(result => {
    if (callback) {
      callback(result);
    }
    if (result && result.uuid) {
      myProject.value = result;
      projectService.queryTronThemeListByProjectId(result.uuid, '', '', 1).then(res => {
        tronThemeList.value = res;
        loadTronThemeCategory(res);
      });
    }
  });
};
const loadMyProjectData = function (code, menu, callback) {
  let _menu = menu ? menu : currMenu.value;
  projectService.getDefaultProject(_menu.code).then(result => {
    if (result && result.uuid) {
      myProject.value = result;
      if (code === 0) {
        loadPart(result);
      }
    } else {
      ElMessage({
        type: "warning",
        message: Kf.t('PleaseContactTheAdministrator'),
        grouping: true
      });
    }
    if (callback) {
      callback(result);
    }
  });
};
const loadComponentLib = function () {
  myFileData.value = {};
  myFileCategoryList.value = {};
  projectService.getDefaultProject('file').then(result => {
    if (result && result.uuid) {
      myFileData.value = result;
      myFileCategoryList.value = {};
      loadPart(result, 'file', 1);
    } else {
      ElMessage({
        type: "warning",
        message: Kf.t('PleaseContactTheAdministrator'),
        grouping: true
      });
    }
  });
};
const loadFileData = function () {
  libraryCategoryList.value = {};
  libraryData.value = {};
  return projectService.getDefaultProject('library').then(result => {
    if (result && result.uuid) {
      libraryData.value = result;
      libraryCategoryList.value = {};
      loadPart(result, 'library', 1);
    } else {
      ElMessage({
        type: "warning",
        message: Kf.t('PleaseContactTheAdministrator'),
        grouping: true
      });
    }
  });
};
const loadCurrUser = function () {
  if (userModel.user.value === undefined || userModel.user.value.userName === undefined) {
    let user = JSON.parse(localStorage.getItem('KC_USER'));
    if (user === null) {
      router.replace({
        path: '/'
      });
    } else {
      userModel.updateUser(user);
    }
    currUser.value = user;
  } else {
    currUser.value = userModel.user.value;
  }
};
const getUuids = function (data) {
  let result = '';
  if ((data === null || data === void 0 ? void 0 : data.length) > 0) {
    data.forEach(item => {
      if (item.uuid) {
        result += result === '' ? item.uuid : ',' + item.uuid.toString();
      }
    });
  }
  return result;
};
const updateShowFlag = function (flag, msg) {
  var _currHeaderTab$value;
  //全部上架/下架
  let uuids = '';
  if (((_currHeaderTab$value = currHeaderTab.value) === null || _currHeaderTab$value === void 0 ? void 0 : _currHeaderTab$value.code) === 'scene') {
    uuids = getUuids(projectModel.sceneList.value);
  }
  projectService.updateFlag(flag, uuids, currHeaderTab.value.code).then(result => {
    if ((result === null || result === void 0 ? void 0 : result.code) === 200) {
      projectModel.switchProject(myProject.value, currLeftContent.value);
      ElMessage({
        type: "success",
        message: Kf.t('Already') + msg,
        grouping: true
      });
    } else {
      ElMessage({
        type: "warning",
        message: msg + Kf.t('FailPleaseContactAdministrator'),
        grouping: true
      });
    }
  });
};
const updatePubFlag = function (flag, msg) {
  var _currHeaderTab$value2;
  //全部添加/取消
  let uuids = '';
  if (((_currHeaderTab$value2 = currHeaderTab.value) === null || _currHeaderTab$value2 === void 0 ? void 0 : _currHeaderTab$value2.code) === 'scene') {
    uuids = getUuids(projectModel.sceneList.value);
  }
  projectService.updatePub(flag, uuids).then(result => {
    if ((result === null || result === void 0 ? void 0 : result.code) === 200) {
      projectModel.switchProject(myProject.value, currLeftContent.value);
      ElMessage({
        type: "success",
        message: Kf.t('Already') + msg,
        grouping: true
      });
    } else {
      ElMessage({
        type: "warning",
        message: msg + Kf.t('FailPleaseContactAdministrator'),
        grouping: true
      });
    }
  });
};
const showPublishLink = function (data, targetName) {
  //预览
  let origin = window.location.origin;
  let pathname = window.location.pathname;
  pathname = pathname.length > 1 ? pathname + "#" : "/#";
  let url = origin + pathname + `/${targetName}/${data.projectUuid}/${data.uuid}`;
  let t2 = localStorage.getItem("KC_TOKEN");
  let message2 = url + `/${t2}`;
  ElMessageBox.confirm(message2, Kf.t('JumpAddress'), {
    confirmButtonText: Kf.t('Jump'),
    cancelButtonText: Kf.t('Copy2'),
    cancelButtonClass: 'cancel-button',
    type: 'success ',
    draggable: true,
    customClass: 'kf-content-msg-confirm1'
  }).then(() => {
    window.open(message2);
  }).catch(() => {
    navigator.clipboard.writeText(message2);
  });
};
let currDragItem = ref({});
const doMoveToProject = function (uuid, projectId, editItem) {
  projectService.moveBuilding(uuid, projectId).then(res => {
    console.log('---moveBuilding', res);
    if (res == 'success') {
      ElMessage({
        type: "success",
        message: Kf.t('MoveSuccessfullyToViewUnderThisItem'),
        grouping: true
      });
      if (currMenu.value.code === 'file') {
        projectModel.switchProject(projectModel.currProject.value);
      } else {
        projectModel.switchProject(myProject.value, currLeftContent.value);
      }
    } else {
      ElMessage({
        type: "warning",
        message: Kf.t('MoveFailPleaseContactAdministrator'),
        grouping: true
      });
    }
  });
};
const moveToCategory = function (param) {
  projectService.updatePartInfo(param, currHeaderTab.value.code).then(result => {
    if ((result === null || result === void 0 ? void 0 : result.code) === 200) {
      ElMessage({
        type: "success",
        message: Kf.t('MovedSuccessfullyTo') + param.category + Kf.t('Down'),
        grouping: true
      });
      projectModel.switchProject(myProject.value, currLeftContent.value);
    } else {
      if ((result === null || result === void 0 ? void 0 : result.status) === '406') {
        ElMessage({
          type: "error",
          message: Kf.t('UserSRemainingSpaceIsNotEnough'),
          grouping: true
        });
      } else {
        ElMessage({
          type: "warning",
          message: Kf.t('UpdateFailPleaseContactAdministrator'),
          grouping: true
        });
      }
    }
  });
};
const getHost = function () {
  let res = '';
  let url = window.location.href;
  const str = url.substr(url.indexOf("://") + 3);
  const firstTwoSlashIndex = str.indexOf('/', str.indexOf('/') + 1); // 获取第二个斜杠的索引
  res = window.location.origin + str.slice(str.indexOf('/'), firstTwoSlashIndex);
  if ('development' === process.env.NODE_ENV) {
    res = 'http://dev.kingfisher.live:8000/cloud';
  }
  return res;
};
const isGMT = function (time) {
  var date = new Date(time);
  return date.getUTCHours() === 0;
};
const timeChange = function (time) {
  if (!isGMT(time)) {
    return time;
  }
  // 创建一个 Date 对象，传入 UTC 时间字符串
  const date = new Date(time);
  // 获取 UTC 时间的时间戳，并加上 8 小时的毫秒数
  const beijingTime = new Date(date.getTime() + 8 * 3600 * 1000);

  // 格式化为 YYYY-MM-DD HH:MM:SS
  return beijingTime.getFullYear() + "-" + String(beijingTime.getMonth() + 1).padStart(2, '0') + "-" + String(beijingTime.getDate()).padStart(2, '0') + " " + String(beijingTime.getHours()).padStart(2, '0') + ":" + String(beijingTime.getMinutes()).padStart(2, '0') + ":" + String(beijingTime.getSeconds()).padStart(2, '0');
};
const getEditTime = function (item) {
  if (currMenu.value.code === 'tutorial' && item.time !== undefined) {
    return item.time;
  }
  if (item.updateTime == null && item.updateDate == null) {
    return Kf.t('NoEditingAtPresent');
  }
  let prefixStr = Kf.t('EditIn');
  let str = prefixStr;
  let _time = item.updateTime;
  /* if (_time === null || _time === undefined || _time === '') {
       _time = timeChange(item.updateDate)
   }*/
  _time = timeChange(item.updateDate);
  if (_time) {
    let _year1 = new Date(_time).getFullYear();
    let _year2 = new Date().getFullYear();
    let _year3 = _year2 - _year1;
    if (_year3 > 0) {
      str = _year3 + Kf.t('PriorTo');
    } else {
      let currTime = new Date().getTime();
      let time1 = new Date(_time).getTime();
      let total = (currTime - time1) / 1000;
      let day = parseInt(total / (24 * 60 * 60));
      if (day > 0) {
        str = day + Kf.t('DaysAgo');
      } else {
        let afterDay = total - day * 24 * 60 * 60;
        let hour = parseInt(afterDay / (60 * 60));
        if (hour > 0) {
          str = hour + Kf.t('AnHourAgo');
        } else {
          let afterHour = total - day * 24 * 60 * 60 - hour * 60 * 60;
          let min = parseInt(afterHour / 60);
          if (min > 0) {
            str = min + Kf.t('#MinutesAgo');
          } else {
            str = Kf.t('TenSecondsAgo');
          }
        }
      }
    }
  }
  return prefixStr + str;
};
/**
 * START
 * 工创5(tronTheme) 主题相关逻辑
 */

export let tronThemeList = ref([]);
export let isTronThemeDetails = ref(false);
export let currTronTheme = ref(); // 当前选中的 tron theme
export let tronThemeCategoryList = ref([]); // category 列表
export let tronComList = ref([]); // 全部组件列表
export let currPart = ref(null); // 当前选中的 组件part
export let currCatgory = ref(null); // 当前选中的 category 组件分类

export let comClassifyList = ref([]); // 分类 part list

const loadTronThemeCategory = function (list) {
  tronThemeCategoryList.value = [];
  if ((list === null || list === void 0 ? void 0 : list.length) > 0) {
    let _data = {};
    list.forEach(item => {
      if (item !== null && item !== void 0 && item.category && _data[item.category] === undefined) {
        tronThemeCategoryList.value.push({
          label: item.category,
          value: item.category
        });
      }
    });
  }
};

// 加载 tron theme 列表
export const loadTronThemeListByProId = function (projectId, groups, type = "", systemFlag, callback) {
  projectService.queryTronThemeListByProjectId(projectId, groups, type, systemFlag).then(res => {
    tronThemeList.value = res;
    loadTronThemeCategory(res);
    if (callback) {
      callback(res);
    }
  });
};

// 创建 空间、组态资产 文件
export const createTronThemeAssets = function (data, systemFlag, callback) {
  projectService.createTronTheme(data, systemFlag).then(res => {
    ElMessage.success(Kf.t('CreateSuccessful'));
    callback && callback();
  });
};

// 更新主题信息
export const setTronThemeAssets = function (theme, callback) {
  if (!theme) return;
  projectService.updateTronThemeInfo(theme).then(res => {
    if (callback) {
      callback(res);
    }
    //ElMessage.success(Kf.t('UpdateSuccessful'));
  });
};
export const moveTronThemeAssets = function (theme) {};

// 删除 空间、组态资产 主题 文件
export const deleteTronThemeAssets = function (theme) {
  if (!theme) return;
  let uuid = theme.uuid;
  ElMessageBox.confirm(Kf.t("DeleteItAndFindItInTheWastebin"), Kf.t('Hint'), {
    confirmButtonText: Kf.t('Confirm'),
    cancelButtonText: Kf.t('Cancel'),
    type: "warning",
    draggable: true,
    customClass: "kf-content-msg-confirm"
  }).then(() => {
    projectService.deleteTronThemeByUuid(uuid).then(res => {
      if (res === 'success') {
        const targetIndex = tronThemeList.value.findIndex(item => item.uuid === uuid);
        if (targetIndex !== -1) {
          tronThemeList.value.splice(targetIndex, 1);
          ElMessage.success(Kf.t('DeleteSuccess'));
        }
      } else {
        ElMessage.error(res);
      }
    });
  }).catch(() => {});
};

/**
 * 获取 组件列表基于theme
 * @param {*} projectId
 * @param {*} themeId
 * @param {*} systemFlag
 */

export const queryPartListByTheme = function (projectId, themeId, systemFlag) {
  if (!systemFlag) {
    systemFlag = currLibTab.value.code === 'userContent' ? 2 : 1;
  }
  comClassifyList.value = [];
  tronComList.value = [];
  const type = currTronTheme.value.type;
  widgetLoading.value = true;
  if (type === "space") {
    projectService.querySpaceListTheme(projectId, themeId, systemFlag).then(res => {
      widgetLoading.value = false;
      tronComList.value = res;
      classifyPartList(res);
    });
  } else if (type === "configuration") {
    projectService.queryPartListTheme(projectId, themeId, systemFlag).then(res => {
      widgetLoading.value = false;
      tronComList.value = res.data;
      classifyPartList(res.data);
    });
  }
};
const queryListByTheme = function (projectId, themeId, systemFlag, type, callback) {
  if (type === "space") {
    projectService.querySpaceListTheme(projectId, themeId, systemFlag).then(res => {
      if (callback) {
        callback(res);
      }
    });
  } else if (type === "configuration") {
    return projectService.queryPartListTheme(projectId, themeId, systemFlag).then(res => {
      if (callback) {
        callback(res.data);
      }
    });
  } else {
    if (callback) {
      callback([]);
    }
  }
};
const loadComponents = function (currProjectId, themeId, systemFlag, callback) {
  projectService.queryPartListTheme(currProjectId, themeId, systemFlag).then(res => {
    if (callback) {
      callback(res);
    }
  });
};
const getCategory = function (item) {
  let _category = item.category;
  if (item.languageInterpret && typeof item.languageInterpret === 'string') {
    var _data$category;
    let _data = JSON.parse(item.languageInterpret);
    if (_data !== null && _data !== void 0 && (_data$category = _data.category) !== null && _data$category !== void 0 && _data$category[locale]) {
      _category = _data.category[locale];
    }
  }
  return _category;
};

// 处理组件列表进行分类
const classifyPartList = function (list) {
  if (!list || !Array.isArray(list)) return;
  let tempPart = {},
    index = 0;
  comClassifyList.value.length = 0;
  tronThemeCategoryList.value = [];
  for (let i = 0; i < list.length; i++) {
    var _list$i, _list$i2, _list$i3;
    if (!(((_list$i = list[i]) === null || _list$i === void 0 ? void 0 : _list$i.attribute) === '_misc' || ((_list$i2 = list[i]) === null || _list$i2 === void 0 ? void 0 : _list$i2.attribute) === '_pipeline' || ((_list$i3 = list[i]) === null || _list$i3 === void 0 ? void 0 : _list$i3.attribute) === '_area')) {
      const part = list[i];
      part._name = getComName(list[i]);
      part.isChecked = false;
      let _category = getCategory(part);
      if (Reflect.has(tempPart, _category)) {
        tempPart[_category].push(part);
      } else {
        tempPart[_category] = [part];
      }
    }
  }
  for (const key in tempPart) {
    comClassifyList.value[index] = {
      category: key,
      list: tempPart[key]
    };
    index++;
    tronThemeCategoryList.value.push({
      label: key,
      value: key
    });
  }
};
const copyTronTheme = function (item, group, systemFlag, callback) {
  if (item.uuid) {
    projectService.copyTronTheme(item.uuid, group, systemFlag).then(res => {
      if (res === 'success') {
        ElMessage({
          type: "success",
          message: Kf.t('CopySuccessful'),
          grouping: true
        });
      } else {
        ElMessage({
          type: "error",
          message: Kf.t('OperateFailPleaseContactAdministrator'),
          grouping: true
        });
      }
      if (callback) {
        callback(res);
      }
    });
  }
};
const copyPartTronTheme = function (tronThemeId, uuids, category, systemFlag, callback) {
  if (tronThemeId !== null) {
    projectService.copyPartTronTheme(tronThemeId, uuids, category, systemFlag).then(res => {
      if (res.code == 200) {
        ElMessage({
          type: "success",
          message: Kf.t('CopySuccessful'),
          grouping: true
        });
      } else {
        ElMessage({
          type: "error",
          message: res.message,
          grouping: true
        });
      }
      if (callback) {
        callback(res);
      }
    });
  }
};

//库中主题下的组件删除
const deletePart = function (projectId, uuid, flag, tronThemeId, systemFlag, callback) {
  if (!projectId || !uuid) {
    return;
  }
  ElMessageBox.confirm(Kf.t("ConfirmTheDeletion"), Kf.t('Hint'), {
    confirmButtonText: Kf.t('Confirm'),
    cancelButtonText: Kf.t('Cancel'),
    type: "warning",
    draggable: true,
    customClass: "kf-content-msg-confirm"
  }).then(() => {
    projectService.deteleParts(projectId, uuid, flag, tronThemeId, systemFlag).then(res => {
      if (callback) {
        callback(res);
      }
    });
  }).catch(() => {});
};
const loadThemeByType = function (projectId, type, systemFlag, callback) {
  if (!projectId || !type) {
    return;
  }
  projectService.queryTronThemeListByProjectId(projectId, null, type, systemFlag).then(res => {
    if (callback) {
      callback(res);
    }
  });
};
const deleteThemeGroup = function (item, callback) {
  if (item !== null && item !== void 0 && item.category) {
    let _systemFlag = currLibTab.value.code === 'userContent' ? 2 : 1;
    ElMessageBox.confirm(Kf.t("DeleteItAndFindItInTheWastebin"), Kf.t('Hint'), {
      confirmButtonText: Kf.t('Confirm'),
      cancelButtonText: Kf.t('Cancel'),
      type: "warning",
      draggable: true,
      customClass: "kf-content-msg-confirm"
    }).then(() => {
      projectService.deleteThemeGroup(item.category, _systemFlag).then(res => {
        if (callback) {
          callback(res);
        }
      });
    }).catch(() => {});
  }
};
const renameThemeGroup = function (item, callback) {
  if (item !== null && item !== void 0 && item.category) {
    ElMessageBox.prompt(Kf.t('Name'), Kf.t('Rename'), {
      confirmButtonText: Kf.t('Confirm'),
      cancelButtonText: Kf.t('Cancel'),
      draggable: true,
      customClass: 'kf-artist-confirm-box2',
      inputValue: item.category
      // inputValidator: (val) => {
      //     if (val == null || val == '') {
      //         return messageModel.messageTip.noName
      //     }
      // },
    }).then(({
      value
    }) => {
      if (value && value != '') {
        doRenameThemeGroup(item.category, value, res => {
          if (callback) {
            callback(res, value);
          }
        });
      }
    }).catch(() => {});
  }
};
const doRenameThemeGroup = function (oldName, newName, callback) {
  let _systemFlag = currLibTab.value.code === 'userContent' ? 2 : 1;
  projectService.updateThemeGroup(oldName, newName, _systemFlag).then(res => {
    if (callback) {
      callback(res);
    }
  });
};
const renameWidgetCategory = function (item, callback) {
  var _item$list, _currTronTheme$value;
  if (item !== null && item !== void 0 && item.category && (item === null || item === void 0 || (_item$list = item.list) === null || _item$list === void 0 ? void 0 : _item$list.length) > 0 && (_currTronTheme$value = currTronTheme.value) !== null && _currTronTheme$value !== void 0 && _currTronTheme$value.uuid) {
    ElMessageBox.prompt(Kf.t('Name'), Kf.t('Rename'), {
      confirmButtonText: Kf.t('Confirm'),
      cancelButtonText: Kf.t('Cancel'),
      draggable: true,
      customClass: 'kf-artist-confirm-box2',
      inputValue: item.category
      // inputValidator: (val) => {
      //     if (val == null || val == '') {
      //         return messageModel.messageTip.noName
      //     }
      // },
    }).then(({
      value
    }) => {
      let _uuids = getUuids(item.list);
      if (value && value != '') {
        doRenameWidgetCategory(_uuids, value, currTronTheme.value.uuid, res => {
          if (callback) {
            callback(res, value);
          }
        });
      }
    }).catch(() => {});
  }
};
const doRenameWidgetCategory = function (uuids, category, themeId, callback) {
  let _systemFlag = currLibTab.value.code === 'userContent' ? 2 : 1;
  projectService.updateCategoryByUuids(uuids, category, _systemFlag, themeId).then(res => {
    if (callback) {
      callback(res);
    }
  });
};
const updateWidgetFile = function (file, uuid, callback) {
  // partService.updateWidget(file, uuid, projectModel.currProject.value.uuid).then(res => {
  //     if (callback) {
  //         callback(res)
  //     }
  // })
};
let themeGroup = ref({});
const loadThemeGroups = function (project) {
  themeGroup.value = {};
  let systemFlagList = [1, 2];
  systemFlagList.forEach(item => {
    projectService.queryTronThemeGroups(project.uuid, item).then(res => {
      themeGroup.value[item] = res;
    });
  });
};

/**
 * END
 * 工创5(tronTheme) 主题相关逻辑
 */

let librarySpaceList = ref([]);
let librarySpaceCategoryList = ref({});
const getComName = function (item) {
  let name = item.name;
  if (item.labelInterpret && item.labelInterpret !== 'null') {
    if (typeof item.labelInterpret === 'string') {
      try {
        item.labelInterpret = JSON.parse(item.labelInterpret);
      } catch (e) {
        console.log(e);
      }
    }
    name = item.labelInterpret[locale] || name;
  }
  return name;
};
const loadCategory = function (project) {
  librarySpaceList.value = [];
  projectModel.loadingData.value = true;
  currHeaderTab.value.code = 'space';
  if (project !== null && project !== void 0 && project.uuid) {
    spaceService.getSpacePart(project.uuid).then(res => {
      /* res.map(item =>{
           if (item.labelInterpret && typeof item.labelInterpret === "string") {
               let _label=JSON.parse(item.labelInterpret)
               item.name = _label[locale] ? _label[locale] : item.name;
           }
       })*/
      librarySpaceCategoryList.value = getCategoryByWidgetList(res);
      librarySpaceList.value = res;
      projectModel.loadingData.value = false;
    });
  }
};
const getCategoryByWidgetList = function (list) {
  let obj = {};
  if ((list === null || list === void 0 ? void 0 : list.length) > 0) {
    list.forEach(item => {
      if ((item === null || item === void 0 ? void 0 : item.attribute) !== '_misc' && (item === null || item === void 0 ? void 0 : item.attribute) !== '_pipeline' && (item === null || item === void 0 ? void 0 : item.attribute) !== '_area') {
        let _item = item;
        _item.isChecked = false;
        _item._name = getComName(item);
        let _category = getCategory(item);
        if (obj[_category]) {
          obj[_category].push(_item);
        } else {
          obj[_category] = [_item];
        }
      }
    });
  }
  return obj;
};
let nameLimitLength = 50; //50个字节
const checkNameLength = function (name, length) {
  let _length = nameLimitLength;
  if (length) {
    _length = length;
  }
  const byteLength = new TextEncoder().encode(name).length;
  if (byteLength <= _length) {
    return true;
  } else {
    return false;
  }
};
export default {
  currMenu,
  currLibTab,
  currGroup,
  loadMyProject,
  loadThemeOfficial,
  myProject,
  isProject,
  loadPart,
  myProjectList,
  currHeaderTab,
  currLeftContentName,
  currLeftContent,
  loadComponentLib,
  loadFileData,
  libraryData,
  libraryCategoryList,
  myFileData,
  myFileCategoryList,
  loadCurrUser,
  currUser,
  updateShowFlag,
  updatePubFlag,
  showPublishLink,
  currDragItem,
  doMoveToProject,
  moveToCategory,
  currOrder,
  newGroup,
  getHost,
  tronComList,
  getEditTime,
  getComName,
  getUuids,
  copyTronTheme,
  copyPartTronTheme,
  deletePart,
  loadThemeByType,
  deleteThemeGroup,
  renameThemeGroup,
  doRenameThemeGroup,
  renameWidgetCategory,
  loadMyProjectData,
  loadComponents,
  getCategory,
  updateWidgetFile,
  loadThemeGroups,
  themeGroup,
  classifyPartList,
  queryListByTheme,
  widgetLoading,
  timeChange,
  loadCategory,
  librarySpaceList,
  getCategoryByWidgetList,
  checkNameLength
};