import {currData, globalState, modeName} from "@/model/globalData";
import {each} from "@/utils/tools";
import {undoMap} from "@/undo/undoMap";
import {smDatas} from "@/model/smCollectionData";
import {ElMessage, ElMessageBox} from 'element-plus'

export const deleteSceneObj = function (item={}) {
    let name = modeName[globalState.mode];
    name = name === Kf.t('Scene') ? Kf.t('Model') : name;

    let currSegment = null;
    let currList = currData.finalCurrList;
    if (globalState.mode === 'wall' && currData.segment && currList.find(x => x.nanoId === currData.segment.wall)) {
        currSegment = currData.segment;
        currList = [];
        name = Kf.t('WallSegment');
    } else {
        currList = Array.from(new Set(currList));
        name = `${currList.length}` + Kf.t('Single') + `${name}`
    }

    if (!currList.length && !currSegment) {
        return;
    }

    let str = Kf.t('Delete2') + `${name}?`
    ElMessageBox.confirm(
        str,
        Kf.t('ConfirmDeletetion'),
        {
            confirmButtonText: Kf.t('ConfirmDeletetion'),
            cancelButtonText: Kf.t('CancelDeletion'),
            type: 'warning',
        }).then(() => {
        if (currSegment) {
            let wall = smCollection.objects[currSegment.wall];
            if (wall) {
                wall.setAccessoriesNeedSave();
                wall.removeSegment(currSegment);
                wall.setNeedSave();
                currSegment.dispose();
            }
            currData.segment = null;
        } else {
            let targets = currList.filter(x => !x.isDeleted);
            if (targets.length) {
                undoMap.push({
                    cmd: 'delete',
                    items: targets.map(x => {return {target: x, value: x.serialize()}}),
                });
            }
            each(currList, target => {
                target.isDeleted = true;
                smDatas.unBindModelAndDevicePoint(target.nanoId);
                if (target.type === 'floor') {
                    if (item.flag === 'onlyFloor') {
                        console.log(smDatas.objects);
                        for (let key in smDatas.objects) {
                            let temp = smDatas.objects[key];
                            if (temp.floor === target.nanoId) {
                                temp.floor = null;
                                temp.setNeedSave();
                            }
                        }
                    } else {
                        for (let key in smDatas.objects) {
                            if (smDatas.objects[key].floor === target.nanoId) {
                                smDatas.objects[key].isDeleted = true;
                            }
                        }
                    }
                }
            })
            currData[globalState.mode] = null;
            currData.currList = [];
            currData.currListFlag++;
        }
    }).catch((e) => {
        console.error(e);
        ElMessage({
            type: 'info',
            message: Kf.t('CancelDeletion2'),group:true
        })
    })
}

let _copyFun = {
    copies:[],
    copy:function (){
        this.copies = [];
        each(currData.currList, target => {
            this.copies.push(target);
        })
        console.log(Kf.t('Copy2'));
    },
    paste:function (){
        this.clone(this.copies,Kf.t('CopySuccessful'), Kf.t('FailedToCopy'));
    },
    clone:function (targets=currData.currList,success=Kf.t('CloningSuccessful'),error=Kf.t('CloningFailed')) {
        let copies = [];
        let target;
        let fail = false;
        each(targets, obj => {
            target = smDatas.cloneObject(obj);
            if (!target) {
                fail = true;
                return -1;
            }
            target.position.x += 350;
            target.position.z -= 200;
            target.makeDirty();
            target.needSave = true;
            copies.push(target)
        })
        if (fail) {
            each(copies, hasCreatedObj => {
                hasCreatedObj.isDeleted = true;
            })
            ElMessage({type: 'error', message:error,group:true});
        } else if(targets.length > 0){
            currData.setCurrList(copies);
            ElMessage({type: 'success', message: success});
        }else{
            ElMessage({type: 'info', message: Kf.t('NoObjectsCoped')});
        }
    }
}

export const copySceneObj= function (){
    _copyFun.copy();
}
export const pasteSceneObj= function (){
    _copyFun.paste();
}
export const cloneCurrList = function () {
    _copyFun.clone();
}

window.deleteSceneObj = deleteSceneObj;