export default {
  name: "numInput",
  props: {
    label: {
      required: false
    },
    title: {
      required: false
    },
    description: {
      required: false
    },
    propValue: {
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: ""
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    formatter: {
      type: Function,
      required: false,
      default: a => a
    },
    validator: {
      type: Function,
      required: false,
      default: _ => true
    },
    isNumber: {
      type: Boolean,
      required: false,
      default: false
    },
    step: {
      type: Number,
      required: false,
      default: 1
    },
    max: {
      type: Number,
      required: false,
      default: 100000000
    },
    min: {
      type: Number,
      required: false,
      default: -100000000
    },
    precision: {
      type: Number,
      required: false,
      default: 3
    },
    unit: {
      type: String,
      required: false,
      default: ""
    },
    mode: {
      type: String,
      required: false,
      default: ''
    },
    color: {
      type: String,
      required: false,
      default: "#E4E4E4"
    }
  },
  emits: ['change'],
  data: function () {
    return {
      keying: false,
      editing: false,
      defValue: "",
      buffer: ""
    };
  },
  computed: {
    value: {
      get: function () {
        if (this.editing) return this.buffer;
        return this.formatter(this.propValue);
      },
      set: function (val) {
        this.editing = true;
        if (isNaN(parseInt(val))) {
          val = 0;
        }
        this.buffer = val;
      }
    },
    modelType() {
      return this.keying || !this.isNumber;
    }
  },
  methods: {
    change: function (val) {
      if (this.validator(val)) {
        let currVal = val;
        if (this.mode && this.mode === "percent") {
          currVal = val + '%';
        }
        if (this.mode && this.mode === "radian") {
          currVal = val + '°';
        }
        this.$emit("change", currVal);
      } else {
        this.$notify.error({
          title: Kf.t('InvalidInput'),
          message: `"${val}"`
        });
        this.editing = false;
      }
    },
    input: function (val) {
      this.value = val;
    },
    inputEdit(event) {
      if (!window.event.altKey && !window.event.ctrlKey) {
        event.stopPropagation();
        this.keying = true;
        const _ip = this.$refs.ip;
        _ip.focus();
      }
    },
    getInputFocus(e) {
      e.currentTarget.select();
    },
    getInputEnter(event) {
      event.currentTarget.blur();
    },
    moveEvent(event) {
      event.stopPropagation();
      if (this.disabled) {
        return;
      }
      const _x = event.clientX;
      const _v = this.value;
      let changed = false;
      document.onmousemove = e => {
        if (e.clientX === event.clientX && !changed) {
          return;
        }
        changed = true;
        const _alt = e.altKey;
        const _ctrl = e.ctrlKey;
        let _size = _alt ? this.step * 10 : _ctrl ? this.step * 5 : this.step;
        let stepStr = this.step.toString();
        const _precision = stepStr.indexOf('.') === -1 ? 0 : stepStr.length - stepStr.indexOf('.') - 1;
        const _m_x = e.clientX;
        const _step = parseFloat(((_m_x - _x) / 10 * _size).toFixed(_precision));
        let _m_v = (parseFloat(_v) + _step).toFixed(this.precision);
        if (_m_v > this.max) {
          _m_v = this.max;
        }
        if (_m_v < this.min) {
          _m_v = this.min;
        }
        this.change(_m_v + this.unit);
      };
      document.onmouseup = () => {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    lostFocus() {
      let value = parseFloat(this.value).toFixed(this.precision);
      if (value > this.max) {
        value = this.max;
      }
      if (value < this.min) {
        value = this.min;
      }
      this.change(value + this.unit);
      this.keying = false;
    }
  },
  watch: {
    propValue: function (newVal, _UNUSED_ARG_oldVal) {
      this.buffer = newVal;
      this.editing = false;
    }
  },
  mounted() {
    this.defValue = this.value;
  }
};