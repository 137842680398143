import { ref, watch } from 'vue';
import pageModel from "@/model/page";
export default {
  name: "enumProp",
  props: {
    props: {
      type: Object,
      default: () => {
        return {};
      }
    },
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  setup(props, {
    emit
  }) {
    let getValue = function () {
      let value = pageModel.getPropValue(props.model, props.props.name);
      return {
        selectValue: value ? value.label || value.name || '' : ''
      };
    };
    let valueObj = ref(getValue());

    //watch property
    watch(props, (newValue, oldValue) => {
      valueObj.value = getValue();
    });
    const doSelectChange = function (val) {
      emit("propChange", {
        name: props.props.name,
        value: val
      });
    };
    return {
      valueObj,
      doSelectChange
    };
  }
};