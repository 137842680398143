import "core-js/modules/es.array.push.js";
import { watch } from 'vue';
import ImageDrawingBuilder from "./smImageDrawingBuilder";
import DxfDrawingBuilder from "./smDxfDrawingBuilder";
import SMWall2DBuilder from "./smWall2DBuilder";
import Area2DBuilder from "./smArea2DBuilder";
import SMPipeline2DBuilder from "./smPipeline2DBuilder";
import Construct2DBuilder from "./smConstruct2DBuilder";
import Model2DBuilder from "./smMode2DBuilder";
import { globalState, currData } from "@/model/globalData";
import { compInited, selectedThemeId } from "@/model/3Dmodel";
class SmMesh2DBuilder {
  constructor(smCollection, scene) {
    this.scene = scene;
    this.smCollection = smCollection;
    this.objects = {};
    this._dirtyObjects = {};
    this.currList = {};
    for (let i = 0; i < smCollection.floors; i++) {
      this.add(smCollection.floors[i]);
    }
    for (let i = 0; i < smCollection.drawings; i++) {
      this.add(smCollection.drawings[i]);
    }
    for (let i = 0; i < smCollection.walls; i++) {
      this.add(smCollection.walls[i]);
    }
    for (let i = 0; i < smCollection.areas; i++) {
      this.add(smCollection.areas[i]);
    }
    for (let i = 0; i < smCollection.pipelines; i++) {
      this.add(smCollection.pipelines[i]);
    }
    for (let i = 0; i < smCollection.models; i++) {
      this.add(smCollection.models[i]);
    }
    smCollection.addCallbacks.push(obj => {
      this.add(obj);
    });
    smCollection.removeCallbacks.push(obj => {
      this.remove(obj);
    });
    smCollection.updateCallbacks.push(obj => {
      this.update(obj);
    });
    watch(() => currData.currListFlag, (n, o) => {
      this.setCurrList(globalState.mode === 'devicePoint' ? Object.values(currData.models) : currData.finalCurrList);
    });
    watch(() => currData.segments, (n, o) => {
      this.scene.setSelectedLine();
    });
    watch(() => currData.points, (n, o) => {
      this.scene.setSelectedPoint();
    });
    watch(() => globalState.mode, (n, o) => {
      let mode = globalState.mode;
      if (mode == 'devicePoint') {
        mode = 'model';
      }
      for (let root of [scene._modelRoot, scene._wallRoot, scene._areaRoot, scene._pipelineRoot, scene._drawingRoot]) {
        root.visibility = mode === 'floor' || root.name === mode ? 1 : 0.85;
      }
      this.updateSelectPointAndSegment();
    });
    watch(() => globalState.drawingMode, (n, o) => {
      let mode = globalState.drawingMode;
      let currList = this.currList;
      for (let key in currList) {
        let obj = currList[key];
        if (obj.obj.finalVisible && obj.inited && obj.setSelected) {
          obj.setSelected(currList[key], mode);
        }
      }
      this.updateSelectPointAndSegment();
    });
    watch(() => globalState.operation, (n, o) => {
      this.updateSelectPointAndSegment();
    });
    watch(() => globalState.enableDrawingView, () => {
      for (let drawing of smCollection.drawings) {
        this.update(drawing);
      }
    });
    watch(() => selectedThemeId.value, (n, o) => {
      for (let key in this.objects) {
        let obj = this.objects[key];
        if (obj.obj.type === 'model') {
          this._dirtyObjects[key] = obj;
          if (obj.dispose) {
            obj.dispose();
          }
        }
      }
    });
    watch(() => globalState.dxfSelected.blockName, () => {
      for (let drawing of smCollection.drawings) {
        if (drawing.type === 'dxfDrawing') {
          this.update(drawing);
        }
      }
    });
    watch(() => [globalState.typeViews.model, globalState.typeViews.wall, globalState.typeViews.area, globalState.typeViews.pipeline], () => {
      for (let root of [scene._modelRoot, scene._wallRoot, scene._areaRoot, scene._pipelineRoot]) {
        root.enableState = globalState.typeViews[root.name];
      }
    });
  }
  add(obj) {
    if (!obj) {
      return;
    }
    this.objects[obj.nanoId] = {
      obj: obj,
      scene: this.scene
    };
    this._dirtyObjects[obj.nanoId] = this.objects[obj.nanoId];
  }
  remove(obj) {
    if (!obj) {
      return;
    }
    if (this.objects[obj.nanoId]) {
      if (this.objects[obj.nanoId].dispose) {
        this.objects[obj.nanoId].dispose();
      }
      delete this.objects[obj.nanoId];
      delete this._dirtyObjects[obj.nanoId];
      if (this.currList[obj.nanoId]) {
        obj.selected = false;
        if (obj.setSelected) {
          obj.setSelected(false);
        } else if (obj.highlightMeshes) {
          let highlightLayer = this.scene.highlightLayer;
          for (let i = 0; i < obj.highlightMeshes.length; i++) {
            highlightLayer.removeMesh(obj.highlightMeshes[i]);
          }
        }
        delete this.currList[obj.nanoId];
      }
    }
  }
  update(obj) {
    if (!obj || !this.objects[obj.nanoId]) {
      return;
    }
    this._dirtyObjects[obj.nanoId] = this.objects[obj.nanoId];
  }
  updateSelectPointAndSegment() {
    this.scene.setSelectedLine();
    this.scene.setSelectedPoint();
  }

  // _pointToModels(curr) {
  //     if (curr && curr.obj.type === 'devicePoint') {
  //         return (Array.from(curr.obj.deviceModelList || [])).map(x => this.objects[x]).filter(x => x);
  //     }
  //     return [curr];
  // }

  setCurrList(objs) {
    let highlightLayer = this.scene.highlightLayer;
    highlightLayer.removeAllMeshes();
    for (let key in this.currList) {
      let obj = this.currList[key];
      if (obj.setSelected) {
        obj.setSelected(false);
      }
    }
    let mode = globalState.drawingMode;
    this.currList = {};
    for (let i = 0; objs && i < objs.length; i++) {
      if (!objs[i]) {
        continue;
      }
      let key = objs[i].nanoId;
      let obj = this.objects[key];
      if (obj) {
        let models = [obj]; //this._pointToModels(obj);
        for (let m of models) {
          if (m.obj.finalVisible && m.inited) {
            if (m.setSelected) {
              m.setSelected(true, mode);
            }
            if (m.highlightMeshes) {
              for (let i = 0; i < m.highlightMeshes.length; i++) {
                highlightLayer.addMesh(m.highlightMeshes[i], this.scene.hightLightColor);
              }
            }
          }
          this.currList[m.obj.nanoId] = m;
        }
        obj.selected = true;
      }
    }
  }

  // forceSelect() {
  //     let currList = this.currList;
  //     for (let key in currList) {
  //         let obj = currList[key];
  //         if (obj.setSelected) {
  //             obj.setSelected(true, true);
  //         }
  //     }
  // }

  focusOnObject(args = {
    ratio: 0.6
  }) {
    let currObjects = [];
    for (let key in this.currList) {
      let curr = this.currList[key];
      let models;
      if (curr.obj.type === 'devicePoint') {
        currObjects = [];
        models = currData.models.map(x => this.objects[x.nanoId]).filter(x => x);
      } else {
        models = [curr]; //this._pointToModels(curr);
      }
      for (let m of models) {
        if (m.focusObject) {
          currObjects.push(m.focusObject);
        }
      }
    }
    this.scene.activeCamera.focusOnObject(currObjects, undefined, args);
  }
  focusOnSegment(focusArgs) {
    let segment = currData.segment;
    if (!segment) {
      return;
    }
    let camera = this.scene.activeCamera;
    let args = camera.args;
    let ratio = 0.9;
    if (focusArgs) {
      if (focusArgs.ratio) {
        ratio = focusArgs.ratio;
      }
    }
    let distance = segment.length / (2 * ratio * Math.atan(camera.fov / 2));
    if (distance > camera.minZ * 0.01) {
      args.distance = distance;
    }
    args.target.x = (segment.pointA.x + segment.pointB.x) * 0.5;
    args.target.z = (segment.pointA.y + segment.pointB.y) * 0.5;
    camera.focusOn(args);
  }
  focusOnPoint() {
    let point = currData.point;
    if (!point) {
      return;
    }
    let camera = this.scene.activeCamera;
    let args = camera.args;
    args.target.x = point.x;
    args.target.z = point.y;
    camera.focusOn(args);
  }
  focusOnBoundingBox(boundingBox) {
    if (!boundingBox || !boundingBox.length) {
      return;
    }
    let camera = this.scene.activeCamera;
    let args = camera.args;
    args.target.x = (boundingBox[0] + boundingBox[2]) * 0.5;
    args.target.z = (boundingBox[1] + boundingBox[3]) * 0.5;
    let sizeX = boundingBox[2] - boundingBox[0];
    let sizeY = boundingBox[3] - boundingBox[1];
    let d = 2 * 0.65 * Math.atan(camera.fov / 2);
    let distance = Math.max(sizeX / (d * camera.getEngine().getScreenAspectRatio()), sizeY / d);
    if (distance > 0.000000001) {
      args.distance = Math.max(distance, camera.minZ * 0.01);
    }
    camera.focusOn(args);
  }

  // _renderDiv = 0;
  render() {
    // if (++this._renderDiv < 1) {
    //     return;
    // }
    // this._renderDiv = 0;
    let highlightLayer = this.scene.highlightLayer;
    let mode = globalState.drawingMode;
    let flag = 0;
    for (let key in this._dirtyObjects) {
      let obj = this._dirtyObjects[key];
      let func;
      switch (obj.obj.type) {
        case 'drawing':
          break;
        case 'imageDrawing':
          func = ImageDrawingBuilder;
          break;
        case 'dxfDrawing':
          func = DxfDrawingBuilder;
          break;
        case 'wall':
          func = SMWall2DBuilder;
          break;
        case 'area':
          func = Area2DBuilder;
          break;
        case 'pipeline':
          func = SMPipeline2DBuilder;
          break;
        case 'construct':
          func = Construct2DBuilder;
          break;
        case 'model':
          if (compInited.value) {
            func = Model2DBuilder;
          }
          break;
      }
      if (func && func.update) {
        if (func.update(obj)) {
          delete this._dirtyObjects[key];
          if (obj.obj.finalVisible && obj.inited && obj.setSelected) {
            obj.setSelected(this.currList[key], mode);
          }
          flag++;
        }
        if (this.currList[key] && obj.highlightMeshes) {
          if (obj.obj.finalVisible && obj.inited) {
            for (let i = 0; i < obj.highlightMeshes.length; i++) {
              highlightLayer.addMesh(obj.highlightMeshes[i], this.scene.hightLightColor);
            }
          } else {
            for (let i = 0; i < obj.highlightMeshes.length; i++) {
              highlightLayer.removeMesh(obj.highlightMeshes[i]);
            }
          }
        }
        if (flag > 50) {
          return; // build only 50 object per frame
        }
      }
    }
  }
}
export { SmMesh2DBuilder };