import KingfisherService from '../utils/request';
import FetchService from '../utils/fetch';
class ModelService {
  /**
   * Creates an instance of ProjectService.
   * @memberOf  ProjectService
   */
  constructor() {
    this.module = 'paty';
    this.kingfisherService = new KingfisherService({});
    this.fetchService = new FetchService({});
  }
  // 获取公共组件库
  getComponentLibs() {
    return this.kingfisherService.get(`/repo/kf-project/findComponentLib`).then(response => {
      return response;
    });
  }
  getAllKFPart() {
    return this.kingfisherService.get(`/repo/kf-space/get-space-part`).then(response => {
      return response;
    });
  }
  getThemeList(params) {
    return this.kingfisherService.get(`/repo/kf-tron-theme/findTronTheme?projectId=${params.projectId}&groups=&type=space`).then(response => {
      return response;
    });
  }
  getKFPartByTheme(params, systemFlag) {
    if (!params.projectId) {
      return;
    }
    return this.kingfisherService.get(`/repo/kf-space/get-space-part-theme?projectId=${params.projectId}&tronThemeId=${params.themeId}&systemFlag=${systemFlag}`).then(response => {
      return response;
    });
  }

  /**
   * 导出点表数据
   * @returns {Promise<T>}
   */
  downloadDevices(params) {
    return this.kingfisherService.post(`/repo/kf-dataconnector/download-devices`, JSON.stringify(params), undefined, {
      'responseType': 'blob'
    }).then(response => {
      return response;
    }).catch(error => {
      return error && error.response ? error.response : error;
    });
  }

  /**
   * 导出空间场景
   * @returns {Promise<T>}
   */
  exportScene(fromProId, uuids) {
    return this.kingfisherService.get(`/repo/kf-space/export-space-building?fromProId=${fromProId}&uuids=${uuids}`, null, undefined, {
      'responseType': 'blob'
    }).then(response => {
      return response;
    }).catch(error => {
      return error && error.response ? error.response : error;
    });
  }
}
export default new ModelService();