import {eachTimes} from "@/utils/tools";
import main from "@/components/main";
import {Vector2} from "../maths/vector";

export const vector3ApplyMatrix4 = function (vector3, matrix4, applySelf) {
    let vec3 = new Kingfisher.Vector3(0, 0, 0);
    if (applySelf) {
        Kingfisher.Vector3.TransformCoordinatesToRef(vector3, matrix4, vec3);
        vector3 = vec3
        return vector3;
    }
    Kingfisher.Vector3.TransformCoordinatesToRef(vector3, matrix4, vec3);
    return vec3;
}


export const calcArea = function (area) {
    let areaValue = 0;
    let segments = area.segments;
    for (let i = 0; i < segments.length; i++) {
        let s = segments[i];
        let pointA = s.pointA;
        let pointB = s.pointB;

        if (s.isArc) {
            areaValue += pointA.x * s.center.y - pointA.y * s.center.x;
            areaValue += s.center.x * pointB.y - s.center.y * pointB.x;
            areaValue += s.radius * s.radius * s.angle;
        }
        else {
            areaValue += pointA.x * pointB.y - pointA.y * pointB.x;
        }
    }
    return Math.abs(areaValue) * 0.5;
}

export const getCenter = function (points) {
    let maxX = -Number.MAX_VALUE;
    let maxY = -Number.MAX_VALUE;
    let minX = Number.MAX_VALUE;
    let minY = Number.MAX_VALUE;

    if (points) {
        for (let i = 0; i < points.length; i++) {
            let point = points[i];
            maxX = Math.max(point.x, maxX);
            maxY = Math.max(point.y, maxY);
            minX = Math.min(point.x, minX);
            minY = Math.min(point.y, minY);
        }
    }

    return new Vector2(Math.round((maxX + minX) * 0.5), Math.round((maxY + minY) * 0.5));
}